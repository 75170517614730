import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges, OnChanges, ViewChild, AfterViewInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { NotesFormState, NotesSaveService } from 'app/services/notes/notes-save.service';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-notes-builder-base',
  templateUrl: './notes-builder-base-component.html',
})
export class NotesBuilderBaseComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() notesIdx: any;
  @Input() saving: boolean;
  @Input() checking: boolean = false;
  @Input() formName: string;
  @Input() form: any;
  @Input() recordIdx;
  @Input() locked;
  @Input() isDisabled
  @Output() checked = new EventEmitter;
  @Output() touched = new EventEmitter;
  @Output() saved = new EventEmitter;

  @ViewChild('formEle', { read: NgForm }) formEle: any;

  //  public form;
  public initialForm; //on load to check for changes
  private valueChanges$ : Subscription;

  constructor(
    protected saveService: NotesSaveService
  ) { }

  ngOnInit(): void {}

  ngOnChanges(changes: SimpleChanges) {}

  ngAfterViewInit() {
    setTimeout(() => { this.setupFormMonitoring(); }, 50);
  }

  setupFormMonitoring() {
    this.formEle.form.name=this.formName;
    if (this.locked) { //locked disable saving
      this.formEle.form.disable({emitEvent: false}); 
      this.isDisabled = true
      if (this.valueChanges$) {
          this.valueChanges$.unsubscribe();
          this.valueChanges$ = null;
      }
      return;
    }
    this.formEle.form.enable({emitEvent: false}); 

    if (this.formEle && !this.valueChanges$) {
      this.saveService.formStateChange(this.formName, NotesFormState.SAVED);

      this.valueChanges$ = this.formEle.valueChanges.subscribe(data => {
        this.save();
      });

//      this.formEle.statusChanges.subscribe(data => {});     //not used but you can do this if we ever wanted to.
  

} else {
      console.warn(`No form element defined for ${this.formName} NotesBuilderBaseComponent it will not work properly`);
    }
  }

  public checkLocks(){
    this.setupFormMonitoring();
  }

  protected save(): void {

    if (!this.form || Object.keys(this.form).length === 0) {
      this.saved.emit(this.formName);
      return;
    }
    const data = {
      formIdx: 0,
      formName: this.formName,
      idx: this.notesIdx,
      form: [this.form]
    }

    this.saveService.save(data);
  }

 
}
