import { Component, Input, SimpleChanges, OnChanges } from '@angular/core';
import { Router } from '@angular/router';
import { type  PatientDetails, type VisitInfo } from 'app/models/patient-details';

@Component({
  selector: 'info-patient-details',
  templateUrl: './info-patient-details.component.html',
  styleUrls: ['./info-patient-details.component.css']
})
export class InfoPatientDetailsComponent implements OnChanges {
  @Input() info: PatientDetails;
  @Input() visits: VisitInfo;
  @Input() patientId;
  public fullAddress: string;
  constructor(private router: Router) { }

  // ngOnInit() {

  //  }

  ngOnChanges(changes: SimpleChanges): void {
    console.log("[pi]", changes); //
    if (changes.info && this.info?.address?.length > 0) {
      this.fullAddress = this.info.address.reduce((previous, current) => {
        if (!current || current === "") {
          return previous;
        }

        if (previous !== "") {
          return `${previous}, ${current}`;
        }
        return current;
      });
    }
  }

  editPatientDetails() {
    console.log(this.patientId);
    this.router.navigateByUrl(`form/patientdetails/${this.patientId}`);
  }

}
