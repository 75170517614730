import { Component } from '@angular/core';
import { NotesBuilderBaseComponent } from 'app/notes/notes-builder/notes-builder-base/notes-builder-base-component';

@Component({
  selector: 'app-permission-to-treat',
  templateUrl: './permission-to-treat.component.html',
  styleUrls: ['./permission-to-treat.component.scss']
})
export class PermissionToTreatComponent extends NotesBuilderBaseComponent{

}
  
