import { Injectable } from '@angular/core';
import {
    Actions,
    OnInitEffects,
    ofType,
    createEffect
} from '@ngrx/effects';
import { Action } from '@ngrx/store';

import { mergeMap, map } from 'rxjs/operators';
import * as clinicsActions from '../actions/clinics';
import { DataService } from '../../services/data.service';
import { Clinic } from '../../models/clinics';


@Injectable()
export class ClinicsEffects {
    constructor(private http: DataService, private actions$: Actions, ) { }

    initClinis$ = createEffect(() => {
        return  this.actions$
        .pipe(
            ofType(clinicsActions.ActionTypes.INIT),
            mergeMap(() => this.http.get<Clinic[]>('/clinics')
                .pipe(
                    map(clinics => (new clinicsActions.SetAction(clinics)))
                ))
        );
    });
}

