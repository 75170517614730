import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Injectable,
  Input,
  LOCALE_ID,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  CalendarUtils,
  CalendarWeekViewComponent,
  DateAdapter,
  getWeekViewPeriod,
} from 'angular-calendar';
import {
  WeekView,
  GetWeekViewArgs,
  EventColor,
  CalendarEvent,
  WeekViewAllDayEvent,
} from 'calendar-utils';
import { DragEndEvent, DragMoveEvent } from 'angular-draggable-droppable';
import { DiaryService } from '../../../../services/diary/diary.service';
import { AppointmentStatus, DiaryColumn } from '../../../../models/diary';
import { Subscription } from 'rxjs';

interface DayRoomViewScheduler extends WeekView {
  rooms: DiaryColumn[];
}

interface GetWeekViewArgsWithRooms extends GetWeekViewArgs {
  rooms: DiaryColumn[];
}

@Injectable()
export class DayRoomViewSchedulerCalendarUtils extends CalendarUtils {


  getWeekView(args: GetWeekViewArgsWithRooms): DayRoomViewScheduler {
    const { period } = super.getWeekView(args);
    const view: DayRoomViewScheduler = {
      period,
      allDayEventRows: [],
      hourColumns: [],
      rooms: [...args.rooms],
    };

    if (!args || !args.events) return;

    view.rooms.forEach((room, columnIndex) => {
      const roomEvents = args.events.filter((event) => event?.meta?.roomName === room.title );

      const events = roomEvents.map((event) => {
        if ( event?.meta?.status === AppointmentStatus.PENDING_APPROVAL ) {
          return {
            ...event,
            cssClass: "flashing"
          }
        }
        if ( event?.meta?.status === AppointmentStatus.CANCELLED ) {
          return {
            ...event,
            cssClass: "cancelled"
          }
        }

        return event;
      })

      const columnView = super.getWeekView({ ...args, events });

      view.hourColumns.push(columnView.hourColumns[0]);
      columnView.allDayEventRows.forEach(({ row }, rowIndex) => {
        view.allDayEventRows[rowIndex] = view.allDayEventRows[rowIndex] || {
          row: [],
        };
        view.allDayEventRows[rowIndex].row.push({
          ...row[0],
          offset: columnIndex,
          span: 1,
        });
      });
    });

    return view;
  }
}

@Component({
  // tslint:disable-line max-classes-per-file
  selector: 'app-mwl-day-room-view-scheduler',
  templateUrl: './day-room-view-scheduler.component.html',
  styleUrls: ['./day-room-view-scheduler.component.scss'],
  providers: [DayRoomViewSchedulerCalendarUtils],
})
export class DayRoomViewSchedulerComponent extends CalendarWeekViewComponent
  implements OnChanges, OnDestroy {

  @Output() roomChanged: EventEmitter<any> = new EventEmitter();
  @Output() rightClickEvent: EventEmitter<any> = new EventEmitter<any>();
  
  declare view: DayRoomViewScheduler;
  rooms: any[] = [];
  daysInWeek = 1;
  private selectedRooms$: Subscription;
  public AppointmentStatus = AppointmentStatus;

  declare hourSegmentClicked: EventEmitter<{
    date: Date;
    sourceEvent: MouseEvent;
    columnIndex: number;
  }>;

  constructor(
    protected cdr: ChangeDetectorRef,
    protected utils: DayRoomViewSchedulerCalendarUtils,
    @Inject(LOCALE_ID) locale: string,
    protected dateAdapter: DateAdapter,
    protected element: ElementRef<HTMLElement>,
    protected diary: DiaryService
  ) {
    super(cdr, utils, locale, dateAdapter, element);
  }

  ngOnInit() {
    this.selectedRooms$ = this.diary.getSelectedRooms().subscribe((rooms: any) => {
    this.rooms = rooms
      .map((room: any) => { return { id: room.idx, title: room.name }})
      .sort(function(a, b) {
        return a.title.localeCompare(b.title, undefined, {
          numeric: true,
          sensitivity: 'base'
        });
      });

      console.log(this.rooms, "this.rooms");
    });
  }

  trackByRoomId = (index: number, row: any) => row.id;

  ngOnChanges(changes: SimpleChanges): void {
    super.ngOnChanges(changes);

    if (changes.rooms) {
      this.refreshBody();
      this.emitBeforeViewRender();
    }
  }

  ngOnDestroy(){
    if (this.selectedRooms$) this.selectedRooms$.unsubscribe();
  }

  getDayColumnWidth(eventRowContainer: HTMLElement): number {
    return Math.floor(eventRowContainer.offsetWidth / this.rooms.length);
  }

  dragMove(dayEvent: any, dragEvent: DragMoveEvent) {
    if (this.snapDraggedEvents) {
      const newRoom = this.getDraggedRoomColumn(dayEvent, dragEvent.x);
      console.log("[drag] ->", newRoom);
      const newEventTimes = this.getDragMovedEventTimes( dayEvent, { ...dragEvent, x: 0 }, this.dayColumnWidth, true );
      const originalEvent = dayEvent.event;
      const adjustedEvent = {
        ...originalEvent,
        ...newEventTimes,
        meta: { ...originalEvent.meta, roomName: newRoom.title },
      };

      const tempEvents = this.events.map((event) => {
        if (event === originalEvent) {
          return adjustedEvent;
        }
        return event;
      });

      this.restoreOriginalEvents(tempEvents, new Map([[adjustedEvent, originalEvent]]));
    }

    this.dragAlreadyMoved = true;
  }

  dragEnded(weekEvent: WeekViewAllDayEvent , dragEndEvent: DragEndEvent, dayWidth: number, useY = false) {
    super.dragEnded( weekEvent, {...dragEndEvent, x: 0,}, dayWidth, useY);
    const newRoom = this.getDraggedRoomColumn(weekEvent, dragEndEvent.x);
    if (newRoom && newRoom.title !== weekEvent.event.meta.roomName) {
      console.log("[drag] finished", { event: weekEvent.event, newRoom })
      this.roomChanged.emit({ event: weekEvent.event, newRoom });
    }
  }

  protected getWeekView(events: CalendarEvent[]) {
    const wv = this.utils.getWeekView({
      events,
      rooms: this.rooms,
      viewDate: this.viewDate,
      weekStartsOn: this.weekStartsOn,
      excluded: this.excludeDays,
      precision: this.precision,
      absolutePositionedEvents: true,
      hourSegments: this.hourSegments,
      dayStart: {
        hour: this.dayStartHour,
        minute: this.dayStartMinute,
      },
      dayEnd: {
        hour: this.dayEndHour,
        minute: this.dayEndMinute,
      },
      segmentHeight: this.hourSegmentHeight,
      weekendDays: this.weekendDays,
      ...getWeekViewPeriod(
        this.dateAdapter,
        this.viewDate,
        this.weekStartsOn,
        this.excludeDays,
        this.daysInWeek
      ),
    });

    return wv;
  }

  private getDraggedRoomColumn( dayEvent:  WeekViewAllDayEvent, xPixels: number) {
    const columnsMoved = Math.round(xPixels / this.dayColumnWidth);
    const currentColumnIndex = this.view.rooms.findIndex((room) => room.title === dayEvent.event.meta.roomName);
    const newIndex = currentColumnIndex + columnsMoved;
    return this.view.rooms[newIndex];
  }

  contextmenuOpen(event, timeEvent){
    console.log("[context]", event,timeEvent);
    this.rightClickEvent.emit({mouseEvent: event, timeEvent: timeEvent});
  }


}
