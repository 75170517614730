<div class="row margin-collapse" *ngIf="transaction || transactions?.length">
  <div class="col">
    <div [ngClass]="'pdf'">
      <div class="void-overlay" *ngIf='transaction && transaction.voidedAt'>VOID</div>
      <div class="void-overlay" *ngIf='transaction && transaction.RefundsTxn'>REFUND TXN {{transaction.RefundsTxn}}</div>
      <table class="invoice-header">
        <tr>
          <td class="w-33pc">
            <span class="fs-6">{{patientName}}</span>
            <div class="addressPatient">
              <span *ngFor="let address of patientAddress">
                <div *ngIf="address">
                  {{address}}<br>
                </div>
              </span>
            </div>
          </td>
          <td></td>
          <td class="w-50pc">
            <table>
              <tr>
                <td colspan="2" class="client-logo">
                  <div >
                    <img [src]="invoiceLogo" class="client-logo" alt="" />
                  </div>
                </td>
              </tr>
              <br>
              <br>
              <tr>
                <td class="w-50pc" *ngIf="!transactionListView">
                  <strong>Date</strong><br />

                  {{transaction.TimePaid | date : 'short' :undefined: 'en-GB'}}<br /><br />

                  <strong>Reference No.</strong><br />
                  <span *ngIf="invoicePrefix">{{invoicePrefix}}-</span>{{transactionIdx}}
                </td>

                <td class="w-50pc">
                  <div class="address text-end">
                    <span *ngFor="let address of clinic">
                      {{address}}<br />
                    </span>
                  </div>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
      <br>
      <table  *ngIf="!transactionListView && (insurance && insuranceInvoice)">
        <tr>
          <th>Patient Name</th>
          <th>D.O.B.</th>
          <th>Address</th>
        </tr>
          <tr>
            <td>{{patientNameForInsurance}}</td>
            <td>{{patientDOBForInsurance}}</td>
            <td><i *ngIf="patientAddressForInsurance">{{patientAddressForInsurance}}</i></td>
          </tr>
      </table>
      <table class="margin-top items" *ngIf="!transactionListView">
        <thead>
          <th>Item</th>
          <!-- <th></th> -->
          <th>Price</th>
          <th>Qty</th>
          <th>Total</th>
          <th *ngIf="selectForRefund">Refund</th>
        </thead>
        <tbody>
          <tr [class]="item.refund ? 'refunding' : ''" *ngFor="let item of items">
            <td *ngIf="item.type !== 'charges'">
              {{item.Name}} &nbsp; 
              <small *ngIf="item.appointment">{{item.appointment.StartTime | date : 'short'}}</small>
              <br/>
              <span *ngFor="let prof of item.profDetails">
                <small><i>{{prof}}</i></small>&nbsp;
              </span>

              <table *ngIf="insurance && insuranceInvoice">
                <tbody>
                  <tr *ngIf="item.InsuranceNumber">
                    <td>Patient Insurance Number:</td>
                    <td> {{item.InsuranceNumber}}</td>
                  </tr>
                  <tr *ngIf="item.AccountChargeReference">
                    <td>Insurance Authorisation Code:</td>
                    <td> {{item.AccountChargeReference}}</td>
                  </tr>
                  <tr *ngIf="item.staffAccountNumber">
                    <td>Practitioner Insurance Number</td>
                    <td>{{item.staffAccountNumber}}</td>
                  </tr>
                </tbody>
              </table>

            </td>
            <td *ngIf="item.type === 'charges'">
              {{ item.notes }} ({{item.Name}} &nbsp; <small>{{transaction.TimePaid | date : 'short'}}</small> 
              <span *ngFor="let prof of item.profDetails">
                &nbsp;
                <small><i>{{prof}}</i></small>
              </span>)
            </td>
            <!-- <td><small>{{item.date}}</small></td> -->
            <td class="number">{{item.value | currency : 'GBP' : 'symbol' }}</td>
            <td class="number">{{item.qty}}</td>
            <td class="number">
              <span *ngIf="item.pervailVATRate > 0">
                Pre VAT: {{(((item.qty * item.value)/(100-item.pervailVATRate))*100) | currency : 'GBP' : 'symbol'
                }}<br>
                VAT ({{item.pervailVATRate}})%:
                {{(item.qty * item.value* (item.pervailVATRate / 100)) | currency : 'GBP' : 'symbol' }}<br>
                With VAT: <strong>{{(item.qty * item.value) | currency : 'GBP' : 'symbol' }}</strong>
              </span>
              <span *ngIf="item.pervailVATRate == 0 || !item.pervailVATRate">
                {{(((item.qty * item.value)/(100-item.pervailVATRate))*100) | currency : 'GBP' : 'symbol' }}
              </span>
            </td>
            <td *ngIf="selectForRefund"><mat-checkbox (ngModelChange)="refreshRefundList($event, item)"[(ngModel)]="item.refund"></mat-checkbox>
          </tr>
          <tr>
            <td colspan="3" class="number total">TOTAL</td>
            <td class="number total">{{saleTotal | currency : 'GBP' : 'symbol' }}</td>
          </tr>
        </tbody>
      </table>
      
      <table class="margin-top items" *ngIf="transactionListView">
        <thead class="transaction-list__header">
          <th>Date</th>
          <th>Ref</th>
          <th>Detail</th>
          <th>Amount</th>
          <th>No. Credits</th>
          <th (click)="toCreditsList()">Credit Balance</th>
          <th>Balance</th>
        </thead>
        <tbody class="transaction-list__body">
          <ng-container *ngFor="let transaction of transactions">
            <ng-container *ngFor="let item of transaction.items">
              <tr [ngClass]="{'refund-txn': transaction.RefundsTxn}">
                <td>{{ transaction.TimePaid | date : 'short' :undefined: 'en-GB' }}</td>
                <td (click)="openInvoice(transaction.idx)">{{ transaction.idx }}
                </td>
                <td>
                  <div class="patient-name">{{ patientName }}</div>
                  <div class="item-name">{{ item.Name }}</div>
                  <div class="practioner-name" *ngIf="item.type=='treatment'">{{ item.staffMember }} {{ item.startTime | date : 'short' }}</div>
                  <div *ngIf="item.notes" class="no-print text-muted">{{ item.notes}}</div>
                </td>
                <td class="right-align">{{ -item.qty * +item.value * ((+item.pervailVATRate/100)+1) | currency : 'GBP' : 'symbol' }}</td> <!-- removed a - minus symbol added to all lines and did it with 'maths' -->
                <td class="right-align">{{ item.creditValue ? -item.creditValue : '-' }}</td>
                <td (click)="toCreditsList()" class="right-align">{{ item.creditBalance }}</td>
                <td class="right-align">{{ item.balance | currency : 'GBP' : 'symbol' }}</td>
                
              </tr>
              
            </ng-container>
            <tr *ngFor="let payment of transaction.payments" class="payment-row">
              <td>{{ transaction.TimePaid | date : 'short' :undefined: 'en-GB' }}</td>
              <td>{{ transaction.idx }}</td>
              <td>Payment: {{ payment.Method }}</td>
              <td class="right-align">{{ payment.Amount | currency : 'GBP' : 'symbol' }}</td>
              <td class="right-align">-</td>
              <td class="right-align">{{ payment.creditBalance }}</td>
              <td class="right-align">{{ payment.balance | currency : 'GBP' : 'symbol' }}</td>
            </tr>
          </ng-container>

          <tr>
            <td colspan="6" class="number total right-align">TOTAL SPEND</td>
            <td class="number total">{{ totalSpend | currency : 'GBP' : 'symbol' }}</td>
          </tr>
          <tr>
            <td colspan="6" class="number total right-align">TOTAL PAYMENTS</td>
            <td class="number total">{{ totalPayments | currency : 'GBP' : 'symbol' }}</td>
          </tr>
          <tr>
            <td colspan="6" class="number total right-align">BALANCE</td>
            <td class="number total">{{ currentBalance | currency : 'GBP' : 'symbol' }}</td>
          </tr>
        </tbody>
      </table>

      <table *ngIf="!transactionListView">
        <tr>

          <td class="w-50pc">
            <table *ngIf="vouchers.length>0 && !transaction.RefundsTxn" class="margin-top">
              <br>
              <thead>
                <tr>
                  <th colspan="2">GIFT VOUCHERS ISSUED</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let voucher of vouchers">
                  <td class="monospace">
                    {{voucher.voucherId}}
                  </td>
                  <td>{{voucher.amount | currency : 'GBP' : 'symbol' }}</td>
                </tr>
              </tbody>
            </table>
          </td>

          <td class="w-50pc">
            <br>
            <table *ngIf="payments.length > 0" class="margin-top">
              <thead>
                <tr>
                  <th colspan="2">PAYMENTS</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let payment of payments">
                  <td>{{payment.Method}}</td>
                  <td class="number">{{payment.Amount | currency : 'GBP' : 'symbol' }}</td>
                </tr>
                <tr *ngIf="payments.length>1">
                  <td>TOTAL</td>
                  <td class="number">{{totalPayments| currency : 'GBP' : 'symbol' }}</td>
                </tr>
              </tbody>
            </table>

            <table *ngIf="credits.balance || credits.used">
              <tbody>
                <tr>
                  <td>CREDITS</td>
                  <td>Used: {{credits.used}}</td>
                  <td>Balance: {{credits.balance}}</td>
                </tr>
              </tbody>
            </table>

            

          </td>

        </tr>
        
      </table>
      <small *ngIf="nextVisit && !insuranceInvoice"><span class="bold">Next visit:</span>&nbsp; {{nextVisit | date : 'EEEE, MMMM d, y, h:mm a'}}</small>
      <br><br>
      


    
    </div>
  </div>
  <div class="col-sm-2 no-print mobile-margin">
    <div class="fix-top">
      <div class="mb-3">
        
            <mat-form-field *ngIf="emailing" class="w-100 mobile-input desktop-input">
              <input matInput 
                placeholder="Email address" 
                [(ngModel)]="clientEmail"
                name="clientEmail">
            </mat-form-field>

            <mat-form-field *ngIf="emailing" class="w-100">
              <mat-label>Select email template</mat-label>
              <mat-select [(value)]="selectedTemplate"  (selectionChange)="updateTemplate($event.value)">
                <mat-option></mat-option>
                <mat-option [value]="option.value" *ngFor="let option of templates">{{ option.viewValue }}</mat-option>
              </mat-select>
            </mat-form-field>

            <mat-form-field *ngIf="emailing" class="w-100 mobile-input desktop-input">
              <mat-label>Subject</mat-label>
              <input matInput 
                placeholder="Subject" 
                [(ngModel)]="emailText.subject"
                name="EmailSubject">
            </mat-form-field>



            <div class="editor">
              <angular-editor *ngIf="emailing" [(ngModel)]="emailText.body" [config]="editorConfig" [placeholder]="'Enter text here...'"  id="print"></angular-editor>
            </div>
             
          
            
    

           
        
            <div  class="mobile-buttons mobile-invoice-buttons">
              <button *ngIf="!emailing" mat-flat-button color="accent" class="mobile-button desktop-button" (click)="emailInvoice()">
                <mat-icon>alternate_email</mat-icon>&nbsp;Email Copy
              </button>

              <button *ngIf="emailing" mat-flat-button color="accent" class="mobile-button desktop-button" (click)="emailInvoice()">
                <mat-icon>alternate_email</mat-icon>&nbsp;Send
              </button>

              <button *ngIf="((!payments || !payments.length) && (!credits || credits.used === 0)) && !transactionListView && items.length && items[0].type !== 'charges'" mat-raised-button color="primary" class="mobile-button desktop-button mt-3" (click)="takePayment()">Take Payment</button>
           
              <div *ngIf="!selectForRefund" class="desktop-buttons">
                <button id="print-button" mat-flat-button color="accent" class="mobile-button desktop-button" (click)="print()">
                  <mat-icon>print</mat-icon>&nbsp;Print</button>
              </div>
             
              <div *ngIf="insuranceInvoice"class="my-3 desktop-buttons">
                <button  class="btn-link" (click)="toCustomer()">Customer Invoice</button>
              </div>
              <div *ngIf="insurance && !insuranceInvoice"class="my-3 desktop-buttons">
                <button  class="btn-link" (click)="toInsurance()">Insurance Invoice</button>
              </div>
                          
              <div *ngIf="!selectForRefund && canVoid " class="my-3 desktop-buttons" >
                <button class="btn-link" (click)="void()">Void Transaction</button>
              </div>

              <div *ngIf="!selectForRefund && canRefund && !transaction.RefundsTxn" class="my-3 desktop-buttons" >
                <button mat-raised-button class="mobile-button desktop-button"  color="basic" (click)="refund()">Refund Transaction</button>
              </div>

              <div *ngIf="selectForRefund" class="my-3 desktop-buttons">
                <button mat-raised-button class="mobile-button desktop-button" color="primary" (click)="processRefundAllAndReset()">Refund All</button>
              </div>
<!--
              <div *ngIf="selectForRefund" class="my-3 desktop-buttons">
                <button mat-raised-button class="mobile-button desktop-button" color="basic" (click)="processRefundAll()">Refund All</button>
              </div>
-->
              <div *ngIf="selectForRefund" class="my-3 desktop-buttons">
                <button mat-raised-button [disabled]="!refundItems || refundItems.length === 0" color="primary" class="mobile-button desktop-button" (click)="processRefund()">Refund Selected</button>
              </div>


              <div class="my-3 desktop-buttons">
                <button mat-raised-button class="mobile-button desktop-button" (click)="back()">Back</button>
              </div>

              
            </div>
          

      </div>
    </div>
  </div>

</div>