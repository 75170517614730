import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Clinic } from 'app/models/clinics';
import { Room } from 'app/models/rooms';
import { StaffMember } from 'app/models/staff';
import { type Treatment, type TreatmentGroup } from 'app/models/treatments';
import { ClinicService } from 'app/services/clinic.service';
import { AppointmentsService } from 'app/services/diary/appointments.service';
import { DiaryService } from 'app/services/diary/diary.service';
import { LoadingService } from 'app/services/loading.service';
import { NotificationService } from 'app/services/notification.service';
import { PatientChooserService } from 'app/services/patient.service';
import { RoomsService } from 'app/services/rooms.service';
import { StaffService } from 'app/services/staff.service';
import { TreatmentService } from 'app/services/treatment.service';
import { combineLatest, Observable } from 'rxjs';
import * as fromDiary from "app/models/diary";
import { map, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromRoot from "app/store/reducers";
import { type PatientDetails } from 'app/models/patient-details';

@Injectable()


// tracks staff / treatments / selected patient and provides valid option combinations

export class EditAppointmentService {
    private staff$;
    private treatments$;
    private patient$;
    private rooms$;
    private clinics$;
    private selectedClinic$;
    private _allClinics: Clinic[];
    private selectedClinicIdx: number;
    private _allRooms: Room[];
    private _availableRooms: Room[];

    private _categoryIdx: number;
    private _saving;
    public clashMessages: string[] = [];
    private _allTreatments: TreatmentGroup[] = [];
    private _availableTreatments: Treatment[];

    private _allStaff: StaffMember[] = [];
    private _reminders: {
        smsEnabled: boolean,
        emailEnabled: boolean,
        mobilePhone: string,
        email: string
    };

    private _diarySpecialApps: any[];

    private selectedClinic;
    public lockRoom: boolean;

    private appointment: fromDiary.Appointment = fromDiary.initialAppointmentValue;

    constructor(
        private staff: StaffService,
        private rooms: RoomsService,
        private treatments: TreatmentService,
        private patientChooserService: PatientChooserService,
        private clinic: ClinicService,
        private loading: LoadingService,
        private appointmentService: AppointmentsService,
        private notifications: NotificationService,
        private diary: DiaryService,
        private router: Router,
        private store: Store<fromRoot.State>) {
            this.clearAppointmentData();
            this.init();

    }


    init() {
        this.staff$ = this.staff.getStaff();
        this.treatments$ = this.treatments.getTreatments();
        this.rooms$ = this.rooms.getRooms();
        this.patient$ = this.patientChooserService.getPatient();
        this.clinics$ = this.clinic.getClinics();
        this.selectedClinic$ = this.clinic.getSelectedClinic();

        combineLatest([this.staff$, this.treatments$, this.rooms$, this.clinics$, this.selectedClinic$]).subscribe((res: any) => {
            this._allStaff = res[0];
            this._allTreatments = res[1];
            this._allRooms = res[2];
            this._allClinics = res[3];
            this.selectedClinic = res[4];
            console.log("[uar]", res);
            this.updateAvailableRooms();
        });


        this.patientChooserService.getPatientInfo()
        .subscribe(res => {
            console.log("[eaps]", res);
            this.patientUpdated(res);
        });

        this.patientChooserService.getPatient().subscribe(res => {
            if (res && res.idx) {
                console.log("[edit] got patient", res);
                this.patientIdx = res.idx;
            }else{
                this.patientIdx = null;
            }
        });

        this.getNotAvailables();
    }

    private getNotAvailables() {
        this.store.select(fromRoot.getSiteSettings).subscribe((res: any) => {
            console.log("[edit] get na na", res);
            if (res.diarySpecialAppointments) {
                this._diarySpecialApps = res.diarySpecialAppointments;
            }
        });
    }

    private patientUpdated(patientInfo: PatientDetails) {
        console.log("[edit app] patient update", JSON.stringify(patientInfo));
        if (!patientInfo){
            return;
        }
        this.appointment.patientInfo = patientInfo;
        if (patientInfo.isSpecial) {
            console.log("[edit app] is NA NA NA", patientInfo);
            this.updateAppointmentToNotAvailable();
            this.loading.stop();
            return;
        } else {
            this.smsReminderEnabled = patientInfo.reminders.sms;
            this.emailReminderEnabled = patientInfo.reminders.email;
            this.patientPhone = patientInfo.mobilePhone;
            this.patientEmail = patientInfo.email;
        }
        this.loading.stop();
        if (this.appointment.idx > 0) {
            const category = this._allTreatments.find((category: any) => category.treatments.find((treatment: any) => treatment.idx === this.treatmentIdx));
            this.refreshTreatmentOpionList(category ? category.idx : null);
            return;
        }

        this.updateTreatmentOptions();
    }

    private flattenTreatments(): Treatment[] {
        let treatments: Treatment[] = [];
        for (let treatGroup of this._allTreatments){
            treatments.concat(treatGroup.treatments);
        }
        return treatments;
    }

    private updateAvailableRooms() {
        if (!this.selectedClinic) {
            console.warn("cant get rooms as I don't know where we are");
            return;
        }
        this._availableRooms = this._allRooms.filter(room => {
            if (room.locatedAt === this.selectedClinic.idx) { return room }
        });
    }

    /**
     * Sets the appointment room to the staff's default room
     * If lockRoom is true then it will not override it, lockRoom is being set to true in the edit-appointment component if in room view 
     */
    private setStaffDefaultRoom() {
        if (this.lockRoom){
            console.log(`[edit] room selection locked`);
            return; //do not override room.
        }
        const id = this.getStaffMember().defaultRoomIdx;
        this.appointment.roomIdx = id;
        console.log(`[edit] staff room set to ${id}`);
    }

    private setRoomFromAppointment() {
        if (!this._availableRooms.includes(this._allRooms.find(room => room.idx === this.appointment.roomIdx))) {
            if (this._availableRooms.length > 0) {
                this.appointment.roomIdx = this._availableRooms[0].idx;
            } else {
                this.setStaffDefaultRoom();
            }
        }
    }

    public loadAppointment(idx: number) {
        console.log("[edit] appointment load");
        this.clearAppointmentData();
        this.loading.start();
        this.appointmentService.getAppointment(idx).subscribe(res => {
            console.log(`[edit app] loads `, res);
            this.appointment = res;

            // this.appointmentDateTime = new Date(res.startTime);
            
            // setup Date
            let date = res.startTime.split(" ");
            date = date[0] + "T" + date[1];
            this.appointment.startTime = new Date(date);
            if (res.status === fromDiary.AppointmentStatus.UNALLOCATED_SLOT){
                console.log("[edit] is unalloc") //no patient so don't do a set patient
            }else{
                this.patientChooserService.setPatient(res.patientIdx);
            }
            this.setRoomFromAppointment();
            this.loading.stop();
           console.log(`[edit app] loads set to > `, this.appointment);
        });
    }

    public setAppointment(appointment: any): void {
        this.appointment = appointment;
    }

    public refreshTreatmentOpionList(cat?: number) {
        const treatFromCat: number = cat || this.getStaffMember().treatFromCat;
        this.categoryIdx = treatFromCat;
        let treatments: TreatmentGroup;

        console.log("[diary edit] my cat is ", treatFromCat, this._allTreatments );
        if (treatFromCat !== 0) {
            treatments = this._allTreatments.find(t => t.idx == treatFromCat);
            if (treatments) {
                this._availableTreatments = treatments.treatments.slice().sort((a, b) => a.name.localeCompare(b.name));
                return;
            }

        }
        this._availableTreatments = [];
        for (let i = 0; i < this._allTreatments.length; i++) {
            this._availableTreatments.push(...this._allTreatments[i].treatments);
        }

    }

    public updateTreatmentOptions() { // when staff member is changed or on load
        if (this.allStaff.length == 0 || this._allTreatments.length == 0 || !this.patientInfo) { return; }
        // find treat form cat
        if (!this.getStaffMember()) {
            return;
        }

        this.setStaffDefaultRoom();

        // is it not available?
        const upperName = this.appointment.patientInfo.name.toUpperCase();

        console.log("[edit] update my options", this.appointment);
      //  if (fromDiary.notAvailablePhrases.indexOf(upperName) !== -1) {
      //      this.updateAppointmentToNotAvailable();
      //      return;
      //  }

        this.appointment.status = fromDiary.AppointmentStatus.BOOKING;
        // Code to filter available appointments by practitioner's category
        this.refreshTreatmentOpionList();


        this.selectBestTreatment();
    }


    public getStaffMember(): StaffMember {
        if (this.allStaff.length == 0) {
            console.error("get all staff called before it was ready");
            return null;
        }
        //console.log(this.appointment.staffIdx);
        
        return this.allStaff.find(f => f.idx == this.appointment.staffIdx);
    }

    private selectBestTreatment() { // when treatment options change
        if (this.appointment.isNotAvailable) {
            console.log("[edit app] [ti] declared NA");
            this.treatmentIdx = 0;
            return;
        }
        console.log("[edit app] [ti] selection running", this.getStaffMember(), this._availableTreatments);
        // do we have a valid treatment on the current appointment
        if (this.treatmentIdx > 0) {
            // we have one, but is it allowed?
            const ti: Treatment = this._availableTreatments.find(t => t.idx == this.treatmentIdx);
            if (ti) {
                this.duration = ti.defaultDuration;
                console.log("[edit app] [ti] treatment already selected from current app", ti);
                return;
            } // yes it is valid for this staff member, bye!
        }


        // look for last appointment
        console.log("[edit app] [ti] last found appointment=", this.appointment.patientInfo.lastAppointment);
        if (this.appointment.patientInfo.lastAppointment.treatmentIdx ) {
       //     console.log("[edit app] [ti] last found appointment=", this.appointment.patientInfo.lastAppointment);
            // we have one, but is it allowed?
            const ti: Treatment = this._availableTreatments.find(t => t.idx == this.appointment.patientInfo.lastAppointment.treatmentIdx);
            if (ti) {
                this.duration = ti.defaultDuration;
                this.treatmentIdx = ti.idx;
                console.log("[edit app] [ti] treatment selected from last found appointment", this.appointment.patientInfo.lastAppointment, ti);
                return;
            } // yes it is valid for this staff member, bye!
        }



        // select 1st treatment if patients nApps<1
        if (this.appointment.patientInfo.nVisits == 0) {
            this.treatmentIdx = Number(this.getStaffMember().defaultFirstTreatment);
            console.log("[ti] select first treatment id", this.treatmentIdx);
        } else { // or default follow up
            this.treatmentIdx = Number(this.getStaffMember().defaultFollowUpTreatment);
            console.log("[ti] select follow up treatment id", this.treatmentIdx);
        }
        this.setStaffDefaultRoom();

        this.updateDefaultDuration();

    }

    public updateDefaultDuration() { // when treatment type changes
        const ti: Treatment = this._availableTreatments.find(t => t.idx == this.treatmentIdx);
        if (!ti) { this.duration = 30; }
        else { this.duration = ti.defaultDuration; }
        this.clashCheck();
    }

    public clashCheck() {
        const res: string[] = [];
        if (this.appointment.status != fromDiary.AppointmentStatus.BOOKING) {
            return;
        }
        this.appointmentService.clashCheck(this.appointment)
            .subscribe((errors: string[]) => {
                if (errors && errors.length > 0) {
                    this.notifications.send(errors.join("\n"));
                }
            });
    }



    public save(actionStatus: string = null, fromMenu: boolean = false, emailRequired: boolean = false): Observable<any> {

        console.log("[flat] SAVE", actionStatus);
        this.saving = true;
        
// bump unallocated to BOOKING on save        
        if (this.appointment.status==fromDiary.AppointmentStatus.UNALLOCATED_SLOT && this.patientIdx>0){
            this.appointment.status=fromDiary.AppointmentStatus.BOOKING;
        }

        if ( actionStatus ) { 
            this.appointment.lastStatus = this.appointment.status;
            this.appointment.status = actionStatus;            
        }else { 
            this.clashCheck(); 
        }

        return this.appointmentService.save(
            this.appointment, this.reminders
        ).pipe(map(res => {
            /*
            if ( actionStatus ) {
                this.appointmentService.emailBookingPatient({ patientIdx: this.appointment.patientIdx, appointmentIdx: this.appointment.idx }).subscribe((emailSent: any) => {});
            }

            if ( emailRequired ) {
                this.appointmentService.emailAppointmentPatient(res).subscribe(() => {});
            }
            */


            if (res.length > 0) {
                this.clashMessages = res;
            }

            this.clearAppointmentData();
            this.saving = false;

            if ( !actionStatus || ( actionStatus && !fromMenu ) ) { this.navigateToDiary(); } else { this.diary.getAppointmentsFromServer(); }

            return res;
        }));
    }

    navigateToDiaryOnAppointmentDay() {
        const dateString = JSON.stringify(this.appointment.startTime).split("T")[0].slice(1);
        this.router.navigate(['/diary'], { queryParams: { date: dateString } });
    }

    navigateToDiary() {
        this.router.navigate(['/diary']);
    }

    /** clears all appoinment related data including patient */
    clearAppointmentData() {
        this.newAppointmentData();
        this._reminders = {smsEnabled: false, emailEnabled: false, email: "", mobilePhone: "" };
        this.patientChooserService.clearPatient();
        console.log("[edit] clearing out ", JSON.stringify(this.appointment), this.appointment);
    }

    /** Just reset appointment */
    newAppointmentData(){
        this.appointment = Object.assign({}, fromDiary.initialAppointmentValue);
        if (!this._reminders){
            this._reminders = {smsEnabled: false, emailEnabled: false, email: "", mobilePhone: "" };
        }
    }

    setNotAvailable(naPatient) {
        this.patientChooserService.selectPatient(naPatient);
        this.updateAppointmentToNotAvailable();
    }

    private updateAppointmentToNotAvailable() {
        this.appointment.status = fromDiary.AppointmentStatus.TIMEOFF;
        console.log("[DIARY EDIT] TI=0");
        this.treatmentIdx = 0;
    }

    public validate(): {ok: boolean, msg: string} {
        this.clashMessages = [];

        if (!this.patientChooserService.getCurrentPatientIdxSnapshot()) {
            return {ok: false, msg: "Patient is required"};
        }

        if (!this.appointment.staffIdx) {
            return {ok: false, msg: "No staff member selected"};
        }

        if (!this.appointment.roomIdx && this.appointment.status == fromDiary.AppointmentStatus.BOOKING) {
            return {ok: false, msg: "No room selected"};
        }

        if (!this.treatmentIdx && this.appointment.status == fromDiary.AppointmentStatus.BOOKING) {
            return {ok: false, msg: "No treatment type selected"};
        }

        return {ok: true, msg: "Let's GO!"};
    }


// Setters and getters, use these to talk to this service to ensure dependancies get updated correctly

    // APPOINTMENT modifiers
    public set startTime(time: Date) {
        this.appointment.startTime = time;
    }

    public get startTime() {
        return this.appointment.startTime;
    }

    public set staffIdx(idx: number) {
        this.appointment.staffIdx = idx;
        this.setStaffDefaultRoom();
    }

    public get staffIdx() {
        return this.appointment.staffIdx;
    }

    public set duration(duration: string | number) {
        if (typeof(duration) == 'number') {
            this.appointment.duration = duration;
            return;
        }

        this.appointment.duration = parseInt(duration);
    }

    public get duration() {
        return this.appointment.duration.toString();
    }

    public get patientInfo() {
        return this.appointment.patientInfo;
    }

    public get treatmentIdx() {
        return this.appointment.treatmentIdx;
    }

    public set treatmentIdx(n) {
        console.log("[ti] set=", n);
        this.appointment.treatmentIdx = n;
        this.updateDefaultDuration();
    }

    public get roomIdx() {
        return this.appointment.roomIdx;
    }
    public set roomIdx(n) {
        this.appointment.roomIdx = n;
    }

    public get notes(): string {
        return this.appointment.notes;
    }

    public set notes(n: string) {
        this.appointment.notes = n;
    }

    public get status() {
        return this.appointment.status;
    }

    public set status(s: string) {
        this.appointment.status = s;
    }

    public set idx(idx: number) {
        this.appointment.idx = idx;
    }

    public set patientIdx(idx: number) {
        console.log("[edit] set patient id=", idx)
        this.appointment.patientIdx = idx;
    }
    public get patientIdx() {
        return this.appointment.patientIdx;
    }

    public set categoryIdx(idx: number) {
        this._categoryIdx = idx;
    }

    public get categoryIdx() {
        return this._categoryIdx;
    }

    // BASE data
    public get allClinics() {
        return this._allClinics;
    }

    public get allStaff() {
        return this._allStaff;
    }


    public get allTreatments() {
        return this._allTreatments;
    }

    public get allRooms() {
        return this._allRooms;
    }

    public get availableTreatments() {
        return this._availableTreatments;
    }

    public set availableTreatments(treatments: any) {
        this._availableTreatments = treatments;
    }

    public get availableRooms() {
        return this._availableRooms;
    }

    public get isNotAvailableAppointment(): boolean {
        return this.appointment.status == fromDiary.AppointmentStatus.TIMEOFF;
    }

    public get isGroupBooking(): boolean {
        return this.appointment.status == fromDiary.AppointmentStatus.UNALLOCATED_SLOT;
    }


    // PATIENT reminder info
    public get patientEmail(): string {
        return this._reminders.email;
    }

    public set patientEmail(e: string) {
        this._reminders.email = e;
    }

    public get patientPhone(): string {
        return this._reminders.mobilePhone;
    }

    public set patientPhone(e: string) {
        this._reminders.mobilePhone = e;
    }

    public get emailReminderEnabled(): boolean {
        return this._reminders.emailEnabled;
    }

    public set emailReminderEnabled(e: boolean) {
        this._reminders.emailEnabled = e;
    }

    public get smsReminderEnabled(): boolean {
        if (!this.appointment.patientInfo.reminders) {
            return false;
        }
        return this._reminders.smsEnabled;
    }

    public set smsReminderEnabled(e: boolean) {
        this._reminders.smsEnabled = e;
    }

    public get reminders() {
        return this._reminders;
    }

    // helpers

    public get saving(): boolean {
        return this._saving;
    }

    public set saving(s: boolean) {
        this._saving = s;
    }

    public get diarySpecialApps(): any[] {
        return this._diarySpecialApps;
    }

}
