<div mat-dialog-title class="dialog-title">
    <!-- Dialog title -->
    <h5>{{data.title}}</h5>
    <button mat-icon-button aria-label="close dialog" class="close-button" tabindex="-1" mat-dialog-close>
        <mat-icon class="close-icon text-muted">close</mat-icon>
    </button>
</div>

<div mat-dialog-content>
    <!-- Dialog message -->
    <p *ngIf="data.message">
        {{data.message}}
    </p>
    <!-- Dialog warning message -->
    <p class="warning" *ngIf="data.warningMessage">
        {{data.warningMessage}}
    </p>
    <ng-container *ngFor="let field of data.formFields">
        <!-- For each question -->
        <ng-container [ngSwitch]="field.type">
            <!-- Text question -->
            <app-form-text-box *ngSwitchCase="'textbox'" [form]="data.form" [question]="field"></app-form-text-box>
            <!-- Number question -->
            <app-form-text-box *ngSwitchCase="'number'" [form]="data.form" [question]="field"></app-form-text-box>
            <!-- Textarea question -->
            <ng-container *ngSwitchCase="'textarea'">
                <mat-form-field style="width:100%">
                    <mat-label>{{field.label}}</mat-label>
                    <textarea matInput #inputValue cdkTextareaAutosize cdkAutosizeMinRows="1" cdkAutosizeMaxRows="6"
                        [id]="field.label" [(ngModel)]="field.value"></textarea>
                </mat-form-field>
            </ng-container>
            <!-- Select question -->
            <app-form-dropdown *ngSwitchCase="'select'" [form]="data.form" [question]="field"></app-form-dropdown>
            <!-- Radio question -->
            <ng-container *ngSwitchCase="'radio'">
                <mat-label>{{field.label}}</mat-label>
                <mat-radio-group name="field.label" [(ngModel)]="field.value">
                    <mat-radio-button *ngFor="let option of field.options" [value]="option.idx">{{option.text}}
                    </mat-radio-button>
                </mat-radio-group>
            </ng-container>
        </ng-container>
    </ng-container>
</div>

        <div mat-dialog-actions>
            <!-- Confirm button -->
            <button *ngFor="let text of data.confirmText" mat-raised-button color="primary"
                (click)="onConfirm()">{{text}}</button>
            <!-- Dismiss button -->
            <button *ngFor="let text of data.dismissText" mat-raised-button color="primary"
                (click)="onDismiss()">{{text}}</button>
            <!-- Input submit button -->
            <button *ngFor="let text of data.inputText; index as i" mat-raised-button color="primary"
                (click)="onInput(i)">{{text}}</button>
        </div>