import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FormTextBoxComponent } from "./form-text-box/form-text-box.component";
import { FormPasswordComponent } from "./form-password/form-password.component";
import { FormChooserComponent } from "./form-chooser/form-chooser.component";
import { FormDropdownComponent } from "./form-dropdown/form-dropdown.component";
import { FormDropdownMultiComponent } from "./form-dropdown-multi/form-dropdown-multi.component";
import { FormDropdownMultiNestedComponent } from "./form-dropdown-multi-nested/form-dropdown-multi-nested.component";
import { FormTextAreaComponent } from "./form-text-area/form-text-area.component";
import { FormCheckboxComponent } from "./form-checkbox/form-checkbox.component";
import { FormInfoBoxComponent } from "./form-info-box/form-info-box.component";
import { FormSliderComponent } from "./form-slider/form-slider.component";
import { FormTitleAreaComponent } from "./form-title-area/form-title-area.component";
import { FormsColourComponent } from "./forms-colour/forms-colour.component";
import { FormHiddenComponent } from "./form-hidden/form-hidden.component"
import { FormTemplateBaseComponent } from "./form-template-base/form-template-base.component";
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MyMaterialModule } from 'app/material/material.module';
import { CustomFormControlsModule } from 'app/custom-form-controls/custom-form-controls.module';




@NgModule({
  declarations: [
    FormHiddenComponent,
    FormTitleAreaComponent,
    FormTemplateBaseComponent,
    FormTextBoxComponent,
    FormSliderComponent,
    FormPasswordComponent,
    FormChooserComponent,
    FormDropdownComponent,
    FormTextAreaComponent,
    FormCheckboxComponent,
    FormInfoBoxComponent,
    FormsColourComponent,
    FormDropdownMultiComponent,
    FormDropdownMultiNestedComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MyMaterialModule,
    CustomFormControlsModule,
   
  ],
  exports: [
    FormTitleAreaComponent,
    FormTemplateBaseComponent,
    FormTextBoxComponent,
    FormSliderComponent,
    FormPasswordComponent,
    FormChooserComponent,
    FormDropdownComponent,
    FormTextAreaComponent,
    FormCheckboxComponent,
    FormInfoBoxComponent,
  //  FormUploaderComponent,
    FormsColourComponent,
  ]

})
export class DynamicFormComponentsModule { }
