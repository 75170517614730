
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GiftCard } from 'app/models/giftcard';




@Component({
  selector: 'app-gift-card-modal',
  templateUrl: './gift-card-modal.component.html',
  styleUrls: ['./gift-card-modal.component.sass']
})
export class GiftCardModalComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<GiftCardModalComponent>, ) { }


  onConfirm(): void {
    // Close the dialog, return true
    console.log(this.data)
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }

  ngOnInit(): void {
  }

}

