import { Component, Input } from "@angular/core";
import { type InsuranceDetails } from "../../models/insurance-details.model";
import { NG_VALUE_ACCESSOR, UntypedFormControl } from "@angular/forms";
import { ValueAccessorBase } from "../value-accessor-base";
import { DataService } from "app/services/data.service";
@Component({
  selector: "insurance-chooser",
  templateUrl: "./insurance-chooser.component.html",
  styleUrls: ["./insurance-chooser.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: InsuranceChooserComponent,
      multi: true,
    },
  ],
})
export class InsuranceChooserComponent extends ValueAccessorBase<string> {
  @Input() id;
  @Input() formControlName;
  @Input() form;

  insuranceChooser: UntypedFormControl;
  public allInsurances: any;
  public currentInsurance: any;
  resultsListPos: any;

  constructor(private http: DataService) {
    super();
  }

  ngOnInit() {
    this.insuranceChooser = new UntypedFormControl(this.id);
    this.fetchData().subscribe((res) => {
      this.allInsurances = res;
    });
  }

  fetchData() {
    return this.http.get<InsuranceDetails[]>(`/insurers/list`);
  }

  fetchInsurance(insuranceIdx) {
    return this.http.get<any>(`/insurers/${insuranceIdx}`);
  }
}
