<mat-toolbar *ngIf='reportDetails'>
    <h4>{{reportDetails.friendlyName}}</h4>

  <div class="toolbar-spacer"></div>
 
  <div *ngFor="let quickDateFilter of quickDateFilters">
    <app-custom-date-range [quickDateFilter]="quickDateFilter"></app-custom-date-range>
  </div>

  <div *ngIf="reportName === 'AppointmentsAMonth'" class="yearselection">
    <button mat-button class="me-5" [matMenuTriggerFor]="yearFromMenu" class="yearfrom-menu" aria-label="Example icon-button with a year from menu">
      <mat-icon>date_range</mat-icon>
      <span>From {{ yearFrom ? yearFrom : 'Year' }}</span>
    </button>

    <mat-menu #yearFromMenu="matMenu">
      <button mat-menu-item *ngFor="let yearFrom of yearsFrom" (click)="selectYear(yearFrom, true, false)">
        {{ yearFrom }}
      </button>
    </mat-menu>

    <button mat-button class="me-5" [matMenuTriggerFor]="yearToMenu" class="yearto-menu" aria-label="Example icon-button with a year to menu">
      <mat-icon>date_range</mat-icon>
      <span>Until {{ yearTo ? yearTo : 'Year' }}</span>
    </button>

    <mat-menu #yearToMenu="matMenu">
      <button mat-menu-item *ngFor="let yearTo of yearsTo" (click)="selectYear(yearTo, false, true)">
        {{ yearTo }}
      </button>
    </mat-menu>
  </div>
 
  <div *ngIf="staffFilter">
    <button mat-button class="me-5" [matMenuTriggerFor]="staffMenu" class="staff-menu" aria-label="staff menu" (menuOpened)="staffMenuOpened()" #staffMenuTrigger="matMenuTrigger">
      <mat-icon>group</mat-icon>
      <span>{{ selectedStaff ? capitalize(selectedStaff.firstname) + " " + capitalize(selectedStaff.lastname) : 'Staff Member' }}</span>
    </button>

    <mat-menu #staffMenu="matMenu">
    <app-staff-chooser (click)="stopPropagation($event)" (valueChange)="selectStaffMember($event)" [shown]="staffMenuShown"></app-staff-chooser>
     <!--<button mat-menu-item *ngFor="let member of staff" (click)="selectStaffMember(member)">
        {{ capitalize(member.firstname) }} {{ capitalize(member.lastname) }}
      </button>
    -->
    </mat-menu>
  </div>

  <div *ngIf="clinicFilter">
    <button mat-button class="me-5" [matMenuTriggerFor]="clinicMenu" class="staff-menu" aria-label="Example icon-button with a clinic menu">
      <mat-icon>group</mat-icon>
      <span>{{ selectedClinic ? selectedClinic.name : 'Clinic' }}</span>
    </button>

    <mat-menu #clinicMenu="matMenu">
      <button mat-menu-item *ngFor="let clinic of clinics" (click)="selectClinic(clinic)">
        {{ clinic.name }}
      </button>
    </mat-menu>
  </div>

  <button mat-stroked-button  
    *ngIf="graphRequired"
    name="export-button" 
    class="me-5"
    (click)="chartVisible ? destroyChart() : createChart()">
    <mat-icon aria-hidden="false" aria-label="Show/Hide graph">insights</mat-icon> {{ chartVisible ? 'Hide' : 'Show' }} graph
  </button>
 
  <button mat-stroked-button  
    *ngIf="reportDetails.rowModelType == 'clientSide'"
    name="export-button" 
    class="me-5"
    (click)="exportReport()">
    <mat-icon aria-hidden="false" aria-label="Export report">forward</mat-icon>Export
  </button>

  <button mat-stroked-button  
    name="add-button" 
    class="me-5"
    [ngClass]="{'mat-flat-button' : filtersActive,
                'mat-button-base' : filtersActive,
                'mat-accent' : filtersActive,
                'text-muted' : !filtersActive}"
    (click)="clearFilters()">
    <mat-icon aria-hidden="false" aria-label="Clear filters">clear</mat-icon>Clear Filters
  </button>

  <div *ngIf="reportDetails.addNewEnabled && (makeForm || makeLetter || poPopup)">
    <button mat-stroked-button
      color="accent" 
      name="add-button" 
      (click)="add()">
      <mat-icon aria-hidden="false" aria-label="Add new">add</mat-icon>Add New
    </button>
  </div>

  <div *ngIf="reportName === 'commissionUnpaid' || reportName === 'commissionUnpaidCredits'">
    <button mat-stroked-button color="accent" name="add-button" (click)="writeCommissionPO()">
      <mat-icon aria-hidden="false" aria-label="Add new">add</mat-icon>Write Commission PO
    </button>
  </div>
</mat-toolbar>

<div class="main">
  <div id="chart" *ngIf="graphRequired" [ngClass]="chartVisible ? 'chartOpening' : 'chartResting'" class="bg-white"></div>
    <div class="grid-container" id="grid-container" [ngClass]="(chartVisible && graphRequired) ? 'height-fix-with-graph' : 'height-fix-without-graph'">
    <ag-grid-angular #agGrid 
    *ngIf="gridActivated"
    id="myGrid" 
    style="height: 100%;"
    [ngClass]="{'ag-theme-alpine-dark' : darkMode, 'ag-theme-alpine' : !darkMode}" 
    [gridOptions]="gridOptions" 
    [columnDefs]="columnDefs"
    [sideBar]="sideBar"
    (gridReady)="onGridReady($event)"
    (firstDataRendered)="onFirstDataRendered($event)"
    (columnVisible)="saveReportFilters()"
    [enableCharts]="true"
    [groupIncludeFooter]="true"
    [groupIncludeTotalFooter]="true"
    [rowData]="rowData"
    [getChartToolbarItems]="getChartToolbarItems"
    ></ag-grid-angular>
  </div> 
</div>
