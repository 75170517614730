<div class="diary-container">
  <div class="container-fluid main-container" style="height: 100%">
    <div class="lhs-wrapper">
      <div class="lhs-calendar-wrapper" [ngClass]="mobileView ? 'p-0' : ''">
        <app-grid-item  [data]="fixedTiles[0]" style="height:100%; width:100%;"></app-grid-item>
      </div>

      <div 
        class="grid-item-wrapper" *ngIf="!mobileView">
        <app-grid-item  [data]="fixedTiles[1]" style="height:100%; width:100%;"></app-grid-item>
      </div>
    </div>


    <div class="tile-wrapper"
      *ngIf="tiles">

      <mat-grid-list 
        class="mat-grid-list"
        [cols]="cols" 
        gutterSize="10px" 
        rowHeight="fit"
      >
        <mat-grid-tile 
        *ngFor="let tile of tiles"
        class="grid-tile"
        [colspan]="tile.cols" 
        [rowspan]="tile.rows"
        >

        <app-grid-item [data]="tile" class="grid-item"></app-grid-item>

        </mat-grid-tile>
      </mat-grid-list>
    </div>

   
  </div>
</div>
