import { NgModule } from "@angular/core";
import { DiaryActionsComponent } from "./diary-actions.component";
import { AppointmentSetStatusComponent } from "./appointment-set-status/appointment-set-status.component";
import { BrowserModule } from "@angular/platform-browser";
import { MyMaterialModule } from "../../../../material/material.module";
import { FormsModule } from "@angular/forms";
import { CommonModule } from "@angular/common";
import { DiaryService } from "../../../../services/diary/diary.service";
import { AppointmentActionsComponent } from "./appointment-actions/appointment-actions.component";



@NgModule({
    imports: [
        BrowserModule,
        CommonModule,
        MyMaterialModule,
        FormsModule
    ],
    declarations: [
        AppointmentSetStatusComponent,
        AppointmentActionsComponent,
        DiaryActionsComponent
    ],
    providers: [
        DiaryService
    ],
    exports: [
        DiaryActionsComponent,
    ]
})
export class DiaryActionsModule { };
