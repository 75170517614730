<div *ngIf="appointmentActions">
  <div [ngClass]="styleType">
    <div *ngFor="let action of appointmentActions.statusActions" 
      [ngSwitch]="action"
      >

      <hr *ngSwitchCase="actions.HR" />

      <appointment-set-status 
        *ngSwitchCase="actions.MARK_ARRIVED" 
        [appointment]="appointment" 
        [icon]="'how_to_reg'" 
        [styleType]="styleType"
        [action]="action" 
        [toStatus]="'Arrived'" 
        (clicked)="execute($event)"></appointment-set-status>
      <appointment-set-status *ngSwitchCase="actions.MARK_IN_TREATMENT" [appointment]="appointment" [styleType]="styleType"
        [icon]="'local_hospital'" [action]="action" [toStatus]="'InTreatment'" (clicked)="execute($event)">
      </appointment-set-status>
      <!-- DOM SAID WAS NOT NEEDED AS AUTO CHANGED WHEN SOAP FILLED IN, what if the person does not use SOAP though? -->
      <!-- <appointment-set-status *ngSwitchCase="actions.MARK_END_TREATMENT" [appointment]="appointment" [styleType]="styleType" 
        [icon]="'fad fa-calendar-check'" [action]="action" [toStatus]="'Complete'" (clicked)="execute($event)">
      </appointment-set-status> -->
      <appointment-set-status *ngSwitchCase="actions.MARK_BOOKING" 
        [appointment]="appointment" 
        [styleType]="styleType"
        [icon]="'undo'" 
        [action]="action" 
        [toStatus]="appointmentStatus.BOOKING" 
        (clicked)="execute($event)"></appointment-set-status>

      <appointment-set-status *ngSwitchCase="actions.MARK_CANCELLED" 
        [appointment]="appointment" 
        [styleType]="styleType"
        [icon]="'delete'" 
        [action]="action" 
        [toStatus]="appointmentStatus.CANCELLED"
        (clicked)="execute($event)"></appointment-set-status>

      <appointment-set-status *ngSwitchCase="actions.MARK_DNA" 
        [appointment]="appointment" 
        [icon]="'not_interested'" 
        [styleType]="styleType"
        [action]="action" 
        [toStatus]="appointmentStatus.NOSHOW" 
        (clicked)="execute($event)"></appointment-set-status>     

      <appointment-actions *ngSwitchCase="actions.TAKE_PAYMENT" 
        [action]="action" 
        [icon]="'payment'" 
        [styleType]="styleType"
        [colour]="'#00aa00'" 
        (clicked)="execute($event)"></appointment-actions>

      <appointment-actions *ngSwitchCase="actions.SHOW_INVOICE" 
        [action]="action" 
        [icon]="'insert_drive_file'" 
        [styleType]="styleType"
        [colour]="'#00aa00'" 
        (clicked)="execute($event)"></appointment-actions>
      
      
      <appointment-set-status *ngSwitchCase="actions.ACCEPT_BOOKING" 
        [appointment]="appointment" 
        [action]="action" 
        [icon]="'check_circle'" 
        [styleType]="styleType"
        (clicked)="execute($event)"
        [toStatus]="appointmentStatus.BOOKING"></appointment-set-status>

      <appointment-set-status *ngSwitchCase="actions.REJECT_BOOKING" 
        [appointment]="appointment" 
        [action]="action" 
        [icon]="'cancel'" 
        [styleType]="styleType"
        [toStatus]="appointmentStatus.CANCELLED"
        (clicked)="execute($event)"></appointment-set-status>

    </div>
  </div>
 
  <div>
    <div *ngFor="let action of appointmentActions.actions" [ngSwitch]="action">
      <hr *ngSwitchCase="actions.HR" />
      <ng-container *ngIf="appointment && appointment.meta.repeatVisitDays && appointment.meta.repeatVisitDays !== -1">
        <appointment-actions *ngSwitchCase="actions.REBOOK" [action]="action" [icon]="'person_add'"
        [colour]="'#ffff00'" (clicked)="execute($event)"></appointment-actions>
      </ng-container>
      <appointment-actions *ngSwitchCase="actions.SHOW_INVOICE" [action]="action" [icon]="'insert_drive_file'"
        [colour]="'#eeee00'" (clicked)="execute($event)"></appointment-actions>
      <appointment-actions *ngSwitchCase="actions.SHOW_SOAP" [action]="action" [icon]="'sanitizer'"
        [colour]="'#cccc00'" (clicked)="execute($event)"></appointment-actions>
      <appointment-actions *ngSwitchCase="actions.SHOW_PHYSICAL_EXAMS" [action]="action" [icon]="'scale'"
        [colour]="'#cccc00'" (clicked)="execute($event)"></appointment-actions>
      <appointment-actions *ngSwitchCase="actions.SHOW_CASE_HISTORY" [action]="action" [icon]="'history_edu'"
        [colour]="'#cccc00'" (clicked)="execute($event)"></appointment-actions>
      <appointment-actions *ngSwitchCase="actions.SHOW_PATIENT_INFO" [action]="action" [icon]="'person_outline'"
        [colour]="'#dddd00'" (clicked)="execute($event)"></appointment-actions>
      <appointment-actions *ngSwitchCase="actions.DNA_CHARGE" [action]="action" [icon]="'monetization_on'"
        [colour]="'#cccc00'" (clicked)="execute($event)"></appointment-actions> 
      <appointment-actions *ngSwitchCase="actions.SHOW_FUTURE_APPOINTMENTS" [action]="action" [icon]="'book_online'"
        [colour]="'#cccc00'" (clicked)="execute($event)"></appointment-actions> 
      <appointment-actions *ngSwitchCase="actions.ATTENDANCE" [action]="action" [icon]="'auto_stories'"
        [colour]="'#00aa00'" (clicked)="execute($event)"></appointment-actions>  
      <appointment-actions *ngSwitchCase="actions.EDIT" [action]="action" [icon]="'edit'"
        [colour]="'#0000aa'" (clicked)="execute($event)"></appointment-actions>
    </div>
  </div>
</div>