import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dynamic-form-list',
  templateUrl: './dynamic-form-list.component.html',
  styleUrls: ['./dynamic-form-list.component.css']
})
export class DynamicFormListComponent implements OnInit {
  public forms = [
    {title: "Patient Details", link: "/form/patientdetails/26", subItems: false},
    {title: "Staff", link: "/form/staff/26", subItems: false}
  ]
  constructor() { }

  ngOnInit() {
  }

}
