import { Component, SimpleChanges, Input, Output, EventEmitter, OnInit, OnChanges } from "@angular/core";
import { ProcessedAppointment } from "../../../../../models/appointments";

@Component({
    selector: "appointment-actions",
    templateUrl: "./appointment-actions.component.html",
    styleUrls: ["../appointment-set-status/appointment-set-status.component.scss"],
})


export class AppointmentActionsComponent implements OnInit, OnChanges {
    @Input() styleType = "context-list";
    @Input() action: string;
    @Input() toStatus: string;
    @Input() icon: string;
    @Input() colour: string;
    @Output() clicked = new EventEmitter<string>();

    constructor() { }

    ngOnInit(): void { }

    ngOnChanges(changes: SimpleChanges): void { }

    clickAction() {
        this.clicked.emit(this.action);
    }
}
