import { Injectable } from '@angular/core';
import { Location } from '@angular/common';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';

@Injectable({ providedIn: 'root' })
export class NavigationService {
  private history: string[] = [];
  private navInFlight = false; // are we currently navigating?

  constructor(private router: Router, private location: Location) {
    this.router.events.subscribe((event) => {
      console.log("[navigate] EVENT", event);

      if (event instanceof NavigationStart){
        this.navInFlight = true;
      }

      if (event instanceof NavigationEnd) {
        this.history.push(event.urlAfterRedirects);
        console.log("[navigate] history + ", this.history);
        this.navInFlight = false;
      }
    });
  }

  getHistory(): string[] {
    return this.history;
  }

  /**
   * Navigate back one step
  */

  back(): void {
    console.log("[navigate] back", this.history);
    if (!this.navInFlight) {
      this.history.pop();  // the top element here contains the 'current page', unless we are navigating.
    }

    if (this.history.length > 0) {
      let route = this.history.pop();
      if (!route.includes('/pay')) {
        this.router.navigateByUrl(route);
      } else {
        route = this.history.pop();
        this.router.navigateByUrl(route);
      }
    } else {
      this.router.navigateByUrl('/');
    }
  }
}