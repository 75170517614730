
<mat-icon *ngIf="locked && lockable" title="section locked">lock</mat-icon>

<button mat-button (click)="showSignOff=!showSignOff" class="ms-2">
    <mat-icon *ngIf="!signatures || !signatures.signatures || signatures.signatures.length==0; else isSigned">rate_review</mat-icon>
    <ng-template #isSigned>


        <div *ngIf="signatures.signatures[0].signatureTitle == SignatureTypes.SignOff && signatures.signatures[0].locked; else notSignOff" class="text-warning">
            <mat-icon class="text-warning" matBadge="{{signatures.signatures.length}}">reviews</mat-icon>
            <span class="text-warning ps-3">
                Signed off by <strong>{{ signatures.signatures[0].userName }}</strong> on {{ signatures.signatures[0]?.DateAdded | date : "short" }}
            </span>
        </div>

        <ng-template #notSignOff>
            <mat-icon matBadge="{{signaturesCount}}">reviews</mat-icon>
            <span class="text-muted ps-3">
                Change requested by <strong>{{ signatures.signatures[0].userName }}</strong> on {{ signatures.signatures[0]?.DateAdded | date : "short" }}
            </span>
        </ng-template>

    </ng-template>

</button>

<div *ngIf="recordIdx>0; else notstarted">
    <app-signing *ngIf="showSignOff" 
        [signatures]="signatures"
        [lockable]="lockable"
        (onLock)="onLock($event)"
        (onSign)="onSign($event)"
        (onRequestChanges)="onRequestChanges($event)"></app-signing>

    <div class="alert alert-warning" *ngIf="signOffError && showSignOff">{{signOffError}}</div>

    <app-lock *ngIf="showSignOff" 
        [signatures]="signatures"
        [recordIdx]="recordIdx"
        [lockable]="lockable"
        (onLock)="onLock($event)"
    ></app-lock>
</div>

<ng-template #notstarted>
    <p *ngIf="showSignOff" class="alert alert-warning">Sign off unavailable as section not yet completed</p>
</ng-template>