import { Component } from '@angular/core';
import { ICellRendererAngularComp } from 'ag-grid-angular';


@Component({
  selector: 'floating-cell',
  template: `<span style="font-weight: bold; padding-top: 8px;">{{ params.valueFormatted || params.value }}</span>`,
})
export class CustomPinnedRowRenderer implements ICellRendererAngularComp {
  public params: any;
  

  agInit(params: any): void {
    this.params = params;
    console.log("CD FORMATTER");
  }

  refresh(): boolean {
    return false;
  }
}
