import { Injectable } from '@angular/core';



@Injectable()
export class SubMainMenuService {
    constructor() { }

    private readonly accountRoutes = [
        {
            header: 'Purchases', routes:
                [{ name: 'List PO', route: 'report/POs' },
                { name: 'Write PO', route: 'null' },
                { name: 'Company Accounts', route: 'report/companies' },
                { name: 'Account Members', route: 'report/AccountMembers' }]
        },
        {
            header: 'Invoices', routes:
                [{ name: 'List Invoices', route: 'report/invoices' },
                { name: 'Invoices By Staff Member', route: 'report/invoicesByStaff' },
                { name: 'Invoice Insurance Company', route: 'report/insuranceUnpaid' },
                { name: 'Accounts/Insurance Balances', route: 'report/unbilledAccounts' }]
        },
        {
            header: 'Other', routes:
                [{ name: 'Commissions', route: '/home/commision' },
                { name: 'Stock Items', route: 'null' },
                { name: 'View Gift Vouchers', route: 'report/AllGiftVouchers' },
                { name: 'Print Gift Voucher', route: 'report/GiftVouchers' }]
        }
    ];

    private readonly commisionRoutes = [
        {
            header: 'Commisions', routes:
                [{ name: 'Unpaid Commisions (Cash)', route: 'report/commissionUnpaid' },
                { name: 'Unpaid Commisions (Credits)', route: 'report/commissionUnpaidCredits' },
                { name: 'Write Commission Invoice', route: 'report/nonbilled' }]
        }
    ];

    private readonly patientRoutes = [
        {
            header: 'Information', routes:
                [{ name: 'Patient Info', route: 'report/persondetail' },
                { name: 'Phone Book', route: 'report/dialer' },
                { name: 'Quick Directions', route: 'report/directions' },
                { name: 'Practioner Schedule', route: 'report/FindAppointments' },
                { name: 'Future Appointments', route: 'report/PrintAppointments' },
                { name: 'Birthdays', route: 'report/birthdays' },
                { name: 'Follow Up Needed', route: 'report/FollowUps' }]
        },
        {
            header: 'Forms', routes:
                [{ name: 'New Patient Forms', route: 'report/NewPatients' },
                { name: 'Treatment Cards', route: 'null' }]
        },
        {
            header: 'Advanced', routes:
                [{ name: 'Patient Record Merger', route: 'null' },
                { name: 'Patient Payments Report', route: 'report/PatientPayments' },
                { name: 'Patient Statement', route: 'report/statement' }]
        }
    ];

    private readonly reportRoutes = [
        {
            header: 'Appointments', routes:
                [{ name: 'Appointments', route: 'home/appointment' }]
        },
        {
            header: 'Financial', routes:
                [{ name: 'Financial', route: 'home/financial' }]
        }
    ];

    private readonly appointmentRoutes = [
        {
            header: 'Appointments', routes:
                [{ name: 'Appointments Per Week', route: 'report/AppointmentsAWeek' },
                { name: 'Appointments Per Month', route: 'report/AppointmentsAMonth' },
                { name: 'Appointments Per Patient', route: 'report/NumberOfAppointments' },
                { name: 'Appointments By Staff', route: 'report/AppointmentsAWeekByPract' },
                { name: 'Cancelled Appointments', route: 'report/CancelledAppointments' },
                { name: 'DNA Report', route: 'report/DNAreport' }]
        },
        {
            header: 'Patients', routes:
                [{ name: 'PVA Weekly', route: 'report/PVAWeekly' },
                { name: 'PVA Monthly', route: 'report/PVAMonthly' },
                { name: 'New Patients', route: 'report/NewPatientsAWeek' },
                { name: 'Inactive Patients', route: 'report/InactivePatients' },
                { name: 'Waiting Times', route: 'report/WaitingTimes' }]
        },
        {
            header: 'Other', routes:
                [{ name: 'Channel By Category', route: 'null' },
                { name: 'Channel By Sub-Category', route: 'report/hearabout' },
                { name: 'Channel By Referall', route: 'report/ChannelByReferal' },
                { name: 'SMS Report', route: 'report/SMS' }]
        }
    ];

    private readonly FinancialRoutes = [
        {
            header: 'Financial', routes:
                [{ name: 'New Client Analysis', route: 'report/NewClientAnalysis' },
                { name: 'Appointment Income Projection', route: 'report/AppointmentIncomeProjection' },
                { name: 'Treatement Sales', route: 'report/TreatmentSales' },
                { name: 'Team Audit', route: 'report/invoicesByStaff' },
                { name: 'X-Report', route: 'report/invoicesByMethod' }]
        }
    ];

    private readonly settingRoutes = [
        {
            header: 'Program Settings', routes:
                [{ name: 'Change Password', route: 'null' },
                { name: 'P & L Categories', route: 'report/PandLCat' },
                { name: 'P & L Sub-Categories', route: 'report/PandLSubCat' },
                { name: 'Payment Methods', route: 'report/paymentmethods' },
                { name: 'Hear About Us Categories', route: 'report/HearAboutUsCat' },
                { name: 'Hear About Us Sub-Categories', route: 'report/HearAboutUsSubCat' },
                { name: 'Edit Menu', route: 'null' },
                { name: 'User Acess', route: 'report/users' },
                { name: 'User Groups', route: 'report/UserGroupNames' }]
        },
        {
            header: 'Clinic Details', routes:
                [{ name: 'Clinic Details', route: 'report/clinicdetails' },
                { name: 'Room Details', route: 'report/room' },
                { name: 'Staff Details', route: 'report/staffdetails' },
                { name: 'GP Details', route: 'report/GPDetails' },
                { name: 'GPs Practice', route: 'report/GPPractices' },
                { name: 'Advanced Settings', route: 'report/AdvancedSettings' }]
        },
        {
            header: 'Financial', routes:
                [{ name: 'Stock Items', route: 'null' },
                { name: 'Treatements', route: 'null' },
                { name: 'Staff Commission', route: 'null' },
                { name: 'VAT Rates', route: 'report/VATRates' }]
        }
    ];

    private readonly creditRoutes = [
        {
            header: 'Pricing', routes:
                [{ name: 'Credit Pricing', route: 'report/CreditPricing' }]
        },
        {
            header: 'Patient Credit Balances', routes:
                [{ name: 'Transfer Credit Between Accounts', route: 'null' },
                { name: 'Buy Credits', route: 'report/BuyCredits' },
                { name: 'Adjust Patient Credit Balance', route: 'report/CreditAdjust' },
                { name: 'Adjust Patient Credit Value', route: 'report/CreditValuation' }]
        },
        {
            header: 'Reports', routes:
                [{ name: 'Credits Report', route: 'report/ClientCredits' }]
        }
    ];

    private readonly letterRoutes = [
        {
            header: 'Letters', routes:
                [{ name: 'Edit Template', route: 'report/MyLetters' },
                { name: 'Write Letter', route: 'report/PrintLetters' }]
        },
    ];

    getRoutes(page: string) {
        switch (page) {
            case 'accounts':
                return this.accountRoutes;
            case 'reports':
                return this.reportRoutes;
            case 'financial':
                return this.FinancialRoutes;
            case 'appointment':
                return this.appointmentRoutes;
            case 'settings':
                return this.settingRoutes;
            case 'credits':
                return this.creditRoutes;
            case 'letters':
                return this.letterRoutes;
            case 'patients':
                return this.patientRoutes;
            case 'commision':
                return this.commisionRoutes;
            default:
                return [];
        }
    }
}

