import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { DataService } from "./data.service";

@Injectable({
    providedIn: "root"
})
export class QRService {

    constructor(private http: DataService) {        
    }
    
    getImage(text:string) : Observable<string>{
        return this.http.get<{qr:string}>(`/qr?text=${text}`).pipe(map(res => {
            return res.qr;
        }));
    }


}
