import { Injectable } from '@angular/core';
import { of, Observable, BehaviorSubject } from 'rxjs';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Injectable()
export class FilePickerService {
  constructor(private sanitizer: DomSanitizer) { }

  private displayItemTemplate = new BehaviorSubject<boolean>(true);
  displayItemTemplate$ = this.displayItemTemplate.asObservable();

  mockUploadFile(formData): Observable<any> {
    const event = new CustomEvent('customevent', {
      detail: {
        type: 'UploadProgreess'
      }
    });
    return of (event.detail);
  }

  // @ts-ignore: Not all code paths return a value
  createSafeUrl(file): SafeResourceUrl {
    try {
      const url = window.URL.createObjectURL(file);
      const safeUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      return safeUrl;
    } catch (er) {
      console.log(er);
    }
  }

  setDisplayItemTemplate(display: boolean) {
    this.displayItemTemplate.next(display);
  }
}
