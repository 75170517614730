import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { fieldClasses } from 'app/dynamic-form/question-base';
import { FormTemplateBaseComponent } from '../form-template-base/form-template-base.component';

@Component({
  selector: 'app-form-chooser',
  templateUrl: './form-chooser.component.html',
  styleUrls: ['./form-chooser.component.css']
})
export class FormChooserComponent  extends FormTemplateBaseComponent {
  @Output() addNew = new EventEmitter;
  @Output() valueChange = new EventEmitter;
  @Input() value: string;

  public fieldClasses = fieldClasses;

  public valueChanged(id)
  {
    console.log("[choose] fcc valueChanged() new value = ", id, this.question.meta.addNew );
    this.valueChange.emit(id);
    if (id == 0 && this.question.meta.addNew){
      this.addNew.emit(this.question.meta.addNew);
    }
  }


}
