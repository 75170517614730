import { Inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as auth from '../models/auth';
import * as authActions from '../store/actions/auth';
import * as fromRoot from '../store/reducers';
import * as config from '../models/config';
import * as configActions from '../store/actions/config'
import { filter, map, take } from 'rxjs/operators';
import { MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { DataService } from './data.service';
import { HttpHeaders } from "@angular/common/http";
import { AuthenticationResult, InteractionType, PopupRequest } from '@azure/msal-browser';
import { AsyncSubject, BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { Router } from '@angular/router';
import { LoadingService } from './loading.service';
// import { LogglyLoggerService } from './error.handler.service';
import { AppointmentsService } from './diary/appointments.service';
import { InitalisationService } from './initialisation-service';


@Injectable()
export class AuthService {
  private refreshTokenTimer$: ReturnType<typeof setInterval>;
  private lastJwt;

  constructor(
    private store: Store<fromRoot.State>,
    @Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
    private msalService: MsalService,
    private http: DataService,
    private router: Router,
    private loading: LoadingService ,
    private appointment: AppointmentsService,
    private initService: InitalisationService
    ) {  
      if ( this.isLoggedIn() ) this.setRefreshTokenTimer(60000); //start 60 sec refresh if we have a valid token.
    }

  private setRefreshTokenTimer(frequency: number): void {
    if ( !this.refreshTokenTimer$ ) {
      this.refreshTokenTimer$ = setInterval(() => {
        console.log("[refresh token]");
        if ( this.getJWT() ) this.renewToken();
      }, frequency);
    } 
  }

  public clearRefreshTokenTimer () {
    if ( this.refreshTokenTimer$ ) clearInterval(this.refreshTokenTimer$);
  }

  getAuthState(): auth.State {
    let state: auth.State;
    this.store.pipe(take(1)).subscribe(s => state = s.auth);
    return state;
  }

  jwt(): Observable<string>{
    return this.store.select(fromRoot.getJWT);
  }

  getJWT(): string {
    let jwt: string;
    this.store.select(fromRoot.getJWT).pipe(take(1)).subscribe(s => jwt = s);
    return jwt;
  }

  setStateFromJWT(state: auth.State) {
    this.store.dispatch(authActions.UpdateAction({payload: state}));
  }

  storeConfig(config: config.State) {
    this.store.dispatch(configActions.SetConfig({payload: config}))
  }
/*
  isCurrentlyLoggedIn(): Observable<string> {
    return this.store.select(fromRoot.getJWT).pipe(filter(f => f!=null && !this.lastJwt),
    map(res=>{
      this.lastJwt = res;
      return res;
    } ));
  }
*/
  isLoggedIn(): boolean{
    if (this.getJWT() && this.getJWT() != "") return true;
    return false;
  }

  public logout(): void {
    this.clearRefreshTokenTimer();
    const account = this.msalService.instance.getAllAccounts()[0];
    this.appointment.deleteDB();
    this.initService.resetRequirements();
    if ( account ){
      this.logOutWithSSO();
    } 
    else{
      this.logoutLocal();
    }
  }

  private logoutLocal(): void {
    this.store.dispatch(authActions.LogoutAction());
  }

  public renewToken(){
    this.store.dispatch(authActions.RefreshAction());
  }

  profileImage(){
    const state = this.getAuthState();
    return state.meta.photo;
  }

  personId(){
    const state = this.getAuthState();
    return state.meta.personId;
  }

  staffId() {
    const state = this.getAuthState();
    return state.meta.staffId;
  }

  supervisor(): boolean{
    const state = this.getAuthState();
    return state.permissions.notesSupervisor;
  }

  userId() {
    const state = this.getAuthState();
    return state.meta.userId;
  }
  getStaffName() : string{
    const state = this.getAuthState();
    return `${state.meta.firstname} ${state.meta.lastname}`;
  }
/*
  groupPermissions(permission: string): boolean {
    const state = this.getAuthState();
    if (!state) return false;
    if (!state.permissions) return false;
    return state.permissions[permission] ? state.permissions[permission] : false;
  }
*/
  public permission(require: auth.AvailablePermissions): boolean {
    let permissions: auth.Permissions = auth.initialPermissions;
    console.log("[pm] ??");
    this.store.select(fromRoot.getPermissions).pipe(take(1)).subscribe((perms: auth.Permissions) => permissions = perms);
    console.log("[pm]", permissions, require);
    return permissions[require];
  }

  public authenticate(companyName: string, username: string, password: string, keepAlive: boolean): void {
    this.store.dispatch(authActions.LoginAction({ payload: { companyName, username, password, keepAlive } }));
  }

  public authenticateCode(companyName: string, username: string, password: string, tfa: string): void {
    this.store.dispatch(authActions.LogInWithCodeAction({ payload: { companyName, username, password, tfa } }));
  }

  public authenticateWithSSO(): void {
//    this.logger.log("authenticateWithSSO()");
    this.loading.start();
    this.store.dispatch(authActions.LogInSSOAction());
  }

  public async logInWithSSO(): Promise<Observable<AuthenticationResult>> {
   // this.logger.log("logInWithSSO()");
    await this.msalService.instance.initialize();
    await this.msalService.instance.handleRedirectPromise();   
    if (this.msalGuardConfig.authRequest) {
      return this.msalService.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest);
    } else {
      return this.msalService.loginPopup();
    }
  }

  public async logOutWithSSO(): Promise<void> {
 //   this.logger.log("logOutWithSSO()");
    console.log("Logging out with SSO!!");
    await this.msalService.instance.initialize();
    await this.msalService.instance.handleRedirectPromise();
    this.msalGuardConfig.interactionType = InteractionType.Popup;
    
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      this.msalService.logoutPopup({}).subscribe((logOutSuccess: any) => this.logoutLocal());
    } else {
      this.msalService.logoutRedirect({}).subscribe((logOutSuccess: any) => this.logoutLocal());
    }
  }

  public retrieveToken(): void {
    const account = this.msalService.instance.getAllAccounts()[0];
  //  this.logger.log("retrieveToken()", account);
    const accessTokenRequest = {
        scopes: ["user.read"],
        account: account
    }

    this.msalService.acquireTokenSilent(accessTokenRequest).subscribe(tokenResponse => {
  //    this.logger.log("acquireTokenSilent()", tokenResponse);
      if ( tokenResponse.idToken ) {
        const headers = new HttpHeaders().set('Authorization', `Bearer ${tokenResponse.idToken}`);

        this.http.post("/auth/o365", { token: tokenResponse.idToken }, { headers: headers })
          .subscribe(
            ( userState: auth.State ) => {
       //       this.logger.log("auth state", auth.State);
              if ( userState.jwt ) this.afterLogin(userState);
            },
            err => {
              //this.store.dispatch(authActions.)
              this.authFailed();
            });
      }
    });
  }

  public authFailed(){
    this.loading.stop();
  }

  public afterLogin(userState: auth.State): void {
      this.setRefreshTokenTimer(60000);
      this.setStateFromJWT(userState);     
      this.router.navigate(['/loading']);
  }

  hasMenuAccess(url: string): boolean {
    let found;
    this.store.select(fromRoot.getFilteredMenu).pipe(take(1)).subscribe(res => {
        found = res.find(f=> {return f.link == url});
    });    
    if (found){
        return true;
    }
    return false;
  }

  public getPinCode(): Observable<string> {
    return this.http.get("/users/pin");
  }

  public updatePinCode(payload: any): Observable<any> {
    return this.http.post("/users/pin", payload);
  }
}

