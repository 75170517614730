<div class="row margin-collapse">
    <div class="col-lg-10">
        <mat-card><mat-card-content>
            <h4>Practitioner Works Matrix</h4>
            <div class="matrix-description">Select the clinics that each member of staff is active at. This controls there availability in the diary as well as affecting security settings as to what they can see.</div>

            <ng-container *ngIf="matrix && matrix.worksAt">
                <table class="table">
                    <thead>
                        <tr>
                            <th></th>
                            <th *ngFor="let clinic of clinics">{{ clinic.name }}</th>
                        </tr>
                    </thead>
                
                    <tbody>
                        <tr *ngFor="let member of staff">
                            <td>{{ member.firstname }} {{ member.lastname }}</td>
                            <td *ngFor="let clinic of clinics">
                                <mat-checkbox [checked]="getValue(member, clinic, 'worksAt')" (change)="valueChanged($event.checked, member, clinic, 'worksAt')"></mat-checkbox>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ng-container>
            
            <h4>Practitioner Commission Matrix</h4>
            <div class="matrix-description">Enter the commission payable on any work undertaken. This is the percentage that is kept by the treating practitioner.</div>
            
            <ng-container *ngIf="matrix && matrix.commission">
                <table class="table commission-table">
                    <thead>
                        <tr>
                            <th></th>
                            <th *ngFor="let clinic of clinics">{{ clinic.name }}</th>
                        </tr>
                    </thead>
                
                    <tbody>
                        <tr *ngFor="let member of staff">
                            <td>{{ member.firstname }} {{ member.lastname }}</td>
                            <td *ngFor="let clinic of clinics">
                                <input type="text" class="form-control" [value]="getValue(member, clinic, 'commission')" [disabled]="!getValue(member, clinic, 'worksAt')" (change)="valueChanged($event.target.value, member, clinic, 'commission')">
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ng-container>
            
            <h4>Practitioner Bills Matrix</h4>
            <div class="matrix-description">Practitioner bills go to Profit and Loss. Ticked if the practitioner works at a clinic and is paid commission. Untick if the practitioner is freelance at a clinic i.e. they work there, take their own money then pay commission or room rent.</div>
            
            <ng-container *ngIf="matrix && matrix.billsAt">
                <table class="table">
                    <thead>
                        <tr>
                            <th></th>
                            <th *ngFor="let clinic of clinics">{{ clinic.name }}</th>
                        </tr>
                    </thead>
                
                    <tbody>
                        <tr *ngFor="let member of staff">
                            <td>{{ member.firstname }} {{ member.lastname }}</td>
                            <td *ngFor="let clinic of clinics">
                                <mat-checkbox [checked]="getValue(member, clinic, 'billsAt')" [disabled]="!getValue(member, clinic, 'worksAt')" (change)="valueChanged($event.checked, member, clinic, 'billsAt')"></mat-checkbox>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </ng-container>
        </mat-card-content></mat-card>
    </div>

    <div class="col-sm-2 no-print mobile-margin">
        <div class="fix-top">
            <div class="mb-3">
                <div class="my-3 desktop-buttons">
                    <button mat-raised-button color="primary" class="mobile-button desktop-button" (click)="save()">Save</button>
                </div>
    
                <div class="my-3 desktop-buttons">
                    <button mat-raised-button class="mobile-button desktop-button">Back</button>
                </div>
            </div>
        </div>
    </div>
</div>