import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from '../../services/data.service';
import { EmailMessage } from 'app/models/email';
@Injectable()
export class InvoiceService {
  constructor(private http: DataService) { }

  getInvoice(txnIdx: number) {
    return this.http.get(`/finance/invoice/${txnIdx}`);
  }

  voidInvoice(txnIdx: number) {
    return this.http.post(`/finance/invoice/${txnIdx}`, { action: 'void' })
  }
 /**
   * Fetches all transactions assigned to the patientIdx
   * 
   */
  public getTransactions(patientIdx: number): Observable<any> {
    return this.http.get(`/patient/statement/${patientIdx}`);
  }



  emailInvoice(type: string, txIdx: number, patientIdx: number, emailMessage: EmailMessage, emailTo: string = null) {
    const payload = {
      idx: txIdx,
      patientIdx: patientIdx,
      email: emailMessage,
      emailTo: emailTo,
      type: type
    };

    return this.http.post(`/finance/invoice/send`, payload);
  }

}
