import { Component } from '@angular/core';
import { NotesBuilderBaseComponent } from 'app/notes/notes-builder/notes-builder-base/notes-builder-base-component';
import { CervicalFields } from '../models/cervical';

@Component({
  selector: 'app-health-details',
  templateUrl: './health-details.component.html',
  styleUrls: ['../shared.scss']
})

export class HealthDetailsComponent extends NotesBuilderBaseComponent {

}