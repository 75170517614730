<table mat-table 
multiTemplateDataRows
[dataSource]="info" 
[ngStyle]="{'width.px': fromModal ? '1000' : ''}" 
[ngClass]="{'mat-elevation-z8': !fromModal}">
  <!-- Position Column -->
  <ng-container matColumnDef="startTime">
    <th mat-header-cell *matHeaderCellDef> Date </th>
    <td mat-cell *matCellDef="let element"> {{element.startTime | date : 'short'}} </td>
  </ng-container>

  <!-- Name Column -->
  <ng-container matColumnDef="staffName">
    <th mat-header-cell *matHeaderCellDef> With </th>
    <td mat-cell *matCellDef="let element"> {{element.staffName}} </td>
  </ng-container>

  <!-- Weight Column -->
  <ng-container matColumnDef="treatmentName">
    <th mat-header-cell *matHeaderCellDef> Treatment </th>
    <td mat-cell *matCellDef="let element"> {{element.treatmentName}} </td>
  </ng-container>

  
  
  <ng-container matColumnDef="charge">
    <th mat-header-cell *matHeaderCellDef> Charge </th>
    <td mat-cell *matCellDef="let element"> {{(element.price | currency:'GBP') || "-" }} </td>
  </ng-container>

  
<!-- Symbol Column -->
<ng-container matColumnDef="status">
  <th mat-header-cell *matHeaderCellDef> Status </th>
  <td mat-cell *matCellDef="let element"> 
    <mat-icon *ngIf="!element.SMSSent" title="No reminder sent" class="text-muted">mail_outline</mat-icon>
    <mat-icon *ngIf="element.SMSSent" title="Reminder sent" class="text-success">mail</mat-icon>

    <mat-icon [ngClass]="{'delete': element.statusDisplay.icon === 'delete'}" [title]="element.statusDisplay.text">{{element.statusDisplay.icon}}</mat-icon>

  </td>
</ng-container>
<ng-container matColumnDef="expand">
  <th mat-header-cell *matHeaderCellDef aria-label="row actions">&nbsp;</th>
  <td mat-cell *matCellDef="let element">
    <button mat-icon-button aria-label="expand row" (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">
      <mat-icon *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
      <mat-icon *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
    </button>
  </td>
</ng-container>

<ng-container matColumnDef="expandedDetail">
  <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
    <div class="example-element-detail"
         [@detailExpand]="element == expandedElement ? 'expanded' : 'collapsed'">
      <div class="example-element-description">
        
        <div>
            <span class='text-muted'>Status:</span>
            {{element.statusDisplay.text}}
        </div>

        <div>
          <span class='text-muted'>Booked:</span>
          {{element.bookingMadeAt | date : 'short'}}
          <span class='text-muted'>&nbsp;by&nbsp;</span>
          {{element.bookedBy || 'Unknown'}}
        </div>

        <div *ngIf="element.status == 'Cancelled'">
          <span class='text-muted'>Cancelled:</span>
          {{element.cancelledAt | date : 'short'}}
        </div>

        <div>
          <span class='text-muted'>Reminder:</span>
          <span *ngIf="element.SMSSent; else noReminder">
            Sent {{ element.SMSSent | date : "short"}}
          </span>
          <ng-template #noReminder>
            No reminder sent
          </ng-template>
        </div>

        <div *ngIf="element.notes">
          <span class='text-muted'>Notes:</span>
          {{ element.notes}}
        </div>

      </div>
    </div>
  </td>
</ng-container>



  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;" 
    class="data-row"
    [class.example-expanded-row]="expandedElement === row"
    (click)="expandedElement = expandedElement === row ? null : row"></tr>
  <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>

</table>


<!-- 
<ng-container matColumnDef="bookingMadeAt">
  <th mat-header-cell *matHeaderCellDef> Booked </th>
  <td mat-cell *matCellDef="let element"> {{element.bookingMadeAt | date : 'short'}} </td>
</ng-container>


<ng-container matColumnDef="cancelledAt">
  <th mat-header-cell *matHeaderCellDef> Cancelled </th>
  <td mat-cell *matCellDef="let element"> {{element.cancelledAt | date : 'short'}} </td>
</ng-container>

<ng-container matColumnDef="notes">
  <th mat-header-cell *matHeaderCellDef> Notes </th>
  <td mat-cell *matCellDef="let element"> {{element.notes}} </td>
</ng-container>


  <ng-container matColumnDef="SMSSent">
    <th mat-header-cell *matHeaderCellDef> SMS Sent </th>
    <td mat-cell *matCellDef="let element"> {{element.SMSSent ? ( element.SMSSent | date : 'short' ) : ''}} </td>
  </ng-container>


-->