export const environment = {
  production: true,
  apiEndPoint: '/api',
  onlineBooking: 'https://booking.multiclinic.co.uk',
  msAuth: {
    redirectUri: '/',
    postLogoutRedirectUri: "/logout"
  },
  agGridLicense: "CompanyName=attend2IT,LicensedApplication=Multiclinic,LicenseType=SingleApplication,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-032737,SupportServicesEnd=14_October_2023_[v2]_MTY5NzIzODAwMDAwMA==8c7251fa24e1183f3d15cffe80226ee6"
};
