//control if the app is busy and locked
import { ActionWithPayload } from './index';
//import { createSelector } from 'reselect';

import * as patientAction from '../actions/patient';

import { type PatientChooserResult } from "../../models/patient-details";
import { createReducer, on } from '@ngrx/store';

export interface State {
    patient: PatientChooserResult;
};

export const initialState: State = {
    patient: {
        idx: null,
        address: null,
        name: null
    }
};

const featureReducer = createReducer(
    initialState,
    on(patientAction.SetPatientIdx, (state, action) => {
        if (action.payload === state.patient.idx){
            return state;
        }
        return Object.assign({}, { patient: {idx: action.payload, address: "", name: ""}});
    }),
    on(patientAction.SetAction, (state, action) => {
        console.log("[pc] SET ", action);
        return Object.assign({}, state, { patient: action.payload });
    }), // Object.assign({}, state, { patient: action.payload })),
    on(patientAction.SetDetail, (state, action) =>{
        console.log("[pc] SET DETAIL", action);
        return Object.assign({}, state, { patientDetail: action.payload });
    }), //  state = Object.assign({}, state, { patientDetail: action.payload })),
    on(patientAction.ClearAction, (state) => state = initialState)
  );

  export function reducer(state: State = initialState, action: ActionWithPayload) {
    return featureReducer(state, action);
  }


/*

export function reducer(state = initialState, action: ActionWithPayload): State {
    switch (action.type) {
        case patientAction.ActionTypes.SET: {
            console.log("[pc] action set just patient:", action.payload);
            return Object.assign({}, state, { patient: action.payload });
        }
        case patientAction.ActionTypes.SET_DETAIL: {
            console.log("[pc] action set detail:", action.payload);
            return Object.assign({}, state, { patientDetail: action.payload });
        }
        case patientAction.ActionTypes.CLEAR: {
            state = initialState
            return state = initialState
        }
        default:
            return state;
    }
}
*/
export const getPatient = (state: State) => state.patient;
export const getPatientIdx = (state: State) => state.patient.idx;
