// Boring Angular Modules //
import { NgModule } from '@angular/core';
//import { BrowserModule }                  from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { SubmitCancelComponent } from './submit-cancel.component';
import { MyMaterialModule } from '../material/material.module';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        MyMaterialModule

    ],
    declarations: [
        SubmitCancelComponent
    ],
    exports: [
        SubmitCancelComponent
    ]
})
export class SubmitCancelModule { }

