import { Component, OnInit } from '@angular/core';
import { Clinic } from 'app/models/clinics';
import { StaffMember } from 'app/models/staff';
import { ClinicService } from 'app/services/clinic.service';
import { LoadingService } from 'app/services/loading.service';
import { NotificationService } from 'app/services/notification.service';
import { StaffService } from 'app/services/staff.service';
import { CommissionMatrixService } from "../../services/commission-matrix.service";

@Component({
  selector: 'app-commission-matrix',
  templateUrl: './commission-matrix.component.html',
  styleUrls: ['./commission-matrix.component.scss']
})
export class CommissionMatrixComponent implements OnInit {
  public matrix: any;
  public staff: StaffMember[] = [];
  public clinics: Clinic[] = [];

  constructor( private commissionMatrixService: CommissionMatrixService,
               private staffService: StaffService,
               private clinicService: ClinicService,
               private loading: LoadingService,
               private notification: NotificationService ) { }

  ngOnInit(): void {
    this.staffService.getStaff().subscribe(( staff: StaffMember[] ) => this.staff = this.commissionMatrixService.sortStaff(staff));
    this.clinicService.getClinics().subscribe(( clinics: Clinic[] ) => this.clinics = clinics );

    this.loadMatrix();
  }

  private loadMatrix() {
    this.loading.start();

    this.commissionMatrixService.getMatrix().subscribe((matrix: any) => {
      this.matrix = { ...matrix, commission: this.commissionMatrixService.populateCommissions(this.staff, this.clinics, matrix.commission) };

      this.loading.stop();
    })
  }

  public getValue(staff: StaffMember, clinic: Clinic, table: string): any {
    return this.commissionMatrixService.getMatrixValue(this.matrix, staff, clinic, table);
  }

  public valueChanged(value: any, staff: StaffMember, clinic: Clinic, table: string): void {
    this.matrix = this.commissionMatrixService.matrixChanged(this.matrix, value, staff, clinic, table);
  }

  public save(): void {
    this.matrix = this.commissionMatrixService.inspectMatrix(this.matrix);
    this.loading.start();

    this.commissionMatrixService.updateMatrix(this.matrix).subscribe((matrix: any) => {
      if ( matrix && matrix.commission ) {
        this.matrix = matrix;
        this.notification.send("You have updated the matrix successfully!");
      }

      this.loading.stop();
    })
  }

}
