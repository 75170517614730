<mat-tab-group mat-stretch-tabs *ngIf="diary.staff" (selectedIndexChange)="tabClick($event)"
  [(selectedIndex)]="selectedIndex" [ngClass]="(staffPanelCollapsed && mobileView) ? 'staffPanelCollapsed' : 'staffPanel'" dynamicHeight>
  
  <ng-container *ngIf="staffPanelCollapsed && mobileView">
    <mat-tab label="Staff" class="tab-selection">
      <button mat-raised-button (click)="staffPanelCollapsed = false" class="tab-button"><mat-icon>visibility</mat-icon>Staff</button>
    </mat-tab>
    <mat-tab label="Rooms" class="tab-selection">
      <button mat-raised-button (click)="staffPanelCollapsed = false" class="tab-button"><mat-icon>visibility</mat-icon>Rooms</button>
    </mat-tab>
  </ng-container>
 
  <ng-container *ngIf="!staffPanelCollapsed || (staffPanelCollapsed && !mobileView)">
    
    <mat-tab label="Staff">
      <button *ngIf="mobileView" mat-raised-button (click)="staffPanelCollapsed = true" class="tab-button tab-button-shown"><mat-icon>visibility_off</mat-icon> Hide Staff Member Selection</button>
      <ul class="l-margin">
        <li *ngIf="selectedView == viewTypes.DAY" class="border-bottom">
          <button mat-button class="w-100" (click)="selectAllStaff()">SELECT ALL</button>
        </li>
        <li class="border-bottom">
          <mat-checkbox 
          [checked]="showOnlyWorkingStaff"
          [disabled]="selectedView == viewTypes.WEEK"
          (change)="setShowWorking($event.checked)">WORKING
          </mat-checkbox>
        </li>

        <li *ngFor="let group of diary.groups" class="cat w-190"
          [ngClass]="{'focused' : (diary.focusedOn.type == DiaryFocusOn.GROUP &&  diary.focusedOn.id == group.idx) }">
          <div *ngIf='selectedView==viewTypes.DAY' (click)="focusGroup(group.idx)" class="focus-on">
            <mat-icon>center_focus_strong</mat-icon>
          </div>
          <mat-checkbox 
              [disabled]='selectedView==viewTypes.WEEK' 
              [checked]="group.selected"
              (dblclick)="focusGroup(group.idx)" 
              (change)="selectAllCat(group, $event.checked)"
              class="cbl-6"><span class="no-wrap">{{ group.name}}</span>
          </mat-checkbox>
          <ul class="l-margin">
            <li *ngFor="let staff of group.staff" [ngClass]="{
              'focused' : (diary.focusedOn.type == DiaryFocusOn.STAFF &&  diary.focusedOn.id == staff.idx),
              'not-working' : (staff.working===false && showOnlyWorkingStaff && selectedView==viewTypes.DAY )}">
  
              <div *ngIf="diary.numberOfStaffInGroups > 1" (click)="focusStaff(staff.idx)" class="focus-on">
                <mat-icon>center_focus_strong</mat-icon>
              </div>
              <mat-checkbox 
                [checked]="staff.selected" 
                (change)="selectStaff(staff, $event.checked, group)"
                (dblclick)="focusStaff(staff.idx)"
                class="cbl-6"><span class="no-wrap">{{ staff.name }}</span></mat-checkbox>
            </li>
          </ul>
        </li>
      </ul>
      
    </mat-tab>
    <mat-tab label="Rooms">
      <button *ngIf="mobileView" mat-raised-button (click)="staffPanelCollapsed = true" class="tab-button tab-button-shown"><mat-icon>visibility_off</mat-icon> Hide Room Selection</button>
      <ul>
        <li *ngIf="selectedView == viewTypes.DAY" class="border-bottom">
          <button mat-button class="w-100" (click)="selectAllRooms()">SELECT ALL</button>
        </li>
        <li *ngFor="let room of allRooms$ | async" >
          <mat-checkbox [checked]="isRoomSelected(room.idx)" (change)="updateSelectedRooms($event.checked, room)">{{ room.name }}</mat-checkbox>
        </li>
      </ul>
    </mat-tab>
  </ng-container>

</mat-tab-group>
