import { Injectable } from '@angular/core';
import * as loadingAction from '../store/actions/loading';
import * as fromRoot from '../store/reducers';
import { Store } from '@ngrx/store';

@Injectable()

export class LoadingService {
  constructor(private store: Store<fromRoot.State>) { }

  start() {
    this.store.dispatch(new loadingAction.ShowAction());
  }

  stop() {
    this.store.dispatch(new loadingAction.HideAction());
  }

  backgroundStart(){
    this.store.dispatch(new loadingAction.BackgroundStart());
  }

  backgroundEnd(){
    this.store.dispatch(new loadingAction.BackgroundStop());
  }

}
