import { GenericDialogComponent } from './generic-dialog/generic-dialog.component';
import { FormDialogComponent } from './form-dialog/form-dialog.component';
import { PasswordDialogComponent } from './password-dialog/password-dialog.component';
import { AttendanceDialogComponent } from './attendance-dialog/attendance-dialog.component';
import { SaveDialogComponent } from './save-dialog/save-dialog.component';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MyMaterialModule } from 'app/material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InfoAttendanceModule } from 'app/notes/info-attendance/info-attendance.module';
import { CancelAppConfirmDialogComponent } from './cancel-app-confirm/cancel-app-confirm-dialog.component';
import { DynamicFormComponentsModule } from 'app/dynamic-form/form-components/dynamic-form-components.module';
import { NotesNewDialogComponent } from './notes-new/notes-new-dialog.component';
import { QRCodeDialogComponent } from './qr-code-dialog/qr-code-dialog.component';
import { DynamicFormDialogComponent } from './dynamic-form-dialog/dynamic-form-dialog.component'
import { DynamicFormsModule } from 'app/dynamic-form/dynamic-forms.module';
import { QuestionnaireDialogComponent } from './questionnaire-dialog/questionnaire-dialog.component';
import { CustomFormControlsModule } from 'app/custom-form-controls/custom-form-controls.module';
import { FasttextEditDialogComponent } from './fasttext-edit-dialog/fasttext-edit-dialog.component';

const Dialogs = [
    AttendanceDialogComponent,
    CancelAppConfirmDialogComponent,
    DynamicFormDialogComponent,
    FasttextEditDialogComponent,
    FormDialogComponent,// where is this used
    GenericDialogComponent,
    NotesNewDialogComponent,
    PasswordDialogComponent,
    QRCodeDialogComponent,
    QuestionnaireDialogComponent,
    SaveDialogComponent,
]

@NgModule({
    imports: [
        CommonModule,
        MyMaterialModule,
        FormsModule,
        InfoAttendanceModule,
        DynamicFormComponentsModule,
        DynamicFormsModule,
        CustomFormControlsModule,
        ReactiveFormsModule
    ],
    providers: [],
    declarations: Dialogs,
    exports: Dialogs
})
export class DialogsModule { }
