import { QuestionBase } from './question-base';

export class HiddenQuestion extends QuestionBase<string> {
  controlType = 'hidden';
  declare type: string;
  placeholder: string;

  constructor(options: {} = {}) {
    super(options);
  }
}
