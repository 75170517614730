import { NgModule } from "@angular/core";
import { AuthNeededGuard, AuthNotNeededGuard } from './auth.guard'
import { InitializeGuard } from "./initialize.guard";
import { HasMenuAccessGuard } from "./has-menu-access.guard";
@NgModule({
  imports: [  ],
  declarations: [],
  providers: [
    AuthNeededGuard,
    AuthNotNeededGuard,
    InitializeGuard,
    HasMenuAccessGuard
  ],
  exports: [
  ],
})
export class GuardsModule {}
