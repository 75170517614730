<div  *ngIf="form" [id]='formName'>
    <form autocomplete="off" class="extremetiesForm" #formEle="ngForm">
      <table class="w-100">
        <thead>
          <th></th>
          <th class="text-center">L</th>
          <th class="text-center">R</th>
        </thead>
        <tbody>
          <tr *ngFor="let field of ExtremetiesFields">
            <td>{{ field.label }}</td>
            <td class="pe-3">
              <mat-form-field>
                <mat-label>{{ field.label }} (L)</mat-label>
                <input matInput [(ngModel)]="form[field.field+'_L']" [name]="field.field+'_L'">
              </mat-form-field>
            </td>
            <td class="pe-3">
              <mat-form-field >
                <mat-label>{{ field.label }} (R)</mat-label>
                <input matInput [(ngModel)]="form[field.field+'_R']" [name]="field.field+'_R'">
              </mat-form-field>
            </td>
          </tr>
        </tbody>
      </table>
      
     
        <mat-expansion-panel hideToggle>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Show Aide Memoire
            </mat-panel-title>
            <mat-panel-description>
              Click to show reminders
            </mat-panel-description>
          </mat-expansion-panel-header>
          <ul style="list-style-type:none;" *ngFor="let sec of AideMemoire">
            <li><b>{{sec.title}}</b></li>
            <li *ngFor="let cat of sec.categories">{{cat}}</li>
          </ul>
        </mat-expansion-panel>

      
       

    </form>
  </div>