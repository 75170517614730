<form autocomplete="off" #formEle="ngForm">   
    <mat-form-field class="w-100">
        <mat-label>Presenting Complaint and Brief History</mat-label>
        <textarea  
        matInput 
        cdkTextareaAutosize
        cdkAutosizeMinRows="1"
        cdkAutosizeMaxRows="12"
        [(ngModel)]="form.presenting_complaint" 
        name="presenting_complaint" 
        ></textarea>
    </mat-form-field>
          
    <mat-form-field class="w-100">
        <mat-label>Pertinent Physical Exam / Imaging / Lab Results</mat-label>
        <textarea  
        matInput 
        cdkTextareaAutosize
        cdkAutosizeMinRows="1"
        cdkAutosizeMaxRows="12"
        [(ngModel)]="form.pertinent_physical" 
        name="pertinent_physical" 
        ></textarea>
    </mat-form-field>
        
    <div class="my-3">Osteoporosis Risk Assessment Results (if positive please indicate)</div>
    <mat-radio-group class="mb-3" aria-label="Osteoporosis Risk" name="osteoporosis_risk" [(ngModel)]="form.osteoporosis_risk">
        <mat-radio-button [value]="false">Negative</mat-radio-button>
        <mat-radio-button [value]="true" class="ms-3">Positive</mat-radio-button>
    </mat-radio-group>
    
    <mat-form-field class="w-100 mt-3" *ngIf="form.osteoporosis_risk">
        <mat-label>Please detail any follow up and outcomes or results if applicable</mat-label>
        <textarea  
        matInput 
        cdkTextareaAutosize
        cdkAutosizeMinRows="1"
        cdkAutosizeMaxRows="12"
        [(ngModel)]="form.osteoporosis_risk_comment" 
        name="osteoporosis_risk_comment" 
        required
        ></textarea>
        <mat-error >This field is required.</mat-error>
    </mat-form-field>
    
    <mat-form-field class="w-100 mt-3">
        <mat-label>Clinical Impression (include references)</mat-label>
        <textarea  
        matInput 
        cdkTextareaAutosize
        cdkAutosizeMinRows="1"
        cdkAutosizeMaxRows="12"
        [(ngModel)]="form.clinical_impression" 
        name="clinical_impression" 
        ></textarea>
    </mat-form-field>
    
    <h6>Diagnostic Codes</h6>
    <div *ngFor="let field of CaseSummaryQuestions" class="row">
        <div>
            <mat-checkbox [(ngModel)]="form[field.field]" [name]="field.field">
                {{field.label}}
            </mat-checkbox>
        </div>
        
        <mat-form-field *ngIf="field.textbox && form[field.field]" class="w-100">
            <mat-label>{{field.label}}</mat-label>
            <input matInput type="text" [(ngModel)]="form[field.field+'_text']" [name]="field.field+'_text'" >
        </mat-form-field>



    </div>


        <mat-form-field class="w-100">
            <mat-label>Prognosis</mat-label>
            <textarea  
                matInput 
                cdkTextareaAutosize
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="12"
                [(ngModel)]="form.prognosis" 
                name="prognosis" 
                ></textarea>
        </mat-form-field>

    <div class="alert alert-info aide-memoire">
        <div class="aide-memoire-text">Aide Memoire</div>
        <div>Include psychosocial considerations (STarT Back, BQ, Pain Scales)</div>
        <ul>
            <li>barriers to recovery</li>
            <li>public health issues</li>
        </ul>
    </div>
    
    <mat-form-field class="w-100">
        <mat-label>Specific Plan of Management (include references)</mat-label>
        <textarea  
                matInput 
                cdkTextareaAutosize
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="12"
                [(ngModel)]="form.specific_plan_of_management" 
                name="specific_plan_of_management" 
                ></textarea>
    </mat-form-field>
    
    <div class="mb-3 aide-memoire-button" (click)="aideMemoireOpen = !aideMemoireOpen">Aide Memoire 
        <mat-icon *ngIf="!aideMemoireOpen">expand_more</mat-icon>
        <mat-icon *ngIf="aideMemoireOpen">expand_less</mat-icon>
    </div>

    <div class="alert alert-info aide-memoire" *ngIf="aideMemoireOpen">
        <div class="aide-memoire-text">Aide Memoire</div>
        <div>Include psychosocial considerations (STarT Back, BQ, Pain Scales)</div>
        <ul>
            <li>
                <div>Self management advice / education</div>
                <div>SMART objectives</div>
                <ul>
                    <li>Short Term</li>
                    <li>Long Term (to be agreed with the patient)</li>
                </ul>
            </li>
            <li>Physical Activity</li>
            <li>Exercise Programme</li>
            <li>
                <div>Rehabilitation</div>
                <ul>
                    <li>Dosage</li>
                    <li>Frequency</li>
                    <li>Duration</li>
                    <li>Progression</li>
                </ul>
            </li>
            <li>Public Health issues (Smoking, Alcohol, Falls)</li>
            <li>Make Every Encounter Count (MEEC)</li>
        </ul>
    </div>
    
    <mat-form-field class="w-100">
        <mat-label>Additional Investigations</mat-label>
        <textarea  
                matInput 
                cdkTextareaAutosize
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="12"
                [(ngModel)]="form.additional_investigations" 
                name="additional_investigations" 
                ></textarea>
    </mat-form-field>
    
    <mat-form-field class="w-100">
        <mat-label>Red Flags / Yellow Flags Contraindications</mat-label>
        <textarea  
                matInput 
                cdkTextareaAutosize
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="12"
                [(ngModel)]="form.red_flags_yellow_flags" 
                name="red_flags_yellow_flags" 
                ></textarea>
    </mat-form-field>
    
    <mat-form-field class="w-100">
        <mat-label>Tutor Recommendation</mat-label>
        <textarea  
                matInput 
                cdkTextareaAutosize
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="12"
                [(ngModel)]="form.tutor_recommendation" 
                name="tutor_recommendation" 
                ></textarea>
    </mat-form-field>
    
    <mat-form-field class="w-100">
        <mat-label>Additional References</mat-label>
        <textarea  
                matInput 
                cdkTextareaAutosize
                cdkAutosizeMinRows="1"
                cdkAutosizeMaxRows="12"
                [(ngModel)]="form.additional_references" 
                name="additional_references" 
                ></textarea>
    </mat-form-field>
    
    <div class="alert alert-info aide-memoire">
        <div class="aide-memoire-text">Aide Memoire</div>
        <div>( up to date guidelines, RCT's, systematic review )</div>
    </div>
    
    <div>
        <mat-checkbox [(ngModel)]="form.gp_letter_sent" name="gp_letter_sent">GP Letter Sent</mat-checkbox>
    </div>

    <div>
        <mat-checkbox [(ngModel)]="form.referral_letter_sent" name="referral_letter_sent">Referal Letter Sent</mat-checkbox>
    </div>
</form>
