import { Component, OnInit } from '@angular/core';
import { VisualsService } from '../../services/visuals.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public isNewStyleChecked: boolean;
  private icons$;

  constructor(private visual: VisualsService) { }

  public sliderToggled() {
      this.visual.setIconStyle(this.isNewStyleChecked);
  }

  ngOnInit() {
      this.icons$ = this.visual.getIconsStyle().subscribe(res => {this.isNewStyleChecked = res});
  }

  ngOnDestroy() {
      this.icons$.unsubscribe();
  }

}
