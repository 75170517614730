import { Component, OnInit } from "@angular/core";
import { FormTemplateBaseComponent } from "../form-template-base/form-template-base.component";

@Component({
  selector: "app-form-slider",
  templateUrl: "./form-slider.component.html",
  styleUrls: ["./form-slider.component.css"],
})
export class FormSliderComponent extends FormTemplateBaseComponent {
  sliderValue = 0;
}
