import { Pipe, PipeTransform } from '@angular/core';
import { CurrencyPipe } from '@angular/common';

@Pipe({
  name: 'currencyPence'
})
export class CurrencyPencePipe extends CurrencyPipe implements PipeTransform {
  
  transform(value: any, currencyCode): any {
    value = value/100;
    return super.transform(value, currencyCode);
  }
}