import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { SaleItem, StockAudit } from 'app/models/saleItems';
import { AuthService } from 'app/services/auth.service';
import { DialogResult, DialogService } from 'app/services/dialog/dialog.service';
import { NavigationService } from 'app/services/navigation.service';
import { NotificationService } from 'app/services/notification.service';
import { SaleItemsService } from 'app/services/sale-items.service';

@Component({
  selector: 'app-stock-settings',
  templateUrl: './stock-settings.component.html',
  styleUrls: ['./stock-settings.component.scss']
})
export class StockSettingsComponent implements OnInit {
  public stockForm: UntypedFormGroup;
  public saleItems: SaleItem[];
  public selectedSaleItem: number;
  public increment: number = 1;
  public target: number = 0;
  public unchangedError: boolean = false;
  public reasonMissingError: boolean = false;

  constructor( private navigationService: NavigationService,
               private saleItemsService: SaleItemsService,
               private formBuilder: UntypedFormBuilder,
               private dialogService: DialogService,
               private auth: AuthService,
               private notification: NotificationService ) {}

  ngOnInit(): void {
    this.saleItemsService.getStockListFromServer().subscribe((saleItems: SaleItem[]) => {
      this.saleItems = saleItems.filter((saleItem: SaleItem) => saleItem.physical);
    })

    this.stockForm = this.formBuilder.group({
      saleItem: [""],
      balance: [""],
      amendment: [""],
      reason: [""]
    })
  }

  public setAmendment(action: string): void {
    const change = action === "increment" ? this.increment : action === "decrement" ? -this.increment : 0; 

    this.stockForm.patchValue({ amendment: +this.stockForm.value.amendment + change });
  }

  public getTarget(): number {
    if ( this.stockForm.value.amendment && this.stockForm.value.balance ) return null;

    if ( this.stockForm.value.saleItem ) {
      if ( this.stockForm.value.amendment ) return this.stockForm.value.saleItem.stock + this.stockForm.value.amendment;
      if ( this.stockForm.value.balance ) return this.stockForm.value.balance;
    } 

    return null;
  }

  public back(): void {
    this.navigationService.back();
  }

  public clear(field: string): void {
    this.stockForm.controls[field].reset();
    this.stockForm.controls[field].updateValueAndValidity();
  }

  public save(): void {
    const target = this.getTarget();

    if ( target === null ) {
      this.unchangedError = true;
      setTimeout(() => this.unchangedError = false, 5000);
      return;
    }

    const stockAudit: StockAudit = {
      idx: this.stockForm.value.saleItem.idx,
      stock: target,
      ammendment: target - this.stockForm.value.saleItem.stock,
      reason: this.stockForm.value.reason,
      userID: this.auth.getAuthState().meta ? this.auth.getAuthState().meta.userId : 0
    }

    this.unchangedError = false;
    const question = `Are you sure you want to change the stock balance of ${this.stockForm.value.saleItem.name} to ${target}? This amendment will also be saved in your records.`;

    // Set up dialog info
    const title = "Update Stock";
    const description = `Are you sure you want to change the stock balance of ${this.stockForm.value.saleItem.name} to ${target}? This amendment will also be saved in your records.`;

    // Open dialog, process response
    this.dialogService.genericDialog(title, description, ['Change'], ['Cancel'], false)
    .then(( answer: DialogResult ) => {
      if ( answer.data ) {
        this.saleItemsService.saveStock(stockAudit).subscribe((updatedSaleItems: SaleItem[]) => {
          if ( !stockAudit.reason ) {
            this.reasonMissingError = true;
            setTimeout(() => this.reasonMissingError = false, 5000);
          }

          this.saleItems = updatedSaleItems.filter((saleItem: SaleItem) => saleItem.physical);
          this.notification.send(`You have successfully updated the stock balance of ${this.stockForm.value.saleItem.name} to ${target}.`);
          this.stockForm.patchValue({ saleItem: this.saleItems.find((saleItem: SaleItem) => saleItem.idx === this.stockForm.value.saleItem.idx) });
        })
      }
    });
  }
}