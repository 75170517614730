import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { UserSettingsService } from './services/user-settings.service';
import { Store } from '@ngrx/store';
import * as fromRoot from 'app/store/reducers';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent {
    public url: string;
    public darkMode: boolean;
    public bsTheme = null;
    constructor(
        private router: Router,
        private settingsService: UserSettingsService,
        private store: Store<fromRoot.State>
    ) {
        this.url = this.router.url;
        this.setMobileView();
        this.store.select(fromRoot.getDarkMode).pipe(takeUntilDestroyed()).subscribe(res=>{
            this.darkMode = res;
            this.bsTheme = this.darkMode ? 'dark' : null; //Bootstrasp theme toggle
        })
    }

    @HostListener("window:resize", ["$event"]) onResize(event: any) {
        this.setMobileView();
    }

    private setMobileView(): void {
        const mobileView = window.innerWidth <= 1000;
        this.settingsService.setMobileView(mobileView);
    }

    ngOnInit() {}
}
