<div class="row margin-collapse">
  <div class="col-lg-10 bg-white">
    <div>
      <h5>Online Booking Settings</h5>
      <p>Select the sections from Patient Questionnaire that will be emailed to a client when they register for the first time with online booking.</p>

      <app-forms-list-chooser (change)="updateList($event)"></app-forms-list-chooser>

    </div>
  </div>

  <div class="col-sm-2 mobile-margin">
    <div class="desktop-buttons">
      <app-submit-cancel [saving]="saving" (onSave)="save()" (onCancel)="cancel()" >
      </app-submit-cancel>
    </div>
  </div>
</div>