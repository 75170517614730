import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpResponse,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { retry, catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';
import { NotificationService } from '../notification.service';

@Injectable({
  providedIn: 'root'
})
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private router: Router, private auth: AuthService, private notify: NotificationService) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request)
      .pipe(
 //       retry(1),
        catchError((error: HttpErrorResponse) => {
          console.log("error handler on http activated");
          let errorMessage = '';
          console.log(error);
          if (error.error instanceof ErrorEvent) {
            // client-side error
            errorMessage = `Error Client Side: ${error.error.message}`;
          } else {
            // server-side error
            if ( error.error.error && error.error.message === "Unknown O365 Site" ) {
              errorMessage = `Error: Your O365 site is not registered. Please send your TID ${error.error.meta.tid} to support@multiclinic.co.uk.`;
            }else if(error.error.message){
              errorMessage = `Login failed: ${error.error.message}`;
            }else {
              errorMessage = `Error Server Side Code: ${error.status}\nMessage: ${error.message}`;
            }
            
            console.log('[401]', error);
            if (error.status == 401 && this.router.url!="/login") { // unauthorised!
              if (error.error.logout){
                if (error.url.indexOf('/auth/refresh')==-1){
                  console.warn("[401] token issues attempting renewal"); 
                  this.auth.renewToken();
                }else{
                  this.notify.send("Your session has expired logging you out");
                  this.router.navigate(['logout']);
                }
//SHOUD trigger token renewal                
              }else{
                  console.log("[401] not authorised",this.router.url);
                  if (this.router.url!="/login"){
                    this.router.navigate(['/access-denied']);
                  }
              }
            }else{
              this.notify.send(`${errorMessage}`);
            }
          }
          console.error(errorMessage);
          return throwError(errorMessage);
        })
      )
  }
}

