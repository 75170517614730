import { Component, OnInit } from '@angular/core';
import { Treatment, type TreatmentGroup } from 'app/models/treatments';
import { TreatmentService } from '../services/treatment.service';

@Component({
  selector: 'app-treatment-manager',
  templateUrl: './treatment-manager.component.html',
  styleUrls: ['./treatment-manager.component.scss']
})
export class TreatmentManagerComponent implements OnInit {

  public treatments: TreatmentGroup[];
  public newTreatment;
  private currentCat;

  constructor(private treatmentService: TreatmentService) { }

  ngOnInit() {
    this.treatmentService.getTreatmentsFromServer().subscribe(res => {
      this.updateTreatmentList(res);
    });
  }

  updateTreatmentList(res){
    this.treatments = this.toBooleans(res);
    console.log(this.treatments)
    this.treatments.push({
      idx: 0,
      name: "",
      treatments: [],
    });
      for (let treatmentCat of this.treatments) {
        treatmentCat.treatments.push({
          idx: 0,
          name: "",
          defaultPrice: 0,
          defaultDuration: 30,
          colour: "#8888ff",
          credits: 0,
          groupSize: 0,
          DNAPrice: 0,
          LNCPrice: 0,
          canBookOnline: false,
          requirePrePayment: false
        });
      }
  }

  public toBoolean(str: string): boolean {
    return str.toLowerCase() === "true" ? true : str.toLowerCase() === "false" ? false : false;
  }

  public toBooleans(treatments: any) {
    return treatments.map((category: any) => {
      return { ...category, treatments: category.treatments.map((cat: any) => { return { ...cat, canBookOnline: this.toBoolean(cat.canBookOnline) } }) };
    })
  }

  saveTreatments(){
    let payload: TreatmentGroup[][] = [];
    this.treatments.forEach((category: any) => {
      payload.push({ ...category, treatments: category.treatments.map((cat: any) => { return { ...cat, canBookOnline: cat.canBookOnline.toString().toUpperCase() }}) });
    })

    this.treatmentService.save(payload).subscribe(res=>{
      this.updateTreatmentList(res);
    })
  }

  saveTreatment(treatment){
    this.treatmentService.save(treatment).subscribe(res=>{
      this.updateTreatmentList(res);
    })
  }  

  deleteTreatment(treatmentCat, treatment){
    treatment.delete = true;
    console.log("deleting ", treatment, "from", treatmentCat)
    this.saveTreatments();
  }

  deleteCategory(cat){
    cat.delete = true;
    console.log(cat)
    this.saveTreatments();
  }


  scroll(dest: string) {
    console.log("[scroll] ", dest)
    const ele = document.getElementById(dest);
    ele.scrollIntoView({behavior: "smooth"});
  }

  onCancel(){

  }

}
