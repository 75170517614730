import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ValueAccessorBase } from 'app/custom-form-controls/value-accessor-base';

@Component({
  selector: 'app-reflexes-field-special',
  templateUrl: './reflexes-field-special.component.html',
  styleUrls: ['./reflexes-field-special.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ReflexesFieldSpecialComponent,
      multi: true,
    }],
})
export class ReflexesFieldSpecialComponent extends ValueAccessorBase<string> {
  @Input() label = "";
}
