export const PainScales = [
    {
        field: 'maxpain',
        label: "On a scale of 1 to 10,  with 0 being no pain at all and 10 being the worst pain imaginable, how would you rate your pain RIGHT NOW?"
    },
    {
        field: 'minpain',
        label: "On the same scale, How would you rate your USUAL level of pain during the last week?"
    },
    {
        field: 'bestpain',
        label: "On the same scale, How would you rate your BEST level of pain during the last week?"
    },
    {
        field: 'worstpain',
        label: "On the same scale, How would you rate your WORST level of pain during the last week?"
    }

]