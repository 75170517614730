import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-submit-cancel',
  templateUrl: './submit-cancel.component.html',
  styleUrls: ['./submit-cancel.component.scss']
})
export class SubmitCancelComponent implements OnInit {
  @Output() onSave = new EventEmitter();
  @Output() onCancel = new EventEmitter();
  @Input() saving: boolean;
  @Input() noSave: boolean;

  public isCancelUsed: boolean;
  public isSaveUsed: boolean;

  constructor() { }

  ngOnInit() {
    this.isCancelUsed = this.onCancel.observers.length > 0;
    this.isSaveUsed = this.onSave.observers.length > 0;
  }

  cancel() {
    this.onCancel.emit();
  }

  save(){
    this.onSave.emit();
  }

}
