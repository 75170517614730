import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Injectable, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { CalendarDateFormatter, CalendarEvent, CalendarEventTitleFormatter, CalendarNativeDateFormatter, DateFormatterParams } from 'angular-calendar';
import { WeekViewHourColumn } from 'calendar-utils';
import { fromEvent } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { addDays, addMinutes, endOfWeek } from 'date-fns';
import { DiaryService } from 'app/services/diary/diary.service';
import { MatMenuTrigger } from '@angular/material/menu';

function floorToNearest(amount: number, precision: number) {
  return Math.floor(amount / precision) * precision;
}

function ceilToNearest(amount: number, precision: number) {
  return Math.ceil(amount / precision) * precision;
}

@Injectable()
export class CustomDateFormatter extends CalendarNativeDateFormatter {

  public dayViewHour({date, locale}: DateFormatterParams): string {
    return new Intl.DateTimeFormat('ca', {
      hour: 'numeric',
      minute: 'numeric'
    }).format(date);
  }

}

@Injectable()
export class CustomEventTitleFormatter extends CalendarEventTitleFormatter {
  weekTooltip(event: CalendarEvent, title: string) {
    if (!event.meta.tmpEvent) {
      return super.weekTooltip(event, title);
    }
  }

  dayTooltip(event: CalendarEvent, title: string) {
    if (!event.meta.tmpEvent) {
      return super.dayTooltip(event, title);
    }
  }
}

@Component({
  selector: 'app-availability-setting',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './availability-setting.component.html',
  providers: [
    {
      provide: CalendarEventTitleFormatter,
      useClass: CustomEventTitleFormatter,
    },
    {
      provide: CalendarDateFormatter, 
      useClass: CustomDateFormatter
    }
  ],
  styles: [
    `
                          .disable-hover {
                            pointer-events: none;
                          }
                        `,
  ],
  styleUrls: ['./availability-setting.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AvailabilitySettingComponent implements OnInit, OnChanges {
  @Input("events") calendarEvents: boolean = false;
  @Output() eventsChange = new EventEmitter();
  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  public selectedEvent;

  public settings;
  
  viewDate = new Date();
  events: CalendarEvent[] = [];
  dragToCreateActive = false;
  weekStartsOn: 1 = 1;
  showCurrentTimeMarker: boolean = false;
  weekDays: any[] = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
  increment: number;
  public hourSegmentHeight = 0;

  constructor(private cdr: ChangeDetectorRef, public diary: DiaryService) {  }

  ngOnInit(){
    this.diary.getCalendarSettings().subscribe(res=>{
      this.settings = res;
    });
    
    this.diary.getIncrement().subscribe(res=>{
      this.increment = res;
      this.hourSegmentHeight = 20;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ( changes.calendarEvents && changes.calendarEvents.currentValue ) {
      this.events = [...changes.calendarEvents.currentValue];
    }
  }

  startDragToCreate(
    segment: WeekViewHourColumn,
    mouseDownEvent: MouseEvent,
    segmentElement: HTMLElement
  ) {
    segment['selected'] = true
    const dragToSelectEvent: CalendarEvent = {
      id: this.events.length, 
      title: 'New event',
      start: segment.date,
      end: new Date(segment.date.getTime() + this.increment * 60000), // initialise the end time to the smallest amout of time
      meta: {
        tmpEvent: true,
      },
    };
    console.log(dragToSelectEvent);
    this.events = [...this.events, dragToSelectEvent];
    const segmentPosition = segmentElement.getBoundingClientRect();
    console.log("segment position", segmentPosition, "segment position");
    this.dragToCreateActive = true;
    const endOfView = endOfWeek(this.viewDate, { weekStartsOn: this.weekStartsOn });

    fromEvent(document, 'mousemove')
      .pipe(
        finalize(() => {
          delete dragToSelectEvent.meta.tmpEvent;
          this.dragToCreateActive = false;
          this.refresh();
        }),
        takeUntil(fromEvent(document, 'mouseup'))
      )
      .subscribe((mouseMoveEvent: MouseEvent) => {
        console.log("segment position", mouseMoveEvent.clientY, segmentPosition.top);
        const minutesDiff = ceilToNearest((mouseMoveEvent.clientY - segmentPosition.top) / ( this.hourSegmentHeight / this.increment ), this.increment);
        console.log("[nathan] minutes", minutesDiff)
        console.log("segment position minutes ", minutesDiff);
        const daysDiff =
          floorToNearest(
            mouseMoveEvent.clientX - segmentPosition.left,
            segmentPosition.width
          ) / segmentPosition.width;

        const newEnd = addDays(addMinutes(segment.date, minutesDiff), daysDiff);
        
        if (newEnd > segment.date && newEnd < endOfView) {
          if ( !this.collisionCheck(newEnd, segment.date) ) dragToSelectEvent.end = newEnd;
        }
        
        this.refresh()
      });
  }

  private collisionCheck(endTime: Date, startTime: Date): boolean {
    const collision = this.events.find((event: any) => ((event.start < endTime && event.start > startTime)));

    return collision !== undefined;
  }

  deleteAvailability(e) {
    let index = this.events.indexOf(this.events.find(f => f.id === this.selectedEvent))
    this.events.splice(index, 1)
    this.refresh();
  }

  clicked(e) {
    this.selectedEvent = e.event.id
  }

  private refresh() {
    this.events = [...this.events];
    this.eventsChange.emit(this.events);
    this.cdr.detectChanges();
  }
}