import { Component } from '@angular/core';
import { NotesBuilderBaseComponent } from 'app/notes/notes-builder/notes-builder-base/notes-builder-base-component';

@Component({
  selector: 'app-patient-consent',
  templateUrl: './pe-patient-consent.component.html',
  styleUrls: ['./pe-patient-consent.component.scss']
})
export class PePatientConsentComponent extends NotesBuilderBaseComponent {

}