import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ValueAccessorBase } from 'app/custom-form-controls/value-accessor-base';

@Component({
  selector: 'rom-field',
  templateUrl: './range-of-motion.component.html',
  styleUrls: ['./range-of-motion.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: RangeOfMotionComponent,
      multi: true,
    }],
})
export class RangeOfMotionComponent extends ValueAccessorBase<string> {
  
}
