<form #formEle="ngForm">
    <mat-form-field class="w-100">
        <mat-label>Short case summary / presenting complaint</mat-label>
        <textarea  
        matInput 
        cdkTextareaAutosize
        cdkAutosizeMinRows="1"
        cdkAutosizeMaxRows="12"
        [(ngModel)]="form.short_case_summary" 
        name="short_case_summary" 
        ></textarea>     
    </mat-form-field>
    
    <mat-form-field class="w-100">
        <mat-label>Relevant exam findings</mat-label>
        <textarea  
        matInput 
        cdkTextareaAutosize
        cdkAutosizeMinRows="1"
        cdkAutosizeMaxRows="12"
        [(ngModel)]="form.relevant_exam_findings" 
        name="relevant_exam_findings" 
        ></textarea>     
    </mat-form-field>
    
    <mat-form-field class="w-100">
        <mat-label>Relevant psychosocial issues (barriers to recovery)</mat-label>
        <textarea  
        matInput 
        cdkTextareaAutosize
        cdkAutosizeMinRows="1"
        cdkAutosizeMaxRows="12"
        [(ngModel)]="form.relevant_psychosocial" 
        name="relevant_psychosocial" 
        ></textarea>     
    </mat-form-field>
    
    <mat-form-field class="w-100">
        <mat-label>Working diagnosis</mat-label>
        <textarea  
        matInput 
        cdkTextareaAutosize
        cdkAutosizeMinRows="1"
        cdkAutosizeMaxRows="12"
        [(ngModel)]="form.working_diagnosis" 
        name="working_diagnosis" 
        ></textarea>     
    </mat-form-field>
    
    <mat-form-field class="w-100">
        <mat-label>Plan of management</mat-label>
        <textarea  
        matInput 
        cdkTextareaAutosize
        cdkAutosizeMinRows="1"
        cdkAutosizeMaxRows="12"
        [(ngModel)]="form.plan_of_management" 
        name="plan_of_management" 
        ></textarea>     
    </mat-form-field>
    
    <mat-form-field class="w-100">
        <mat-label>Report of findings</mat-label>
        <textarea  
        matInput 
        cdkTextareaAutosize
        cdkAutosizeMinRows="1"
        cdkAutosizeMaxRows="12"
        [(ngModel)]="form.report_of_findings" 
        name="report_of_findings" 
        ></textarea>     
    </mat-form-field>
    
    <mat-form-field class="w-100">
        <mat-label>Care provided - please see relevant SOAP note</mat-label>
        <textarea  
        matInput 
        cdkTextareaAutosize
        cdkAutosizeMinRows="1"
        cdkAutosizeMaxRows="12"
        [(ngModel)]="form.care_provided" 
        name="care_provided" 
        ></textarea>     
    </mat-form-field>
    
    <mat-form-field class="w-100">
        <mat-label>Additional tests / Tutor examination / Tutor comments</mat-label>
        <textarea 
                      matInput
                      cdkTextareaAutosize
                      cdkAutosizeMinRows="3"
                      cdkAutosizeMaxRows="12"
                      name="additional_tests"
                      matInput [(ngModel)]="form.additional_tests"></textarea>
    </mat-form-field>

    <mat-checkbox [(ngModel)]="form.patient_consents" name="patient_consents">
        Patient consents to treatment
    </mat-checkbox>
</form>