import { Component, OnInit, Input, ViewContainerRef, ViewChild, ComponentFactoryResolver } from '@angular/core';
import { CardTemplateBaseComponent } from '../grid-item-components/card-template-base/card-template-base.component';
import { CardLeftSidebarComponent } from '../grid-item-components/card-left-sidebar/card-left-sidebar.component';
import { CardRightSidebarComponent } from '../grid-item-components/card-right-sidebar/card-right-sidebar.component';
import { CardDiaryHeaderComponent } from '../grid-item-components/card-diary-header/card-diary-header.component';
import { CardSidebarHeaderComponent } from '../grid-item-components/card-sidebar-header/card-sidebar-header.component';
import { CardDiaryComponent } from '../grid-item-components/card-diary/card-diary.component';
import { CardFooterComponent } from '../grid-item-components/card-footer/card-footer.component';

@Component({
  selector: 'app-grid-item',
  templateUrl: './grid-item.component.html',
  styleUrls: ['./grid-item.component.css']
})
export class GridItemComponent implements OnInit {
@Input() data:any;
@ViewChild('container', {read: ViewContainerRef, static: true}) private container: ViewContainerRef;
readonly templateMapper = {
  leftSidebar: CardLeftSidebarComponent,
  rightSidebar: CardRightSidebarComponent,
  diaryHeader: CardDiaryHeaderComponent,
  sidebarHeader: CardSidebarHeaderComponent,
  diary: CardDiaryComponent,
  footer: CardFooterComponent
}; 
constructor(private componentFactoryResolver: ComponentFactoryResolver) { }

  ngOnInit() {
    const componentFactory = this.componentFactoryResolver.resolveComponentFactory(this.getComponentForCardType(this.data.component));
    const viewContainerRef = this.container;
    const componentRef = viewContainerRef.createComponent(componentFactory);
    (<CardTemplateBaseComponent>componentRef.instance).data = this.data;
  }
  private getComponentForCardType(cardType) {
    return this.templateMapper[cardType];
  }
}
