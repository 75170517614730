/* Rewrite of letter/email replacement code now uses an observable so letter content updated sycnhronously */
/* observable used to allow for fetching of missing data from server */
/* front end letter/email wil then update as more data is fetched */

import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { DataService } from './data.service';
import * as fromRoot from 'app/store/reducers';
import { filter, map, take } from 'rxjs/operators';
import { BehaviorSubject, Subject } from 'rxjs';
import { AuthService } from './auth.service';
import { NotesService } from './notes/notes.service';
import { PatientChooserService } from './patient.service';
import { type  PatientDetails } from 'app/models/patient-details';

@Injectable()
export class TextReplacementService {
    private patientInfoCache: any;

    public output$: BehaviorSubject<string>;
    private _output: string = "";
    
    private _patientIdx;

    constructor(private http: DataService,
        private auth: AuthService,
        private notesService: NotesService,
        private patientService: PatientChooserService,
        private store: Store<fromRoot.State>) {
        this.output$ = new BehaviorSubject("");      
    }

    set output(val) {
        this._output = val;
        this.emitOutput();
    }

    get output() {
        return this._output;

    }

    public setPatientIdx(id){
        this._patientIdx = id;
    }

    public emitOutput() {
        this.output$.next(this.output);
    }

    public fulfillReplacements(text: string): BehaviorSubject<string> {
        //and we are off
        this.output = text;

        //extract all the {tags}, lower case with brackes removed
        const found = this.output.match(/{(.*?)}/g).map(f => {
            return f.replace("}", "").replace("{", "");
        });

        for (let item of found) {
            this.replaceSegment(item);
        }

        return this.output$;
    }

    private replaceSegment(variable: string) {

        const parts = variable.toLocaleLowerCase().split(".");
        console.log("[replacement] parts", parts);
        switch (parts[0]) {
            case 'patient':
                return this.replacePatientInfo(parts);
            case 'staff':
                return this.replaceStaffInfo(parts[1]);
            case 'date':
                return this.replaceDate(parts[1]);
            case 'clinic':
                return this.replaceClinicInfo(parts);
            case 'notes':
                const casedParts = variable.split("."); //table names in SQL are case-sensitive so need the correct capitalisation
                return this.replaceNotesInfo(casedParts);
        }

    }


    //prefixed CLINIC:
    private replaceClinicInfo(parts: string[]){
        const variable = parts[1];         
        this.store.select(fromRoot.getSelectedClinic).pipe(take(1)).subscribe(clinic=>{
            switch (variable) {
                case 'logo':
                    console.log("LOGO = ", clinic.logo);
                    return this.doReplacement(`clinic.${variable}`,  clinic.logo);
                case 'address':
                    const address = [clinic.address1, clinic.address2, clinic.town, clinic.county, clinic.postcode].filter(f => f !== null).join('<br/>')
                    return this.doReplacement(`clinic.${variable}`, address);
                case 'name':
                    return this.doReplacement(`clinic.${variable}`, clinic.name);
                case 'email':
                    return this.doReplacement(`clinic.${variable}`, clinic.email);
                case 'phone':
                    return this.doReplacement(`clinic.${variable}`, clinic.phone);
                case 'website':
                    return this.doReplacement(`clinic.${variable}`, clinic.website);
            }
        });
    }

    //prefixed DATE.
    private replaceDate(variable: string) {
        switch (variable) {
            case 'date':
                return this.doReplacement(`date.${variable}`, new Date().toLocaleDateString("en-GB"));
            case 'time':
                return this.doReplacement(`date.${variable}`, new Date().toLocaleTimeString("en-GB", { hour: "2-digit", minute: "2-digit" }));
            case "add4weeks":
                const plus4 = new Date().getTime() + (4 * 7 * 24 * 60 * 60 * 1000); //.toLocaleDateString("en-GB"))
                return this.doReplacement(`date.${variable}`, new Date(plus4).toLocaleDateString("en-GB"));
        }
    }


    //prefixed PATIENT. 
    private replacePatientInfo(parts: string[]) {
        const variable = parts[1];
        this.patientService.getPatientDetails(this._patientIdx).subscribe((patientInfo: PatientDetails) => {
            console.log(`[letters] request details for ${this._patientIdx}`, patientInfo);
            switch (variable) {
                case 'idx':
                    return this.doReplacement(`patient.${variable}`, this._patientIdx);
                case 'firstname':
                    return this.doReplacement(`patient.${variable}`, patientInfo.firstname);
                case 'lastname':
                    return this.doReplacement(`patient.${variable}`, patientInfo.lastname);
                case 'title':
                    return this.doReplacement(`patient.${variable}`, patientInfo.title);
                case 'fullname':
                    return this.doReplacement(`patient.${variable}`, patientInfo.name);
                case 'dob':
                    return this.doReplacement(`patient.${variable}`, patientInfo.dob);
                case 'address_nocr':
                    return this.doReplacement(`patient.${variable}`, patientInfo.address.filter(f => f !== null).join(', '));
                case 'gp':
                    if (patientInfo.gp[0]){
                        return this.gpReplacement(parts, patientInfo.gp[0].idx);
                    }
                    return variable;
                case 'firstvisit':
                    return this.doReplacement(`patient.${variable}`, patientInfo.firstVisit);
                case 'lastvisit':
                    return this.doReplacement(`patient.${variable}`, patientInfo.lastAppointment.StartTime);
                default:
                    return variable;
            }
        });
    }

    private gpReplacement(parts: string[], gpIdx: number){
        const variable = parts[2];
        this.notesService.getGPData(gpIdx)
            .subscribe((res: any) => {
                switch (variable){
                    case 'title':
                        return this.doReplacement(`patient.gp.${variable}`, res.title);
                    case 'firstname':
                        return this.doReplacement(`patient.gp.${variable}`, res.firstName);
                    case 'lastname':
                        return this.doReplacement(`patient.gp.${variable}`, res.lastName);
                    case 'name':
                        return this.doReplacement(`patient.gp.${variable}`, res.name);
                    case 'address1':
                        return this.doReplacement(`patient.gp.${variable}`, res.address1);
                    case 'address2':
                        return this.doReplacement(`patient.gp.${variable}`, res.address2);
                    case 'town':
                        return this.doReplacement(`patient.gp.${variable}`, res.town);
                    case 'county':
                        return this.doReplacement(`patient.gp.${variable}`, res.county);
                    case 'postcode':
                        return this.doReplacement(`patient.gp.${variable}`, res.postcode);
                    case 'fulladdress':
                        const fullAddress = Object.keys(res).filter(line => ['address1','address2','town','county','postcode'].includes(line) && res[line]).map(item => res[item]).join('<br/>') // GP Surgery address
                        return this.doReplacement(`patient.gp.${variable}`, fullAddress);
                }
          })
    }

    //prefixed STAFF.
    private replaceStaffInfo(variable: string){
        const staffId = this.auth.staffId();
        console.log("[replace] find id ", staffId);
        this.store.select(fromRoot.getStaff).pipe(take(1)).subscribe(res=>{
            const staff = res.find(f=>f.idx ==  staffId);
            console.log("[staff] replace", res);
            switch (variable){
                case 'fullname':
                    let name = `${staff.title} ${staff.firstname} ${staff.lastname}`;
                    if (staff.profReg){
                        name = `${name} (${staff.profReg})`;
                    }
                    return this.doReplacement(`staff.${variable}`, name);
            }
        });
    }

    //PREFIX notes
    private replaceNotesInfo(parts: string[]){
        const table = parts[1];
        const variable = parts[2];
        this.notesService.getData(table, 0, this._patientIdx).pipe(take(1)).subscribe(res => {
            if (res.data[variable]){
                return this.doReplacement(`${parts[0]}.${parts[1]}.${parts[2]}`, res.data[variable]);
            }
        });
    }

    private doReplacement(search, replace) {
        const regEx = new RegExp(`{${search}}`, "ig");
        this.output = this.output.replace(regEx, replace);
    }

}
