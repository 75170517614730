<div class="title m-5">
    <h5>Merge patients</h5>
</div>

<div class="row my-5 mx-2">

    <div class="col-5">
        <mat-label>From</mat-label>
        <patient-chooser *ngIf="!patientFromIsSelected" (selectPatient)="selectedPatientEvent(1, $event)">
        </patient-chooser>
        <div *ngIf="patientFromIsSelected">
            <mat-form-field class="full-width">
                <input matInput disabled [value]="fromPatient?.name">
            </mat-form-field>
        </div>

    </div>

    <div class="col-2 arrow-container">
        <mat-icon class="center">arrow_right_alt</mat-icon>
    </div>

    <div class="col-5">
        <mat-label>To</mat-label>
        <patient-chooser *ngIf="patientFromIsSelected && !patientToIsSelected"
            (selectPatient)="selectedPatientEvent(2, $event)"></patient-chooser>
        <div *ngIf="patientToIsSelected || !patientFromIsSelected">
            <mat-form-field class="full-width">
                <input matInput disabled [value]="toPatient?.name">
            </mat-form-field>
        </div>
    </div>

</div>

<div class="row my-5 mx-2">

    <div class="col-5">
        <div *ngIf="fromPatient">
            <mat-list role="">
                <mat-list-item role="">name: {{fromPatient.name}}</mat-list-item>
                <mat-list-item role="">dob: {{fromPatient.dob}}</mat-list-item>
                <mat-list-item role="">index: {{fromPatient.idx}}</mat-list-item>
                <mat-list-item role="">address: {{fromPatient.address}}</mat-list-item>
              </mat-list>
        </div>
    </div>

    <div class="col-2 arrow-container">
    </div>

    <div class="col-5">
        <div *ngIf="toPatient">
            <mat-list role="">
                <mat-list-item role="">name: {{toPatient.name}}</mat-list-item>
                <mat-list-item role="">dob: {{toPatient.dob}}</mat-list-item>
                <mat-list-item role="">index: {{toPatient.idx}}</mat-list-item>
                <mat-list-item role="">address: {{toPatient.address}}</mat-list-item>
              </mat-list>
        </div>
    </div>

</div>

<div *ngIf="!merged" class="row my-5 mx-2 d-flex justify-content-evenly">
    <h5 class="my-5" *ngIf="patientToIsSelected">You are about to merge <strong>{{fromPatient?.name}}</strong> into <strong>{{toPatient?.name}}</strong></h5>
    <button (click)="onReset()" *ngIf="patientFromIsSelected" mat-raised-button color="warn">Start again</button>
    <button (click)="onMerge()" *ngIf="patientToIsSelected" mat-raised-button color="primary">Merge</button>
</div>