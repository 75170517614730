export const ExtremetiesFields: { label: string, field: string }[] =
[{
  label: "Range of Motion",
  field: "rom"
},
{
  label: "Shoulder Imp",
  field: "Shoulder_Imp"
},
{
  label: "Drop Arm",
  field: "Drop_Arm"
},
{
  label: "Jt Play",
  field: "Jt_Play"
},
{
  label: "Yegason",
  field: "Yegason"
},
{
  label: "Trigger Points",
  field: "Trigger_Points"
},
{
  label: "Phalens",
  field: "Phalens"
},
{
  label: "Mills",
  field: "Mills"
},
{
  label: "Cozens",
  field: "Cozens"
},
{
  label: "McMurrays",
  field: "McMurrays"
},
{
  label: "Valgus/Varus",
  field: "ValgusVarus"
},
{
  label: "Drawers",
  field: "Drawers"
},
{
  label: "Patella Grind",
  field: "Patella_Grind"
},
{
  label: "",
  field: "blank_2"
},
{
  label: "",
  field: "blank_1"
}

]

export const AideMemoire: {title: string, categories: string[]}[] = [
    {
      title: "Shoulder",
      categories: ["Apprehension/Anterior Release", "Bursa Tests", "Drop Arm", "Joint Play", "Hawkins Kennedy","Neer's Test","Painful Arc", "Speed's Test","Yergason's", "Others"]
    },
    {
      title: "Elbow",
      categories: ["Ligamint stability","Joint Play","Tinel's Test","Medial/Lateral Epi Tests","Others"]
    },
    {
      title: "Wrist and Hand",
      categories: [ "Finkelstein's","Allen's Test","Tinel's Test","Phalen/Reversed Phalen","Joint Play","Median/Ulnar/Radial Tension Tests","Others"]
    },
    {
      title: "Hip",
      categories: ["Anterior/Posterior Labral Tear Test","Craig's Test","Anvil/Fulcrum Test","Others"]
    },
    {
      title: "Knee",
      categories: ["Anatomical leg length","Thessaley Test","Squat Test","Duck Walk","McMurray's","Pivot Shift","Draw Sign","Lachman","Patella Grind","Apley's Compresion/Distraction","Valgus/Varus Stress","Joint Swelling","Joint Play","Fabere/Lasegue","Others"]
    },
    {
      title: "Ankle and Foot",
      categories: ["Anterior/Posterior Draw Test","Anterior/Posterior Inversion Stress Test","Lunge Test","High Ankle Sprain Tests","Tarsal Tunnel","Impingement Sign","Functional Hallux Limitus","Morton's Neuroma","Joint Play","Others"]
    }
  ];
