export interface LatestStatus {
    idx?: number;
  //  locked?: number;
 //   changeRequested?: number;
 //   signatureCount?: number;
    formName?: string;
}

export const initialLatestStatus: LatestStatus = {
    idx: -1,
 //   locked: null,
  //  changeRequested: null,
  //  signatureCount: 0,
    formName: ""
  };



export const NotesTabComponents = {
    PATIENT_DETAILS: 'info-patient-details',
    ATTENDANCE: 'info-attendance',
    NOTES_TABLE: 'info-notes-table',
    TELEPHONE: 'app-telephone-consultation',
    UPLOADER: 'app-uploader'
  };

  export interface NoteTab {
    id?: number;
    name: string;
    title: string;
    info?: any;
    formName?: string;
    visibleConfigKey?: string;
    visible?: boolean;
    recordCount: number;
    component: string;
  }
  


  export const NotesTypes = {
    ATTENDANCE: 'attendance',
    CASE_HISTORY: 'casehistory',
    LETTER: 'letters',
    PATIENT_DETAILS: 'patientInfo',
    PHYSICAL_EXAM: 'physicalexam',
    SOAP: 'soap',
    SUMMARY: 'casesummary',
    TELEPHONE: 'telephone',
    TREATMENT_CARD: 'treatmentcards',
    UPLOAD: 'uploads',
    QUESTIONNAIRE: 'patientquestionaire'

  };

  export const NoteTabs: NoteTab[] = [
    {
      name: NotesTypes.PATIENT_DETAILS,
      title: 'Details',
      visible: true,
      component: NotesTabComponents.PATIENT_DETAILS,
      recordCount: 0
    },
    {
      name: NotesTypes.ATTENDANCE,
      title: 'Attendance',
      visible: true,
      component: NotesTabComponents.ATTENDANCE,
      recordCount: 0
    },
    {
      name: NotesTypes.CASE_HISTORY,
      title: 'History',
      formName: 'notes_casehistory',
      visibleConfigKey: 'enableCaseHistory',
      component: NotesTabComponents.NOTES_TABLE,
      recordCount: 0
    },
    {
      name: NotesTypes.QUESTIONNAIRE,
      title: 'Questionnaire',
      formName: 'notes_patientquestionaire',
      visibleConfigKey: 'enableQuestionnaire',
      component: NotesTabComponents.NOTES_TABLE,
      recordCount: 0
    },
    {
      name: NotesTypes.PHYSICAL_EXAM,
      title: 'Phys. Exam',
      formName: 'notes_physicalexam',
      visibleConfigKey: 'enablePhysicalExam',
      component: NotesTabComponents.NOTES_TABLE,
      recordCount: 0
    },
    {
      name: NotesTypes.SUMMARY,
      title: 'Summary',
      formName: 'notes_casesummary',
      visibleConfigKey: 'enableCaseSummary',
      component: NotesTabComponents.NOTES_TABLE,
      recordCount: 0
    },
    {
      name: NotesTypes.SOAP,
      title: 'SOAP',
      formName: 'notes_soap',
      visibleConfigKey: 'enableSOAP',
      component: NotesTabComponents.NOTES_TABLE,
      recordCount: 0
    },
    {
      name: NotesTypes.LETTER,
      title: 'Letters',
      formName: 'letters',
      visibleConfigKey: 'enableLetters',
      component: NotesTabComponents.NOTES_TABLE,
      recordCount: 0
    },
    {
      name: NotesTypes.TELEPHONE,
      title: 'Phone Consult',
      visibleConfigKey: 'enablePhoneConsult',
      component: NotesTabComponents.TELEPHONE,
      recordCount: 0
    },
    {
      name: NotesTypes.UPLOAD,
      title: 'Files',
      visibleConfigKey: 'enableUploads',
      component: NotesTabComponents.UPLOADER,
      recordCount: 0
    },
    {
      name: NotesTypes.TREATMENT_CARD,
      title: 'Treatment Cards',
      formName: 'notes_treatmentcard',
      visibleConfigKey: 'enableTreatmentCards',
      component: NotesTabComponents.NOTES_TABLE,
      recordCount: 0
    }
  ];
