import { Injectable } from '@angular/core';
import { MatDialog } from "@angular/material/dialog";
import { FutureAppointment } from 'app/models/appointments';
import { ClinicService } from 'app/services/clinic.service';
import { AppointmentsService } from 'app/services/diary/appointments.service';
import { LoadingService } from 'app/services/loading.service';
import { PatientChooserService } from 'app/services/patient.service';
import { forkJoin, Observable } from 'rxjs';
import { PrintCardDialogComponent, PrintCardDialog } from "./print-card-dialog.component";

@Injectable({
  providedIn: 'root'
})
export class PrintCardDialogService {
  private dialogOpen: number = 0;

  constructor(
    public dialog: MatDialog,
    private appointments: AppointmentsService,
    private patient: PatientChooserService,
    private clinic: ClinicService,
    private loadingService: LoadingService ) { }

  public getAppointmentDetails(appointmentIdx: number){
    console.log("[app saved] show dialog for", appointmentIdx)
    if (this.dialogOpen>0){
      return;
    }
    this.dialogOpen = appointmentIdx;
    this.loadingService.start();

      this.appointments.getFutureAppointment(appointmentIdx, "appointment").subscribe(( appointment: Array<FutureAppointment> ) => {
        if ( !appointment.length ){
          this.displayCardDialog(appointment).subscribe(dialogResult => {
            this.dialogOpen=0;
          }); //no future apps?
          return;
        }  
        const emailO: Observable<{ id: number, email: string }> = this.patient.getPatientEmail(appointment[0].patientIdx);
        const clinicO: Observable<any> = this.clinic.getAddress(appointment[0].clinicIdx);

        forkJoin({
          email: emailO,
          clinic: clinicO
        })
        .subscribe( val => {
            appointment = appointment.sort((a: any, b: any) => a.bookingMadeAt ? -1 : b.bookingMadeAt ? 1 : 0);

            this.displayCardDialog(appointment, val.email.email, val.clinic.clinic).subscribe(dialogResult => {
              this.dialogOpen = 0;
            });
          })
        });
  }

  public displayCardDialog(appointments: Array<FutureAppointment>, patientEmail: string = null, clinicDetails: any = null): Observable<any> {
    console.log("[dialog]", clinicDetails);
    const dialogData = new PrintCardDialog(appointments, patientEmail, clinicDetails);
    const dialogReference = this.dialog.open(PrintCardDialogComponent, { width: "500px", data: dialogData });
    this.loadingService.stop();
    return dialogReference.afterClosed();
  }

 
}
