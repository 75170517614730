import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from '../services/data.service';

@Injectable()
export class PatientPurgeService {
  constructor(private http: DataService) { }

  public getPatients(minAge: number) {
    return this.http.get(`/patients/inactive/${minAge}`);
  }

  public beginThePurge(password: string, rows: any[]) {
    const payload = { password: password, rows: rows };
    return this.http.post(`/patients/inactive`, payload)
  }
}
