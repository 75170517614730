export interface SubMenuItem{
    idx: number;
    title: string;
    link: string;
    score: number;
    favourite: boolean;
}



export interface MenuItem{
    title: string;
    link?: string;
    subItems?: SubMenuItem[];
}

export interface State {
    items: Array<MenuItem>;
    filterItems?: Array<MenuItem>;

};

export const initialState: State = {
    items: [],
    filterItems: []
};
