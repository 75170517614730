import { Component, EventEmitter, HostListener, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { SoapService } from 'app/services/notes/soap.service';
import { FilesService } from '../uploader/files.service';
import { DialogService } from 'app/services/dialog/dialog.service';
import { FormDialogQuestion, FormOptions } from "../../models/dialog"
import { NotesService } from 'app/services/notes/notes.service';
import { NotificationService } from 'app/services/notification.service';
import { initialSOAP, initialSOAPData, SOAPData, SOAPRecord } from 'app/models/soap';
import { LoadingService } from 'app/services/loading.service';
import { TreatmentService } from 'app/services/treatment.service';
import { DataService } from 'app/services/data.service';
import { SaveDialogComponent } from 'app/dialogs/save-dialog/save-dialog.component';
import { SiteSettingsService } from 'app/services/site-settings.service';
import moment from 'moment';
import { SignatureService } from 'app/services/notes/signature.service';
import { PatientInfoBoxService } from 'app/services/notes/patient-info-box.service';
import { PatientInfo, initialPatientInfo } from 'app/models/patient-details';
import { take } from 'rxjs';
import { AuthService } from 'app/services/auth.service';
import { AvailablePermissions } from 'app/models/auth';
import { FilePickerService } from '../uploader/lib/file-picker.service';
import { UploadedFile } from '../uploader/uploader.component';

@Component({
  selector: 'app-soap',
  templateUrl: './soap.component.html',
  styleUrls: ['./soap.component.scss', '../info-table-shared.css'],
  providers: [SoapService]
})
export class SoapComponent implements OnInit, OnDestroy {
  @Input('selectedNotes') selectedNotes: { idx: number; description: string; };
  @Output("newNotesCreated") newNotesCreated: EventEmitter<any> = new EventEmitter();

  public files: UploadedFile[] = [];
  public diagnosisDisabled: boolean = true;
  public notesIdx: number;
  public soapData: SOAPData = { soaps: [], diagnosis: {}, patientIdx: 0, notesIdx: 0 };
  public isSortingNewestFirst: boolean = false;
  public discharged: boolean = false;
  public recommendedRevisitSet: boolean = false;
  public today: Date = new Date();
  public hideSoap: boolean = true;
  public newSoap: SOAPRecord;
  public patientIdx: number;
  public soapSignatures: boolean[] = [];
  public soapSignaturesData: any[] = [];
  public caseHistories: any[] = [];
  public signaturesEnabled: boolean;
  private savedState?: any;
  private canLeave = false
  private soapCompleted = 0;
  private saveSoapInterval$: any;
  public rxNoEnabled = true;
  public refreshRequired = false;
  public info: PatientInfo = initialPatientInfo;
  public allowDischarge: boolean = false;
  fileTest: void;

  constructor(
    private dataService: DataService,
    private route: ActivatedRoute,
    private soapService: SoapService,
    private fileService: FilesService,
    private dialogService: DialogService,
    private notesService: NotesService,
    private treatments: TreatmentService,
    private notification: NotificationService,
    private dialog: DialogService,
    private loading: LoadingService,
    private settings: SiteSettingsService,
    private pibs: PatientInfoBoxService,
    private signatureService: SignatureService,
    public authService: AuthService,
    private filePickerService: FilePickerService) {
    this.newSoap = Object.assign({}, initialSOAP, {date: new Date()});
  }

  /**
   * Checkes for unsaved changes and prompts user if
   * they want to leave the page when refreshing/navigating
   * outside of app.
   * @returns {boolean} Returns a boolean
   */
  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHander(event) {
    return this.canLeave //if true user can leave else a warning box comes up
  }



  ngOnInit() {
    this.filePickerService.setDisplayItemTemplate(true);
    console.log("[notes] soap ng on inits");
    this.route.params.subscribe((params: Params) => {
      if (params["notesIdx"]) {
        this.notesIdx = +params['notesIdx'];
   //     this.signatureService.notesIdxChange$.next(this.notesIdx)
        this.diagnosisDisabled = false;
        this.discharged = false;
        this.soapData = initialSOAPData;
        this.updateNotes();
      }
      this.settings.getNotesSettings().subscribe(res => {
        this.signaturesEnabled = res.enableSignOff;
        this.rxNoEnabled = res.enableRxNo;
      })

      this.allowDischarge = !this.authService.permission(AvailablePermissions.allow_discharge)    
    });

    this.isSortingNewestFirst = localStorage.getItem('isSortingNewestSOAPFirst') === "true";

    this.notesService.patientIdx().subscribe((patientIdx: number) => {
      if (patientIdx) {
        console.log(`[notes] got new patient ID ${patientIdx}`);
        this.soapData = initialSOAPData;
        this.patientIdx = patientIdx;
        this.notesIdx = 0;

        if (this.notesIdx==0){
          this.updateNotes();
        }
      }
    });
  
  }

  private startAutoSave(){
      this.stopAutoSave();
    
    this.saveSoapInterval$ = setTimeout(() => {
      console.log("[soap] autosaves")
      this.autoSave();
    }, 3000);
  }

  private stopAutoSave(){
    if (this.saveSoapInterval$){
      clearTimeout(this.saveSoapInterval$);
    }
  }

  toggleSort() {
    this.isSortingNewestFirst = !this.isSortingNewestFirst;
    this.soapData.soaps.reverse();
    localStorage.setItem('isSortingNewestSOAPFirst', this.isSortingNewestFirst.toString()); // :@ why is this not in Settings Brooklyn?
  }

  private filterSoapFiles(soaps: SOAPRecord[], files: any): any {
    return soaps.map((soap: any) => {
      if (soap.file) soap.file = JSON.parse(soap.file).map((s: any) => Number(s));

      soap.file = files.filter((f: any) => soap.file && soap.file.includes(f.idx));

      if (soap.CancelDate) soap.isActive = false;

      soap.showFiles = false;

      return soap;
    })
  }

  private diagnosisFilled(diagnosis: any): boolean {
    return (diagnosis.clinicaldiagnosis || diagnosis.diagnosis || diagnosis.planofmanagement || diagnosis.redflags);
  }

  private updateNotes(): void {
    console.log(`[soap] got request to load id ${this.notesIdx} for ${this.patientIdx}`);

    if (this.notesIdx > 0 || this.patientIdx > 0) { //need either notes id or patient id
      this.loading.start();


      this.soapService.getSoap(this.notesIdx, this.patientIdx).subscribe((soapData: SOAPData) => {
        // If the last soap is not completed we load it, otherwise we load an empty soap
        if (soapData.soaps.length && !soapData.soaps[0].completed) {

          this.newSoap = {
            date: soapData.soaps[0].date,
            A: soapData.soaps[0].A,
            O: soapData.soaps[0].O,
            P: soapData.soaps[0].P,
            S: soapData.soaps[0].S,
            idx: soapData.soaps[0].idx,
            revisedTime: soapData.soaps[0].revisedTime,
            isActive: true,
          };
          this.files = soapData.soaps[0].files;
        } else {
          this.newSoap = Object.assign({}, initialSOAP, {date: new Date()});

        }
        this.refreshRequired = false;
        console.log('[soap] data=', soapData);
        
        if (this.patientIdx!=soapData.patientIdx){
          console.log("[soap] rejected as patient id is wrong")
          //reject! 
          this.loading.stop();
          return;
        }

        this.soapData = soapData;
        this.patientIdx = soapData.patientIdx;
        this.notesIdx = soapData.notesIdx;

        //this.soapData.diagnosis = soapData.diagnosis;
        if (this.soapData.diagnosis.discharged && this.soapData.diagnosis.discharged !== "0000-00-00 00:00:00") this.discharged = true;
        if (this.soapData.diagnosis.isActive && this.diagnosisFilled(this.soapData.diagnosis)) this.diagnosisDisabled = true;

          this.savedState = JSON.parse(JSON.stringify(this.soapData.diagnosis))

          this.soapSignatures = [];
          this.soapSignaturesData = [];

          this.soapData.soaps.forEach((data: any) => {
            this.soapSignatures.push(false);
            this.soapSignaturesData.push(null);
          });
          this.loading.stop();
      
      });
    } else {
      console.log("not enough data to load notes");
    }

  }

  public recover(soap: SOAPRecord){
    this.newSoap = {
      date: soap.date,
      A: soap.A,
      O: soap.O,
      P: soap.P,
      S: soap.S,
      idx: 0,
      revisedTime: Math.floor(new Date().getTime()/1000),
      isActive: true,
    };
  }

  public fileUploaded(event: any): void {
    for (let file of event) {
      this.files.push(file);      
    }
  }

  public removeFile(file: UploadedFile){
    this.fileService.deleteFile(file.fileId).subscribe(res=>{});
    this.files = this.files.filter(f=>(f!=file));
  }

  deactivateSoap(soap) {
    soap.isActive = !soap.isActive
    // Set up dialog info
    const title = "Deactivate SOAP";
    const description = "Please enter reason for deletion";
    const form = [new FormDialogQuestion('Reason', 'reason', 'textarea', null)]

    // Open dialog, process response
    this.dialogService.formDialog(title, description, form, null, ['Cancel'], ['Delete'])
      .then((res: any) => {
        const reason = res.data[0].value;
        this.soapService.deleteSoap(this.notesIdx, this.patientIdx, soap.idx, reason).subscribe(res => this.updateNotes())
      })
  }

  saveFormClicked() {
    this.stopAutoSave();
    this.newSoap.completed = true;
    this.saveForm();
    this.filePickerService.setDisplayItemTemplate(false);
  }

  autoSave(){
    const fileArr = this.files.map(f=>(f.idx));
    this.newSoap.completed = false;

    if (this.newSoap.S || this.newSoap.O || this.newSoap.A || this.newSoap.P) {
      this.soapService.saveSoap(this.notesIdx, this.newSoap, fileArr).subscribe(res => {
        this.newSoap.idx = res.idx;
        this.newSoap.revisedTime = res.revisedTime;
        console.log("[soap] autosave done");
        if (res.updated==0){
          this.notification.send("Autosave rejected as there is newer SOAP notes saved already");
          this.refreshRequired = true;
        }
      });
    }
  }


  saveForm() {
    // save if at least one form field is filled
    if (this.newSoap.S || this.newSoap.O || this.newSoap.A || this.newSoap.P) {
      this.loading.start();

      //if (!this.diagnosisDisabled) this.saveClinicalDiagnosis();

      this.saveSOAP()
      this.recommendedRevisitSet = false;
    }
  }

  saveSOAP(soap?: any) {
    
    if (!soap) {
      soap = this.newSoap;
    }

  
    const fileArr = this.files.map(f=>(f.idx));

    soap.assocDiagnosis = this.soapData.diagnosis.idx;

    this.soapService.saveSoap(this.notesIdx, soap, fileArr).subscribe(res => {
      soap.idx = res.idx;
      soap.file = [...this.files];
      if (res.updated==0){
        this.loading.stop();
        this.notification.send("Save rejected as there is newer SOAP notes saved already");
        this.refreshRequired = true;
        return;
      }
      
      this.files = [];
      // this.file = [];
      this.soapData.soaps.unshift(soap);
      this.updateNotes();
      this.canLeave = true;
      this.soapCompleted = 0;
      this.loading.stop();
      this.startAutoSave();
    });
  }

  saveClinicalDiagnosis(discharge?: boolean, reason?: string) {
    this.loading.start();

    console.log("[nathan] reason", reason)

    let diagnosis = {
      clinicaldiagnosis: this.soapData.diagnosis.clinicaldiagnosis,
      diagnosis: this.soapData.diagnosis.diagnosis,
      planofmanagement: this.soapData.diagnosis.planofmanagement,
      redflags: this.soapData.diagnosis.redflags,
      discharged: discharge ? moment().format("YYYY-MM-DD HH:mm:ss") : null,
      discharged_reason: reason || null,
      isActive: "TRUE"
    };

    let payload = {
      formName: 'notes_diagnosis',
      formIdx: 0,
      idx: this.notesIdx,
      form: [diagnosis]
    }

    this.notesService.saveDiagnosis(payload).subscribe((diagnosisUpdated: any) => {
      if (diagnosisUpdated) {
        if (discharge) {
          this.discharged = true;
          this.updateNotes();
          this.notification.send("You have discharged this soap successfully.");
        } else {
          this.updateNotes();
          this.notification.send("You have saved the clinical diagnosis successfully.");
        }
      }
    });
  }

  private newSoapAdding(soap: SOAPRecord): boolean {
    return (soap.S === "" && soap.O === "" && soap.A === "" && soap.P === "");
  }

  download(file: UploadedFile): void {
    this.fileService.getFile(file.fileId);
  }

  dischargePatient() {

    // Set up dialog info
    const title = "Discharge Patient";
    const description = "Are you sure you would like to discharge this patient?";
    const formFields = [new FormDialogQuestion('Reason', 'reason', 'textbox', null)]

    this.dialogService.formDialog(title, description, formFields, null, ['Cancel'], ['Discharge'])
      .then((form: any) => {
        if (form.data[0].value) {
          if (!this.newSoapAdding(this.newSoap)) {
            this.saveSOAP();
            setTimeout(() => this.saveClinicalDiagnosis(true, form.data[0].value), 2000);
          } else {
            this.saveClinicalDiagnosis(true, form.data[0].value);
          }
        }
      })
  }

  setRecommendedRevisit(): void {
    this.treatments.getTreatments().subscribe((treatments: any) => {
      const title = "Recommended Revisit";
      const formFields = [
        new FormDialogQuestion('Length', 'length', 'number', null),
        new FormDialogQuestion('Period', 'period', 'select',
          [
            new FormOptions(1, 'Days'),
            new FormOptions(7, 'Weeks'),
            new FormOptions(28, 'Months (4 weeks)')
          ]),
        new FormDialogQuestion('Treatment', 'treatment', 'select', treatments.map(treatment => { return { idx: treatment.idx, text: treatment.name } }))
      ]
      this.dialogService.formDialog(title, null, formFields, null, ['Cancel'], ['Submit'], this.submitRecommendedRevisit.bind(this))
        .then((response: any) => {
          if (!response) return;
          if (response.error) {
            this.dialogService.genericDialog('Error', response.error, ['OK'], null, null)
          }
          else {
            this.recommendedRevisitSet = true
          }
        })
    });
  }

  submitRecommendedRevisit(form: any): Promise<any> {
    /* Used as a callback to the dialog, resolves true if submitted successfully,
    resolves the error message otherwise */

    const payload = {
      patientIdx: this.patientIdx,
      days: Number(form.data[0].value) * Number(form.data[1].value),
      treatmentIdx: form.data[2].value,
    };
    return new Promise((resolve, reject) => {
      this.dataService.post('/diary/revisit', payload).subscribe((response: any) => {
        if (response.error) {
          resolve(response)
        } else {
          resolve(true)
        }
      });
    })
  }

  private checkSavedState(a: any, b: any) {
    if (JSON.stringify(a) === JSON.stringify(b) && JSON.stringify(this.newSoap) === JSON.stringify(initialSOAP)) {
      return true
    }
    return false
  }

  /**
   * Checks if there are unsaved changes and prompts user if they want to leave the page.
   * @returns {Promise<boolean> | boolean} Returns a promise resolving to a boolean or a boolean
   * indicating whether the user can deactivate the current route.
   */
  public canDeactivate(): Promise<boolean> | boolean {
    if (this.checkSavedState(this.savedState, this.soapData.diagnosis)) {
      return true;
    }
    return this.dialog.bespokeDialog('Are you sure you want to leave this page? Any unsaved data will be lost', SaveDialogComponent, 'save', 'Unsaved Data', null, "550px")
      .then((res: boolean | 'save') => {
        if (res === 'save') {
          this.saveForm();
          return true;
        } else {
          return res
        };
      }
      ).catch(err => {
        return false
      })
  }

  ngOnDestroy(): void {
 //   this.signatureService.notesIdxChange$.next(0);
    this.signatureService.stopInterval();
    this.stopAutoSave();
  }

  soapChanges(){
    console.log("[soap] changes");
    this.startAutoSave();
  }

  refreshNotes(){
    this.updateNotes();
  }
}

