import { Injectable } from '@angular/core';
import { DataService } from '../../services/data.service';
import { Appointment, ProcessedAppointment } from '../../models/appointments';
import { Observable, Subject } from 'rxjs';
import { AppointmentStatus } from '../../models/diary';
import { Dexie } from "dexie";
import { LoadingService } from '../loading.service';


import { Store } from '@ngrx/store';
import * as fromRoot from "../../store/reducers";
import * as fromAppointments from '../../store/actions/appointments';
import * as fromDiary from "../../models/diary";
import { EmailMessage } from 'app/models/email';

import { AppointmentTable } from "../../models/local-db";
import { LocalDBService } from '../local-db.service';
import { endOfWeek, format, startOfWeek } from 'date-fns';


@Injectable({
  providedIn: 'root'
})
export class AppointmentsService extends LocalDBService {

  //  AppointmentTable: Dexie.Table<Appointment>;

  innerObserveApps$;
  observeApps: Subject<Appointment[]>;

  private showCancelled = false;
  private lastArgs: Appointment;
  private currentView: fromDiary.ViewTypes;
  private soloStaff: number;
  private emitAppDebounce$;
  private emitAppLock: boolean;
  private lastTimestamp = 0;

  constructor(
    public api: DataService,
    private store: Store<fromRoot.State>,
    private loadingService: LoadingService
  ) {
    super();

    this.observeApps = new Subject();

    this.store.select(fromRoot.getShowCancelled).subscribe(res => {
      console.log("[apps update] getShowCancelled");
      this.showCancelled = res;
      this.lastTimestamp = 0;
      this.forceAppointmentEmit();
    });

    this.store.select(fromRoot.getCurrentDiaryPrivateView).subscribe(data => {
      console.log("[apps update] getCurrentDiaryPrivateView");
      this.lastTimestamp = 0;
      this.forceAppointmentEmit();
    });

    this.store.select(fromRoot.getCurrentDiaryView).subscribe(data => {
      console.log("[apps update] getCurrentDiaryView");
      this.lastTimestamp = 0;
      // this.forceAppointmentEmit();
      this.currentView = data;
    });

    this.store.select(fromRoot.getSelectedStaff).subscribe(res => {
      console.log("[apps update] getSelectedStaff");
      this.lastTimestamp = 0;
      this.forceAppointmentEmit();
      if (this.currentView == fromDiary.ViewTypes.WEEK && res[0]) {
        this.soloStaff = res[0].idx;
//        console.log("[apps update] selected Staff", res);
    //    this.forceAppointmentEmit();
      } else {
        this.soloStaff = null;
      }

    });

    this.store.select(fromRoot.getCurrentViewDate).subscribe(data => {
      console.log("[apps update] getCurrentViewDate");
      this.lastTimestamp = 0;
      this.forceAppointmentEmit();

    });
  }

  public reInitDB() {
    this.open();
  }


  public forceAppointmentEmit() {

    if (this.emitAppLock) {
      return;
    }

    if (this.emitAppDebounce$) {
      clearTimeout(this.emitAppDebounce$);
    }

    if (this.lastArgs) {
      this.emitAppDebounce$ = setTimeout(() => {
        this.emitAppLock = true;
        this.getAppsForDay(this.lastArgs.startDay, this.lastArgs.endDay).then(res => {
          this.emitAppointments(res);
          console.log("[apps] [db] 2. force Emit has emitted");
          this.emitAppLock = false;
        });
      }, 50);
    }
  }

  deleteDB() {
    this.delete().then(() => {
      this.open();
    });
  }

  loadFromServer(args: Appointment = null) {

    if (!args) {
      args = this.lastArgs;
//      console.log("[apps] load using last settings", args);
    } else {
  //    console.log("[apps] load using new settings", args);
    }


    if (!args) {
//      console.log("[apps] [db] load aborted no args");
      return;
    }

    const firstTime: boolean = !this.lastArgs;

    if (!this.lastArgs || this.lastArgs.startTime != args.startTime || this.lastArgs.endTime != args.endTime) {
      this.lastArgs = { ...args }; // this had got angry as messing with state
      const startDay = this.startDay(args.startTime, 12);
      const endDay = this.startDay(args.endTime, -12);
      this.lastArgs.startDay = startDay;
      this.lastArgs.endDay = endDay;
   //   this.observeAppsOnDay(startDay, endDay); // do we need to resubscribe on loadFromServer?
      if (firstTime) {
        this.forceAppointmentEmit();
      }
    }

    setTimeout(() => {
      this.loadingService.backgroundStart();
      this.api.post(`/diary/appointments?last=${this.lastTimestamp}`, args).subscribe(res => {
        this.storeToDB(res);
        this.loadingService.backgroundEnd();
      });
    });
  }

  private startDay(time, hrShift) {
    const date = new Date(time);
    const dayNumber = Math.floor(((date.getTime() / (1000 * 60 * 60)) + hrShift) / 24); // day count.
    return dayNumber;
  }

  private async getAppsForDay(start, end) {
    console.log(`[apps] get from ${start} tp ${end}`, this.lastArgs);
    console.trace();

    if (!this.soloStaff) { // never send everyone in week view
      end = start;
    }
    let apps = [];

    try {
      apps = await this.table(AppointmentTable).where('dayNumber').between(start, end, true, true).toArray();
    } catch (err) {
      console.warn(err);
      this.reInitDB();
    }

    if (!this.showCancelled) {
      apps = apps.filter(f => f.status != AppointmentStatus.CANCELLED && f.status != AppointmentStatus.LNC && f.status != AppointmentStatus.LNCPAID);
    }

    if (this.soloStaff) {
      apps = apps.filter(f => f.staffIdx === this.soloStaff);
    }
/*
    // Needs to check the group size currently no value
    apps = apps.map(app => {
      const isGroupBooking = app.groupSize > 1 ;
      return { ...app, isGroupBooking };
    });
*/
    return apps;
  }

  private emitAppointments(result) {
    console.log(`[db] (: emit :) Got result `, result);
    this.observeApps.next(result);
  }

  private storeToDB(apps) {
    console.log("[db] saving", apps);
    const appPromises: any[] = [];
    for (const app of apps) {
      if ( app.lastModified > this.lastTimestamp ) {
        this.lastTimestamp = app.lastModified;
      }
      app.dayNumber = this.startDay(app.startTime, 0);
      appPromises.push(
        this.table(AppointmentTable)
      .where(':id')
      .equals(app.idx)
      .first()
      .then<any>(
        res => {
          if (!res) {

            return this.table(AppointmentTable).add(app).then(res => {
              return true;
            },
              err => {
                console.warn("[db] add error", app, err);
                return false; // update clash was already in DB
              });
          } else if (res.lastModified != app.lastModified || res.nextAppointment != app.nextAppointment) {

            return this.table(AppointmentTable).update(app.idx, app).then((updated => {
              return true;
            }));
          }

          return false;

        },
        err => {
          console.warn("[db] locate to update fails with an error", err);
          return err;
        })
      );
    }

    // wait till DB add completes and will have an array of true|false depending if any changes were found
    Promise.all(appPromises).then(res => {
      if (res.find(r => r == true)) {
        this.forceAppointmentEmit();
      }
    });


    return apps;
  }

  refreshAppointmentInfo(idx: number): Observable<any> {
    return this.api.get(`/diary/appointment/${idx}`);
  }

  getAppointment(idx: number): Observable<any> {
    return this.api.get(`/diary/appointment/${idx}`);
  }

  sendAppointmentEmail(payload: { patientIdx: number, email: string, message: EmailMessage }): Observable<any> {
    return this.api.post<any>(`/diary/appointments/email`, payload);
  }

  public getFutureAppointment(idx: number, field: string): Observable<any> {
    // field can be appointment or patient
    return this.api.get<any>(`/diary/appointments/future?${field}=${idx}`);
  }

  clashCheck(payload: fromDiary.Appointment): Observable<string[]> {
    return this.api.post<string[]>('/diary/clashcheck', payload);
  }

  public emailBookingPatient(payload: any): Observable<any> {
    return this.api.post("/diary/appointments/booking/email", payload);
  }

  public emailAppointmentPatient(payload: any): Observable<any> {
    return this.api.post("/diary/appointments/confirm/email", payload);
  }

  public saveStartTime(idx: number, newStart: String, duration: number, newRoom: any): void {
    this.store.dispatch(fromAppointments.SaveStartTime({ payload: { idx, newStart, duration, newRoom } }));
  }

  public emailCancelPatient(payload: any): Observable<any> {
    return this.api.post("/diary/appointments/cancel/email", payload);
  }

  public updateStatus(appointment: ProcessedAppointment, newStatus: string, notes?: string) {
    console.log("[flex]", appointment);

    const data = { lastStatus: appointment.meta.status, status: newStatus, notes: notes || appointment.meta.notes };

    appointment.meta.status = newStatus;

    this.api.post(`/diary/appointment/${appointment.idx}`, data).subscribe(res => { });

    this.table(AppointmentTable).update(appointment.idx, { status: newStatus }).then(
      res => {
        console.log(`[db] updated: ${res} as we set ${appointment.idx} to ${newStatus}`);
        this.forceAppointmentEmit();
      },
      err => {
        console.log("[db] app status update fails");
      }
    );
    //    this.getAppointmentsFromServer()); //let this happen on normal schedule
  }


  save(payload: fromDiary.Appointment, reminders: any = null): Observable<string[]> {
    console.log("[diary] pre", payload);

    const adjustedTime = new Date(payload.startTime);
    adjustedTime.setTime(adjustedTime.getTime() - adjustedTime.getTimezoneOffset() * 60 * 1000);

    // dont edit object as it gets reflected in the UI
    console.log("[diary] post", payload);

    if (payload.idx > 0) {// edit
      return this.api.post<string[]>(`/diary/appointment/${payload.idx}`, Object.assign({}, payload, { startTime: adjustedTime, reminders: reminders }));      
    }
    return this.api.post<string[]>('/diary/appointment', Object.assign({}, payload, { startTime: adjustedTime, reminders: reminders }));
  }

  public getGroups() {
    return this.api.get("/groups");
  }

}
