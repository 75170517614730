<div [formGroup]="form" class="form-group">
  <mat-form-field style="width:100%">
    <mat-label>{{question.label}}</mat-label>
    <mat-select #inputValue [id]="question.key" [formControlName]="question.key" [(value)]="question.value"
      [multiple]="true">

      <div *ngIf="this.question.multichoice == 1" class="select-all">
        <button mat-button (click)="selectAllToggle()">
          <mat-icon>done_all</mat-icon>
          <span ml="1">Select All/None</span>
        </button>
      </div>

      <mat-optgroup *ngFor="let cat of optionsGrouped" [label]="cat.text">
        <div *ngIf="this.question.multichoice == 1" class="select-all">
          <button mat-button (click)="selectAllFromCat(cat)">
            <mat-icon>done_all</mat-icon>
            <span ml="1">Select All/None from {{cat.text}}</span>
          </button>
        </div>

        <mat-option *ngFor="let option of cat.subcat" [value]="option.idx">{{option.text}}</mat-option>

      </mat-optgroup>

    </mat-select>
    <mat-error *ngIf="fieldInvalid() && showInvalidField">{{fieldInvalidError()}}</mat-error>
  </mat-form-field>

</div>



