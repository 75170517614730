import { AfterViewInit, Component, ElementRef, Input, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { InvoiceService } from "./invoice.service";
import { Store } from "@ngrx/store";
import * as fromRoot from "../../store/reducers";
import { take } from "rxjs/operators";
import { QuestionService } from "../../dynamic-form/services/question.service";
import { ReportsService } from "../../reports/reports.service";
import { PatientChooserService } from "../../services/patient.service";
import { PatientInfoBoxService } from 'app/services/notes/patient-info-box.service';
import { MatDialog } from "@angular/material/dialog";
import { GiftCardModalComponent } from "app/till/gift-card-modal/gift-card-modal.component";
import { NavigationService } from "app/services/navigation.service";
import { NotificationService } from "app/services/notification.service";
import { isToday } from "date-fns";

import { AngularEditorConfig } from "@kolkov/angular-editor";
import { EditorConfig } from "../../models/editor-config";
import { EmailTemplates } from "app/models/email";
import { PatientAppointmentHistoryService } from "app/services/patient-appointment-history.service";
import { ClinicService } from "app/services/clinic.service";
import { EmailTemplatesService } from "app/settings/email-templates/email-templates.service";
import { TextReplacementService } from "app/services/text-replacement.service";

@Component({
  selector: "app-invoice",
  templateUrl: "./invoice.component.html",
  styleUrls: ["./invoice.component.scss"],
})
export class InvoiceComponent implements AfterViewInit {
  @ViewChild('invoice', { static: true }) invoice: ElementRef;
  @Input('email') email: string;
  noEmail = false;
  transactionIdx: number;
  invoiceTo: string[];
  invoiceFrom: string;
  saleTotal: number;
  paymentTotal: number;
  items: any[];
  transaction: any;
  payments: any[];
  credits: any;
  clinic: string[];
  patient: string[];
  documentType = "RECEIPT";
  invoiceLogo: string;
  clientEmail: string;
  dob: string;
  image: string;
  vouchers: any[];
  nextVisit?: string;
  patientIdx: number;
  transactions: any[];
  patientName: string;
  patientAddress: string[] = [];
  currentBalance = 0;
  currentCreditBalance = 0;
  totalSpend = 0;
  totalPayments = 0;
  transactionListView = false;
  canVoid = false;
  canRefund = false;
  insurance = false;
  insuranceInvoice = false;
  insuranceNumber: string;
  patientNameForInsurance: string;
  patientAddressForInsurance: string;
  patientDOBForInsurance: string;
  insuranceDetails: any;
  selectForRefund = false;
  refundItems: any[];
  selectAll = false;
  emailing = false;
  emailText = {
    body: "",
    subject: ""
  };
  htmlContent = "foo";
  editorConfig: AngularEditorConfig = EditorConfig;
  practitionerAccountNo?: any;
  templates: any[] = [];
  selectedTemplate: string;



  public invoicePrefix = "";
  constructor(
    private route: ActivatedRoute,
    private invoiceService: InvoiceService,
    private store: Store<fromRoot.State>,
    private patientService: PatientChooserService,
    private patientAppointmentHistoryService: PatientAppointmentHistoryService,
    private elementRef: ElementRef,
    public dialog: MatDialog,
    private navigationService: NavigationService,
    private notification: NotificationService,
    private router: Router,
    private clinicService: ClinicService,
    private emailTemplatesService: EmailTemplatesService,
    private textReplacementService: TextReplacementService
  ) { }

  ngOnInit() {
    this.route.paramMap.subscribe((params) => {
      this.transactionIdx = parseInt(params.get("txnIdx"));
      const patientIdx = parseInt(params.get("patientIdx"));

      if (this.patientIdx) { this.clearBalances(); }
      this.patientIdx = patientIdx;


      if (this.transactionIdx) { this.updateTransaction(); }
      if (this.patientIdx) { this.updateTransactions(); }
    });

    this.store
      .select(fromRoot.getSiteSettings)
      .pipe(take(1))
      .subscribe((res: any) => {
        console.log("[settings]", res);
        this.invoiceLogo = res.invoiceLogo;
      }); 

    this.clinicService.getSelectedClinic().subscribe(res=> {
      console.log("[clinic]", res);
      this.clinic = [res.name, res.address1, res.postcode];
    });

    this.getEmailTemplate(EmailTemplates.INVOICE)
    this.getTemplateList()
  }

  ngAfterViewInit() {
    console.log(this.transactionIdx);
  }

  updateEmailText() {
    this.textReplacementService.setPatientIdx(this.patientIdx);
    this.textReplacementService.fulfillReplacements(this.emailText.body).subscribe(res=>{
      this.emailText.body = res;
    });
  }

  clearBalances(): void {
    this.currentBalance = 0;
    this.currentCreditBalance = 0;
    this.totalPayments = 0;
    this.totalSpend = 0;
  }

  updateTransactions(): void {

    this.invoiceService.getTransactions(this.patientIdx).subscribe((transactions: any) => {
      console.log("[transactions]", transactions);
      this.transactions = transactions;
      this.transactionListView = true;

      this.transactions.map((transaction: any) => {
        transaction.items = transaction.items.map((item: any) => {
          this.currentBalance -= item.qty * +item.value * ((+item.pervailVATRate / 100) + 1);
          this.totalSpend += item.qty * +item.value * ((+item.pervailVATRate / 100) + 1);

          if (item.type == "credits") {
            item.creditValue = item.saleFreeText == "credit-transfer" ? -item.qty : -item.qty * item.creditValue;
            item.Name = item.Name;
          }

          this.currentCreditBalance -= item.creditValue;

          return {
            ...item,
            balance: this.currentBalance,
            creditBalance: this.currentCreditBalance
          };
        });

        transaction.payments = transaction.payments.map((payment: any) => {
          this.currentBalance += +payment.Amount;
          this.totalPayments += +payment.Amount;

          return {
            ...payment,
            balance: this.currentBalance,
            creditBalance: this.currentCreditBalance
          };
        });

        return {
          ...transaction,
          items: transaction.items,
          payments: transaction.payments
        };
      });

      this.patientService.getPatientDetails(this.patientIdx).subscribe(patientInfo => {
        this.patientAddress = patientInfo.address;
        this.clientEmail = patientInfo.email;
        this.patientName = patientInfo.name;
      });
    });
  }

  updateTransaction() {
    this.invoiceService
      .getInvoice(this.transactionIdx)
      .subscribe((res: any) => {
        console.log("[transactions]", res);

        this.totalPayments = 0;
        res.payments.forEach(payment => {
          this.totalPayments += +payment.Amount;
        });
        this.clientEmail = res.email;
        if (res.payments.find(f => f.Method === "Insurance")) {
          this.insurance = true;
        }
        this.practitionerAccountNo = null; // res.items.find(f => f.StaffAccountNumber !== null).StaffAccountNumber
        if (res.transaction.ChargeToAccountIdx) {
          this.insuranceInvoice = true;
          this.insurance = true;
          this.updatePatientForInsurance(res);
        } else {
          this.insuranceInvoice = false;
        }
        this.updatePatient(res);
        this.processLoadedInvoice(res);
      });
  }


  updatePatientForInsurance(txn: any) {
    console.log("[invoice] gpi 2", txn.transaction.ChargeToAccountIdx);
    const complete = {};
    this.patientService.getPatientDetails(txn.transaction.ChargeToAccountIdx).subscribe(res => {
      console.log("[invoice] gpi 2", res);
      this.patientNameForInsurance = res.name;
      this.patientAddressForInsurance = res.address.filter(line =>line && line).join(", ");
      const index = res.dob.indexOf('(');
      if (index != -1) {
        this.patientDOBForInsurance = res.dob.slice(0, index - 1);
      } else {
        this.patientDOBForInsurance = res.dob;
      }
    });
    // For some reason the insurance details don't come through on the insurance invoice. This is a fix to get the
    // details from the customer invoice
    this.invoiceService.getInvoice(this.transactionIdx - 1).subscribe((res: any) => {
      this.insuranceNumber = res.items[0].insuranceDetails[1].replace("Patient Insurance No. ", "");
      this.items = this.items.map(item => {
        item.profDetails = res.items.find(f => f.itemIdx === item.itemIdx).profDetails;
        return item;
      });
    });
  }

  updatePatient(res: any) {
    this.patientIdx =  res.transaction.PatientIdx;
    this.patientService.getPatientDetails(this.patientIdx).subscribe(patientInfo => {
      console.log("[invoice] pi ", this.patient);
      if (patientInfo) {
        this.patientAddress = patientInfo.address;
        this.insuranceNumber = patientInfo.insuranceNumber;
//        if (patientInfo.nextVisit) { this.nextVisit = patientInfo.nextVisit }
        this.clientEmail = patientInfo.email;
        this.patientName = patientInfo.name;
      }
    });

    this.patientAppointmentHistoryService.getSummaryForPatient(this.patientIdx).subscribe(res => {
      console.log("[NEXT]", res);
      this.nextVisit = res.nextVisit;
    });

    }


  processLoadedInvoice(res) {
    this.items = res.items.map(item => {
      item.refund = false;
      return item;
    }).filter(item => {
      // Remove uninsured items if an insurance invoice
      if ((this.insuranceInvoice && item.profDetails) || !this.insuranceInvoice) {
        return item;
      }
    });

    let amount = 0;
    res.payments.forEach(payment => {
      amount += Number(payment.Amount);
    });

    this.practitionerAccountNo = null; // this.items.find(f => f.StaffAccountNumber !== null).StaffAccountNumber
    this.transaction = res.transaction;
    console.log("[invoice] trans", this.transaction);
    this.invoiceService.getTransactions(this.transaction.PatientIdx).subscribe(txn => {
      console.log("[invoice]", txn);
    });

    // can void i.e is it today

    const paidDate = new Date(this.transaction.TimePaid);

    // can only void if it is today
    if (isToday(paidDate) && !this.transaction.voidedAt) {
      this.canVoid = true;
    } else {
      this.canVoid = false;
    }
    ////////////
    // can't refund a refund
    if (this.transaction.RefundsTxn > 0) {
      this.canRefund = false;
    } else {
      this.canRefund = true;
    }

    this.payments = res.payments;
    this.credits = res.credits || null;
    this.vouchers = res.vouchers || [];

    // Uncomment when readding DOB
    // this.pibs.getPatientDetails().subscribe(res => {
    //     if (res) {
    //         this.dob = res.patientInfo.dob;
    //     }
    // });

    this.clinic = res.clinic;

/*    this.reportService
      .getData(110, {
        context: null,
        sortModel: [],
        failCallback: null,
        filterModel: null,
        successCallback: null,
        endRow: 100,
        startRow: 0,
      })
      .subscribe((res) => {
        let clinicID = res.find((x) => x.field_478 === this.clinic[0]);

        this.qs.getQuestions("clinic", clinicID.rowId).subscribe((res) => {
          this.invoicePrefix = res.questions[2].value;
        });
      });
*/

    this.invoicePrefix = res.invoicePrefix;

    this.patient = res.patient;
    // console.log("PATIENT", this.patient);
    this.saleTotal = this.items.reduce(function (tot: number, item: any) {
      return tot + item.value * item.qty;
    }, 0);
    this.paymentTotal = this.payments.reduce(function (
      tot: number,
      item: any
    ) {
      return tot + parseFloat(item.Amount);
    },
      0);
    console.log(this.paymentTotal);
    if (this.saleTotal - this.paymentTotal == 0) {
      this.documentType = "RECEIPT";
    }
    else { this.documentType = "INVOICE"; }

  }


  print() {
    window.print();
  }

  void() {
    this.invoiceService.voidInvoice(this.transactionIdx).subscribe(res => {
      this.processLoadedInvoice(res);
    });
  }

  refund() {
    this.selectForRefund = true;
  }


  refundAll() {
    this.selectAll = !this.selectAll;
    if (this.selectAll) {
      this.items = this.items.map(item => {
        item.refund = true;
        return item;
      });
      this.refundItems = this.items;
    } else {
      this.items = this.items.map(item => {
        item.refund = false;
        return item;
      });
      this.refundItems = [];
    }

  }

  refreshRefundList(e, item) {
    e ? item.refund = true : item.refund = false;
    this.refundItems = this.items.filter(f => { if (f.refund === true) {return f.idx;}});
  }

  public processRefund(reset: boolean = false): void {
    this.refundItems = [];
    this.items.forEach(item => {
      if (item.refund) {
        this.refundItems.push(item.idx);
      }
    });
    this.router.navigateByUrl(`/pay?refund=${this.transactionIdx}&items=${this.refundItems}&reset=${reset}`);
  }

  public processRefundAll(reset: boolean = true): void {
    this.refundAll();
    this.processRefund(reset);
  }

  public processRefundAllAndReset(): void {
    this.processRefundAll(true);
  }

  back(): void {
    if (this.selectForRefund) {
      this.selectForRefund = false;
    } else {
      this.patientService.clearPatient();
      this.navigationService.back();
    }

  }

  emailInvoice() {
    if (!this.emailing && this.emailText) {
      this.updateEmailText();
      this.emailing = true;
    } else {
      const invoice = this.elementRef.nativeElement.getElementsByClassName("pdf")[0].innerHTML.replaceAll("£", "&pound;");
      const patientIdx = this.patientIdx || this.transaction.PatientIdx;
      const transactionIdx = this.patientIdx ? 0 : this.transactionIdx;

      if (this.clientEmail) {
        console.log(this.emailText);
        
        this.invoiceService.emailInvoice(
          EmailTemplates.INVOICE,
          transactionIdx,
          patientIdx,
          {
            attachment: invoice,
            body: this.emailText.body,
            subject: this.emailText.subject
          },
          this.clientEmail
          ).subscribe(res => {
            this.notification.send(`You have successfully emailed this invoice to ${this.clientEmail}.`);
            this.emailing = false;
          });
      } else {
        this.noEmail = true;
      }
    }
  }

  toInsurance() {
    this.router.navigate(['finance', 'invoice', this.transactionIdx + 1]);
    if (this.emailing) {
      this.updateTemplate(EmailTemplates.INSURANCE_INVOICE)
    }
  }

  toCustomer() {
    this.router.navigate(['finance', 'invoice', this.transactionIdx - 1]);
    if (this.emailing) {
      this.updateTemplate(EmailTemplates.INVOICE)
    }
  }

  toCreditsList() {
    this.router.navigate(['credits', 'list', this.patientIdx]);
  }

  openGiftCardModal(giftCards) {
    return new Promise((resolve, reject) => {
      const dialogRef = this.dialog.open(GiftCardModalComponent, {
        width: "600px",
        data: {
          giftCards: giftCards
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed');
        resolve(result);
      });
    });
  }

  public takePayment(): void {
    this.router.navigate(['/pay'], { queryParams: { recoverTxn: this.transactionIdx }});
  }

  private getEmailTemplate(name: string) : void {
    this.emailTemplatesService.getTemplate(name).subscribe((res: any) => {
      this.emailText.body = res.body
      this.emailText.subject = res.subject
    })
  } 

  private getTemplateList() {
    this.emailTemplatesService.listTemplates().subscribe(templates => {
      templates.forEach(template => {
        if ([EmailTemplates.INVOICE, EmailTemplates.INSURANCE_INVOICE].includes(template.title)) {
          this.templates.push(
            {
              value: template.title,
              viewValue: template.friendlyTitle
            }
          )
        }
      })
      this.insuranceInvoice ? this.selectedTemplate = EmailTemplates.INSURANCE_INVOICE : this.selectedTemplate = EmailTemplates.INVOICE
      console.log(this.templates);
    })
  }

  updateTemplate(templateName: string) {
    console.log(templateName);
    this.getEmailTemplate(templateName)
    setTimeout(() => this.updateEmailText(), 500)
  }

  openInvoice(txnIdx){
    this.router.navigate(['finance', 'invoice', txnIdx]);
  }

}
