import { Injectable } from "@angular/core";
import * as fromRoot from "../store/reducers";
import { Store } from "@ngrx/store";
import { skipWhile, take } from "rxjs/operators";
import { Observable } from "rxjs";

import { SaleItem, StockAudit } from "../models/saleItems";
import { DataService } from "app/services/data.service";

@Injectable({
    providedIn: "root"
})
export class SaleItemsService {

    constructor(private store: Store<fromRoot.State>,
                private http: DataService) {

    }

    getSaleItems(): Observable<SaleItem[]> {
        return this.store.select(fromRoot.getSaleItems).pipe(
            skipWhile(val => val == null || val.length == 0),
            take(1));
    }

    getStockListFromServer(): Observable<SaleItem[]> {
        return this.http.get<SaleItem[]>('/sale-items');
    }

    listCredits() {
        return this.http.get<any>('/list-credits');
    }

    save(saleItems: SaleItem[]): Observable<SaleItem[]> {
        return this.http.post<SaleItem[]>('/sale-items', saleItems);
    }

    saveStock(stockAudit: StockAudit): Observable<SaleItem[]> {
        return this.http.post<SaleItem[]>('/sale-items/stock', stockAudit);
    }

    delete(saleItem: SaleItem) {
        return this.http.delete(`/sale-item/${saleItem.idx}`);
    }

}
