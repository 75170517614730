import { Action } from '@ngrx/store';
import { ReportFilter, State } from '../../models/site-settings';
import { type } from '../util';
/*
export const SET = 'SETTINGS_SET';
export const SET_DIARY = 'SETTINGS_SET_DIARY';
export const ADD_MENU = 'SETTINGS_ADD_MENU';
export const INIT = 'SETTINGS_INIT';
*/


export const ActionTypes = {
    SET: type('[SiteSettingsState] Set'),
    SET_DIARY: type('[SiteSettingsState] Set Diary'),
    INIT: type('[SiteSettingsState] Initalise settings')
};


/**
 * Every action is comprised of at least a type and an optional
 * payload. Expressing actions as classes enables powerful 
 * type checking in reducer functions.
 * 
 * See Discriminated Unions: https://www.typescriptlang.org/docs/handbook/advanced-types.html#discriminated-unions
 */

export class InitAction implements Action {
    type = ActionTypes.INIT;
    constructor() { }
}


export class SetAction implements Action {
    type = ActionTypes.SET;
    constructor(public payload: State) { }
}


/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */

export type Actions
    = SetAction |   
    InitAction 