import { Component, Input, OnInit } from "@angular/core";
import { DateRange } from "@angular/material/datepicker";
import moment from 'moment';
import { Subscription } from "rxjs";
import { ReportsConfigService } from "../reports-config.service";

@Component({
  selector: "app-custom-date-range",
  templateUrl: "./custom-date-range.component.html",
  styleUrls: ["./custom-date-range.component.scss"],
})
export class CustomDateRangeComponent implements OnInit {
  public selectedDateRange: DateRange<Date>;
  @Input() quickDateFilter: any;
  public dateRangeFilters$: Subscription;
  public calendarSelected = false;

  constructor(private reportsConfig: ReportsConfigService) {}

  ngOnInit(): void {}

  onCalendarDateRange(date: Date, qdf: any): void {
    console.log("[report] onCalendarDateRange", date);
    let dateFrom;
    let dateTo;
    if (
      this.selectedDateRange &&
      this.selectedDateRange.start &&
      date > this.selectedDateRange.start &&
      !this.selectedDateRange.end
    ) {
      this.selectedDateRange = new DateRange(
        this.selectedDateRange.start,
        date
      );
      dateFrom = moment(this.selectedDateRange.start).utc().format("Y-MM-DD HH:mm:ss");
      dateTo = moment(this.selectedDateRange.end).utc().format("Y-MM-DD HH:mm:ss");
      this.reportsConfig.setDateRangeFilterModel(qdf,null,null,dateFrom,dateTo);
      this.calendarSelected = true;
      setTimeout(() => (this.calendarSelected = false), 50);
    } else {
      this.selectedDateRange = new DateRange(date, null);
    }
  }

  dateSelectDelta(delta: number, unit: string, qdf: any, text: string): void {
    this.reportsConfig.setDateRangeFilterModel(qdf, delta, unit, null, null);

    this.dateRangeFilters$ =
      this.reportsConfig.dateRangeFilterModelSubject.subscribe((value) => {
        console.log(value);
        qdf.activeText = value.label;
      });
  }

  onCalendarClick(event: any) {
    // prevent calendar to close if date range not selected
    if (!this.calendarSelected) {
      event.stopPropagation();
    }
  }
}
