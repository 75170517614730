import { Component } from '@angular/core';
import { NgZone, ElementRef, ViewChild, Renderer2 } from '@angular/core'
import {
    Router,
    // import as RouterEvent to avoid confusion with the DOM Event
    Event as RouterEvent,
    NavigationStart,
    NavigationEnd,
    NavigationCancel,
    NavigationError
} from '@angular/router'
import { Store } from '@ngrx/store';

import * as fromRoot from '../store/reducers';
import * as loadingActions from '../store/actions/loading';

@Component({
    selector: 'loader-overlay',
    templateUrl: './loader-overlay.component.html',
    styleUrls: ['./loader-overlay.component.css']
})
export class LoaderOverlayComponent {
    @ViewChild('spinnerElement', { static: false }) spinnerElement: ElementRef;
    subscription: any;

    constructor(
        private router: Router,
        private ngZone: NgZone,
        private renderer: Renderer2,
        private store: Store<fromRoot.State>    ) {
/*
        router.events.subscribe((event: RouterEvent) => {
            this._navigationInterceptor(event);
        });
      */

    }


    ngOnInit() {
        this.subscription = this.store.select(fromRoot.getLoading).subscribe(res => {
            if (res) this.show();
            else this.hide();
        });
    }

    ngOnDestroy() {
        this.subscription.unsubscribe();
    }
    // Shows and hides the loading spinner during RouterEvent changes
    private _navigationInterceptor(event: RouterEvent): void {

        if (event instanceof NavigationStart) {
            
            // We wanna run this function outside of Angular's zone to
            // bypass change detection
            
            this.store.dispatch(new loadingActions.ShowAction());
        }
        if (event instanceof NavigationEnd) {
            this.store.dispatch(new loadingActions.HideAction());
        }

        // Set loading state to false in both of the below events to
        // hide the spinner in case a request fails
        if (event instanceof NavigationCancel) {
            this.store.dispatch(new loadingActions.HideAction());
        }
        if (event instanceof NavigationError) {
            this.store.dispatch(new loadingActions.HideAction());
        }
    }

    private show(): void {
        if (!this.spinnerElement) return;
        this.ngZone.runOutsideAngular(() => {

            // For simplicity we are going to turn opacity on / off
            // you could add/remove a class for more advanced styling
            // and enter/leave animation of the spinner
            // For simplicity we are going to turn opacity on / off
            // you could add/remove a class for more advanced styling
            // and enter/leave animation of the spinner
            this.renderer.addClass(this.spinnerElement.nativeElement, 'loading-overlay-shown');

        });
    }

    private hide(): void {
        if (!this.spinnerElement) return;
        // We wanna run this function outside of Angular's zone to
        // bypass change detection, 
        this.ngZone.runOutsideAngular(() => {

            // For simplicity we are going to turn opacity on / off
            // you could add/remove a class for more advanced styling
            // and enter/leave animation of the spinner

            // For simplicity we are going to turn opacity on / off
// you could add/remove a class for more advanced styling
// and enter/leave animation of the spinner
this.renderer.removeClass(this.spinnerElement.nativeElement, 'loading-overlay-shown');

        });
    }

}
