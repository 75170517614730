import { Injectable } from '@angular/core';
import {
    Actions,
    createEffect,
    ofType
} from '@ngrx/effects';
import { Action } from '@ngrx/store';

import { mergeMap, map } from 'rxjs/operators';
import * as staffActions from '../actions/staff';
import { DataService } from '../../services/data.service';
import { StaffMember } from '../../models/staff';

@Injectable()
export class StaffEffects {


    // @Effect()
    // initStaff$ = this.actions$
    //     .pipe(
    //         map(staff => (new staffActions.FetchAction()))
    //     );

    // @Effect()
    // fetchStaff$ = this.actions$
    //     .pipe(
    //         ofType(staffActions.ActionTypes.FETCH),
    //         mergeMap(() => this.http.get<StaffMember[]>('/staff')
    //             .pipe(
    //                 map(staff => (new staffActions.SetAction(staff)))
    //             ))
    //     );


    constructor(private http: DataService, private actions$: Actions,) { }

    // ngrxOnInitEffects(): Action {
    //     console.log("STAFF Init effect has happened");
    //     // console.trace();
    //     return { type: staffActions.ActionTypes.INIT };
    // }
    initStaff$ =  createEffect(() => {
        return this.actions$
            .pipe(
                ofType(staffActions.ActionTypes.INIT),
                mergeMap(() => this.http.get < StaffMember[] >('/staff')
                    .pipe(
                        map(staff => (new staffActions.SetAction(staff)))
                ))
            );
        });
}

