import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Store } from "@ngrx/store";
import * as fromRoot from "../../../store/reducers";
import { take } from "rxjs/operators";
import { InsuranceInvoiceService } from "../insurance-invoice.service";
import { PatientInfoBoxService } from 'app/services/notes/patient-info-box.service';
import { QuestionService } from "app/dynamic-form/services/question.service";
import { ReportsService } from "app/reports/reports.service";
import { PatientChooserService } from "app/services/patient.service";

@Component({
  selector: "app-customer-invoice",
  templateUrl: "./customer.component.html",
  styleUrls: ["./customer.component.scss"],
})
export class CustomerComponent {
  transactionIdx: number;
  invoiceTo: string[];
  invoiceFrom: string;
  saleTotal: number;
  paymentTotal: number;
  items: any[];
  transaction: any;
  payments: any[];
  clinic: string[];
  patient: string[];
  insurance: string[];
  documentType: string = "RECEIPT";
  invoiceLogo: string;
  clientEmail: string;
  dob: string;
  accountNumber: string;
  excess: number;

  public invoicePrefix = "";
  constructor(
    private route: ActivatedRoute,
    private invoiceService: InsuranceInvoiceService,
    private store: Store<fromRoot.State>,
    private pibs: PatientInfoBoxService,
    private qs: QuestionService,
    private reportService: ReportsService,
    private patientSerivce: PatientChooserService
  ) {}

  ngOnInit() {
    this.route.paramMap.subscribe((params) => {
      this.transactionIdx = parseInt(params.get("txnIdx"));
      this.updateTransaction();
    });

    this.store
      .select(fromRoot.getSiteSettings)
      .pipe(take(1))
      .subscribe((res: any) => {
        this.invoiceLogo = res.invoiceLogo;
      });
  }

  updateTransaction() {
    this.invoiceService
      .getInvoice(this.transactionIdx)
      .subscribe((res: any) => {
        console.log("THIS IS THE INVOICE DATA");
        console.log(res);
        console.log(res.payments);
        this.items = res.items;
        this.transaction = res.transaction;
        console.log(res.transaction);
        this.payments = res.payments;
        console.log(this.payments);

        this.patientSerivce.setPatient(res.transaction.PatientIdx);
        this.patientSerivce.getPatientInfo().subscribe((patientInfo) => {
          if (patientInfo) {
            this.clientEmail = patientInfo.email;
          }
        });

        this.pibs.getPatientDetails().subscribe((res) => {
          if (res) {
            this.dob = res.dob;
          }
        });

        this.clinic = res.clinic;
        this.reportService
          .getData(110, {
            context: null,
            sortModel: [],
            failCallback: null,
            filterModel: null,
            successCallback: null,
            endRow: 100,
            startRow: 0,
          })
          .subscribe((res) => {
            let clinicID = res.find((x) => x.field_478 === this.clinic[0]);

            this.qs.getQuestions("clinic", clinicID.rowId).subscribe((res) => {
              this.invoicePrefix = res.questions[2].value;
            });
          });

        this.patient = res.patient;
        this.insurance = res.insurance;
        this.accountNumber = res.insuranceNumber;
        this.excess = parseFloat(res.insuranceExcess);
        console.log("PATIENT", this.patient);
        this.saleTotal = this.items.reduce(function (tot: number, item: any) {
          return tot + item.value * item.qty;
        }, 0);
        this.paymentTotal = this.payments.reduce(function (
          tot: number,
          item: any
        ) {
          return tot + parseFloat(item.Amount);
        },
        0);
        console.log(this.paymentTotal);
        if (this.saleTotal - this.paymentTotal == 0)
          this.documentType = "RECEIPT";
        else this.documentType = "INVOICE";
      });
  }

  print() {
    window.print();
  }

  emailInvoice() {}
}
