import "@angular/compiler";
import { LOCALE_ID, NgModule } from "@angular/core";
import '@angular/common/locales/global/en-GB';
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { RouterModule } from "@angular/router";
import { CommonModule } from "@angular/common";
// import { MiniCalendarModule } from 'mini-calendar';

// import { NgbModule } from "@ng-bootstrap/ng-bootstrap"; //do not add back
import { HttpClientModule } from "@angular/common/http";
import { HTTP_INTERCEPTORS } from "@angular/common/http";

import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { StoreModule } from "@ngrx/store";

import { NotesModule } from "./notes/notes.module";
import { FinanceModule } from "./finance/finance.module";
// import { FileSelectDirective } from 'ng2-file-upload'; //not sure why here

import { reducers, metaReducers } from "./store/reducers/index";

import { AuthInterceptor } from "./services/interceptors/auth.interceptor";
import { HttpErrorInterceptor } from "./services/interceptors/error.interceptor";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { MyMaterialModule } from "./material/material.module";
import { LoginComponent } from "./login/login.component";
import {
  PatientPurgeComponent,
  DialogConfirmPurge,
} from "./patient-purge/patient-purge.component";

import { NavBarComponent } from "./nav-bar/nav-bar.component";

import { AuthService } from "./services/auth.service";
import { DataService } from "./services/data.service";
import { InitalisationService } from "./services/initialisation-service"

import { AgGridModule } from "ag-grid-angular";
//import "ag-grid-enterprise";

import { ReportsModule } from "./reports/reports.module";
// import { IonicModule } from "@ionic/angular";

import { NavBarService } from "./services/menu/nav-bar.service";
//import { FingerprintAIO } from "@ionic-native/fingerprint-aio/ngx";
import { LogoutComponent } from "./logout/logout.component";

import { ContactFooterComponent } from "./footer/contact-footer.component";

import { SideMenuComponent } from "./nav-bar/side-menu/side-menu.component";


import { SidebarComponent } from "./main-menu/sidebar/sidebar.component";
import { VisualsService } from "./services/visuals.service";
import { SubMenuComponent } from "./nav-bar/sub-menu/sub-menu.component";

import { UserButtonComponent } from "./nav-bar/user-button/user-button.component";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
import { EffectsModule } from "@ngrx/effects";

import { environment } from "../environments/environment";
import { LoaderOverlayComponent } from "./loader-overlay/loader-overlay.component";
import { SnackBarComponent } from "./snack-bar/snack-bar.component";


import { DiaryModule } from "./diary/diary.module";
import { GuardsModule } from './guards/guards.module';
import { TillComponent } from "./till/till.component";

import { SaleItemsService } from "./services/sale-items.service";
import { FormatterService } from "./services/formatter.service";
import { PaymentMethodsService } from "./services/payment-methods.service";

import { TillService } from "./till/till.service";

import { SiteSettingsService } from "./services/site-settings.service";
import { GiftCardService } from "./view-all-gift-cards/giftcard.service"

import {EffectsList} from "./store/effects";
import { GeoLocationService } from "./services/geo-location.service";


import {
  MAT_DATE_LOCALE,
  DateAdapter as DateAdapterMaterial,
} from "@angular/material/core";

import { ViewAllGiftCardsComponent } from "./view-all-gift-cards/view-all-gift-cards.component";
import { GiftCardValidatorComponent } from "./till/gift-card-validator/gift-card-validator.component";
import { MainMenuModule } from "./main-menu/main-menu.module";
import { SubMainMenuService } from "./main-menu/sub-main-menu/sub-main-menu.service";
import { TreatmentManagerComponent } from "./treatment-manager/treatment-manager.component";
import { CalendarModule, DateAdapter } from "angular-calendar";
import { adapterFactory } from "angular-calendar/date-adapters/date-fns";
import { NavigationService } from "./services/navigation.service";
import { ClinicService } from "./services/clinic.service";
import { NotificationService } from "./services/notification.service";
import { TillPromptDialogComponent } from './till/till-prompt-dialog/till-prompt-dialog.component';
import { AccessDeniedComponent } from './access-denied/access-denied.component';
import { GiftCardModalComponent } from './till/gift-card-modal/gift-card-modal.component';
import { UserProfileComponent } from './user-profile/user-profile.component';
import { PasswordStrengthComponent } from './user-profile/password-strength/password-strength.component';
import { LoadingPageComponent } from './loading-page/loading-page.component';
//import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { SubmitCancelModule } from "./submit-cancel/submit-cancel.module";

import { IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation, LogLevel } from '@azure/msal-browser';
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent }  from '@azure/msal-angular';

//import { LogglyLoggerService} from "./services/error.handler.service";
//import { LogglyService } from "./services/loggly.service";
import { AngularEditorModule } from "@kolkov/angular-editor";

import { FormUploaderComponent } from "./dynamic-form/form-components/form-uploader/form-uploader.component";

import { LocalDBService } from './services/local-db.service';
import {LicenseManager} from "ag-grid-enterprise";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";
import { PipesModule } from "./pipes/pipes.module";

import { DialogsModule } from './dialogs/dialogs.module';
import { DynamicFormComponentsModule } from './dynamic-form/form-components/dynamic-form-components.module'
import { CustomFormControlsModule } from "./custom-form-controls/custom-form-controls.module";
import { SettingsModule } from './settings/settings.module';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MomentDateAdapter } from "@angular/material-moment-adapter";
import { PatientAppointmentHistoryService } from './services/patient-appointment-history.service';
import { TextReplacementService} from './services/text-replacement.service';
import { FastTextModule } from "./directives/fast-text/fast-text.module";
import { CreditsModule } from "./credits/credits.module";
import { MergePatientsComponent } from './patient-purge/merge-patients/merge-patients.component';
import { UserSettingsEffects } from "./store/effects/user-settings";
import { UserSettingsService } from "./services/user-settings.service";


if (environment.agGridLicense){
  LicenseManager.setLicenseKey(environment.agGridLicense);
}

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;
const isIframe = window !== window.parent && !window.opener;

export function loggerCallback(logLevel: LogLevel, message: string) { console.log(message); }


//ATTEND2IT
const CLIENT_ID = '4aa2ddc8-69a6-47cc-a071-7a6eb2b0bb4e' //application ID
//const TENANT_ID = '48877022-420a-4077-bb4d-d80c82670676' //TENNANT ID
const TENANT_ID = 'common' //TENNANT ID

const HOSTNAME = window.location.hostname;

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: CLIENT_ID, //this is the application id
      authority: `https://login.microsoftonline.com/${TENANT_ID}`, 
      redirectUri: `https://${HOSTNAME}${environment.msAuth.redirectUri}`,
      postLogoutRedirectUri: `https://${HOSTNAME}${environment.msAuth.postLogoutRedirectUri}`
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    system: {
      allowNativeBroker: false, // Disables WAM Broker
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return { 
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['user.read']
    }
  };
}

@NgModule({
  declarations: [
    AccessDeniedComponent,
    AppComponent,
    DialogConfirmPurge,
    LoginComponent,
    NavBarComponent,
    PatientPurgeComponent,
    SubMenuComponent,
    LogoutComponent,
    ContactFooterComponent,
    LoaderOverlayComponent,
    LoadingPageComponent,
    SideMenuComponent,
    SnackBarComponent,
    SidebarComponent,
    TillComponent,
    UserButtonComponent,
    ViewAllGiftCardsComponent,
    GiftCardValidatorComponent,
    TreatmentManagerComponent,
    TillPromptDialogComponent,
    GiftCardModalComponent,
    UserProfileComponent,
    PasswordStrengthComponent,
    FormUploaderComponent,
    MergePatientsComponent
  ],
  imports: [
    CustomFormControlsModule,
    AgGridModule,
    BrowserModule,
    PipesModule,
    DiaryModule,
    FastTextModule,
   // IonicModule.forRoot(),
    BrowserAnimationsModule,
    AppRoutingModule,
     DialogsModule,
    HttpClientModule,
    FinanceModule,
    FormsModule,
    ReactiveFormsModule,
    GuardsModule,
    MyMaterialModule,
    SubmitCancelModule,
    ReportsModule,
    SettingsModule,
    SubmitCancelModule,
    EffectsModule.forRoot(EffectsList),
    StoreModule.forRoot(reducers, { metaReducers }),
    NotesModule,
    CommonModule,
    StoreDevtoolsModule.instrument({ maxAge: 25, logOnly: environment.production }),
    MainMenuModule,
    MsalModule,
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory }),
    AngularEditorModule,
    DynamicFormComponentsModule,
    CreditsModule
  ],
  providers: [
    {
      provide: DateAdapterMaterial,  //be careful overriding DateAdapter as Calendar also uses one
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill' } },
    AuthService,
    ClinicService,
    DataService,
    VisualsService,
    GiftCardService,
    InitalisationService,
    LocalDBService,
    GeoLocationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true
    },
    {
      provide: LOCALE_ID,
      useValue: "en-GB",
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    NavBarService,
    NavigationService,
    NotificationService,
//    FingerprintAIO,
    SaleItemsService,
    SiteSettingsService,
    UserSettingsService,
    PatientAppointmentHistoryService,
    PaymentMethodsService,
    SubMainMenuService,
    TextReplacementService,
//    LogglyLoggerService,
//    LogglyService,
    TillService,
    FormatterService
   ],
  bootstrap: [AppComponent],
})
export class AppModule { }

