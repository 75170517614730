
<div class="row margin-collapse no-print">
    <div class="col-lg-10">
      <mat-card><mat-card-content>
          <mat-form-field class="w-100">
            <mat-label for="letterTitle">Filename</mat-label>
            <input matInput [(ngModel)]="letterTitle" type="text"  name="letterTitle">
          </mat-form-field>
        
        <br>
        <div class="cardContainer">
            <div class="cardCol">
              <div *ngIf="unavailableReplacements" class="alert alert-warning">The following replacements could not be made as there is no patient data: {{unavailableReplacements}}</div>
              <div *ngIf="deprecatedReplacements" class="alert alert-danger">The following replacements are not available in this version of Multiclinic and should be changed or removed from the letter template: {{deprecatedReplacements}}</div>
              <angular-editor [placeholder]="'Enter text here...'" [config]="editorConfig" [(ngModel)]="htmlContent" id="print"></angular-editor>
            </div>
        </div>
      </mat-card-content></mat-card>
    </div>
    <div class="col-sm-2 no-print mobile-margin">
        <div class="fix-top">
          <div class="mb-3">  
                <mat-form-field class="w-100 mobile-input desktop-input">
                  <mat-label>Email To</mat-label>
                  <input matInput 
                    type="email"
                    [email] = "true"
                    [(ngModel)]="gpEmail"
                    name="email">
                </mat-form-field>  
                <mat-form-field class="w-100 mobile-input desktop-input">
                  <mat-label>Email Subject</mat-label>
                  <input matInput 
                    placeholder="Email address" 
                    type="text"
                    [(ngModel)]="emailText.subject"
                    name="emailSubject">
                </mat-form-field>  

                <div class="editor">
                  <angular-editor *ngIf="emailing" [(ngModel)]="emailText.body" [config]="editorConfig" [placeholder]="'Enter text here...'"  id="emailBody"></angular-editor>
                </div>

                <div class="mobile-buttons mobile-invoice-buttons">
                  <button *ngIf="!emailing" mat-flat-button color="accent" class="mobile-button desktop-button" (click)="emailCopy()">
                    <mat-icon>alternate_email</mat-icon>&nbsp;Email Copy
                  </button>

                  <button *ngIf="emailing" mat-flat-button color="accent" class="mobile-button desktop-button" (click)="emailLetter()">
                    <mat-icon>alternate_email</mat-icon>&nbsp;Send
                  </button>
               
                  <div class="desktop-buttons">
                    <button id="print-button" mat-flat-button color="accent" class="mobile-button desktop-button" (click)="print()">
                      <mat-icon>print</mat-icon>&nbsp;Print</button>
                  </div>
                  
                  <div class="my-3 desktop-buttons">
                    <button mat-raised-button class="mobile-button desktop-button" color="primary" (click)="save()">Save</button>
                  </div>
                  <div class="my-3 desktop-buttons">
                    <button mat-raised-button class="mobile-button desktop-button" (click)="cancel()">Back</button>
                  </div>
  
          </div>
        </div>
      </div>
    </div>
</div>
 