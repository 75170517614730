import { Injectable } from '@angular/core';

import { Actions, createEffect, ofType } from '@ngrx/effects';

import { switchMap, map, withLatestFrom } from 'rxjs/operators';
import * as patientActions from '../actions/patient';
import { DataService } from '../../services/data.service';
import { type PatientChooserResult, PatientDetails } from "../../models/patient-details";
import { EMPTY, Observable, of } from 'rxjs';
import { Store } from '@ngrx/store';

import * as fromRoot from "../reducers";

@Injectable()
export class PatientEffects {
    constructor(private http: DataService, private actions$: Actions, private store: Store<fromRoot.State> ) { }
  
    
    loadPatient$ = createEffect(()=> {
        return this.actions$
            .pipe(
                ofType(patientActions.ActionTypes.LOAD),
                map((action: fromRoot.ActionWithPayload) => action.payload),
                map(patient => {
                    patientActions.SetPatientIdx({payload: patient.idx});
                    return patient;
                }),
                switchMap((payload) => this.http.get<PatientChooserResult>(`/patients/patient/${payload}`)
                .pipe(
                    map(patient => (patientActions.SetAction({payload: patient})))
                ))
            )
    }, { dispatch: true });

    /*
    loadDetail$ = createEffect(() =>{
        return this.actions$.pipe(
        ofType(patientActions.ActionTypes.SET),
        map((action: fromRoot.ActionWithPayload) => action.payload),
        switchMap(payload => {
            console.log("[pc] loadDetail$");
            if (payload.isSpecial){
                return of(patientActions.SetDetail({ payload: 
                    {
                        patientInfo: {
                            name: "Not Available"
                        },
                        isSpecial: true
                    }
                }));
            }
            return this.http.get<PatientDetails>(`/patients/info/${payload.idx}`).pipe(
                map((patient) => {
                    console.log("[pc] SET DETIAL", patient);
                    return patientActions.SetDetail({payload: patient})
                })
            )
        }))
    }, { dispatch: true });

    loadFullDetails$ = createEffect(() => {
        return this.actions$.pipe(
        ofType(patientActions.ActionTypes.LOAD_FULL_DETAILS),
        withLatestFrom(this.store),
        switchMap(([payload, storeState]) => {
            console.log("[pc] loadFullDetails$");
            return this.http.get<PatientDetails>(`/patients/info/${storeState.patient.patient.idx}?full=1`).pipe(
                map((patient) => {
                    return patientActions.SetDetail({payload: patient})
                })
            )
        }))
    }, { dispatch: true });
    */

}

