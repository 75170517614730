/* Wrapper service for letters that forwards requests to the text replacement service */

import { Injectable } from '@angular/core';
import { BehaviorSubject, Subscription } from 'rxjs';
import { NotesService } from './notes.service';
import { TextReplacementService } from '../text-replacement.service';

@Injectable()
export class LettersReplacementService {

    private patientIdx$: Subscription;

    constructor(
        private textReplacementService: TextReplacementService,
        private notesService: NotesService
    ){
        this.patientIdx$ = this.notesService.patientIdx().subscribe(res => {
            this.textReplacementService.setPatientIdx(res);
        });
    }

    public fulfillReplacements(text: string): BehaviorSubject<string> {
        return this.textReplacementService.fulfillReplacements(text);
    }
 

}
