    <mat-form-field class="full-width">
      <input 
        placeholder="Select Staff"
        #inputRef
        id="inputRef"
        matInput 
        [matAutocomplete]="auto" 
        [formControl]="autoCompleteTextInput"
        [value]="showStaffName"
        >
      <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="updateValue($event)">
        <mat-option *ngFor="let staff of filteredOptions | async" [value]="staff">
          {{ staff.firstname }} {{staff.lastname}}
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>

  