<div>
  <button class="hide" mat-icon-button  aria-label="Example icon-button with a menu">
    <mat-icon>more_vert</mat-icon>
  </button>
  <mat-menu #menu="matMenu" xPosition="after">
    <button mat-menu-item (click)="deleteAvailability($event)">
      <mat-icon>delete</mat-icon>
      <span>Remove</span>
    </button>
  </mat-menu>
</div>

<mwl-calendar-week-view
  [viewDate]="viewDate"
  [events]="events"
  [eventTemplate]="weekViewEventTemplate" 
  [headerTemplate]="headerTemplate"
  [hourSegmentTemplate]="weekViewHourSegmentTemplate"

  [weekStartsOn]="weekStartsOn"
  [hourSegments]="settings.hourSegments" 
  [hourSegmentHeight]="hourSegmentHeight">
</mwl-calendar-week-view>
<!-- currentTimeMarkerTemplate  commented out to try and fix it -->

<!-- Overwrite the hour segments so they display minutes within hours -->
<ng-template
  #weekViewHourSegmentTemplate
  let-segment="segment"
  let-locale="locale"
  let-segmentHeight="segmentHeight"
  let-isTimeLabel="isTimeLabel">
  <div
    #segmentElement
    class="cal-hour-segment"
    [style.height.px]="segmentHeight"
    [class.cal-hour-start]="segment.isStart"
    [class.cal-after-hour-start]="!segment.isStart"
    [ngClass]="segment.cssClass"
    (mousedown)="startDragToCreate(segment, $event, segmentElement)">
    <div class="week-time" *ngIf="isTimeLabel" [style.height.px]="segmentHeight">{{ segment.date | calendarDate:'dayViewHour':locale }}</div>
  </div>
</ng-template>

<!-- Overwrite the current time marker so it displays conditionally 
<ng-template
  #currentTimeMarkerTemplate
  let-columnDate="columnDate"
  let-dayStartHour="dayStartHour"
  let-dayStartMinute="dayStartMinute"
  let-dayEndHour="dayEndHour"
  let-dayEndMinute="dayEndMinute"
  let-isVisible="isVisible"
  let-topPx="topPx">
  <div class="cal-current-time-marker" *ngIf="isVisible && showCurrentTimeMarker" [style.top.px]="topPx"></div>
</ng-template>
-->
<!-- Overwrite the header so it displays only the days -->
<ng-template #headerTemplate let-days="days" >
    <div class="cal-day-headers cursor-default">
        <div class="cal-header" *ngFor="let day of weekDays">
            <div class="top">
                <div class="day-label text-uppercase">{{ day }}</div>
            </div>
        </div>
    </div>
</ng-template>

<!-- Overwrite the cells to be displayed in a light-green background -->
<ng-template #weekViewEventTemplate let-weekEvent="weekEvent" let-eventClicked="eventClicked" let-column="column" let-daysInWeek="daysInWeek" let-eventActionsTemplate="eventActionsTemplate" let-locale="locale">
    <div class="cal-event bg-available h-100" 
          [attr.aria-label]="{ event: weekEvent.tempEvent || weekEvent.event, locale: locale } | calendarA11y: 'eventDescription'"
          [matMenuTriggerFor]="menu"
          (click)="clicked(weekEvent)"
          (contextmenu)="clicked(weekEvent)">
          
    </div>
</ng-template>
<!-- 
<mat-menu #availabilityMenu="matMenu">
  <button mat-menu-item>
    <mat-icon>dialpad</mat-icon>
    <span>Redial</span>
  </button>
  <button mat-menu-item disabled>
    <mat-icon>voicemail</mat-icon>
    <span>Check voice mail</span>
  </button>
  <button mat-menu-item>
    <mat-icon>notifications_off</mat-icon>
    <span>Disable alerts</span>
  </button>
</mat-menu> -->


