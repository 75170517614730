import { Injectable, EventEmitter, OnInit } from "@angular/core";
import { BehaviorSubject, EMPTY, Observable, Subject, Subscription } from "rxjs";
import {
  CalendarEvent,
  CalendarEventTimesChangedEvent
} from "angular-calendar";
// import { addHours, startOfDay } from "date-fns";
import { DataService } from "../../services/data.service";
import {
  type Availability,
  ViewTypes,
  AppointmentStatus,
  StaffRoomsView,
  SortingMode,
  type CalendarSettings,
  DiaryFocusOn,
  DiaryFocusOnType,
  DIARY_FOCUS_NONE
} from "../../models/diary";

import { Appointment, ProcessedAppointment, AppointmentSelectOn } from "../../models/appointments";
import { AppointmentsService } from "./appointments.service";
import { AvailabilityService } from "./availability.service";

import {
  startOfWeek,
  endOfWeek,
  format,
  addMinutes
} from "date-fns";

import * as fromRoot from '../../store/reducers';
import * as fromDiary from '../../store/actions/diary';
import * as fromStaff from '../../store/actions/staff';
import * as fromAppointments from '../../store/actions/appointments';

import { Store } from "@ngrx/store";
import { map, debounceTime, take, distinctUntilChanged, switchMap } from "rxjs/operators";
import { StaffMember } from "../../models/staff";
import { StaffService } from "../staff.service";
import { RoomsService } from "../rooms.service";
import { Room } from "../../models/rooms";
import { AuthService } from '../../services/auth.service';
import { PatientChooserService } from "../patient.service";
import { ClinicService } from "../clinic.service";
import { TreatmentService } from "../treatment.service";
import { type Treatment } from "app/models/treatments";
import { UserSettingsService } from "../user-settings.service";
import { AppointmentColourService } from "./appointment-colour.service";
import { AvailablePermissions } from "app/models/auth";

export const Colors: any = {
  red: {
    primary: "#ad2121",
    secondary: "#FAE3E3",
    textColour: "#000000"
  },
  blue: {
    primary: "#1e90ff",
    secondary: "#D1E8FF",
    textColour: "#000000"
  },
  yellow: {
    primary: "#e3bc08",
    secondary: "#FDF1BA",
    textColour: "#000000"
  }
};


@Injectable({
  providedIn: "root"
})
export class DiaryService {
  public staff: StaffMember[] = [];
  public rooms: Room[];
//  private availability: Availability[];
  public privateView: boolean = true;
  private staffOrRooms$: Subscription;
  private staffOrRoom: string;
  private settings$;
  innerWidth: number;
 // public settings: Settings;

  private _clickedEvent: any;
  clickedEvent$: BehaviorSubject<any> = new BehaviorSubject(null);

  public EVENT_WIDTH = 100;
  // public allUsers = [];
  //    public users = [];
  public selectedRooms = [];

  public colors = Colors;
  
  /* : any = {
    red: {
      primary: "#ad2121",
      secondary: "#FAE3E3",
      textColour: "#000000"
    },
    blue: {
      primary: "#1e90ff",
      secondary: "#D1E8FF",
      textColour: "#000000"
    },
    yellow: {
      primary: "#e3bc08",
      secondary: "#FDF1BA",
      textColour: "#000000"
    }
  };
*/
  // public appointments.allEvents = [];

  public events$: Subject<any>;
  public diaryEventEmitter = new EventEmitter();
  public timeWindowChanged = new EventEmitter();
  private getAppointments$;
  private calendarDirty = true;
  private currentAppointments: any[];
  private availability;
  private availabilityDirty = true;
  private viewDate;
  private showOnlyWorkingStaff;
  private selectedView: ViewTypes;
  private settings;
  public groups: any = [];
  public allTreatments;
  public allStaff;
  public staffGroupBy: SortingMode;
  public focusedOn: {type: any, id: number} = DIARY_FOCUS_NONE;
  private c: boolean = true;
  public mobileView: boolean = false;
  private mobileView$: Subscription;
  private currentAppointmentObjects: any[];
  public numberOfStaffInGroups: number


  set clickedEvent(e){
    this._clickedEvent = e;
    this.clickedEvent$.next(e);
  }

  get clickedEvent(){
    return this._clickedEvent;
  }

  constructor(
    public api: DataService,
    public appointments: AppointmentsService,
    public availabilityService: AvailabilityService,
    private store: Store<fromRoot.State>,
    private staffService: StaffService,
    private roomsService: RoomsService,
    private auth: AuthService,
    private patientChooser: PatientChooserService,
    private clinic: ClinicService,
    private treatments: TreatmentService,
    private settingsService: UserSettingsService,
    private appointmentColourService: AppointmentColourService
  ) {
    this.initEventsObservable();
    this.initDiary();
/*
    if (this.auth.isLoggedIn()) {
        this.initDiary();
      } else {
        this.loggedInService.loggedInEmitter.subscribe(() => {
          this.initDiary();
        });
      }
*/

    this.store.dispatch(fromDiary.SetDraggingActive({ payload: false }));

    this.getAppointments$ = this.appointments.observeApps
      .subscribe(appointments => {
        console.log("[diary] [apps] changes gets", appointments);
        if (!appointments){ //not yet initalised so do not update
     //     console.log("[diary] [apps] nothing to see yet");
          return;
        }
        const newAppointments = appointments.map(appointment => this.buildAppointmentObject(appointment));
        this.rebuildEvents(newAppointments);  //this is a loop 
        this.currentAppointmentObjects = newAppointments;
        this.currentAppointments = appointments;
        this.hasWrokingStaffChanged();
      //  console.log("[staff] select update from getApps");
      if (this.availabilityDirty){
        this.selectWorkingStaff();
        this.availabilityDirty = false;
      }
    });

    this.staffOrRooms$ = this.getStaffOrRoomsView().subscribe(( staffOrRoom: any ) => this.staffOrRoom = staffOrRoom);
  }


  initDiary(){
    this.initialiseSettings();
    this.store.select(fromRoot.getCurrentDiaryPrivateView).subscribe(data => {
      this.privateView = data;
    });

    this.getAvailability().subscribe(res => {
      this.availability = res;
    });

    
    this.getCurrentDate().subscribe(res => { //date changes
      this.viewDate = res;
      console.log("[staff] select update from date change");
      this.currentAppointments = []; //dump the local appointment cache as it is no longer valid for checking availability
      this.selectWorkingStaff(); // <- should not run till after the appointments updates?
        this.availabilityDirty = true;
    });

    
    this.getCalendarSettings().subscribe(res => {
      console.log("[events] [settings] [sidebar] updates", res)
      console.log("[staff] select update from calendar settings");
      this.settings = res;
    });

    this.treatments.getTreatments().subscribe(res => {
      console.log("[dleft]", res);
      this.allTreatments = res;
      this.addStaffToTreatmentCats();
    });

    this.staffService.getStaff().subscribe(res => {
      this.allStaff = res;
      this.addStaffToTreatmentCats();
    });

    this.getStaffGroupBy().pipe(distinctUntilChanged()).subscribe(res => {
      this.staffGroupBy = res;
      this.addStaffToTreatmentCats();
    });

    this.getShowWorking().subscribe(res => {
      this.showOnlyWorkingStaff = res;
      console.log("[staff] select update from show working");
      this.selectWorkingStaff();
    })

    this.getStartEndHour().subscribe(res => {
      this.selectWorkingStaff();
    })

    this.store.select(fromRoot.getDiaryView).subscribe(res=>{
      console.log("[view] changes to ~ ", res);
      this.selectedView = res;
    });

    this.store.select(fromRoot.getFocusedOn).subscribe(res=>{
      console.log("[view] Focus = ", res);
      this.focusAction(res);
      this.focusedOn = res;
    });
    
    this.mobileView$ = this.settingsService.getMobileView().subscribe((mobileView: boolean) => {
      if ( this.currentAppointmentObjects && this.mobileView !== mobileView ) {
        this.currentAppointmentObjects = this.currentAppointmentObjects.map((app: any) => ( { ...app, draggable: !mobileView } ));
        this.emitAllApps(this.currentAppointmentObjects);
      }

      this.mobileView = mobileView;
    } );
  }

  hideRightSidebar() {
    this.store.dispatch(fromDiary.SetShowRightPanel({ payload: false }));

  }

  showRightSidebar(event) {
    this.clickedEvent = event;
    this.store.dispatch(fromDiary.SetShowRightPanel({ payload: true }));
    if (event.meta.status == AppointmentStatus.TIMEOFF){ //no further checks needed
      return;
    }

    /* why here ?

    this.patientChooser.getPatientFromServer(this.clickedEvent.meta.patientId).pipe(take(1)).subscribe(res=>{
      if (res.patientInfo.lastVisit){
         event.meta.lastVisit = res.patientInfo.lastVisit;
      }
      this.clickedEvent = event;
    })

    */
  }

  isPrevApp(app){
    return Date.parse(app.startTime) < Date.parse(new Date().toDateString())
  }

  


  setCancelled(cancelled: boolean) {
    console.log("['cancelled']", cancelled);
    this.store.dispatch(fromDiary.SetShowCancelled({payload: cancelled}));
  }

  

  getAppointmentSettings(selectOn: AppointmentSelectOn = StaffRoomsView.STAFF): Appointment {
    const diaryDate = this.getCurrentDateValue();
    const diaryView = this.getCurrentViewValue();
    let start;
    let end;
    let selectedVals = [];

    if ( this.staffOrRoom === StaffRoomsView.ROOMS ) selectedVals = this.getAllStaff().map(user => user.idx);
    if ( this.staffOrRoom === StaffRoomsView.STAFF ) selectedVals = this.getCurrentSelectedStaff().map(user => user.idx);
    
    //start = format(startOfWeek(new Date(diaryDate)), "yyyy-MM-dd 00:00:00");
    //end = format(endOfWeek(new Date(diaryDate)), "yyyy-MM-dd 23:59:59");

    
    if (diaryView == ViewTypes.WEEK) {
      start = format(startOfWeek(new Date(diaryDate)), "yyyy-MM-dd 00:00:00");
      end = format(endOfWeek(new Date(diaryDate)), "yyyy-MM-dd 23:59:59");
    } else {
      start = format(new Date(diaryDate), "yyyy-MM-dd 00:00:00");
      end = format(new Date(diaryDate), "yyyy-MM-dd 23:59:59");
    }

    const settings = {
      startTime: start,
      endTime: end,
      selectOn: selectOn,
      selectVals: selectedVals,
      diaryView: diaryView
    };

    console.log("[diary] [settings]", diaryDate, settings);

    return settings;

  }

  getAllStaff() {
    return this.staff;
  }


  private buildAppointmentObject(appointment): ProcessedAppointment {
    console.log("[app] bAO ", appointment);
    let colour: string;
    let textColour: string;

    if (appointment.status == AppointmentStatus.BOOKING) {
      colour = appointment.colour;
      textColour = "#000000";
    } else {
      colour = this.appointmentColourService.getColour(appointment.status, appointment.hasSOAP);
      textColour = this.appointmentColourService.getColour(`${appointment.status}Text`, appointment.hasSOAP);
    }

    if (appointment.status == AppointmentStatus.PENDING_APPROVAL_PAYMENT_REQUIRED) {
      colour = `rgba(0,0,0,0.2)`;// this.appointmentColourService.getColour(AppointmentStatus.PENDING_APPROVAL, appointment.hasSOAP);
      textColour = `rgba(0,0,0,0.2)`; //this.appointmentColourService.getColour(`${AppointmentStatus.PENDING_APPROVAL}Text`, appointment.hasSOAP);

    }
    

    let patientName = "";

    if (appointment.patientFirstname && appointment.patientFirstname!=null){
      patientName = `${appointment.patientFirstname} ${appointment?.patientLastname}`;
    }else{
      patientName = ""+appointment.patientLastname;
    }

    let title = patientName;
    
    if (this.privateView){
      title = appointment.treatment;
    }

    const dnaUnpaidCount = appointment.history ? appointment.history.find(f=> f.status==AppointmentStatus.NOSHOW)?.num : 0;
    const dnaTotalCount = appointment.history ? appointment.history.find(f=> f.status==AppointmentStatus.DNAPAID)?.num + dnaUnpaidCount : 0;
    const lncUnpaidCount = appointment.history ? appointment.history.find(f=> f.status==AppointmentStatus.LNC)?.num : 0;
    const lncTotalCount = appointment.history ? appointment.history.find(f=> f.status==AppointmentStatus.LNCPAID)?.num + lncUnpaidCount : 0;
    const lastDNA = appointment.history ? appointment.history.find(f=> f.status==AppointmentStatus.NOSHOW)?.last : 0;
    const lastLNC = appointment.history ? appointment.history.find(f=> f.status==AppointmentStatus.LNC)?.last : 0;
    const totalPaid  = appointment.history ? appointment.history.find(f=> f.status==AppointmentStatus.PAID)?.num : 0;

    return {
      idx: appointment.idx,
      title: title,
      color: { primary: "#333333", secondary: colour, textColour: textColour }, 
      start: this.fixDate(appointment.startTime),
      end: addMinutes(
        this.fixDate(appointment.startTime),
        appointment.duration
      ),
      meta: {
        apointmentUID: appointment.idx,
        SMSStatus: appointment.SMSStatus,
        user: this.returnStaffMember(appointment.staffIdx),
        patientId: appointment.patientIdx,
        treatment: appointment.treatment,
        patientName: patientName,
        roomName: appointment.roomname,
        roomIdx: appointment.roomIdx,
        status: appointment.status,
        notes: appointment.notes,
        hasSOAP: appointment.hasSOAP,
        transactionIdx: appointment.transactionIdx,
        patientGeneralNotes: appointment.patientGeneralNotes,
        patientDOB: appointment.patientDOB,
        nextAppointment: appointment.nextAppointment,
        groupSize: appointment.groupSize,
        history: {  
          dnaTotalCount: dnaTotalCount,
          dnaUnpaidCount: dnaUnpaidCount,
          lncTotalCount: lncTotalCount,
          lncUnpaidCount: lncUnpaidCount,
          lastDNA: lastDNA,
          lastLNC: lastLNC,
          totalPaid: totalPaid,
        },
        repeatVisitDays: appointment.RepeatVisitDays
      },
      resizable: {
        beforeStart: true,
        afterEnd: true
      },
      draggable: !this.mobileView
    };
  }

  getAvailability(): Observable<Availability[]>{
    return this.store.select(fromRoot.getAvailability);
  }

  setAvailability(availability){
    console.log("[apps update] set availability");

    this.store.dispatch(fromDiary.SetAvailablity({payload: availability}));
  //  this.updateAvailabity();
  }
 
  buildStaffObject(staff, color) {
    const modifiedStaff: StaffMember = {
      ...staff,
      color: color.next().value,
      selected: true
    };
    return modifiedStaff;
  }

  public getAppointmentsFromServer() {
    //console.log("[diary] getAppointments()", this.staff, this.rooms);
    if (!this.staff) return; //no staff yet
    if (!this.rooms) return; //no rooms yet
    
    const appointmentSettings: Appointment = this.getAppointmentSettings();
    if (appointmentSettings.selectVals.length == 0) {
     // console.log("[diary] abort nothing to show");
      return;
    }

    this.appointments.loadFromServer(appointmentSettings);
    console.log("[apps] [diary] [load] GAPS loadAppointments")
    
    this.store.select(fromRoot.getDraggingActive).pipe(take(1)).subscribe((draggingActive: boolean) => {
      if ( !draggingActive ) {
        this.store.dispatch(fromAppointments.LoadAppointments({payload: appointmentSettings}));
      }
    })
    
  
  }

  private rebuildAppointments(newAppointments){
  
    console.log("[apps] [diary] [load] update changes detected", newAppointments);

   // this.appointments.loadFromServer();
    //this.appointments.setAllAppointments(newAppointments); // <-- this is just a copy of state why store it twice
  //  this.updateAvailabity();
//    this.diaryEventEmitter.emit();//this.appointments.getAllAppointments());
   // this.emitAllApps(newAppointments);
  //  this.calendarDirty = false;
  }

  rebuildEvents(newAppointments) { //this also rebuild availability which is not neccessary so moved to own funciton
  
  //  this.rebuildAppointments(newAppointments);

    //this.diaryEventEmitter.emit();//this.appointments.getAllApointments());
    this.emitAllApps(newAppointments);

  }

  public emitAllApps(newAppointments){
    console.log("[events$] emits")
    this.events$.next(newAppointments);
  }

  initialiseSettings() {
    if (this.settings$) { //already done thanks
      
      return; 
    }
    console.log("[diary] service settings");
    const color = this.colourArrayIterator();
    this.settings$ = this.getSettings().subscribe(
      (settings: any) => {
        this.staffService.getStaff().pipe(take(1)).subscribe(res => {
          this.staff = res.map(staff => this.buildStaffObject(staff, color));
        });
        this.roomsService.getRooms().pipe(take(1)).subscribe(res => {
          this.rooms = res;

        });
        console.log("[settings] init", settings);

        this.store.dispatch(fromDiary.SetQuickSegmentBreaks({payload: {
          morningEnd: settings.morningEnd, 
          afternoonStart: settings.afternoonStart, 
          midSplitStart: settings.midSplitStart, 
          midSplitEnd: settings.midSplitEnd
        }}));
        this.store.dispatch(fromDiary.SetAvailablity({payload: settings.availability}));
        this.store.dispatch(fromDiary.SetColours({payload: settings.colours}));
        this.store.dispatch(fromDiary.SetStartHour({payload: settings.startHour}));
        this.store.dispatch(fromDiary.SetEndHour({payload: settings.endHour}));
        this.store.dispatch(fromDiary.SetIncrement({payload: settings.increment}));
        this.store.dispatch(fromDiary.SetHourSegments({payload: (60 / settings.increment) }));
        this.store.dispatch(fromDiary.SetStaffGroupBy({payload: settings.staffGroupBy}));
        this.setAvailability(settings.availability);

        this.getAppointmentsFromServer();
        console.log("[pm] check now please");
        if ( this.staff.length === 1 && this.auth.permission(AvailablePermissions.diary_lock_to_own) ) {
          this.setWeekOrDayView(ViewTypes.WEEK);
          this.updateSelectedStaff(this.staff);
        }

        //check we have a valid view state, if nothing selected then force a select all
        this.getDiaryDisplayState().pipe(distinctUntilChanged()).subscribe(res => {
          if (!res.staffDayView || res.staffDayView.length == 0) {
//            this.updateSelectedStaff(this.staff);
          }
          if (!res.roomDayView || res.roomDayView.length == 0) {
//            this.updateSelectedRooms(this.rooms);
          }
        });
      }
    );
  }

  fixDate(date) {
    return new Date(Date.parse(date.replace(/-/g, "/")));
  }

  public initEventsObservable() {
    
//this allows subscribers to monitor for when all appointments has been updated. Does not fetch changes from the server.    
    this.events$ = new Subject();
    /*
    Observable.create(observer => {
      this.diaryEventEmitter
      .subscribe(event => {
        observer.next(this.appointments.getAllAppointments());
      });

    });
*/
    // watch all the vars that could need a data refresh and trigger it if needed. ?? Surely this is only date
    this.store.select(fromRoot.getDiaryDataRefreshNeeded)
      .pipe(debounceTime(1000))
      .subscribe(res => {

        // console.profileEnd();
        console.log("[apps] [diary] *** getDiaryDataRefreshNeeded *** with", res);
        //getAppoitmentsFromServer fixes the appointments not appearing when changed to week view in diary
        this.getAppointmentsFromServer(); //is this doubling up? - NO... actually yes we only need to fetch from the server if date changes. other wise just request an email
        this.appointments.forceAppointmentEmit();
    });

    this.store.select(fromRoot.getCurrentViewDate)
    .subscribe(res=>{
        console.log("[apps] [diary] *** diary date changes *** with", res);
        this.getAppointmentsFromServer();
    });
  }

  getSettings() {
    return this.clinic.getSelectedClinic().pipe(
        switchMap( res=> {
          if ( res && res.idx ) return this.api.get(`/diary/settings/${res.idx}`);

          return EMPTY;
    }));
  }

  colourArrayIterator() {
    let nextIndex = 0;
    let iterationCount = 0;
    const rangeIterator = {
      next: () => {
        let result;
        if (nextIndex < Object.keys(this.colors).length) {
          result = {
            value: this.colors[Object.keys(this.colors)[nextIndex]],
            done: false
          };
          nextIndex += 1;
          iterationCount++;
        } else {
          nextIndex = 0;
          result = {
            value: this.colors[Object.keys(this.colors)[nextIndex]],
            done: false
          };
          nextIndex++;
          iterationCount++;
        }
        return result;
      }
    };
    return rangeIterator;
  }

  returnStaffMember(idx): StaffMember {
    // if (!this.staff) console.log("STAFF NOT INITALISED ERROR");
      const member = this.staff.filter(staff => staff.idx === idx)[0];
    if (!member) {
            console.warn(`staff lookup failed; find ${idx} results in ${member}`);
            console.log("[staff] look up from ", this.staff);
            return {
              defaultFirstTreatment: 0,
              defaultFollowUpTreatment: 0,
              firstname: "unknown",
              defaultRoomIdx: 0,
              idx: idx,
              lastname: "unknown",
              staffGroupIdx: 0,
              treatFromCat: 0,
            };
    }
    return member;
  }


  // takes a difference in days from currentdate then emits an event that changes the diary. If delta is ZERO then goes to today.
  setDate(currentDate, deltaDays?: number) {

    console.log("[diary] [set date]", currentDate, deltaDays);
    //Changes the date format so that when DST changes the selector class on the calender doesn't break
    currentDate = format(new Date(currentDate), "yyyy-MM-dd 08:00:00");
    let newDate;
    switch (deltaDays) {
      case null:
      case undefined:
        newDate = new Date(currentDate);
        break;
      case 0:
        newDate = new Date();
        break;
      default:
        this.getCurrentView().pipe(take(1)).subscribe(res => {
          if (res == ViewTypes.WEEK && Math.abs(deltaDays)===1) deltaDays = deltaDays * 7;
        });
        newDate = new Date(currentDate);
        newDate.setDate(newDate.getDate() + deltaDays);
        break;
    }
    console.log("[diary] set: "+newDate.getDay());
    this.store.dispatch(fromDiary.SetDate({ payload: newDate.getTime() }));
  }

  setWeekOrDayView(view: ViewTypes) {
    console.log("[view] SET => ", view);
    this.calendarDirty = true;
    this.store.dispatch(fromDiary.SetWeekOrDayView({ payload: view }));

  }

  setZoom(zoom: number) {
    this.store.dispatch(fromDiary.SetZoom({ payload: zoom }));
  }

  setPrivate(privateView: boolean) {
    this.calendarDirty = true;
    this.store.dispatch(fromDiary.SetPrivate({ payload: privateView }));
    //this.getAppointments();
  }

  bulkMarkStaffSelected(ids: number[]){
    console.log("[selected] [staff] ticking ", ids);
    this.store.dispatch(new fromStaff.BulkMarkSelected(ids));
  }

  deselectAllStaff(){
    this.store.dispatch(new fromStaff.SelectNone);
  }

  selectAllStaff(){
    this.store.dispatch(new fromStaff.SelectAll);
  }

  selectStaffMember(idx: number, selected: boolean){
    this.store.dispatch(new fromStaff.MarkSelected({ idx: idx, selected: selected }));
  }

  updateSelectedStaff(staff) {
    this.calendarDirty = true;
    console.log("[selected] [staff] dispatch", staff);
 
    const staffIds = staff.map(s=>(s.idx));

    this.bulkMarkStaffSelected(staffIds);
 
  }

  updateSortOrder(order){
    this.store.dispatch(new fromStaff.SetSortOrder(order));
  }

  updateSelectedRooms(rooms) {
    this.calendarDirty = true;
    this.store.dispatch(fromDiary.SetSelectedRooms({ payload: rooms }));
  }
/*
  updateSelectedStaffWeekView(staff) {
    this.calendarDirty = true;
    this.store.dispatch(fromDiary.SetSelectedStaffWeekView({ payload: staff }));
  }
*/

  updateSelectedRoomWeekView(room) {
    this.calendarDirty = true;
    this.store.dispatch(fromDiary.SetSelectedRoomWeekView({ payload: room }));
  }

  updateStaffOrRoomsView(view: StaffRoomsView) {
    this.calendarDirty = true;
    this.store.dispatch(fromDiary.SetStaffOrRoomView({ payload: view }));
  }

  getStaffOrRoomsView() {
    return this.store.select(fromRoot.getStaffOrRoomsView)
  }
  getPractitionerArrayLength() {
    return this.getCurrentSelectedStaff().length;
  }
  getRoomArrayLength(){
    return this.getCurrentSelectedRooms().length;
  }

  getCurrentSelectedStaff() {
    let selStaff;

    if (this.getCurrentViewValue() == ViewTypes.DAY) {
      this.getSelectedStaffDayView().pipe(take(1)).subscribe(res => {
        selStaff = res;
      });
    } else {
      this.getSelectedStaff().pipe(take(1)).subscribe(res => {
        selStaff = res;
      });
    }
    return selStaff;
  }


  getSelectedStaff(): Observable<any[]> {
    return this.store.select(fromRoot.getSelectedStaff);
  }

  getSelectedStaffDayView(): Observable<any[]> {
    return this.store.select(fromRoot.getSelectedStaff).pipe(map((res: any[]) => {
      console.log("[staff] gss day view ", res);
      if (!res || res.length == 0) {
        return this.staff;
      }
      return res;
    }));
  }

  getSelectedStaffWeekView(): Observable<any[]> {
    return this.store.select(fromRoot.getSelectedStaff);
    
    /*
    .pipe(map((res:any) => {
      if (!res || res.length == 0) {
        return this.staff;
      }
      return res;
    }));
    */
  }


   getCurrentSelectedRooms() { //this takes into account view state
    let selRooms;

    if (this.getCurrentViewValue() == ViewTypes.DAY) {
      this.getSelectedRooms().pipe(take(1)).subscribe(res => {
        //        console.log(res)
        selRooms = res;
      });
    } else {
      this.store.select(fromRoot.getSelectedRoomWeekView).pipe(take(1)).subscribe(res => {
        selRooms = res;
      });
    }

    return selRooms;
  }

  getSelectedRoomsWeekView() {
    return this.store.select(fromRoot.getSelectedRoomWeekView).pipe(distinctUntilChanged());
  }

  getSelectedRooms() {
    return this.store.select(fromRoot.getSelectedRooms).pipe(distinctUntilChanged());
  }

  getShowWorking() {
    return this.store.select(fromRoot.getShowWorking).pipe(distinctUntilChanged());
  }

  updateWorking(timeWindow : {startHour: number, endHour: number}){
    console.log("[events] update [working] hours: ", timeWindow);
    this.store.dispatch(fromDiary.SetWorkingHours({ payload: timeWindow }));
  }

  resetWorking(){
    this.store.dispatch(fromDiary.ResetWorkingHours());
  }

  setShowWorking(w: boolean){
    this.store.dispatch(fromDiary.SetShowWorking({payload: w}));
  }

  getCurrentDateValue() {
    let v;
    this.getCurrentDate().pipe(take(1)).subscribe(res => {
      v = res;
    });
    return v;
  }

  getCurrentViewValue() {
    return this.selectedView;
  }

 

  getCurrentDate() {
    // access the current date from the app state so it can be set anywhere.
    return this.store.select(fromRoot.getCurrentViewDate).pipe(map(res => {
      if (!res) { // handle no date / invalid date
        return new Date();
      }
      return new Date(res);
    }));
  }

  getStartEndHour() {
    return this.store.select(fromRoot.getDiaryStartEndHour);
  }

  getCurrentView(): Observable<ViewTypes> {
    return this.store.select(fromRoot.getCurrentDiaryView).pipe(
      distinctUntilChanged(), 
      map(res => {
      if (!res) {
        return ViewTypes.WEEK;
      }
      return res;
    }));
  }

  getCurrentZoom() {
    return this.store.select(fromRoot.getCurrentDiaryZoom).pipe(map(res => {
      if (!res) {
        //        console.log("No recorded zoom")
        return 20;
      }

      return res;
    }));
  }

  getIncrement(){
    return this.store.select(fromRoot.getIncrement).pipe(take(1));
  }

  getCalendarSettings(): Observable<CalendarSettings>{
    return this.store.select(fromRoot.getCalendarSettings);
  }

  getStaffGroupBy(){
    return this.store.select(fromRoot.getStaffGroupBy);
  }

  getDiaryDisplayState() {
    return this.store.select(fromRoot.getDiaryDisplayState);
  }


  getSidebarHidden() {
    return this.store.select(fromRoot.getCurrentDiaryShowRightPanel)

  }

  

  private getMinsStartEnd(){
    const minsStart = (this.settings.startHour * 60); 
    const minsEnd = minsStart + ((this.settings.endHour-this.settings.startHour) * 60);
    return [minsStart, minsEnd];
  }

  private hasWrokingStaffChanged(){ //look at apps to see if we have anyh randos that are working that should not normally be
    console.log("[working] ? ", this.showOnlyWorkingStaff);
    if (!this.showOnlyWorkingStaff){
      return; //do not override if in manual selection mode
    }
    const selectedStaff = this.getCurrentSelectedStaff();
    console.log("[working staff]", selectedStaff, this.currentAppointments);
    const [minsStart, minsEnd] = this.getMinsStartEnd();
    for (let app of this.currentAppointments){
      const staffOk = selectedStaff.find(f=> f.idx==app.staffIdx);
      if (!staffOk){
        console.log("[working staff] staff not listed found");
        const appStart = new Date(app.startTime).getHours() * 60;
        const appEnd = appStart + app.duration;

        if ((appStart > minsStart && appStart < minsEnd) || (appEnd > minsStart && appEnd < minsEnd)){
          this.selectStaffMember(app.staffIdx, true);
        }
        
      }
    }


  }

  private selectWorkingStaff(staffToSelect = []){
    

    if (!this.showOnlyWorkingStaff || this.selectedView != ViewTypes.DAY) {
      return; //we should not be here, leave
    }
    const vdDate = this.viewDate
    // Set viewDate to beginning of day
    vdDate.setHours(0,0,0,0)
    
    const vdTime = vdDate.getTime()

    const [minsStart, minsEnd] = this.getMinsStartEnd();

    const dayStart: number = 
          (Math.ceil((vdTime - this.availabilityService.getWeekStartUnixTime(this.viewDate)) / (1000 * 60))) 
      + (this.settings.startHour * 60); 
    
    const dayEnd: number = dayStart + ((this.settings.endHour-this.settings.startHour) * 60);   

  //if something is supplied then its the appointments that have changed so may need to show more?
  console.log("[working] [staff] for day ", this.viewDate);
    if (staffToSelect.length == 0 ){
      console.log("[working] [staff] checks availability ", this.availability);
      for (let avail of this.availability){
        if (avail.available.find(f => (f > dayStart && f < dayEnd))) {
  //          const staffMemeber = this.allStaff.find(f => f.idx == avail.staffIdx);
            for (let group of this.groups){
                const staffMember = group.staff.find(f => f.idx == avail.staffIdx);
                if (staffMember){
                  staffToSelect.push(staffMember);
                }
              }         
            
        }
      }
    }

  
/*
    console.log("[working] [staff] checks apps ", this.currentAppointments);
    for (let group of this.groups){ // itterate groups
      for (let staff of group.staff){ // staff in groups
        if (!staffToSelect.includes(staff) && this.currentAppointments){ // is staff member selected aleady?
          
          const appFound = this.currentAppointments.find(f => {
              if (f.staffIdx != staff.idx) { return false; } //staff don't match do not proceed to time checks
              const appStart = new Date(f.startTime).getHours() * 60;
              const appEnd = appStart + f.duration;
  
              if ((appStart > minsStart && appStart < minsEnd) || (appEnd > minsStart && appEnd < minsEnd)){
                console.log("[staff] [match.com] time ok", f );
                return true;
              }
              return false;
          }); // do they have and appointment?
          
          if (appFound){
              console.log("[staff] [match.com] pushing", staff );
              staffToSelect.push(staff);
          }
        }
      }
    }
*/
    console.log("[staff] selectWorkingStaff() are::  ", staffToSelect);

    this.updateSelectedStaff(staffToSelect);
    this.hasWrokingStaffChanged();


    //setTimeout(()=> { 
    //  console.log("[working staff] TO TO");
    //  this.hasWrokingStaffChanged(); }, 1000);
    
    return staffToSelect;
  
  }

  public sortStaffBy(staff: StaffMember[], method: SortingMode): void {
    switch ( method ) {
      case SortingMode.treatment: return this.sortByTreatmentIdx(staff);
      case SortingMode.group: return this.sortByGroupIdx(staff);
    }
  }

  private truncateStaffName(name: string): string {
    return ( name.length > 20 ) ? name.substring(0, 20) + " ..." : name;
  }

  private sortByGroupIdx(staff: StaffMember[]): void {
    this.appointments.getGroups().subscribe((groups: any) => {
      this.groups = groups;

      console.log("[groups] sort By GroupIdx", groups);

      this.groups = this.groups.map((group: any) => {
        
        return {
          ...group,
          selected: true,
          staff: staff.filter((s: StaffMember) => s.staffGroupIdx === group.idx).map((member: StaffMember) => {
            return {
              name: this.truncateStaffName(`${member.firstname} ${member.lastname}`),
              idx: member.idx,
              selected: member.selected,
              working: true
            }
          })
        }
      })
      this.groupsUpdated();
    })

  }

  private sortByTreatmentIdx(staff: StaffMember[]): void {

    this.groups = this.allTreatments.map((treatment: Treatment) => {

      return Object.assign({}, treatment, {
        selected: true,
        staff: staff.filter(s => s.treatFromCat == treatment.idx).map(r => { return { 
          name: this.truncateStaffName(`${r.firstname} ${r.lastname}`), idx: r.idx, selected: r.selected, working: true }; 
        })
      });

    });

    this.groups.push({
      idx: 0,
      name: "Not set",
      selected: true,
      staff: staff.filter(s => !s.treatFromCat).map(r => { return { name: `${r.firstname} ${r.lastname}`, idx: r.idx, selected: r.selected, working: true }; })
    });

    console.log("[groups] sort By TreatmentIdx", this.groups);

    this.groups = this.groups.filter(t => t.staff.length > 0);

    this.groupsUpdated();
  }

  groupsUpdated(){
    let order = [];
    for (let group of this.groups){
      for (let staff of group.staff){
        order.push(staff.idx);
      }
    }
    this.updateSortOrder(order);

    console.log("[groups] [staff] has been updated", this.groups);

    this.numberOfStaffInGroups = 0
    this.groups.forEach(group => {
      this.numberOfStaffInGroups += group.staff.length
    })
  }


  
  addStaffToTreatmentCats() {
    console.log("[staff] allstaff=", this.allStaff);

    if ( !this.allTreatments || !this.allStaff ) return;

    this.sortStaffBy(this.allStaff, (this.staffGroupBy || SortingMode.treatment));

    this.setShowWorking(true);
  }


  //this file is too BIG

  public focusOn(focus: DiaryFocusOnType){
    console.log(`[focus] DISPATCH`, focus);
    this.store.dispatch(fromDiary.SetFocusOn({payload: {type: focus.type, id: focus.id}}));
  }

  private focusAction(focusOn: DiaryFocusOnType){
    if (this.focusedOn.type == focusOn.type && this.focusedOn.id == focusOn.id && focusOn.type != DiaryFocusOn.NONE ){
      this.focusOn(DIARY_FOCUS_NONE); //appears a double click
      return;
    }

    switch (focusOn.type){
      case DiaryFocusOn.STAFF:
        this.setShowWorking(false);
        this.focusStaff(focusOn.id);
        return;

      case DiaryFocusOn.GROUP:
        this.setShowWorking(false);
        this.focusGroup(focusOn.id);
        return;

      case DiaryFocusOn.NONE: //is this ever valid?
        console.log("[view] focus on none");
        this.setWeekOrDayView(ViewTypes.DAY);
        this.setShowWorking(true);
    }
  }


  private focusStaff(member: number ) {
    // Find staff member
    
    const staffToSelect = [this.allStaff.find(f => f.idx == member)];
    console.log(`[focus] on staff memember ${member}`, member, staffToSelect);
    // If already in week view
    if ( this.selectedView === ViewTypes.WEEK ) {
      //  with the same staff member
       // with a different staff member
        // change to correct staff
        this.updateSelectedStaff(staffToSelect);
        this.selectTreatments(member);
    } else { // still in day view
      // Change to week view of staff member
      this.setWeekOrDayView(ViewTypes.WEEK);
      this.updateSelectedStaff(staffToSelect);
      this.selectTreatments(member);
    }
  }

  private focusGroup(group: number) {
    // Change to day view if not already
    this.setWeekOrDayView(ViewTypes.DAY);
    // If already in focus view
    if (this.focusedOn.id !== 0) {
      // with the same group 
        // change to correct group 
        
        let staffToSelect = [];
        this.groups
         .find(f => f.idx == group)
         .staff.forEach(member => {
           staffToSelect.push(this.allStaff.find(f => f.idx == member.idx));
        });
        this.updateSelectedStaff(staffToSelect);
    } else { // still in normal view
      // change to group focus view

        let staffToSelect = [];
        this.groups
         .find(f => f.idx == group)
         .staff.forEach(member => {
           staffToSelect.push(this.allStaff.find(f => f.idx == member.idx));
        });
        this.updateSelectedStaff(staffToSelect);
    }
  }

  public selectTreatments(memberIdx: number): void {
    this.groups = this.groups.map((treatment: any) => {
      const foundStaff = treatment.staff.findIndex((member_: any) => member_.idx === memberIdx);

      return {
        ...treatment,
        selected: foundStaff !== -1,
        staff: treatment.staff.map((member_: any, index: number) => {
          if (index === foundStaff) {
            this.focusedOn = { type: DiaryFocusOn.STAFF, id: member_.idx };
            return { ...member_, selected: true }
          }

          return { ...member_, selected: false }
        })
      }
    })
  }

/*
  public showNotesInAppointment(event: any): boolean {
    console.log(event);
    return false;
    /*
    const notAvailable: boolean = (notAvailablePhrases.indexOf(event.title) !== -1); //need to check all phrases 
   // const duration: number = ( event.end.getTime() - event.start.getTime() ) / 60000;
    const tallEnough: boolean = true; // duration > 45; //this is arbitrary,

    return notAvailable && tallEnough;
    
  }
*/
/*
  public noteInAppointment(event: any, note: string): string { //this is too arbitary
      const duration: number = ( event.end.getTime() - event.start.getTime() ) / 60000; 
      return ( duration < note.length ) ? note.substring(0, duration - 20) + "..." : note;
  }
*/

  public forceAppointmentEmit(){
    this.appointments.forceAppointmentEmit();
  }
}
