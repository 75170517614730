import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-view-all-gift-cards',
  templateUrl: './view-all-gift-cards.component.html',
  styleUrls: ['./view-all-gift-cards.component.scss']
})
export class ViewAllGiftCardsComponent implements OnInit {
  shouldShowGiftCardForm = false;
  public giftcards = [{transactionID: 2367, voucherID: 7, purchasedBy: 'Jess Cat', value: 10.00, issuedOn: new Date(),
  usedOn: new Date(), code: 'GKJED'}];
  displayedColumns: string[] = ['transactionID', 'voucherID', 'purchasedBy', 'value', 'issuedOn', 'usedOn', 'code'];


  constructor() { }

  ngOnInit() {
  }

  showGiftCardForm(value: boolean) {
    this.shouldShowGiftCardForm = value;
  }

}
