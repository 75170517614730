import { Injectable } from '@angular/core';
import * as fromRoot from "../store/reducers";
import { Store } from "@ngrx/store";
import * as staffActions from "../store/actions/staff";
import * as paymentMethodsActions from "../store/actions/payment-methods";
import * as saleItemsActions from "../store/actions/saleItems";
import * as treatmentsActions from "../store/actions/treatments";
import * as roomsActions from "../store/actions/rooms";
import * as clinicsActions from "../store/actions/clinics";
import * as siteSettingsActions from "../store/actions/site-settings";
import * as userSettingsActions from "../store/actions/user-settings";
import * as menuActions from "../store/actions/menu"
import * as diaryActions from "../store/actions/diary"
import * as authActions from '../store/actions/auth';

import { Router } from '@angular/router';

export const REQUIREMENT = {
    auth: "Auth",
    clinics: "Clinics",
    diary: "Diary",
    menu: "Menu",
    paymentMethods: "Till",
    rooms: "Rooms",
    saleItems: "Sale Items",
    siteSettings: "Site Settings",
    userSettings: "User Settings",
    staff: "Staff",
    treatments: "Treatments",   
};

@Injectable()
export class InitalisationService {

    public state: fromRoot.State
    private getState$;
    private checkRequirements$;

    public requirements =
        [
            {
                require: REQUIREMENT.auth,
                ok: false
            },
            {
                require: REQUIREMENT.clinics,
                ok: false
            },
            {
                require: REQUIREMENT.diary,
                ok: false
            },
            {
                require: REQUIREMENT.menu,
                ok: false
            },
            {
                require: REQUIREMENT.paymentMethods,
                ok: false
            },
            {
                require: REQUIREMENT.rooms,
                ok: false
            },
            {
                require: REQUIREMENT.saleItems,
                ok: false
            },
            {
                require: REQUIREMENT.siteSettings,
                ok: false
            },
            {
                require: REQUIREMENT.userSettings,
                ok: false
            },
            {
                require: REQUIREMENT.staff,
                ok: false,
            },
            {
                require: REQUIREMENT.treatments,
                ok: false,
            }
        ];

    constructor(private store: Store<fromRoot.State>, private router: Router) { }

    init() {
        if (this.getState$) {
            return;
        }
        this.getState$ = this.store.select(fromRoot.getState).subscribe(res => {
            this.state = res;
            if (this.checkRequirements() && this.getState$){
                this.getState$.unsubscribe();
                this.getState$ = null;
            };
        });
    }

    checkRequirements() : boolean {
        if (!this.checkRequirements$) {
            this.checkRequirements$ = true;
            this.checkRequirementsDebounced();
        }

        for (let requirement of this.requirements){
            if (!requirement.ok){
                return false;
            }
        }
        console.log("[init] OK :)");
        return true;   
    }

    resetRequirements(){
        this.requirements.map(requirement => {
            return Object.assign(requirement, {ok: false});
        });
    }

    private checkFailed(action) {
        this.store.dispatch(action);

    }


    public softResetState(){ //clears all state data and starts a reload
        console.log("[state] soft resetting");
        this.resetRequirements();
    //clear all state
        this.checkFailed(new clinicsActions.InitAction);
        this.checkFailed(new menuActions.LoadMenu);
        this.checkFailed(new paymentMethodsActions.InitAction);
        this.checkFailed(new roomsActions.InitAction);
        this.checkFailed(new saleItemsActions.InitAction);
        this.checkFailed(new userSettingsActions.InitAction);
        this.checkFailed(new siteSettingsActions.InitAction);
        this.checkFailed(new staffActions.InitAction);
        this.checkFailed(new treatmentsActions.InitAction);
    }


    private checkRequirementsDebounced(): void {
        let ok = true;
        setTimeout(() => {
            this.checkRequirements$ = false;
        }, 5000);

        for (let requirement of this.requirements.filter(f=>(!f.ok))) {
            switch (requirement.require) {
                case REQUIREMENT.auth:
                    this.store.dispatch(authActions.RefreshAction());
                    if (this.state.auth.jwt) {
                        requirement.ok = true;
                    } else {

                    }
                    break;
                case REQUIREMENT.clinics:
                    if (this.state.clinics.clinics.length > 0) {
                        requirement.ok = true;
                    } else {
                        this.checkFailed(new clinicsActions.InitAction);
                    }
                    break;
                case REQUIREMENT.diary:
                    if (this.state.diary.increment && this.state.diary.increment>0){
                        requirement.ok = true;
                    } else {

                    }
                case REQUIREMENT.menu:
                    if (this.state.menu.items.length > 0) {
                        requirement.ok = true;
                    } else {
                        this.checkFailed(new menuActions.LoadMenu);
                    }
                    break;

                case REQUIREMENT.paymentMethods:
                    if (this.state.paymentMethods.paymentMethod.length > 0) {
                        requirement.ok = true;
                    } else {
                        this.checkFailed(new paymentMethodsActions.InitAction);
                    }
                    break;

                case REQUIREMENT.rooms:
                    if (this.state.rooms.rooms.length > 0) {
                        requirement.ok = true;
                    } else {
                        this.checkFailed(new roomsActions.InitAction);
                    }
                    break;

                case REQUIREMENT.saleItems:
                    if (this.state.saleItems.saleItems) {
                        requirement.ok = true;
                    } else {
                        this.checkFailed(new saleItemsActions.InitAction);
                    }
                    break;

                case REQUIREMENT.siteSettings:
                    if (this.state.siteSettings.site.topLogo!="") {
                        requirement.ok = true;
                    } else {
                        this.checkFailed(new siteSettingsActions.InitAction);
                    }
                    break;
                case REQUIREMENT.userSettings:
                        if (this.state.userSettings.loaded) {
                            requirement.ok = true;
                        } else {
                            this.checkFailed(new userSettingsActions.InitAction);
                        }
                        break;
                case REQUIREMENT.staff:
                    if (this.state.staff.staff.length > 0) {
                        requirement.ok = true;
                    } else {
                        this.checkFailed(new staffActions.InitAction);

                    }
                    break;

                case REQUIREMENT.treatments:
                    if (this.state.treatments.treatments.length > 0) {
                        requirement.ok = true;
                    } else {
                        this.checkFailed(new treatmentsActions.InitAction);
                    }
                    break;
            }
        }

    }

}
