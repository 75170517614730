import { Component, OnInit } from '@angular/core';
import { NotesBuilderBaseComponent } from '../../notes-builder/notes-builder-base/notes-builder-base-component';

@Component({
  selector: 'app-fast-alcohol-screening-test',
  templateUrl: './fast-alcohol-screening-test.component.html',
  styleUrls: ['./fast-alcohol-screening-test.component.scss']
})
export class FastAlcoholScreeningTestComponent extends NotesBuilderBaseComponent {

}
