<form autocomplete="off" #formEle="ngForm">

    <mat-label>MEN: How often do you have EIGHT or more drinks on one occasion? </mat-label>
    <div>
        <mat-label>WOMEN: How often do you have SIX or more drinks on one occasion?</mat-label>
    </div>
    <mat-form-field [style.width.%]="100">
        <mat-label>Select answer</mat-label>
        <mat-select [(ngModel)]="form.alcohol_cunsumption_record" name="alcohol_cunsumption_record">
            <mat-option [value]="'Never'">Never</mat-option>
            <mat-option [value]="'Less than Monthly'">Less than Monthly</mat-option>
            <mat-option [value]="'Monthly'">Monthly</mat-option>
            <mat-option [value]="'Weekly'">Weekly</mat-option>
            <mat-option [value]="'Daily or almost daily'">Daily or almost daily</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-label>How often in the last year have you been unable to remember what happened the night before you have
        been drinking?</mat-label>
    <mat-form-field [style.width.%]="100">
        <mat-label>Select answer</mat-label>
        <mat-select [(ngModel)]="form.alcohol_memory_loss" name="alcohol_memory_loss">
            <mat-option [value]="'Never'">Never</mat-option>
            <mat-option [value]="'Less than Monthly'">Less than Monthly</mat-option>
            <mat-option [value]="'Monthly'">Monthly</mat-option>
            <mat-option [value]="'Weekly'">Weekly</mat-option>
            <mat-option [value]="'Daily or almost daily'">Daily or almost daily</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-label>How often during the last year you failed to do what was normally expected of you beacuse of
        drinking?</mat-label>
    <mat-form-field [style.width.%]="100">
        <mat-label>Select answer</mat-label>
        <mat-select [(ngModel)]="form.alcohol_related_fails" name="alcohol_related_fails">
            <mat-option [value]="'Never'">Never</mat-option>
            <mat-option [value]="'Less than Monthly'">Less than Monthly</mat-option>
            <mat-option [value]="'Monthly'">Monthly</mat-option>
            <mat-option [value]="'Weekly'">Weekly</mat-option>
            <mat-option [value]="'Daily or almost daily'">Daily or almost daily</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-label>In the last year has a relative or friend, or a doctor or other health worker been concerned about
        your drinking or suggested you cut down?</mat-label>
    <mat-form-field [style.width.%]="100">
        <mat-label>Select answer</mat-label>
        <mat-select [(ngModel)]="form.alcohol_asked_to_quit" name="alcohol_asked_to_quit">
            <mat-option [value]="'No'">No</mat-option>
            <mat-option [value]="'Yes, on one occasion'">Yes, on one occasion</mat-option>
            <mat-option [value]="'Yes, on more than one occasion'">Yes, on more than one occasion</mat-option>
        </mat-select>
    </mat-form-field>

</form>