import { AppointmentStatus } from "../../models/diary";
import { Store } from "@ngrx/store";
import * as fromRoot from 'app/store/reducers';
import { Injectable } from "@angular/core";
import { take } from "rxjs/operators";

@Injectable({
    providedIn: "root"
})
export class AppointmentColourService {
    AppointmentStatus = AppointmentStatus;

    constructor(private store: Store<fromRoot.State>) {

    }

    public getColour(status: string, hasSOAP: boolean = false) {
        let out;
        this.store.select(fromRoot.getColours).pipe(take(1)).subscribe(colours => {
            if (!colours) {
                out = '#ffffff'; // colous not loaded yet :(
            } else if (!colours[status]) {
                out = '#eeeeee';
            } else if (status === AppointmentStatus.PAID && !hasSOAP) {
                out = colours[AppointmentStatus.PAID_NO_SOAP];
            } else {
                out = colours[status];
            }
        });
        return out;
    }
}
