import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { type PatientDetails } from 'app/models/patient-details';
import { DialogService } from 'app/services/dialog/dialog.service';
import { NavigationService } from 'app/services/navigation.service';
import { NotesService } from 'app/services/notes/notes.service';
import { PatientInfoBoxService } from 'app/services/notes/patient-info-box.service';
import { Subscription } from 'rxjs';


export class Consultation {
  constructor () {
    this.type =  null;
    this.contacted = null;
    this.overview = null;
    this.signature = null;
    this.time =  `${new Date().getHours()}:${new Date().getMinutes()}`;
    this.date =  new Date();
  }
  date: any;
  time: any;
  callTime: any;
  type: string;
  contacted: string;
  overview: string;
  signature: boolean;
  contactMethod: any;
}

export const TelephoneConstultationMethod = {
  home: "home",
  mobile: "mobile"
}
@Component({
  selector: 'app-telephone-consultation',
  templateUrl: './telephone-consultation.component.html',
  styleUrls: ['./telephone-consultation.component.scss']
})
export class TelephoneConsultationComponent implements OnInit, OnDestroy {
  consultation: Consultation = new Consultation();
  public errorMessage: string;

  patientInfo$: Subscription;
  consultations: Consultation[];
  patientIdx: number;
  public TelephoneConstultationMethod = TelephoneConstultationMethod;
  public patientInfo: PatientDetails;
  private canLeave = true
  @HostListener('window:beforeunload', ['$event'])
  beforeUnloadHander(event) {
      return this.canLeave //if true user can leave else a warning box comes up
  }

  constructor(
    private noteService: NotesService,
    private nav: NavigationService,
    private pibs: PatientInfoBoxService,
    private dialogService: DialogService
  ) { }

  ngOnInit(): void {
    this.patientInfo$ = this.pibs.getPatientIdx().subscribe(res => {
      console.log("[phone] pid updates", res);
      this.patientIdx = res.idx;
      this.loadNotes(res.idx);
    });
  }

  ngOnDestroy(){
    this.patientInfo$.unsubscribe();
  }

  cantLeave(e) {
  if (e.target.value == '') {
    this.canLeave = true
  }else{    this.canLeave = false}
  }
  private loadNotes(patientIdx) {
    if (!patientIdx){
      console.warn("refuse to get telephone notes with no patient id");
      return;
    }

    this.pibs.getPatientDetails().subscribe(res => {
      this.patientInfo = res;
     });


   this.noteService.getTelephoneConsultations(patientIdx)
   .subscribe((res:{ consultations: Consultation[], patientIdx: number} ) => {
     this.consultations = res.consultations;
     this.patientIdx = res.patientIdx;
     this.consultations.map(consult=>{
      consult.time = new Date(consult.callTime);
      consult.date = new Date(consult.callTime);
      return consult;
     });
   });
  }

  addConsultation() {
    if (!this.consultation.overview || this.consultation.overview === "" || this.consultation.overview.length < 2) {
      return;
    }
  
    // Check if a contact method has been selected
    if (!this.consultation.contactMethod) {
      // Display a message to the user that no contact method selected
      this.selectContactModal()
      return;
    }
  
    const dateString = JSON.stringify(this.consultation.date).split("T")[0].slice(1);
    const timeString = this.consultation.time;
    this.consultation.callTime = `${dateString} ${timeString}`;

    this.noteService.saveConsultation(this.patientIdx, this.consultation).subscribe((res: any) => {
      this.loadNotes(res.patientIdx);
      this.consultation = new Consultation();
      this.canLeave = true;
    });
  }

  selectContactModal() {
    this.dialogService.genericDialog('Please select how patient was contacted on the form',
    'Please select if patient was contacted by home or mobile phone',
    ['Back'],
    [],
    );
}
  

  back() {
    this.nav.back();
  }

}

