<div class="container pb-5" *ngIf="transaction">

  <div class="invoice-tools no-print">
    <button mat-raised-button color="primary" (click)="print()"><mat-icon>print</mat-icon></button>
    <mat-form-field class="example-full-width">
      <input matInput placeholder="Email address" 
        name="clientEmail"
        [(ngModel)]="clientEmail">
    </mat-form-field>
    <button mat-raised-button color="primary" (click)="emailInvoice()"><mat-icon>alternate_email</mat-icon></button>
  </div>
  <div id="componentID">
    <h1>Customer Invoice</h1>
    <img [src]="invoiceLogo" class="d-none d-md-block client-logo" alt="" />

    <table class="invoice-header" style="width: 100%;">
      <thead>
        <tr>
          <th>To</th>
          <th>From</th>
        </tr>
      </thead>
      <tr>
        <td>
          <div class="addressPatient">
            <span *ngFor="let address of patient">
              {{address}}<br />
            </span>
            <span>DOB: {{dob}}</span>
          </div>
        </td>
        <td>
          <div class="address text-end">
            <span *ngFor="let address of clinic">
              {{address}}<br />
            </span>
          </div>
        </td>
      </tr>
      <tr>
        <td>Invoice No.</td>
        <td class="text-end">{{invoicePrefix}}{{transactionIdx}}</td>
      </tr>
      <tr *ngIf="transaction.AccountChargeReference">
        <td>Insurance Authorization Code</td>
        <td class="text-end">{{transaction.AccountChargeReference}}</td>
      </tr>
      <tr>
        <td>Invoice Date</td>
        <td class="text-end">{{transaction.TimePaid | date : 'medium'}}</td>
      </tr>
    </table>

    <table class="margin-top items" style="width: 100%;">
      <thead>
        <th>Item</th>
        <th>Price</th>
        <th>Qty</th>
        <th>Total</th>
      </thead>
      <tbody>
        <tr *ngFor="let item of items">
          <td>
            <strong>{{item.Name}}</strong><br />
            <span *ngFor="let prof of item.profDetails; let i = index">
              {{prof}} {{i === 0 ? '#' + item.staffID : ''}}
            </span><br />
            <div *ngIf='item.Name != "Excess"'><small><i>Excess paid:
                  {{excess | currency : 'GBP' : 'symbol' }}</i></small></div>
          </td>
          <td class="number">{{item.value | currency : 'GBP' : 'symbol' }}</td>
          <td class="number">{{item.qty}}</td>
          <td class="number">
            <span *ngIf="item.pervailVATRate > 0">
              Pre VAT: {{(((item.qty * item.value)/(100-item.pervailVATRate))*100) | currency : 'GBP' : 'symbol' }}<br>
              VAT ({{item.pervailVATRate}})%:
              {{(item.qty * item.value* (item.pervailVATRate / 100)) | currency : 'GBP' : 'symbol' }}<br>
              With VAT: <strong>{{(item.qty * item.value) | currency : 'GBP' : 'symbol' }}</strong>
            </span>
            <span *ngIf="item.pervailVATRate == 0 || !item.pervailVATRate">
              {{(((item.qty * item.value)/(100-item.pervailVATRate))*100) | currency : 'GBP' : 'symbol' }}
            </span>
          </td>
        </tr>
        <tr>
          <td colspan="3" class="number total">TOTAL</td>
          <td class="number total">{{saleTotal | currency : 'GBP' : 'symbol' }}</td>
        </tr>
      </tbody>
    </table>

    <table *ngIf="payments" class="margin-top" style="width: 100%;">
      <thead>
        <tr>
          <th colspan="2">PAYMENTS</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let payment of payments">
          <td>{{payment.Method}}</td>
          <td class="number">{{payment.Amount | currency : 'GBP' : 'symbol' }}</td>
        </tr>
      </tbody>
    </table>

    <table *ngIf="payments" class="margin-top" style="width: 100%;">
      <thead>
        <tr>
          <th colspan="2">INSURANCE INFO</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td><span class="font-weight-bold">Account Name:&nbsp;</span><span>{{insurance[0]}}</span><br />
            <span class="font-weight-bold">Account Number:&nbsp;</span><span>{{accountNumber}}</span><br />
            <span class="font-weight-bold">Insurance Authorization
              Code:&nbsp;</span><span>{{transaction.AccountChargeReference}}</span><br /></td>
          <td class="number"><span class="font-weight-bold">Total Invoiced to Insurance:
            </span>{{saleTotal - (excess * items.length) | currency : 'GBP' : 'symbol' }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
