import { NgModule } from '@angular/core';

import { CommonModule } from '@angular/common';

import { ValuesPipe } from './values';
import { SanitizeHtmlPipe } from './sanitize-html.pipe';
import { MaxStringLengthPipe } from './max-string-length.pipe';
import { CurrencyPencePipe } from './pence.pipe'
import { FormatFileSizePipe } from './format-file-size.pipe';

const allPipes = [
  ValuesPipe,
  SanitizeHtmlPipe,
  MaxStringLengthPipe,
  CurrencyPencePipe,
  FormatFileSizePipe
];

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: allPipes,
  providers: [

  ],
  exports: allPipes
})
export class PipesModule { }
