<div [formGroup]="form" class="form-group">


<mat-checkbox 
    type='checkbox' 
    class='checkbox' 
    value='TRUE' 
    [id]="question.key" 
    [formControlName]="question.key">

    {{question.label}}

</mat-checkbox>

<mat-error *ngIf="fieldInvalid() && showInvalidField">{{fieldInvalidError()}}</mat-error>

</div>
