import { Component, Inject } from "@angular/core";
import { GridOptions } from "ag-grid-community";
import { PatientPurgeService } from "./patient-purge.service";
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from "@angular/material/dialog";
import { MatSnackBar } from "@angular/material/snack-bar";
import { CsvService } from "../services/csv.service";

interface PurgeDialogData {
  lines: number;
  password: string;
}

@Component({
  selector: "patient-purge",
  templateUrl: "./patient-purge.component.html",
  styleUrls: ["./patient-purge.component.css"],
  providers: [PatientPurgeService],
})
export class PatientPurgeComponent {
  public gridOptions: GridOptions;
  public patients: any;
  public minAge: number = 365; //days inactive
  public rowSelection = "multiple";
  public totalRows = 0;
  public selectedRows = 0;
  constructor(
    private purgePatient: PatientPurgeService,
    public dialog: MatDialog,
    private snackBar: MatSnackBar,
    private csvService: CsvService
  ) {
    this.gridOptions = <GridOptions>{
      rowData: this.patients,
      columnDefs: this.columnDefs,
      defaultColDef: {
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains", "notContains"],
          debounceMs: 0,
          caseSensitive: false,
          suppressAndOrCondition: true,
        },
      },
      onGridReady: () => {
        this.gridOptions.api.sizeColumnsToFit();
      },
      rowHeight: 48, // recommended row height for material design data grids,
    };
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(DialogConfirmPurge, {
      width: "450px",
      data: { rows: this.gridOptions.api.getSelectedNodes() },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log("GO");
      const nodes = this.gridOptions.api.getSelectedRows();
      this.purgePatient.beginThePurge(result, nodes).subscribe((res: any) => {
        if (res.error == "") {
          this.snackBar.open("Purge completed", "ok", {
            duration: 2000,
          });

          const csvData = res.completed;
          this.csvService.downloadCSVData(csvData, "purged.csv");
          // let a = document.createElement("a");
          // a.setAttribute('style', 'display:none;');
          // document.body.appendChild(a);
          // let blob = new Blob([csvData], { type: 'text/csv' });
          // let url = window.URL.createObjectURL(blob);
          // a.href = url;
          // a.download = 'purged.csv';
          // a.click();
          this.updatePatientData();
        } else {
          this.snackBar.open(`Purge failed: ${res.error}`, "ok", {
            duration: 2000,
          });
        }
      });
    });
  }

  public columnDefs = [
    {
      headerName: "#",
      width: 30,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      checkboxSelection: true,
      sortable: true,
      rowSelection: "multiple",
    },
    { headerName: "First Name", field: "firstName", sortable: true },
    { headerName: "Last Name", field: "lastName", sortable: true },
    { headerName: "Post Code", field: "postCode", sortable: true },
    {
      headerName: "Days since last app",
      field: "daysSinceLastApp",
      sortable: true,
    },
  ];

  ngOnInit() {
    this.updatePatientData();
  }

  updatePatientData() {
    this.purgePatient.getPatients(this.minAge).subscribe((res: any) => {
      this.patients = res.patients;
      this.gridOptions.api.setRowData(this.patients);
      this.totalRows = this.patients.length;
    });
  }

  updateSelection(event) {
    this.selectedRows = this.gridOptions.api.getSelectedNodes().length;
  }

  exportReport() {
    this.gridOptions.api.exportDataAsExcel();
  }
}

@Component({
  selector: "dialog-confirm-purge",
  templateUrl: "dialog-confirm-purge.html",
})
export class DialogConfirmPurge {
  public password: string;
  constructor(
    public dialogRef: MatDialogRef<DialogConfirmPurge>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }
}
