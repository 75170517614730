<div class="row margin-collapse">
  <div class="col-sm-10">
      <mat-card><mat-card-content>  
        <ng-container *ngIf="reportsData">
          <div id="print-html" class="p-3 font-monospace" *ngIf="!errorMessage">
            <ul *ngFor="let report of reportsData">
              <ng-container *ngFor="let data of report">
                <h3 class="font-weight-bold pt-3">
                  <span *ngIf="data.reportType">{{ data.reportType }} Report </span>
                  <span *ngIf="data.zNumber">Number:&nbsp;{{ data.zNumber }}</span>
                </h3>
                <table *ngIf="data.reportType">
                  <tr>
                    <td style="width: 160px;">Report Generated</td>
                    <td style="width: 160px;">{{ data.dateGenerated }}</td>
                  </tr>
                  <tr>
                    <td style="width: 160px;">For user</td>
                    <td style="width: 160px;" class="font-weight-bold">{{ data.userName }}</td>
                  </tr>
                </table>
                <h3 class="font-weight-bold">{{ data.title }}</h3>
                <table>
                  <ng-container *ngIf="data.hasOwnProperty('headers')">
                    <tr >
                      <th *ngFor="let header of data.headers" class="text-end-n1">{{ header }}</th>
                    </tr>
                  </ng-container>
                  <tr *ngFor="let item of data.items" [ngClass]="{'total-border': item.name == 'TOTAL'}">
                    <td><span>{{item.name}}</span></td>
                    <td *ngFor="let value of item.values" class="text-end">
                      <span>{{ value | currency:'GBP' }}</span>
                    </td>
                  </tr>
                </table>
                <span *ngIf="data.resetMessage">{{ data.resetMessage }}</span>
              </ng-container>
            </ul>
          </div>

          <ng-container *ngIf="errorMessage">
            <h3 class="p-3">{{ errorMessage }}</h3>
          </ng-container>
        </ng-container>
      </mat-card-content></mat-card>
  </div>
  <div class="col-sm-2 no-print mobile-margin">
    <div class="fix-top">
      <div class="mb-3">
              <div class="desktop-buttons">
                <button id="print-button" mat-flat-button color="accent" class="mobile-button desktop-button" (click)="print()">
                  <mat-icon>print</mat-icon>&nbsp;Print</button>
              </div>
              <div class="desktop-buttons">
                <button id="back-button" mat-flat-button color="primary" class="mobile-button desktop-button" (click)="continue()" >
                 Continue</button>
              </div>
      </div>
    </div>
  </div>
</div>

