<form autocomplete="off" #formEle="ngForm">

    <h5 class="mt-4">This questionnaire is about your joint, back, neck, bone and muscle symptoms such as aches, pains and/or
        stiffness.</h5>
    <h5>Please focus on the particular health problem(s) for which you sought treatment from this service.</h5>
    <h5>For each question select one box to indicate which statement best describes you over the last 2 weeks.</h5>

    <div *ngFor="let question of MskhqQuestions" class="row mt-4">
        <mat-label class="col-md-5 col-lg-6">{{question.label}}</mat-label>
        <div class="col-md-7 mt-2 col-lg-6">
            <mat-button-toggle-group [(ngModel)]="form[question.field]" [name]="question.field" class="w-100 my-2">
                <mat-button-toggle [value]="''+i+1" *ngFor="let option of question.options; index as i" class="w-100">{{option}}</mat-button-toggle>
            </mat-button-toggle-group>
        </div>
    </div>

    <div class="row mt-4">
        <mat-label class="col-md-12"><strong>Physical activity levels</strong> </mat-label>
        <mat-label class="col-md-12">In the past week, on how many days have you done a total of 30 minutes or more of
            physical activity, which was enough to raise your heart rate? This may include sport, exercise and brisk
            walking or cycling for recreation or to get to and from places, but should not include housework
            or physical activity that is part of your job.</mat-label>

        <div class="col-md-12 my-2">
            <mat-button-toggle-group [(ngModel)]="form.physical_activity_levels" name="physical_activity_levels" class="w-100 my-2">
                <mat-button-toggle value="1" class="w-100">None</mat-button-toggle>
                <mat-button-toggle value="2" class="w-100">1 day</mat-button-toggle>
                <mat-button-toggle value="3" class="w-100">2 days</mat-button-toggle>
                <mat-button-toggle value="4" class="w-100">3 days</mat-button-toggle>
                <mat-button-toggle value="5" class="w-100">4 days</mat-button-toggle>
                <mat-button-toggle value="6" class="w-100">5 days</mat-button-toggle>
                <mat-button-toggle value="7" class="w-100">6 days</mat-button-toggle>
                <mat-button-toggle value="8" class="w-100">7 days</mat-button-toggle>
            </mat-button-toggle-group>
        </div>
    </div>

</form>