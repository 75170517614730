import { Component, Output, EventEmitter } from "@angular/core";
import { Router } from "@angular/router";
import { NavBarService } from "../services/menu/nav-bar.service";
//import { MenuController } from "@ionic/angular";
import { MenuItem } from '../models/menu'
import * as fromRoot from '../store/reducers';
import * as fromMenu from '../store/actions/menu';

import { Store } from "@ngrx/store";
import { Observable, from } from "rxjs";
import { SiteSettingsService } from "../services/site-settings.service";
import { AuthService } from "../services/auth.service";
import { distinctUntilChanged, map, startWith, take } from "rxjs/operators";
import { UntypedFormControl } from "@angular/forms";

@Component({
    selector: "app-nav-bar",
    templateUrl: "./nav-bar.component.html",
    styleUrls: ["./nav-bar.component.scss"]
})
export class NavBarComponent {
    @Output() triggerSideMenu = new EventEmitter();
    private menuItems$;
    public autoSearchValue: string;
    public auto: any[] = [];
    public clientLogo;
    public newItems;
    public flatItems;
    public filteredFlatItems;
    public menuQuickFindInput = new UntypedFormControl();
    private menuLoaded;
    showFiller = false;

    constructor(
        private nav: NavBarService,
        private router: Router,
        //   private menu: MenuController,
        private store: Store<fromRoot.State>,
        private settings: SiteSettingsService,
        private auth: AuthService
    ) {
        this.triggerSideMenu = new EventEmitter();
    }

    ngOnInit() {

        this.menuItems$ = this.store.select(fromRoot.getMenu).pipe(distinctUntilChanged()).subscribe((items) => {
            console.log("[menu] items upates", items);
            //if (!items || items.length==0){
            //    return;
            //}
            this.newItems = this.nav.getManipulatedNavBarItems(items);
            this.flatItems = this.nav.flattenMenuItem(items);
            this.menuLoaded = true;
        })

        this.store.select(fromRoot.getSiteSettings).subscribe((res: any) => {
            this.clientLogo = res.topLogo;
        })


        this.filteredFlatItems = this.menuQuickFindInput.valueChanges
            .pipe(
                startWith(''),
                map(value => this._filter(value))
            );
    }

   

    ngOnDestroy() {
        this.menuItems$.unsubscribe();
    }

    private _filter(value: string): string[] {
        if (!value || typeof value != 'string' ) {
            return;
        }
        console.log(value);
        const filterValue = value.toLowerCase();
        if (!this.flatItems){
            return [];
        }
        return this.flatItems.filter(option => option.title.toLowerCase().includes(filterValue));
    }


    openFirst() {
        //this.menu.enable(true, "first");
        //this.menu.open("first");
    }

    searchNavigateTo(where) {
        console.log(where.option.value);
        const goTo = where.option.value;
        this.autoSearchValue = "";
        this.router.navigate([goTo]);
    }

    isSubMenu(menuItems): boolean {
        console.log(menuItems);
        console.log(Array.isArray(menuItems));
        if (Array.isArray(menuItems)) return true;
        return false;
    }

    toggleSideBar() {
        this.triggerSideMenu.emit("1");
    }

    quickNavigate(event) {
        console.log("quick", event.option.value.link)
        this.router.navigateByUrl(event.option.value.link);
        this.menuQuickFindInput.setValue("");
    }

}
