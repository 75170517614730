import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from '../../services/data.service';
import { map } from 'rxjs/operators';
import { format } from 'date-fns';
import { Router } from '@angular/router';

@Injectable()
export class PandLService {
  constructor( private http: DataService, private router: Router ) { }

  public getIncome(startDate: string, endDate: string, clinics: string): Observable<any> {
    return this.http.post("/finance/income", { startDate, endDate, clinics });
  }

  public decimal(value: string, decimal: number): any {
    const num = parseFloat(value).toFixed(decimal);
    const splitnum = num.split(".");

    return splitnum[1] ? num : num + ".00";
  }

  public spread(items: any, headings: any): any {
    const spreaded: any[] = new Array(13).fill("0.00");
    let total: any = 0;
    
    items.forEach((item: any) => {
        const foundIndex = headings.findIndex((heading: any) => ( heading.year === +item.theyear && heading.month === +item.themonth ));

        const value = this.decimal(item.total, 2);
        spreaded[foundIndex] = value;
        total += +value;
    })

    spreaded[13] = this.decimal(total, 2);

    return spreaded;
  } 

  public generateHeadings(startDate: string, months: any): any {
    const startYear = +startDate.split("-")[0];
    const startMonth = +startDate.split("-")[1];

    const headingsSpans = [];
    const headings = [];

    let currentIndex = 0;
    let currentYear = startYear;
    let currentMonth = startMonth;
    let currentSpan = 1;

    while ( currentIndex < 13 ) {
      headings.push({
        year: currentYear,
        month: currentMonth,
        monthName: months[currentMonth - 1]
      })

      if ( currentMonth === 12 ) {
        headingsSpans.push({
          year: currentYear,
          span: currentSpan
        })

        currentMonth = 1;
        currentYear += 1;
        currentSpan = 1;
      } else {
        currentMonth++;
        currentSpan++;
      }

      currentIndex++;
    }

    if ( headingsSpans.length < 2 ) {
      headingsSpans.push({
        year: currentYear,
        span: currentSpan
      })
    }

    headings.push({
      year: null,
      month: null,
      monthName: "Total"
    })

    return { headings, headingsSpans };
  }

  public getTotalRevenue(itemSales: any, moneyInFromSales: any, vouchers: any, giftVouchers: any, commission: any): any {
    const totalRevenue = [];

    for ( let i = 0; i < 14; i++ ) {
      let total: number = 0;
      itemSales.forEach((itemSale: any) => {
          total += +itemSale.items[i];
      })

      moneyInFromSales.forEach((moneyInFromSale: any) => {
        total += +moneyInFromSale.items[i];
      })

      if ( vouchers.length ) total += +vouchers[i];
      if ( giftVouchers.length ) total += +giftVouchers[i];
      if ( commission.length ) total += +commission[i];

      totalRevenue.push(this.decimal(total as any, 2));
    }

    return totalRevenue;
  }

  public getTotalExpenditure(subcats: any): any {
    const totalExpenditure = [];

    for ( let i = 0; i < 14; i++ ) {
      let total: number = 0;
      subcats.forEach((subcat: any) => {
          total += +subcat.items[i];
      })

      totalExpenditure.push(this.decimal(total as any, 2));
    }

    return totalExpenditure;
  }

  public getTotalGOP(totalRevenue: any, totalExpenditure: any): any {
    const totalGOP = [];
    const totalGOPPercentage = [];

    for ( let i = 0; i < 14; i++ ) {
        const gop: number = +totalRevenue[i] - +totalExpenditure[i];
        const absRevenue = +totalRevenue[i] < 0 ? (-1) * +totalRevenue[i] : +totalRevenue[i];
        const gopPercentage = this.decimal((gop / absRevenue * 100) as any, 2);

        totalGOP.push(this.decimal(gop as any, 2));
        totalGOPPercentage.push((totalRevenue[i] as any) === "0.00" ? "0.00" : +gopPercentage);
    }

    return [totalGOP, totalGOPPercentage];
  }

  public navigateToReport(headings: any, currentStartDate: any, currentEndDate: any, clinics: any, reportName: string, array: any, arrayIndex: number, typename: string = null): void {
    const PandLSubCatIdx = array.idx || null;
    const type = array.heading ? array.heading.toLowerCase() : null;
    const currentYear = headings[arrayIndex].year;
    const currentMonth = headings[arrayIndex].month - 1;
    const startDate = format(new Date(currentYear, currentMonth), "yyyy-M-dd");
    const endDate = format(new Date(currentYear, currentMonth + 1, 0), "yyyy-M-dd");

    const queryParams: any = {};

    if ( PandLSubCatIdx ) queryParams.PandLSubCatIdx = PandLSubCatIdx;

    if ( !PandLSubCatIdx && ( type || typename ) ) {
      queryParams.type = type === "inter account xfer" ? 'transfers' : type;

      if ( typename ) queryParams.type = typename;
    }

    if ( arrayIndex === 13 ) {
      queryParams.startDate = currentStartDate;
      queryParams.endDate = currentEndDate;
    } else {
      queryParams.startDate = startDate;
      queryParams.endDate = endDate;
    }

    queryParams.clinics = clinics;

    if ( type === "inter account xfer" ) reportName = "DrillDownPaymentMethod";

    this.router.navigate([`/report/${reportName}`], { queryParams });
  }

  public populateWithSubCategories(fromArray: any, toArray: any, subcats: any, headings: any): any {
    toArray[0] = {idx: 0, heading: "Misc", items: []};
    fromArray.forEach((item: any) => {
      const foundIndex = toArray.findIndex((subitem: any) => subitem.idx === item.thetype);

      if ( foundIndex !== -1 ) {
        toArray[foundIndex].items.push(item);
      } else {
        if ( item.thetype !== 0 ) {
          const itemFound = subcats.find((subitem: any) => subitem.idx === item.thetype);

          if ( itemFound ) toArray.push({ idx: item.thetype, heading: itemFound.name, items: [ item ] });
        }
      }
    })
    return toArray.map((to: any) => {
      return { ...to, items: this.spread(to.items, headings) }; 
    });
  }

  public sortItem(header: string, heading: string, data: any, headings: any): any {
    let total: number = 0;
    let items: any[] = new Array(13).fill("0.00");
    data.forEach((item: any) => {
      if ( item.thetype === header ) {
        const foundIndex = headings.findIndex((heading: any) => ( heading.year === +item.theyear && heading.month === +item.themonth ));

        const value = this.decimal(item.total, 2);
        items[foundIndex] = value;
        total += +value;
      }
    });

    items.push(this.decimal(total as any, 2));

    return { heading, items };
  }

  /** THIS METHOD IS POSSIBLY OUTDATED AND NOT REQUIRED ANY LONGER */
  getIncomeDetail(startDate: string, interval: string, type: string, subcat: string = null) {
    const payload = { startDate: startDate, interval: interval, type: type };
    return this.http.post('/finance/income.php', payload).pipe(map((res: any) => res.income));
  }
}

