import { Component } from '@angular/core';

import { Store } from '@ngrx/store';

import * as fromRoot from '../store/reducers';
import { MatSnackBar } from '@angular/material/snack-bar';
import * as notficationAction from '../store/actions/notification';
import { NotificationMessage } from '../models/notification-message';
import { NotificationService } from 'app/services/notification.service';

@Component({
    selector: 'snack-bar',
    templateUrl: './snack-bar.component.html',
    styleUrls: ['./snack-bar.component.css']
})
export class SnackBarComponent {

    subscription$: any;

    constructor(
        private store: Store<fromRoot.State>, 
        private notification: NotificationService,
        private snackBar: MatSnackBar
        ) { }
    lastMessage:number = 0;

    ngOnInit() {
        this.subscription$ = this.store.select(fromRoot.getNotificationMessages).subscribe((res: NotificationMessage[]) => {
            if (res && res[0]) {
                if (res[0].timestamp == this.lastMessage) return;
                this.show(res[0]);
            }
        });
        this.notification.startListener();
/* Test mode */
/*
        setInterval(() => {
            this.show({message: "I am a test I am a test I am a test I am a test I am a test I am a test I am a test I am a test I am a test ", timestamp: 0, idx: 0, systemNotification: false});
        }, 10000);
*/

    }

    ngOnDestroy() {
        this.subscription$.unsubscribe();
    }

    private show(msg: NotificationMessage): void {
        this.notification.native(msg);

        console.log("[message] [snackBar] ", msg);

        const duration = msg.message.length > 100 ? 10000 : 5000;
        this.lastMessage = msg.timestamp;
        let snackBarRef = this.snackBar.open(msg.message, "Dismiss", {
            duration,
            horizontalPosition: "end",
            verticalPosition: "top",
            panelClass: ['success-snackbar']
        });

        snackBarRef.afterDismissed().subscribe(() => {
            this.store.dispatch(new notficationAction.ShownAction());
            this.lastMessage = 0;
        });

    }

}
