import * as appointmentsAction from '../actions/appointments';
import { Action } from '@ngrx/store';
import {State, initialState} from '../../models/appointments'
import { ActionWithPayload } from '.';

export {State, initialState}



export function reducer(state = initialState, action: ActionWithPayload): State {
switch(action.type){

    case appointmentsAction.ActionTypes.Loaded :{
      //  console.log("[apps] Action recieved appoitnement", action.payload)
        if (JSON.stringify(action.payload) == JSON.stringify(state.appointments)){
        //  console.log ("[apps] NO CHANGE!");
          return state; // loaded is identaial
        }
        return Object.assign({}, state, { appointments: [...action.payload],
      });
    }

    case appointmentsAction.ActionTypes.SaveStartTime: {
      const updatedAppointments = state.appointments.map((appointment: any) => {
        if ( appointment.idx === action.payload.idx ) {
          return {
            ...appointment,
            startTime: action.payload.newStart,
            duration: action.payload.duration,
            roomIdx: action.payload.newRoom ? action.payload.newRoom.id : appointment.roomIdx,
            roomname: action.payload.newRoom ? action.payload.newRoom.title : appointment.roomname
          }
        }

        return appointment;
      });

      return Object.assign({}, state, { appointments: updatedAppointments });
    }

    default: {
        return state;
      }
}
}
export const getAppointments = (state: State) => state.appointments;
