<app-nav-bar
  [hidden]="url === '/login' || url === '/logout'"
  (triggerSideMenu)="drawer.toggle()"
  class="no-print" 
  [ngClass]="{'dark-mode': darkMode}"
></app-nav-bar>

<mat-drawer-container class="example-container" [ngClass]="{'dark-mode': darkMode}" [attr.data-bs-theme]="bsTheme">

  <mat-drawer #drawer class="example-sidenav no-print" mode="over" style="width:16em;">
    <app-side-menu (closeMenu)="drawer.close()"></app-side-menu>
  </mat-drawer>
  <div main class="example-container main-display">
    <router-outlet></router-outlet>
  </div>
</mat-drawer-container>

<loader-overlay class="no-print"></loader-overlay>
<snack-bar class="no-print"></snack-bar>
