import { Component, ElementRef, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Store } from '@ngrx/store';
import { FutureAppointment } from 'app/models/appointments';
import { AppointmentsService } from 'app/services/diary/appointments.service';
import { NotificationService } from 'app/services/notification.service';
import { PatientChooserService } from 'app/services/patient.service';
import { environment } from 'environments/environment';
import { take } from 'rxjs/operators';
import * as fromRoot from '../../store/reducers';

export class PrintCardDialog {
  public appointments: Array<FutureAppointment>;
  public patientEmail: string;
  public clinicDetails: any;

  constructor(appointments: Array<FutureAppointment>, patientEmail: string, clinicDetails: any) {
    this.appointments = appointments;
    this.patientEmail = patientEmail;
    this.clinicDetails = clinicDetails;
  }
}

@Component({
  selector: 'app-print-card-dialog',
  templateUrl: './print-card-dialog.component.html',
  styleUrls: ['./print-card-dialog.component.scss']
})
export class PrintCardDialogComponent implements OnInit {
  public clientLogo: string;
  public printingStyles: string;
  public printingSettings: string;
  public patientEmail: string;
  private patientIdx: number;
  public patientName: string;
  public emailSendingError: boolean = false;
  public siteName: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PrintCardDialog, 
    public dialogRef: MatDialogRef<PrintCardDialogComponent>,
    private store: Store<fromRoot.State>,
    private elementRef: ElementRef,
    private appointmentsService: AppointmentsService,
    private notification: NotificationService,
    private patient: PatientChooserService ) { 
      this.store.select(fromRoot.getSiteSettings).pipe(take(1)).subscribe((siteSettings: any) => {
        this.clientLogo = siteSettings.topLogo;
        this.siteName = siteSettings.siteName;
      });
      this.patientEmail = this.data.patientEmail;

      // If no appointments set patientIdx to 0
      if ( data.appointments[0] ) {
        this.patientIdx = data.appointments[0].patientIdx;
      } else {
        this.patientIdx = 0
      }

      this.patient.getPatientDetails(this.patientIdx).subscribe(res => {
        this.patientName = res.name;
      });

      this.printingStyles = `
      <head>
        <style>
          * { font-family: Arial }
          .card-container { background-color: white; border: 1px solid rgba(0,0,0,.4); width: 500px; }
          .image-container { background-color: #066d3e; }
          .image-container img { padding: .4rem; }
          .inner-text { padding: .75rem .5rem; }
          .clinic-title { color: rgba(255,255,255,.7); }
          ul { list-style: none; color: rgba(0,0,0,.8); padding: 0 .5rem; }
          .bottom-container { background-color: #066d3e; color: white; font-size: .8rem; padding: 0 .5rem; text-align: center; width: 486px;}
        </style>
      </head>`;

      this.printingSettings = 'left = 0, top = 0, width = 900, height = 900, toolbar = 0, scrollbars = 0, status = 0';
  }

  ngOnInit(): void {}


  sendEmail(): void {
    const printCard = this.printingStyles + this.elementRef.nativeElement.getElementsByClassName("print-card")[0].innerHTML;

    if ( !this.patientEmail ) {
      this.emailSendingError = true;
      setTimeout(() => this.emailSendingError = false, 5000);
      return;
    }

    const payload = {
      patientIdx: this.patientIdx,
      email: this.patientEmail,
      message: { body: printCard, subject: "Your Future Appointments"}
    };
        
    this.appointmentsService.sendAppointmentEmail(payload).subscribe(( emailSent: any ) => {
      this.notification.send(`You have successfully emailed this card to ${this.patientEmail}.`);
    })
    this.dialogRef.close(true);
  }

  print(): void {
    const printingContent = this.elementRef.nativeElement.getElementsByClassName("print-card")[0];
    const windowPrint = window.open('', '', this.printingSettings);
    windowPrint.document.write(this.printingStyles);
    windowPrint.document.write(printingContent.innerHTML);
    windowPrint.document.close();
    windowPrint.focus();
    windowPrint.print();
    windowPrint.close();
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }

 
  public getLink(appointment){
    const appToEncode = {
      startTime: appointment.startTime,
      duration: appointment.duration,
      staffName: appointment.staffName,
      treatmentName: appointment.treatmentName,
    }
    const encodedApp = encodeURIComponent(JSON.stringify(appToEncode));
    return `${environment.onlineBooking}/clinic/${this.siteName}/ical?app=${encodedApp}`;
  }

}