import { Injectable } from '@angular/core';
import { Actions } from '@ngrx/effects';
import { AppointmentsService } from '../../services/diary/appointments.service';
import { Store } from '@ngrx/store';
import * as fromRoot from "../reducers";

@Injectable()
export class AppointmentEffects {

  constructor(
    private actions$: Actions,
    private appointmentService: AppointmentsService,
    private store: Store<fromRoot.State>
  ) {

  }
}
