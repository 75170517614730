import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { PatientChooserService } from 'app/services/patient.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-patient-credits',
  templateUrl: './patient-credits.component.html',
  styleUrls: ['./patient-credits.component.scss']
})
export class PatientCreditsComponent implements OnInit, OnDestroy {
  patientIdx: number
  patientName: string
  creditsDetails: any
  subscriptions: Subscription = new Subscription()

  constructor(private route: ActivatedRoute,
    private patientService: PatientChooserService) { }

  ngOnInit() {
    this.route.paramMap.subscribe((params) => {
      this.patientIdx = parseInt(params.get("patientIdx"));
      this.getPatientDetails(this.patientIdx)
    });
  }

  getPatientDetails(idx: number) {
    this.subscriptions.add(this.patientService.getPatientAccountInfo(idx).subscribe((res) => {
      this.creditsDetails = res.creditsDetails
    }))
    this.subscriptions.add(this.patientService.getPatientDetails(idx).subscribe((res) => {
      this.patientName = res.name
    }))
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe()
  }

}
