<form autocomplete="off" #formEle="ngForm">
    <mat-checkbox [(ngModel)]="form.consent" name="consent">
        <div class="ws-normal">
            I consent to being examined. I am aware that I have the right to withdraw consent at any time.<br />
            <span class="text-muted">In the case of a child / young adult under the age of 18, or a vulnerable adult
                with
                diminished intellectual capacity, the parent, guardian or carer must give consent.
            </span>
        </div>
    </mat-checkbox>
</form>