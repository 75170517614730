import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ValueAccessorBase } from 'app/custom-form-controls/value-accessor-base';

@Component({
  selector: 'app-reflexes-field',
  templateUrl: './reflexes-field.component.html',
  styleUrls: ['./reflexes-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ReflexesFieldComponent,
      multi: true,
    }],
})
export class ReflexesFieldComponent extends ValueAccessorBase<string> {
  @Input() label = "";
}
