<div class="loading-overlay" #spinnerElement>
  <!-- md-spinner is short for <md-progress-circle mode="indeterminate"></md-progress-circle> -->
  <div class="loader"></div>
  
    <div class="loading-wrapper flex-container" #spinnerElement>
    <div class='row'>
      <div class="spinner-border text-success" style="height: 10vh; width: 10vh" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
  </div>
  </div>

