import { Injectable } from '@angular/core';
import { DataService } from 'app/services/data.service';
import { Observable, map } from 'rxjs';
import { TreatmentCardTemplate, TreatmentCard, TreatmentCardGroups } from 'app/models/treatment-cards';

@Injectable({
  providedIn: 'root'
})
export class TreatmentCardService {

  constructor(private http: DataService) { }

  getTreatmentCardTemplates(): Observable<TreatmentCardTemplate[]> {
    return this.http.get<any>(`/templates/treatmentcards`);
  }

  getTreatmentCards(notesIdx: number): Observable<any> {
    return this.http.get<any>(`/notes/treatmentcard/${notesIdx}`).pipe(map(tc=>{
      tc.cards = this.groupTreatmentCards(tc.cards);
      return tc;
    }));
  }

  saveTreatmentCard(notesIdx: number, payload: TreatmentCardGroups): Observable<any> {
    return this.http.post<any>(`/notes/treatmentcard/${notesIdx}`, payload);
  }

  private groupTreatmentCards(tc: TreatmentCard[]): TreatmentCardGroups[]{
    const tcGroups: TreatmentCardGroups[] = [];
    for (let card of tc){
      const found = tcGroups.find(f=>(f.rxNo == card.rxNo));
      if (found){
        found.cards.push(card);
      }else{
        tcGroups.push({rxNo: card.rxNo, cards: [card], date: card.date})
      }
    }
    return tcGroups;
  }
}
