import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';


import { DataService } from '../services/data.service';
import { IGetRowsParams } from 'ag-grid-community';
import { map } from 'rxjs/operators';
import { ReportDetails} from '../models/reports';
export { ReportDetails };

@Injectable()
export class ReportsService {
    private reportIdx;

    constructor(private http: DataService) { }

    setReportIdx(idx: number) {
        this.reportIdx = idx;
    }

    getPatientsList(): Observable<any> {
        return this.http.get<any>('/reports/patients').pipe(map(res => res.data));
    }

    getData(idx: number, params: IGetRowsParams, query: string = ""): Observable<any> {
        console.log("[report]", query, params);
        return this.http.post<any>(`/report/${idx}/data${query}`, { params: params });
    }

    loadReport(id: string): Observable<ReportDetails> {
        return this.http.get<ReportDetails>(`/report/${id}`);
    }

}

