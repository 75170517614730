import { EventEmitter, Injectable, Output } from '@angular/core';
import { take } from 'rxjs/operators';
import { DataService } from "../services/data.service";
import { GiftCard } from "../models/giftcard"
import { Observable } from 'rxjs';
import { GiftVoucherPayment } from 'app/models/payment-methods';

@Injectable()
export class GiftCardService {

    @Output() giftcardValidated = new EventEmitter<GiftVoucherPayment>();

    private url: string = '' // Add giftcard endpoint
    private possibleCharacters = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z'];
    private validityLength: number = 2 // validity length in years 
    public errorMessage = "";

   
    generateGiftCard() {
        let code = "";
        for (let i = 0; i < 5; i++){
            const randomIndex = Math.floor(Math.random() * 26);
            code += this.possibleCharacters[randomIndex];
        }
        return code
    }

    newGiftCard(value: number, clientIdx: number){
        const code = this.generateGiftCard()
        const issueDate = new Date()
        const year = issueDate.getFullYear()
        const month = issueDate.getMonth()
        const day = issueDate.getDate()
        const expiryDate = new Date(year + this.validityLength, month, day)

        const giftCard = new GiftCard(code, clientIdx, value, issueDate, expiryDate) 
        return giftCard
    }

    validate(code: string) {
        //FnANOmGS
        console.log("[GC] validating")
        return this.checkCodeAgainstDatabase(code)
      }

    checkCodeAgainstDatabase(code: string): Observable<any> {
    return this.service.get<any>(`/till/giftvoucher/${code}`);
    }

    postGiftCard(giftcard: GiftCard){
        return this.service.post(this.url, giftcard)
    }

    getGiftCardBycode(code:string){
        this.service.get(`${this.url}/{code}`).pipe(take(1))
    }

    updateGiftCard(giftcard: GiftCard){
        this.service.put(`${this.url}/{code}`, giftcard)
    }

    constructor(private service: DataService ) {}

   

}
