import { HttpClient } from '@angular/common/http';
import { AfterViewInit, Component, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { SaleItem } from 'app/models/saleItems';
import { StaffMember } from 'app/models/staff';
import { AccountsService } from 'app/services/accounts.service';
import { ClinicService } from 'app/services/clinic.service';
import { DialogService } from 'app/services/dialog/dialog.service';
import { NavigationService } from 'app/services/navigation.service';
import { NotificationService } from 'app/services/notification.service';
import { SaleItemsService } from 'app/services/sale-items.service';
import { StaffService } from 'app/services/staff.service';
import { format } from 'date-fns';
import { Observable, Subscription } from 'rxjs';
import { map, startWith, take } from 'rxjs/operators';
import { InitialPurchaseOrderItem, NewPurchaseOrder, PurchaseOrder, PurchaseOrderItem } from "../../models/purchaseOrder";
import { CommissionPODialogComponent } from '../../dialogs/commission-po-dialog/commission-po-dialog.component';
import { PurchaseOrdersService } from './purchase-orders.service';
import * as reportsFields from 'app/models/report-fields';
import { ReportsConfigService } from 'app/reports/reports-config.service';
import { LoadingService } from 'app/services/loading.service';
import { FormControl } from '@angular/forms';
export interface VATRates {
  display: string;
  value: number;
}

@Component({
  selector: 'app-purchase-orders',
  templateUrl: './purchase-orders.component.html',
  styleUrls: ['./purchase-orders.component.scss']
})

export class PurchaseOrdersComponent implements OnInit, AfterViewInit {
  public accounts: any[];
  public account: any;
  public accountDisplay: string;
  public filteredAccounts: Observable<any[]>;
  //public items: any[] = [];

 // public itemControls: UntypedFormControl[] = [];
  public filteredSaleProductsList: Observable<SaleItem[]>;
  public accountsControl: UntypedFormControl;
  public saleProducts: SaleItem[] = [];
  public saleItems$: Subscription;
  public totalExVat: number;
  public totalVat: number;
  public total: number;
  public vatRates = [{ display: '0%', value: 0 }, { display: '5%', value: 0.05 }, { display: '20%', value: 0.2 }];
  public itemVATRates: VATRates[] = this.vatRates;
  public newItem: PurchaseOrderItem = { ...InitialPurchaseOrderItem };
  public date: Date = new Date();
  public clinics: any[];
  public clinicParamIdx: number;
  public clinic: any;
  public poDetails: PurchaseOrder = NewPurchaseOrder;
  public editingPage = false;
  public commissionPO: any = null;
  public selectedStaff: StaffMember = null;
  public subCategories: any[] = [];
  public autoCompleteTextInput = new FormControl("");

  constructor(
    private accountsService: AccountsService,
    private clinicService: ClinicService,
    private poService: PurchaseOrdersService,
    private router: Router,
    private navigationService: NavigationService,
    private saleItemsService: SaleItemsService,
    private route: ActivatedRoute,
    private notification: NotificationService,
    private staffService: StaffService,
    private dialogService: DialogService,
    private loadingService: LoadingService ) {

    this.accountsControl = new UntypedFormControl();


    this.route.params.subscribe((params: Params) => {
      console.log("[po]", params);

      if (params["model"]) {
        let useFields;
        if (params["reportName"] === reportsFields.CommissionUnpaidCash.REPORT_NAME) {
          useFields = reportsFields.CommissionUnpaidCash;
        } else {
          useFields = reportsFields.CommissionUnpaidCredits;
        }
        const filters = JSON.parse(params["model"]);
        console.log("[po] filters", params, filters, useFields);

        const staffIdx = filters[useFields.staffIdx] ? filters[useFields.staffIdx].filter : 0;
        const clinicIdx = filters[useFields.clinicIdx] ? filters[useFields.clinicIdx].filter : 0;
        const dateFilter = filters[useFields.date] ? filters[useFields.date] : null;

        if (!staffIdx) {
          this.notification.send("You need to filter on staff memeber to create a Credits PO");
          this.back();
          return;
        }

        this.staffService.getStaff().subscribe((staff: StaffMember[]) => {
          this.selectedStaff = staff.find((member: StaffMember) => member.idx === +staffIdx);
          console.log("[po] for staff memeber", staff, staffIdx, this.selectedStaff);
          this.getCommissionPO(params["reportName"], this.selectedStaff, clinicIdx, dateFilter);
        });
      }

      if (params["poIdx"]) {
        this.editingPage = true;
        this.getCurrentPO(params["poIdx"]);
      } else {
        this.getEmptyPO();
      }
    });
  }


  ngOnInit(): void {
    this.accountsService.getAccounts().subscribe((res: any) => {
      const accounts = res.data;
      this.accounts = accounts.map((item: any) => { return { id: item.rowId, name: item.field_5 } });
      this.initiateAutocomplete();
    });

    this.saleItems$ = this.saleItemsService.getStockListFromServer().subscribe((stock: any) => {
      this.saleProducts = stock.filter((item: any) => item.type !== "credits" && item.name !== "Gift Voucher");
      this.initiateAutocomplete();
    });

    this.poService.getPandLCats().subscribe((subcats: any) => {
      console.log(subcats);
      this.subCategories = subcats;
    });

    this.filteredSaleProductsList = this.autoCompleteTextInput.valueChanges.pipe(
      map((value: string) => {
        if (typeof value != "string") { value = ""; }
        const filtered = this._filter(value);
        return filtered;
      }));
  }

  ngAfterViewInit() {
    this.initiateAutocomplete();
  }

  private _filter(value: string): SaleItem[] {
    console.log("[choose] why bad", value);

    if (!value || value == "") {
      return this.saleProducts;
    }

    const filterValue = value.toLowerCase() || "";
    return this.saleProducts.filter(option =>
      option.name.toLowerCase().includes(filterValue));
  }



  private getClinicDetails(clinicIdx: number = 0, only: boolean = false): void {

    this.clinicService.getClinics().subscribe((clinics: any) => {
      this.clinics = clinics;
      console.log("[po] getClinicDetails", clinicIdx, only, this.clinics);
      /* this doesn't work as it is imutable
            if ( only ) {
              this.clinics = this.clinics.filter((clinic: any) => clinic.idx === clinicIdx);
            }
      */

      if (clinicIdx) {
        this.clinic = this.clinics.find((clinic: any) => clinic.idx == clinicIdx);
        this.poDetails.clinicIdx = this.clinic.idx;
      } else {
        this.clinicService.getSelectedClinic().subscribe(activeClinic => {
          this.clinic = this.clinics.find((clinic: any) => clinic.idx == activeClinic.idx);
          this.poDetails.clinicIdx = activeClinic.idx;
        });
      }
    });
  }

  private getCommissionPO(reportName: string, staff: StaffMember, clinicIdx: number, dateFilter: any): void {
    this.poService.getCommissionPO(reportName, staff.idx, clinicIdx, dateFilter).subscribe((commissionPO: any) => {
      this.commissionPO = commissionPO;

      this.getClinicDetails(clinicIdx, true);
      this.account = staff;
      this.accountDisplay = staff.firstname + " " + staff.lastname;
      this.date = new Date();
      console.log("[po]", this.commissionPO)
      if (this.commissionPO && this.commissionPO.length) {
        this.dialogService.bespokeDialog("",
          CommissionPODialogComponent,
          "commission",
          "Would you like to add these transactions to the PO?",
          {po: this.commissionPO, staff: staff, dateFilter: dateFilter} ,
          "1000px").then((answer: any) => {
            if (answer !== false) {
              answer.forEach((transaction: any) => {
                this.addPOItem(transaction);
              });
            }
          });
      }

      this.poDetails = NewPurchaseOrder;
    });
  }

  private addPOItem(po: any): void {
    console.log("[po]", po);
    po.appointmentTime = format((new Date(po.appointmentTime)), "HH:mm dd/MM/yy");
    this.poDetails.items.push({
      ...po,
      notes: `${po.CommissionRate}% of £${po.totalSales}`,
      saleFreeText: `${po.patientName} (${po.appointmentTime})`,
      value: parseFloat(po.commissionPayable),
      qty: 1,
      pervailVATRate: 0
    });
    console.log("[po]", this.poDetails.items);
  }

  private getEmptyPO(): void {
    this.getClinicDetails();
    this.poDetails.items = [];
    this.initiateAutocomplete();
    this.poDetails = NewPurchaseOrder;
  }

  private getCurrentPO(poIdx: number): void {
    this.loadingService.start();
    this.poService.getPOByIdx(poIdx).subscribe((currentPO: PurchaseOrder) => {
      this.poDetails = currentPO;
      console.log(this.poDetails)
      this.account = this.poDetails.accountName;

      this.getClinicDetails(this.poDetails.clinicIdx);

      this.date = new Date(this.poDetails.dateAdded);


      this.poDetails.items = this.poDetails.items.map((item: PurchaseOrderItem) => {
        const VATRateExist = this.itemVATRates.findIndex((itemVAT: VATRates) => itemVAT.value === +item.pervailVATRate);
        const VATRates = VATRateExist !== -1 ? this.itemVATRates : [{ display: +item.pervailVATRate * 100 + "%", value: +item.pervailVATRate }, ...this.itemVATRates];
        return {
          ...item,
          pervailVATRate: item.pervailVATRate ? +item.pervailVATRate : 0,
          VATRates
        }
      })
      this.initiateAutocomplete();
      this.updateTotal();
      this.loadingService.stop();
    });
  }

  private initiateAutocomplete(): void {
    this.filteredAccounts = this.accountsControl.valueChanges.pipe(
      startWith(""),
      map((value) => this.filterAccounts(value))
    )


  }

  private filterAccounts(value: string) {
    const filterValue = value.toLowerCase();

    if (!value) return this.accounts;

    const filterList = this.accounts.filter((option) =>
      option.name.toLowerCase().includes(filterValue)
    );

    return filterList
  }

  private filterSales(value: string): SaleItem[] {
    const filterValue = value.toLowerCase();

    if (!value) return this.saleProducts;

    const filterList = this.saleProducts.filter((option) =>
      option.name.toLowerCase().includes(filterValue)
    );

    return filterList;
  }

/*
  public inputClicked(value: string, index: number = 0): void {
    if (!value) {
      this.itemControls[index].setValue(" ");
      this.itemControls[index].setValue("");
    }
  }
*/

  private itemInspection(item: PurchaseOrderItem): string[] {
    const missingFields: string[] = [];
    if (!item.saleFreeText) missingFields.push("Item Name");
    if (+item.value === 0) missingFields.push("Cost (each)");
    if (!(item.qty >= 0)) missingFields.push("Quantity");

    return missingFields;
  }

  // this function is never used 
  // public addItem(item: PurchaseOrderItem): void {

  //   const inspectedItem = this.itemInspection(item);

  //   if (inspectedItem.length === 0) {
  //     const foundFreeText = this.saleProducts.find((option) => option.name.toLowerCase().includes(item.saleFreeText.toLowerCase()));

  //     if (foundFreeText) item.itemIdx = foundFreeText.idx;
  //     if (!item.notes) item.notes = null;
  //     if (!item.pervailVATRate) item.pervailVATRate = 0;
  //     if (!item.qtyReceived) item.qtyReceived = 0;

  //     this.poDetails.items = [...this.poDetails.items, { ...this.newItem }];
  //     this.updateTotal();
  //   } else {
  //     this.notification.send(`Please fill the following fields: ${inspectedItem.join(', ')}`);
  //   }
  // }

  public removeItem(item: PurchaseOrderItem): void {
    //this.poDetails.items = this.poDetails.items.filter((item_: PurchaseOrderItem) => item_.idx !== item.idx);
    item.qty = 0;
    this.updateTotal();
  }

  public formInspection(items: PurchaseOrderItem[]): boolean {
    const missingFields: string[] = [];

    if (!this.account) missingFields.push("Account");
    if (!this.clinic) missingFields.push("Clinic");
    if (!this.date) missingFields.push("Date");

    items.find((item: PurchaseOrderItem, index: number) => {
      const inspectedItem = this.itemInspection(item);

      if (inspectedItem.length !== 0) missingFields.push(`Item on row ${index + 1} with fields ${inspectedItem.join(', ')}`);
    })

    if (!items.length) missingFields.push(`Item on row 1 with fields: ${this.itemInspection(this.poDetails.items[0]).join(", ")}`);

    if (missingFields.length > 0) this.notification.send(`PO missing the following: ${missingFields.join(", ")}`);

    return missingFields.length === 0;
  }

  public itemSelected(itemName: string, index: number = 0): void {
    const item = this.saleProducts.find((option) =>
      option.name.toLowerCase().includes(itemName.toLowerCase())
    );

    console.log("selecting", item, itemName, index);

    if (item) {
      console.log(item);
      this.newItem.itemIdx = item.idx;
      this.newItem.value = item.price;
      this.newItem.PandLSubCatIdx = item.PandLSubCatIdx
    }
  }

  public savePurchaseOrder(): void {
    console.log("[save] ", this.poDetails.items);
    this.addLine();
    this.poDetails.items = this.poDetails.items.map((item: PurchaseOrderItem) => {
      const foundFreeText = this.saleProducts.find((option) => (!item.saleFreeText || item.saleFreeText !== "")
        && option.name.toLowerCase().includes(item.saleFreeText.toLowerCase()));
      return {
        ...item,
        itemIdx: foundFreeText ? item.itemIdx : 0
      };
    });

    this.poDetails.items = this.poDetails.items.filter((item: PurchaseOrderItem) => item.itemIdx !== -1);

    const formInspected = this.formInspection(this.poDetails.items);

    if (formInspected) {
      this.poDetails.items = this.poDetails.items.map((item: PurchaseOrderItem) => { return { ...item, pervailVATRate: item.pervailVATRate.toString() } })
      this.poDetails.clinicIdx = this.clinic.idx;
      this.poDetails.accountName = this.account;
      this.poDetails.dateAdded = this.date;
      const foundAccountIdx = this.accounts.find((account: any) => account.name === this.account);
      if (foundAccountIdx && foundAccountIdx.id) this.poDetails.accountIdx = foundAccountIdx.id;
      else this.poDetails.accountIdx = 0;

      console.log(this.poDetails);
      this.savePO();
    }
  }

  public savePO(commissionPO: boolean = false): void {
    this.poDetails.commission = commissionPO

    this.loadingService.start();
    this.poService.postPurchaseOrder(this.poDetails).subscribe((response: any) => {
      this.loadingService.stop();
      if (response) {
        this.router.navigate(['finance', 'po', 'print', response.idx]);

        this.poDetails = response;

        if (this.editingPage) {
          this.notification.send("You have successfully updated this PO.");
        } else {
          this.notification.send("You have successfully created this PO.");
        }
      } else {
        this.notification.send("There was a problem with updating this PO.");
      }
    });
  }

  public writeCommissionPO(): void {
    this.poDetails.accountName = this.accountDisplay;
    this.poDetails.dateAdded = this.date;
    this.poDetails.accountIdx = this.account.idx;

    this.savePO(true);
  }

  private clearTotal(): void {
    this.totalExVat = 0;
    this.totalVat = 0;
    this.total = 0;
  }

  public updateTotal(): void {
    this.clearTotal();

    this.poDetails.items.forEach((item: PurchaseOrderItem) => {
      this.totalExVat += item.qty * +item.value;
      this.totalVat += item.qty * +item.value * +item.pervailVATRate;
    });

    this.total = this.totalExVat + this.totalVat;
  }

  updateIncVAT(event: any): void {
    console.log(event);
    const costP = +event.target.value * 100;
    this.newItem.value = Math.round(costP / (this.newItem.pervailVATRate + 1)) / 100;
  }

  public updateValue(event: any): void {
    this.account = event.option.value;
  }

  public displayFn(data: any): string {
    let ret = data && data.text ? data.text : '';
    ret += data && data.info ? ` - ${data.info}` : '';
    return ret;
  }

  public back(): void {
    this.navigationService.back();
  }

  addLine() {
    if (!this.newItem.idx) {
      this.poDetails.items.push({ ...this.newItem });
    }
    this.updateTotal();

    this.newItem = { ...InitialPurchaseOrderItem };
  }

  editItem(item) {
    this.newItem = item;
  }
}
