<div class="signature" *ngIf="signatures">
    <ng-container *ngIf="!signatures.userHasPinCode && signatures.userSupervisor">
        <div class="alert alert-warning">Please set a signing pin code to proceed</div>
        <button mat-raised-button color="primary" (click)="setPinCode()" class="my-2">Set PIN Code</button>
        <hr>
    </ng-container>

    <ng-container>
        <div class="signature-label">Signature {{ ( signatures.userHasPinCode) ? '' : 'verified' }}</div>
      
            <ng-container *ngIf="!isSupervisor">
                <mat-form-field class="w-100 mt-3">
                    <mat-label>Supervisor</mat-label>
                    <mat-select [id]="'supervisor'" [name]="'supervisor'" [(ngModel)]="selectedSupervisorIdx">
                        <mat-option [value]="0">As Student</mat-option>
                        <mat-option *ngFor="let supervisor of signatureService.supervisors"
                            [value]="supervisor.supervisorIdx">{{ supervisor.supervisorName }}</mat-option>
                    </mat-select>
                </mat-form-field>
            </ng-container>

            
                <div class="row mb-2">
                    <div class="col-sm-3" *ngIf="isSupervisor || selectedSupervisorIdx > 0">
                        <button mat-raised-button 
                            color="primary" 
                            class="w-100"
                            (click)="openPinPanel(true)">
                            <mat-icon> border_color</mat-icon>
                            Sign off</button>
                    </div>
                    <div class="col-sm-3" *ngIf="isSupervisor || selectedSupervisorIdx > 0">
                        <button mat-raised-button color="accent" class="w-100" 
                            [disabled]="locked===true"
                            (click)="openPinPanel(false)">
                            <mat-icon>question_answer</mat-icon>
                            More Info Required</button>
                    </div>
            
                    <div class="col-sm-3" *ngIf="(isSupervisor || selectedSupervisorIdx > 0) && locked">
                        <button 
                            *ngIf="locakable"
                            mat-raised-button class="w-100"
                            (click)="unLock()">
                            <mat-icon>lock_open</mat-icon>
                            <span *ngIf="!isSupervisor && selectedSupervisorIdx==0; else unlock">Req. Change</span>
                            <ng-template #unlock>Unlock</ng-template>
                        </button>
                        

                            
                    </div>
                </div>

                <mat-form-field appearance="fill" class="w-100 mb-0">
                    <mat-label>Comments</mat-label>
                    <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="1" cdkAutosizeMaxRows="12" name="comment"
                        [(ngModel)]="comment"></textarea>
                        <button matSuffix mat-icon-button aria-label="Send"  (click)="openPinPanel(false)">
                            <mat-icon>send</mat-icon>
                        </button>
                </mat-form-field>
            

        </ng-container>
</div>