export interface Clinic{
    idx: number;
    name: string;
    address1: string;
    address2: string;
    town: string;
    county: string;
    postcode: string;
    phone: string;
    email: string;
    invoicePrefix: string;   
    website: string;
    logo?: string;
}

export const initialClinic: Clinic = {
    idx: 0,
    name: "",
    address1: "",
    address2: "",
    town: "",
    county: "",
    postcode: "",
    phone: "",
    email: "",
    invoicePrefix: "",
    website: "",
}

export type Clinics = Clinic[];

