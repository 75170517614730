import { StaffMember } from "./staff";

export interface Availability {
    staffIdx: Number;
    available: number[];
}
export interface ProcessedAvailability {
    staffIdx: Number;
    startTime: Date;
    endTime: Date;
}
export interface ExtendedProcessedAvailability {
    title: string;
    color: any;
    start: any;
    end: any;
    day?: number;
    meta: {
        apointmentUID: number;
        user: StaffMember;
        patientId: any;
    };
}


export const enum SortingMode {
    treatment = "TREATMENT",
    group = "GROUP"
  }

  export enum ViewTypes {
    DAY =  'day',
    WEEK = 'week',
    MONTH = 'month',
    NOT_SET = 'not set'
}

export const viewTypes = {
  DAY: ViewTypes.DAY,
  WEEK: ViewTypes.WEEK,
  MONTH: ViewTypes.MONTH,
  NOT_SET: ViewTypes.NOT_SET
};

export enum StaffRoomsView {
    STAFF = 'staff',
    ROOMS = 'room'
}

export const staffRoomsView = {
    STAFF: StaffRoomsView.STAFF,
    ROOMS: StaffRoomsView.ROOMS
};

export const AppointmentStatus = {
    NOSHOW: "NoShow",
    ARRIVED: "Arrived",
    IN_TREATMENT: "InTreatment",
    COMPLETE: "Complete",
    PAID: "Paid",
    DNAPAID: "DNApaid",
    PAID_NO_SOAP: "PaidNoSOAP",
    CANCELLED: "Cancelled",
    LNC: "LNC",
    LNCPAID: "LNCPaid",
    TIMEOFF: "TimeOff",
    BOOKING: "Booking",
    PENDING_APPROVAL: "Pending-approval",
    PENDING_APPROVAL_PAYMENT_REQUIRED: "Pending-approval-payment-required",
    UNALLOCATED_SLOT: "groupEnabled"
};

export const AppointmentStatusFriendly = new Map<string, string>([
    [AppointmentStatus.NOSHOW, "DNA"],
    [AppointmentStatus.ARRIVED, "Arrived"],
    [AppointmentStatus.IN_TREATMENT, "In Treatment"],
    [AppointmentStatus.COMPLETE, "Complete"],
    [AppointmentStatus.PAID, "Paid"],
    [AppointmentStatus.DNAPAID, "DNA Paid"],
    [AppointmentStatus.PAID_NO_SOAP, "Paid No SOAP"],
    [AppointmentStatus.CANCELLED, "Cancelled"],
    [AppointmentStatus.LNC, "LNC"],
    [AppointmentStatus.LNCPAID, "LNC Paid"],
    [AppointmentStatus.TIMEOFF, "Time Off"],
    [AppointmentStatus.BOOKING, "Booking"],
    [AppointmentStatus.PENDING_APPROVAL, "Pending approval"],
    [AppointmentStatus.PENDING_APPROVAL_PAYMENT_REQUIRED, "Pending approval - payment required"],
    [AppointmentStatus.UNALLOCATED_SLOT, "Unallocated"]
]);


export const AppointmentStatusIcons = new Map<string, string>([
    [AppointmentStatus.NOSHOW, "not_interested"],
    [AppointmentStatus.ARRIVED, "how_to_reg"],
    [AppointmentStatus.IN_TREATMENT, "local_hospital"],
    [AppointmentStatus.COMPLETE, "done"],
    [AppointmentStatus.PAID, "payment"],
    [AppointmentStatus.DNAPAID, "payment"],
    [AppointmentStatus.PAID_NO_SOAP, "payment"],
    [AppointmentStatus.CANCELLED, "delete"],
    [AppointmentStatus.LNC, "block"],
    [AppointmentStatus.LNCPAID, "payment"],
    [AppointmentStatus.TIMEOFF, "holiday_village"],
    [AppointmentStatus.BOOKING, "confirmation_number"],
    [AppointmentStatus.PENDING_APPROVAL, "book_online"],
    [AppointmentStatus.UNALLOCATED_SLOT, "UNALLOCATED_SLOT"]
]);


export const DiaryActions = {
    ATTENDANCE: "Attendance report",
    DNA_CHARGE: "DNA charge",
    DNA: "DNA",
    EDIT: "Edit",
    MARK_ARRIVED: "Arrived",
    MARK_BOOKING: "Reset",
    MARK_ACCEPTED: "Accept",
    MARK_CANCELLED: "Cancel",
    MARK_IN_TREATMENT: "In treat.",
    MARK_END_TREATMENT: "End treat.",
    MARK_DNA: "DNA",
    REBOOK: "Rebook",
    SHOW_INVOICE: "View Transaction",
    SHOW_SOAP: "SOAP notes",
    SHOW_CASE_HISTORY: "Case History",
    SHOW_PHYSICAL_EXAMS: "Physical Exams",
    SHOW_PATIENT_INFO: "Patient Info",
    TAKE_PAYMENT: "Take payment",
    SHOW_FUTURE_APPOINTMENTS: "Future apps.",
    ACCEPT_BOOKING: "Accept Booking",
    REJECT_BOOKING: "Reject Booking",
    HR: "section break"
};

export const readOnlyDiaryActions: string[] = [
    DiaryActions.SHOW_SOAP,
    DiaryActions.SHOW_PATIENT_INFO,
    DiaryActions.SHOW_FUTURE_APPOINTMENTS
];

export interface DiaryFocusOnType {
    id: number;
    type: string;
}

export const DiaryFocusOn = {
   STAFF : 'staff',
   GROUP : 'group',
   NONE : 'none'
};

export const DIARY_FOCUS_NONE = { type: DiaryFocusOn.NONE, id: 0 };

export interface AvailableActions {
    status: string;
    actions: string[];
    statusActions: string[];
}

export const AVAILABLEACTIONS: AvailableActions[] = [
    {
        status: AppointmentStatus.NOSHOW,
        statusActions: [DiaryActions.MARK_CANCELLED, DiaryActions.MARK_ARRIVED, DiaryActions.HR, DiaryActions.TAKE_PAYMENT],
        actions: [DiaryActions.SHOW_SOAP, DiaryActions.SHOW_PATIENT_INFO, DiaryActions.SHOW_FUTURE_APPOINTMENTS, DiaryActions.ATTENDANCE, DiaryActions.HR, DiaryActions.EDIT ]
    },
    {
        status: AppointmentStatus.DNAPAID,
        statusActions: [],
        actions: [DiaryActions.SHOW_SOAP, DiaryActions.SHOW_CASE_HISTORY, DiaryActions.SHOW_PHYSICAL_EXAMS, DiaryActions.SHOW_PATIENT_INFO, DiaryActions.SHOW_INVOICE, DiaryActions.SHOW_FUTURE_APPOINTMENTS,  DiaryActions.ATTENDANCE]
    },
    {
        status: AppointmentStatus.LNC,
        statusActions: [DiaryActions.TAKE_PAYMENT, DiaryActions.HR, DiaryActions.MARK_BOOKING],
        actions: [DiaryActions.SHOW_CASE_HISTORY, DiaryActions.SHOW_SOAP, DiaryActions.SHOW_PATIENT_INFO, DiaryActions.SHOW_FUTURE_APPOINTMENTS, DiaryActions.ATTENDANCE, DiaryActions.EDIT, ]
    },
    {
        status: AppointmentStatus.LNCPAID,
        statusActions: [],
        actions: [DiaryActions.SHOW_CASE_HISTORY, DiaryActions.SHOW_SOAP, DiaryActions.SHOW_CASE_HISTORY, DiaryActions.SHOW_PHYSICAL_EXAMS, DiaryActions.SHOW_PATIENT_INFO, DiaryActions.SHOW_INVOICE, DiaryActions.SHOW_FUTURE_APPOINTMENTS,  DiaryActions.ATTENDANCE]
    },
    {
        status: AppointmentStatus.BOOKING,
        statusActions: [DiaryActions.MARK_CANCELLED, DiaryActions.MARK_DNA, DiaryActions.MARK_ARRIVED, DiaryActions.TAKE_PAYMENT, DiaryActions.HR],
        actions: [DiaryActions.SHOW_CASE_HISTORY, DiaryActions.SHOW_SOAP, DiaryActions.SHOW_PATIENT_INFO,  DiaryActions.SHOW_FUTURE_APPOINTMENTS, DiaryActions.ATTENDANCE, DiaryActions.HR, DiaryActions.EDIT, ]
    },
    {
        status: AppointmentStatus.UNALLOCATED_SLOT,
        statusActions: [DiaryActions.MARK_CANCELLED],
        actions: [ DiaryActions.EDIT, DiaryActions.MARK_CANCELLED ]
    },
    {
        status: AppointmentStatus.ARRIVED,
        statusActions: [DiaryActions.MARK_DNA, DiaryActions.MARK_BOOKING, DiaryActions.MARK_IN_TREATMENT, DiaryActions.TAKE_PAYMENT, DiaryActions.HR ],
        actions: [DiaryActions.SHOW_CASE_HISTORY, DiaryActions.SHOW_SOAP, DiaryActions.SHOW_PATIENT_INFO, DiaryActions.SHOW_FUTURE_APPOINTMENTS, DiaryActions.ATTENDANCE, DiaryActions.HR, DiaryActions.EDIT, ]
    },
    {
        status: AppointmentStatus.IN_TREATMENT,
        statusActions: [DiaryActions.MARK_END_TREATMENT, DiaryActions.MARK_BOOKING, DiaryActions.TAKE_PAYMENT, DiaryActions.HR],
        actions: [DiaryActions.SHOW_SOAP, DiaryActions.SHOW_PATIENT_INFO, DiaryActions.SHOW_FUTURE_APPOINTMENTS, DiaryActions.ATTENDANCE, DiaryActions.HR, DiaryActions.EDIT, ]
    },
    {
        status: AppointmentStatus.COMPLETE,
        statusActions: [DiaryActions.TAKE_PAYMENT, DiaryActions.MARK_BOOKING, DiaryActions.HR],
        actions: [DiaryActions.SHOW_CASE_HISTORY, DiaryActions.SHOW_SOAP,  DiaryActions.SHOW_PHYSICAL_EXAMS, DiaryActions.REBOOK, DiaryActions.SHOW_PATIENT_INFO, DiaryActions.SHOW_FUTURE_APPOINTMENTS, DiaryActions.ATTENDANCE]
    },
    {
        status: AppointmentStatus.PAID,
        statusActions: [DiaryActions.SHOW_INVOICE, DiaryActions.HR],
        actions: [DiaryActions.SHOW_SOAP, DiaryActions.REBOOK, DiaryActions.SHOW_CASE_HISTORY, DiaryActions.SHOW_PHYSICAL_EXAMS, DiaryActions.SHOW_PATIENT_INFO, DiaryActions.SHOW_FUTURE_APPOINTMENTS, DiaryActions.ATTENDANCE]
    },
    {
        status: AppointmentStatus.PAID_NO_SOAP,  // clones PAID
        statusActions: [DiaryActions.SHOW_INVOICE, DiaryActions.HR],
        actions: [DiaryActions.SHOW_SOAP, DiaryActions.REBOOK, DiaryActions.SHOW_CASE_HISTORY, DiaryActions.SHOW_PHYSICAL_EXAMS, DiaryActions.SHOW_PATIENT_INFO, DiaryActions.SHOW_FUTURE_APPOINTMENTS, DiaryActions.ATTENDANCE]
    },
    {
        status: AppointmentStatus.TIMEOFF,
        statusActions: [DiaryActions.MARK_CANCELLED, DiaryActions.HR],
        actions: [DiaryActions.SHOW_FUTURE_APPOINTMENTS, DiaryActions.HR, DiaryActions.EDIT, ]
    },
    {
        status: AppointmentStatus.CANCELLED,
        statusActions: [DiaryActions.MARK_BOOKING, DiaryActions.HR, DiaryActions.MARK_BOOKING],
        actions: [DiaryActions.SHOW_SOAP, DiaryActions.ATTENDANCE, DiaryActions.SHOW_FUTURE_APPOINTMENTS, ]
    },
    {
        status: AppointmentStatus.PENDING_APPROVAL,
        statusActions: [DiaryActions.ACCEPT_BOOKING, DiaryActions.REJECT_BOOKING, DiaryActions.HR],
        actions: [DiaryActions.EDIT]
    },
    {
        status: AppointmentStatus.PENDING_APPROVAL_PAYMENT_REQUIRED,
        statusActions: [DiaryActions.REJECT_BOOKING],
        actions: []
    }
];


export interface DiaryColumn {
    id: number;
    title: string;
    discipline?: string;
    name?: any;
}

export interface CalendarSettings {
    startHour: number;
    endHour: number;
    increment: number;
    hourSegments: number;
    morningEnd: number;
    afternoonStart: number;
    midSplitStart: number;
    midSplitEnd: number;
}


export interface State extends CalendarSettings {
    selectedStaff?: any[];
    selectedRooms?: any[];
    selectedStaffWeekView?: any[];
    selectedRoomWeekView?: any[];
    viewDate?: number;
    view?: ViewTypes;
    zoom?: number;
    private?: boolean;
    showRightPanel?: boolean;
    staffOrRooms?: StaffRoomsView;
    showWorking?: boolean;
    draggingActive?: boolean;
    availability?: Availability[];
    colours?: string[];
    _startHour: number;
    _endHour: number;
    staffGroupBy?: SortingMode;
    focusedOn?: DiaryFocusOnType;
    showCancelled ?: boolean;
}

export const initialState: State = {
    selectedStaff: [],
    selectedRooms: [],
    selectedStaffWeekView: [],
    selectedRoomWeekView: [],
    zoom: 20,
    private: false,
    showRightPanel: false,
    staffOrRooms: StaffRoomsView.STAFF,
    viewDate: new Date().getTime(),
    view: ViewTypes.NOT_SET,
    showWorking: false,
    draggingActive: false,
    availability: [],
    colours: [],
    startHour: 8,
    endHour: 20,
    _startHour: 8,
    _endHour: 20,
    increment: 15,
    hourSegments: 4,
    staffGroupBy: SortingMode.treatment,
    focusedOn: DIARY_FOCUS_NONE,
    showCancelled: false,
    morningEnd: 12,
    afternoonStart: 12,
    midSplitStart: 0,
    midSplitEnd: 0
};


// USED FOR Saving and creating appointments but not in the actual diary.... need to merge the models
export interface Appointment {
    idx?: number;
    startTime?: Date;
    staffIdx?: number;
    treatmentIdx?: number;
    roomIdx?: number;
    duration?: number;
    notes?: string;
    status?: string;
    lastStatus?: string;
    isNotAvailable?: boolean;
    patientIdx?: number;
    groupSize?: number;
    patientInfo?: {
        name?: string,
        notes?: string,
        mobilePhone?: string,
        email?: string,
        lastAppointment?: {
            idx?: number,
            chiroIdx?: number,
            staffName?: string,
            treatmentIdx?: number,
            treatmentName?: string
        },
        nVisits?: number,
        defaultStaffIdx?: number,
        regularStaffMember?: string,
        reminders?: {
            sms: boolean,
            email: boolean
        }
    };
}

export const initialAppointmentValue = {
    idx: 0,
    startTime: new Date(),
    staffIdx: null,
    treatmentIdx: null,
    roomIdx: null,
    duration: 30,
    notes: "",
    status: AppointmentStatus.BOOKING,
    patientIdx: null,
    groupSize: 1,
    patientInfo: {
        name: null,
        nVisits: 0,
        mobilePhone: "",
        email: "",
        reminders: {
            sms: false,
            email: false
        }
    }
};

