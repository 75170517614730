<div *ngIf="form" [id]='formName'>
    <form autocomplete="off"  class="reflexForm" #formEle="ngForm">
      <div class="reflexRow">
        <div class="reflexCol">
          <app-reflexes-field name="BIC_L"  [label]="'BIC'" [(ngModel)]="form['BIC_L']"></app-reflexes-field>
          <app-reflexes-field name="ABD"    [label]="'ABD'" [(ngModel)]="form['UABR_L']"></app-reflexes-field>
          <app-reflexes-field name="UABR_L" [label]="'F. Flex'" [(ngModel)]="form['LABR_L']"></app-reflexes-field>
          <app-reflexes-field name="PAT_L"  [label]="'PAT'" [(ngModel)]="form['PAT_L']"></app-reflexes-field>
          <app-reflexes-field-special name="BAB_L"    [label]="'BAB'"    [(ngModel)]="form['BAB_L']"></app-reflexes-field-special>
          <app-reflexes-field-special name="CLONUS_L" [label]="'CLONUS'" [(ngModel)]="form['CLONUS_L']"></app-reflexes-field-special>
        </div>
        <div class="reflexCol"><img src="https://cdn.multiclinic.co.uk/assets/man-front.png"></div>
        <div class="reflexCol">
          <app-reflexes-field name="BIC_R"  [label]="'BIC'" [(ngModel)]="form['BIC_R']"></app-reflexes-field>
          <app-reflexes-field name="UABR_R" [label]="'ABD'" [(ngModel)]="form['UABR_R']"></app-reflexes-field>
          <app-reflexes-field name="LABR_R" [label]="'F. Flex'" [(ngModel)]="form['LABR_R']"></app-reflexes-field>
          <app-reflexes-field name="PAT_R"  [label]="'PAT'" [(ngModel)]="form['PAT_R']"></app-reflexes-field>
          <app-reflexes-field-special name="BAB_R"    [label]="'BAB'" [(ngModel)]="form['BAB_R']"></app-reflexes-field-special>
          <app-reflexes-field-special name="CLONUS_R" [label]="'CLONUS'" [(ngModel)]="form['CLONUS_R']"></app-reflexes-field-special>
        </div>
      </div>

      <div class="reflexRow">
        <div class="reflexCol">
          <app-reflexes-field name="TRI_L"  [label]="'TRI'"  [(ngModel)]="form['TRI_L']"></app-reflexes-field>
          <app-reflexes-field name="BRA_L"  [label]="'BRA'"  [(ngModel)]="form['BRA_L']"></app-reflexes-field>
          <app-reflexes-field name="HAMS_L" [label]="'HAMS'" [(ngModel)]="form['HAMS_L']"></app-reflexes-field>
          <app-reflexes-field name="TIB_L"  [label]="'TIB POST'" [(ngModel)]="form['TIB_L']"></app-reflexes-field>
          <app-reflexes-field name="ACH_L"  [label]="'ACH'" [(ngModel)]="form['ACH_L']"></app-reflexes-field>
        </div>
        <div class="reflexCol"><img src="https://cdn.multiclinic.co.uk/assets/man-front.png"></div>
        <div class="reflexCol">
          <app-reflexes-field name="TRI_R"  [label]="'TRI'"  [(ngModel)]="form['TRI_R']"></app-reflexes-field>
          <app-reflexes-field name="BRA_R"  [label]="'BRA'"  [(ngModel)]="form['BRA_R']"></app-reflexes-field>
          <app-reflexes-field name="HAMS_R" [label]="'HAMS'" [(ngModel)]="form['HAMS_R']"></app-reflexes-field>
          <app-reflexes-field name="TIB_R"  [label]="'TIB POST'" [(ngModel)]="form['TIB_R']"></app-reflexes-field>
          <app-reflexes-field name="ACH_R"  [label]="'ACH'"      [(ngModel)]="form['ACH_R']"></app-reflexes-field>
        </div>
      </div>
      <div class="row  set-all-row">
        <div class="col">
          <div class="row  set-all-row">
            <span class="bold">Set All Upper Reflexes</span>
          </div>
          <div class="row justify-content-center">
            <div class="col-auto">
             <button [disabled]="isDisabled" class="set-all" mat-stroked-button (click)="setAllReflexes('upper','0')">0</button>
             <button [disabled]="isDisabled" class="set-all" mat-stroked-button (click)="setAllReflexes('upper','1')">1(+)</button>
             <button [disabled]="isDisabled" class="set-all" mat-stroked-button (click)="setAllReflexes('upper','2')">2(+)</button>
             <button [disabled]="isDisabled" class="set-all" mat-stroked-button (click)="setAllReflexes('upper','3')">3(+)</button>
            </div>
          </div>
        </div>
        <div class="col margin-left">
          <div class="row  set-all-row">
            <span class="bold">Set All Lower Reflexes</span>
          </div>
          <div class="row justify-content-center">
            <div class="col-auto">
                <button [disabled]="isDisabled" class="set-all" mat-stroked-button (click)="setAllReflexes('lower','0')">0</button>
                <button [disabled]="isDisabled" class="set-all" mat-stroked-button (click)="setAllReflexes('lower','1')">1(+)</button>
                <button [disabled]="isDisabled" class="set-all" mat-stroked-button (click)="setAllReflexes('lower','2')">2(+)</button>
                <button [disabled]="isDisabled" class="set-all" mat-stroked-button (click)="setAllReflexes('lower','3')">3(+)</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  </div>