<div class="row margin-collapse">
    <div class="col-lg-10">
        <form autocomplete="off" [formGroup]="profileForm">
            <mat-card><mat-card-content>
                <h2 class="mb-5 controller text-start mx-auto">User profile</h2>                   
                
                <div class="controllers">
                    <div class="mb-1">
                        <img [src]="profileImage" width="128" height="128"/>
                    </div>
                    <mat-form-field class="controller">
                        <mat-label>First name</mat-label>
                        <input matInput 
                            name="firstName"
                            placeholder="First name" 
                            formControlName="FirstName">
                        <mat-error *ngIf="handleError('FirstName', 'required')">You must provide a <strong>first name.</strong></mat-error>
                    </mat-form-field>
    
                    <mat-form-field class="controller">
                        <mat-label>Last name</mat-label>
                        <input matInput 
                            name="lastName"
                            placeholder="Last name" 
                            formControlName="LastName">
                        <mat-error *ngIf="handleError('LastName', 'required')">You must provide a <strong>last name.</strong></mat-error>
                    </mat-form-field>
    
                    <mat-form-field class="controller">
                        <mat-label>Email address</mat-label>
                        <input matInput 
                            name="email"
                            placeholder="Email address" 
                            formControlName="email" 
                            required>
                        <mat-error *ngIf="handleError('email', 'required')">You must provide an <strong>email address.</strong></mat-error>
                        <mat-error *ngIf="handleError('email', 'email')" class="mb-3">You must provide a valid <strong>email address.</strong></mat-error>
                    </mat-form-field>
    
                    <mat-form-field class="controller">
                        <mat-label>Phone number</mat-label>
                        <input matInput 
                            name="phoneNumber"
                            placeholder="Phone number" 
                            type="text" 
                            formControlName="HomePhone">
                        <mat-error *ngIf="handleError('HomePhone', 'required')">You must provide a <strong>phone number.</strong></mat-error>
                    </mat-form-field>
    
                    <div [ngClass]="settingNewPassword ? 'setting-password setting-password--open' : 'setting-password'">
                        <h2 class="mt-3 text-start controller">Change password</h2>
    
                        <div (click)="setValidators()" class="closePasswordField"><i class="fal fa-times"></i></div>
                        <div class="alert alert-info controller mt-3">In order to <strong>protect your personal data</strong>, please provide your current password so we can identify your account.</div>
    
                        <mat-form-field class="controller mb-2">
                            <mat-label>Current password</mat-label>
                            <input matInput placeholder="Current password" type="password" formControlName="password">
                            <mat-error *ngIf="handleError('password', 'required')">You must provide your <strong>current password.</strong></mat-error>
                        </mat-form-field>
    
                        <mat-form-field class="controller mb-2">
                            <mat-label>New password</mat-label>
                            <input matInput placeholder="New password" [type]="hidePassword ? 'password' : 'text'" formControlName="newPassword" (keyup)="doPasswordsMatch()">
                            <button type="button" mat-icon-button matSuffix (click)="hidePassword = !hidePassword" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePassword">
                              <mat-icon>{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
                            </button>
                            <mat-error *ngIf="handleError('newPassword', 'required')">You must provide a <strong>new password.</strong></mat-error>
                        </mat-form-field>
    
                        <app-password-strength class="controller mb-2" [passwordToCheck]="profileForm.value.newPassword" (passwordStrength)="passwordStrongEnough = $event"></app-password-strength>
        
                        <mat-form-field class="controller mb-2">
                            <mat-label>New password confirmation</mat-label>
                            <input matInput placeholder="New password confirmation" [type]="hidePassword ? 'password' : 'text'" formControlName="newPasswordConfirm" (keyup)="doPasswordsMatch()">
                            <button type="button" mat-icon-button matSuffix (click)="hidePassword = !hidePassword" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hidePassword">
                              <mat-icon>{{ hidePassword ? 'visibility_off' : 'visibility' }}</mat-icon>
                            </button>
                            <mat-error *ngIf="handleError('newPasswordConfirm', 'required')">You must provide the <strong>new password again.</strong></mat-error>
                        </mat-form-field>
    
                        <div *ngIf="!passwordsMatch" class="password-match-error controller mb-2"><span class="error-color">*</span> The two passwords must match.</div>
                    </div>
                
                    <div class="alert alert-danger controller mt-3" *ngIf="userDataMissingError">Something has gone wrong. Your user data is missing. Please try to log out and back in!</div>
                    <div class="alert alert-danger controller mt-3" *ngIf="passwordStrengthError">Please provide a password that complies with the requirements.</div>
                    <div class="alert alert-danger controller mt-3" *ngIf="passwordChangeError">Please provide two passwords that match.</div>
                    <div class="alert alert-danger controller mt-3" *ngIf="updateFailure">Update request has been unsuccessful. Please try again!</div>
                    <div class="alert alert-danger controller mt-3" *ngIf="formInvalidError">Please correct the errors before you update your records.</div>
                    <div class="alert alert-success controller mt-3" *ngIf="updateSuccessful">Update request has been completed successfully.</div>
                    <div class="alert alert-success controller mt-3" *ngIf="passwordUpdateSuccessful">Update password request has been completed successfully.</div>
                </div>
            </mat-card-content></mat-card>
        </form>
    </div>

    <div class="col-sm-2 mobile-till-margin">
        <div class="fix-top">
            <ul class="list-sections">
                <li><button class="btn btn-link" (click)="setValidators()">Change password</button></li>
                <li><button class="btn btn-link" (click)="openPinPanel()">Set pin code</button></li>
            </ul>
          
            <div class="desktop-buttons mobile-buttons">
                <div class="m3-3 text-center mobile-button" *ngIf="!settingNewPassword">
                    <button mat-raised-button color="primary" class="w-100" (click)="saveUpdates()">Update records</button>
                </div>
                <div class="m3-3 text-center mobile-button" *ngIf="settingNewPassword">
                    <button mat-raised-button color="primary" class="w-100" (click)="savePassword()">Update password</button>
                </div>
                <div class="my-3 text-center mobile-button">
                    <button mat-raised-button class="w-100" (click)="cancel()">Back</button>
                </div>
            </div>
        </div>
    </div>
</div>