import { Action } from '@ngrx/store';
import { State } from '../../models/site-settings';
import { PaymentMethod } from '../../models/payment-methods';
import { type } from '../util';

export const SET = 'PAYMENT_METHODS_SET';
export const INIT = 'PAYMENT_METHODS_INIT';

export const ActionTypes = {
    SET: type('[PaymentMethodsState] Set'),
    INIT: type('[PaymentMethodsState] Init'),
};


/**
 * Every action is comprised of at least a type and an optional
 * payload. Expressing actions as classes enables powerful 
 * type checking in reducer functions.
 * 
 * See Discriminated Unions: https://www.typescriptlang.org/docs/handbook/advanced-types.html#discriminated-unions
 */
export class SetAction implements Action {
    type = ActionTypes.SET;
    constructor(public payload: PaymentMethod[]) { }
}

export class InitAction implements Action {
    type = ActionTypes.INIT;
    constructor() { }
}

/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
export type Actions
    = SetAction |
    InitAction;
