import { QuestionBase } from './question-base';

export class PasswordQuestion extends QuestionBase<string> {
    controlType = 'password';
    declare type: string;
    placeholder: string;

    constructor(options: {} = {}) {
        super(options);
    }
}
