import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatListOption, MatSelectionList } from '@angular/material/list'
import { QuestionService } from 'app/dynamic-form/services/question.service';
import { LoadingService } from 'app/services/loading.service';

@Component({
  selector: 'app-questionnaire-dialog',
  templateUrl: './questionnaire-dialog.component.html',
  styleUrls: ['../dialog-shared.scss']
})
export class QuestionnaireDialogComponent implements OnInit {
  questionnaireSubforms: any;
  selectedSubforms: any;
  @ViewChild('subformsList') subformsList: MatSelectionList;


  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    public dialogRef: MatDialogRef<QuestionnaireDialogComponent>,
  ) { }

  ngOnInit(): void {
  }

  public onConfirm(index: number): void {
    this.dialogRef.close({ index: index, sendOnEmail: true, selectedSubforms: this.selectedSubforms });
  }

  public onDismiss(index: number): void {
    this.dialogRef.close({ index: index, sendOnEmail: false, selectedSubforms: this.selectedSubforms });
  }

  updateList(e){
    this.selectedSubforms = e;
  }
}

