
export const CervicalFields: { label: string, field: string }[] =
  [
    {
      label: "Cranial Nerves",
      field: "Cranial_Nerves"
    },
    {
      label: "Foraminal Comp",
      field: "Foraminal_Comp"
    },
    {
      label: "Adsons",
      field: "Adsons"
    },
    {
      label: "Rev Adsons",
      field: "Rev_Adsons"
    },
    {
      label: "Shoulder",
      field: "Shoulder"
    },
    {
      label: "Other TOS test",
      field: "Other_TOS_test"
    },
    {
      label: "Axial Comp",
      field: "Axial_Comp"
    },
    {
      label: "Cervical Distraction",
      field: "Cervical_distraction"
    },
    {
      label: "Dejerines Triad",
      field: "Dejerines_Triad"
    },
    {
      label: "Dermatomes",
      field: "Dermatomes"
    },
    {
      label: "Trigger Points",
      field: "Trigger_Points"
    },
    {
      label: "Arm Squeeze",
      field: "Arm_Squeeze"
    },
    {
      label: "Free text 1",
      field: "blank_1"
    },
    {
      label: "Free text 2",
      field: "blank_2"
    }];
