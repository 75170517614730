import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatTableDataSource } from "@angular/material/table";
import { PatientChooserResult } from "app/models/patient-details";
import { NotificationService } from "app/services/notification.service";
import { PatientChooserService } from "app/services/patient.service";
import { SaleItemsService } from "app/services/sale-items.service";

@Component({
  selector: "app-transfer-credits",
  templateUrl: "./transfer-credits.component.html",
  styleUrls: ["./transfer-credits.component.scss"],
})
export class TransferCreditsComponent implements OnInit, OnDestroy {
  fromPatient: PatientChooserResult;
  toPatient: PatientChooserResult;
  fromPatientCredits: any;
  toPatientCredits: any;
  patientFromIsSelected = false;
  patientToIsSelected = false;
  listOfCredits: any[];
  numberAvailableToTransfer: number;
  numberToTransfer: number;
  creditTransferIdx: number;
  transfered = false;

  constructor(
    private patientService: PatientChooserService,
    private saleItemsService: SaleItemsService,
    private notificationService: NotificationService
  ) {}

  ngOnInit(): void {
    this.getListCredits();
  }

  selectedPatientEvent(
    instance: number,
    selectedPatient: PatientChooserResult
  ) {
    if (instance === 1) {
      this.fromPatient = selectedPatient;
      this.patientFromIsSelected = true;
      this.fromPatientCredits = this.getPatientCredits(this.fromPatient.idx);
    } else if (instance === 2) {
      this.toPatient = selectedPatient;
      this.patientToIsSelected = true;
      this.toPatientCredits = this.getPatientCredits(this.toPatient.idx);
    }
    console.log("From: ", this.fromPatient, "To", this.toPatient);
  }

  getListCredits() {
    this.listOfCredits = [];
    this.saleItemsService.listCredits().subscribe((res) => {
      res.forEach((item) => {
        if (item.type == "credits") this.listOfCredits.push(item);
      });
      console.log(this.listOfCredits);
    });
  }

  getPatientCredits(idx: number) {
    let creditsDetails = [];
    this.patientService.getPatientAccountInfo(idx).subscribe((res) => {
      res.creditsDetails.forEach((item) => {
        creditsDetails.push(item);
      });
    });
    console.log(creditsDetails);
    return creditsDetails;
  }

  changeCreditType(creditIdx: number) {
    console.log(creditIdx);
    this.creditTransferIdx = creditIdx;
    this.fromPatientCredits.forEach((creditType) => {
      if (creditType.creditIdx == creditIdx)
        this.numberAvailableToTransfer = creditType.count;
    });
  }

  selectNumber(n: number) {
    this.numberToTransfer = n;
    console.log(n);
  }

  numSequence(n: number): Array<number> {
    return Array(n);
  }

  ngOnDestroy(): void {
    this.patientFromIsSelected = false;
    this.patientToIsSelected = false;
  }

  onReset() {
    this.fromPatient = this.toPatient = this.fromPatientCredits = this.toPatientCredits = this.patientFromIsSelected = this.patientToIsSelected = this.numberToTransfer = undefined
    this.ngOnInit()
  }

  onTransfer() {
    this.transfered = true;
    console.log();
    this.patientService
      .transferCredits(
        this.fromPatient.idx,
        this.toPatient.idx,
        this.creditTransferIdx,
        this.numberToTransfer
      )
      .subscribe((res) => {
        console.log(res);
        this.notificationService.send("You have successfully transferred credits")
      });
    this.fromPatientCredits = this.getPatientCredits(this.fromPatient.idx);
    this.toPatientCredits = this.getPatientCredits(this.toPatient.idx);
  }
}
