import { State, initialState, ReportFilter } from '../../models/user-settings';
import * as settingsAction from '../actions/user-settings';
import { ActionWithPayload } from './index';

export { State, initialState };

const MENU_DECAY = 0.9;

export function reducer(state = initialState, action: ActionWithPayload): State {
    switch (action.type) {
        case settingsAction.ActionTypes.SET:
            return Object.assign({}, state, action.payload);
        case settingsAction.ActionTypes.SET_MOBILE_VIEW:
            return Object.assign({}, state, { ...state, mobileView: action.payload });
        case settingsAction.ActionTypes.SET_DARK_MODE:
            return Object.assign({}, state, { ...state, darkMode: action.payload });
        case settingsAction.ActionTypes.SET_REPORT_FILTERS:
            let reportFilterExist: boolean = false;
            const reportFilters = state.reportFilters.map((reportFilter: ReportFilter) => {
              if ( reportFilter.reportName === action.payload.reportName ) {
                reportFilterExist = true;

                return {
                  ...reportFilter,
                  sortingFilters: action.payload.sortingFilters,
                  columnFilters: action.payload.columnFilters,
                  columnState: action.payload.columnState
                }
              }

              return reportFilter;
            })

            if ( !reportFilterExist ) reportFilters.push(action.payload);

            return Object.assign({}, state, { ...state, reportFilters });
        default:
            return state;
  }
}

export const getIconStyle = (state: State) => {
  return state.useOldIcons ? state.useOldIcons : false;
}


export const getReportFilters = (state: State) => state.reportFilters;
export const getMobileView = (state: State) => state.mobileView;
export const getDarkMode = (state: State) => state.darkMode;
