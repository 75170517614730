<form autocomplete="off" #formEle="ngForm">
    <h6>
        Patient presenting with Low Back Pain that
    </h6>

    <div class="row mt-5">
        <div class="col-sm-1"></div>
        
            <mat-label class="col-sm-3 pt-2" >Has lasted more than 3 months?</mat-label>
        
        <mat-form-field class="col-sm-3">
            <mat-label>Select answer</mat-label>
            <mat-select [(ngModel)]="form.lasted_more_than_three_months" name="lasted_more_than_three_months">
                <mat-option [value]="'Yes'">Yes</mat-option>
                <mat-option [value]="'No'">No</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="row mt-2">
        <div class="col-sm-1"></div>
        <mat-label class="col-sm-3 pt-2">Started before the age of 45?</mat-label>
    <mat-form-field class="col-sm-3">
        <mat-label>Select answer</mat-label>
        <mat-select [(ngModel)]="form.started_before_age_fortyfive" name="started_before_age_fortyfive">
            <mat-option [value]="'Yes'">Yes</mat-option>
            <mat-option [value]="'No'">No</mat-option>
        </mat-select>
    </mat-form-field>
    </div>

</form>