import { Component, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { QuestionService } from 'app/dynamic-form/services/question.service';
import { NavigationService } from 'app/services/navigation.service';
import { take } from 'rxjs/operators';
import * as fromRoot from "../../store/reducers"
import { PurchaseOrdersService } from '../purchase-orders/purchase-orders.service';
import { PurchaseOrder, PurchaseOrderItem  } from "../../models/purchaseOrder"
import { ClinicService } from 'app/services/clinic.service';
import { InvoiceService } from '../invoice/invoice.service';
import { PatientChooserService } from 'app/services/patient.service';
import { NotificationService } from 'app/services/notification.service';
import { PaymentMethodsService } from 'app/services/payment-methods.service';
import { PaymentMethod } from 'app/models/payment-methods';
import { EmailTemplates } from 'app/models/email';
import { LoadingService } from 'app/services/loading.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { EditorConfig } from 'app/models/editor-config';
import { EmailTemplatesService } from 'app/settings/email-templates/email-templates.service';
import { TextReplacementService } from 'app/services/text-replacement.service';

@Component({
  selector: 'app-print-purchase-order',
  templateUrl: './print-purchase-order.component.html',
  styleUrls: ['./print-purchase-order.component.scss']
})
export class PrintPurchaseOrderComponent implements OnInit {
  public poDetails: PurchaseOrder;
  public total: number = 0;
  public totalIncVAT: number = 0
  public clinicName: string;
  public clinic: any[];
  public invoiceLogo: string;
  public invoicePrefix: string;
  public clientEmail: string;
  public emailSubject: string;
  public paymentMethod: PaymentMethod;
  public poExist: boolean;
  public accountAddress: string[] = [];
  public patientName: string = null;
  public dateTruncated: boolean = false;
  public numberReceived: number = 0;
  public numberOutstanding: number;
  public emailText = {
    body: "",
    subject: ""
  };
  public emailing: boolean;
  public editorConfig: AngularEditorConfig = EditorConfig;

  constructor( private store: Store<fromRoot.State>,
               private qs: QuestionService, 
               private route: ActivatedRoute, 
               private navigationService: NavigationService, 
               private poService: PurchaseOrdersService,
               private clinicService: ClinicService,
               private elementRef: ElementRef,
               private invoiceService: InvoiceService,
               private patientService: PatientChooserService,
               private notification: NotificationService,
               private router: Router,
               private loading: LoadingService,
               private paymentMethodsService: PaymentMethodsService,
               private emailTemplatesService: EmailTemplatesService, 
               private textReplacementService: TextReplacementService ) { 

    this.route.params.subscribe((params: Params) => {
      if ( params["poIdx"] ) this.getCurrentPO(params["poIdx"]);
    })
  }

  ngOnInit(): void {
    this.store.select(fromRoot.getSiteSettings).pipe(take(1)).subscribe((res: any) => this.invoiceLogo = res.invoiceLogo);

    
  }

  private getCurrentPO(poIdx: number): void {
    this.loading.start();
    this.poService.getPOByIdx(poIdx).subscribe((currentPO: PurchaseOrder) => {
      this.poDetails = currentPO;
      this.numberReceived = this.poDetails.items.map(item => item.qtyReceived).reduce((acc, curr) => Number(acc) + Number(curr));
      this.numberOutstanding = this.poDetails.items.map(item => Number(item.qty)).reduce((acc, curr) => acc + curr) - this.numberReceived;
      if ( this.poDetails.dateAdded && this.poDetails.dateAdded.substring(this.poDetails.dateAdded.length - 8) === "00:00:00" ) {
        this.dateTruncated = true;
      }

      this.poDetails.items.forEach(item => {
        this.total += item.value * item.qty;
        this.totalIncVAT += item.value * item.qty *  (1 + +item.pervailVATRate);
      });

      if ( this.poDetails && this.poDetails.items.length ) {
        this.updateDetails();
        this.poExist = true;
      }

      this.loading.stop();
      this.getEmailTemplate(EmailTemplates.PO);
    })
  }

  private updateDetails(): void {

    this.clinicService.getClinics().pipe(take(1)).subscribe((clinics: any) => {
      this.clinicName = clinics.filter((clinic: any) => clinic.idx === this.poDetails.clinicIdx)[0].name;

      this.clinic=[];
      this.clinicService.getAddress(this.poDetails.clinicIdx).subscribe((address: any) => {
        console.log("address", address);
        if (address.address1) this.clinic.push(address.address1);
        if (address.town) this.clinic.push(address.town);
        if (address.postcode) this.clinic.push(address.postcode);
      })
    })

    this.qs.getQuestions("clinic", this.poDetails.clinicIdx).subscribe((response: any) => {
      this.invoicePrefix = response.questions[2].value;
      this.emailSubject = `PO: ${this.invoicePrefix} ${this.poDetails.idx}`;
    });

    this.patientService.getPatientDetails(this.poDetails.accountIdx).subscribe(patientInfo => {
      this.accountAddress = patientInfo.address;
      this.clientEmail = patientInfo.email;
      this.patientName = patientInfo.name;
    })
  }

  public back(): void {
    this.navigationService.back()
  }

  public print(): void {
    window.print();
  }

  public navigateToReceive(): void {
    this.router.navigate(["/finance/po/receive", this.poDetails.idx]);
  }

  public navigateToEdit(): void {
    this.router.navigate(["/finance/po", this.poDetails.idx]);
  }

  public quickClose(): void {
    let totalPaid = 0;
    this.poDetails.items = this.poDetails.items.map((poItem: PurchaseOrderItem) => {
      totalPaid += +poItem.qty * +poItem.value;

      return {
        ...poItem,
        qtyReceived: +poItem.qty
      }
    })

    this.poDetails.amountPaid = totalPaid;
    this.poDetails.received = true;

    this.paymentMethodsService.getPaymentMethods().subscribe((paymentMethods: PaymentMethod[]) => {
      this.paymentMethod = paymentMethods.filter((p: PaymentMethod) => p.name === this.poDetails.paymentMethod)[0];

      this.poDetails.paymentMethod = this.paymentMethod.idx;
      this.poService.postPurchaseOrder(this.poDetails).pipe(take(1)).subscribe((response: PurchaseOrder) => {
        if ( response ) {
          this.poDetails = response;
          this.notification.send(`You have successfully closed this PO.`);
        } else {
          this.notification.send(`There was a problem with closing this PO.`);
        }
      })
    })
  }

  public emailInvoice(): void {
    if (!this.emailing) {
      this.emailing = true;
    } else {
      const invoice = this.elementRef.nativeElement.getElementsByClassName("pdf")[0].innerHTML.replaceAll("£", "&pound;");
      const transactionIdx = 0;

      if (this.clientEmail) {
        this.invoiceService.emailInvoice(
          EmailTemplates.PO,
          transactionIdx,
          this.poDetails.accountIdx,
          {
            subject: this.emailSubject,
            attachment: invoice,
            body: this.emailText.body
          },
          this.clientEmail,
        ).subscribe((emailSent: boolean) => {
          if (emailSent) this.notification.send(`You have successfully emailed this PO to ${this.clientEmail}.`);
          else this.notification.send(`There was a problem sending this PO to ${this.clientEmail}. Please try again!`);
          this.emailing = false;
        });
      }
    }
  }

  public addNew(){
    this.router.navigate(['finance', 'newPO']);
  }

  voidPO(){
    this.poService.voidPO(this.poDetails.idx).subscribe(res=>{
      this.back();
    });
  }

  private getEmailTemplate(name: string) : void {
    this.emailTemplatesService.getTemplate(name).subscribe((res: any) => {
      this.emailText.body = res.body
      this.emailText.subject = res.subject
      this.updateEmailText();
    })

  } 

  updateEmailText() {
    this.textReplacementService.setPatientIdx(this.poDetails.accountIdx);
    this.textReplacementService.fulfillReplacements(this.emailText.body).subscribe(res=>{
      this.emailText.body = res;
    });
  }
}