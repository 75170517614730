<div [formGroup]="form" class="form-group full-width">
    <mat-form-field 
    style="width:100%">
    <mat-label>{{question.label}}</mat-label>
    <textarea 
        [maxLength]="question.maxLength"
        matInput
        #inputValue
        cdkTextareaAutosize
        cdkAutosizeMinRows="1"
        cdkAutosizeMaxRows="6"
        [id]="question.key" 
        [formControlName]="question.key" 
        [(value)]="question.value"
        (keyup)="handleChange(question, inputValue)"
    ></textarea>
    <mat-error *ngIf="fieldInvalid() && showInvalidField">{{fieldInvalidError()}}</mat-error>
    </mat-form-field>
</div>