import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";

import {
  ActivatedRoute
} from "@angular/router";
import { HostListener } from "@angular/core";
import { Store } from "@ngrx/store";

import * as fromRoot from '../store/reducers';
import { DiaryService } from "../services/diary/diary.service";
import { timer } from "rxjs";
@Component({
  selector: "app-diary",
  templateUrl: "./diary.component.html",
  styleUrls: ["./diary.component.scss"]
})
export class DiaryComponent implements OnInit {
  tiles;
  public mobileView: boolean = false;

  mainPanel = {

    text: "diary",
    cols: 20,
    rows: 10,
    color: "white",
    component: "diary",
    overflow: "scroll"

  }
  rightPanel = {
    text: "Right Bar",
    cols: 5,
    rows: 10,
    color: "lightgreen",
    component: "rightSidebar",
    overflow: "normal"
  }

  fixedTiles = [
    // {text: 'Staff', cols: 2, rows: 2, color: 'lightblue', component: 'sidebarHeader'},
    // {text: 'Centre Title', cols: 8, rows: 1, color: 'lightblue', component: 'diaryHeader'},
    // {text: 'Appointment Details', cols: 2, rows: 2, color: 'lightblue', component: 'sidebarHeader'},
    {
      text: "Left Bar Header",
      cols: 1,
      rows: 4,
      color: "lightgreen",
      class: "d-none d-lg-inline-block",
      component: "sidebarHeader",
      overflow: "normal"
    },


    {
      text: "Left Bar",
      cols: 1,
      rows: 6,
      color: "lightgreen",
      class: "d-none d-lg-inline-block",
      component: "leftSidebar",
      overflow: "normal"
    },
    // {text: 'Footer', cols: 12, rows: 1, color: 'lightpink', component: 'footer'},
  ];
  screenHeight: any;
  screenWidth: any;
  cols = 25;
  private showRightPanel$;
  private appointments$;

  constructor(
    private store: Store<fromRoot.State>, 
    private diary: DiaryService, 
    private route: ActivatedRoute) { }

  setMobileView(windowWidth: number): void {
    this.mobileView = windowWidth <= 1000;
    console.log("mobile view: ", this.mobileView)
  }

  ngOnInit() {
    console.log("[diary] ngOnInit() in diary.component");
    this.setMobileView(window.innerWidth);

    this.route.params.subscribe((res)=>{
      console.log(res);
    })
    this.showRightPanel$ = this.store.select(fromRoot.getCurrentDiaryShowRightPanel).subscribe(data => {
      console.log("[sidebar] showright",data);
      if (data) {
        this.mainPanel.cols = 20;

        this.tiles = [this.mainPanel, this.rightPanel]
      } else {
        this.mainPanel.cols = 25;
        this.tiles = [this.mainPanel]
      }
    });
    this.diary.getAppointmentsFromServer();

    if (!this.appointments$){
      const source = timer(5000, 15000 );

      this.appointments$ = source.subscribe(res=>{
        this.diary.getAppointmentsFromServer(); 
      });
      
      
      /*= setInterval(()=>{ 
        console.log("[apps] scheduled fetch happens below VVV");
        this.diary.getAppointmentsFromServer(); 
      }, 15000 );
    */
    }

    this.diary.forceAppointmentEmit();

  }

  ngOnDestroy() {
    this.showRightPanel$.unsubscribe();
    if (this.appointments$) {
//      clearInterval(this.appointments$);
      this.appointments$.unsubscribe();
    }
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?) {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
    this.setMobileView(event.target.innerWidth);
  }

  addCol() {
    this.tiles[0].cols++;
    this.tiles[1].cols--;
  }

  removeCol() {
    this.tiles[0].cols--;
    this.tiles[1].cols++;
  }
}
