import { Component, SimpleChanges, Input, Output, EventEmitter, OnInit, OnChanges } from "@angular/core";
import { ProcessedAppointment } from "app/models/appointments";


import { AppointmentsStatusService } from "app/services/diary/appointment-status.service";
@Component({
    selector: "appointment-set-status",
    templateUrl: "./appointment-set-status.component.html",
    styleUrls: ["./appointment-set-status.component.scss"],
})


export class AppointmentSetStatusComponent implements OnInit, OnChanges{
    @Input() styleType = "context-list";
    @Input() appointment: ProcessedAppointment;
    @Input() action: string;
    @Input() toStatus: string;
    @Input() icon: string;
    @Output() clicked = new EventEmitter<string>();

    public colour: string;

    constructor(
        private appointments: AppointmentsStatusService) { }

    ngOnInit(): void { }

    ngOnChanges(changes: SimpleChanges): void {
        this.colour = this.appointments.getColour(this.toStatus);
    }

    setStatus() {
       console.log("[status]", this.appointment, this.toStatus );
       this.appointments.setStatus(this.appointment, this.toStatus);
       this.clicked.emit(this.toStatus);
    }
}
