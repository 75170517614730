export interface PurchaseOrder {
    idx?: number;
    accountIdx?: number;
    accountName?: string;
    amountPaid?: number;
    items?: PurchaseOrderItem[];
    clinicIdx?: number;
    dateAdded?: any;
    notes?: string;
    received?: boolean;
    paymentMethod?: any;
    timePaid?: string;
    commission?: boolean;
    voidedAt?: string

}

export const NewPurchaseOrder: PurchaseOrder = {
    idx: 0,
    accountIdx: 0,
    accountName: "",
    amountPaid: 0,
    items: [],
    clinicIdx: 0,
    dateAdded: null,
    notes: "",
    received: false,
    paymentMethod: null,
    timePaid: "",
    commission: false,
    voidedAt: ""
};

export interface PurchaseOrderItem {
    idx?: number;
    itemIdx?: number;
    pervailVATRate?: any;
    qty?: number;
    qtyReceived?: number;
    qtyToReceive?: number;
    notes?: string;
    saleFreeText?: string;
    value?: number;
    PandLSubCatIdx?: number;
}

export const InitialPurchaseOrderItem = {
    itemIdx: -1,
    pervailVATRate: 0,
    qty: 1,
    qtyReceived: 0,
    qtyToReceive: 0,
    notes: null,
    saleFreeText: "",
    value: 0,
    PandLSubCatIdx: -1
};
