import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { DataService } from './data.service';

@Injectable({
  providedIn: 'root'
})
export class AccountsService {

  constructor(private http: DataService) { }

  getAccounts(){
    return this.http.post('/report/2/data',{"params":{"startRow":0,"endRow":1000,"sortModel":[],"filterModel":{}}})
  //   .pipe(map((res: any) => {
  //     return {
  //       id: res.rowId,
  //       name: res.field_5
  //     }
  //   }))
  // }
  }
}
