import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { SignatureTypes, Signatures } from 'app/models/signatures';
import { SignatureService } from 'app/services/notes/signature.service';
import { NotificationService } from 'app/services/notification.service';
import { Subscription, filter } from 'rxjs';


@Component({
    selector: 'app-signature',
    templateUrl: './signature.component.html',
    styleUrls: ['./signature.component.scss']
})
export class SignatureComponent implements OnInit, OnDestroy, OnChanges{
    @Input({required: true}) notesIdx;
    @Input({required: true}) recordIdx;
    @Input({required: true}) formName;
    @Input() lockable: boolean = true;
    @Input() requestLock: boolean = false;

    @Output() onLocked: EventEmitter<any> = new EventEmitter();

    SignatureTypes = SignatureTypes;
    
    public signatures;
    public locked: boolean = false;
    public showSignOff: boolean = false;
    public signOffError: string;
    public signatures$: Subscription;
    public signaturesCount: number = 0;

    constructor(private signatureService: SignatureService, private notifications: NotificationService) { }

    ngOnInit(): void {
        this.getSignatures()
    }

    getSignatures() {
        if (this.signatures$){
            return;
        }
        this.signatures$ = this.signatureService.signaturesByNotesIdx$.pipe(filter(f=>(f && f.notesIdx && f.notesIdx>0))).subscribe(res => {

            if (res && this.signatures && 
                res.signatures && 
                this.signatures.signatures && 
                res.signatures[0] && 
                this.signatures.signatures[0] && 
                this.signatures.signatures[0].idx == res.signatures[0].idx){
                    return; //its the same dont update
            }
            
            this.signaturesCount = 0;
            this.signatures = {
                notesIdx: res.notesIdx,
                signatures: res.signatures.filter(f=> {
                    console.log("[sigs] ??", f)
                    if (f.notesTable){
                        return (this.formName==f.notesTable  && f.recordIdx == this.recordIdx);
                    }
                    return (f.recordIdx == this.recordIdx);
                })
            };
            

            console.log(`[sigs] record id = ${this.recordIdx} form=${this.formName}`, this.signatures.signatures);
            this.signatures.signatures.forEach(sign => {
                if (sign.recordIdx == this.recordIdx) {
                    this.signaturesCount++
                }
            })
            //console.log(this.signatures);

            this.checkLocked();
        })
    }

    ngOnChanges(changes: SimpleChanges) {
        console.log("[sig change]", changes);
        
        if (this.notesIdx) {
            // this.getSignatures();
            this.getSignatures();
        }

        if (changes.requestLock && this.requestLock===true){
            console.log("[sign] we have been asked to lock up", this.recordIdx);
        }

    }

    ngOnDestroy(){
        this.signatures$.unsubscribe();
    }

    setSignatures(res) {
        this.signatures = res;
        console.log("[sign] [locked]", res);
        this.checkLocked();
    }

    checkLocked(){
        this.locked = this.signatureService.isLocked(this.signatures);
   //     console.log("[sign] [locked] onLocked emits", this.locked);
        this.onLocked.emit(this.locked);
    }

    onSign(e) {
        console.log("[sigs] adding sig to ", this.recordIdx);
        this.signatureService.addSignature(this.notesIdx, this.recordIdx, this.formName, e.pinCode, e.locked, e.userIdx, e.comment, e.signatureTitle).subscribe((res: any) => {
            if (res && !res.error) {
                this.displaySuccess();
                this.setSignatures(res);
                this.showSignOff = false;
                this.signOffError = null;
            } else {
                this.displayFailure(res.error);
                this.signOffError = res.error;
            }
           
        });
    }

    onRequestChanges(e) {
        this.signatureService.requestChange(this.notesIdx, this.recordIdx, this.formName, e.comment).subscribe((res: any) => {
            this.setSignatures(res);
        });
    }

    onLock(locked) {
        console.log("[sigs] adding lock to ", this.recordIdx);
        this.signatureService.lock(this.notesIdx, this.recordIdx, this.formName, locked, 0, "").subscribe((res: any) => {
            this.setSignatures(res);
        });
    }

    public displaySuccess(): void {
        this.notifications.send("You have successfully signed");
    }

    public displayFailure(errorMessage: string): void {
        this.notifications.send(errorMessage);
    }


}