import { Injectable } from '@angular/core';
import {
    Actions,
    ofType,
    createEffect
} from '@ngrx/effects';

import { mergeMap, map } from 'rxjs/operators';
import * as saleItemsActions from '../actions/saleItems';
import { DataService } from '../../services/data.service';
import { SaleItem } from '../../models/saleItems';

@Injectable()
export class SaleItemsEffects{
    constructor(private http: DataService, private actions$: Actions) { }

    // ngrxOnInitEffects(): Action {
    //     return { type: saleItemsActions.ActionTypes.INIT };
    // }

    initSaleItems$ = createEffect(() => {
        return this.actions$
            .pipe(
                ofType(saleItemsActions.ActionTypes.INIT),
                mergeMap(() => this.http.get<SaleItem[]>('/sale-items')
                    .pipe(
                        map(staff => (new saleItemsActions.SetAction(staff)))
                ))
        );
    });
}
