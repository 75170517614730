<div
  class="sub-menu-wrapper"
  (mouseenter)="subMenuShown = true"
  (mouseleave)="subMenuShown = false"
>
  <!-- <a mat-button class='mat-button'  mat-button [matMenuTriggerFor]="appMenu" >{{menuItem.title}}</a> -->

  <!-- no sub items-->
  <button *ngIf="menuItem.subItems && !menuItem.subItems.length; else subMenu" 
    [routerLink]="menuItem.link" 
    class="no-subs space-letters w-100"
    mat-button>
    {{ menuItem.title}}
  </button>
  

  <!-- sub items -->
  <ng-template #subMenu>
    <button mat-button [matMenuTriggerFor]="appMenu" class="space-letters">
      {{ menuItem.title}}
      <mat-icon aria-hidden="false" aria-label="Example home icon">arrow_drop_down</mat-icon>
    </button>
    <mat-menu #appMenu="matMenu">
        <ng-template ngFor let-subItem [ngForOf]="displayItems">
            <button          
              (click)="addNewItem(menuItem, subItem)"
              [routerLink]="subItem.link"
              mat-menu-item>
                <strong *ngIf="subItem.favourite ">{{ subItem.title }}</strong>
                <span *ngIf="!subItem.favourite">{{ subItem.title }}</span> 
          
            <!-- <span *ngIf="subItem.favourite && i < 5"><i class="fas fa-star"></i></span> -->
          </button>
          


      </ng-template>
      
      <button
      *ngIf="!showAll && menuItem.subItems.length > TOP_COUNT"
      class="desktop-show-all border-top"
      (mouseenter)="setShowAll($event, true)"
      mat-menu-item
      >
      <strong>Show All </strong>  <span><mat-icon>keyboard_arrow_down</mat-icon></span>
    </button>

    <button
    *ngIf="!showAll && menuItem.subItems.length > TOP_COUNT"
    class="tablet-show-all border-top"
    (click)="setShowAll($event, true)"
    mat-menu-item
    >
    <strong>Show All </strong>  <span><mat-icon>keyboard_arrow_down</mat-icon></span>
  </button>
        
    <button
    *ngIf="showAll"
      (click)="setShowAll($event, false)"
      mat-menu-item
      >
    <strong>Show top 5  </strong> <span><mat-icon>keyboard_arrow_up</mat-icon></span>
    </button>
    </mat-menu>
  </ng-template>
</div>
