import { Component, OnInit } from '@angular/core';
import { VisualsService } from '../../services/visuals.service';

@Component({
    selector: 'app-main-menu',
    templateUrl: './main-menu.component.html',
    styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent implements OnInit {

    public isNewStyleChecked: boolean;
    private icons$;

    constructor(private visual: VisualsService) { }

    public sliderToggled() {
        this.visual.setIconStyle(this.isNewStyleChecked);
    }

    ngOnInit() {
        this.icons$ = this.visual.getIconsStyle().subscribe(res => {this.isNewStyleChecked = res});
    }

    ngOnDestroy() {
        this.icons$.unsubscribe();
    }


}
