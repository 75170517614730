import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { ReportFilter } from 'app/models/user-settings';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import * as fromRoot from '../store/reducers';
import * as fromSettings from '../store/actions/user-settings';
import { DataService } from './data.service';

@Injectable()
export class UserSettingsService {
    constructor(private store: Store<fromRoot.State>, 
        private http: DataService) { }

    get(key: string) {
        return this.http.get(`/settings/${key}`);
    }

    getConfig() {
        return this.store.select(fromRoot.getConfig).pipe(take(1))
    }

    getReportFilters(): Observable<any> {
        return this.store.select(fromRoot.getReportFilters);
    }

    setReportFilters(reportFilter: ReportFilter): void {
        this.store.dispatch(new fromSettings.SetReportFilters(reportFilter));
    }

    getMobileView(): Observable<any> {
        return this.store.select(fromRoot.getMobileView);
    }

    setMobileView(mobileView: boolean): void {
        return this.store.dispatch(new fromSettings.SetMobileView(mobileView));
    }


    set(key: string, val: any){
        return this.http.post(`/settings/${key}`, {data: val});
    }

}

