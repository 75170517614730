import { Component } from '@angular/core';
import { NotesBuilderBaseComponent } from 'app/notes/notes-builder/notes-builder-base/notes-builder-base-component';
import { MskhqQuestions } from '../models/mskhq';

@Component({
  selector: 'app-mskhq',
  templateUrl: './mskhq.component.html',
  styleUrls: ['./mskhq.component.scss']
})
export class MskhqComponent extends NotesBuilderBaseComponent {
  MskhqQuestions = MskhqQuestions
}
