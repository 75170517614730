<div [id]='formName' *ngIf="form">
    <form autocomplete="off" #formEle="ngForm">
      <mat-form-field>
        <mat-label>Diagnosis</mat-label>
          <textarea 
          matInput 
          cdkTextareaAutosize
          cdkAutosizeMinRows="1"
          cdkAutosizeMaxRows="12"
          [(ngModel)]="form.clinicaldiagnosis" 
          name="diagDiag" 
          ></textarea>
        </mat-form-field>
      
      <mat-form-field>
        <mat-label>Notes</mat-label>
        <textarea  
        matInput 
        cdkTextareaAutosize
        cdkAutosizeMinRows="1"
        cdkAutosizeMaxRows="12"
        [(ngModel)]="form.diagnosis" 
        name="diagNotes" 
        ></textarea>
      </mat-form-field>
      
      <mat-form-field>
        <mat-label>Plan Of Management</mat-label>
        <textarea 
        matInput 
        cdkTextareaAutosize
        cdkAutosizeMinRows="1"
        cdkAutosizeMaxRows="12"
        [(ngModel)]="form.planofmanagement" 
        name="diagManagement" 
        ></textarea>
      </mat-form-field>

      <mat-form-field>
        <mat-label>Red Flags</mat-label>
        <textarea 
        matInput 
        cdkTextareaAutosize
        cdkAutosizeMinRows="1"
        cdkAutosizeMaxRows="12"
        [(ngModel)]="form.redflags" 
        name="diagRedFlags" 
        ></textarea>
      </mat-form-field>

    </form>
  </div>