import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { SaleItem } from "../models/saleItems";
import { DataService } from "app/services/data.service";

@Injectable({
  providedIn: "root",
})
export class XZReportsService {
  constructor(private http: DataService) {}

  getReportFromServer(reportType, userID, zReportIdx): Observable<any> {
    return this.http.get<any[]>(`/xz-report/${reportType}/${userID}/${zReportIdx}`);
  }

  getTillsFromServer(): Observable<any> {
    return this.http.get<any>(`/xz-report`);
  }
}
