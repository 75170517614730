import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogsModule } from 'app/dialogs/dialogs.module';
import { MyMaterialModule } from 'app/material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LetterTemplateSelectComponent } from './letter-template-select/letter-template-select.component';
import { LetterWriterComponent } from './letter-writer/letter-writer.component';
import { LettersService } from 'app/services/notes/letters.service';
import { RouterModule } from '@angular/router';
import { LettersAppRoutes } from './letters.routes';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { NewLetterTemplateComponent } from './new-letter-template/new-letter-template.component';
import { LettersReplacementService } from 'app/services/notes/letters-replacement.service';
import { TemplateKeywordGuideModule } from 'app/settings/template-keyword-guide/template-keyword-guide.module'
@NgModule({
    imports: [   
        AngularEditorModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MyMaterialModule,
        DialogsModule,
        RouterModule.forChild(LettersAppRoutes),
        TemplateKeywordGuideModule
    ],
    declarations: [
        NewLetterTemplateComponent,
        LetterWriterComponent,
        LetterTemplateSelectComponent,
    ],
    providers: [  
        LettersService,
        LettersReplacementService
    ],
    exports: [
        LetterWriterComponent
    ]
})
export class LettersModule {}
