<mat-form-field>
    <mat-label>{{label}}</mat-label>
    <mat-select 
        [(ngModel)]="value" 
        [disabled]="isDisabled">
        <mat-option value="-1">--</mat-option>
        <mat-option *ngFor="let option of muscleNerveOptions" [value]="option">
            {{option}}
        </mat-option>
    </mat-select>
</mat-form-field>