import { AfterViewInit, Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatListOption, MatSelectionList } from '@angular/material/list';
import { QuestionService } from 'app/dynamic-form/services/question.service';
import { Setting } from 'app/models/site-settings';
import { LoadingService } from 'app/services/loading.service';
import { NavigationService } from 'app/services/navigation.service';
import { SiteSettingsService } from 'app/services/site-settings.service';
import { ValueAccessorBase } from '../value-accessor-base';

@Component({
    selector: 'app-forms-list-chooser',
    templateUrl: './forms-list-chooser.component.html',
    styleUrls: ['./forms-list-chooser.component.scss'],
    providers: [
      {
        provide: NG_VALUE_ACCESSOR,
        useExisting: FormsListChooserComponent,
        multi: true,
      }]
  })
  export class FormsListChooserComponent  extends ValueAccessorBase<any> implements OnInit, AfterViewInit {

    questionnaireSubforms: any;
    saving: boolean;
    @ViewChild('subformsList') subformsList: MatSelectionList;
    @Output('change') change = new EventEmitter<any>();
    selectedAll = false

    constructor(
      private qs: QuestionService,
      private loading: LoadingService,
      private settings: SiteSettingsService,
      private navigation: NavigationService
    ) {
      super();
    }

    ngOnInit(): void {
        this.getQuestionaireSubforms();
    }

    ngAfterViewInit() {
      //console.log("LOAD!");
      //this.load();
    }

    private getQuestionaireSubforms() {
      this.loading.start();
      this.qs.getQuestions('patientquestionaire').subscribe(form => {
        console.log("[QuestionnaireSubforms] =>", form.questions);
        this.questionnaireSubforms = form.questions;
        this.loading.stop();
        this.load();
      });
    }


    onGroupsChange(options: MatListOption[]) {
      // map these MatListOptions to their values
      this.updateValue(options.filter(o => o.value.idx).map(o => o.value)); // filter out select.all
    }

  public selectAll(e) {
    if (this.selectedAll) {
      this.subformsList.deselectAll();
    } else {
      this.subformsList.selectAll();
    }
    this.selectedAll = !this.selectedAll
  }

    save() {
      this.settings.set(Setting.ONLINE_FORMS, this.value).subscribe(res=>{
        console.log(res);
      });
    }

    cancel() {
      this.navigation.back();
    }

    updateValue(v){
      this.value = v;
      this.change.emit(v);
    }
    /**
     * sdsd
     * @param selected the options that are selected
     * Matches the selected options to what is shown.
     */
    matchOptions(selected){
      console.log("match options", selected);
      this.subformsList.deselectAll();  // deselect everything 
      const options = this.subformsList.options.toArray();
      for (const option of options ) {
        const found = selected.find(s => {
          return (s.idx === option.value.idx);
        });
        if (found) { // toggle back to selected if found.
          option.toggle();
        }
      }
      this.updateValue(selected);
    }

    load() {
      this.settings.get(Setting.ONLINE_FORMS).subscribe(res=>{
        if (res) {
          this.matchOptions(res);
        }
      });
    }

  }
