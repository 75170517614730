import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AgGridAngular } from 'ag-grid-angular';
import { CellClickedEvent, ColDef, GridReadyEvent } from 'ag-grid-community';
import { Observable, Subscription } from 'rxjs';
import { EmailTemplatesService, Template } from "../email-templates.service";


@Component({
  selector: 'app-email-templates',
  templateUrl: './email-templates.component.html',
  styleUrls: ['./email-templates.component.scss']
})
export class EmailTemplatesComponent implements OnInit, OnDestroy {
  public templates: any;
  public rowData$!: Observable<any[]>;
  public gridOptions;
  emailsSubscription$: Subscription
  @ViewChild(AgGridAngular) agGrid!: AgGridAngular;

  public columnDefs: ColDef[] = [
    { field: 'idx'},
    { field: 'title'},
    { field: 'subject'},
  ];

  public defaultColDef: ColDef = {
    sortable: true,
    filter: true,
    resizable: true
  };

  constructor(
    private email: EmailTemplatesService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.fetchEmailTemplates();
    this.gridOptions = {
      rowHeight: 50
    }
  }

  private fetchEmailTemplates(): void {
    this.emailsSubscription$ = this.email.listTemplates().subscribe((res) => {
      this.templates = res;
      this.rowData$ = this.templates
    })
  }

  onGridReady(params: GridReadyEvent) {
    params.api.sizeColumnsToFit();
  }

  onCellClicked(e: CellClickedEvent): void {
    this.router.navigate([e.data.title], {relativeTo: this.activatedRoute});
  }

  ngOnDestroy(): void {
    this.emailsSubscription$?.unsubscribe()
  }

}
