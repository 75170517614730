import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { DialogService } from 'app/services/dialog/dialog.service';
import { PinComponent } from 'app/dialogs/pin/pin.component';
import { SignatureService } from 'app/services/notes/signature.service';
import { SignatureTypes } from 'app/models/signatures';
import { Router } from '@angular/router';
import { AuthService } from 'app/services/auth.service';
import { DiaryService } from 'app/services/diary/diary.service';
import { StaffMember } from 'app/models/staff';
@Component({
  selector: 'app-signing',
  templateUrl: './signing.component.html',
  styleUrls: ['./signing.component.scss']
})
export class SigningComponent implements OnInit, OnChanges {
  //  @Input("notesIdx") notesIdx: number;
  //  @Input("recordIdx") recordIdx: number = 0;
  //  @Input("signatureType") signatureType: string;
  @Input("signatures") signatures: any;
  @Input("lockable") locakable: boolean = true;

  @Output() onSign: EventEmitter<any> = new EventEmitter();
  @Output() onLock: EventEmitter<boolean> = new EventEmitter();
  @Output() onRequestChanges: EventEmitter<any> = new EventEmitter();

  public changeRequested: boolean = false;
  /*
    public userHasPinCode: boolean = false;
    public userGroupName: string;
    public userSupervisor: boolean = false;
  */
  public comment: string = "";
  public locked: boolean;

  public supervisors: any[] = [];
  public signature: any[] = [];
  public selectedSupervisorIdx: number = 0;
  public supervisorsNum: number;
  public isSupervisor: boolean;
  public signatureTypeDetails: any;
  public loggedInStaff: any;

  constructor(public signatureService: SignatureService,
    private dialogService: DialogService,
    private router: Router,
    private auth: AuthService,
    private diary: DiaryService
  ) { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['signatures']) {
      this.loadSignature();
    }
  }

  ngOnInit(): void {
    this.supervisors = this.signatureService.supervisors;
    this.isSupervisor = this.auth.supervisor();
    this.loggedInStaff = this.diary.allStaff.find((staff: StaffMember) => staff.idx === this.auth.staffId());
    console.log(this.supervisors, 'please be this', this.loggedInStaff)
  }


  private loadSignature(): void {
    if (!this.signatures) {
      return;
    }
    this.signature = this.signatures.signatures;

    if (this.signature.length) {
      this.changeRequested = (this.signature[0].change_requested && this.signature[0].change_requested > 0) ? true : false;
    }

    this.locked = this.signatureService.isLocked(this.signatures);

  }

  public unLock() {
    //supervisor unlock request
    if (this.isSupervisor || this.selectedSupervisorIdx > 0) {
      this.lock(false);
      return;
    }

    //then this is a student unlock request
    const saveComment = "" + this.comment; //copy it so we can bin it off
    this.comment = "";
    this.onRequestChanges.emit({ comment: saveComment });
  }

  public lock(lock: boolean) {
    if (this.isSupervisor || this.selectedSupervisorIdx==0) { //signing as ourseleves and we are supervisor, or as student
      this.onLock.emit(lock);
      return;
    }
    this.dialogService.bespokeDialog("", PinComponent, "pin", "Enter your PIN").then((pinCode: string) => {
      if (pinCode) {
        this.onLock.emit(lock);
      }
    })
  }

  public openPinPanel(sign: boolean): void {
    const saveComment = "" + this.comment; //copy it so we can bin it off
    

    let signatureTitle = SignatureTypes.SignOff;
    if (!sign) {
      signatureTitle = SignatureTypes.RFI;
    }
    
    if (this.isSupervisor || (this.selectedSupervisorIdx==0 && !sign) ) { //signing as ourseleves and we are supervisor, OR commenting as student.
      this.comment = "";
      this.onSign.emit(
        {
          pinCode: null,
          comment: saveComment,
          userIdx: null,
          signatureTitle: signatureTitle,
          locked: sign, //is this a RFI or a sign off
        });
      return;
    }

    const userIdx = this.selectedSupervisorIdx;
    this.dialogService.bespokeDialog("", PinComponent, "pin", "Enter your PIN").then((pinCode: string) => {
      console.log("[pin] CLOSES", pinCode);
      if (pinCode) {
        this.comment = "";
        this.onSign.emit(
          {
            pinCode: pinCode,
            comment: saveComment,
            userIdx: userIdx,
            signatureTitle: signatureTitle,
            locked: sign //is this a RFI or a sign off
          });
      }
    })
  }

  // public addComment() {
  //   const saveComment = "" + this.comment; // Copy it so we can save it

  //   const supervisorIdxToNum = {};

  //   const supervisorIdx = this.selectedSupervisorIdx;
  //   const supervisorNum = supervisorIdxToNum[supervisorIdx];
  //   // If we aren't the current logged in user will ask the selected supervisor for their pin to sign off
  //   if (supervisorNum != this.loggedInStaff.idx && this.selectedSupervisorIdx != 0) {
  //     this.dialogService.bespokeDialog("", PinComponent, "pin", "Enter your PIN").then((pinCode: string) => {
  //       if (pinCode) {
  //         this.comment = "";
  //         this.onSign.emit({
  //           pinCode: pinCode,
  //           comment: saveComment,
  //           userIdx: this.selectedSupervisorIdx,
  //           signatureTitle: SignatureTypes.comment,
  //           locked: this.locked
  //         });
  //       }
  //     });
  //   } else {
  //     // If we are not selecting a different user than the one logged in we can just add comments
  //     this.onSign.emit({
  //       pinCode: null,
  //       comment: saveComment,
  //       userIdx: this.selectedSupervisorIdx,
  //       signatureTitle: SignatureTypes.comment,
  //       locked: this.locked
  //     });
  //   }
  // }
  

  setPinCode() {
    this.router.navigate(['profile']);
  }



}
