import { Injectable } from '@angular/core';
import { Dexie } from "dexie";


@Injectable({
  providedIn: 'root'
})
export class LocalDBService extends Dexie {

  constructor(
  ) {
    super("AppointmentDatabase");

    /**
     * Remember to increase version if any changes are made to schema
     */
    this.version(7)
      .stores({
        AppointmentTable: "idx, dayNumber, staffIdx",
        PatientInfoTable: "idx"
      })
      .upgrade(trans => {
        console.log("[db] is upgrading");
        this.delete().then(() => {
          this.open();
        });
      });

  }
}
