<div class="pageContainer"></div>
<div class="row margin-collapse">
    <div class="col-lg-10">

        <h2>Treatments</h2>


        <div [id]="treatmentCat.name" *ngFor="let treatmentCat of treatments" class="mt-3">
            <mat-card><mat-card-content>

                    <mat-form-field>
                        <mat-label *ngIf="!treatmentCat.name">New Treatment</mat-label>
                        <input [(ngModel)]="treatmentCat.name" name="catName" matInput>
                    </mat-form-field>
                    <mat-accordion class="example-headers-align" [multi]="true">
                        <mat-expansion-panel *ngFor="let treatment of treatmentCat.treatments">
                            <mat-expansion-panel-header>
                                <mat-panel-title>
                                    {{treatment.name ? treatment.name : "(+) Add New"}}
                                </mat-panel-title>
                                <mat-panel-description>
                                </mat-panel-description>
                            </mat-expansion-panel-header>
                            <div class="row">
                                <div class="col col-sm-auto">
                                    <mat-form-field>
                                        <mat-label>Name </mat-label>
                                        <input [(ngModel)]="treatment.name" name="treatmentName" matInput>
                                    </mat-form-field>
                                </div>
                                <div class="col col-sm-auto">
                                    <mat-form-field>
                                        <mat-label>Price</mat-label>
                                        <input [(ngModel)]="treatment.defaultPrice" name="defaultPrice" matInput>
                                    </mat-form-field>
                                </div>

                                <div class="col col-sm-auto">
                                    <mat-form-field>
                                        <mat-label>Duration/mins</mat-label>
                                        <input [(ngModel)]="treatment.defaultDuration" name="defaultDuration" matInput>
                                    </mat-form-field>
                                </div>
                                <div class="col col-sm-auto">
                                    <mat-form-field>
                                        <mat-label>Colour</mat-label>
                                        <input [(ngModel)]="treatment.colour" type="color" name="tColour" matInput>
                                    </mat-form-field>
                                </div>


                            </div>
                            <div class="row">
                                <div class="col col-sm-auto">
                                    <mat-form-field>
                                        <mat-label>LNC Charge</mat-label>
                                        <input type="number" min="1" max="99" maxlength="2"
                                            [(ngModel)]="treatment.LNCPrice" name="LNCPrice" matInput>
                                    </mat-form-field>
                                </div>
                                <div class="col col-sm-auto">
                                    <mat-form-field>
                                        <mat-label>DNA Charge</mat-label>
                                        <input type="number" [(ngModel)]="treatment.DNAPrice" name="DNAPrice" matInput>
                                    </mat-form-field>
                                </div>
                                <div class="col col-sm-auto">
                                    <mat-form-field>
                                        <mat-label>Credit Value</mat-label>
                                        <input [(ngModel)]="treatment.credits" name="credits" matInput>
                                    </mat-form-field>
                                </div>
                                <div class="col col-sm-auto">
                                    <mat-form-field>
                                        <mat-label>Group Size</mat-label>
                                        <input type="number" min="1" max="99" maxlength="2"
                                            [(ngModel)]="treatment.groupSize" name="credits" matInput>
                                    </mat-form-field>
                                </div>




                            </div>
                            <div class="row">
                                <div class="col col-sm-3 online">
                                    <mat-slide-toggle [(ngModel)]="treatment.canBookOnline">Available
                                        Online</mat-slide-toggle>
                                </div>
                                <div class="col col-sm-3 online">
                                    <mat-form-field>
                                        <mat-label>Pre-payment</mat-label>
                                        <mat-select [(ngModel)]="treatment.requirePrePayment" name="requirePrePayment">
                                            <mat-option value="no">
                                                Not Requested
                                            </mat-option>
                                            <mat-option value="required">
                                                Required
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>


                                   
                                </div>
                                <div class="col col-sm-3"></div>
                                <div class="col col-sm-3">
                                    <button (click)="deleteTreatment(treatmentCat, treatment)" mat-button>
                                        <mat-icon *ngIf="treatmentCat.name">delete</mat-icon>Delete
                                    </button>
                                </div>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>
                </mat-card-content></mat-card>
        </div>

    </div>

    <div class="col-lg-2 mobile-margin">
        <div class="fix-top">
            <ul class="list-sections">
                <li *ngFor="let treatmentCat of treatments">
                    <button class="btn btn-link" (click)="scroll(treatmentCat.name)">
                        {{ treatmentCat.name }}
                    </button>
                </li>
            </ul>
            <div class="desktop-buttons">
                <app-submit-cancel (onSave)="saveTreatments()" (onCancel)="onCancel()"></app-submit-cancel>
            </div>
        </div>
    </div>
</div>