<mat-card>
    <mat-card-content>
        <mat-form-field>
            <mat-label>Associated Case</mat-label>
            <mat-select name='diagnosisIdx' [(ngModel)]="diagnosisIdx"
                (selectionChange)="updateDiagnosis()">
                <mat-option *ngFor="let ch of caseHistories" [value]="ch.idx">
                    {{ch.dateUpdated | date: 'short' }} - {{ ch.description}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <form autocomplete="off" class="mb-2">
            <table class="diagnosis-data">
                <thead>
                    <tr>
                        <th>Diagnosis</th>
                        <th>Notes</th>
                        <th>Plan of management</th>
                        <th>Red flags</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td class="table-5">
                            {{ diagnosisData?.clinicaldiagnosis }}
                        </td>
                        <td class="table-5">
                            {{ diagnosisData?.diagnosis }}
                        </td>
                        <td class="table-5">
                            {{ diagnosisData?.planofmanagement }}
                        </td>
                        <td class="table-5">
                            {{ diagnosisData?.redflags }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </form>

    </mat-card-content>

</mat-card>
