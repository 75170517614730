import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ElementRef,
  ViewChild,
  Input,
  Inject,
  OnDestroy
} from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AppointmentsService } from "../services/diary/appointments.service";
import { PatientChooserService } from "../services/patient.service";
import { SaleItemsService } from "../services/sale-items.service";
import { PaymentMethodsService } from "../services/payment-methods.service";
import { SaleItem } from "../models/saleItems";
import { combineLatest, Observable, Subscription, throwError } from "rxjs";
import { map, startWith, take, takeUntil } from "rxjs/operators";
import { UntypedFormControl } from "@angular/forms";
import { MatAutocompleteSelectedEvent } from "@angular/material/autocomplete";
import { GiftVoucherPayment, PaymentMethod } from "../models/payment-methods";
import { TillService } from "./till.service";
import { CurrencyPipe } from "@angular/common";
import { initialPatientChooserResult, initialPatientDetails, type PatientChooserResult, type PatientDetails } from "app/models/patient-details";
import { ClinicService } from "app/services/clinic.service";
import { Clinic, initialClinic } from "app/models/clinics";
import { MatAutocompleteTrigger } from "@angular/material/autocomplete";
import { MatDialog } from "@angular/material/dialog";
import { TillPromptDialogComponent } from "./till-prompt-dialog/till-prompt-dialog.component";
import { GiftCardService } from "app/view-all-gift-cards/giftcard.service";
import { NotificationService } from "app/services/notification.service";
import { DialogService } from "../services/dialog/dialog.service";
import { returnTypes } from "app/models/dialog";
import { Store } from "@ngrx/store";
import * as fromRoot from "../store/reducers"
import * as patientActions from "../store/actions/patient"
import { InvoiceService } from "app/finance/invoice/invoice.service";
import { NavigationService } from "app/services/navigation.service";
import { LoadingService } from "app/services/loading.service";
import { InsuranceDetails } from "app/models/insurance-details.model";
import { AppointmentStatus } from "app/models/diary";

interface Item {
  idx: number;
  name: string;
  price: number;
  qty: number;
  type: string; // item | treatment
  notes: string;
  staffIdx: number;
  pervailVAT: number;
  treatmentTypeIdx?: number;
  freeText: string;
  pervailComm: number;
  creditValue?: number;
}

interface Payments {
  paymentTypeIdx: number;
  name: string;
  amount: number;
  voucherIdx?: number;
  creditIdx?: number;
  accountIdx?: number;
}

const TREATMENT = "treatment";
const SALE_ITEM = "sale";

const PAYMENT_CREDITS = -2;
const PAYMENT_INTENT = -4;

@Component({
  selector: "app-till",
  templateUrl: "./till.component.html",
  styleUrls: ["./till.component.scss"],
  providers: [CurrencyPipe],
})
export class TillComponent implements OnInit, OnDestroy {
  @ViewChild("inputElement", { static: false }) inputEle: ElementRef;
  @ViewChild("resultsList", { static: false }) resultsEle: ElementRef;
  @Input() id;

  readonly ItemType = { TREATMENT: TREATMENT, SALE_ITEM: SALE_ITEM };

  public appointmentIdx: number;
  public items: Item[] = [];
  public newItem: Item;
  public _totalPrice: number = 0;
  //public paymentAmount: number;
  public _outstanding: number = 0;
  public account = { accountTo: 0, balance: 0 };
  public patientInfo: PatientChooserResult;
  public patientInfoFull: PatientDetails;
  public saleItems$;
  public paymentMethods: { actual: PaymentMethod[], voucher: PaymentMethod[], insurance: PaymentMethod[] } = { actual: [], voucher: [], insurance: [] };
  public saleProducts: SaleItem[] = [];
  public filteredSaleProducts: Observable<SaleItem[]>;
  public myControl: UntypedFormControl;
  public payments: Payments[] = [];
  public patientIdx: number;
  public clinic: Clinic;
  public clinicFromTreatment = false;
  public clinics: Clinic[];
  public clinicIdx: number;
  public accountingDate = new Date();
  private change = 0;
  matchedInsurers: InsuranceDetails[];

  private lastMethod: PaymentMethod;
  payingBalance: boolean = false;
  inputValue: string;
  private inputUpdateTimeout;
  foundValues: PatientChooserResult[];
  selectListShown: boolean;
  resultsListPos: any;
  showPatientName: string = "";
  patientChooser: UntypedFormControl;
  private getPatient$;
  selectedPatient: PatientChooserResult;
  private unmatchedInsurance: any;
  private uninsuredItems: any[];
  private staffInsurance$;
  private patientInsurance$;
  private insuranceUsed = false;
  clinicComplete: boolean = false;
  patientComplete: boolean = false;
  formComplete: boolean = true;
  formSubmitted: boolean = false;
  confirmResult: boolean = false;
  clinicSet: boolean = false;
  giftcardValidated = false;
  errorMessage: string;
  balance: number = 0;
  isRefund: boolean = false;
  public refundsTxn: number;

  public otherUnpaidAppointments = [];
  private authCode;
  public showGiftCardPrompt = false;
  private giftVoucherPaymentMethod: PaymentMethod;
  public hasInsurance = false;
  public creditCost;
  public processing = false;
  public recoveredTransaction: boolean = false;
  public recoverTransactionIdx: number = null;
  public resetAfterRefund: boolean = false;
  public appointmentInFuture: string = null;
  private subscriptions$: Subscription[] = [];
  private _paymentAmountPounds: string;
  public creditsDetails: any;
  public treatmentFromCat: number;

  public paymentIntent : {intentId: string, amount: number};

  @ViewChild(MatAutocompleteTrigger) inputDropdown;
  promptResponse: any;

  get paymentAmountPounds() {
    //return (this.paymentAmount / 100).toFixed(2).toString();
    return this._paymentAmountPounds;
  }

  set paymentAmountPounds(amm: string) {
    this._paymentAmountPounds = amm;
  }

  get paymentAmount() {
    return parseFloat(this._paymentAmountPounds) * 10 * 10
  }

  set paymentAmount(amm) {
    this.paymentAmountPounds = (amm / 100).toFixed(2).toString();
  }


  get outstanding() {
    return this._outstanding;
  }

  set outstanding(v) {
    this._outstanding = v; //this.wholeValuesOnly(v);
  }

  get totalPrice() {
    return this._totalPrice;
  }

  set totalPrice(v) {
    this._totalPrice = v; //this.wholeValuesOnly(v);
  }

  constructor(
    private route: ActivatedRoute,
    private appointment: AppointmentsService,
    private patient: PatientChooserService,
    private saleItemsService: SaleItemsService,
    private paymentMethodsService: PaymentMethodsService,
    private tillService: TillService,
    private router: Router,
    private clinicService: ClinicService,
    private giftCardService: GiftCardService,
    private notification: NotificationService,
    public dialog: MatDialog,
    private dialogService: DialogService,
    private store: Store<fromRoot.State>,
    private invoiceService: InvoiceService,
    private nav: NavigationService,
    private loading: LoadingService,
  ) {
    this.newItem = {
      idx: 0,
      name: "",
      qty: 1,
      price: 0,
      notes: "",
      type: SALE_ITEM,
      staffIdx: 0,
      pervailVAT: 0,
      freeText: "",
      pervailComm: 0,
    };
    this.myControl = new UntypedFormControl();
    this.selectListShown = false;
    this.selectedPatient = initialPatientChooserResult;
    this.resultsListPos = { left: 0, top: 0, width: 0 };
  }

  ngOnInit() {
    this.route.queryParamMap.subscribe((queryParams) => {
      if (queryParams.get("appointmentIdx")) {
        this.loadAppointment(parseInt(queryParams.get("appointmentIdx")));
      }

      if (queryParams.get("recoverTxn")) {
        this.recoveredTransaction = true;
        this.recoverTransactionIdx = parseInt(queryParams.get("recoverTxn"));
        this.recoverTransaction(this.recoverTransactionIdx);
      }

      if (queryParams.get("reset")) {
        this.resetAfterRefund = true;
      }
      if (queryParams.get("refund")) {
        this.isRefund = true;
        this.refundsTxn = parseInt(queryParams.get("refund"))
        let items = queryParams.get("items").split(',').map(item => Number(item))
        this.processRefund(this.refundsTxn, items);
      }

      this.subscriptions$.push(
        this.patient.getPatient().subscribe(res => {
          if (res && res.idx) {
            this.patientIdx = res.idx;
            //            console.log("[till] [patient] has updated")
            this.patientChanged();
          }
        })
      );

      this.subscriptions$.push(
        this.patient.getPatientInfo().subscribe(res => {
          console.log('[till] pi', res)
          if (res) {
            this.patientInfoFull = res;
            if (res.insurance) this.hasInsurance = true
          }
        })
      );

    });

    this.saleItems$ = this.saleItemsService
      .getStockListFromServer()
      // .getSaleItems()
      .subscribe((res) => {
        this.saleProducts = res;
        this.initiateAutocomplete();
      });

    this.subscriptions$.push(
      this.paymentMethodsService.getPaymentMethods()
        .subscribe(res => {
          this.paymentMethods.actual = res.filter(f => (!f.discountValue && f.name != "Insurance"));
          this.paymentMethods.voucher = res.filter(f => f.discountValue);
          this.paymentMethods.insurance = res.filter(f => (f.name == "Insurance"));
          this.giftVoucherPaymentMethod = res.find(f => (f.name.toLowerCase() == "gift voucher"));
        })
    );



    this.patientChooser = new UntypedFormControl(this.id);
    this.getPatient$ = this.patient.getPatient().subscribe((res) => {
      this.selectedPatient = res;
    });

    this.clinicService.getClinics().subscribe((res) => {
      this.clinics = res;
    });

    this.clinicService.getSelectedClinic().subscribe((res) => {
      if (!res) {
        //        console.warn("no clinic set");
      } else {
        //        console.log("[clinic set]", res);
        this.clinic = this.clinics.find((f) => res.idx == f.idx);
        this.clinicIdx = res.idx;
      }
    });

    if (this.selectedPatient) {
      this.choosePatient(this.selectedPatient);
      this.clinicService.setSelectedClinic(this.clinic);
    }

  }

  ngOnDestroy(): void {
    for (let sub$ of this.subscriptions$) {
      sub$.unsubscribe();
    }
  }

  private setAppointmentInFuture(appointment: any): void {
    const today = new Date();
    const startTime = new Date(appointment.startTime);

    const startTimeinUTC = Date.UTC(startTime.getFullYear(), startTime.getMonth(), startTime.getDate());
    const todayinUTC = Date.UTC(today.getFullYear(), today.getMonth(), today.getDate());
    const differenceInDays = Math.floor((startTimeinUTC - todayinUTC) / (1000 * 60 * 60 * 24));

    if (differenceInDays === 1) {
      this.appointmentInFuture = "tomorrow.";
    } else if (differenceInDays > 1) {
      this.appointmentInFuture = "in " + differenceInDays + " days.";
    }
  }

  openPrompt(title?: string, message?: string) {
    title = title || "Title";
    message = message || `Please type something.`;


    return new Promise((resolve, reject) => {
      const dialogRef = this.dialog.open(TillPromptDialogComponent, {
        maxWidth: "400px",
        data: {
          title: title,
          message: message,
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        //        console.log('The dialog was closed');
        this.promptResponse = result;
        //        console.log(this.promptResponse)
        resolve(this.promptResponse)
      });

    });
  }

  initiateAutocomplete() {
    this.filteredSaleProducts = this.myControl.valueChanges.pipe(
      startWith(""),
      map((value) => this._filter(value))
    );
    this.filteredSaleProducts.subscribe(res => console.log("[till] sale items", res))
  }

  processRefund(txId: number, items: number[]) {
    this.invoiceService.getInvoice(Number(txId)).subscribe((res: any) => {
      this.patientIdx = res.transaction.PatientIdx
      this.patient.setPatient(this.patientIdx)
      this.clinicIdx = res.transaction.ClinicIdx
      this.clinicService.setSelectedClinicById(this.clinicIdx)
      this.clinicService.getSelectedClinic().subscribe(res => {
        this.clinic = res
      })
      let refundItems = res.items.filter(item => items.includes(item.idx))
      this.items = refundItems.map(item => {
        return {
          idx: item.idx,
          name: item.Name,
          qty: item.qty,
          price: (0 - Number(item.value)).toFixed(2),
          notes: item.notes,
          type: item.type,
          staffIdx: item.StaffIdx,
          pervailVAT: Number(item.pervailVATRate),
          freeText: item.saleFreeText,
          pervailComm: Number(item.pervailComm)
        }
      })
      this.payments = res.payments.map(payment => {
        return {
          paymentTypeIdx: payment.MethodIdx,
          name: payment.Method,
          amount: (0 - this.toPence(payment.Amount))
        }
      })
      this.updateTotal()
    })
  }

  showResultsList() {
    this.selectListShown = true;
    const inputPos = offset(this.inputEle.nativeElement);
    this.resultsListPos.width = inputPos.width;
  }

  onBlur() {
    setTimeout(() => {
      this.selectListShown = false;
    }, 250);
  }

  choosePatient(foundValue) {
    console.log("[till] chose patient", foundValue);
    this.patient.setPatient(foundValue.idx);
    this.patientIdx = foundValue.idx;
    this.patient.getPatient().subscribe((res) => {
      this.patientInfo = res;
      //      console.log("PATIENT INFO", this.patientInfo);
      if (res) this.showPatientName = this.patientInfo.name;
    });
  }

  addNewPatient() {
    this.router.navigate([
      "form/patientdetails/0"])
  }

  chooseWalkIn() {
    console.log("what is this?");
    this.patient.getResults("Walk In").subscribe((res) => {
      const walkInObject = res.filter(
        (obj) => obj.name.toLowerCase() === "walk in patient"
      );
      console.log(res, walkInObject);
      this.choosePatient(walkInObject[0]);
    });
  }

  private recoverTransaction(transactionIdx: number): void {
    this.loading.start();

    this.invoiceService.getInvoice(transactionIdx).subscribe((transaction: any) => {
      this.clinicFromTreatment = true;
      this.clinicSet = true;
      this.clinic = { ...initialClinic, name: transaction.clinic[0] };
      this.patientInfo = { name: transaction.patient[0], idx: 0, address: "" };
      this.appointmentIdx = 1;
      this.patientIdx = transaction.transaction.PatientIdx;
      this.patientChanged();

      transaction.items.forEach((item: any) => {
        this.items.push({
          idx: item.idx,
          name: item.Name,
          price: +item.value,
          qty: item.qty,
          type: item.type,
          notes: item.notes,
          staffIdx: item.StaffIdx,
          pervailVAT: +item.pervailVATRate,
          freeText: item.saleFreeText,
          pervailComm: +item.pervailComm,
          creditValue: +item.CreditValue
        });
      });

      this.updateTotal();

      this.clinicService.getClinics().pipe(take(1)).subscribe((clinics: Clinic[]) => {
        this.clinicIdx = clinics.find((clinic: Clinic) => clinic.name === this.clinic.name).idx;
      });

      this.loading.stop();
    })
  }

  private includePriceAndNotes(appointment: any): any {
    if (appointment.status === AppointmentStatus.NOSHOW) {
      appointment.price = appointment.DNAPrice;
      appointment.notes = "DNA Charge";
    }

    if (appointment.status === AppointmentStatus.LNC) {
      appointment.price = appointment.LNCPrice;
      appointment.notes = "Late Notice Cancellation fee";
    }

    return appointment;
  }

  loadAppointment(idx) {
    this.appointmentIdx = idx;
    this.creditCost = 0;
    this.loading.start();
    this.appointment.getAppointment(idx).subscribe((res) => {
      this.setAppointmentInFuture(res);
      this.addTreatment(this.includePriceAndNotes(res));
      this.checkInsuranceMatch(res)
      this.patient.setPatient(res.patientIdx);
      this.patientIdx = res.patientIdx;

      if (res.intentAmount){
        this.paymentIntent = {intentId: res.intentId, amount: res.intentAmount};
      }
      this.patient.getPatient().subscribe((res) => {
        this.patientInfo = res;
        this.loading.stop();
      });
    });
  }

  patientChanged() {
 //   this.nCredits = 0
    this.patient
      .getPatientAccountInfo(this.patientIdx)
      .subscribe((res) => {
       // this.nCredits = 0;
        console.log("[patient] [account info]", res, this.appointmentIdx);
        if (!res) {
          return;
        }
        this.account.balance = Number(res.grandtotal)

        if (!this.recoverTransactionIdx) {
          this.otherUnpaidAppointments = this.appointmentIdx ? res.unpaid.filter((app: any) => app.idx !== this.appointmentIdx) : res.unpaid;
          //            this.checkUnpaidAppointments(res.unpaid)
        }


        this.creditsDetails = res.creditsDetails.filter(c=>{
          const found = c.appliesTo.find(f=> (f==this.treatmentFromCat || f==this.items[0].treatmentTypeIdx));
          if (found || c.appliesTo.length==0){
            return true;
          }
        });

        console.log("[credits]", this.creditsDetails, "in cat", this.treatmentFromCat)
        // display only the number of credits that apply to the selected treatment

      });
  }

  checkUnpaidAppointments(apps) {
    this.otherUnpaidAppointments = [];
    const appointmentsWithCurrentIgnored = this.appointmentIdx ? apps.filter((app: any) => app.idx !== this.appointmentIdx) : apps;

    appointmentsWithCurrentIgnored.forEach((appt => {
      this.appointment.getAppointment(appt.idx).subscribe((res) => {
        if (res.price) this.otherUnpaidAppointments.push(res)
      });
    }))
  }

  addUnpaidAppointments() {
    this.otherUnpaidAppointments.forEach((appt) => {
      this.appointment.getAppointment(appt.idx).subscribe((res) => {
        this.addTreatment(this.includePriceAndNotes(res));
      })
    });
    this.removeUnpaidAppointments()
  }

  removeUnpaidAppointments() {
    this.otherUnpaidAppointments = []
  }

  addDNACost(price, staffID) {
    if (Number.isNaN(price)) {
      price = 0;
    }
    this.items.push({
      idx: 0,
      qty: 1,
      name: "DNA Charge",
      price: price,
      notes: "Did not attend",
      type: SALE_ITEM,
      staffIdx: staffID,
      pervailVAT: 0,
      freeText: "DNA charge for appointment",
      pervailComm: 0,
    });
    this.updateTotal();
  }

  calculateTotalPrice() {

    this.totalPrice = 0;
    for (let item of this.items) {
      this.totalPrice += (item.qty * this.toPence(item.price));
    }
  }

  updateTotal() {
    this.calculateTotalPrice();
    this.paymentAmount = this.calculatePaymentOutstanding();
  }

  resetItem() {
    this.newItem = {
      idx: this.newItem.idx + 1,
      name: "",
      qty: 1,
      price: 0,
      notes: "",
      type: SALE_ITEM,
      staffIdx: 0,
      pervailVAT: 0,
      freeText: "",
      pervailComm: 0,
    };
  }

  public addItem() {
    const alreadyInBasket = this.items.find(f => f.idx === this.newItem.idx)
    if (alreadyInBasket) {
      this.items[this.items.indexOf(alreadyInBasket)].qty += this.newItem.qty
      this.resetItem()
    } else {
      this.items.push(Object.assign({}, this.newItem));
      this.resetItem()
    }
    this.myControl.setValue("");
    this.updateTotal();
    this.calculatePaymentLeft(this.paymentAmount);
    this.inputDropdown._onChange("");
    this.inputDropdown.openPanel();
    console.log(this.items);
  }

  private addTreatment(res) {
    this.items.push({
      idx: res.idx,
      qty: 1,
      name: res.treatment,
      price: res.price,
      notes: res.notes,
      type: TREATMENT,
      staffIdx: res.staffIdx,
      pervailVAT: res.pervailVAT,
      treatmentTypeIdx: res.treatmentIdx,
      freeText: res.treatment,
      pervailComm: res.commission,
      creditValue: res.creditValue,
    });
    this.clinicIdx = res.clinicIdx;
    this.clinicService.setSelectedClinicById(this.clinicIdx);
    this.clinicFromTreatment = true;
    this.treatmentFromCat = res.treatFromCat;
    this.creditCost += res.creditValue;
    this.updateTotal();
  }

  private _filter(value: string): SaleItem[] {
    const filterValue = value.toLowerCase();
    const filterList = this.saleProducts.filter((option) =>
      option.name.toLowerCase().includes(filterValue)
    );
    console.log(filterList);
    return filterList;
  }

  itemSelected(event: MatAutocompleteSelectedEvent) {
    const itemData = event.option.id.split("_");
    const itemIdx = parseInt(itemData[1]);
    const item: SaleItem = this.saleProducts.find((f) => (f.idx == itemIdx && f.type == itemData[0]));
    if (!item) return;

    console.log("[item] have selected ", item);
    this.newItem.price = item.price;
    this.newItem.name = item.name;
    this.newItem.idx = item.idx;
    this.newItem.type = item.type;
  }

  removeItem(removalIdx) {
    this.items = this.items.filter((e) => e.idx != removalIdx);
    this.updateTotal();
  }

  removePayment(payment) {
    this.payments = this.payments.filter(f => (f != payment));
    this.updateTotal();
  }

  private calculatePaymentOutstanding(): number {
    let totalPaid = 0;
    console.log("[pay]", this.payments);
    for (let payment of this.payments) {
      totalPaid += Number(payment.amount);
    }
    this.outstanding = this.totalPrice - Number(totalPaid);
    return this.outstanding;
  }

  public processTransaction() {
    this.loading.start();
    this.processing = true;

    const payload: any = {
      patientIdx: this.patientIdx,
      items: this.items,
      payments: this.payments,
      clinicIdx: this.clinicIdx,
      accountingDate: this.accountingDate,
      insuranceInvoice: this.insuranceUsed,
      insuranceAuthCode: this.authCode,
      refundsTxn: this.refundsTxn
    }

    if (this.recoverTransactionIdx) {
      payload.transactionIdx = this.recoverTransactionIdx;
    }

    if (this.isRefund && this.resetAfterRefund) {
      payload.resetAppointmentStatus = true;
    }

    this.tillService.postTransaction(payload).subscribe((res: any) => {
      this.loading.stop();
      if (this.insuranceUsed) {
        this.router.navigate(["finance", "invoice", res.transactions[1]]);
      } else {
        this.router.navigate(["finance", "invoice", res.transactions[0]]);
      }
      this.store.dispatch(patientActions.ClearAction())
    });
  }

  public verifyForm() {
    if (!this.clinicSet) {
      this.clinic = this.clinics[0]
      this.updateClinic('')
    }
    this.formComplete = true;
    if (!this.patientIdx) {
      this.patientComplete = false
      this.formComplete = false
      this.notification.send("No patient selected");
    } else {
      this.patientComplete = true
    }
    if (!this.clinicIdx) {
      this.clinicComplete = false
      this.formComplete = false
      this.notification.send("No clinic selected");
    } else {
      this.clinicComplete = true
    }
    if (this.items.length < 1) {
      if (this.account.balance > 0) {
        this.payingBalance = true
      } else {
        this.formComplete = false
        this.notification.send("No items selected");
      }
    }
    console.log("ITEMS", this.items);
    this.formSubmitted = true;

    if (this.outstanding > 0) {
      // Set up dialog info
      console.log("[outstanding] ", this.outstanding);
      const title = "Remaining charges";
      const message = "Would you like to add remaining charges to your account?";

      // Open dialog, process response
      this.dialogService.genericDialog(title, message, ['Yes'], ['No'], false)
        .then(res => {
          console.log("Would you like to add remaining charges to your account?", res)
          if (res) {
            this.account.balance -= this.outstanding;
            this.processTransaction()
          } else return;
        })
    } else {
      return true
    }
  }

  public correctPayments(): void {
    const payments = [];
    let currentPrice = this.totalPrice;

    this.payments.forEach((payment: any) => {
      if (currentPrice >= payment.amount) {
        payments.push(payment);
      } else if (currentPrice > 0) {
        payment.amount = currentPrice;
        payments.push(payment);
      }

      currentPrice -= payment.amount;
    })

    this.payments = payments;
    this.outstanding = 0;
  }

  public finishTransaction() {
    if (this.change > 0) {
      // Set up dialog info
      const title = "Change";
      const message = "Would you like to add your change to your account?";

      // Open dialog, process response
      this.dialogService.genericDialog(title, message, ['Yes'], ['No'], false)
        .then(res => {
          if (res) {
            this.account.balance += this.change;
            this.change = 0;
            this.processTransaction();
          } else {
            this.correctPayments();
            this.processTransaction();
          }
        })
    } else {
      this.processTransaction()
    }
  }


  public processPayment() {
    if (this.processing) {
      return;
    }

    if (this.verifyForm())
      if (this.payingBalance) {
        // Set up dialog info
        const title = "Balance";
        const message = "Would you like to pay off your balance?";
        const actions = [
          { name: "No", returnType: returnTypes.FALSE },
          { name: "Yes", returnType: returnTypes.TRUE }
        ]
        // Open dialog, process response
        this.dialogService.genericDialog(title, message, ['Yes'], ['No'], false)
          .then(res => {
            if (res) {
              if (this.formComplete) {
                if (this.insuranceUsed) {
                  //this.processInsurance()
                  this.finishTransaction()
                } else {
                  this.finishTransaction()
                }
              } else {
                console.log("[txn] form not complete")
              }
            } else return;
          })
      } else {
        if (this.formComplete) {
          if (this.insuranceUsed) {
            //this.processInsurance()
            this.finishTransaction()
          } else {
            this.finishTransaction()
          }
        } else {
          console.log("[txn] form not complete")
        }
      } else return;
  }


  calculatePaymentLeft(amount: number, method: PaymentMethod | null = null, voucherCode: number = null) {
    let outstanding = this.calculatePaymentOutstanding();
    if (this.isRefund) {
      if (method) {
        this.payments.push({
          paymentTypeIdx: method.idx,
          amount: amount,
          name: method.name,
          voucherIdx: voucherCode
        })
      }
    } else {
      let zeroPriceCount = 0
      let process = true
      this.items.forEach(item => {
        if (item.price == 0.00) {
          zeroPriceCount++
        }
      })
      if (zeroPriceCount === this.items.length && this.account.balance <= 0) {
        process = false
      }
      if (outstanding == 0 && process) {
        outstanding = this.account.balance
      }
    }
    if (amount > outstanding) {
      this.change += amount - outstanding;
    }
    if (method) {
      this.payments.push({
        paymentTypeIdx: method.idx,
        amount: amount,
        name: method.name,
        voucherIdx: voucherCode
      });
      this.paymentAmount = this.calculatePaymentOutstanding();
    }
  }


  calculateTotal() {
    let total = 0
    this.items.forEach(item => {
      total += (item.price * item.qty)
    })
    return total
  }

  public payByIntent(){
    this.payments.push({
      paymentTypeIdx: PAYMENT_INTENT,
      amount: this.paymentAmount,
      name: this.paymentIntent.intentId
    });
    this.finishTransaction();
  }

  payByCredits(credits) {
    this.payments.push({
      paymentTypeIdx: PAYMENT_CREDITS,
      amount: this.creditCost,
      name: "credits",
      creditIdx: credits.creditIdx,
      accountIdx: credits.patientIdx
    });
    this.finishTransaction();
  }


  payByGiftCard(method: PaymentMethod) {
    this.openPrompt("Gift Card Validation", "Enter the code on your gift card").then((code: string) => {
      this.giftCardService.validate(code).pipe(take(1)).subscribe(res => {
        if (res.ok === true) {
          console.log("[GC] validated")
          this.giftcardValidated = true
          const gc = {
            code: code,
            amount: this.toPence(res.amount),
            id: res.id
          }
          this.calculatePaymentLeft(gc.amount, method, gc.id)
        } else {
          console.log("[GC] invalid", res.error)
          this.errorMessage = res.error;
        }
      })
    })
  }

  public payBy(method: PaymentMethod) {
    // console.log(this.lastMethod, method, parseFloat(this.paymentAmount));

    const amount = this.paymentAmount;
    console.log(`[add] pay ${amount}`);
    console.log(`[pay] by`, method);

    if (method == this.lastMethod && amount == 0) {
      this.processPayment();
      return;
    }

    this.lastMethod = method;

    switch (method.name) {
      case "Gift Voucher":
        this.payByGiftCard(method)
        return;
      case "Insurance":
        this.getAndMatchInsurance(method);
        this.insuranceUsed = true;
        return;
      default:
        if (method.discountValue || method.discountValue === 0) {
          console.log(`[pay] by discount`, method);
          this.calculateDiscount(method);
          return
        }

        if (amount <= 0 && !this.isRefund) {
          return
        }
        this.calculatePaymentLeft(
          amount,
          method
        );
        return;

    }
  }

  calculateDiscount(method) {
    if (this.calculatePaymentOutstanding() <= 0) return
    const idx = method.idx
    const prevDiscount = this.payments.find(f => f.paymentTypeIdx === idx)
    let index;
    if (prevDiscount) {
      index = this.payments.indexOf(prevDiscount)
    }
    if (index !== undefined) this.payments.splice(index, 1)
    let discountPercent
    if (method.discountValue === "SET") {
      const amount = this.paymentAmount;
      if (amount <= 0) {
        return
      }
      this.calculatePaymentLeft(amount, method)
      return
    }

    const totalVal = this.calculateTotal();
    let discount = 0;

    if (method.discountValue.indexOf('%') != -1) {
      discountPercent = Number(method.discountValue.slice(0, -1));
      discount = discountPercent * totalVal;
    } else {
      discount = Number(method.discountValue) * 100;
    }


    this.calculatePaymentLeft(discount, method);
    return
  }

  public cancel() {
    this.nav.back();
  }

  public updateClinic(event) {
    console.log("[clinic]", this.clinic, event);
    this.clinicService.setSelectedClinic(this.clinic);
    this.clinicSet = true
  }


  /* Insurance processing methods */


  checkInsuranceMatch(appointment: any) {
    // When an appointment is loaded, check if insurance is compatable with patient and staff member
    let staffInsurers: number[]
    let patientInsurers: number[]
    let matchedInsurers: number[]
    combineLatest([
      this.tillService.fetchInsurance(appointment.patientIdx),
      this.tillService.fetchStaffInsurance(),
      this.tillService.fetchInsurers()
    ])
      .subscribe((combined: any) => {
        patientInsurers = combined[0].map(ins => ins.patientInsurer)
        staffInsurers = combined[1].find(f => f.idx === appointment.staffIdx).insurerIdxArray.map(ins => ins.insurerIdx)
        matchedInsurers = staffInsurers.filter(ins => {
          if (patientInsurers.includes(ins)) return ins
        })
        this.matchedInsurers = combined[2].filter(ins => matchedInsurers.includes(ins.idx))
      })
  }

  public getAndMatchInsurance(method) {
    // Called from html when insurance is selected as payment method
    if (!this.matchedInsurers) {
      // Set up dialog info
      const title = "Alert";
      const message = "No valid insurance available";
      const actions = [
        { name: "OK", returnType: returnTypes.TRUE }
      ]
      // Open dialog, process response
      this.dialogService.genericDialog(title, message, ['OK'], null, false)
      return
    }
    this.checkUninsuredItems(method)
  }

  private checkUninsuredItems(method) {
    // Flag any non-appointments as uninsured
    this.uninsuredItems = this.items
      .filter((item) => {
        if (item.name === "Excess") return
        if (item.staffIdx === 0) return item
      })
      .filter((value, index, self) => self.indexOf(value) === index);
    // process tx further depending on uninsured items
    if (this.uninsuredItems.length && !this.insuranceUsed) {
      this.confirmUninsured(method)
    } else if (!this.insuranceUsed) {
      this.processInsurance(method)
    } else {
      // Set up dialog info
      const title = "Alert";
      const message = "Insurance has already been used.";
      const actions = [
        { name: "OK", returnType: returnTypes.TRUE }
      ]

      // Open dialog, process response
      this.dialogService.genericDialog(title, message, ['OK'], null, false)
    }
  }

  private confirmUninsured(method) {
    // Make user aware insurance does not cover all items

    // Set up dialog info
    const title = "Un-insured Items";
    const message = `The following items are not covered by insurance: ${this.uninsuredItems.map(item => item.name).join()}. Would you like to proceed?`;

    // Open dialog, process response
    this.dialogService.genericDialog(title, message, ['Yes'], ['No'], false)
      .then(
        res => {
          if (!res) return
          this.processInsurance(method)
        })
  }

  private processInsurance(method) {
    // Take authorisation code
    this.openPrompt("Insurance", "What is your Insurance Authorization Code?").then(
      res => {
        if (!res) return
        this.authCode = res
        let paymentAmount
        switch (this.uninsuredItems.length) {
          case 0:
            paymentAmount = this.paymentAmount
            break
          // Remove value of uninsured items
          case 1:
            paymentAmount = this.paymentAmount - (parseFloat(this.uninsuredItems[0].price) * this.uninsuredItems[0].qty)
            break
          default:
            paymentAmount = this.paymentAmount - this.uninsuredItems.reduce((a, b) => {
              let priceA = parseFloat(a.price) * a.qty
              let priceB = parseFloat(b.price) * b.qty
              return priceA + priceB
            })
        }
        // process tx further
        this.calculatePaymentLeft(paymentAmount, method)
      }
    )
  }


  accountingDateChanges(event) {
    console.log("[date] ", this.accountingDate, event.target.value);
  }

  toPence(input: number) {
    return input * 100;
  }

}

function offset(el) {
  var rect = el.getBoundingClientRect();
  const scrollLeft = 0; //.pageXOffset || document.documentElement.scrollLeft,
  const scrollTop = 0; //window.pageYOffset || document.documentElement.scrollTop;
  return {
    top: rect.top - scrollTop,
    left: rect.left + scrollLeft,
    height: rect.height,
    width: rect.width,
  };
}
