import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest } from '@angular/common/http';
import { EMPTY, Observable } from 'rxjs';
import { AuthService } from '../auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private auth: AuthService) { }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const jwt = this.auth.getJWT();
    const authReq = req.clone({
      headers: req.headers.set('Authorization', `Bearer ${jwt}`),
      withCredentials: true
    });
    if (!jwt && req.url.indexOf('auth')==-1 && req.url.indexOf('config')==-1){
      console.log(`[http] no jwt declining request to ${req.url}`);
      return EMPTY;
    }
    
    return next.handle(authReq);
  }
}
