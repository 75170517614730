import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { DataService } from "../../services/data.service";

@Injectable()
export class InsuranceInvoiceService {
  constructor(private http: DataService) {}

  getInvoice(txnIdx: number) {
    return this.http.get(`/finance/invoice/insurance/${txnIdx}`);
  }
}
