import { Component } from '@angular/core';
import { NotesBuilderBaseComponent } from 'app/notes/notes-builder/notes-builder-base/notes-builder-base-component';
import { MedicalHistoryFields } from '../models/medical-history';

@Component({
  selector: 'app-medical-history',
  templateUrl: './medical-history.component.html',
  styleUrls: ['../shared.scss']
})

export class MedicalHistoryComponent extends NotesBuilderBaseComponent {
  medicalHistoryFields  = MedicalHistoryFields;
}