//control if the app is busy and locked
import { ActionReducer, Action } from '@ngrx/store';
import { createSelector } from 'reselect';

import * as loadingAction from '../actions/loading';


export interface State {
    loading: boolean;
    background: boolean;
};

export const initialState: State = {
    loading: false,
    background: false
};



export function reducer(state = initialState, action: Action): State {
    switch (action.type) {
        case loadingAction.ActionTypes.SHOW: {
            return Object.assign({}, state, { loading: true });
        }
        case loadingAction.ActionTypes.HIDE: {
            return Object.assign({}, state, { loading: false });
        }
        case loadingAction.ActionTypes.BACKGROUND_START: {
            return Object.assign({}, state, { background: true });
        }
        case loadingAction.ActionTypes.BACKGROUND_STOP: {
            return Object.assign({}, state, { background: false });
        }
        default:
            return state;
    }
}

export const getLoading = (state: State) => state.loading;
export const getBackground = (state: State) => state.background;


