<button mat-button class="me-5" [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
  <mat-icon>date_range</mat-icon>
  <div *ngIf="quickDateFilter.activeText; else noFilter" class="qdf-container">
    <div class="qdf-active-text">{{quickDateFilter.activeText}}</div>
    <!-- <div class="tiny-text">{{quickDateFilter.headerName}}</div> -->
  </div>
  <ng-template #noFilter>{{quickDateFilter.headerName}}</ng-template>
</button>

<mat-menu #menu="matMenu">
  <div mat-menu-item>
    <button mat-icon-button class="plusminus" (click)="dateSelectDelta(-1, 'd', quickDateFilter, 'Yesterday')"><mat-icon>remove</mat-icon></button>
    <button mat-button class="interval" (click)="dateSelectDelta(0, 'd', quickDateFilter, 'Today')">Day</button>
    <button mat-icon-button class="plusminus"  (click)="dateSelectDelta(1, 'd', quickDateFilter, 'Tomorrow')"><mat-icon>add</mat-icon></button>
  </div>
  
  <div mat-menu-item>
    <button mat-icon-button class="plusminus"  (click)="dateSelectDelta(-1, 'w', quickDateFilter, 'Last Week')"><mat-icon>remove</mat-icon></button>
    <button mat-button class="interval" (click)="dateSelectDelta(0, 'w', quickDateFilter, 'This Week')">Week</button>
    <button mat-icon-button class="plusminus"  (click)="dateSelectDelta(1, 'w', quickDateFilter, 'Next Week')"><mat-icon>add</mat-icon></button>
  </div>

  <div mat-menu-item>
    <button mat-icon-button class="plusminus"  (click)="dateSelectDelta(-1, 'm', quickDateFilter, 'Last Month')"><mat-icon>remove</mat-icon></button>
    <button mat-button class="interval" (click)="dateSelectDelta(0,'m', quickDateFilter, 'This Month')">Month</button>
    <button mat-icon-button class="plusminus"  (click)="dateSelectDelta(1, 'm', quickDateFilter, 'This Month')"><mat-icon>add</mat-icon></button>
  </div>
  <mat-divider></mat-divider>
  <div mat-menu-item class="calendar w-100" [matMenuTriggerFor]="appMenu" (click)="$event.stopPropagation()">
      <mat-icon>date_range</mat-icon>Calendar
  </div>
</mat-menu>

<mat-menu #appMenu="matMenu" >
  <mat-calendar (click)="onCalendarClick($event)" 
    (selectedChange)="onCalendarDateRange($event, quickDateFilter)"
    [selected]="selectedDateRange">
  </mat-calendar>
</mat-menu>

