<section>
  <mat-form-field class="example-full-width">
    <mat-label>{{question.label}}</mat-label>
    <input 
      #inputRef
      id="inputRef"
      matInput 
      [matAutocomplete]="auto" 
      [formControl]="autoCompleteTextInput"
      [(ngModel)]="value"
      >

      
    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="updateValue($event)" [displayWith]="displayFn" >
      <mat-option *ngFor="let address of addressResults | async" [value]="address">
        {{ address.Text }}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</section>
