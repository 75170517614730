import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataService } from 'app/services/data.service';
import { NotificationService } from 'app/services/notification.service';

@Component({
  selector: 'app-password-dialog',
  templateUrl: './password-dialog.component.html',
  styleUrls: ['../dialog-shared.scss']
})
export class PasswordDialogComponent implements OnInit {
  public newPassword: string;
  public confirmNewPassword: string;
  public passwordError: string;
  private userIdx: number;


  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any, 
    public dialogRef: MatDialogRef<PasswordDialogComponent>, 
    private http: DataService,
    private notification: NotificationService
) {
  this.userIdx = this.data.args[0].userIdx;
 }

ngOnInit(): void {
}

public onDismiss() : void {
  this.dialogRef.close(false);
}


passwordCorrect(): boolean {
  if ( !this.newPassword || !this.confirmNewPassword ) {
    this.passwordError = "Passwords must be provided.";
    return false;
  }

  this.newPassword = this.newPassword.trim();
  this.confirmNewPassword = this.confirmNewPassword.trim();

  if ( this.newPassword !== this.confirmNewPassword ) {
    this.passwordError = "Passwords must match.";
    return false;
  }

  return true;
}

changePassword(): void {
  if ( !this.passwordCorrect() ) {
    setTimeout(() => this.passwordError = null, 3000);
    return;
  }

  this.http.post("/users/password/admin", { password: this.newPassword, userIdx: this.userIdx }).subscribe(( success: boolean ) => {
    if ( success ) {
      this.notification.send("You have changed this password successfully.");
      this.onDismiss();
    } else {
      this.notification.send("There has been an error during this password change.");
    }
  })
}
}