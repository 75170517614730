<form autocomplete="off" #formEle="ngForm">
    <mat-checkbox [(ngModel)]="form.chiropractic_treatment" name="chiropractic_treatment">
        <div class="ws-normal">
            I consent to chiropractic treatment. I am aware that I have the right to withdraw consent to treatment at
            any time.<br />
            <span class="text-muted">In the case of a child / young adult under the age of 18, or a vulnerable adult
                with
                diminished intellectual capacity, the parent, guardian or carer must give consent.
            </span>
        </div>
    </mat-checkbox>

    <hr />

    <mat-checkbox [(ngModel)]="form.notes_used_for_education" name="notes_used_for_education">
        <div class="ws-normal">
            As a teaching clinic, education and training is at the core of our work. 
            <br/>I consent to my notes being
            accessed and used anonymously for education,
            audit, teaching and / or research purposes by the WIOC.
        </div>
    </mat-checkbox>

    <hr />

    <mat-checkbox [(ngModel)]="form.participation_in_projects" name="participation_in_projects">
        <div class="ws-normal">
            I agree to being contacted regarding possible participation in ongoing or new research projects.
            I understand that this will not however commit me to agreeing to any future research projects.
        </div>
    </mat-checkbox>
</form>