import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as fromRoot from '../store/reducers';
import * as fromSettings from '../store/actions/user-settings';
import { Observable } from 'rxjs';
@Injectable(
  {
    providedIn: 'root'
  }
)
export class VisualsService {
    constructor(private store: Store<fromRoot.State>) { }


    public setIconStyle(style: boolean) {
        this.store.dispatch(new fromSettings.SetAction({ useOldIcons: style }));
    }

    public getIconsStyle() {
        return this.store.select(fromRoot.getIconStyleSetting);
    }

    public getDarkMode(): Observable<boolean> {
      return this.store.select(fromRoot.getDarkMode)
    }

    public setDarkMode(mode: boolean): void{
      this.store.dispatch(new fromSettings.SetDarkMode(mode));
  }
}
