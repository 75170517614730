import { Component, OnInit } from '@angular/core';
import { FormTemplateBaseComponent } from '../form-template-base/form-template-base.component';

@Component({
  selector: 'app-form-text-area',
  templateUrl: './form-text-area.component.html',
  styleUrls: ['./form-text-area.component.css']
})
export class FormTextAreaComponent extends FormTemplateBaseComponent {
  public rows: number = 2;

  ngOnInit(): void {
    if ( this.question.options ) {
   //   this.rows = +JSON.parse(this.question.options).rows;
    }
  }

  handleChange(question: any, input: any) {
    question.value = input.value;
  }
}
