import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { NavigationService } from 'app/services/navigation.service';
import { KnownReplacements, Template } from 'app/models/letter';
import { NotificationService } from 'app/services/notification.service';
import { TemplateEditorConfig } from 'app/models/editor-config';
import { LettersService } from 'app/services/notes/letters.service';

@Component({
  selector: 'app-new-letter-template',
  templateUrl: './new-letter-template.component.html',
  styleUrls: ['./new-letter-template.component.scss']
})
export class NewLetterTemplateComponent implements OnInit {
  public editorConfig: AngularEditorConfig = TemplateEditorConfig;
  public templateIdx: number;
  public KnownReplacements = KnownReplacements;
  private notesIdx: number;
  public template: Template;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private sanitizer: DomSanitizer,
    private nav: NavigationService,
    private notification: NotificationService,
    private lettersService: LettersService
   
  ) { }

  ngOnInit() {
   this.route.params.subscribe(res => {
     if (res.templateIdx) {
       this.templateIdx = Number(res.templateIdx)
       this.lettersService.getTemplate(this.templateIdx).subscribe(t => {
          this.template = t
       })
     }
     if (res.notesIdx) {
       this.notesIdx = res.notesIdx
     }
   })
  }

  public cancel(){
    this.nav.back()
  }


  public change(change){
    change = this.sanitizer.bypassSecurityTrustStyle(change)
   }

  public save(){
   
    this.lettersService.updateTemplate(this.template.idx, this.template).subscribe(res => {
      if (res) {
        this.notification.send('Template saved successfully!')
        if (this.notesIdx) {
          /* If a note id has carried through, open that note 
          with the new template as the html content */
          this.router.navigateByUrl(`/notes/letter/${this.notesIdx}/${res}`)
        }
      }
    })
  }
}
