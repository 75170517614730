export interface Template {
  idx: number,
  title: string,
  content: string,
  description: string
}

export const KnownReplacements = [
  {
    tag: "clinic",
    description: "Clinic Info",
    subItems: [{
      tag: "logo",
      description: "Clinic Logo"
    },
    {
      tag: "address",
      description: "Clinic Address"
    },
    {
      tag: "name",
      description: "Clinic Name"
    },
    {
      tag: "email",
      description: "Clinic Email"
    },
    {
      tag: "phone",
      description: "Clinic Phone Number"
    },
    {
      tag: "website",
      description: "Clinic Weblink"
    }]
  },
  {
    tag: "date",
    description: "Date and Time",
    subItems: [{
      tag: "date",
      description: "Current date as dd/mm/yyyy"
    },
    {
      tag: "time",
      description: "Current Time as HH:MM"
    },
    {
      tag: "add4weeks",
      description: "Current date plus 4 weeks"
    }
    ]
  },
  {
    tag: "patient",
    description: "Patient Info",
    subItems: [{
      tag: "firstname",
      description: "Firstname"
    },
    {
      tag: "lastname",
      description: "Lastname"
    },
    {
      tag: "title",
      description: "Title"
    },
    {
      tag: "fullname",
      description: "Patient full name including title"
    },
    {
      tag: "address_nocr",
      description: "Patient address, comma seperated"
    },
    {
      tag: "dob",
      description: "Patient DOB"
    },
    {
      tag: "firstvisit",
      description: "Patient first visit date"
    },
    {
      tag: "lastvisit",
      description: "Patient last visit date"
    },
    {
      tag: "gp.name",
      description: "GP name"
    },
    {
      tag: "gp.address",
      description: "GP address"
    }
    ]
  },
  {
    tag: "staff",
    description: "Logged in as Staff Info",
    subItems: [
      {
        tag: "fullname",
        description: "Staff memember full name including title and qualifications"
      }
    ]
  },
  {
    tag: "notes",
    description: "Access latest notes data",
    subItems: [
      {
        tag: "FORM.FIELD",
        description: "Will pull the specified FIELD from a FORM"
      }
    ]
  }
]