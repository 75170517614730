import { Component, OnInit } from '@angular/core';
import { NotesBuilderBaseComponent } from 'app/notes/notes-builder/notes-builder-base/notes-builder-base-component';

@Component({
  selector: 'app-physical-activity',
  templateUrl: './physical-activity.component.html',
  styleUrls: ['./physical-activity.component.scss']
})
export class PhysicalActivityComponent extends NotesBuilderBaseComponent {

}
