import { Component, OnInit } from '@angular/core';
import { FormTemplateBaseComponent } from '../form-template-base/form-template-base.component';

@Component({
  selector: 'app-forms-colour',
  templateUrl: './forms-colour.component.html',
  styleUrls: ['./forms-colour.component.scss']
})
export class FormsColourComponent extends FormTemplateBaseComponent {



}
