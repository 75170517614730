import { Action } from '@ngrx/store';
import { State } from '../../models/site-settings';
import { StaffMember } from '../../models/staff';
import { type } from '../util';

export const SET = 'STAFF_SET';
export const INIT = 'STAFF_INIT';
export const FETCH = "STAFF_FETCH";

export const ActionTypes = {
    SET: type('[StaffState] Set'),
    INIT: type('[StaffState] Init'),
    FETCH: type('[StaffState] Fetch'),
    MARK_SELECTED: type('[StaffState] Mark Selected'),
    BULK_MARK_SELECTED: type('[StaffState] Bulk Mark Selected'),
    SELECT_ALL:type('[StaffState] Select All'),
    SELECT_NONE:type('[StaffState] Select None'),
    SET_SORT_ORDER: type('[StaffState] Set Sort Order')
};


/**
 * Every action is comprised of at least a type and an optional
 * payload. Expressing actions as classes enables powerful 
 * type checking in reducer functions.
 * 
 * See Discriminated Unions: https://www.typescriptlang.org/docs/handbook/advanced-types.html#discriminated-unions
 */
export class SetAction implements Action {
    type = ActionTypes.SET;
    constructor(public payload: StaffMember[]) { }
}

export class InitAction implements Action {
    type = ActionTypes.INIT;
    constructor() { }
}

export class FetchAction implements Action {
    type = ActionTypes.FETCH;
    constructor() { }
}

export class MarkSelected implements Action {
    type = ActionTypes.MARK_SELECTED;
    constructor (public payload: {idx: number, selected: boolean} ){}
}

export class BulkMarkSelected implements Action {
    type = ActionTypes.BULK_MARK_SELECTED;
    constructor (public payload: number[]){}
}

export class SetSortOrder implements Action {
    type = ActionTypes.SET_SORT_ORDER;
    constructor(public payload: number[]){}
}

export class SelectAll implements Action {
    type = ActionTypes.SELECT_ALL;
    constructor(){}
}

export class SelectNone implements Action {
    type = ActionTypes.SELECT_NONE;
    constructor(){}
}
/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
export type Actions
    = SetAction |
    InitAction | FetchAction;
