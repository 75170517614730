import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ResourceLoader } from "@angular/compiler";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { LoadingService } from "app/services/loading.service";
import { NavigationService } from "app/services/navigation.service";
import { XZReportsService } from "app/services/xzreports.service";
import { throwIfEmpty } from "rxjs/operators";

@Component({
  selector: "app-xzreports",
  templateUrl: "./xzreports.component.html",
  styleUrls: ["./xzreports.component.scss"],
})
export class XzReportsComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private loading: LoadingService,
    private xzReportsService: XZReportsService,
    private nav: NavigationService,
  ) {}
  reportsData = [];
  errorMessage: string;

  ngOnInit(): void {
    console.log("[REPORTS] xzxzxzxzxz");
    this.retrieveReport();
  }

  continue() {
    this.nav.back()
  }

  retrieveReport() {
    this.loading.start();
    this.xzReportsService
      .getReportFromServer(
        this.route.snapshot.paramMap.get("reportType"),
        this.route.snapshot.paramMap.get("userID"),
        this.route.snapshot.paramMap.get("zreportIdx")
      )
      .subscribe((res) => {
        if (res.errorMessage){
          this.errorMessage = res.errorMessage;
          this.reportsData = [];
        }else{
          this.reportsData = res;
          this.errorMessage = null;
        }
        this.loading.stop();
      });
    return this.reportsData;
  }

  print(){
        // var mywindow = window.open('', 'PRINT', 'height=400,width=600');
        // var html = document.getElementById('print-html').innerHTML
        // mywindow.document.write(html);
        
        // mywindow.document.close(); // necessary for IE >= 10
        // mywindow.focus(); // necessary for IE >= 10*/
    
        // mywindow.print();
        // mywindow.close();
        window.print()
      }
}
