import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { StaffMember } from 'app/models/staff';
import { ClinicService } from 'app/services/clinic.service';
import { DialogService } from 'app/services/dialog/dialog.service';
import { FormDialogQuestion, FormOptions } from '../../../models/dialog'
import { AvailabilityService } from 'app/services/diary/availability.service';
import { DiaryService } from 'app/services/diary/diary.service';
import { NavigationService } from 'app/services/navigation.service';
import { NotificationService } from 'app/services/notification.service';
import { StaffService } from 'app/services/staff.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-availability',
  templateUrl: './availability.component.html',
  styleUrls: ['./availability.component.scss']
})
export class AvailabilityComponent implements OnInit {
  public events: any[] = [];
  public availabilityTemplates: any[] = [];
  public clinics: any[] = [];
  public selectedClinic: any;
  public availabilities: any[] = [];
  public staffName: string;
  private staffIdx: number;
  private clinics$: Subscription;
  public increment: number;

  constructor(private navigationService: NavigationService, 
              private dialogService: DialogService,
              private clinicService: ClinicService,
              private diary: DiaryService,
              private availabilityService: AvailabilityService,
              private route: ActivatedRoute,
              private notification: NotificationService,
              private staff: StaffService ) { }

  ngOnInit(): void {
    this.diary.getIncrement().subscribe(res=>{
      this.increment = res;
    })

    this.getAvailabilityTemplatesList() 

    this.route.params.subscribe((params: Params) => {
      if (params['staffIdx']) this.staffIdx = +params['staffIdx'];
    });

    this.clinics$ = this.clinicService.getClinics().subscribe(( clinics: any ) => {
      this.clinics = clinics;
      if ( !this.selectedClinic ) this.selectedClinic = clinics[0];
    });
    this.initialiseAvailability();
  }

  private initialiseAvailability() : void {
    this.diary.initialiseSettings();
    this.diary.getAvailability().subscribe(rawAvailabilities => {
      const currentAvailability = rawAvailabilities.filter((availability: any) => availability.staffIdx === this.staffIdx);
      this.availabilities = this.availabilityService.convertAvailability(currentAvailability, new Date(), this.increment);
      this.createEvents();
    });
    
  }

  private updateAvailability(newAvailability) : void {
    let currentAvailability = {
      available: newAvailability.availability[0].availability,
      staffIdx: newAvailability.staffIdx,
    }
    this.availabilities = this.availabilityService.convertAvailability([currentAvailability], new Date(), this.increment);
    this.createEvents();
  }



  public createEvents(): void {
    this.events = [];

    this.availabilities.forEach((availability: any, index: number) => {
      this.events = [...this.events, {
        id: index,
        start: availability.startTime,
        end: availability.endTime,
        meta: {},
        title: "New event"
      }];
    })
  }

  public setDatesTime(day: number, hour: number, minutes: number) {
    // Set date to correct day this week
    const now = new Date().getDay();
    let diff = day - now;
    let next = new Date().getTime() + (1000 * 60 * 60 * 24 * diff);
    next = new Date(next).setHours(hour,minutes,0,0);
    let newDate = new Date(next)
    return newDate
  }


  public selectClinic(event: any): void {
    this.selectedClinic = this.clinics.filter((clinic: any) => clinic.idx === event.option.value)[0];
    this.clearCalendar();
  }

  /**
   * opens a modal and the chosen option will fill the calendar
   * it order for the calendar to use the data we have to convert a string array of numbers into calendar events
   */
  public pickGroupTemplate(): void {
    let formOptions = []

    for (let i = 0; i < this.availabilityTemplates.length; i++) {
      formOptions.push(new FormOptions(this.availabilityTemplates[i].idx, this.availabilityTemplates[i].description))
    }

    this.dialogService.formDialog("Select Template", "Please select a template", [new FormDialogQuestion('', 'template', 'radio', formOptions)], null, ['Cancel'], ['Delete', 'Pick'])
      .then((res: any) => {
        if (res.data) {
          let selectedTemplate = this.availabilityTemplates.find((obj) => obj.idx === res.data[0].value);
          // res.index represents the index of the pressed button in the array passed as param, ['Delete', 'Pick'] 
          if (res.index == 1) {
            let currentAvailability = {
              available: selectedTemplate.Avail.split(',').map(Number),
              staffIdx: this.staffIdx
            }

            this.availabilities = this.availabilityService.convertAvailability([currentAvailability], new Date(), this.increment);

            this.createEvents()

          } else if (res.index == 0) {
            this.deleteAvailabilityTemplate(selectedTemplate.idx)
          }
        }
      })
  }

  public UndoLastStep(): void {
    this.events = this.events.filter((event, index) => ( index !== this.events.length - 1 ));
  }

  public clearCalendar(): void {
    this.events = [];
  }

  public navigateBack(): void {
    this.navigationService.back();
  }

  public submitAvailability(): void {
    this.availabilities = [];

    this.events.forEach((event: any) => {
      this.availabilities = [...this.availabilities, {
        startTime: event.start,
        endTime: event.end,
        staffIdx: this.staffIdx
      }]
    })

    const convertedBackAvailabilities = this.availabilityService.convertBackAvailability(this.availabilities, new Date(), this.increment);

    const constructedAvailability = {
      staffIdx: this.staffIdx,
      availability: [{
        clinicIdx: this.selectedClinic.idx,
        availability: convertedBackAvailabilities ? convertedBackAvailabilities[0].available : [-1]
      }]
    }

    this.staff.getStaff().pipe(take(1)).subscribe((staff: StaffMember[]) => {
      const staffDetails = staff.find((s: StaffMember) => s.idx === this.staffIdx);

      this.staffName = staffDetails.firstname + " " + staffDetails.lastname;
    })
    
    this.availabilityService.saveAvailability(constructedAvailability).subscribe((response: any) => {
      if (response) {
        this.notification.send(`Submission successful!\nMessage: You have successfully updated the availability of ${this.staffName}.`);
        this.updateAvailability(constructedAvailability);
      } else {
        this.notification.send(`Submission failed!\nMessage: Unable to update the availability of ${this.staffName}.`);
      }
    });
  }

  getAvailabilityTemplatesList() {
    this.availabilityService.listAvailabilityTemplates().subscribe(res => {
      this.availabilityTemplates = res
    })
  }

  deleteAvailabilityTemplate(idx: number) {
    this.availabilityService.deleteAvailabilityTemplate(idx).subscribe(res => {
      this.getAvailabilityTemplatesList();
      this.notification.send(`You have successfully deleted the availability template`);
    })
  }

  /**
   * opens a modal for the user to type a description for the template to be saved
   * before we save in DB we have to convert the calendar events back into a string array of numbers
   */
  saveAsTemplate() {
    this.dialogService.formDialog('Save Availability Template', "Type a description for the template", [new FormDialogQuestion('Description', 'description', 'textarea', null)], null, ['Cancel'], ['Save'])
      .then((res: any) => {
        if (res.data) {
          let description = res.data[0].value

          this.availabilities = [];

          this.events.forEach((event: any) => {
            this.availabilities = [...this.availabilities, {
              startTime: event.start,
              endTime: event.end,
              staffIdx: this.staffIdx
            }]
          })

          const convertedBackAvailabilities = this.availabilityService.convertBackAvailability(this.availabilities, new Date(), this.increment);

          const payload = {
            description: description,
            avail: convertedBackAvailabilities[0].available
          }

          this.availabilityService.saveAvailabilityTemplate(payload).subscribe(res => {
            this.getAvailabilityTemplatesList();
            this.notification.send(`You have successfully saved the availability template`);
          })
        }
      });
  }

}
