<div class="pain-container">
  <form autocomplete="off" #formEle="ngForm" class="w-100">
    <div *ngFor="let painScale of PainScales" class="mb-4">
      <div>{{painScale.label}}</div>
      <div class="form-field scale-container my-2">
        <mat-button-toggle-group [(ngModel)]="form[painScale.field]" name="pain_{{painScale.label}}" class="w-100">
          <mat-button-toggle *ngFor="let number of numbers" [value]="number" class="w-100">{{number}}</mat-button-toggle>
        </mat-button-toggle-group>
        <div class="d-flex justify-content-between w-100 my-2 text-muted">
          <div>No Pain</div>
          <div>Worst Pain Imaginable</div>
        </div>
      </div>
    </div>
  </form>
</div>
