import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

export class DialogData {
  title: string;
  message: string;
  

  constructor(title: string, message: string){
    this.message = message
    this.title = title
  }
}

@Component({
  selector: 'app-till-prompt-dialog',
  templateUrl: './till-prompt-dialog.component.html',
  styleUrls: ['./till-prompt-dialog.component.sass']
})

export class TillPromptDialogComponent implements OnInit {
  message: string;
  promptResponse: string;
  invalid: boolean = false;

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData , public dialogRef: MatDialogRef<TillPromptDialogComponent> ) { }


  onConfirm(): void {
    // Close the dialog, return true
    if (!this.promptResponse) {
      this.invalid = true
    } else{
      this.dialogRef.close(this.promptResponse);
    }
   
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }
  
  ngOnInit(): void {
  }

}
