import { VitalSignsComponent } from './vital-signs/vital-signs.component';
import { RangeOfMotionAreaComponent } from './range-of-motion/range-of-motion.component';
import { ReflexesComponent } from './reflexes/reflexes.component';
import { MusclesAndNervesComponent } from './muscles-and-nerves/muscles-and-nerves.component';
import { OtherTestsComponent } from './other-tests/other-tests.component';
import { DrawingLumbarComponent } from './drawing-lumbar/drawing-lumbar.component';
import { DrawingThoracicComponent } from './drawing-thoracic/drawing-thoracic.component';
import { DrawingAbdomenComponent } from './drawing-abdomen/drawing-abdomen.component';
import { ExtremitiesComponent } from './extremities/extremities.component';
import { CervicalComponent } from './cervical/cervical.component';
import { LumbopelvicComponent } from './lumbopelvic/lumbopelvic.component';
import { DiagnosisComponent } from './diagnosis/diagnosis.component';
import { PermissionToTreatComponent } from './permission-to-treat/permission-to-treat.component';
import { CaseSummaryQuestionsComponent } from './case-summary-questions/case-summary-questions.component';
import { ReportOfFindingsComponent } from './report-of-findings/report-of-findings.component';
import { PatientConsentComponent } from './patient-consent/patient-consent.component'
import { PePatientConsentComponent } from './pe-patient-consent/pe-patient-consent.component';
import { EmploymentDetailsComponent } from './employment-details/employment-details.component';
import { MedicationComponent } from './medication/medication.component';
import { USWOtherTestsComponent } from './other-tests/usw-other-tests.component';
import { ExtremitiesV2Component } from './extremities-v2/extremities-v2.component';
import { MskhqComponent } from './mskhq/mskhq.component';


import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MyMaterialModule } from 'app/material/material.module';

import { NotesCanvasComponent } from '../notes-canvas/notes-canvas.component';
import { DialogsModule } from 'app/dialogs/dialogs.module';

import { DynamicFormsModule } from 'app/dynamic-form/dynamic-forms.module';
import { CustomFormControlsModule } from 'app/custom-form-controls/custom-form-controls.module';
import { NotesCustomFormControlsModule} from "app/notes/custom-form-controls/notes-custom-form-controls.module";
import { NotesSaveService } from "app/services/notes/notes-save.service";
import { MedicalHistoryComponent } from './medical-history/medical-history.component';
import { HealthDetailsComponent } from './health-details/health-details.component';
import { USWHealthDetailsComponent } from './health-details/usw-health-details.component';
import { FamilyHistoryComponent } from './family-history/family-history.component';
import { PainDetailsComponent } from './pain/pain-details.component';
import { FemalesDetailsComponent } from './females/females-details.component';
import { OsteoporosisComponent } from './osteoporosis/osteoporosis.component';
import { SleepDetailsComponent } from './sleep/sleep-details.component';
import { TutorCommentsComponent } from './tutor-comments/tutor-comments.component';
import { DrawingQuestionnaireComponent } from './drawing-questionnaire/drawing-questionnaire.component';
import { FastAlcoholScreeningTestComponent } from './fast-alcohol-screening-test/fast-alcohol-screening-test.component';
import { PhysicalActivityComponent } from './physical-activity/physical-activity.component';
import { ReapsComponent } from './reaps/reaps.component';
import { SpinalRedFlagsComponent } from './spinal-red-flags/spinal-red-flags.component';
import { DrawingExtremitiesComponent } from './drawing-extremities/drawing-extremities.component';
import { DrawingCervicalComponent } from './drawing-cervical/drawing-cervical.component';
import { AxialSpondyloarthritisRiskAssessmentComponent } from './axial-spondyloarthritis-risk-assessment/axial-spondyloarthritis-risk-assessment.component';
import { StartBackComponent } from './start-back/start-back.component';



const ModuleList = 
[
    CaseSummaryQuestionsComponent,
    CervicalComponent,
    DiagnosisComponent,
    DrawingLumbarComponent,
    DrawingThoracicComponent,
    DrawingAbdomenComponent,
    EmploymentDetailsComponent,
    ExtremitiesComponent,
    ExtremitiesV2Component,
    FamilyHistoryComponent,
    FemalesDetailsComponent,
    HealthDetailsComponent,
    LumbopelvicComponent,
    MedicalHistoryComponent,
    MedicationComponent,
    MusclesAndNervesComponent,
    OsteoporosisComponent,
    OtherTestsComponent,
    PainDetailsComponent,
    PatientConsentComponent,
    PePatientConsentComponent,
    PermissionToTreatComponent,
    RangeOfMotionAreaComponent,
    ReflexesComponent,
    ReportOfFindingsComponent,
    SleepDetailsComponent,
    TutorCommentsComponent,
    USWHealthDetailsComponent,
    USWOtherTestsComponent,
    VitalSignsComponent,
    FastAlcoholScreeningTestComponent,
    PhysicalActivityComponent,
    ReapsComponent,
    SpinalRedFlagsComponent,
    DrawingExtremitiesComponent,
    AxialSpondyloarthritisRiskAssessmentComponent,
    DrawingCervicalComponent,
    MskhqComponent,
    StartBackComponent
];

@NgModule({
    imports: [
        CustomFormControlsModule,
        CommonModule,
        FormsModule,
        DynamicFormsModule,
        ReactiveFormsModule,
        MyMaterialModule,
        DialogsModule,
        NotesCustomFormControlsModule,

    ],
    declarations: [...ModuleList, 
        NotesCanvasComponent, DrawingQuestionnaireComponent, 
    ],
    providers: [ NotesSaveService ],
    exports: ModuleList
})
export class NotesFormComponentsModule {}

