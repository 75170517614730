export const VitalSignsFields: { label: string, field: string }[] =
[{
  label: "Posture",
  field: "pos"
},
{
  label: "Gait",
  field: "gait"
},
{
  label: "Foot/Ankle Alignment",
  field: "foot"
},
{
  label: "Scoliosis",
  field: "sco"
},
{
  label: "Asymmetries",
  field: "ass"
},
{
  label: "Muscle Fasciculation",
  field: "musc"
},
{
  label: "Atrophy",
  field: "atrophy"
},
{
  label: "Face",
  field: "face"
},
{
  label: "Hands",
  field: "hands"
},
{
  label: "Discolouration",
  field: "disco"
}]