import { Action } from '@ngrx/store';
import { SubMenuItem } from 'app/models/menu';
import { MenuItem } from '../../models/menu';

export enum ActionTypes{
    ClickItem = '[Menu Bar] Click Item',
    Loaded = '[Menu API] Menu Loaded Success',
    LoadMenu = '[Menu Bar] Load Menu',
    FilterMenu = '[Menu Bar] Filter Menu',
    SaveMenuScores = '[Menu Bar] Save Scores',
    MenuStateSaved = '[Menu Bar] State Saved',
    ScorePlusOne = '[Menu Bar] Score',
    ToggleFavourite = '[Menu Bar] Toggle Favourite'

}
export class ClickItem implements Action {
    readonly type = ActionTypes.ClickItem;
    constructor(public payload: { title: string; }) {}
  }
  export class Loaded implements Action {
    readonly type = ActionTypes.Loaded;
    constructor(public payload: Array<MenuItem>) {}
  }
  export class LoadMenu implements Action {
    readonly type = ActionTypes.LoadMenu;
  }
  export class FilterMenu implements Action {
    readonly type = ActionTypes.FilterMenu;
    constructor(public payload: String) {}
  }

  export class SaveMenuScores implements Action { 
    readonly type = ActionTypes.SaveMenuScores;
    constructor() {}
  }

  export class MenuStateSaved implements Action {
    readonly type = ActionTypes.MenuStateSaved;
    constructor() {}
  }

  export class MenuScoreAdd implements Action {
    readonly type = ActionTypes.ScorePlusOne;
    constructor(public payload: SubMenuItem) {}
  }

  export class ToggleFavourite implements Action {
    readonly type = ActionTypes.ToggleFavourite;
    constructor(public payload: SubMenuItem) {}
  }

  export type ActionsUnion = ClickItem | Loaded | LoadMenu | FilterMenu;