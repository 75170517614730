<form autocomplete="off" #formEle="ngForm">  
    <h5>Lifestyle</h5>
    <mat-form-field>
        <mat-label>How many units of alcohol do you consume per week</mat-label>
        <input 
            matInput 
            type="text" 
            name="alcoholunits"
            [(ngModel)]="form.alcoholunits">
    </mat-form-field>

    <mat-form-field>
        <mat-label>Do you smoke?</mat-label>
        <input 
            matInput 
            type="text" 
            name="smoker"
            [(ngModel)]="form.smoker">
    </mat-form-field>
    
    <mat-form-field>
        <mat-label>How many per day / frequency?</mat-label>
        <input 
            matInput 
            type="text" 
            name="perday"
            [(ngModel)]="form.perday">
    </mat-form-field>

    <mat-form-field>
        <mat-label>For how long</mat-label>
        <input 
            matInput 
            type="text" 
            name="howlong"
            [(ngModel)]="form.howlong">
    </mat-form-field>

    <mat-form-field>
        <mat-label>Are you an ex-smoker</mat-label>
        <input 
            matInput 
            type="text" 
            name="exsmoker"
            [(ngModel)]="form.exsmoker">
    </mat-form-field>

    <mat-form-field>
        <mat-label>When did you give up</mat-label>
        <input 
            matInput 
            type="text" 
            name="whengiveup"
            [(ngModel)]="form.whengiveup">
    </mat-form-field>
    <mat-form-field>
        <mat-label>How many did you used to smoke per day</mat-label>
        <input 
            matInput 
            type="text" 
            name="howmanydid"
            [(ngModel)]="form.howmanydid">
    </mat-form-field>
</form>