import { Injectable } from "@angular/core";
import * as fromRoot from "../store/reducers";
import { Store } from "@ngrx/store";
import { skipWhile, take } from "rxjs/operators";
import { Observable } from "rxjs";
import { PaymentMethod } from "../models/payment-methods";


@Injectable({
    providedIn: "root"
})
export class PaymentMethodsService {

    constructor(private store: Store<fromRoot.State>) {

    }


    getPaymentMethods(): Observable<PaymentMethod[]> {
        return this.store.select(fromRoot.getPaymentMethods).pipe(
            skipWhile(val => val == null || val.length == 0),
            take(1));
    }

}
