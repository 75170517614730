<div class="strength">
    <div *ngIf="currentValue && passwordToCheck" 
         [ngClass]="!(currentValue.value && currentValue.value > 0) ? 'zero-strength' : 'strength-bar'" 
         [style.width.%]="currentValue.value" 
         [style.background]="currentValue.colour">
        {{ currentValue.value }}%
    </div>
    
    <div *ngIf="missingStrength" class="missing-strength"><span class="error-color">*</span> {{ missingStrength }}</div>
</div>
