<div class="row margin-collapse">
    <div class="col-lg-10">
        <div>
            <h4 class="fg-a">Verify Email</h4>
            
            <div>
                <mat-card><mat-card-content>
                    <p>Emails must be verified with AWS to allow you to send from MultiClinic.</p>
                    <p>We recommend you creae a new unique email address for this, security warnings may appear in clients email if you use and existing email.</p>
                    <mat-form-field style="width:100%">
                        <mat-label>Email Address</mat-label>
                        <input matInput #inputValue 
                            id="emailAddress"
                            name="emailAddress"
                            type="email" 
                            [(ngModel)]="emailAddress" />                      
                    </mat-form-field>
                    <p>Verification Status: <strong>{{status}}</strong> 
                        <button 
                            *ngIf="statusOk!==null"
                            class="ms-4"
                            (click)="emailUpdated()"
                            mat-flat-button color="primary">Check Again</button>
                    </p>

                    <p *ngIf="status == emailStates.success">
                        <mat-icon>check_circle_outline</mat-icon>&nbsp;This from address is valid and can be used to send from MultiClinic
                    </p>
                    <hr/>
                    <p *ngIf="status == emailStates.pending">
                        Please check your email inbox, junk and spam folder on the above address for an email from <em>Amazon Web Services</em>. 
                        Once found please click the link to approve sending.
                    </p>

                    <p *ngIf="statusOk===false">
                        In order to authorise an email address as a FROM address then it is a requirement that you have access to that email inbox. <br/>
                        You will receieve and email from "Amazon Web Services" and you should click the link to verify.                        
                    </p>

                    <p>
                        For best performance you should add the following to to your domains SPF record.
                    </p>
                        <pre>include:amazonses.com</pre>
                        
                    <p>If your record for example already has an include of <span class="font-monospace">example.com</span>, 
                        you should append <span class="font-monospace">include:amazonses.com</span> to the existing record.</p>
                        e.g. <pre>"v=spf1 include:example.com include:amazonses.com ~all"</pre>
                    <p>For more information see <a target="_blank" href="https://docs.aws.amazon.com/ses/latest/dg/send-email-authentication-spf.html">here</a></p>
                    


                    <p *ngIf="statusOk===false"><button mat-flat-button color="primary" (click)="sendVerification()">Send Verification Email</button></p>
                </mat-card-content></mat-card>
            </div>
        </div>
    </div>
  
    <div class="col-sm-2 mobile-margin">
        <div class="fix-top">   
            <div class="desktop-buttons">
                <button mat-raised-button class="w-100" (click)="back()">Back</button>
            </div>
        </div>
    </div>
  
    
  </div>
  