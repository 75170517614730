//import { Action } from '@ngrx/store';
import {State, initialState, ViewTypes} from '../../models/diary';
import { createReducer, on } from '@ngrx/store';

import * as diaryActions from '../actions/diary';
import { ActionWithPayload } from './index';



export{ State, initialState };

const _reducer = createReducer(
  initialState,
  on(diaryActions.SetDate, (state, action)=> state = ({ ...state, viewDate: action.payload})),
  on(diaryActions.SetPrivate, (state, action)=> state = ({ ...state, private: action.payload})),
  on(diaryActions.SetSelectedRoomWeekView, (state, action) => state = ({ ...state, selectedRoomWeekView: action.payload})),
  on(diaryActions.SetSelectedRooms, (state, action) => state = ({ ...state, selectedRooms: action.payload})),
//  on(diaryActions.SetSelectedStaff, (state, action) => state = ({ ...state, selectedStaff: action.payload})),
//  on(diaryActions.SetSelectedStaffWeekView, (state, action) => state = ({ ...state, selectedStaffWeekView: action.payload})),
  on(diaryActions.SetStaffOrRoomView, (state, action) => state = ({ ...state, staffOrRooms: action.payload})),
  on(diaryActions.SetWeekOrDayView, (state, action) => state = ({ ...state, view: action.payload})),
  on(diaryActions.SetZoom, (state, action) => state = ({ ...state, zoom: action.payload})),
  on(diaryActions.SetShowRightPanel, (state, action) => state = ({ ...state, showRightPanel: action.payload})),
  on(diaryActions.SetShowWorking, (state, action) => state = ({ ...state, showWorking: action.payload})),
  on(diaryActions.SetDraggingActive, (state, action) => state = ({ ...state, draggingActive: action.payload})),
  on(diaryActions.SetAvailablity, (state, action) => state = ({ ...state, availability: action.payload})),
  on(diaryActions.SetColours, (state, action) => state = ({ ...state, colours: action.payload})),
  on(diaryActions.SetStartHour, (state, action) => state = ({ ...state, startHour: action.payload, _startHour: action.payload})),
  on(diaryActions.SetEndHour, (state, action) => state = ({ ...state, endHour: action.payload, _endHour: action.payload })),
  on(diaryActions.SetIncrement, (state, action) => state = ({ ...state, increment: action.payload})),
  on(diaryActions.SetHourSegments, (state, action) => state = ({ ...state, hourSegments: action.payload})),
  on(diaryActions.SetStaffGroupBy, (state, action) => state = ({ ...state, staffGroupBy: action.payload})),
  on(diaryActions.SetWorkingHours, (state, action) => state = ({ ...state, startHour: action.payload.startHour || state._startHour , endHour: action.payload.endHour || state._endHour })),
  on(diaryActions.ResetWorkingHours, (state) => state = ({...state, startHour: state._startHour, endHour: state._endHour})),
  on(diaryActions.SetFocusOn, (state, action) => state = ({ ...state, focusedOn: action.payload})),
  on(diaryActions.SetShowCancelled, (state, action) => state = ({ ...state, showCancelled: action.payload})),
  on(diaryActions.SetQuickSegmentBreaks, (state, action)=> state = ({ ...state, 
    morningEnd: +action.payload.morningEnd, 
    afternoonStart: +action.payload.afternoonStart, 
    midSplitEnd: +action.payload.midSplitEnd, 
    midSplitStart: +action.payload.midSplitStart }))
);

export function reducer(state:State = initialState, action: ActionWithPayload) {
    if (action.type == diaryActions.ActionTypes.SET_DATE){
        console.log("[diary] settings reduce", action)
    }
    return _reducer(state, action);
}  

export const getStaffOrRoomsView = (state: State) => state.staffOrRooms;
export const getCurrentDiaryView = (state: State) => state.view;
export const getCurrentDiaryViewState = (state: State) => {
  if (state.view == ViewTypes.DAY) {
    return {
      view: state.view,
      selectedStaff: state.selectedStaff,
      selectedRooms: state.selectedRooms
    };
  }
  return {
    view: state.view,
    selectedStaff: state.selectedStaffWeekView,
    selectedRooms: state.selectedRoomWeekView
  };
 }

// export const getSelectedStaffDayView = (state: State) => state.selectedStaff; //should have been removed when we stopped storing this here
// export const getSelectedStaffWeekView = (state: State) => state.selectedStaffWeekView;

export const getSelectedRoomWeekView = (state: State) => state.selectedRoomWeekView;
export const getSelectedRooms= (state: State) => state.selectedRooms;
export const getCurrentDiaryDate = (state: State) => state.viewDate;
export const getCurrentView = (state: State) => state.view;
export const getCurrentDiaryZoom = (state: State) => state.zoom;
export const getCurrentDiaryPrivateView = (state: State) => state.private;
export const getCurrentDiaryShowRightPanel = (state: State) => state.showRightPanel;
export const getShowWorking = (state: State) => state.showWorking;
export const getDraggingActive = (state: State) => state.draggingActive;
export const getAvailability = (state: State) => state.availability;
export const getColours = (state: State) => state.colours;
export const getStartHour = (state: State) => state.startHour;
export const getEndHour = (state: State) => state.endHour;
export const getIncrement = (state: State) => state.increment;
export const getHourSegments = (state: State) => state.hourSegments;
export const getStaffGroupBy = (state: State) => state.staffGroupBy;
export const getFocusedOn = (state: State) => state.focusedOn;
export const getShowCancelled = (state: State) => state.showCancelled;
export const getMorningEnd = (state: State) => state.morningEnd;
export const getAfternoonStart = (state: State) => state.afternoonStart;
export const getMidSplitStart = (state: State) => state.midSplitStart;
export const getMidSplitEnd = (state: State) => state.midSplitEnd;


/*

export const getDiaryTimeLayoutSettings = (state: State) => { startHour: state.startHour, 
                                                              endHour: state.endHour,
                                                              increment: state.increment,
                                                              hourSegments: state.hourSegments
                                                            }
*/