<div class="form-group" [formGroup]="form">
  <div [ngSwitch]="question.type" class='col-sm-8'>
 
    <div *ngSwitchCase="fieldClasses.PATIENTCHOOSER">
      <label>{{question.label}}</label>
      <patient-chooser [id]="question.key" [formControlName]="question.key" [question]="question"></patient-chooser>
    </div>

    <div *ngSwitchCase="fieldClasses.ADDRESSCHOOSER">
      <address-chooser 
        [question]="question" 
        [formControlName]="question.key" 
        (valueChange)="valueChanged($event)" 
        [externalValue]="value"></address-chooser>
    </div>

    <div *ngSwitchDefault>
      <generic-chooser 
        [question]="question" 
        [formControlName]="question.key" 
        (valueChange)="valueChanged($event)" 
        [externalValue]="value"></generic-chooser>
    </div>
  </div>
</div>
