import { Injectable } from '@angular/core';
import * as notificationAction from '../store/actions/notification';
import * as fromRoot from '../store/reducers';
import { Store } from '@ngrx/store';
import { NotificationMessage } from '../models/notification-message';
import { DataService } from 'app/services/data.service';
import { AuthService } from 'app/services/auth.service';
import { timer } from 'rxjs';




@Injectable()

export class NotificationService {
  private nativeNotifications: boolean;
  private messageListener$;
  constructor(
    private store: Store<fromRoot.State>,
    private http: DataService,
    private auth: AuthService) { 
    }

    startListener(){
      if (this.messageListener$){
        return;
      }

      console.log("[notify] starting notification service");
      const source = timer(5000, 15000);
      source.subscribe(res=>{
        this.checkForMessages();
      });

      
//      this.messageListener$ = setInterval(()=>{
  //      this.checkForMessages();
    //  }, 15000);
      this.requestNotficationPermission();
    }

    send(msg: string): void {
        const date = new Date();
        const timestamp = date.getTime();
        const message: NotificationMessage = { message: msg, timestamp: timestamp, idx: timestamp, systemNotification: false };
        this.dispatchMessage(message)
    }

    private dispatchMessage(message: NotificationMessage){
      this.store.dispatch(new notificationAction.ShowMessage(message));
    }

    checkForMessages(){
      if (!this.auth.isLoggedIn()){
        return;
      }
      this.http.get('/messaging').subscribe((res:any)=>{
        if (res){
          console.log("[message] dispatched", res);
          this.dispatchMessage(
              {idx: res.idx, 
              message: res.message, 
              systemNotification: true, 
              timestamp: res.sentAt*1000});
        }
      })
    }

    requestNotficationPermission() {
      // set the button to shown or hidden, depending on what the user answer
    // Let's check if the browser supports notifications
    if (!('Notification' in window)) {
      console.log("This browser does not support notifications.");
    } else {
      if(this.checkNotificationPromise()) {
        Notification.requestPermission()
        .then((permission) => {
          console.log("[alert] perm status", permission);
          this.nativeNotifications = true;
        })
      } else {
        Notification.requestPermission((permission) => {
          console.log("[alert] perm bad status", permission);
          this.nativeNotifications = false;
        });
      }
    }
  }

    checkNotificationPromise() {
      try {
        Notification.requestPermission().then();
      } catch(e) {
        return false;
      }
  
      return true;
    }

    native(msg:NotificationMessage){
      if (this.nativeNotifications && msg.systemNotification){
        let n = new Notification(msg.message, {tag: `Multiclinic_${msg.idx}`});
        return true;

      }
      
      return false;
    }
}
