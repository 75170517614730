import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { InitalisationService } from 'app/services/initialisation-service';
import { Observable } from 'rxjs';

@Injectable()
export class InitializeGuard  {

  constructor(private initService: InitalisationService, 
      private router: Router) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean  {
    this.initService.init();
    if (!this.initService.checkRequirements()){
      console.log("[init] rejects");
      this.router.navigate(["/loading"]);
      return false;
    }
    console.log("[init] all is ok");
    return true;
  }

}