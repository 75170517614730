<div class="container-fluid notes-table">
  <div class="row ">
    <div class="col-lg-10 table-content">
      <table mat-table [dataSource]="dataSource" matSort matSortActive="lastUpdated" matSortDisableClear matSortDirection="desc">
        <ng-container matColumnDef="idx">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>#</th>
          <td  mat-cell *matCellDef="let element">{{ element.idx }}</td>
        </ng-container>

        <ng-container matColumnDef="dateAdded">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Added</th>
          <td  mat-cell *matCellDef="let element">{{ element.dateAdded | date: 'short' }}</td>
        </ng-container>

        <ng-container matColumnDef="lastUpdated">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Updated</th>
          <td mat-cell *matCellDef="let element">{{ (element.lastUpdated || element.dateAdded) | date: 'short' }}</td>
        </ng-container>

        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef>Description</th>
          <td mat-cell *matCellDef="let element">{{ element.description }}</td>
        </ng-container>

       
        <ng-container matColumnDef="user">
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Staff Member</th>
          <td mat-cell *matCellDef="let element">{{ element.staffMember }}</td>
        </ng-container>

        <ng-container matColumnDef="status" >
          <th mat-header-cell mat-sort-header *matHeaderCellDef>Status</th>
          <td mat-cell *matCellDef="let element">
            <ng-container *ngIf="formName === 'notes_casesummary'">
              <span *ngIf="!(element.changeRequested && element.changeRequested > 0) && element.locked && element.locked === 1 && element.signatureCount === 3" class="bg-success lock-badge">Complete</span>
              <span *ngIf="!(element.changeRequested && element.changeRequested > 0) && ( ( element.signatureCount > 0 && element.signatureCount < 3 ) || element.locked === 0 )" class="bg-danger lock-badge">Incomplete</span>
            </ng-container>

            <ng-container *ngIf="formName !== 'notes_casesummary'">
              <span *ngIf="!(element.changeRequested && element.changeRequested > 0) && element.locked && element.locked === 1" class="bg-danger lock-badge">Complete</span>
              <span *ngIf="!(element.changeRequested && element.changeRequested > 0) && element.locked === 0" class="bg-success lock-badge">Incomplete</span>
            </ng-container>
          
            <span *ngIf="!(element.changeRequested && element.changeRequested > 0) && !element.locked && element.locked !== 0" class="bg-success lock-badge">Open</span>
            <span *ngIf="element.changeRequested && element.changeRequested > 0" class="bg-warning lock-badge">Change Requested</span>
          </td>
        </ng-container>


        <ng-container matColumnDef = "delete">
          <th mat-header-cell *matHeaderCellDef>Delete</th>
          <td mat-cell *matCellDef="let element"><button (click)="delete($event)"><mat-icon>delete</mat-icon></button></td>
        </ng-container>


        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr [class]="strikethrough? 'strikethrough' : ''" 
          *matRowDef="let row; columns: displayedColumns" 
          [ngClass]="{'active-row': selectedNotes?.idx === row.idx}" 
          [id]="row.idx" 
          (click)="selectNote(row.idx,row.type)" 
          class="data-row"
          mat-row ></tr>
      </table>

      <!-- <button mat-flat-button color="primary" (click)="downloadData()">Download As CSV</button> -->

      <mat-card *ngIf="!info || (info && !info.length)">
        <mat-card-content>
        Patient currently does not hold any <strong class="mx-1"> {{ formFriendlyName }} </strong> records.
      </mat-card-content></mat-card>
    </div>
 
    <div class="col-lg-2 mobile-margin">
        <button mat-raised-button color="primary" class="submit-cancel mt-1" (click)="addNewNotes()">
          <mat-icon>add</mat-icon>Add New</button>

          <button mat-stroked-button *ngIf="formName == 'notes_patientquestionaire'" color="primary" class="mt-3 w-100" (click)="requestPatientCompletesForm()">
            Req. Patient Completes</button>
    </div>
  </div>
</div>
