import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { GridOptions, PopupComponent, SideBarDef, ValueFormatterParams } from 'ag-grid-community';
import { GraphConfig } from 'app/models/graph';
import { PaymentMethod } from 'app/models/payment-methods';
import { ReportClickAction } from 'app/models/reports';
import { StaffMember } from 'app/models/staff';
import { FormatterService } from 'app/services/formatter.service';
import { NotificationService } from 'app/services/notification.service';
import { PaymentMethodsService } from 'app/services/payment-methods.service';
import { StaffService } from 'app/services/staff.service';
import { endOfDay, endOfMonth, endOfWeek, format, startOfDay, startOfMonth, startOfWeek } from 'date-fns';
import { BehaviorSubject } from 'rxjs';
import { CustomPinnedRowRenderer } from './custom-pinned-row-renderer/custom-pinned-row-renderer.component';
import { PrintCardDialogService } from './print-card-dialog/print-card-dialog.service';
import { ReportDetails } from './reports.service';

@Injectable()
export class ReportsConfigService {
  public gridSettings: {gridOptions: GridOptions, sideBar: SideBarDef};
  public pivot: boolean = false;
  public dateDeltaState = { unit: null, delta: 0};
  public dateRangeFilterModelSubject = new BehaviorSubject(null);

  constructor( private router: Router,
               private notifications: NotificationService, 
               private formatter: FormatterService,
               private printCardDialog: PrintCardDialogService,
               private paymentMethods: PaymentMethodsService,
               private staffService: StaffService ) {
      this.setGridSettings();
  }

  public cellRendererSelector = (params) => {
    if (params.node.rowPinned) {
        return {
            component: CustomPinnedRowRenderer
           // params: {values: ['Male', 'Female']}
        };
    }
    return undefined;
  }

  public setGridSettings(): void {
    const rowBuffer = 0;
    const rowSelection = "single";
    const rowModelType = "clientSide";
    const cacheBlockSize = 50;
    const cacheOverflowSize = 2;
    const maxConcurrentDatasourceRequests = 1;
    const maxBlocksInCache = 10;

    const defaultColDef = {
      sortable: true,
      resizable: true,
      cellRendererSelector: this.cellRendererSelector,
      filter: true,
      floatingFilter: true,
      filterParams: {
        debounceMs: 0,
        caseSensitive: false,
        suppressAndOrCondition: true,
        /*
        comparator: function (filterLocalDateAtMidnight, cellValue) {
          const dateAsString = cellValue;
          const dateParts = dateAsString.substring(0, 10).split('-');
          const cellDate = new Date(
            Number(dateParts[0]),
            Number(dateParts[1]) - 1,
            Number(dateParts[2])
          );

          //const dateFrom = (document.querySelector(".date-from-input") as any).value;
          //const dateTo = (document.querySelector(".date-to-input") as any).value;

          //const dateFromAsString = dateFrom.split("/");
          //const dateFromDate = new Date( +dateFromAsString[2], +dateFromAsString[1] - 1, +dateFromAsString[0] )

          //const dateToAsString = dateTo.split("/");
          const dateToDate = new Date( +dateToAsString[2], +dateToAsString[1] - 1, +dateToAsString[0] )

          if ( dateFrom && dateTo ) {
            if ( ( dateFromDate.getTime() <= cellDate.getTime() ) && ( dateToDate.getTime() >= cellDate.getTime() ) ) {
              return 0;
            }
          } else if ( dateFrom ) {
            if ( dateFromDate.getTime() <= cellDate.getTime() ) {
              return 0;
            }
          } else if ( dateTo ) {
            if ( dateToDate.getTime() >= cellDate.getTime() ) {
              return 0;
            }
          }
        }*/
      }
    };

    const overlayLoadingTemplate = `
    <div class="spinner-border text-success" style="height: 10vh; width: 10vh" role="status">
    <span class="visually-hidden">Loading...</span>
  </div>`;

    this.gridSettings = {
      gridOptions: {
        rowSelection,
        defaultColDef,
        rowBuffer,
        cacheBlockSize,
        cacheOverflowSize,
        maxConcurrentDatasourceRequests,
        maxBlocksInCache,
        rowModelType,
   //     frameworkComponents,
        overlayLoadingTemplate,
        suppressAggFuncInHeader: true,
        groupIncludeFooter: true,
        groupIncludeTotalFooter: true,
      },
      sideBar: {
        toolPanels: [
          {
              id: 'columns',
              labelDefault: 'Columns',
              labelKey: 'columns',
              iconKey: 'columns',
              toolPanel: 'agColumnsToolPanel',
          },
          {
              id: 'filters',
              labelDefault: 'Filters',
              labelKey: 'filters',
              iconKey: 'filter',
              toolPanel: 'agFiltersToolPanel',
          }
        ],
        position: 'right',
        hiddenByDefault: true,
      }
    }

    console.log(this.gridSettings);
  }
// TODO : stuff

  public pivotMode(reportName: string): boolean {
    this.pivot = ( reportName === "AppointmentsAMonth" || reportName === "insuranceUnpaid" );
    return this.pivot;
  }

  private aggregation(gridOptions: any, columnDefs: any, column: number, columnTitle: string, groupTitle: string): void {
    columnDefs[column].aggFunc = "count";
    columnDefs[column].rowGroup = true;
    columnDefs[column].hide = true;
    columnDefs[column].headerName = columnTitle;

    gridOptions.autoGroupColumnDef = {
      headerName: groupTitle,
      field: 'field_grouped'
    };
  }

  public reportAggregation(gridOptions: any, columnDefs: any, reportName: string): void {
    /*
    switch ( reportName ) {
      case "DNAreport": this.aggregation(gridOptions, columnDefs, 2, "Appointments by Staff member", 'Staff member'); break;
      case "Attendance": this.aggregation(gridOptions, columnDefs, 4, "Attendance by Staff", 'Staff member'); break;
      case "Appointments": this.aggregation(gridOptions, columnDefs, 2, "Appointments by Staff member", 'Staff member'); break;
    }
    */
  }

  public reportConfig(report: ReportDetails, quickDateFilters: any[], makeForm: boolean) {
    if ( report.error ) {
      this.notifications.send(report.error);
      this.router.navigate(["/"]);
      return null;
    }

    for ( let column of report.columnDefs ) {
      if ( column.hide ){
         console.log("[column] HIDE ", column)
         column.hide = true;
         column.initialHide = true;
      } else {
        column.hide = false;
      }

      switch ( column.valueFormatter ) {
        case "date":
          column.valueFormatter = this.dateFormatter.bind(this);
          column.chartDataType = 'time';
          column.suppressMenu = true;
          column.floatingFilterComponentParams = { suppressFilterButton: true };
          break;

        case "datetime":
          column.chartDataType = 'time'
          column.valueFormatter = this.datetimeFormatter.bind(this);
          column.suppressMenu = true;
          column.floatingFilterComponentParams = { suppressFilterButton: true };
          break;

        case "currency":
          column.valueFormatter = this.currencyFormatter.bind(this);
          column.type = 'rightAligned';
          break;
        
        case "number":
          column.comparator =  (valueA, valueB, nodeA, nodeB, isDescending) => valueA - valueB;
          column.valueFormatter = this.roundNumberFormatter.bind(this)
          break;
        case "float":
            column.valueFormatter = this.floatFormatter.bind(this);
            break;
      }

      column.filter = ( column.filter || "agTextColumnFilter" );
      if (column.rowGroup) {
        column.rowGroup = true;
        column.hide = true;
      }

      column.filterParams = ( column.filterParams || {
        debounceMs: 0,
        caseSensitive: false,
        suppressAndOrCondition: true,
      });

      if ( column.filter == "agDateColumnFilter" ) {
        column.filterParams.comparator = this.dateComparator;
        column.filterParams.browserDatePicker = true;
        column.filterParams.inRangeInclusive = true;
        column.alwaysShowBothConditions = true;
      }

      if ( column.quickFilter ){
        if ( column.filter == "agDateColumnFilter" ) {
          quickDateFilters.push({headerName: column.headerName, field: column.field});
        }
      }
    }

    const pivot = this.pivotMode(report.reportName);
    
    if ( pivot && report.reportName === "AppointmentsAMonth" ) {
      const xAxisField = report.columnDefs.findIndex((col: any) => col.field.indexOf(report.graph.xAxis.id) !== -1);
      const yAxisField = report.columnDefs.findIndex((col: any) => col.field.indexOf(report.graph.yAxis.id) !== -1);
      const seriesField = report.columnDefs.findIndex((col: any) => col.field.indexOf(report.graph.series.id) !== -1);

      report.columnDefs[xAxisField].pivot = true;
      report.columnDefs[xAxisField].filter = "agSetColumnFilter";
      report.columnDefs[yAxisField].rowGroup = true;
      report.columnDefs[seriesField].aggFunc = "sum";

      delete this.gridSettings.gridOptions.defaultColDef.filterParams.comparator;
    }

    if ( pivot && report.reportName === "insuranceUnpaid" ) {
      const xAxisField = report.columnDefs.findIndex((col: any) => col.field.indexOf(563) !== -1);

      report.columnDefs[xAxisField].rowGroup = true;

      delete this.gridSettings.gridOptions.defaultColDef.filterParams.comparator;
    }

    makeForm = report.onClick === "MAKEFORM";

    if ( makeForm && report.deleteEnabled) {
      report.columnDefs.push({
        headerName: "",
        field: "delete-row",
        sortable: false,
        filter: false,
        resizable: false,
        width: 48,
        cellRenderer: (params) => {
          return `<i class="material-icons">delete</i>`;
        },
      });
    }

    return report;
  }

  /**
   * Get the correct param when we click on a row to send to the next page
   * @param report 
   * @param rowId 
   * @param params 
   * @returns 
   */
  private getParamForNavigateAway(report: ReportDetails, rowId: number | string, params: any): string|number {
    console.log(`[report] `, report);

    if (report.onClickField){
      const field = report.columnDefs.find((col: any) => col.field === `field_${report.onClickField}`); 
      console.log(`[report] F=`, field, params.data);
      //.map((c: any) => c.field)[0];
      if (field && params.data[field.field]){
        return params.data[field.field];
      } 
    }
    return rowId;


  }

  public navigateReport(report: ReportDetails, rowId: number | string, params: any): void {
    console.log(report, rowId, params);
    rowId = this.getParamForNavigateAway(report, rowId, params);
    switch (report.onClick){
      case ReportClickAction.openForm:
        this.router.navigate([`/form`, report.onClickVar, rowId]);
        return;
      case ReportClickAction.openInvoice:
        this.router.navigate(["/finance/invoice", this.getParamForNavigateAway(report, rowId, params)]);
        return;
      case ReportClickAction.popupAppointmentList:
        this.printCardDialog.getAppointmentDetails(+rowId);
        return;
      case ReportClickAction.openStatement:
        this.router.navigate(["/finance/invoice/transaction-list", rowId]);
        return;
      case ReportClickAction.openCreditsList:
        this.router.navigate(['credits', 'list', rowId]);
        return;
      case ReportClickAction.openPO:
        this.router.navigate(["/finance/po/print", rowId]);
        return;
      case ReportClickAction.accountMembers:
        this.router.navigate(["/form/accountdetails", rowId]);
        return;
      case ReportClickAction.submitFilters:
        this.paymentMethods.getPaymentMethods().subscribe((methods: PaymentMethod[]) => {
          const paymentMethodIdx = methods.find((method: PaymentMethod) => method.name === params.data.field_573).idx;

          this.router.navigate([`/report/${report.onClickVar}`], { queryParams: { [report.onClickField]: paymentMethodIdx } });
          return;
        })
      case ReportClickAction.staffAppointments:
        this.staffService.getStaff().subscribe((staff: StaffMember[]) => {
          const staffMemberIdx = staff.find((staff: StaffMember) => (staff.firstname + " " + staff.lastname) === params.data.field_40).idx;

          // for now the desired behaviour is to prevent any redirect from here
          // this.router.navigate([`/report/${report.onClickVar}`], { queryParams: { [report.onClickField]: staffMemberIdx } });
          return;
        })
        return
      default:

        const field = report.columnDefs.find((col: any) => col.headerName === report.onClickVar); 
        const data = report.reportName === 'MyLetters' ? params.data.rowId : params.data[report.columnDefs[0].field];
        const url = `${report.onClick}/${data}`;
        console.log("[report] navigate", url, params.data, report.columnDefs[0].field);
        this.router.navigateByUrl(url);
        return;
      }
  }

  public getGraphParameters(graphConfig: GraphConfig, chartContainer: any): any {
    const formatDateString = (dateString: string): string => format(new Date(dateString), "dd/MM/yyyy");
    function numberFormatter(params: any) { 
      return +params.value; }

    function categoryFormatter(params: any) {
      const dataCount = params.axis.boundSeries[0].xData.length;
      if ( (( dataCount > 20 ) && ( params.index % 10 === 0 )) || ( dataCount <= 20 ) ) {
        return formatDateString(params.value.value);
      }

      return "";
    }

    const highlightFillColour: string = "green";
    const highlightStrokeColour: string = "yellow";
    const marker: any = {
      enabled: true,
      shape: 'diamond',
      size: 10,
      strokeWidth: 1,
      opacity: 0.2,
    };

    const tick: any = {
      width: 2,
      size: 10,
      color: 'rgba(0,0,0,.1)'
    }

    const tooltip: any = {
      renderer: function (params: any) {
        return {
          content:
            '<b>' + params.xName.toUpperCase() + ':</b> ' + formatDateString(params.xValue) +
            '<br/>' + '<b>' + params.yName.toUpperCase() + ':</b> ' + params.yValue
        };
      }
    }

    const params: any = {
      chartType: graphConfig.chartType,
      chartContainer,
      suppressChartRanges: true,
      chartThemeOverrides: {
        line: {
          navigator: {
            enabled: true,
          },
          series: {
            highlightStyle: {
              fill: highlightFillColour,
              stroke: highlightStrokeColour,
            },
            marker,
            tooltip
          },
        },
        common: {
          padding: {
            top: 20,
            left: 30,
            bottom: 30,
            right: 30,
          },
          autoSize: true,
          legend: {
            enabled: true,
            position: "bottom"
          },
        },
        axes: {
          time: {
            label: {
              rotation: 0,
              format: '%d/%m/%y',
            },
          },
          category: {
              position: 'bottom',
              label: {
                rotation: 0,
                formatter: graphConfig.xAxis.format === 'date' || graphConfig.xAxis.format === 'datetime' ? categoryFormatter : null,
                fontSize: 10
              },
              title: {
                text: graphConfig.xAxis.title,
                enabled: true,
              },
              tick
          },
          number: {
            label: {
              rotation: 0,
              formatter: graphConfig.yAxis.format === 'number' ? numberFormatter : null,
            },
            title: {
              text: graphConfig.yAxis.title,
              enabled: true,
            },
            tick
          }
        }
      }
    }

    if ( !this.pivot ) {
      params.cellRange = {
        columns: [`field_${graphConfig.xAxis.id}`, `field_${graphConfig.yAxis.id}`]
      }
    } else {
      params.chartThemeOverrides.common.legend = {
          enabled: true,
          position: 'left',
          padding: 20,
          item: {
            label: {
              formatter: function (params) {
                var value = String(params.value);
                return value.substring(0,4);
              },
            },
            marker: {
              type: 'diamond',
              size: 10,
              padding: 10,
              strokeWidth: 2,
            },
            paddingX: 120,
            paddingY: 20,
          },
        }
    }
    
    return params;
      
  }

  /** 
  -  * sets the date range filter model, called from custom-date-range component
  -  * takes as parameters the table column, unit and increment/decrement number, start and end date from calendar
  - */
  public setDateRangeFilterModel(qdf: any, delta: number, unit: string, dateFrom: string, dateTo: string) {

    let dateToString = format(new Date(), "yyyy-MM-dd HH:mm:ss");
    let dateFromString = format(new Date(), "yyyy-MM-dd HH:mm:ss");
    let label = "";

    if (dateFrom && dateTo) { // calendar dates were selected
      dateFromString = dateFrom;
      dateToString = dateTo;
    } else { // delta and unit was selected
      if (this.dateDeltaState.unit == unit) {
        delta = this.dateDeltaState.delta + delta;
      }

      if (unit == "d") {
        const baseDate = new Date();
        baseDate.setDate(baseDate.getDate() + delta);
        
        const endBaseDate = new Date();
        endBaseDate.setDate(baseDate.getDate() + 1);

        dateFromString = format(startOfDay(baseDate), "yyyy-MM-dd HH:mm:ss");
        dateToString = format(startOfDay(endBaseDate), "yyyy-MM-dd HH:mm:ss");

        if (delta == 0) {
          label = "Today"
        } else if (delta == 1) {
          label = "Tomorrow"
        } else if (delta == -1) {
          label = "Yesterday"
        } else if (delta > 0) {
          label = `+${delta} days`
        } else {
          label = `${delta} days`
        }
      }

      if (unit == "w") {
        const baseDate = new Date();
        baseDate.setDate(baseDate.getDate() + (7 * delta));
        
        const endBaseDate = new Date();
        endBaseDate.setDate(baseDate.getDate() + 7);
        
        dateFromString = format(startOfWeek(baseDate), "yyyy-MM-dd HH:mm:ss");
        dateToString = format(startOfWeek(endBaseDate), "yyyy-MM-dd HH:mm:ss");

        if (delta == 0) {
          label = "This Week"
        } else if (delta == 1) {
          label = "Next Week"
        } else if (delta == -1) {
          label = "Last Week"
        } else if (delta > 0) {
          label = `+${delta} weeks`
        } else {
          label = `${delta} weeks`
        }
      }

      if (unit == "m") {
        const baseDate = new Date();
        baseDate.setMonth(baseDate.getMonth() + delta, 1);

        const endBaseDate = new Date();
        endBaseDate.setMonth(baseDate.getMonth() + 1, 1);

        dateFromString = format(startOfMonth(baseDate), "yyyy-MM-dd HH:mm:ss");
        dateToString = format(startOfMonth(endBaseDate), "yyyy-MM-dd HH:mm:ss");
        
        if (delta == 0) {
          label = "This Month"
        } else if (delta == 1) {
          label = "Next Month"
        } else if (delta == -1) {
          label = "Last Month"
        } else if (delta > 0) {
          label = `+${delta} months`
        } else {
          label = `${delta} months`
        }
      }
      // Gets start of the month also clears filters on reports back to start month
      if (unit == "bm") {
        const baseDate = new Date();
        baseDate.setMonth(baseDate.getMonth() - delta, 1);
        dateFromString = format(startOfMonth(baseDate), "yyyy-MM-dd HH:mm:ss");
        dateToString = format(endOfMonth(baseDate), "yyyy-MM-dd HH:mm:ss");
      
        if (delta == 0) {
          label = "This Month"
        } else if (delta == 1) {
          label = "Next Month"
        } else if (delta == -1) {
          label = "Last Month"
        } else if (delta > 0) {
          label = `+${delta} months`
        } else {
          label = `${delta} months`
        }
      }
      
    }

    this.dateDeltaState = { unit: unit, delta: delta };

    // save new filter mnodel to Subject
    this.dateRangeFilterModelSubject.next({
      label: label,
      column: qdf,
      model: {
        dateFrom: dateFromString, dateTo: dateToString, type: "inRange", filterType: "date"
      }
    })
  }

  public onGridSizeChanged(gridWidth: any, params: any): void {

    /* removed as messes with visible columns on resize. idk why
    const columnsToShow = [];
    const columnsToHide = [];
  
    if ( !params || !params.columnApi ) return;

    let totalColsWidth = 0;
    const allColumns = params.columnApi.getColumns();
    for (var i = 0; i < allColumns.length; i++) {
      const column = allColumns[i];
      totalColsWidth += column.getMinWidth();
      if (totalColsWidth > gridWidth) {
        columnsToHide.push(column.colId);
      } else {
        columnsToShow.push(column.colId);
      }
    }
  
    params.columnApi.setColumnsVisible(columnsToShow, true);
    params.columnApi.setColumnsVisible(columnsToHide, false);
  */
    params.api.sizeColumnsToFit();
  }

  datetimeFormatter(params: ValueFormatterParams): string {
    return this.formatter.datetimeFormatter(params.value);
  }

  dateFormatter(params: ValueFormatterParams): string {
    return this.formatter.dateFormatter(params.value);
  }
  
  floatFormatter(params: ValueFormatterParams): number {
    return this.formatter.floatFormatter(params.value);
  }
  
  currencyFormatter(params: ValueFormatterParams) {
    return this.formatter.currencyFormatter(params.value);
  }

  roundNumberFormatter(params: ValueFormatterParams) {
    return this.formatter.roundNumberFormatter(params.value)
  }


  dateComparator (date1, date2) {
    const time1 = new Date(date1).getTime();
    const time2 = new Date(date2).getTime();

   // console.log("[date comp]", date1, date2, (time1-time2));
    return time2-time1;
  }

  clearFilters(){
    this.dateDeltaState = { unit: null, delta: 0};
  }
}
