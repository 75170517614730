import { Injectable } from "@angular/core";
import { StaffRoomsView } from "app/models/diary";
import { DiaryService } from "./diary.service";

@Injectable({
  providedIn: "root"
})
export class ZoomService {
  private _segmentHeight: number;
  private _columnWidth = 150;
  private numberOfColumns = 20;
  private numberOfElements = 0;
  private view = StaffRoomsView.STAFF;
  private selectedStaffLength = 0;
  private selectedRoomsLength = 0;

  public readonly MIN_ZOOM = 15;
  public readonly MAX_ZOOM = 50;

  constructor(private diary: DiaryService) {
    const updateWidth = (): void => {
      if (this.view === StaffRoomsView.STAFF) {
        this.width = this.selectedStaffLength
      } else {
        this.width = this.selectedRoomsLength
      }
    };

    this.diary.getStaffOrRoomsView().subscribe(view => {
      this.view = view
      updateWidth()
    });

    this.diary.getCurrentZoom().subscribe(res => {
      this._segmentHeight = res;
    });

    this.diary.getSelectedStaffDayView().subscribe(res => {
      this.selectedStaffLength = res.length
      updateWidth()
    })

    this.diary.getSelectedRooms().subscribe(res => {
      console.log(res);
      if (res) {
        this.selectedRoomsLength = res.length;
        updateWidth();
      }
    });

    this.diary.getSidebarHidden().subscribe(res => {
      this.numberOfColumns = res ? 20 : 25;
      this._columnWidth = this.numberOfElements > 0 ? Math.floor((Math.floor(((window.innerWidth - 255) / 25) * this.numberOfColumns) - 100) / this.numberOfElements) : 0
      this.diary.diaryEventEmitter.emit("width");
    });

  }

  get height(): number {
    return this._segmentHeight;
  }
  set height(value: number) {
    if (value > this.MAX_ZOOM) { value = this.MAX_ZOOM; }
    if (value < this.MIN_ZOOM) { value = this.MIN_ZOOM; }
    this._segmentHeight = value;
    this.diary.setZoom(value);
    this.diary.diaryEventEmitter.emit("width");
  }
  get width(): number {
    return this._columnWidth;
  }
  set width(value: number) {
    this.numberOfElements = value;
    this._columnWidth = value > 0 ? Math.floor((Math.floor(((window.innerWidth - 255) / 25) * this.numberOfColumns) - 100)
                                                / this.numberOfElements) : 0;
    console.log(`${value} : ${this._columnWidth}`);
    this.diary.diaryEventEmitter.emit("width");
  }

}
