import { Component, OnInit } from '@angular/core';
import { NotesBuilderBaseComponent } from 'app/notes/notes-builder/notes-builder-base/notes-builder-base-component';
import { ReapsQuestions } from '../models/reaps';


@Component({
  selector: 'app-reaps',
  templateUrl: './reaps.component.html',
  styleUrls: ['./reaps.component.scss']
})
export class ReapsComponent extends NotesBuilderBaseComponent {
  ReapsQuestions = ReapsQuestions
}
