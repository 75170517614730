import { Component, OnInit, ViewChild } from '@angular/core';
import { MatOption } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DynamicFormSaveService } from 'app/dynamic-form/services/dynamic-form-save.service';
import { FilesService } from 'app/notes/uploader/files.service';
import { DialogService } from 'app/services/dialog/dialog.service';
import { FormTemplateBaseComponent } from '../form-template-base/form-template-base.component';

@Component({
  selector: 'app-form-dropdown-multi',
  templateUrl: './form-dropdown-multi.component.html',
  styleUrls: ['./form-dropdown-multi.component.css']
})
export class FormDropdownMultiComponent extends FormTemplateBaseComponent {
  @ViewChild('inputValue') private inputValue;

  constructor(fileService: FilesService, dialogService: DialogService, router: Router, route: ActivatedRoute, dynamicFormSaveService: DynamicFormSaveService) {
    super(fileService, dialogService, router, dynamicFormSaveService)
  }

  ngOnInit() {
  }

  selectAllToggle() {
    const selected = this.inputValue.options.find(opt => opt.selected);
    if (selected) {
      this.inputValue.options.forEach((item: MatOption) => item.deselect());
    } else {
      this.inputValue.options.forEach((item: MatOption) => item.select());
    }
  }

}
