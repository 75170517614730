<!--
  <mat-form-field [ngClass]="{
       'rom-1' : value=='-1', 
       'rom-2' : value=='0', 
       'rom-3' : value=='1', 
       'rom-4' : value=='2' }">
  <mat-select [(ngModel)]="value">
    <mat-option value="-1" class="rom-1">--</mat-option>
    <mat-option value="0" class="rom-2">
      &nbsp;
    </mat-option>
    <mat-option value="1" class="rom-3">
      &nbsp;
    </mat-option>
    <mat-option value="2" class="rom-4">
      &nbsp;
    </mat-option>
  </mat-select>
</mat-form-field>
-->

  
  <button  mat-icon-button [disabled]="isDisabled" [matMenuTriggerFor]="rom" 
[ngClass]="{
  'rom-1': value=='-1' || !value,
  'rom-2': value=='0',
  'rom-3': value=='1',
  'rom-4': value=='2'
}">
  <mat-icon *ngIf="value=='-1' || !value">more_horiz</mat-icon>
  <mat-icon *ngIf="value=='0'">panorama_fish_eye</mat-icon>
  <mat-icon *ngIf="value=='1'">clear</mat-icon>
  <mat-icon *ngIf="value=='2'">highlight_off</mat-icon>
</button>
<mat-menu #rom="matMenu">
  <button mat-menu-item [disabled]="isDisabled"  class="rom-1 text-center" (click)="value='-1'"><mat-icon>more_horiz</mat-icon></button>
  <button mat-menu-item [disabled]="isDisabled" class="rom-2 text-center" (click)="value='0'"><mat-icon>panorama_fish_eye</mat-icon></button>
  <button mat-menu-item [disabled]="isDisabled" class="rom-3 text-center" (click)="value='1'"><mat-icon>clear</mat-icon></button>
  <button mat-menu-item [disabled]="isDisabled" class="rom-4 text-center" (click)="value='2'"><mat-icon>highlight_off</mat-icon></button>
</mat-menu>






