/* Converts question classses into a valid form group */
import { Injectable, Input } from "@angular/core";
import {
  UntypedFormControl,
  FormGroup,
  UntypedFormBuilder,
  Validators,
} from "@angular/forms";
import { QuestionBase, fieldType } from "../question-base";

interface ValidationResult {
  valid: boolean;
  error: string;
}

class EmailValidator {
  static validEmail(control: UntypedFormControl): ValidationResult {
    var re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (control.value == "") {
      return null; //{ 'validEmail': false }; blank is considered valid; this is handled by the required field
    }

    if (!re.test(control.value)) {
      return {
        valid: false,
        error: "Invalid email",
      };
    }

    return null;
  }
}

class MustBeCheckedValidator {
  static mustBeChecked(control: UntypedFormControl): ValidationResult {
    if (!control.value) {
      return {
        valid: false,
        error: "Checkbox required",
      };
    } else {
      return null;
    }
  }
}

class MustSelectOptionValidator {
  static mustselect(control: UntypedFormControl): ValidationResult {
    if (!control.value) {
      return {
        valid: false,
        error: "Select an option",
      };
    } else {
      return null;
    }
  }
}

@Injectable()
export class QuestionControlService {
  constructor(private _fb: UntypedFormBuilder) {}

  toFormBuilder(questions: QuestionBase<any>[]) {
    if (!questions || !questions[0]) return;
    let group: UntypedFormControl[] = [];
    //    let val: any = "";
    let locked: boolean = false;
    console.log(questions);
    questions
      //            .filter(f => (f.controlType != fieldType.FILEUPLOAD))
      .forEach((question) => {
        if (question) {
          let val: string = ""; //clear it out
          if (question && question.value) {
            if (question.value == null) {
              val = "";
              locked = false;
            } else {
              val = question.value;
              locked = false;
            }
          }
          let validators: any = [];
          if (question.required && question.controlType == fieldType.CHECKBOX) {
            validators.push(MustBeCheckedValidator.mustBeChecked);
          } else if (
            question.required &&
            question.controlType == fieldType.DROPDOWN
          ) {
            validators.push(MustSelectOptionValidator.mustselect);
          } else if (question.required) {
            validators.push(Validators.required);
          }
          if (question.type == "email") {
            validators.push(EmailValidator.validEmail);
          }
          //Remove this once DOB question has question.type date
          if (question.type == "dateofbirth") {
            question.type = "date";
          }

          let asyncValidator = null;
          // let asyncValidators = [];
          switch (question.validators) {
            case "":
              break; //nothing to see here
            default:
              console.error("Unknown validator: ");
          }

          const disabled = question.options && (question.options as any) === 'disabled';

          console.log("[form] sets", question.key, val);
         // if (group[question.key]) question.key = question.key + "2"
          group[question.key] = new UntypedFormControl(
            { value: val || "", disabled },
            validators,
            asyncValidator
          );
          if (locked) group[question.key].disable();
        }
      });
    return this._fb.group(group);
  }
}
