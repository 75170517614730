import { Injectable } from '@angular/core';
import {
    Actions,
    ofType,
    createEffect
} from '@ngrx/effects';
import { Action } from '@ngrx/store';

import { mergeMap, map } from 'rxjs/operators';
import * as paymentMethodsActions from '../actions/payment-methods';
import { DataService } from '../../services/data.service';
import { PaymentMethod } from '../../models/payment-methods';

@Injectable()
export class PaymentMethodEffects {
    constructor(private http: DataService, private actions$: Actions,) { }

    // ngrxOnInitEffects(): Action {
    //     return { type: paymentMethodsActions.ActionTypes.INIT };
    // }


    initPaymentMethods$ =  createEffect(() => {
        return this.actions$
            .pipe(
                ofType(paymentMethodsActions.ActionTypes.INIT),
                mergeMap(() => this.http.get <PaymentMethod[]>('/payment-methods')
                    .pipe(
                        map(method => {
                            console.log("diary state", method)
                            method = method.map(pm => {
                                console.log(pm);
                                if (pm.colour == "#FFFFFF" || !pm.colour){
                                  return Object.assign(pm, {colour: "#CCCCCC"});
                                }
                                return pm;
                            });
                            return new paymentMethodsActions.SetAction(method)
                        })
                ))
            );
        });
}

