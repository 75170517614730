import { UploaderCaptions } from './uploader-captions';

export const DefaultCaptions: UploaderCaptions = {
    dropzone: {
        title: 'Drag and drop file here',
        or: 'or',
        browse: 'Browse Files'
    },
    cropper: {
        crop: 'Crop',
        cancel: 'Cancel'
    },
    previewCard: {
        remove: 'Remove',
        uploadError: 'Error on upload'
    }
};
