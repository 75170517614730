import { Component, OnInit } from "@angular/core";
import { SubMenuItem } from "app/models/menu";
import { NavBarService } from "app/services/menu/nav-bar.service";
import { SubMenuService } from "app/services/menu/sub-menu.service";

@Component({
  selector: "app-menu-settings",
  templateUrl: "./menu-settings.component.html",
  styleUrls: ["./menu-settings.component.scss"],
})
export class MenuSettingsComponent implements OnInit {
  public menu: any;
  public filteredMenu: any;

  constructor(private nav: NavBarService, private subMenu: SubMenuService) {}

  ngOnInit(): void {
    this.nav.getMenuFromApi().subscribe((menu: any) => {
      this.menu = menu;
      this.filteredMenu = this.getSubMenu(menu.menuItems);
    });

    this.nav.saveMenu();
  }

  public getSubMenu(menu: any): any {
    return menu.filter((menuItem: any) => menuItem.subItems.length);
  }

  public useSession(menu: any) {
    const subMenuItems: any[] = [];
    menu.forEach((menuItem: any) => {
      menuItem.subItems.forEach((subItem: any) => {
        console.log(subItem)
        subMenuItems.push({
          [subItem.title]: {
            id: subItem.idx,
            favourite: subItem.favourite,
            score: subItem.score
          }
        });
      })
    })

    return subMenuItems;
  }

  public updateMenu(subItem: any): void {
    return this.menu.menuItems.map((menuItem: any) => {
      if (menuItem.idx === subItem.parentIdx) {
        const subItems = menuItem.subItems.map((subItem_: any) => {
          if (subItem_.idx === subItem.idx) {
            return {
              ...subItem_,
              favourite: !subItem.favourite,
              score: !subItem.favourite ? 10 : 0,
            };
          }

          return subItem_;
        });

        return {
          ...menuItem,
          subItems,
        };
      }

      return menuItem;
    });
  }

  public starClicked(subItem: any, menuItemTitle: string): void {
    subItem.favourite = !subItem.favourite;
    this.nav.toggleFavourite(subItem);
    
  }
}