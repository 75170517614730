<form autocomplete="off" #formEle="ngForm">   
    <mat-form-field>
        <mat-label>Current Medication</mat-label>
        <textarea 
            matInput
            cdkTextareaAutosize
            cdkAutosizeMinRows="1"
            cdkAutosizeMaxRows="12"
            name="Current"
            [(ngModel)]="form.Current"></textarea>
    </mat-form-field>

    <mat-form-field>
        <mat-label>Past Medication</mat-label>
        <textarea 
            matInput
            cdkTextareaAutosize
            cdkAutosizeMinRows="1"
            cdkAutosizeMaxRows="12"
            name="Past"
            [(ngModel)]="form.Past"></textarea>
    </mat-form-field>

    <mat-form-field>
        <mat-label>Please specify any drug allergies?</mat-label>
        <textarea 
            matInput
            cdkTextareaAutosize
            cdkAutosizeMinRows="1"
            cdkAutosizeMaxRows="12"
            name="DrugAllergies"
            [(ngModel)]="form.DrugAllergies"></textarea>
    </mat-form-field>
</form>