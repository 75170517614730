import { Store, ActionReducer, ActionReducerMap, combineReducers, compose, Action, MetaReducer } from '@ngrx/store';
import { localStorageSync, LocalStorageConfig } from 'ngrx-store-localstorage';

import { createSelector } from 'reselect';

import * as fromAuth from './auth';
import * as fromSiteSettings from './site-settings';
import * as fromUserSettings from './user-settings';
import * as fromLoading from './loading';
import * as fromNotification from './notification';
import * as fromMenu from './menu'
import * as fromAppointments from './appointments'
import * as fromTreatments from './treatments';
import * as fromStaff from './staff';
import * as fromPatient from './patient';
import * as fromRooms from './rooms';
import * as fromSaleItems from './saleItems';
import * as fromPaymentMethods from './payment-methods';
import * as fromClinics from './clinics';
import * as fromDiary from './diary';
import * as fromConfig from './config';
import * as fromNotes from './notes';

//its a dev thing
import { storeFreeze } from 'ngrx-store-freeze';
import { environment } from '../../../environments/environment';
import { ActionTypes } from '../actions/auth';
import { startOfDay } from 'date-fns';

export interface State {
    auth: fromAuth.State;
    siteSettings: fromSiteSettings.State;
    userSettings: fromUserSettings.State;
    notification: fromNotification.State;
    loading: fromLoading.State;
    menu: fromMenu.State;
    appointments: fromAppointments.State;
    treatments: fromTreatments.State;
    staff: fromStaff.State;
    patient: fromPatient.State;
    rooms: fromRooms.State;
    saleItems: fromSaleItems.State;
    paymentMethods: fromPaymentMethods.State;
    clinics: fromClinics.State;
    diary: fromDiary.State;
    config: fromConfig.State;
    notes: fromNotes.State;
}

export interface ActionWithPayload extends Action {
  payload?: any;
}

export const reducers: ActionReducerMap<State> = {
    auth: fromAuth.reducer,
    siteSettings: fromSiteSettings.reducer,
    userSettings: fromUserSettings.reducer,
    notification: fromNotification.reducer,
    loading: fromLoading.reducer,
    menu: fromMenu.reducer,
    appointments: fromAppointments.reducer,
    treatments: fromTreatments.reducer,
    staff: fromStaff.reducer,
    patient: fromPatient.reducer,
    rooms: fromRooms.reducer,
    saleItems: fromSaleItems.reducer,
    paymentMethods: fromPaymentMethods.reducer,
    clinics: fromClinics.reducer,
    diary: fromDiary.reducer,
    config: fromConfig.reducer,
    notes: fromNotes.reducer
};

export const initialState: State = {
    auth: fromAuth.initialState,
    siteSettings: fromSiteSettings.initialState,
    userSettings: fromUserSettings.initialState,
    notification: fromNotification.initialState,
    loading: fromLoading.initialState,
    menu: fromMenu.initialState,
    appointments: fromAppointments.initialState,
    treatments: fromTreatments.initialState,
    staff: fromStaff.initialState,
    patient: fromPatient.initialState,
    rooms: fromRooms.initialState,
    saleItems: fromSaleItems.initialState,
    paymentMethods: fromPaymentMethods.initialState,
    clinics: fromClinics.initialState,
    diary: fromDiary.initialState,
    config: fromConfig.initialState,
    notes: fromNotes.initialState
}

export const localStorageConfig: LocalStorageConfig = { 
    keys: ['auth', 'diary', 'userSettings', 'siteSettings', 'rooms', 'staff', 'treatments', 'saleItems', 'paymentMethods', 'clinics','config', 'forms', 'menu', 'patient'], //added patient to store for f5..
    rehydrate: true };

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync(localStorageConfig)(reducer);
}


export function clearState(reducer) {
    return function (state, action) {
  
      if (action.type === ActionTypes.LOGOUT_CLEAR) {
          console.log("[REDUCE] reducto!")
        return reducer(initialState, action);
      }
  
      return reducer(state, action);
    };
}

export const developmenMetaReducers: MetaReducer<State>[] = [localStorageSyncReducer, storeFreeze, clearState];
export const productionMetaReducers: MetaReducer<State>[] = [localStorageSyncReducer, clearState];
export const metaReducers: MetaReducer<State>[] 
    = !environment.production ? developmenMetaReducers : productionMetaReducers;

/**
 * Every reducer module exports selector functions, however child reducers
 * have no knowledge of the overall state tree. To make them useable, we
 * need to make new selectors that wrap them.
 *
 * The createSelector function from the reselect library creates
 * very efficient selectors that are memoized and only recompute when arguments change.
 * The created selectors can also be composed together to select different
 * pieces of state.
 */

export const getState = (state:State) => state;
export const getAuthState = (state: State) => state.auth;
export const getSiteSettingsState = (state: State) => state.siteSettings;
export const getUserSettingsState = (state: State) => state.userSettings;
export const getLoadingState = (state: State) => state.loading;
export const getNotificationState = (state: State) => state.notification;
export const getMenuState = (state: State) => state.menu;
export const getAppointmentState = (state: State) => state.appointments;
export const getStaffState = (state: State) => state.staff;
export const getTreatmentsState = (state: State) => state.treatments;
export const getPatientState = (state: State) => state.patient;
export const getRoomsState = (state: State) => state.rooms;
export const getSaleItemsState = (state: State) => state.saleItems;
export const getPaymentMethodsState = (state: State) => state.paymentMethods;
export const getClincsState = (state: State) => state.clinics;
export const getDiaryState = (state: State) => state.diary;
export const getConfigState = (state: State) => state.config;
export const getNotesState = (state: State) => state.notes;

export const getIconStyleSetting = createSelector(getUserSettingsState, fromUserSettings.getIconStyle);

//DIARY
export const getCurrentDiaryView = createSelector(getDiaryState, fromDiary.getCurrentDiaryView);
export const getStaffOrRoomsView  = createSelector(getDiaryState, fromDiary.getStaffOrRoomsView);
// export const getSelectedStaffDayView = createSelector(getDiaryState, fromDiary.getSelectedStaffDayView);
export const getSelectedRooms = createSelector(getDiaryState, fromDiary.getSelectedRooms);


//export const getSelectedStaffWeekView = createSelector(getDiaryState, fromDiary.getSelectedStaffWeekView);
export const getSelectedStaff = createSelector(getStaffState, fromStaff.getSelectedStaff);
/////////////////

export const getSelectedRoomWeekView = createSelector(getDiaryState, fromDiary.getSelectedRoomWeekView);
export const getCurrentViewDate = createSelector(getDiaryState, fromDiary.getCurrentDiaryDate);
export const getCurrentDiaryZoom = createSelector(getDiaryState, fromDiary.getCurrentDiaryZoom);
export const getCurrentDiaryPrivateView = createSelector(getDiaryState, fromDiary.getCurrentDiaryPrivateView);
export const getCurrentDiaryShowRightPanel = createSelector(getDiaryState, fromDiary.getCurrentDiaryShowRightPanel);
export const getShowWorking = createSelector(getDiaryState, fromDiary.getShowWorking);
export const getDraggingActive = createSelector(getDiaryState, fromDiary.getDraggingActive);
export const getAvailability = createSelector(getDiaryState, fromDiary.getAvailability);
export const getColours = createSelector(getDiaryState, fromDiary.getColours);
export const getStartHour = createSelector(getDiaryState, fromDiary.getStartHour);
export const getEndHour = createSelector(getDiaryState, fromDiary.getEndHour);
export const getIncrement = createSelector(getDiaryState, fromDiary.getIncrement);
export const getHourSegments = createSelector(getDiaryState, fromDiary.getHourSegments);
export const getStaffGroupBy = createSelector(getDiaryState, fromDiary.getStaffGroupBy);
export const getDiaryView = createSelector(getDiaryState, fromDiary.getCurrentView);
export const getFocusedOn = createSelector(getDiaryState, fromDiary.getFocusedOn);
export const getShowCancelled = createSelector(getDiaryState, fromDiary.getShowCancelled);
export const getMorningEnd = createSelector(getDiaryState, fromDiary.getMorningEnd)
export const getAfternoonStart = createSelector(getDiaryState, fromDiary.getAfternoonStart);
export const getMidSplitStart = createSelector(getDiaryState, fromDiary.getMidSplitStart);
export const getMidSplitEnd = createSelector(getDiaryState, fromDiary.getMidSplitEnd);

export const getCalendarSettings = createSelector(getStartHour, getEndHour, getIncrement, getHourSegments, getMorningEnd, getAfternoonStart, getMidSplitStart, getMidSplitEnd, 
                                                (start, end, inc, segments, morning, afternoon, midSplitStart, midSplitEnd) => 
    {
        return { startHour: start, endHour: end, increment: inc, hourSegments: segments, morningEnd: morning, afternoonStart: afternoon, midSplitStart: midSplitStart, midSplitEnd: midSplitEnd  }
    });

export const getDiaryStartEndHour = createSelector(getStartHour, getEndHour, (start, end) =>
    {
        return { startHour: start, endHour: end}
});
    
//export const getSubMenuLastUsed = createSelector(getSettingsState, fromSettings.getSubMenuLastUsed);
export const getSiteSettings = createSelector(getSiteSettingsState, fromSiteSettings.getSiteSettings);
export const getNotesSettings = createSelector(getSiteSettingsState, fromSiteSettings.getNotesSettings);
export const getReportFilters = createSelector(getUserSettingsState, fromUserSettings.getReportFilters);
export const getMobileView = createSelector(getUserSettingsState, fromUserSettings.getMobileView);
export const getDarkMode = createSelector(getUserSettingsState, fromUserSettings.getDarkMode);

//diary view has both staff sides and day wwe info
export const getDiaryDisplayState = 
    createSelector(getCurrentDiaryView, getSelectedStaff, getSelectedRooms, getSelectedRoomWeekView, getStaffOrRoomsView,
    (view,staff, rooms, roomWeek, staffOrRooms) => { 
        return { view: view, staffDayView: staff, roomDayView: rooms, roomWeekView: roomWeek, staffOrRooms: staffOrRooms} });

//detects if any settings that require a reload of the diary from the api are changed hmm what changes?....
export const getDiaryDataRefreshNeeded = 
    createSelector(getCurrentDiaryView, getSelectedStaff, getSelectedRooms, getSelectedRoomWeekView, getStaffOrRoomsView,
    (view, staff,  rooms, roomWeek, staffOrRooms) => { 
        return { view: view, staffDayView: staff, roomDayView: rooms, roomWeekView: roomWeek, staffOrRooms: staffOrRooms} });

export const getSelectedStaffCurrentView = createSelector( getCurrentDiaryView, getSelectedStaff, 
    (view, staff)  => {
        return {view: view, staff: staff }
    });

//loading?
export const getLoading = createSelector(getLoadingState, fromLoading.getLoading);
export const getBackgroundLoading = createSelector(getLoadingState, fromLoading.getBackground);

//any notifications?
export const getNotificationMessages = createSelector(getNotificationState, fromNotification.getMessage);

export const getMenu = createSelector(getMenuState, fromMenu.getMenu);
export const getFilteredMenu = createSelector(getMenuState, fromMenu.getFilteredMenu);
//export const getAppointments = createSelector(getAppointmentState, fromAppointments.getAppointments);

export const getStaff = createSelector(getStaffState, fromStaff.getStaff);
export const getTreatments = createSelector(getTreatmentsState, fromTreatments.getTreatments);
export const getRooms = createSelector(getRoomsState, fromRooms.getRooms);
export const getSaleItems = createSelector(getSaleItemsState, fromSaleItems.getSaleItems);
export const getPaymentMethods = createSelector(getPaymentMethodsState, fromPaymentMethods.getpaymentMethods);

export const neededData = createSelector(getStaff, getTreatments, (staff, treatments) => { return { staff: staff, treatments: treatments } } );

export const getCurrentPatient = createSelector(getPatientState, fromPatient.getPatient);
export const getCurrentPatientIdx = createSelector(getPatientState, fromPatient.getPatientIdx);

export const getClinics = createSelector(getClincsState, fromClinics.getClinics);
export const getSelectedClinic = createSelector(getClincsState, fromClinics.getSelectedClinic);

export const getConfig = createSelector(getConfigState, fromConfig.getState)
//auth
export const getJWT = createSelector(getAuthState, fromAuth.getJWT);
export const getPermissions = createSelector(getAuthState, fromAuth.permissions);

// Notes
export const getLatestStatus = createSelector(getNotesState, fromNotes.getLatestStatus);