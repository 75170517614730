
<mat-selection-list #subformsList class="mt-1 mb-1 overflow-auto form-list "
  (selectionChange)="onGroupsChange(subformsList.selectedOptions.selected)">
  <mat-list-option [selected]="true" [value]="{select: 'all'}" (click)="selectAll($event)">
    SELECT ALL
  </mat-list-option>
  <mat-list-option *ngFor="let subform of questionnaireSubforms" [value]="subform" [selected]="true">
    {{subform.label}}
  </mat-list-option>
</mat-selection-list>
