import { Action, createAction, props } from '@ngrx/store';
import { RecievedAppointment, Appointment } from '../../models/appointments';

export enum ActionTypes{
    Loaded = '[Appointments API] Appointments Loaded Success',
    LoadAppointments = '[Diary Service] Load Appointments',
    SaveStartTime = '[Appointments API] Save Start Time'
}

export const Loaded           = createAction(ActionTypes.Loaded, props<{payload: Array<RecievedAppointment>}>());
export const LoadAppointments = createAction(ActionTypes.LoadAppointments, props<{payload: Appointment}>());
export const SaveStartTime    = createAction(ActionTypes.SaveStartTime, props<{payload: {idx: number, newStart: String, duration: number, newRoom: any}}>());

