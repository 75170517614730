import { HttpClient } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { NavigationService } from "app/services/navigation.service";
import { XZReportsService } from "app/services/xzreports.service";

@Component({
  selector: "app-report-selector",
  templateUrl: "./report-selector.component.html",
  styleUrls: ["./report-selector.component.scss"],
})
export class ReportSelectorComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    private xzReportsService: XZReportsService,
    private nav: NavigationService
  ) {}
  tillsData = [];
  total: number;

  ngOnInit(): void {
    this.retrieveReport();
  }

  retrieveReport() {
    this.xzReportsService.getTillsFromServer().subscribe((res) => {
    this.tillsData = res;
    this.updateTotal()
    });
    return this.tillsData;
  }

  updateTotal(){
    this.total = this.tillsData.reduce((a,b) => a + Number(b.TotalAmount), 0)
  }

  back(){
    this.nav.back()
  }
}
