import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Store } from "@ngrx/store";

import { DataService } from "../services/data.service";
import { type Attendance, type AttendanceSummary, type PatientChooserResult, type PatientDetails, type PatientNotesInfo } from "../models/patient-details";
import * as fromRoot from "../store/reducers";
import * as patientActions from "../store/actions/patient";
import { distinctUntilChanged, filter, map, switchMap, take } from "rxjs/operators";
import { LocalDBService } from "./local-db.service";
import { PatientInfoTable } from "app/models/local-db";
import { AppointmentStatusFriendly, AppointmentStatusIcons } from "app/models/diary";
import moment from 'moment';
import { BehaviorSubject } from "rxjs";

@Injectable()
export class PatientChooserService extends LocalDBService {

  private observableCache: BehaviorSubject<any>[] = [];

  constructor(
    private http: DataService,
    private store: Store<fromRoot.State>
  ) {
    super();
  }


  getResults(input: string, patients = 1, companies = 0, insurance = 0): Observable<PatientChooserResult[]> {

    const qParam = `?patients=${patients}&companies=${companies}&insurance=${insurance}`;
    return this.http.get<PatientChooserResult[]>(`/patients/list/${encodeURIComponent(input)}${qParam}`);
  }

  setPatient(idx: number): void {
    console.log("[pc] sets i=", idx);
    this.store.dispatch(patientActions.LoadAction({ payload: idx }));
  }

  getPatient(): Observable<PatientChooserResult> {
    return this.store.select(fromRoot.getCurrentPatient).pipe(distinctUntilChanged());
  }

  getPatientEmail(idx: number): Observable<any> {
    return this.http.get<any>(`/patients/email/${idx}`);
  }

  getPatientContactDetails(idx: number): Observable<{ idx: number, email: string, homePhone: string, mobilePhone: string }> {
    return this.http.get<any>(`/patients/contact-details/${idx}`);
  }

  selectPatient(patient: PatientChooserResult) {
    console.log("[pc] selects", patient);
    this.store.dispatch(patientActions.SetAction({ payload: patient }));
  }

  getPatientInfo(): Observable<PatientDetails> {
    return this.getPatient().pipe(
      filter(f => {
        console.log("[pc] pidx=", f);
        if (f && f.idx) { return true; }
        return false;
      }),
      switchMap(res => {
        return this.getPatientDetails(res.idx);
      }));
  }

  clearPatient(): void {
    this.store.dispatch(patientActions.ClearAction())
  }

  getCurrentPatientIdx(): Observable<number> {
    return this.store.select(fromRoot.getCurrentPatientIdx).pipe(distinctUntilChanged());
  }

  getCurrentPatientIdxSnapshot(): number {
    let p: number;
    this.getCurrentPatientIdx()
      .pipe(take(1))
      .subscribe((res) => {
        console.log("[gcp]", res);
        if (!res) {
          p = 0;
        } else {
          p = res;
        }
      });
    return p;
  }

  getPatientBalance(patientIdx): Observable<any> {
    return this.http.get<any>(`/account/balance/${patientIdx}`);
  }

  getPatientAccountInfo(patientIdx): Observable<any> {
    return this.http.get<any>(`/account/summary/${patientIdx}`);
  }

  transferCredits(from: number, to: number, creditIdx: number, count: number) {
    console.log(from, to, creditIdx, count);
    const payload = {
      from,
      to,
      creditIdx,
      count
    }
    return this.http.post<any>(`/transfer-credits`, payload);
  }

  /* Depreceated use getPatientDetails()


  getPatientFromServer(patientIdx: number, full: boolean = false): Observable<any> {
    if (!patientIdx){
      console.warn("Request for patient info with no id!")
      return EMPTY;
    }

    if (full) {
      return this.http.get<PatientDetails>(`/patients/info/${patientIdx}?full=1`);
    }
    return this.http.get<PatientDetails>(`/patients/info/${patientIdx}`);
  }
  */

  getFullDetails() {
    this.store.dispatch(patientActions.LoadFullDetails());
  }
  /*
    chooseNotAvailable() {
      this.getResults("available").subscribe((res) => {
        const notAvailableObject = res.filter(
          (obj) => obj.name === "Not Available"
        );
        this.selectPatient(notAvailableObject[0]);
      });
    }
  
  /*
  
  - Need to implement all these -
  ---------------------------------
  
  export interface PatientInfo  {
    details: PatientDetails;
    attendanceSummary?: AttendanceSummary[];
    attendance?: Attendance[];
    notes?: PatientNotesInfo;
  }
  
  */


  /**
   * Generalised function to get any section of patient info
   */
  getInfoSection<T>(patientIdx: number, section: string): BehaviorSubject<T> {
    const id = `${patientIdx}-${section}`;

    if (!this.observableCache[`${patientIdx}-${section}`]) {
      console.log(`[pc] [patient] obs NOT in cache ${section}`);
      this.observableCache[id] = new BehaviorSubject(null);
      this.runInfoFetchSequence<T>(patientIdx, section);
    }
    return this.observableCache[`${patientIdx}-${section}`];
  }

  private runInfoFetchSequence<T>(patientIdx: number, section: string) {
    const id = `${patientIdx}-${section}`;

    this.table(PatientInfoTable)
      .where(':id')
      .equals(id)
      .first()
      .then<any>(cache => {

        if (cache) {
          console.log("[pc] [db] cache HIT!");
          this.observableCache[id].next(cache.data); // emit cached data
        } else {
          console.log("[pc] [db] cache MISS!");
        }

        // then re-fetch from server
        /** todo: add a chache expiry maybe to cut down on calls */
        this.http.get<T>(`/patient/info/${patientIdx}/${section}`).subscribe(res => {
          this.observableCache[id].next(res);
          this.table(PatientInfoTable)
            .put({ data: res, idx: id, patientIdx: patientIdx, field: section });
          this.observableCache[id].complete();
          this.observableCache[id] = false;
        });
      },
        err => { // record error with local db fetch from server
          console.log("[notes] [db] locate to update fails with an error", err);
          this.http.get<T>(`/patient/info/${patientIdx}/${section}`).subscribe(res => {
            this.observableCache[id].next(res);
            this.table(PatientInfoTable).add({ data: res, idx: patientIdx, field: section });
            this.observableCache[id].complete();
            this.observableCache[id] = false;
          });
          return err;
        });
  }


  getPatientInfoNotes(patientIdx: number): Observable<PatientNotesInfo> {
    return this.getInfoSection<PatientNotesInfo>(patientIdx, "notes").pipe(filter(res=>{ 
      return res!=null }));
  }

  getAttendance(patientIdx: number): Observable<Attendance[]> {
    return this.getInfoSection<Attendance[]>(patientIdx, "attendance").pipe(
      filter(res=>{ return res!=null }),
      map(
        res => {
          for (const att of res) {
            att.statusDisplay = {
              icon: AppointmentStatusIcons.get(att.status),
              text: AppointmentStatusFriendly.get(att.status)
            };
            // bookingMadeAt is a string GTM timestamp in the database. If it is a Dayligh Saving Time then we add an hour.
            if (moment(att.bookingMadeAt).isDST()) {
              let bookingMadeAt = moment(att.bookingMadeAt)
              bookingMadeAt.locale('en-gb')
              let bookingMadeAtBST = moment(bookingMadeAt).add(1, 'hours').format('lll')
              att.bookingMadeAt = bookingMadeAtBST
            }
          }
          return res;
        }));
  }

  getPatientDetails(patientIdx: number): Observable<PatientDetails> {
    return this.getInfoSection<PatientDetails>(patientIdx, "info")
      .pipe(filter(res=>{ 
        return res!=null }));
  }

  getAttendanceSummary(patientIdx: number): Observable<AttendanceSummary[]> {
    return this.getInfoSection<AttendanceSummary[]>(patientIdx, "attendance-summary")
    .pipe(filter(res=>{ 
      return res!=null }));
  }

  mergePatients(from: number, to: number) {
    const payload = {from, to}
    return this.http.post<any>(`/patients/merge`, payload);
  }

}
