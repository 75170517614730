import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { MainMenuComponent } from './main-menu/main-menu.component';
import { MainMenuGridButtonComponent } from './grid-components/main-menu-grid-button/main-menu-grid-button.component';
import { GridComponent } from './grid/grid.component';
import { MyMaterialModule } from '../material/material.module';
import { HomeComponent } from './home/home.component';
import { SubMainMenuComponent } from './sub-main-menu/sub-main-menu.component';



const MenuRoutes = [
    {
        path: 'menu',
        children: [
            {
                path: '',
                component: HomeComponent
            },

        ]
    }
];


@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(MenuRoutes),
        MyMaterialModule
    ],
    declarations: [
        MainMenuComponent,
        MainMenuGridButtonComponent,
        GridComponent,
        HomeComponent,
        SubMainMenuComponent
    ],
    providers: [],
    exports: [
        MainMenuComponent,
        MainMenuGridButtonComponent,
        GridComponent,
        HomeComponent
    ]
})
export class MainMenuModule { }
