import { Overlay, OverlayRef } from "@angular/cdk/overlay";
import { TemplatePortal } from "@angular/cdk/portal";
import {
  Directive,
  ElementRef,
  Input,
  TemplateRef,
  ViewContainerRef
} from "@angular/core";

import { Subscription } from "rxjs";
import { FastTextService } from "./fast-text.service";


// Todo: need to share overlayRef between instances
// Follow menu/trigger example?

@Directive({
  selector: "[mcFastText]"
})
export class FastTextDirective {
  @Input() context: unknown;

  public sub: Subscription;
  public overlayRef: OverlayRef | null;

  constructor(
    public elementRef: ElementRef<HTMLElement>,
    public overlay: Overlay,
    public viewContainerRef: ViewContainerRef,
    private fastTextService: FastTextService
  ) {}

  private handleKeyPress = event$ => {
    const key = event$.key;
    if (key=="/"){
      console.log("slash up");
      this.fastTextService.show(this.elementRef);
    }
  };

  public ngOnInit(): void {
    this.elementRef.nativeElement.addEventListener(
      "keypress",
      this.handleKeyPress
    );
  }

  public ngOnDestroy(): void {
    this.elementRef.nativeElement.removeEventListener(
      "keypress",
      this.handleKeyPress
    );
  }
/*
  public open({ x, y }: MouseEvent, user): void {
    this.close();
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo({ x, y })
      .withPositions([
        {
          originX: "end",
          originY: "bottom",
          overlayX: "end",
          overlayY: "top"
        }
      ]);

    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close()
    });

    this.overlayRef.attach(
      new TemplatePortal(this.mcFastText, this.viewContainerRef)
    );

    this.sub = fromEvent<MouseEvent>(document, "click")
      .pipe(
        filter(event => {
          const clickTarget = event.target as HTMLElement;
          return (
            !!this.overlayRef &&
            !this.overlayRef.overlayElement.contains(clickTarget)
          );
        }),
        take(1)
      )
      .subscribe(() => this.close());
  }

  public close(): void {
    if (this.sub) this.sub.unsubscribe();
    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }

  */
}
