<mat-card id="notes_very_top">
    <mat-card-content>
    <div class="row">
        <div class="col-sm-3">
            <h4>Patient notes</h4>
        </div>
        <div class="col-sm-9">
            <patient-chooser [(ngModel)]="patientIdx"></patient-chooser>
        </div>
    </div>
</mat-card-content></mat-card>

<app-notes-general-info-area class="no-print" *ngIf="patientIdx>0"></app-notes-general-info-area>

<router-outlet></router-outlet>
<app-fast-text></app-fast-text>