

import { HttpRequest, HttpClient, HttpEvent, HttpEventType } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Observable, of } from 'rxjs';

import { environment } from 'environments/environment';
import { FilePickerAdapter, UploadStatus, UploadResponse } from './lib/file-picker.adapter';
import { FilePreviewModel } from './lib/file-preview.model';


export class UploadFilePickerAdapter extends FilePickerAdapter {

  apiEndPoint: string = environment.apiEndPoint;
  endpoint = '/files/upload'; // Add POST endpoint
  patientIdx: number = 0;
  isPublic: string = "";

  constructor(private http: HttpClient) {
    super();
  }

  public setPublic(p:boolean): void{
    console.log("[file] public", p);
    if (p){
      this.isPublic = "1";
      return;
    } 
    this.isPublic = "0";
  }

  public setPatientIdx(patientIdx: number): void{
    this.patientIdx = patientIdx;
    console.log("[files] ", patientIdx);
  }

  public uploadFile(fileItem: FilePreviewModel): Observable<UploadResponse> {
    const form = new FormData();
    form.append('file', fileItem.file);
    form.append('public', this.isPublic);
    if (!this.patientIdx){
      this.patientIdx = 0;
    }
    const api = `${this.apiEndPoint}${this.endpoint}/${this.patientIdx}`;
    const req = new HttpRequest('POST', api, form, {reportProgress: true});

    return this.http.request<UploadResponse>(req)
    .pipe(
      map((res: HttpEvent<any>) => {
        if (res.type === HttpEventType.Response) {
          const responseFromBackend = res.body;
          return {
            body: responseFromBackend,
            status: UploadStatus.UPLOADED
          };
        } else if (res.type ===  HttpEventType.UploadProgress) {
          /** Compute and show the % done: */
            const uploadProgress = +Math.round((100 * res.loaded) / res.total);
            return {
              status: UploadStatus.IN_PROGRESS,
              progress: uploadProgress
            };
        }
      }),
      catchError(er => {
        console.log("[upload] error", er);
        return of({status: UploadStatus.ERROR, body: er });
      })
      );
  }


    public removeFile(fileItem: FilePreviewModel): Observable<any> {
      
      const responseFromBackend = fileItem.uploadResponse;
      console.log(fileItem);
      const removeApi = `${this.apiEndPoint}${this.endpoint}/${this.patientIdx}/${fileItem.fileName}`;
      return this.http.delete(removeApi);
    }
   
}