import { Component, Input } from '@angular/core';


@Component({
    selector: 'app-grid-button',
    templateUrl: './main-menu-grid-button.component.html',
    styleUrls: ['./main-menu-grid-button.component.scss']
})
export class MainMenuGridButtonComponent {
    @Input() link: any
    @Input() isOldStyle: boolean;
    

    constructor() {
    }

   

}
