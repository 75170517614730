import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { DataService } from '../data.service';
import { Signatures } from 'app/models/signatures';
import * as fromRoot from 'app/store/reducers';
import { Store } from '@ngrx/store';


@Injectable({
  providedIn: 'root'
})
export class SignatureService {

  public signaturesByNotesIdx$ = new BehaviorSubject(undefined);
  public signatures$;
  private notesIdx;
  public _supervisors;

  constructor(private http: DataService, private store: Store<fromRoot.State>) {
    this.getSupervisors();
    this.store.select(fromRoot.getNotesState).subscribe(res => {
      this.stopInterval()
      if (res.idx > 0) {
        this.notesIdx = res.idx;
        this.fetchSignatures();
        if (!this.signatures$){
          this.signatures$ = setInterval(() =>
            this.fetchSignatures()
            , 60000)
        }
      }
    })
  }

  fetchSignatures() {
    if (this.notesIdx>0){
      this.getSignaturesByNotesIdx(this.notesIdx).subscribe(data => {
        console.log('Signatures in service', data);
        this.signaturesByNotesIdx$.next(data)
      })
    }
  }

  

  private getSupervisors(){
    this.http.get(`/notes/signatures/supervisors`).subscribe(res => {
        this._supervisors = res;
    });
  }

  public getSignaturesByNotesIdx(notesIdx: number): Observable<Signatures> {
    return this.http.get(`/notes/signatures/${notesIdx}`);
  }

  public getSignatures(notesIdx: number, recordIdx: number = 0): Observable<Signatures> {
    return this.http.get(`/notes/signatures/${notesIdx}/${recordIdx}`);
  }

  public addSignature(notesIdx: number, recordIdx: number, formName: string, pinCode: string, locked: boolean, userIdx: number = null, comment: string = null, signatureTitle: string = null): Observable<any> {
    const payload = { pinCode, userIdx, locked, recordIdx: recordIdx, comment, signatureTitle, formName };

    return this.http.post(`/notes/signatures/${notesIdx}/new`, payload);
  }

  public getLocks(notesIdx: number): Observable<any> {
    return this.http.get(`/notes/signatures/locks/history/${notesIdx}`);
  }

  public lock(notesIdx: number, recordIdx: number, formName: string,  locked: boolean, signatureIdx: number, signatureTitle: string): Observable<any> {
    return this.http.post(`/notes/signatures/locks/lock/${notesIdx}/${locked ? 1 : 0}`, { signatureIdx, signatureTitle, locked, notesIdx, recordIdx, formName });
  }

  public requestChange(notesIdx: number, recordIdx: number, formName: string,  comment: string): Observable<any> {
    return this.http.post(`/notes/signatures/request/change/${notesIdx}`, { comment, notesIdx, recordIdx, formName});
  }
/*
  public getSignatureTypes(typeRequired: string): any {
    return SIGNATURE_TYPES.filter((type: any) => typeRequired === type.title)[0];
  }
*/
  get supervisors(){
    return this._supervisors;
  }

  public isLocked(signatures : Signatures): boolean{
    console.log("[sigs] locked ? ", signatures);
    if (signatures.signatures && signatures.signatures[0]) {
      return signatures.signatures[0].locked;
    }
    return false;
  }

  public isLockable(signatures: Signatures): boolean{
// has to have been previously locked to show the unlock buttons    
    if (signatures.signatures && signatures.signatures[0]) {
      const lock = signatures.signatures.find(s => s.locked==true);
      if (lock) return true;
    }
    return false;
  }

  public stopInterval() {
    if (this.signatures$){
      clearInterval(this.signatures$);
    }
  }
}