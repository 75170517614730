<div class="container-fluid">
  <div class="row margin-collapse">
    <div class="col-lg-10 p-0 print-w-100">
      <div class="mb-2">
        <app-diagnosis-card [diagnosisIdx]="soapData.diagnosis.idx" [notesIdx]="notesIdx"></app-diagnosis-card>
      </div>
      <mat-card><mat-card-content>
        

        <form autocomplete="off" #soapForm="ngForm" name="soap">
          <table *ngIf="soapData && notesIdx>0" class="soap-data">
            <thead>
              <tr>
                <th class="button" (click)="toggleSort()"><p>Date</p><mat-icon class="del" *ngIf="isSortingNewestFirst">keyboard_arrow_up</mat-icon><mat-icon class="del" *ngIf="!isSortingNewestFirst">keyboard_arrow_down</mat-icon></th>
                <th *ngIf="rxNoEnabled">Rx no.</th>
                <th class="table-5">S</th>
                <th class="table-5">O</th>
                <th class="table-5">A</th>
                <th class="table-5">P</th>
                <th>Files</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngIf="!discharged" class="no-print">
                <td class="table-8">
                  {{ newSoap.date | date : 'short' }}
                  <div>
                    <button mat-icon-button (click)="refreshNotes()">
                      <mat-icon *ngIf="!refreshRequired">sync</mat-icon>
                      <mat-icon class="text-danger" *ngIf="refreshRequired" title="Update required!">sync_problem</mat-icon>
                    </button>
                  </div>
       
                </td>
                <td class="table-8 text-center"  *ngIf="rxNoEnabled">
                  <span *ngIf="soapData.soaps">
                    {{soapData.soaps[0] ? soapData.soaps[0].rxno + 1 : 1}}
                  </span>
                  
                </td>
                <td class="table-5 v-top">
                    <textarea 
                      matInput 
                      cdkTextareaAutosize
                      cdkAutosizeMinRows="6"
                      id="new_S" 
                      name="new_S"
                      class="mat-form-field-appearance-fill mh-100"
                      (change)="soapChanges()"
                      (keyup)="soapChanges()"
                      mcFastText
                      [(ngModel)]="newSoap.S"></textarea>
  
                </td>
                <td class="table-5 v-top">
                    <textarea 
                      matInput 
                      cdkTextareaAutosize
                      cdkAutosizeMinRows="6"
                      id="new_O" 
                      name="new_O"
                      class="mat-form-field-appearance-fill"
                      (change)="soapChanges()"
                      (keyup)="soapChanges()"
                      mcFastText
                      [(ngModel)]="newSoap.O"></textarea>
                </td>
                <td class="table-5 v-top">
                    <textarea 
                      matInput
                      cdkTextareaAutosize
                      cdkAutosizeMinRows="6"
                      id="new_A" 
                      name="new_A"
                      class="mat-form-field-appearance-fill"
                      (change)="soapChanges()"
                      (keyup)="soapChanges()"
                      mcFastText
                      [(ngModel)]="newSoap.A"></textarea>
                </td>
                <td class="table-5 v-top">
                    <textarea 
                      matInput
                      cdkTextareaAutosize
                      cdkAutosizeMinRows="6"
                      id="new_P" 
                      name="new_P"
                      class="mat-form-field-appearance-fill"
                      (change)="soapChanges()"
                      (keyup)="soapChanges()"
                      mcFastText
                      [(ngModel)]="newSoap.P"></textarea>
                </td>
                <td class="table-8">
                  <app-uploader (fileData)="fileUploaded($event)" [patientIdx]="patientIdx" [fromNotes]="true"></app-uploader>
                  <mat-list *ngIf="files.length > 0">
                    <mat-list-item *ngFor="let file of files" class="w-100">
                        <mat-icon mat-list-icon class="upload-remove" (click)="removeFile(file)">remove_circle_outline</mat-icon>
                        <button class="btn btn-link w-auto" (click)="download(file)">{{file.name}}</button>
                    </mat-list-item>
                  </mat-list>
                </td>
              </tr>

              <ng-container *ngFor="let soap of soapData.soaps; let i = index">
                <tr *ngIf="soap.completed">
                  <td class="table-8 pre-formatted">
                    <br>{{ ( soap.date !== "0000-00-00 00:00:00" ) ? ( soap.date | date : 'short' ) : "" }}<br>
                    <span *ngIf="soap.CancelDate">
                      <hr/>
                      <b>Removed:</b> 
                      <br>
                      {{soap.CancelDate |date: 'short'}}
                    </span>
                    <br>
                    <span *ngIf="soap.CancelReason">
                      <b>Reason:</b> 
                      <br>
                      {{soap.CancelReason}}
                    </span>
                    <span class="staffname">{{ soap.staffName }}</span>
                    <button *ngIf="!discharged && !soap.CancelDate; else reactivateSoap" (click)="deactivateSoap(soap)" class="del no-print" tabindex="-1"><mat-icon class="del">delete</mat-icon></button>
                    <ng-template #reactivateSoap>
                      <button class="btn btn-link" (click)="recover(soap)"><mat-icon>restore_page</mat-icon></button>
                    </ng-template>
                  </td>
                  <td class="table-8 pre-formatted text-center"  *ngIf="rxNoEnabled">
                    {{soap.rxno}}
                  </td>
                  <td [ngClass]="{ 'strikethrough' : soap.CancelDate }" class="table-5 pre-formatted">
                    {{soap.S}}
                  </td>
                  <td [ngClass]="{ 'strikethrough' : soap.CancelDate }" class="table-5 pre-formatted">
                    {{soap.O}}
                  </td>
                  <td [ngClass]="{ 'strikethrough' : soap.CancelDate }" class="table-5 pre-formatted">
                    {{soap.A}}
                  </td>
                  <td [ngClass]="{ 'strikethrough' : soap.CancelDate }" class="table-5 pre-formatted">
                    {{soap.P}}
                  </td>
                  <td class="table-8 pre-formatted">
                    <mat-list *ngIf="soap.file">
                      <mat-list-item *ngFor="let file of soap.files">
                        <button class="btn btn-link" (click)="download(file)">{{file.name}}</button>
                        
                      </mat-list-item>
                    </mat-list>
                  </td>
                  
                    
                  
                </tr>
  <!-- SIGs-->
                <tr *ngIf="signaturesEnabled && soap.completed" class="no-print">
                  <td colspan="7">
                    <div class="signature-panel">
                      <app-signature [notesIdx]="notesIdx" [recordIdx]="soap.idx" [lockable]="false" [formName]="'notes_soap'" ></app-signature>
                    </div>

                   
                  </td>
                </tr>
  <!-- SIGs ends-->
              </ng-container>
            </tbody>
          </table>
        </form>
      </mat-card-content></mat-card>
    </div>
    <div class="col-lg-2 mobile-margin no-print">
      <div class="fix-top">
        <div class="desktop-buttons">
          <button class="submit-cancel" 
          [disabled]="discharged || (!newSoap.S && !newSoap.O && !newSoap.A && !newSoap.P) || refreshRequired"
          class="submit-cancel mb-3" mat-raised-button color="primary" (click)="saveFormClicked()">Save</button>
          <button class="submit-cancel" [disabled]="(recommendedRevisitSet || !patientIdx)" class="submit-cancel mb-3" mat-button color="primary" (click)="setRecommendedRevisit()">Set Recommended Revisit</button>
          <button [disabled]="allowDischarge" *ngIf="!discharged" class="submit-cancel" mat-button color="primary" (click)="dischargePatient()">Discharge</button>
        </div>
      </div>
    </div>
  </div>
</div>