import { QuestionBase } from './question-base';

export class Checkbox extends QuestionBase<boolean> {
  controlType = 'checkbox';

  constructor(options: any = {}) {
    super(options);
    this.value = options.value ? true : false;
  }
}
