export const StartBackQuestions: { label: string, field: string }[] = [
    {
        label: "My pain has spread at some time in the past 2 weeks",
        field: "pain_spread"
    },
    {
        label: "In addition to my main pain, I have had pain elsewhere in the last 2 weeks",
        field: "pain_elsewhere"
    },
    {
        label: "In the last 2 weeks, I have only walked short distances because of my pain",
        field: "walked_short_distances"
    },
    {
        label: "In the last 2 weeks, I have dressed more slowly than usual because of my pain",
        field: "dressed_slowly"
    },
    {
        label: "It's really not safe for a person with a condition like mine to be physically active",
        field: "not_safe"
    },
    {
        label: "Worrying thoughts have been going through my mind a lot of the time in the last 2 weeks",
        field: "worrying_thoughts"
    },
    {
        label: "I feel that my pain in terrible and that and that it’s never going to get any better",
        field: "pain_terrible"
    },
    {
        label: "In general in the last 2 weeks, I have not enjoyed all the things I used to enjoy",
        field: "not_enjoyed_things"
    },
];