import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import * as fromDiary from "app/models/diary";

@Component({
  selector: 'app-cancel-app-dialog',
  templateUrl: './cancel-app-confirm-dialog.component.html',
  styleUrls: ['../dialog-shared.scss']
})
export class CancelAppConfirmDialogComponent implements OnInit {

  public textInput: string
  public appStatus = fromDiary.AppointmentStatus;
  public isChecked: boolean


  constructor(
    @Inject(MAT_DIALOG_DATA) 
    public data: any,
    public dialogRef: MatDialogRef<CancelAppConfirmDialogComponent>,
  ) { }

  ngOnInit(): void {
  }


  public onDismiss() : void {
    this.dialogRef.close(false);
  }

  check(event) {
    if(event.target.checked == true) {
      this.isChecked = true
    } else {
      this.isChecked = false
    }
  }

  public onConfirm(action) : void {
    this.dialogRef.close({action: action, reason: this.textInput, isChecked: this.isChecked})
    // this.dialogRef.close(true);
    console.log(action, this.textInput);
    

  }

}