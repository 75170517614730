<mat-list>
  <mat-list-item>
    <mat-form-field class="w-100">
      <mat-label><mat-icon>search</mat-icon>Search</mat-label>
      <input [(ngModel)]="autoSearchValue" (keyup)="searchEvent($event)" matInput />
    </mat-form-field>
  </mat-list-item>

  <mat-accordion>
  
    <ng-template ngFor let-p [ngForOf]="filterItems$ | async">
      <mat-nav-list *ngIf="p.subItems && !p.subItems.length">
        <a mat-list-item [routerLink]="p.link" (click)="closeSideMenu()">
          <span matLine style="padding-left: .5rem;">{{p.title}}</span>
        </a>
      </mat-nav-list>

      <mat-expansion-panel *ngIf="p.subItems && p.subItems.length">
        <mat-expansion-panel-header>
          <mat-panel-title>
            {{p.title}}
          </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-panel-description>
          <mat-list>
            <mat-list-item *ngFor="let subitem of p.subItems" [routerLink]="[subitem.link]"
              (click)="closeSideMenu()">
              <span>{{subitem.title}}</span>
            </mat-list-item>
          </mat-list>
        </mat-panel-description>
      </mat-expansion-panel>
    </ng-template>
  </mat-accordion>
</mat-list>