<div>
    <div class="att-container">
        <div *ngFor="let year of years"
            class="year-line"
            [style.left.%]="year.percentage"
            [style.width.%]="year.length"
            [title]="year.year">
            {{year.year}}
        </div>

        <div *ngFor="let att of attendanceGraph" 
        class="att-line"
        [style.left.%]="att.percentage"
        [style.backgroundColor]="att.colour"
        title="{{att.description | date : 'short'}}"
        ></div>

        
    </div>
</div>