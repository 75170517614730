import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { LoadingService } from 'app/services/loading.service';

@Component({
  selector: 'app-form-dialog',
  templateUrl: './dynamic-form-dialog.component.html',
  styleUrls: ['../dialog-shared.scss']
})

export class DynamicFormDialogComponent  implements OnInit {

  constructor(
      @Inject(MAT_DIALOG_DATA)
      public data: any, 
      public dialogRef: MatDialogRef<DynamicFormDialogComponent>, 
      private loading: LoadingService
  ) { }

  ngOnInit(): void {
    this.loading.start();
  }

  
  public onConfirm(event) : void {
    console.log("[diag] close", event);
    this.dialogRef.close({data: event});
  }

  public onDismiss() : void {
    this.dialogRef.close(false);
  }

  public onInput(index: number) : void {
    this.dialogRef.close({index: index, data: this.data.formFields})
  }

  public loadComplete(e){
    console.log("[load] has completed", e);
    this.loading.stop();
  }
}
