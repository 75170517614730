import { Component } from '@angular/core';
import { NotesBuilderBaseComponent } from 'app/notes/notes-builder/notes-builder-base/notes-builder-base-component';
import { VitalSignsFields } from '../models/vital-signs'
@Component({
  selector: 'app-vital-signs',
  templateUrl: './vital-signs.component.html',
  styleUrls: ['./vital-signs.component.css']
})
export class VitalSignsComponent extends NotesBuilderBaseComponent {

  VitalSignsFields = VitalSignsFields;
  
  public calcBMI() : void {
    if (this.form && this.form.height && this.form.weight)
      this.form.bmi = (this.form.weight / (this.form.height / 100) ** 2).toFixed(1)
  }

}
