<section>
  <!--
    <header>
      
      <div *ngIf="allowAddNew">
        <button (click)="addNewPatient()">Add New Patient</button>
        <button (click)="chooseNotAvailable()">Mark Not Available</button>
        <button (click)="chooseWalkIn()">Walk-In Patient</button>
      </div>
    
    </header>
  -->
  <mat-form-field>
    <input type="text" 

          id="input-name"
          (keyup)="updateChooser($event)" 
          (blur)="onBlur()"
          [ngClass]="{'name-selected' : selectedPatient && selectedPatient.idx>0}" 
          [ngModel]="showPatientName"
          [placeholder]="placeholder" 
          [disabled]="readOnly"
          autocomplete="off"
          name="selectedPatient"
          #inputElement 
          matInput />
        
      <div class="button-container no-print">
        <button *ngIf="!readOnly"
                (click)="clearPatient()" 
                type="button"
                title="Clear">
                <mat-icon>clear</mat-icon>
        </button>

        <button *ngIf="selectedPatient"
                (click)="editPatient()" 
                type="button"
                title="Edit Patient">
                <mat-icon>edit</mat-icon>
        </button>

        <button *ngIf="selectedPatient"
          (click)="showAttendance()" 
          type="button"
          title="Show Attendance">
          <mat-icon>auto_stories</mat-icon>
        </button>
        

      </div>
  </mat-form-field>
<!--   <pre>NAME {{ selectedPatient | json }}</pre> -->
  
    <input type="hidden" 
      name="patientChooserHidden" 
      [(ngModel)]="value" />
  
    <ul class="results-list" [ngClass]="{'shown' : selectListShown}" [style.width.px]="resultsListPos.width"  #resultsList>
      <li *ngIf="!foundValues">Loading...</li>
      <li *ngIf="foundValues && foundValues.length==0">No results</li>
      <li *ngFor="let foundValue of foundValues" (click)="choosePatient(foundValue)" class="foundPatient">
        <div><span [innerHtml]="foundValue.highlighted | sanitizeHtml"></span><span *ngIf="foundValue.idx" class="patientIdx">#{{foundValue.idx}}</span></div>
        <div class="address row">
          <div class="col-sm-4">{{ foundValue.address }}</div>
          <div class="col-sm-4" >{{ foundValue.dob | date : 'dd/MM/yyyy'}}</div>
          <div class="col-sm-4" *ngIf="foundValue.lastAppointmentStaff">
            <span class="text-muted">Last</span>&nbsp;{{ foundValue.lastAppointmentStaff }}</div>
        </div>
      </li>
    </ul>
  </section>
  
  
  
  <p *ngIf="showLastAppointment && lastAppointment" class="lastAppointment alignWarnings">
    <mat-icon class="text-muted">info</mat-icon>
    Last Appointment: {{lastAppointment.treatmentName}} on {{lastAppointment.StartTime | date : 'short'}} with
    {{lastAppointment.staffName}}
  </p>
  
  <p *ngIf="unpaidDNA && lastAppointment && showLastAppointment" class="unpaidDNA alignWarnings">
    <mat-icon class="text-muted">warning</mat-icon>
    Unpaid DNA: {{lastAppointment.treatmentName}} on {{lastAppointment.StartTime | date : 'short'}} with
    {{lastAppointment.staffName}}
  </p>

  <p *ngIf="unpaidLNC && showLastAppointment" class="unpaidDNA alignWarnings">
    <mat-icon class="text-muted">warning</mat-icon>
    Unpaid LNC: {{unpaidLNC.treatmentName}} on {{unpaidLNC.StartTime | date : 'short'}} with
    {{unpaidLNC.staffName}}
  </p>

  <p *ngIf="showNotes && patientNotes" class="lastAppointment alignWarnings">
    <mat-icon class="text-muted">notes</mat-icon>
    {{ patientNotes }}
  </p>
