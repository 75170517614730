import { Component, Input, Output, EventEmitter, SimpleChanges, OnInit, OnDestroy, OnChanges } from '@angular/core';
import { ActivatedRoute,  Router } from '@angular/router';

import { PatientInfoBoxService } from 'app/services/notes/patient-info-box.service';
import { PatientChooserService } from '../../services/patient.service';
import { FilesService } from '../uploader/files.service';
import * as fromSettings from 'app/models/site-settings';
import { SiteSettingsService } from 'app/services/site-settings.service';
import { Subscription } from 'rxjs';

import * as fromNotes from 'app/models/notes';
import { initialPatientInfo, type PatientInfo } from 'app/models/patient-details';
import { NotesSaveService } from 'app/services/notes/notes-save.service';
import { PatientAppointmentHistoryService } from 'app/services/patient-appointment-history.service';
import { Location } from '@angular/common';
@Component({
  selector: 'patient-info-box',
  templateUrl: './patient-info-box.component.html',
  styleUrls:  ['./patient-info-box.component.css'],
  providers:  [PatientInfoBoxService]
})

export class PatientInfoBoxComponent implements OnInit, OnDestroy, OnChanges {
  @Input() patientIdx: number;
  @Input() defaultTab;
  @Output() newSOAPaddedEmitter = new EventEmitter();
  @Output() closeNote = new EventEmitter();
  info: PatientInfo = initialPatientInfo;
  public selectedTab = 0;
  files: any[];
  consultations: any;
  patientDetails$: Subscription = new Subscription();
  settings$: Subscription;
  selectedTabAfterSave$: Subscription;

  public notesSettings: fromSettings.NotesSettings;
  public noteTabs = [];
  public NotesTabComponents = fromNotes.NotesTabComponents;

  constructor(
    private pibs: PatientInfoBoxService,
    private router: Router,
    private patientChooserService: PatientChooserService,
    private filesService: FilesService,
    private route: ActivatedRoute,
    private location: Location,
    private settingsService: SiteSettingsService,
    private notesSaveService: NotesSaveService,
    private appintmentHistoryService: PatientAppointmentHistoryService,
    ) { }

  ngOnInit() {

    this.getFiles()

    this.getNotesSummary() 

    this.getAttendance()

    this.pibs.getPatientIdx().subscribe(res => {
      if (res) {
        console.log("[notes pibs] got new patient id=", res);
        this.patientIdx = res.idx;
        this.appintmentHistoryService.getSummaryForPatient(this.patientIdx).subscribe((res) => {
          this.info.visitInfo = {
            nVisits : res.previousApps,
            nextVisit : res.nextVisit,
            lastVisit : res.lastVisit,
            firstVisit : res.firstVisit
          };
        });
      }
    });

    this.pibs.getPatientDetails().subscribe(res => {
      console.log("[notes] got pd", res);
      this.info.details = res;
      this.tabsUpdates();
    });

    this.settings$ = this.settingsService.getNotesSettings().subscribe(res => {
  // populate live list with settings enabled list
      this.notesSettings = res;
      for (const tab of fromNotes.NoteTabs) {
        if (tab.visible || this.notesSettings[tab.visibleConfigKey]) {
          this.noteTabs.push(tab);
        }
      }
      this.tabsUpdates();
    });


    // listens to tab changes after save
    this.selectedTabAfterSave$ = this.notesSaveService.currentNotesTab.subscribe((res) => {
      if(this.selectedTab !== this.notesTabIndex(res)){this.selectedTab = this.notesTabIndex(res)}
      console.log(res);
    });

    this.route.url.subscribe(res=>{

      // console.log("[notes] we are at", res, this.location.path());
      const seg = this.matchSegments();
      console.log("[notes] we are at", res, this.location.path(), seg);
      this.selectedTab = seg;
    });

}

  // takes the form name and returns the index used to switch between tabs
  notesTabIndex(formName: string) {
    const tabIndex = this.noteTabs.findIndex((tab)=> {
      return tab.name == formName;
    });
    return tabIndex;
  }

  matchSegments(): number{
    const urlSegments = this.location.path().split("/");
    console.log("[notes] we", this.noteTabs, fromNotes.NoteTabs);
    for (const segment of urlSegments) {
      const found = this.noteTabs.findIndex(f => {
        return (f.name == segment);
      });
      if (found >= 0) {return found; }
    }
    return 0;
  }

  tabsUpdates() {

    if (!this.info || !this.noteTabs) {
      return;
    }
    for (const tab of this.noteTabs) {
      switch (tab.component) {
        case fromNotes.NotesTabComponents.NOTES_TABLE:
          console.log("TAB", tab);
          tab.recordCount = this.info.notes[tab.name] ? this.info.notes[tab.name].length : 0;
          break;
        case fromNotes.NotesTabComponents.ATTENDANCE:
            tab.recordCount = this.info[tab.name] ? this.info[tab.name].length : 0;
            break;
        case fromNotes.NotesTabComponents.TELEPHONE:
          tab.recordCount = this.info.notes.telephoneConsults ? this.info.notes.telephoneConsults : 0;
          break;
        case fromNotes.NotesTabComponents.UPLOADER:
          tab.recordCount = this.files ? this.files.length : 0;
          break;
        default:
          console.log("tab no tab", tab);
      }
    }
  }

  ngOnDestroy() {
    this.patientDetails$.unsubscribe();
    this.settings$.unsubscribe();
    this.selectedTabAfterSave$.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.patientIdx = changes.patientIdx.currentValue;
  }

  onTabChanged(event) {
     this.router.navigate([`notes`, this.noteTabs[event.index].name]);
  }

  getNotesSummary() {
    this.patientDetails$.add(this.pibs.getNotesSummary().subscribe(res => {
      console.log("[notes pibs] got new notes notes info", res);
      this.info.notes = res;
      this.tabsUpdates();
    })
    )
  }

  getAttendance() {
    this.patientDetails$.add(this.pibs.getAttendance().subscribe(res => {
      console.log("[notes] got new notes info");
      this.info.attendance = res;
      this.tabsUpdates();
    })
    )
  }

  getFiles() {
    this.patientDetails$.add(this.pibs.getPatientIdx().subscribe(res => {
      this.filesService.getFiles(res.idx).subscribe(files => {
        console.log(`[FILES] in pib for ${res.idx}:`, files);
        this.files = files;
        this.tabsUpdates();
      });
    })
    )
  }

  loadNotes(data, title) { // this function filters for NEW and EXISTING notes requests and makes the correct call paths.

    console.log("[notes] is it new?", data);
    if (data === "close") {
      this.closeNote.emit(true);
    }

    data.isNewNote = !(data.idx > 0);


    if (!data.isNewNote || title == fromNotes.NotesTypes.LETTER) { // not new so have an ID, or is letter no dont need one
      this.openNotes(data, title);
      return;
    }

    console.log("[notes] called from pib will run ADD NEW");

    this.pibs.addNewNotes(this.patientIdx, title, data.description).subscribe(res => {
      this.getNotesSummary(); // refresh notes

      // line below doesn't do anything, looks like the ngrx flow is not finished
      // this.patientChooserService.getFullDetails(); // refresh info
      data.idx = res.notesIdx;
      this.openNotes(data, title);
    });

  }


  private openNotes(data, title) {
        switch (title) {
          case fromNotes.NotesTypes.SOAP: {
            // if (data.isNewNote) {
            //  this.newSOAPaddedEmitter.emit({ patientId: this.patientIdx, notesID: data.idx });  //what is this?
            //  return;`  
            // }
            this.router.navigate([`notes`, title, data.idx]);
            return;
          }

          case fromNotes.NotesTypes.LETTER: {
            if (data.isNewNote) {
              this.router.navigate([`notes`, `letters`, `template`, data.idx]);
            } else if (data.isOldLetter) {
              this.router.navigate([`notes`, `letters`, data.idx, `old`]);
            } else {
              this.router.navigate([`notes`, `letters`, data.idx]);
            }
            return;
          }

          case fromNotes.NotesTypes.TREATMENT_CARD: {
            this.router.navigate([`notes`, `treatmentcards`, data.idx]);
            return;
          }

          case fromNotes.NotesTypes.QUESTIONNAIRE: {
            this.router.navigate([`notes`, `edit`, `patientquestionaire`, data.idx]);
            return;
          }

          default: {
            console.log("[notes] title > NAV", ['notes', 'edit', title, data.idx]);
            this.router.navigate(['notes', 'edit', title, data.idx]);
          }

        }

  }
}
