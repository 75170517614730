<ng-container *ngIf="!diary.mobileView">
  <div class="cal-container">
    <div class="row">
      <div class="col-12 flex date-jump week-btns">
        <button mat-button (click)="setDate(-7)">
          <mat-icon>first_page</mat-icon>
        </button>
        <button mat-button (click)="setDate(-1)">
          <mat-icon>chevron_left</mat-icon>
        </button>
        <button mat-button (click)="setDate(0)">
          <span [ngClass]="{'active-day' : isToday}"><strong>Today</strong></span>
        </button>
        <button mat-button (click)="setDate(1)">
          <mat-icon>chevron_right</mat-icon>
        </button>
        <button mat-button (click)="setDate(7)">
          <mat-icon>last_page</mat-icon>
        </button>
      </div>
    </div>
  
    <div class="row">
  
      <div class="col-12 d-flex week-btns icon-close">
        <button mat-button (click)="setDate(7)" class="border-end">1<mat-icon>chevron_right</mat-icon></button>
        <button mat-button (click)="setDate(14)" class="border-end">2<mat-icon>chevron_right</mat-icon></button>
        <button mat-button (click)="setDate(21)" class="border-end">3<mat-icon>chevron_right</mat-icon></button>
        <button mat-button (click)="setDate(28)" class="border-end">4<mat-icon>chevron_right</mat-icon></button>
      </div>
    </div>
  
    <div class="row mt-n5">
      <div class="col-sm-3"></div>
      <div class="col-sm-6 text-muted text-small text-center">
        <small>+ Weeks</small>
      </div>
      <div class="col-sm-3"></div>
    </div>
  
    <div class="calendarContainer col-12 text-center">
      <div style="margin: 0 auto;" class="calendarContainerWrapper" *ngIf="calendarCollapsed && diary.mobileView">
        <button mat-raised-button (click)="calendarCollapsed = false" class="tab-button tab-button-shown"><mat-icon>visibility</mat-icon> Show Calendar</button>
      </div>
  
      <div style="margin: 0 auto;" class="calendarContainerWrapper" *ngIf="(!calendarCollapsed && diary.mobileView) || !diary.mobileView">
        <button mat-raised-button *ngIf="diary.mobileView" (click)="calendarCollapsed = true" class="tab-button tab-button-shown"><mat-icon>visibility_off</mat-icon> Hide Calendar</button>

        <!--           [selected]="viewDate"  -->
        <mat-calendar #calendar 
          style="font-size:8px!important;" 
          [startAt]="viewDate"
          [selected]="viewDate" 
          (selectedChange)="onCalendarSelectedChange($event)">
        </mat-calendar> 
      </div>
  
  
    </div>
  </div>
</ng-container>

<ng-container *ngIf="diary.mobileView">
  <div class="dayMonth">
      <div>{{ viewDate | calendarDate: "dayViewTitle" }}</div>
      <span (click)="calendarExpanded = !calendarExpanded">
        <mat-icon *ngIf="calendarExpanded">calendar_today</mat-icon>
        <mat-icon *ngIf="!calendarExpanded">event</mat-icon>
      </span>
  </div>

  <!-- <ng-container *ngIf="!calendarExpanded">
    <swiper [config]="swiperOptions" (reachBeginning)="reachedBeginning()" (reachEnd)="reachedEnd()">
        <ng-container *ngFor="let slide of slides; index as i">
          <ng-template swiperSlide>
            <div>
              <div class="dayTags">
                <div class="tagBox">{{ slide.dayTag }}</div>
              </div>
              <div class="dayNumbers" (click)="selectDay(slide.date)" [class.activeDay]="sameDay(slide.date, this.viewDate)" [class.todayDay]="sameDay(today, slide.date)">
                    <div class="dayBox">{{ slide.dayNumber }}</div>
              </div>
            </div>
          </ng-template>
        </ng-container>
    </swiper>
  </ng-container> -->

  <ng-container *ngIf="calendarExpanded">
    <mat-calendar #calendar style="font-size:8px!important;" [selected]="viewDate" [startAt]="viewDate" (selectedChange)="selectDay($event)"></mat-calendar>
  </ng-container>

  <div class="staffselection">
    <button class="btn btn-link" (click)="selectWorking()" *ngIf="!allStaffRequested">See the diary for working staff</button>
    <button class="btn btn-link" (click)="selectOnlyOne()" *ngIf="allStaffRequested">See only my own diary</button>

    <div>
      <button mat-button class="p-0 small-btn" (click)="zoomService.height=zoomService.height-1"><mat-icon>zoom_out</mat-icon></button>
      <mat-slider [min]="zoomService.MIN_ZOOM" [max]="zoomService.MAX_ZOOM" step="1" name="diaryZoom" [(ngModel)]="zoomService.height"><input matSliderThumb /></mat-slider>
      <button mat-button class="p-0 small-btn" (click)="zoomService.height=zoomService.height+1"><mat-icon>zoom_in</mat-icon></button>
    </div>
  </div>
</ng-container>