import { NgModule } from "@angular/core";
//import { BrowserModule }                  from '@angular/platform-browser';
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

//import { UploaderModule }                 from '../uploader/uploader.module'

import { MyMaterialModule } from "../material/material.module";
import { RouterModule } from "@angular/router";
import { SubmitCancelModule } from "../submit-cancel/submit-cancel.module";
import { CustomFormControlsModule } from "../custom-form-controls/custom-form-controls.module";
import { DynamicFormListComponent } from "./dynamic-form-list/dynamic-form-list.component";
import { DynamicFormSaveService } from "./services/dynamic-form-save.service";
import { DynamicFormQuestionComponent } from "./dynamic-form-question/dynamic-form-question.component";
import { QuestionControlService } from "./services/question-control.service";
import { QuestionService } from "./services/question.service";
import { DynamicAutoCompleteService } from "./services/dynamic-autocomplete.service";
import { DynamicFormComponent } from "./dynamic-form/dynamic-form.component";
import { AuthNeededGuard } from "app/guards/auth.guard";
import { FormsColourComponent } from './form-components/forms-colour/forms-colour.component';
import { AWSVerifyEmailComponent } from "./aws-email-verify/aws-email-verify.component";
import { AWSEmailVerifyService } from "./services/aws-email-verify.service";

@NgModule({
  imports: [
    CommonModule,
    CustomFormControlsModule,
    FormsModule,
    ReactiveFormsModule,
    MyMaterialModule,
    SubmitCancelModule,
    RouterModule.forChild([
      {
        path: "form/:formName/:searchOn",
        component: DynamicFormComponent,
        canActivate: [AuthNeededGuard]
      },
      {
        path: "form",
        component: DynamicFormListComponent,
        canActivate: [AuthNeededGuard]
      },
      {
        path: "aws-verify-email",
        component: AWSVerifyEmailComponent
      }
    ]),
  ],
  declarations: [
    AWSVerifyEmailComponent,
    DynamicFormComponent,
    DynamicFormQuestionComponent,
    DynamicFormListComponent
  ],
  providers: [
    AWSEmailVerifyService,
    DynamicFormSaveService, 
    QuestionControlService, 
    QuestionService,
    DynamicAutoCompleteService
  ],

  exports: [DynamicFormComponent],
})
export class DynamicFormsModule {}
