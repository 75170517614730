import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NavigationService } from 'app/services/navigation.service';

interface CommissionTransaction {
  commissionPayable: number;
  totalSales: number;
  checked: boolean;
  transactions?: CommissionTransaction[];
}
@Component({
  selector: 'app-dialog',
  templateUrl: './commission-po-dialog.component.html',
  styleUrls: ['./commission-po-dialog.component.scss', '../dialog-shared.scss']
})
export class CommissionPODialogComponent implements OnInit {

  public groupIndex: number = null;
  public transactions: CommissionTransaction[] = [];
  public recommendedPeriod: number = 0;
  public recommendedPeriodStep: number = 1;
  public recommendedTreatments: any[] = [];
  public recommendedTreatment: any;
  public recommendedRevisitError: string = null;
  public userIdx: number;
  public newPassword: string;
  public confirmNewPassword: string;
  public passwordError: string = null;
  public dischargedReason: string = null;
  public cancellationNotes: string = null;
  public totalSales: number = 0;
  public totalCommissions: number = 0;
  public totalSelected: number = 0;
  public allSelected: boolean = false;
  public transactionsSelected: boolean = false;

  public dateFilter: any;
  public staffMemeber: any;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<CommissionPODialogComponent>,
    private navigate: NavigationService) {

  }

  ngOnInit(): void {
    this.dialogRef.disableClose = true;
    console.log("[comm po]", this.data);
    if (this.data.args.dateFilter){
      this.dateFilter = {from: this.data.args.dateFilter.dateFrom, to:  this.data.args.dateFilter.dateTo };
    }
    this.staffMemeber = `${this.data.args.staff.firstname} ${this.data.args.staff.lastname}`;

    this.data.args.po.forEach((trans: any) => {
      const found = this.transactions.find((t: any) => trans.patientIdx === t.patientIdx);

      if (!found) {
        const transactions = this.getTransactionsByPatient(trans.patientIdx);
        this.transactions.push({ ...trans, checked: false, transactions: transactions.transactions, totalSales: transactions.totalSales, commissionPayable: transactions.commissionPayable });
      }
    });

  }

  getTransactionsByPatient(patientIdx: number): any {
    let transactions = this.data.args.po.filter((t: any) => t.patientIdx === patientIdx);

    let totalSales = 0;
    let commissionPayable = 0;
    transactions.forEach((transaction: any) => {
      transaction.totalSales = +transaction.totalSales;
      totalSales += transaction.totalSales;
      transaction.CommissionRate = +transaction.CommissionRate;
      transaction.commissionPayable = (transaction.CommissionRate / 100) * transaction.totalSales;
      commissionPayable += transaction.commissionPayable;
    });

    transactions = transactions.map((transaction: any) => {
      return { ...transaction, totalSales: +transaction.totalSales, commissionPayable: +transaction.commissionPayable }
    })

    return { transactions, totalSales, commissionPayable };
  }

  expandTransaction(transaction: any): void {
    if (transaction.transactions.length > 1) {
      transaction.expanded = !transaction.expanded;
    }
  }

  selectTransaction(selected: boolean, transaction: any, sub?: boolean, parent?: any): void {
    console.log("[sel]", transaction);

    if (selected) { //we have selected
      if (sub) { //i am a sub item
        let checked: number = 0;
        parent.transactions.forEach((trans: any) => { //count parents ticked
          if (trans.checked) checked++;
        })
        if (parent.transactions.length === checked) parent.checked = true; //all checked so tick parent
      } else { //top level so toggle children
        transaction.transactions.forEach((trans: any) => {
          if (trans.checked) {
          } else {
            trans.checked = true;
          }
        })
        
      }
    } else { //we have deselected
      if (sub) { //is a child
        parent.checked = false; //unslect parent
        this.allSelected = false; //not all anymore
      } else { //is parent release children
        this.allSelected = false; 
        transaction.transactions.forEach((trans: any) => {
          if (trans.checked) this.totalSelected -= 1;
          trans.checked = false;
        })
      }
    }

    this.setTransactionsSelected();
  }

  selectAll(all: boolean): void {
    this.allSelected = all;

    this.transactions.forEach((transaction: any) => {
      transaction.checked = all;
      this.selectTransaction(all, transaction);
    })

    this.setTransactionsSelected();
  }

  setTransactionsSelected(): void {
    this.transactionsSelected = this.transactions.findIndex((transaction: any) => transaction.checked || transaction.transactions.find((trans: any) => trans.checked)) !== -1;
    
    console.log(this.transactionsSelected);

    this.totalSales = 0;
    this.totalCommissions = 0;
    this.totalSelected = 0;
    for (let parent of this.transactions) {
      for (let transaction of parent.transactions){
        if (transaction.checked) {
          console.log(transaction);
          this.totalSales += +transaction.totalSales;
          this.totalCommissions += transaction.commissionPayable;
          this.totalSelected++;
        }
      }
    }
  }

  getSelectedTransactions(): any {
    const transactions = [];
    this.transactions.forEach((transaction: any) => {
      transaction.transactions.forEach((trans: any) => {
        if (trans.checked) transactions.push(trans);
      })
    })

    return transactions;
  }

  addSelectedTransactions(): void {
    this.dialogRef.close(this.getSelectedTransactions());
  }


  onDismiss(): void {
    console.log("[navigate] DISMISS");
    this.navigate.back();
    this.dialogRef.close(false);
  }

}
