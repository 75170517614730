<div class="row margin-collapse">
    <div class="col-lg-10">
        <app-availability-setting [(events)]="events"></app-availability-setting>
    </div>

    <div class="col-sm-2 mobile-till-margin">
        <div class="fix-top">
          <div class="selection-container">
            <div class="selection-title">Select a Clinic</div>
            <mat-selection-list [multiple]="false" class="pt-0 mb-3" (selectionChange)="selectClinic($event)">
                <mat-list-option *ngFor="let clinic of clinics" [value]="clinic.idx" [selected]="selectedClinic.idx === clinic.idx">{{ clinic.name }}</mat-list-option>
            </mat-selection-list>
          </div>

          <div class="desktop-buttons mobile-buttons">
            <div class="my-3 text-center">
              <button mat-raised-button class="mobile-button desktop-button" (click)="pickGroupTemplate()">Pick a Template</button>
            </div>

            <div class="my-3 text-center">
              <button mat-raised-button class="mobile-button desktop-button" (click)="saveAsTemplate()">Save as Template</button>
            </div>

            <div class="my-3 text-center">
              <button mat-raised-button color="primary" class="mobile-button desktop-button" (click)="submitAvailability()">Submit Availability</button>
            </div>

            <div class="my-3 text-center ">
              <button mat-raised-button class="mobile-button desktop-button" (click)="clearCalendar()" [disabled]="!events.length">Clear Calendar</button>
            </div>

            <div class="my-3 text-center">
              <button mat-raised-button class="mobile-button desktop-button" (click)="UndoLastStep()" [disabled]="!events.length">Undo Last Step</button>
            </div>

            <div class="my-3 text-center">
              <button mat-raised-button class="mobile-button desktop-button" (click)="navigateBack()">Back</button>
            </div>
          </div>
        </div>
    </div>
</div>