import { Component } from '@angular/core';
import { NotesBuilderBaseComponent } from 'app/notes/notes-builder/notes-builder-base/notes-builder-base-component';
import { CervicalFields } from '../models/cervical';

@Component({
  selector: 'app-cervical',
  templateUrl: './cervical.component.html',
  styleUrls: ['./cervical.component.css']
})
export class CervicalComponent extends NotesBuilderBaseComponent {
  CervicalFields = CervicalFields;

}