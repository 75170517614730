<div [id]='formName'>
    <form autocomplete="off" class="muscleForm" #formEle="ngForm">
      <div *ngFor="let section of sections">
        <div class="row mt-3">
          <div class="col-sm-2 pt-2" >
            Set all
          </div>

          <div class="col-sm-10 mt-1" >
            <button 
              *ngFor="let option of muscleNerveOptions"
              class="set-all" 
              (click)="setAllMuscles(section, option)"
              [disabled]="isDisabled"
              mat-stroked-button>
              {{option}}</button>
          </div>
        </div>

        <table class="w-100">
          <thead>
            <th>Muscle</th>
            <th>Nerve</th>
            <th class="text-center">Left</th>
            <th class="text-center">Right</th>
          </thead>
          <tbody>
            <tr *ngFor="let row of section">
              <td>{{ row.name }}</td>
              <td class="px-1">{{ row.model }}</td>
              <td class="left-right">
                <app-muscle-field 
                  [label]="'L'" 
                  [name]="row.model+'_L'"
                  [(ngModel)]="form[row.model+'_L']">
                </app-muscle-field>
              </td>
              <td class="left-right">
                <app-muscle-field 
                  [label]="'R'" 
                  [name]="row.model+'_R'"
                  [(ngModel)]="form[row.model+'_R']">
                </app-muscle-field>
              </td>
            </tr>
            </tbody>
        </table>
        <hr/>
        
      </div>
        
        <mat-form-field>
          <mat-label>Myofascial Trigger Points L</mat-label>
          <input matInput [(ngModel)]="form['MTP_L']" name="MTP_L">
        </mat-form-field>
        <mat-form-field>
          <mat-label>Myofascial Trigger Points R</mat-label>
          <input matInput [(ngModel)]="form['MTP_R']" name="MTP_R">
        </mat-form-field>

        <mat-form-field>
          <mat-label>Cranial Nerve</mat-label>
          <input matInput [(ngModel)]="form['cranial_nerve']" name="cranial_nerve">
        </mat-form-field>
    </form>
  </div>