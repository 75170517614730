import { Component } from '@angular/core';

import { NotesBuilderBaseComponent } from 'app/notes/notes-builder/notes-builder-base/notes-builder-base-component';


@Component({
  selector: 'app-range-of-motion',
  templateUrl: './range-of-motion.component.html',
  styleUrls: ['./range-of-motion.component.css']
})
export class RangeOfMotionAreaComponent extends NotesBuilderBaseComponent {
 
  public romFields: string[] = [
    "Oc_L", "C1_R", "C1_L", "C2_R", "C2_L", "C3_R", "C3_L", "C4_R", "C4_L", "C5_R", "C5_L", "C6_R", "C6_L",
    "C7_R", "C7_L", "T1_R", "T1_RIB_L", "T1_L", "T2_R", "T2_RIB_R", "T2_RIB_L", "T2_L", "T3_R",
    "T3_RIB_R", "T3_RIB_L", "T3_L", "T4_R", "T4_RIB_R", "T4_RIB_L", "T4_L", "T5_R", "T5_RIB_R",
    "T5_RIB_L", "T5_L", "T6_R", "T6_RIB_R", "T6_RIB_L", "T6_L", "T7_R", "T7_RIB_R", "T7_RIB_L",
    "T7_L", "T8_R", "T8_RIB_R", "T8_RIB_L", "T8_L", "T9_R", "T9_RIB_R", "T9_RIB_L", "T9_L",
    "T10_R", "T10_RIB_R", "T10_RIB_L", "T10_L", "T11_R", "T11_RIB_R", "T11_RIB_L", "T11_L",
    "T12_R", "T12_RIB_R", "T12_RIB_L", "T12_L", "L1_R", "L1_RIB_R", "L1_L", "L2_R", "L2_L",
    "L3_R", "L3_L", "L4_R", "L4_L", "L5_R", "SI_L", "L5_L", "S1_R", "SI_R", "S2_L", "S2_R",
  ] 

  public setRom(value: number) {
    this.romFields.forEach(field => {
      this.form[field] = value
    })
  }

}
