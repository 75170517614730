import { StaffMember } from "./staff";

export type AppointmentSelectOn = 'staff' | 'rooms';

export interface Appointment {
    startTime: String;
    endTime: String;
    selectOn: AppointmentSelectOn;
    selectVals: Array<Number>;
    cancelled?: boolean;
    startDay?: number;
    endDay?: number;
}

export interface FutureAppointment {
  bookingMadeAt: Date;
  cancelledAt: Date;
  clinicIdx: number;
  idx: number;
  patientIdx: number;
  staffName: string;
  startTime: Date;
  status: string;
  treatmentName: string;
}

export interface ProcessedAppointment {
    idx: number;
    title: string;
    color: { primary: string, secondary: string, textColour: string };
    start: Date;
    end: Date;
    meta: {
        apointmentUID: any;
        user: StaffMember;
        patientId: any;
        treatment: string;
        patientName: string;
        roomName: string;
        roomIdx: number;
        status: string;
        notes: string;
        patientGeneralNotes: string;
        transactionIdx: number;
        patientDOB: string;
        SMSStatus: string;
        history: any;
        nextAppointment: any;
        repeatVisitDays: number;
        hasSOAP: boolean;
        groupSize: number;
    };
    resizable: {
        beforeStart: boolean;
        afterEnd: boolean;
    };
    draggable: boolean;
}

export interface RecievedAppointment {

}
export interface State {
  appointments: Array<any>;

}
export const initialState: State =  {
  appointments: []

};
