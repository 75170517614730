<div class="alert alert-warning" *ngIf="poExist===false">This purchase order does not exist in our database.</div>

<div class="row margin-collapse" *ngIf="poDetails && poExist">
  <div class="col">
    <div class="pdf">
      <div class="void-overlay"*ngIf='poDetails.voidedAt' >VOID</div>
      <table class="invoice-header">

        <tr>

          <td class="w-33pc">
            <div *ngIf="patientName">
              {{ patientName }}
            </div>
            <div class="addressAccount">
              <span *ngFor="let address of accountAddress">
                {{address != '' && address}}<br />
              </span>
            </div>

          </td>
          <td></td>
          <td class="w-50pc">
            <table>
              <tr>
                <td colspan="2">
                  <div class="text-end">
                    <img [src]="invoiceLogo" class="client-logo" alt="" />
                  </div>
                </td>
              </tr>
              <br>
              <br>
              <tr>
                <td class="w-50pc">
                  <strong>Purchase Order Date</strong><br />

                  {{ poDetails?.dateAdded | date : 'dd/MM/yyyy' :undefined: 'en-GB'}}<br /><br />

                  <strong>Purchase Order No.</strong><br />
                  {{invoicePrefix}}{{poDetails?.idx}}
                </td>
                <td class="w-50pc">
                  <div class="address text-end">
                    <span>{{clinicName}}</span><br>
                    <span *ngFor="let address of clinic">
                      {{address}}<br />
                    </span>
                  </div>
                </td>
              </tr>
            </table>
          </td>
        </tr>
      </table>
      <br>
      <table class="margin-top items">
        <thead>
          <th>Item</th>
          <th>Qty</th>
          <th>Unit Price</th>
          <th>VAT Rate</th>
          <th *ngIf="numberReceived>0">Recvd</th>          
          <th>Total</th>
        </thead>
        <tbody>
          <tr *ngFor="let item of poDetails.items">
            <td>
              <div>{{ item.saleFreeText }}</div>
              <div class="text-muted">{{ item.notes }}</div>
            </td>
            <td class="number">{{ item.qty }}</td>
            <td class="number">{{ item.value | currency : 'GBP' : 'symbol' }}</td>
            <td class="number">{{ item.pervailVATRate | percent}}</td>
            <td class="number" *ngIf="numberReceived>0">{{ item.qtyReceived }}</td>
            <td class="number">
              <span>
                {{ item.value * item.qty | currency : 'GBP' : 'symbol' }}
              </span>
            </td>
          </tr>
          
          <tr class="no-border"><td>&nbsp;</td></tr>

          <tr class="no-border">
            <td colspan="3"></td>
            <td class="number ">Subtotal</td>
            <td class="number ">{{ total | currency : 'GBP' : 'symbol' }}</td>
          </tr>
          <tr class="no-border">
            <td colspan="3"></td>
            <td class="number ">Total VAT</td>
            <td class="number ">{{ totalIncVAT-total | currency : 'GBP' : 'symbol' }}</td>
          </tr>
          <tr>
            <td colspan="3"></td>
            <td class="number grand-total">Total</td>
            <td class="number grand-total">{{ totalIncVAT | currency : 'GBP' : 'symbol' }}</td>
          </tr>
           

          <tr class="no-border"><td>&nbsp;</td></tr>
          
          <tr>
            <th class="number total">PAID</th>
            <th class="number total">PAYMENT METHOD</th>
            <th class="number total">OUTSTANDING</th>
          </tr>
          <tr>
            <td class="number ">{{poDetails.amountPaid | currency : 'GBP' : 'symbol' }}</td>
            <td class="number ">{{poDetails.paymentMethod}}</td>
            <td class="number ">{{totalIncVAT - poDetails.amountPaid | currency : 'GBP' : 'symbol'}}</td>
          </tr>
          
        </tbody>
      </table>

    </div>
  </div>
  <div class="col-sm-2 no-print mobile-margin">
    <div class="fix-top">
      <div class="mb-3">

        <mat-form-field class="w-100 mobile-input desktop-input">
          <mat-label>Email To</mat-label>
          <input matInput type="email" name="clientEmail" [(ngModel)]="clientEmail">
        </mat-form-field>
        <mat-form-field class="w-100 mobile-input desktop-input">
          <mat-label>Email Subject</mat-label>
          <input matInput type="text" name="emailSubject" [(ngModel)]="emailSubject">
        </mat-form-field>
        <div class="editor bg-white">
          <angular-editor *ngIf="emailing" [(ngModel)]="emailText.body" [config]="editorConfig" [placeholder]=""  id="print"></angular-editor>
        </div>

        <div class="mobile-buttons mobile-invoice-buttons">
          <button *ngIf="!emailing" mat-flat-button color="accent" class="mobile-button desktop-button" (click)="emailInvoice()">
            <mat-icon>alternate_email</mat-icon>&nbsp;Email Copy
          </button>

          <button *ngIf="emailing" mat-flat-button color="accent" class="mobile-button desktop-button" (click)="emailInvoice()">
            <mat-icon>alternate_email</mat-icon>&nbsp;Send
          </button>

          <div class="desktop-buttons">
            <button id="print-button" mat-flat-button color="accent" class="mobile-button desktop-button"
              (click)="print()">
              <mat-icon>print</mat-icon>&nbsp;Print
            </button>
          </div>

          <div class="my-3 text-center desktop-buttons">
            <button mat-raised-button class="mobile-button desktop-button" (click)="quickClose()">Quick Close</button>
          </div>

          <div class="my-3 text-center desktop-buttons">
            <button mat-raised-button class="mobile-button desktop-button" (click)="navigateToEdit()">Edit</button>
          </div>

          <div class="my-3 text-center desktop-buttons">
            <button [disabled]="!numberOutstanding && poDetails.amountPaid >= total" mat-raised-button color="primary"
              class="mobile-button desktop-button" (click)="navigateToReceive()">Pay / Receive</button>
          </div>
          <hr />
          <div class="my-3 mt-5 text-center desktop-buttons">
            <button mat-flat-button color="accent" class="mobile-button desktop-button" (click)="addNew()">
              <mat-icon>add</mat-icon>
              Add New
            </button>
          </div>

          <div class="my-3 text-center desktop-buttons">
            <button mat-raised-button class="mobile-button desktop-button" (click)="back()">Back</button>
          </div>
          <hr />
          <div class="desktop-buttons">
            <button id="print-button" mat-flat-button color="error" class="mobile-button desktop-button mt-5"
              (click)="voidPO()">
              <mat-icon>delete</mat-icon>&nbsp;Void
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

</div>