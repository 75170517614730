export class State {
  jwt: string;
  error?: string;
  meta: UserMeta;
  permissions: Permissions;
//  groupPermissions: GroupPermissions;
}

export interface UserMeta {
  username: string;
  firstname: string;
  lastname: string;
  userId: number;
  personId: number;
  staffId: number;
  IPBasedRestriction: string;
  groupId: number;
  o365AccountId: string;
  tfaRequired: string;
  tfaCode: string; 
  photo: string;
  db: string;
}

export const initialUserMeta = {
  username: '',
  firstname: '',
  lastname: '',
  userId: 0,
  personId: 0,
  staffId: 0,
  IPBasedRestriction: null,
  groupId: 0,
  o365AccountId: null,
  tfaRequired: '',
  tfaCode: null,
  db: '',
  photo: "https://cdn.multiclinic.co.uk/assets/no-profile.jpg" 
}

export const enum PermittedType {
  None = "none",
  Write = "write",
  Read = "read"
}

export enum AvailablePermissions {
  diary_lock_to_own = 'diary_lock_to_own',
  diary_read_only = 'diary_read_only',
  notesSupervisor = 'notesSupervisor',
  allow_upload = 'allow_upload',
  allow_discharge = 'allow_discharge'
}

export const GroupPermissionsList = [
  {
    id: AvailablePermissions.allow_upload,
    text: "Can upload files",
    selected: false
  },
  {
    id: AvailablePermissions.diary_lock_to_own,
    text: "Can only see own diary",
    selected: false
  },
  {
    id: AvailablePermissions.diary_read_only,
    text: "Diary Read Only",
    selected: false
  },
  {
    id: AvailablePermissions.notesSupervisor,
    text: "Can sign off Notes",
    selected: false
  },
  {
    id: AvailablePermissions.allow_discharge,
    text: "Can discharge patients",
    selected: false
  }
]

export interface Permissions {
//  staff: PermittedType;
//  rooms: PermittedType;
//  clinics: PermittedType;
//  diary: PermittedType;
//  userList: PermittedType;
//  ownpatientrecords: PermittedType;
//  saleitems: PermittedType;
//  treatmentprices: PermittedType;
//  accounts: PermittedType;
//  voidTxn: PermittedType;
//  till: PermittedType;
//  reports: PermittedType;
//  export_report: string;
//  sys_admin: string;
//  patientmerger: PermittedType;
//  redateTransactions: string;
//  receptionist: PermittedType;
  diary_lock_to_own: boolean;
  diary_read_only: boolean;
  notesSupervisor: boolean;
  allow_upload: boolean,
  allow_discharge: boolean
}
/*
export interface GroupPermissions {
  diary_lock_to_own: number,
  access_redateTransactions: number,
  tfaRequired: number,
  diary_read_only: number,
  isSupervisor: number,
  allow_upload: number,
  allow_discharge: number
}
*/

export const initialPermissions: Permissions = {
//  staff: PermittedType.None,
//  rooms: PermittedType.None,
 // clinics: PermittedType.None,
//  diary: PermittedType.None,
//  userList: PermittedType.None,
//  ownpatientrecords: PermittedType.None,
//  saleitems: PermittedType.None,
//  treatmentprices: PermittedType.None,
//  accounts: PermittedType.None,
//  voidTxn: PermittedType.None,
//  till: PermittedType.None,
//  reports: PermittedType.None,
//  export_report: "",
//  sys_admin: "",
//  patientmerger: PermittedType.None,
//  redateTransactions: "",
//  receptionist: PermittedType.None,
  diary_lock_to_own: false,
  diary_read_only: false,
  notesSupervisor: false,
  allow_discharge: false,
  allow_upload: false
}
/*
export const initialGroupPermissions = {
  diary_lock_to_own: 0,
  access_redateTransactions: 0,
  tfaRequired: 0,
  diary_read_only: 0,
  isSupervisor: 0,
  allow_upload: 0,
  allow_discharge: 0
}
*/
export const initialState = {
  jwt: '',
  meta: initialUserMeta,
  permissions: initialPermissions,
 // groupPermissions: initialGroupPermissions
};