import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, SimpleChanges } from '@angular/core';


@Component({
  selector: '[PLline]',
  templateUrl: './p-and-l-line.component.html',
  styleUrls: ['./p-and-l-line.component.css'],
})
export class PandLLineComponent {
  @Input('line') line;
  @Input('title') title;
  @Output('drilldownClick') drillDownClick = new EventEmitter();
  total: number = 0;

  constructor() { }

  ngOnInit() {
    this.updateTotal();
  }

  ngOnChanges() {
    this.updateTotal();
  }

  updateTotal() {
    this.total = 0;
    console.log(this.line);
    for (let l of this.line) {
      if (l) {
        this.total += parseFloat(l);
      }
    }
  }

  drillDown(month) {
    this.drillDownClick.emit(month);
  }
}
