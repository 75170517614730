import { AuthNeededGuard } from 'app/guards/auth.guard';
import { GenericReportComponent } from './generic/generic-report.component';

export const ReportsRoutes = [
    {
        path: 'report',
        children: [ 
            {
                path: ':id',
                component: GenericReportComponent,
                canActivate: [AuthNeededGuard]

            }

        ]
    }
]
