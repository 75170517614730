import { Component } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../store/reducers';
import { PatientChooserService } from '../../services/patient.service';


@Component({
  selector: 'app-notes-general-info-area',
  templateUrl: './notes-general-info-area.component.html',
  styleUrls: ['./notes-general-info-area.component.scss']
})
export class NotesGeneralInfoComponent {
  public patientIdx: number;
  public appointmentId: number;

  //public formName = "";
  public formId = "";
  public isShowingForm = true;
  private noteMeta;
  constructor(private route: ActivatedRoute, private store: Store<fromRoot.State>, 
    private router: Router, public patientChooser: PatientChooserService) { }

  ngOnInit() {
    //this.router.navigate([`notes`])
  }

  viewNote() {
    this.router.navigate([`notes`,`soap`,this.noteMeta.notesID]);
  }

  closeForm(event?: any) {
    this.router.navigate([`notes`]);
  }

}
