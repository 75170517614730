import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { DataService } from '../../services/data.service';
import { GiftVoucherPayment } from "../../models/payment-methods";
import { IGetRowsParams } from 'ag-grid-community';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-gift-card-validator',
  templateUrl: './gift-card-validator.component.html',
  styleUrls: ['./gift-card-validator.component.scss']
})
export class GiftCardValidatorComponent implements OnInit {
  @Output()
  giftcardValidated = new EventEmitter<GiftVoucherPayment>();

  public giftCardCode = "";
  public errorMessage = "";

  constructor(private http: DataService) { }

  ngOnInit() {
  }

  validate() {
    //FnANOmGS
    console.log("[GC] validating")
    this.checkCodeAgainstDatabase(this.giftCardCode).subscribe((res) => {
      if (res.ok === true) {
        this.giftcardValidated.emit(
        {
          code:   this.giftCardCode,
          amount: parseFloat(res.amount),
          id: res.id
        });
        console.log("[GC] validated")
      } else {
        this.errorMessage = res.error;
        console.log("[GC] invalid")
      }
    });
  }

  cancel() {
    this.giftcardValidated.emit(null);
  }

  checkCodeAgainstDatabase(code: string): Observable<any> {
    return this.http.get<any>(`/till/giftvoucher/${code}`);
  }

}
