import { Action } from '@ngrx/store';
import { Clinic } from '../../models/clinics';
import { type } from '../util';

export const SET = 'CLINICS_SET';
export const INIT = 'CLINICS_INIT';
export const SELECTED = 'CLINICS_SELECTED';

export const ActionTypes = {
    SET: type('[Clinics] Set'),
    INIT: type('[Clinics] Init'),
    SELECTED: type('[Clinics] Selected')

};


/**
 * Every action is comprised of at least a type and an optional
 * payload. Expressing actions as classes enables powerful 
 * type checking in reducer functions.
 * 
 * See Discriminated Unions: https://www.typescriptlang.org/docs/handbook/advanced-types.html#discriminated-unions
 */
export class SetAction implements Action {
    type = ActionTypes.SET;
    constructor(public payload: Clinic[]) { }
}

export class SelectedAction implements Action {
    type = ActionTypes.SELECTED;
    constructor(public payload: Clinic) {};
} 

export class InitAction implements Action {
    type = ActionTypes.INIT;
    constructor() { }
}

/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
export type Actions
    = SetAction |
    InitAction |
    SelectedAction;
