<div mat-dialog-title class="dialog-title">
    <h5>{{data.title}}</h5>
    <button mat-icon-button aria-label="close dialog" class="close-button" tabindex="-1" mat-dialog-close>
        <mat-icon class="close-icon text-muted">close</mat-icon>
    </button>
</div>

<div mat-dialog-content>
    <mat-form-field class="w-100">
        <mat-label>Title</mat-label>
        <input 
            #inputEle
            matInput 
            id="newName" 
            name="newName"
            [(ngModel)]="textInput" />
    </mat-form-field>
</div>

<div mat-dialog-actions>
    <div class="d-flex justify-content-center" style="min-width: 400px;">
        <div>
            <button mat-raised-button class="w-100" color="primary" (click)="onConfirm(true)">
                Add
            </button>
        </div>
    </div>
</div>