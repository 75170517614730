import { Injectable } from '@angular/core';
import { DataService } from 'app/services/data.service';
import { Observable } from 'rxjs';

export interface DynamicDropdownOption{
  idx: number;
  text: string;
  info?: string
}

@Injectable({
  providedIn: 'root'
})
export class DynamicAutoCompleteService {

    cache: any[] = [];

    constructor(
        private http: DataService
    ) {}

    get(fieldIdx: number) : Observable<DynamicDropdownOption[]>{
      return  new Observable<DynamicDropdownOption[]>((subscriber)  =>{
        //is it in chache, if so send that.
        if (this.cache[fieldIdx]){
          subscriber.next(this.cache[fieldIdx]);
        }
        //recover data, update cache and emit
        this.http.get<DynamicDropdownOption[]>(`/forms/dynamicoptions/${fieldIdx}`).subscribe(res=>{
          this.cache[fieldIdx] = res;
          subscriber.next(res);
          subscriber.complete();
        });
      });
      
      
    }

}
