<ng-template cdkConnectedOverlay 
    [cdkConnectedOverlayOrigin]="origin" 
    [cdkConnectedOverlayOpen]="filteredFastText"
    [cdkConnectedOverlayMinWidth]="containerWidth"
    (detach)="detach($event)"
    (overlayKeydown)="keyDown($event)">
    <div class="root">
        <div (click)="editMode()" class="edit"><mat-icon>edit</mat-icon></div>
        <ul >
            <li>{{search}}&nbsp;</li>
            <li *ngFor="let text of filteredFastText" 
                [ngClass]="{'selected': text==selectedOption}"
                (click)="selectOption(text)">
                <div class="short">{{text.short}}</div>
                <div class="long">{{text.long}}</div>
            </li>
        </ul>
    </div>
</ng-template>