<div class="row margin-collapse">
    <div class="col-lg-10" *ngIf="template">
        <mat-card><mat-card-content>
            <h2>{{ templateIdx ? 'Edit Template' : 'New Template' }}</h2>

            <app-template-keyword-guide></app-template-keyword-guide>
            <div class="row m-top">
                <div class="col">
                    <mat-form-field>
                        <mat-label for="templateName">Template Name</mat-label>
                        <input matInput name="templateName" type="text" [(ngModel)]="template.title">
                    </mat-form-field>
                    <mat-form-field>
                        <mat-label for="templateDescription">Description</mat-label>
                        <input matInput name="templateName" type="text" [(ngModel)]="template.description">
                    </mat-form-field>
                </div>
            </div>

            <br>
            <div class="cardContainer">
                <div class="cardCol">
                    <angular-editor [placeholder]="'Enter text here...'" [(ngModel)]="template.content"
                        [config]="editorConfig" #html (ngModelChange)="change($event)"></angular-editor>
                </div>
            </div>
        </mat-card-content></mat-card>
    </div>
    <div class="col-sm-2 no-print mobile-margin">
        <div class="fix-top">
            <div class="mb-3">

                <div class="mobile-buttons mobile-invoice-buttons">

                    <div class="my-3 desktop-buttons">
                        <button mat-raised-button color="primary" class="mobile-button desktop-button"
                            (click)="save()">Save</button>
                    </div>
                    <div class="my-3 desktop-buttons">
                        <button mat-raised-button class="mobile-button desktop-button" (click)="cancel()">Back</button>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>