import { ComponentType } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ConfirmDialog, FormFields, FormInput, GenericInput, QRCodeDialog } from '../../models/dialog';
import { MatDialog } from "@angular/material/dialog";

/*
export class ConfirmDialog {
  public title: string;
  public message: string;
  public args: any[];
  public componentName: string;

  constructor( title: string, message: string, componentName?: string, args?: any[] ){
    this.title = title;
    this.message = message;
    this.args = args;
    this.componentName = componentName;
  }
}
*/
import { FormDialogComponent } from 'app/dialogs/form-dialog/form-dialog.component';
import { GenericDialogComponent } from 'app/dialogs/generic-dialog/generic-dialog.component';
import { QRService } from '../qr.service';
import { QRCodeDialogComponent } from 'app/dialogs/qr-code-dialog/qr-code-dialog.component';
import { DynamicFormDialogComponent } from 'app/dialogs/dynamic-form-dialog/dynamic-form-dialog.component';
import { QuestionnaireDialogComponent } from 'app/dialogs/questionnaire-dialog/questionnaire-dialog.component';
import { LoadingService } from '../loading.service';
import { FasttextEditDialogComponent } from 'app/dialogs/fasttext-edit-dialog/fasttext-edit-dialog.component';

export interface DialogResult {
  data: any;
  index: number;
}
@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(public dialog: MatDialog,
    private qr: QRService,
    private loading: LoadingService) { }

  genericDialog(
    title: string, // title of dialog
    message: string, // main body message
    confirmText: string[], // text for confirm button
    dismissText: string[], // text for dismiss button
    warning = false, // warning box required?
    warningMessage = "" // warning message
  ): Promise<DialogResult> {
    return new Promise((resolve, reject) => {
      title = title || "Confirm Action";
      const data = new GenericInput(title, message, confirmText, dismissText, warning, warningMessage || null);
      const dialogReference = this.dialog.open(GenericDialogComponent, {
        data: data
      });
      dialogReference.afterClosed().subscribe((dialogResult: any) => {
        resolve(dialogResult);
      });
    });
  }


  questionnaireDialog(
    title: string, // title of dialog
    message: string, // main body message
    confirmText: string[], // text for confirm button
    dismissText: string[], // text for dismiss button
    warning = false, // warning box required?
    warningMessage = "" // warning message
  ): Promise<DialogResult> {
    return new Promise((resolve, reject) => {
      title = title || "Confirm Action";
      const data = new GenericInput(title, message, confirmText, dismissText, warning, warningMessage || null);
      const dialogReference = this.dialog.open(QuestionnaireDialogComponent, {
        data: data
      });
      dialogReference.afterClosed().subscribe((dialogResult: any) => {
        resolve(dialogResult);
      });
    });
  }

  dynamicFormDialog(
    formName: string, // title of dialog
  ): Promise<DialogResult> {
    return new Promise((resolve, reject) => {
      const dialogReference = this.dialog.open(DynamicFormDialogComponent, {
        data: { formName: formName, isDialog: true }

      });
      dialogReference.afterClosed().subscribe((dialogResult: DialogResult) => {
        resolve(dialogResult);
      });
    });
  }





  formDialog(
    title: string, // title of dialog
    message: string, // main body of message
    formFields: FormFields[], // form elements to add to dialog
    confirmText: string[], // text for confirm button
    dismissText: string[], // text for dismiss button
    inputText: string[], // text for input button
    callback?: Function // optional callback to process input
  ) {
    return new Promise((resolve, reject) => {
      const form = new UntypedFormGroup({
        name: new UntypedFormControl(''),
        helperText: new UntypedFormControl(''),
        isRequired: new UntypedFormControl(false),
        inputType: new UntypedFormControl('none')
      });
      const data = new FormInput(title, message, formFields, confirmText, dismissText, inputText, form);
      const dialogReference = this.dialog.open(FormDialogComponent, {
        data: data,
        maxWidth: "400px"
      });
      dialogReference.afterClosed().subscribe((dialogResult: any) => {
        if (callback) {
          callback(dialogResult).then(response => {
            if (response.error) {
              resolve(response)
            }
          });
        } else {
          resolve(dialogResult);
        }
      });
    });
  }

  bespokeDialog(
    message: string,
    component: ComponentType<unknown>,
    componentName: string,
    title?: string,
    args?: any,
    maxWidth?: string,
    width?: string
  ) {

    /* Takes dialog component as an input and opens that bespoke dialog */

    return new Promise((resolve, reject) => {
      message = message || `Are you sure you want to do this?`;
      title = title || "Confirm Action";
      args = args || null;
      const dialogData = new ConfirmDialog(title, message, componentName, args);

      const styles: any = {
        maxWidth: maxWidth || "400px"
      };

      if (width) { styles.width = width; }

      const dialogReference = this.dialog.open(component, {
        ...styles,
        data: dialogData
      });
      dialogReference.afterClosed().subscribe(dialogResult => {
        console.log("[pin] closes", dialogResult);
        resolve(dialogResult);
      });
    });
  }
  // Closes this dialog box
  close() {
  this.dialog.closeAll();
  }

  qrDialog(
    title: string,
    qrData: string
  ) {
    console.log("[dialog] new QR code", qrData);
    return new Promise((resolve, reject) => {
      this.qr.getImage(qrData).subscribe((res: any) => {

        console.log("[qr]", res);

        const dialogData = new QRCodeDialog(title, res);

        console.log("[qr]", dialogData);

        const styles: any = {
          maxWidth: "400px"
        };


        const dialogReference = this.dialog.open(QRCodeDialogComponent, {
          ...styles,
          data: dialogData
        });
        this.loading.stop();
        dialogReference.afterClosed().subscribe(dialogResult => {
          resolve(dialogResult);
        });

      });
    });
  }

  fastTextEditDialog(
    data: any
  ): Promise<DialogResult> {
    return new Promise((resolve, reject) => {

      const styles = {
        minWidth: "300px",
        panelClass: 'my-edit-dialog'
      };
      let dialogData = data ? data : []
      const dialogReference = this.dialog.open(FasttextEditDialogComponent, {
        ...styles,
        data: dialogData
      });

      dialogReference.afterClosed().subscribe((dialogResult: DialogResult) => {
        resolve(dialogResult);
      });
    });
  }

}
