import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { TreatmentCardTemplate, TreatmentCard, TreatmentCardGroups } from 'app/models/treatment-cards';
import { LoadingService } from 'app/services/loading.service';
import { TreatmentCardService } from 'app/services/notes/treatment-card.service';
@Component({
  selector: 'app-treatment-card',
  templateUrl: './treatment-card.component.html',
  styleUrls: ['./treatment-card.component.scss']
})
export class TreatmentCardComponent implements OnInit {
  public treatmentCards: TreatmentCard[] = [];
  public newTreatmentCard: TreatmentCardGroups;
  public assocDiagnosis: number;
  newLabels: any;
  public notesIdx: number;
  public ascSorting: boolean = true;

  public treatmentCardTemplates: TreatmentCardTemplate[];
  public labelItterator = [];

  constructor(private treatmentCardService: TreatmentCardService,
    private route: ActivatedRoute,
    private loading: LoadingService) { }

  ngOnInit(): void {
    this.loadTreatmentCardTemplates();

    this.route.params.subscribe((params: Params) => {
      if (params["notesIdx"]) {
        this.notesIdx = +params['notesIdx'];
        this.loadTreatmentCard(this.notesIdx);
      }
    });
  }


  private loadTreatmentCard(notesIdx: number) {
    this.treatmentCardService.getTreatmentCards(notesIdx).subscribe(res => {
      this.treatmentCards = res.cards;
      this.assocDiagnosis = res.assocDiagnosis
      this.newTreatmentCard = {rxNo: this.treatmentCards.length+1, date: new Date().toUTCString(), cards: [] };
      this.loading.stop();
    })
  }

  private loadTreatmentCardTemplates(): void {
    this.treatmentCardService.getTreatmentCardTemplates().subscribe(treatmentCards => {
      this.treatmentCardTemplates = treatmentCards
      //   this.newTreatmentCardTemplate = this.treatmentCardTemplates[0];
    })
  }
/*
  public initialiseNewTreatmentCard(): void {
    this.sortByRxNumber();

    //   this.newTreatmentCard = { ...this.newTreatmentCard,
    //     topLabels : [...this.newTreatmentCardTemplate.topLabels],
    //    middleLabels : [...this.newTreatmentCardTemplate.middleLabels],
    //    bottomLabels : [...this.newTreatmentCardTemplate.bottomLabels]
    //  };

  }

  */

  public addNewNotes() {
    const getAllIdx = this.treatmentCards.map(t => t.rxNo);
    const baseRxNo = getAllIdx.length > 0 ? getAllIdx[0] + 1 : 1;
    this.newTreatmentCard.cards.push({
        idx: 0,
        rxNo: baseRxNo,
        name: "Notes",
        type: "notes",
        data: {
            topLabels: [""],
            middleLabels: [],
            bottomLabels: []
        }
    });
}


  public addNewCard(card: TreatmentCardTemplate) {
    const getAllIdx = this.treatmentCards.map(t => t.rxNo);
    const baseRxNo = getAllIdx.length > 0 ? getAllIdx[0] + 1 : 1;
    this.newTreatmentCard.cards.push({
      idx: 0,
      rxNo: baseRxNo,
      name: card.name,
      type: "card",
      data: {
        topLabels: [...card.topLabels],
        middleLabels: [...card.middleLabels],
        bottomLabels: [...card.bottomLabels]
      }
    });

    this.labelItterator = new Array(card.topLabels.length).fill("x");

    this.newLabels = { top: [...card.topLabels] };
  }

/*

  public trackByFn(index: number): number {
    return index;
  }

  public sortByRxNumber(): void {
    this.treatmentCards.sort((treatmentCardA: any, treatmentCardB: any) => {
      return this.ascSorting ? treatmentCardA.RxNo - treatmentCardB.RxNo : treatmentCardB.RxNo - treatmentCardA.RxNo;
    });
  }
*/
  public save(): void {
    this.loading.start();
    if (this.newTreatmentCard.cards.length > 0) {
        this.treatmentCardService.saveTreatmentCard(this.notesIdx, this.newTreatmentCard).subscribe(res => {
            this.loadTreatmentCard(this.notesIdx);
        });
    }
}


}

