<div class="row margin-collapse">
  <div class="col-sm-10">
      <mat-card><mat-card-content>  
        <h2>Open Tills</h2>
        <ng-container *ngIf="tillsData.length > 0">
          <table class="table">
            <tbody>
              <tr *ngFor="let till of tillsData">
                <td>{{ till.username }}</td>
                <td>{{ till.TotalAmount | currency:'GBP' }}</td>
                <td><a routerLink="/finance/xzreports/X/{{ till.userID }}/0" mat-stroked-button>X Report</a></td>
                <td><a routerLink="/finance/xzreports/Z/{{ till.userID }}/0"  class="fixed-width" mat-stroked-button>Z Report (Close Till)</a></td>
              </tr>
              <tr class="totals">
                <td>All Tills</td>
                <td>{{ total | currency: 'GBP'}}</td>
                <td><a routerLink="/finance/xzreports/X/all/0" mat-stroked-button>X Report</a></td>
                <td><a routerLink="/finance/xzreports/Z/all/0" class="fixed-width" mat-stroked-button>Z Report (Close All Tills)</a></td>
              </tr>
            </tbody>
          </table>
        </ng-container>

        <ng-container *ngIf="tillsData.length==0">
          <table class="table">
            <thead>
              <tr>
                <th>No Open Tills</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>No open tills. Tills are automatically opened when a user makes their first transaction. Tills are then
                  closed by generating Z-Reports.</td>
              </tr>
            </tbody>
          </table>
        </ng-container>
      </mat-card-content></mat-card>
  </div>

<div class="col-sm-2">
  <div class="fix-top">
          <div class="my-3 text-center mobile-button">
              <button mat-raised-button 
              color="primary"
              (click)="back()"
              class="side-button" 
              >Back</button>
          </div>
  </div>
</div>
</div>
