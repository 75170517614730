export const MskhqQuestions: { label: string, field: string, options: string[] }[] = [
    {
        label: "Pain/stiffness during the day. How severe was your usual joint or muscle pain and/or stiffness overall during the day in the last 2 weeks?",
        field: "pain_during_day",
        options: ['Not at all', 'Slightly', 'Moderately', 'Fairly severe', 'Very severe']
    },
    {
        label: "Pain/stiffness during the night. How severe was your usual joint or muscle pain and/or stiffness overall during the night in the last 2 weeks?",
        field: "pain_during_night",
        options: ['Not at all', 'Slightly', 'Moderately', 'Fairly severe', 'Very severe']
    },
    {
        label: "Walking. How much have your symptoms interfered with your ability to walk in the last 2 weeks?",
        field: "pain_walking",
        options: ['Not at all', 'Slightly', 'Moderately', 'Severely', 'Unable to walk']
    },
    {
        label: "Washing/Dressing. How much have your symptoms interfered with your ability to wash or dress yourself in the last 2 weeks?",
        field: "washing_dressing",
        options: ['Not at all', 'Slightly', 'Moderately', 'Severely', 'Extremely']
    },
    {
        label: "Physical activity levels. How much has it been a problem for you to do physical activities (e.g. going for a walk or jogging) to the level you want because of your joint or muscle symptoms in the last 2 weeks?",
        field: "physical_activity",
        options: ['Not at all', 'Slightly', 'Moderately', 'Very much', 'Extremely']
    },
    {
        label: "Work/daily routine. How much have your joint or muscle symptoms interfered with your work or daily routine in the last 2 weeks (including work & jobs around the house)?",
        field: "daily_routine",
        options: ['Not at all', 'Slightly', 'Moderately', 'Severely', 'Extremely']
    },
    {
        label: "Social activities and hobbies. How much have your joint or muscle symptoms interfered with your social activities and hobbies in the last 2 weeks?",
        field: "social_activities",
        options: ['Not at all', 'Slightly', 'Moderately', 'Severely', 'Extremely']
    },
    {
        label: "Needing help. How often have you needed help from others (including family, friends or carers) because of your joint or muscle symptoms in the last 2 weeks?",
        field: "needing_help",
        options: ['Not at all', 'Rarely', 'Sometimes', 'Frequently', 'All the time']
    },
    {
        label: "Sleep. How often have you had trouble with either falling asleep or staying asleep because of your joint or muscle symptoms in the last 2 weeks?",
        field: "sleep",
        options: ['Not at all', 'Rarely', 'Sometimes', 'Frequently', 'Every night']
    },
    {
        label: "Fatigue or low energy. How much fatigue or low energy have you felt in the last 2 weeks?",
        field: "fatigue",
        options: ['Not at all', 'Slight', 'Moderate', 'Severe', 'Extreme']
    },
    {
        label: "Emotional well-being. How much have you felt anxious or low in your mood because of your joint or muscle symptoms in the last 2 weeks?",
        field: "well_being",
        options: ['Not at all', 'Slightly', 'Moderately', 'Severely', 'Extremely']
    },
    {
        label: "Understanding of your condition and any current treatment. Thinking about your joint or muscle symptoms, how well do you feel you understand your condition and any current treatment (including your diagnosis and medication)?",
        field: "understanding_condition",
        options: ['Completely', 'Very well', 'Moderately', 'Slightly', 'Not at all']
    },
    {
        label: "Confidence in being able to manage your symptoms. How confident have you felt in being able to manage your joint or muscle symptoms by yourself in the last 2 weeks (e.g. medication, changing lifestyle)?",
        field: "confidence",
        options: ['Completely', 'Very', 'Moderately', 'Slightly', 'Not at all']
    },
    {
        label: "Overall impact. How much have your joint or muscle symptoms bothered you overall in the last 2 weeks?",
        field: "overall_impact",
        options: ['Not at all', 'Slightly', 'Moderately', 'Severely', 'Unable to walk']
    },
];