<div class="body">
  <div class="hide-right-panel-button" (click)="hideRightSidebar()">
    <mat-icon>keyboard_arrow_up</mat-icon>
    Appointment Info
    <mat-icon>keyboard_arrow_up</mat-icon>
  </div>


  <div *ngIf="clickedEvent" class="pr-20">
    <div class="app-details">
      <div class="row">
        <div class="col-sm-12">
         <h5 class="text-center">{{ clickedEvent.meta.patientName }}</h5>
         <div *ngIf="contactDetails?.phone"><a class="btn btn-link font-size-08 ps-1" href="tel:{{contactDetails.phone}}"><mat-icon class="small me-3">call</mat-icon>{{contactDetails.phone}}</a></div>
         <div *ngIf="contactDetails?.email"><a class="btn btn-link font-size-08 ps-1"  href="mailto:{{contactDetails.email}}"><mat-icon class="small me-3">email</mat-icon>{{contactDetails.email}}</a></div>
        </div>
      </div>
      <hr/>
      <div class="row list-size-overide text-center">
        <div class="col-sm-12">
          {{clickedEvent.meta.treatment}} in {{clickedEvent.meta.roomName}}
        </div>
      </div>


      <div class="row text-center app-time">
        <div class="col-sm-6">
          <mat-icon class="small" matListIcon>calendar_today</mat-icon>
          <p class="staff-member-name center" matLine>
            {{ clickedEvent.start | date }}
          </p>
        </div>
        <div class="col-sm-6">
          <mat-icon class="small" matListIcon>query_builder</mat-icon>
          <p class="staff-member-name center" matLine>
            {{ clickedEvent.start | date: "HH:mm" }} - {{ clickedEvent.end | date: "HH:mm" }}
          </p>
        </div>
      </div>

      <div class="left-icon-list">


        <div *ngIf="clickedEvent.meta.notes" class="notes px-1 mb-1">
          {{clickedEvent.meta.notes}}
        </div>

        <ng-template [ngIf]="clickedEvent.meta.status != AppointmentStatus.TIMEOFF && attendance">

          <div *ngIf="clickedEvent.meta.patientGeneralNotes" class="notes px-2 mb-2">
            {{clickedEvent.meta.patientGeneralNotes}}
          </div>

          <div class="row list-size-overide">
            <div *ngIf="attendance.previousApps" class="col-sm-6 font-size-08 text-center">
              <div class='px-1 center'>
                Past Apps.
                <p class="staff-member-name center" matLine>{{attendance.previousApps }}</p>
              </div>
            </div>

            <div *ngIf="attendance.lastVisit" class="col-sm-6 font-size-08 text-center">
              <div class='px-1 center'>
                Last App.
                <p class="staff-member-name center" matLine>{{attendance.lastVisit | date: 'dd/MM/yyy'}}</p>
              </div>
            </div>
          </div>
          <div class="row list-size-overide">
            <div class="col-sm-6 font-size-08 text-center">
              <div class='px-1 center'>
                DNAs
                <p class="staff-member-name center" matLine>{{attendance.dnaTotalCount}} ({{attendance.dnaUnpaidCount}})</p>
              </div>
            </div>

            <div *ngIf="attendance.lastDNA " class="col-sm-6 font-size-08 text-center">
              <div class='px-1 center'>
                Last DNA
                <p class="staff-member-name center" matLine>{{attendance.lastDNA| date: 'dd/MM/yyy'}}</p>
              </div>
            </div>
          </div>
          <div class="row list-size-overide">
            <div class="col-sm-6 font-size-08 text-center">
              <div class='px-1 center'>
                LNCs
                <p class="staff-member-name center" matLine>{{attendance.lncTotalCount }} ({{attendance.lncUnpaidCount}})</p>
              </div>
            </div>

            <div *ngIf="attendance.lastLNC" class="col-sm-6 font-size-08 text-center">
              <div class='px-1 center'>
                Last LNC
                <p class="staff-member-name center" matLine>{{attendance.lastLNC | date: 'dd/MM/yyy'}}</p>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>

  <app-diary-actions [appointment]="clickedEvent" [styleType]="'icons'" (clicked)="actionClicked($event)">
  </app-diary-actions>
</div>
