import { Injectable } from '@angular/core';
import { DynamicFormSaveService } from 'app/dynamic-form/services/dynamic-form-save.service';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { LoadingService } from '../loading.service';

export const NotesFormState = {
  SAVED: 'saved',
  DIRTY: 'dirty',
  SAVING: 'saving'
}

export interface FormSectionUpdate{
  formName: string,
  recordIdx: number;
}
@Injectable()
export class NotesSaveService {

  public formStates = [];
  private autoSave$ = [];
  public currentNotesTab = new BehaviorSubject('');
  public formSectionSaves$ = new Subject<FormSectionUpdate>;


  constructor(
    private formService: DynamicFormSaveService,
    private loading: LoadingService) { }

  public save(data) {
    data.formName = data.formName.includes('_usw') ? data.formName.substring(0, data.formName.length - 4) : data.formName;
    
    this.formStateChange(data.formName, NotesFormState.DIRTY);
    if(this.autoSave$[data.formName]){
      clearTimeout(this.autoSave$[data.formName]);
    }
    this.autoSave$[data.formName] = setTimeout(() => { this.doSave(data); }, 5000);
  }

  private doSave(data) {
    this.loading.backgroundStart();
    this.formStateChange(data.formName, NotesFormState.SAVING);
    this.formService.saveForm(data).subscribe(res => {
      this.formStateChange(data.formName, NotesFormState.SAVED);
      this.loading.backgroundEnd();
      this.formSectionSaves$.next({recordIdx: res.idx, formName: data.formName});
    })
    console.log(this.autoSave$);
  }

  formStateChange(form, state) {
    this.formStates[form] = state;
    console.log("[status]", this.formStates);
  }

  awaitAllSaved() : Observable<number>{ // keeps cecking till all the forms are saved
      return new Observable(subscriber => {
        this.emitComplete(subscriber);

        const check$ = setInterval(() => {
          if (this.emitComplete(subscriber)){
            clearInterval(check$);
            subscriber.complete();
          };
        }, 250);
      });
  }

  private emitComplete(subscriber){
    const ok = this.checkAllSaved();
    if (ok==0){
      subscriber.next(0);
      return true;
    }
      subscriber.next(ok);
      return false;
  }


  checkAllSaved(): number{
    let pending = 0;    
    for (let state in this.formStates){
      if (this.formStates[state] == NotesFormState.DIRTY || this.formStates[state] == NotesFormState.SAVING) {
        pending++;
      }
    };
    return pending;
  }

}
