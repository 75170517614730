import {SubMenuItem} from "./menu"


export interface NotesSettings {
        enableSignOff: boolean;
        enableCaseHistory: boolean;
        enableQuestionnaire: boolean;
        enablePhysicalExam: boolean;
        enableCaseSummary: boolean;
        enableSOAP: boolean;
        enableLetters: boolean;
        enablePhoneConsult: boolean;
        enableUploads: boolean;
        enableTreatmentCards: boolean;
        enableRxNo: boolean;
}
export interface State {
 //   submenu?: SubMenuItem[];
    site?: {
        topLogo: string,
        invoiceLogo: string,
        diarySpecialAppointments?: any[],
        siteName?: string
    };
    notes?: NotesSettings,
   
}
export interface ReportFilter {
    reportName: string,
    columnFilters: any,
    columnState: any,
    columnGroupState: any
}


export const initialState: State = {
  
  //  submenu: [],
    site: {
        topLogo: "",
        invoiceLogo: "",
        diarySpecialAppointments: []
    },
    notes: {
        enableSignOff: false,
        enableCaseHistory: true,
        enableQuestionnaire: true,
        enablePhysicalExam: true,
        enableCaseSummary: true,
        enableSOAP: true,
        enableLetters: true,
        enablePhoneConsult: true,
        enableUploads: true,
        enableTreatmentCards: true,
        enableRxNo: true
    }
}



export interface SortingFilter {
    colId: string;
    sort: string;
    sortIndex?: number;
}

export const Setting = {
    ONLINE_FORMS: "online-booking-forms"
}
