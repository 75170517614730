<div class="d-flex justify-content-between align-center align-items-center">
  <div>
    <h1 class="p-2">Profit and Loss</h1>
  </div>
  <div class="m-2">
    <span class="export-csv mat-mdc-button-touch-target d-flex align-items-center" (click)="exportElmToExcel()"><mat-icon>forward</mat-icon>Export</span>
  </div>
</div>


<div class="row formContainer">
  <div class="col-3 px-2 text-muted">{{ currentStartDate | date : 'dd/MM/yyyy' }} to {{ currentEndDate | date : 'dd/MM/yyyy' }}</div>

  <div class="col-1">
    <input type="hidden" name="startDatePicker" [matDatepicker]="startDatepicker" [(ngModel)]="startDate" (dateChange)=startDateChanged($event)>
    <mat-datepicker-toggle [for]="startDatepicker"></mat-datepicker-toggle>
    <mat-datepicker #startDatepicker></mat-datepicker>
  </div>

  <div class="col-8">
    <mat-checkbox style="margin-left: 1rem" *ngFor="let clinic of clinics" (change)="clinicsChanged()" [name]="clinic.idx" [(ngModel)]="clinic.selected">{{ clinic.name }}</mat-checkbox>
  </div>
</div>


  <div class="tableContainer">
    <table #userTable class="table">
      <tbody>
        <tr class="year">
          <th>Year</th>
          <td *ngFor="let headingSpan of headingsSpans" [attr.colspan]="headingSpan.span">{{ headingSpan.year }}</td>
        </tr>
        <tr class="month">
          <th>Month</th>
          <td *ngFor="let heading of headings">{{ heading.monthName }}</td>
        </tr>
    
        <!-- Sales -->
        <tr class="section"><th>Sales</th><td colspan="14"></td></tr>
  
        <ng-container *ngFor="let itemSale of itemSales">
          <tr *ngIf="rowExists(itemSale.items)">
            <th>{{ itemSale.heading }}</th>
            <td *ngFor="let item of itemSale.items; let i = index" class="cell" (click)="navigateToReport('DrillDownIncome', itemSale, i)">{{ item | currency : 'GBP' : 'symbol' }}</td>
          </tr>
        </ng-container>
  
        <tr *ngIf="rowExists(commission)">
          <th>Commission</th>
          <td *ngFor="let item of commission; let i = index" class="cell" (click)="navigateToReport('DrillDownIncome', commission, i, 'commission')">{{ item | currency : 'GBP' : 'symbol' }}</td>
        </tr>
  
        <tr *ngIf="rowExists(vouchers)">
          <th>Vouchers / Marketing</th>
          <td *ngFor="let item of vouchers; let i = index" class="cell" (click)="navigateToReport('DrillDownPaymentMethod', vouchers, i, 'vouchers')">{{ item | currency : 'GBP' : 'symbol' }}</td>
        </tr>
  
        <tr *ngIf="rowExists(giftVouchers)">
          <th>Gift Vouchers</th>
          <td *ngFor="let item of giftVouchers; let i = index" class="cell" (click)="navigateToReport('DrillDownIncome', giftVouchers, i, 'voucher')">{{ item | currency : 'GBP' : 'symbol' }}</td>
        </tr>
  
        <ng-container *ngFor="let moneyInFromSale of moneyInFromSales">
          <tr *ngIf="rowExists(moneyInFromSale.items)">
            <th>{{ moneyInFromSale.heading }}</th>
            <td *ngFor="let item of moneyInFromSale.items; let i = index" class="cell" (click)="navigateToReport('DrillDownSales', moneyInFromSale, i)">{{ item | currency : 'GBP' : 'symbol' }}</td>
          </tr>
        </ng-container>
  
        <tr class="summary">
          <th>TOTAL REVENUE</th>
          <td class="cell" *ngFor="let revenue of totalRevenue; let i = index" (click)="navigateToReport('DrillDownIncome', totalRevenue, i)" >{{ revenue | currency : 'GBP' : 'symbol'}}</td>
        </tr>
    
        <!-- Cost of Sales -->
        <tr class="section costofsales"><th>Cost of Sales</th><td colspan="14">
          <mat-icon class="icon" *ngIf="!costOfSalesOpen" (click)="costOfSalesOpen = !costOfSalesOpen">expand_more</mat-icon>
          <mat-icon class="icon" *ngIf="costOfSalesOpen" (click)="costOfSalesOpen = !costOfSalesOpen">expand_less</mat-icon>
        </td></tr>
  
        <ng-container *ngIf="costOfSalesOpen">
          <ng-container *ngFor="let subcat of subcats">
            <tr *ngIf="rowExists(subcat.items)">
              <th>{{ subcat.heading }}</th>
              <td *ngFor="let item of subcat.items; let i = index" class="cell" (click)="navigateToReport('DrillDownPurchases', subcat, i)">{{ item | currency : 'GBP' : 'symbol'}}</td>
            </tr>
          </ng-container>
        </ng-container>
  
        <tr class="summary">
          <th>TOTAL EXPENDITURE</th>
          <td *ngFor="let expenditure of totalExpenditure">{{ -expenditure | currency : 'GBP' : 'symbol' }}</td>
        </tr>
    
        <!-- Bottom line -->
        <tr class="section"><th></th><td colspan="14"></td></tr>
  
        <tr>
          <th>GOP</th>
          <td *ngFor="let gop of totalGOP">{{ gop | currency : 'GBP' : 'symbol' }}</td>
        </tr>
  
        <tr>
          <th>% GOP</th>
          <td *ngFor="let gop of totalGOPPercentage">{{ gop }}%</td>
        </tr>
      </tbody>
    </table>
  </div> 
