import { UploadedFile } from "app/notes/uploader/uploader.component";

export class SOAPRecord {
    S: string;
    O: string;
    A: string;
    P: string;
    isActive: boolean;
    date?: string;
    idx?: number;
    rxno?: number;
    files?: UploadedFile[];
    completed?: boolean;
    revisedTime: number;
    assocDiagnosis?: number;
}
  
export interface SOAPData {
    soaps: SOAPRecord[];
    diagnosis: any;
    notesIdx: number;
    patientIdx: number;
}
  
export const initialSOAPData: SOAPData = {
    soaps: [], 
    diagnosis: {}, 
    patientIdx: 0, 
    notesIdx: 0

}

export const initialSOAP: SOAPRecord = {
    S: '', O: '', A: '', P: '',revisedTime: 0, isActive: true
}