<div class="container">
 



       <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Display Settings
            </mat-panel-title>
            <mat-panel-description>
             Modify the look and feel of MultiClinic
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div > 
              <div class="flex-item" > Use 'Classic' icons in the main menu   <mat-slide-toggle [style.float]="'right'" (change)="sliderToggled()" [(ngModel)]="isNewStyleChecked">
                </mat-slide-toggle> </div> 

      

          </div>
         
            
        </mat-expansion-panel>
    </mat-accordion>

       
</div>
