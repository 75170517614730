import { Injectable } from "@angular/core";
import * as fromRoot from "../../store/reducers";
import * as fromSettings from "../../store/actions/site-settings";
import * as fromMenu from "../../store/actions/menu";

import { Store } from "@ngrx/store";
import { SubMenuItem } from "../../models/menu";
import { Observable } from "rxjs";

@Injectable({
    providedIn: "root"
})
export class SubMenuService {

    constructor(private store: Store<fromRoot.State>) {
    }

    addNewItem(menuItem: SubMenuItem): void { //this is called when a menu item is clicked upon ¯\_(ツ)_/¯
        
        this.store.dispatch(new fromMenu.MenuScoreAdd(menuItem));

    }
/*
    removeItem(menuItem: SubMenuItem): void {
        
    }

    getSubMenuLastUsed(): Observable<Array<SubMenuItem>> {
        return this.store.select(fromRoot.getSubMenuLastUsed);
    }

*/
}
