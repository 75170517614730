export const OtherTestsFields: { label: string, field: string }[] =
  [
    {
      label: "Extremities ROM",
      field: "Extremities_ROM"
    },
    {
      label: "SOT Tests",
      field: "SOT_Tests"
    },
    {
      label: "Cardio/Abdo/Pulm",
      field: "CardioAbdoPulm"
    },
    {
      label: "Other Test",
      field: "Other_Test"
    },
    {
      label: "General Notes",
      field: "general"
    }
  ]
