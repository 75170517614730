import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogsModule } from 'app/dialogs/dialogs.module';
import { MyMaterialModule } from 'app/material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AngularEditorModule } from '@kolkov/angular-editor';

import { UISettingsComponent } from "./ui-settings/ui-settings.component";
import { AvailabilitySettingComponent } from './availability-setting/availability-setting.component';
import { AvailabilityComponent } from './availability-setting/availability/availability.component';
import { MenuSettingsComponent } from './menu-settings/menu-settings.component';
import { StockSettingsComponent } from './stock-settings/stock-settings.component';
import { EmailTemplatesComponent } from './email-templates/email-templates/email-templates.component';
import { EditTemplateComponent } from './email-templates/edit-template/edit-template.component';
import { TemplateKeywordGuideModule } from './template-keyword-guide/template-keyword-guide.module';
import { CalendarModule } from 'angular-calendar';
import { OnlineBookingSettingsComponent } from './online-booking-settings/online-booking-settings.component'
import { AgGridModule } from 'ag-grid-angular';
import { SettingsRoutingModule } from './settings.routes';
import { SubmitCancelModule } from 'app/submit-cancel/submit-cancel.module';
import { CustomFormControlsModule } from 'app/custom-form-controls/custom-form-controls.module';


@NgModule({
    imports: [
        AngularEditorModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MyMaterialModule,
        DialogsModule,
        TemplateKeywordGuideModule,
        CalendarModule,
        AgGridModule,
        SettingsRoutingModule,
        SubmitCancelModule,
        CustomFormControlsModule
    ],
    declarations: [
        UISettingsComponent,
        AvailabilitySettingComponent,
        AvailabilityComponent,
        MenuSettingsComponent,
        StockSettingsComponent,
        EmailTemplatesComponent,
        EditTemplateComponent,
        OnlineBookingSettingsComponent

    ],
    providers: [],
    exports: []
})
export class SettingsModule {}
