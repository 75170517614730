import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { AuthService } from 'app/services/auth.service';
import { Observable } from 'rxjs';
import { DataService } from '../../services/data.service';
import * as fromRoot from '../../store/reducers';
import * as notesActions from '../../store/actions/notes';
import * as fromNotes from 'app/models/notes';
import { map } from 'rxjs/operators';
import { SOAPData, SOAPRecord } from 'app/models/soap';
@Injectable()
export class SoapService {
    constructor(private http: DataService, 
        private auth: AuthService,
        private store: Store<fromRoot.State>) { }

    getSoap(notesIdx: number, patientIdx: number): Observable<any> {
        let qParam = "";
        if (patientIdx>0){
            qParam = `?patientIdx=${patientIdx}`;

        }
        return this.http.get<any>(`/notes/soap/${notesIdx}${qParam}`).pipe(map(res =>{
            console.log("[notes] internal ref checks on", res); 
            this.setLatestStatus({idx: res.notesIdx, formName: "notes_soap"});
            return res;
        }));
    }

    saveSoap(notesIdx: number, newSoap: SOAPRecord, file: any[]): Observable<any> {
        let data =
            {
                soap: {
                    file: JSON.stringify(file),
                    S: newSoap.S,
                    O: newSoap.O,
                    A: newSoap.A,
                    P: newSoap.P,
                    date: newSoap.date,
                    revisedTime: newSoap.revisedTime,
                    assocDiagnosis: newSoap.assocDiagnosis,
                    completed: newSoap.completed,
                    idx: newSoap.idx
                }
            }
        return this.http.post<any>(`/notes/soap/${notesIdx}`,data)
    }

    deleteSoap(notesIdx: number, patientIdx: number, idx: number, reason: string): Observable<any> {
        let data =
            {
                reason: reason
            }
        return this.http.post<any>(`/notes/soap/${notesIdx}/${patientIdx}/${idx}`,data)
    }

    deleteDiagnosis(dataIdx: number): Observable<any> {
        let data =[
            {
                isActive: false,
            }
        ] 
        return this.http.post<any>(`/forms/0/${dataIdx}`, data)
    }

    

   
    // this seems to be some sort of left over from when we rewrote notes and sign off that never got reimplemented :thinking:
    public setLatestStatus(payload: fromNotes.LatestStatus): void {
        this.store.dispatch(notesActions.SetLatestStatus({ payload }));
    }
}

