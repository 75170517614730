<form #formEle="ngForm">
    <div class="row">
        <div class="col-sm-4">
            <ul>
                <li>Reassurance</li>
                <li>Education</li>
                <li>DX / DDX</li>
                <li>Prognosis / Evidence</li>
                <li>Treatment Methods</li>
                <li>Expected outcomes</li>
                <li>Side Effects / Risks</li>
                <li>Positive Advice</li>
                <li>Keep Active</li>
                <li>Positive Mental Attitude</li>
                <li>Agreed POM</li>
                <li>Outcome Measures (PROMS)</li>
                <li>Goal Setting - Patient</li>
                <li>Objectives - Patient</li>
                <li>Self-Management</li>
                <li>Public Health</li>
            </ul>
        </div>
        <div class="col-sm-8">
            <mat-form-field appearance="fill" class="w-100">
                <mat-label>Report of Findings Narrative</mat-label>
                <textarea matInput
                    [(ngModel)]="form.report_of_findings"
                    name="report_of_findings"
                    cdkTextareaAutosize
                    cdkAutosizeMinRows="3"
                    cdkAutosizeMaxRows="18">
                </textarea>
            </mat-form-field>
        </div>
    </div>
</form>