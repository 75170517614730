<form autocomplete="off" #formEle="ngForm">   
    <p>Do you or any of your family suffer from the following?</p>
    <mat-form-field>
        <mat-label>Blood pressure / circulation problems</mat-label>
        <input 
            matInput 
            type="text" 
            name="bloodpressure"
            [(ngModel)]="form.bloodpressure">
    </mat-form-field>

    <mat-form-field>
        <mat-label>Heart / stroke problems</mat-label>
        <input 
            matInput 
            type="text" 
            name="heart"
            [(ngModel)]="form.heart">
    </mat-form-field>

    <mat-form-field>
        <mat-label>Diabetes / endocrine problems</mat-label>
        <input 
            matInput 
            type="text" 
            name="job"
            [(ngModel)]="form.diabetes">
    </mat-form-field>

    <mat-form-field>
        <mat-label>Lung / breathing problems</mat-label>
        <input 
            matInput 
            type="text" 
            name="lung"
            [(ngModel)]="form.lung">
    </mat-form-field>

    <mat-form-field>
        <mat-label>Digestion / bowel problems</mat-label>
        <input 
            matInput 
            type="text" 
            name="digestion"
            [(ngModel)]="form.digestion">
    </mat-form-field>

    <mat-form-field>
        <mat-label>Bladder / kidney problem</mat-label>
        <input 
            matInput 
            type="text" 
            name="bladder"
            [(ngModel)]="form.bladder">
    </mat-form-field>

    <mat-form-field>
        <mat-label>Reproductive problems</mat-label>
        <input 
            matInput 
            type="text" 
            name="repro"
            [(ngModel)]="form.repro">
    </mat-form-field>

    <mat-form-field>
        <mat-label>Cancer</mat-label>
        <input 
            matInput 
            type="text" 
            name="cancer"
            [(ngModel)]="form.cancer">
    </mat-form-field>

    <mat-form-field>
        <mat-label>Skin disorder</mat-label>
        <input 
            matInput 
            type="text" 
            name="skin"
            [(ngModel)]="form.skin">
    </mat-form-field>

    <mat-form-field>
        <mat-label>Migraine / headaches</mat-label>
        <input 
            matInput 
            type="text" 
            name="migrane"
            [(ngModel)]="form.migrane">
    </mat-form-field>

    <mat-form-field>
        <mat-label>Dizziness / tinnitus</mat-label>
        <input 
            matInput 
            type="text" 
            name="dizzy"
            [(ngModel)]="form.dizzy">
    </mat-form-field>

    <mat-form-field>
        <mat-label>Sleeping problems</mat-label>
        <input 
            matInput 
            type="text" 
            name="sleep"
            [(ngModel)]="form.sleep">
    </mat-form-field>

    <mat-form-field>
        <mat-label>Ears / eyes / nose / throat problems</mat-label>
        <input 
            matInput 
            type="text" 
            name="ent"
            [(ngModel)]="form.ent">
    </mat-form-field>

    <mat-form-field>
        <mat-label>Neurological (Epilepsy/MS)</mat-label>
        <input 
            matInput 
            type="text" 
            name="neuro"
            [(ngModel)]="form.neuro">
    </mat-form-field>

    <mat-form-field>
        <mat-label>Anxiety / stress / depression</mat-label>
        <input 
            matInput 
            type="text" 
            name="anxiety"
            [(ngModel)]="form.anxiety">
    </mat-form-field>

    <mat-form-field>
        <mat-label>Arthritis</mat-label>
        <input 
            matInput 
            type="text" 
            name="arthriyis"
            [(ngModel)]="form.arthriyis">
    </mat-form-field>

    <mat-form-field>
        <mat-label>Weight problems</mat-label>
        <input 
            matInput 
            type="text" 
            name="weight"
            [(ngModel)]="form.weight">
    </mat-form-field>

    <mat-form-field>
        <mat-label>Any other problems</mat-label>
        <input 
            matInput 
            type="text" 
            name="anyother"
            [(ngModel)]="form.anyother">
    </mat-form-field>

    <mat-form-field>
        <mat-label>Circulation problem - legs / arms / hands</mat-label>
        <input 
            matInput 
            type="text" 
            name="circulation_problem"
            [(ngModel)]="form.circulation_problem">
    </mat-form-field>

    <mat-form-field>
        <mat-label>Tinnitus (buzzing in ears)</mat-label>
        <input 
            matInput 
            type="text" 
            name="tinnitus"
            [(ngModel)]="form.tinnitus">
    </mat-form-field>

    <mat-form-field>
        <mat-label>Have you lived or travelled abroad in the last 6 months? (where)</mat-label>
        <input 
            matInput 
            type="text" 
            name="travelled_abroad"
            [(ngModel)]="form.travelled_abroad">
    </mat-form-field>


</form>