import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-form-dialog',
  templateUrl: './form-dialog.component.html',
  styleUrls: ['../dialog-shared.scss']
})

export class FormDialogComponent  implements OnInit {

  constructor(
      @Inject(MAT_DIALOG_DATA)
      public data: any, 
      public dialogRef: MatDialogRef<FormDialogComponent>, 
  ) { }

  ngOnInit(): void {
  }

  public onConfirm() : void {
    this.dialogRef.close(true);
  }

  public onDismiss() : void {
    this.dialogRef.close(false);
  }

  public onInput(index: number) : void {
    this.dialogRef.close({index: index, data: this.data.formFields})
  }
}
