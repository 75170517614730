import { Component } from '@angular/core';
import { CardTemplateBaseComponent } from '../card-template-base/card-template-base.component';

@Component({
  selector: 'app-card-footer',
  templateUrl: './card-footer.component.html',
  styleUrls: ['./card-footer.component.css']
})
export class CardFooterComponent extends CardTemplateBaseComponent {

}

