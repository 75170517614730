import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-generic-dialog',
  templateUrl: './generic-dialog.component.html',
  styleUrls: ['../dialog-shared.scss']
})
export class GenericDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) 
    public data: any, 
    public dialogRef: MatDialogRef<GenericDialogComponent>, 
  ) { }

  ngOnInit(): void {
  }

  public onConfirm(index: number) : void {
    this.dialogRef.close({index: index, data: true});
  }

  public onDismiss(index: number) : void {
    this.dialogRef.close({index: index, data: false});
  }

}
