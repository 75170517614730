<div class="wrapper">

<div class="container pb-0">
      <div class="header-row row">
          <img class="logo-img" [src]="logo">
          <h2 class="logo">Login</h2>
      </div>
      <div class="login-item">     
        
        <ng-container *ngIf="stage === 0">
            <form autocomplete="off" role="form" (ngSubmit)="login()" class="form form-login">
              <mat-form-field *ngIf="!companyNameStored">
                <input matInput 
                  type="text" 
                  id="companyName" 
                  name="companyName" 
                  required 
                  [(ngModel)]='companyName' 
                  placeholder="Company name">
            </mat-form-field>
    
                <mat-form-field>
                  <input matInput 
                    type="email" 
                    id="username" 
                    name="username" 
                    required 
                    [(ngModel)]='username' 
                    placeholder="Username">
              </mat-form-field>
              
              
            <mat-form-field>
                  <input matInput 
                    type="password" 
                    id="password" 
                    name="password" 
                    required 
                    [(ngModel)]='password' 
                    placeholder="Password">
            </mat-form-field>

            <mat-checkbox [checked]="keepAlive"  (change)="keepAlive = !keepAlive">Keep me signed in</mat-checkbox>

              <div class="row">
                <button mat-flat-button color="primary" type="submit" class="submit-button">Login</button>
                <button mat-stroked-button color="secondary" type="button" class="submit-button" (click)="executeSSO()">
                  <img class='ms-logo' src="/assets/images/ms-symbollockup_mssymbol_19.svg"/>
                  <span class="ms-text">Sign in with Microsoft</span>
                </button>
              </div>
            
            </form>
            <div class="row mt-5">
              <button *ngIf="companyNameStored && allowCompanyNameChange" 
                mat-flat-button 
                color="secondary" 
                type="button" 
                class="small-button text-muted" 
                (click)="changeClinic()">Change company</button>
            </div>
        </ng-container>

        <ng-container *ngIf="stage === 1">
          <form role="form" (ngSubmit)="loginWithCode()" class="form form-login">
    
            <p>{{ twoFactorNeededMessage }}. Please enter the code that you received.</p>

            <div class="code-container">        
              <input type="number" class="code-input" id="code0" name="code0" placeholder="0" (keyup)="validateKey($event, 0)" (input)="validateInput($event, 0)" maxlength="1" min="0" max="9" #code0 autofocus />
              <input type="number" class="code-input" id="code1" name="code1" placeholder="0" (keyup)="validateKey($event, 1)" (input)="validateInput($event, 1)" maxlength="1" min="0" max="9" #code1 />
              <input type="number" class="code-input" id="code2" name="code2" placeholder="0" (keyup)="validateKey($event, 2)" (input)="validateInput($event, 2)" maxlength="1" min="0" max="9" #code2 />
              <span class="code-input"> - </span>
              <input type="number" class="code-input" id="code3" name="code3" placeholder="0" (keyup)="validateKey($event, 3)" (input)="validateInput($event, 3)" maxlength="1" min="0" max="9" #code3 />
              <input type="number" class="code-input" id="code4" name="code4" placeholder="0" (keyup)="validateKey($event, 4)" (input)="validateInput($event, 4)" maxlength="1" min="0" max="9" #code4 />
              <input type="number" class="code-input" id="code5" name="code5" placeholder="0" (keyup)="validateKey($event, 5)" (input)="validateInput($event, 5)" maxlength="1" min="0" max="9" #code5 />
            </div>
          
            <div class="row mb-5 mx-1">
              <button mat-flat-button color="primary" type="submit" class="submit-button" [disabled]="!codeProvided()">Login</button>
            </div>
          </form>
          
          <div class="col text-center">
            <button *ngIf="resendTimeOut === 0; else waitMessage" aria-label="No code, try again" class="btn btn-link small-link" (click)="login()">
              No code, try again
            </button>

            <ng-template #waitMessage>
              <div class="text-center helvetica-neue small-font">
                <p>Code sent, if email please remember to check your junk or spam folder.</p>
                <p class="text-muted">Resend code available in {{ resendTimeOut }}s</p>
              </div>
            </ng-template>
          </div>

          <div class="col text-center">
            <ng-template #helpTooltipContent>We've sent you a one time code - wait for this to be emailed to you. You have
              as much time as you need to enter the code. <br><br>The 30 second countdown is to prevent asking for multiple
              codes, <b>not a time limit to login.</b></ng-template>

            <button type="button" class="btn helvetica-neue small-font dotted-text help-hover" placement="bottom"
              triggers="click hover" aria-label="Help">
              Help
            </button>
          </div>
        </ng-container>
      </div>
</div>

<div class="footer">
  <p class="text-muted">In order to function this site needs to use Cookies. By logging in you agree to this</p>
</div>
</div>




