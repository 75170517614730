<div mat-dialog-title class="dialog-title">
    <h5>{{data.title}}</h5>
    <button mat-icon-button aria-label="close dialog" class="close-button" tabindex="-1" mat-dialog-close>
        <mat-icon class="close-icon text-muted">close</mat-icon>
    </button>
</div>

<div mat-dialog-content>
    <div class="alert alert-danger" *ngIf="incompleteError">Please provide all numbers before submit.</div>

    <div class="row stars">
        <div class="col-sm-2" *ngFor="let l of numbers.split('')">
            <mat-icon>star</mat-icon>
        </div>
    </div>

    
    <div class="options">
        <div class="option" (click)="optionSelected(option)" *ngFor="let option of options">
            {{ option }}
        </div>
    </div>
</div>

<div mat-dialog-actions class="row">
    <div class="col-sm-3"></div>
    <div class="col-sm-6">
        <button mat-raised-button 
        tabindex="0"
        color="primary" 
        class="w-100"
        (click)="onConfirm()">Submit</button>
    </div>
    <div class="col-sm-3"></div>
</div>
