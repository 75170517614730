import { NotesComponent } from './notes.component';
import { SoapComponent } from './soap/soap.component';
import { AuthNeededGuard } from 'app/guards/auth.guard';
import { TreatmentCardComponent } from './treatment-card/treatment-card.component';
import { NotesBuilderComponent } from './notes-builder/notes-builder.component';
import { PatientQuestionnaireComponent } from './patient-questionnaire/patient-questionnaire.component';
import { LetterWriterComponent } from './letters/letter-writer/letter-writer.component';
import { LetterTemplateSelectComponent } from './letters/letter-template-select/letter-template-select.component';

export const NotesAppRoutes = [
  {
    path: 'notes/:section',
    component: NotesComponent,
    canActivate: [AuthNeededGuard]
  },
  {
  path: 'notes',
  component: NotesComponent,
  canActivate: [AuthNeededGuard],
  children: [
    {
      path: 'soap/:notesIdx',
      component: SoapComponent,
    },
    {
      path: 'treatmentcards/:notesIdx',
      component: TreatmentCardComponent,
    },
    {
      path: 'show/patientquestionaire/:notesIdx',
      component: PatientQuestionnaireComponent,
    },
    {
      path: 'edit/:form/:notesIdx',
      component: NotesBuilderComponent,
    },
    {
      path: 'letters/:notesIdx',
      component: LetterWriterComponent,
    },
    {
      path: 'letters/:notesIdx',
      component: LetterWriterComponent,
    },
    {
      path: 'letters/template/:notesIdx',
      component: LetterTemplateSelectComponent,
    },
    {
      path: 'letters/:notesIdx/:templateIdx',
      component: LetterWriterComponent,
    },
  ]
}];

