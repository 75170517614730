<div [id]='formName' class="observationCard">
    <form autocomplete="off" name="notes_vitalsigns"  #formEle="ngForm">
      <div class="row">
        <div class="col-sm-4">
          <mat-form-field>
            <mat-label>Height (cm)</mat-label>
            <input matInput id="height" type="text" name="height"
              [(ngModel)]="form.height" (ngModelChange)="calcBMI()"/>
          </mat-form-field>
        </div>

        <div class="col-sm-4">
          <mat-form-field>
            <mat-label>Weight (kg)</mat-label>
            <input matInput id="weight" type="text"  name="weight"
              [(ngModel)]="form.weight" (ngModelChange)="calcBMI()" />
          </mat-form-field>
        </div>

        <div class="col-sm-4">
          <mat-form-field>
            <mat-label>BMI</mat-label>
            <input matInput id="bmi" type="text" name="BMI" [disabled]="true"
              [(ngModel)]="form.bmi" />
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-sm-4">
          <mat-form-field>
            <mat-label>Temperature (&#176;C)</mat-label>
            <input matInput id="temperature" type="text"  name="temperature"
              [(ngModel)]="form.temperature"/>
          </mat-form-field>
        </div>

        <div class="col-sm-4">
          <mat-form-field>
            <mat-label>Pulse (bpm)</mat-label>
            <input matInput id="pulse" type="text"  name="pulse"
              [(ngModel)]="form.pulse" />
          </mat-form-field>
        </div>

        <div class="col-sm-4">
          <mat-form-field>
            <mat-label>Respiratory Rate (breath /min)</mat-label>
            <input matInput id="bpm" type="text"  name="bpm" [disabled]="true"
              [(ngModel)]="form.bpm" />
          </mat-form-field>
        </div>
      </div>

      <hr/>
      <table class="table">
        <thead>
          <tr>
            <th>BP</th>
            <th class="text-center">L</th>
            <th class="text-center">R</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              Supine
            </td>
            <td>
              <mat-form-field>
                <mat-label>BP Supine(L)</mat-label>
                <input matInput type="text" name="bp" [(ngModel)]="form.bp" />
              </mat-form-field>
            </td>
            <td>
              <mat-form-field>
                <mat-label>
                  BP Supine (R)
                </mat-label>
                <input matInput type="text" name="bpr" [(ngModel)]="form.bpr" />
              </mat-form-field>
            </td>
          </tr>

          <tr>
            <td>
              Seated/Standing
            </td>
            <td>
              <mat-form-field>
                <mat-label>BP (L)</mat-label> 
                <input matInput type="text" placeholder="Left" name="sbp" [(ngModel)]="form.sbp" />
              </mat-form-field>
            </td>
            <td>
              <mat-form-field>
                <mat-label>BP (R)</mat-label>
                <input matInput type="text" name="sbpr" [(ngModel)]="form.sbpr" />
              </mat-form-field>
            </td>
          </tr>
        </tbody>
      </table>



      <mat-form-field *ngFor="let field of VitalSignsFields">
        <mat-label>{{field.label}}</mat-label>
        <textarea
          matInput 
          cdkTextareaAutosize
          type="text" 
          [name]="field.field"
          [(ngModel)]="form[field.field]" ></textarea>
      </mat-form-field>

     
  

      <mat-form-field>
        <mat-label for="cap">Cardio/Abdo/Pulm Exam</mat-label>
        <textarea 
            matInput 
            cdkTextareaAutosize
            cdkAutosizeMinRows="2" 
            class='mb-3 mt-1' 
            rows="2"  
            type="text" 
            name="cap"
            [(ngModel)]="form.cap" ></textarea>
      </mat-form-field>
    
    </form>
  </div>