import { ElementRef, Injectable } from '@angular/core';
import { InsuranceDetails } from 'app/models/insurance-details.model';
import { DataService } from 'app/services/data.service';
import { DialogService } from 'app/services/dialog/dialog.service';
import { SSL_OP_SSLEAY_080_CLIENT_DH_BUG } from 'constants';
import { Observable, Subject } from 'rxjs';


export interface FastTextData{
    idx?: number,
    appliesTo?: string,
    origin: ElementRef,
    fastText: FastText[];

}
export interface FastText {
    short: string;
    long: string;
}

@Injectable({
  providedIn: 'root'
})
export class FastTextService {
  
    fastText$ = new Subject<FastTextData>
    insertString$ = new Subject<string>;
    origin : ElementRef;
    appliesTo: string;

    constructor(private http: DataService,
        private dialogService: DialogService) { }

    show(ele: ElementRef) {
        console.log("SHOW");
        this.origin = ele;
        const input = ele.nativeElement as HTMLInputElement;
        console.log("parent ", input.form.name);
        this.getFastText(input.form.name, input.name).subscribe(res => {
            console.log(res);
            this.appliesTo = `${input.form.name}.${input.name}`

            this.fastText$.next({
                idx: res.idx,
                appliesTo: this.appliesTo,
                origin: ele,
                fastText: res.Content ? res.Content.filter(t=>(t.short && t.long)) : []
            })
        });

        // this.fastText$.next({
        //     idx: 0,
        //     appliesTo: '',
        //     origin: ele,
        //     fastText: []
        // });
    }

    hide(){
        console.log("HIDE");
        this.fastText$.next(null);
    }

    insert(text: string){
//        const regex = /(\/.*)$/i;
        this.insertString$.next(text);
        const ele = this.origin.nativeElement as HTMLInputElement
        const found=ele.value.lastIndexOf("/");
        ele.value = ele.value.substring(0, found) + text;
    }

    getFastText(form: string, element: string): Observable<any> {
        return this.http.get<any>(`/fasttext/${form}/${element}`)
    }

    saveFastText(idx: number, data: any): Observable<any> {
        return this.http.post<any>(`/fasttext/${idx}`, data)
    }

    enterEditMode(data) {
        this.hide();
        data.appliesTo = this.appliesTo;

        this.dialogService.fastTextEditDialog(data)
    }

  
}
