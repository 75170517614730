import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';

import { CaseHistoryComponent } from './form-components/case-history/case-history.component';
import { SoapComponent } from './soap/soap.component';
import { NotesComponent } from './notes.component';

import { NotesAppRoutes } from './notes.routes';

import { SubmitCancelModule } from '../submit-cancel/submit-cancel.module';
import { PatientInfoBoxComponent } from './patient-info-box/patient-info-box.component';
import { InfoPatientDetailsComponent } from './info-patient-details/info-patient-details.component';
import { InfoNotesTableComponent } from './info-notes-table/info-notes-table.component';
import { DiagnosisCardComponent } from './diagnosis-card/diagnosis-card.component';


import { DynamicFormsModule } from '../dynamic-form/dynamic-forms.module';

import { MyMaterialModule } from '../material/material.module';
import { CustomFormControlsModule } from '../custom-form-controls/custom-form-controls.module';

import { NotesService } from 'app/services/notes/notes.service';
import { LoadingService } from '../services/loading.service';
import { NotesDeactivateGuard } from './notes-deactivate.guard';

import { UploaderComponent } from './uploader/uploader.component';
import { FilesService } from './uploader/files.service'
import { SoapService } from 'app/services/notes/soap.service';

import { NotesGeneralInfoComponent } from './general-info-area/notes-general-info-area.component';
import { TelephoneConsultationComponent } from './telephone-consultation/telephone-consultation.component';

import { TreatmentCardComponent } from './treatment-card/treatment-card.component';
import { PinComponent } from 'app/dialogs/pin/pin.component';

import { NotesBuilderComponent } from './notes-builder/notes-builder.component';
import { NotesBuilderFormComponent } from './notes-builder/notes-builder-form/notes-builder-form.component';
import { NotesBuilderBaseComponent } from './notes-builder/notes-builder-base/notes-builder-base-component'
import { NotesFormComponentsModule } from './form-components/form.components.module'
import { InfoAttendanceModule } from 'app/notes/info-attendance/info-attendance.module';
import { PatientQuestionnaireComponent } from 'app/notes/patient-questionnaire/patient-questionnaire.component';
import { SignatureModule } from './signature/signature.module';
import { LettersModule } from './letters/letters.module';
import { PatientCompletedFormsService } from 'app/services/notes/patient-completed-forms.service'
import { QRService } from 'app/services/qr.service';
import { FilePickerModule } from  './uploader/lib/file-picker.module';
import { PipesModule } from 'app/pipes/pipes.module';
import { FastTextModule } from 'app/directives/fast-text/fast-text.module';
@NgModule({
  imports: [
    CommonModule,
    CustomFormControlsModule,
    DynamicFormsModule,
    FormsModule,
    RouterModule.forChild(NotesAppRoutes),
    SubmitCancelModule,
    MyMaterialModule,
    NotesFormComponentsModule,
    InfoAttendanceModule,
    SignatureModule,
    LettersModule,
    FilePickerModule,
    PipesModule,
    FastTextModule
  ],
  declarations: [
    CaseHistoryComponent,
    InfoNotesTableComponent,
    InfoPatientDetailsComponent,
    NotesComponent,
    PatientInfoBoxComponent,
    SoapComponent,
    NotesGeneralInfoComponent,
    TelephoneConsultationComponent,
    UploaderComponent,
    TreatmentCardComponent,
    PinComponent,
    NotesBuilderComponent,
    NotesBuilderFormComponent,
    NotesBuilderBaseComponent,
    PatientQuestionnaireComponent,
    DiagnosisCardComponent
  ],
  providers: [
    NotesService,
    LoadingService,
    FilesService,
    SoapService,
    NotesDeactivateGuard,
    PatientCompletedFormsService,
    QRService
  ],
  exports: [
    UploaderComponent
  ]
})
export class NotesModule { }

