import { Action, createAction, props } from '@ngrx/store';

export enum ActionTypes{
    SetConfig = '[Auth Service] Set Config',
    GetConfig = '[Auth Service] Get Config'
}

export const GetConfig = createAction(ActionTypes.GetConfig);
export const SetConfig = createAction(ActionTypes.SetConfig, props<{payload: {settings: {logo: string, site: string}, site: string}}>());

