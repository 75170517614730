<div class="edit-mode-container p-2">

    <div mat-dialog-title class="dialog-title d-flex">
        <h3 class="mb-5 mt-3">Edit Mode</h3>
        <button mat-icon-button aria-label="close dialog" class="close-button" tabindex="-1" (click)="onSelectClose()" mat-dialog-close>
            <mat-icon class="close-icon text-muted">close</mat-icon>
        </button>
    </div>
    
    <div mat-dialog-content>
        <form [formGroup]="fastTextForm" (ngSubmit)="onSubmit()">
    
            <div class="row buttons-row">
                <div class="col p-0" formArrayName="fastTextList">
                    
                    <div class="row button-row" *ngFor="let fastWordCtrl of controls, index as i" [formGroupName]="i">
    
                        <div class="col-5">
    
                            <mat-form-field>
                                <mat-label>Short</mat-label>
                                <input formControlName="short" matInput placeholder="" value="fastWordCtrl">
                            </mat-form-field>
                        </div>
    
                        <div class="col-5">
                            <mat-form-field>
                                <mat-label>Long</mat-label>
                                <textarea
                                formControlName="long"  
                                style="box-sizing:content-box;"
                                matInput
                                cdkTextareaAutosize
                                cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="5"
                                placeholder=""
                                value="fastWordCtrl.key"></textarea>
                            </mat-form-field>
                        </div>
    
                        <div *ngIf="i<controls.length-1" class="col-2">
                            <div (click)="onDeleteButton(i)" mat-raised-button type="button" color="warn"
                                class="btn-fast-text"><mat-icon>delete</mat-icon></div>
                        </div>

                        <div *ngIf="i==controls.length-1" class="col-2">
                            <div mat-raised-button type="button" color="warn" class="btn-fast-text" type="button" (click)="onAddButton()"><mat-icon>add_circle</mat-icon></div>
                        </div>

                    </div>
                </div>
            </div>
        </form>
    </div>
    
    <div mat-dialog-actions>
        <button mat-raised-button color="primary" class="btn-save-fast-text" type="submit" (click)="onSubmit()">Save</button>
    </div>

</div>
