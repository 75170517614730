import { Injectable } from '@angular/core';
import { Availability } from 'app/models/diary';
import { addMinutes } from 'date-fns';
import { Observable } from 'rxjs';
import { DataService } from '../data.service';


@Injectable({
  providedIn: 'root'
})
export class AvailabilityService {

  startOfWeekInUnix;
  startTimeIndex;
  
  constructor(private http: DataService) {}

  compareNumbers(a, b) {
    return a - b;
  }

  orderArray(arrayToOrder) {
    return arrayToOrder.sort(this.compareNumbers);
  }

  stringArrayToNumber(arrayToConvert) {
    return arrayToConvert.map(time => Number(time));
  }

  getWeekStartUnixTime(date) : number{
    const currentDate = new Date(date);

    let weekDelta = currentDate.getDay()-1;

    if (weekDelta==-1){ //old MC availability sunday is day 7
      weekDelta = 6;
    }

    const firstDayOfWeek = currentDate.getDate() - weekDelta;// + 1;
    //console.log(`[working] [week start] ${firstDayOfWeek} = ${currentDate.getDate()} - ${weekDelta}`);
    
    this.startOfWeekInUnix = new Date(currentDate.setDate(firstDayOfWeek)).setHours(0, 0, 0);
    return this.startOfWeekInUnix;
  }

  convertArray(arrayToConvert) {
    let orderedArray = [];
    orderedArray = this.stringArrayToNumber(arrayToConvert);
    orderedArray = this.orderArray(orderedArray);
    return orderedArray;
  }

  private findStartAndEndTime(available: any[], increment: number): any {
    const availabilities: any[] = [];

    let index: number = 0;
    let startTime: number = available[0];
    let endTime: number = 0;

    
    while ( index < available.length ) {
      if ( available[index + 1] - available[index] <= increment ) {
        endTime = available[index + 1];
      } else {
        endTime = available[index];
        availabilities.push({ startTime, endTime });
        startTime = available[index + 1];
      }

      index++;
    }

    return availabilities;
  }

  addDayOfWeek(dateToAddTo, amount) {
    return dateToAddTo.setDate(dateToAddTo.getDate() + amount);
  }

  private convertDateToISO(time) {
    let secondsInMinute = 60;
    let milisecondsInSecond = 1000;
    let timeUnix = this.startOfWeekInUnix + (secondsInMinute * time) * milisecondsInSecond;

    return new Date(timeUnix).toISOString();
  }

  convertAvailability(availability: Availability[], date: Date = new Date(), increment: number = 15) {
  //  this.diaryWorker.sendAvailability({availability: availability, date: date, increment: increment});

    this.getWeekStartUnixTime(date);

    if (!availability) return;

    const allAvailableSlots = [];
    availability.forEach((element: any) => {
      if (element.available[0] != '') {

        const orderedArray = this.convertArray(element.available);
        const startEndArray = this.findStartAndEndTime(orderedArray, increment);
        
        startEndArray.forEach((startEnd: any) => {
          allAvailableSlots.push({
            startTime: new Date(this.convertDateToISO(startEnd.startTime)),
            endTime: addMinutes(new Date(this.convertDateToISO(startEnd.endTime)), increment),
            staffIdx: element.staffIdx
          });
        })
      }
    });

    return allAvailableSlots;
  }

  convertDateToNumber(date: Date = new Date()): number {
    const secondsInMinute = 60;
    const milisecondsInSecond = 1000;

    return Math.round((new Date(date).getTime() - this.startOfWeekInUnix) / ( secondsInMinute * milisecondsInSecond ));
  }

  public convertBackAvailability(availability: Availability[], date: Date = new Date(), increment: number = 15): any {
    this.getWeekStartUnixTime(date);

    if ( !availability || !availability.length ) return;

    const allAvailability = [];
    let currentStaffIdx = availability[0].staffIdx;
    let available = [];
    availability.forEach((element: any, index: number) => {
      const startTime = this.convertDateToNumber(element.startTime);
      const endTime = this.convertDateToNumber(element.endTime);

      if ( element.staffIdx === currentStaffIdx ) {
        for ( let i = startTime; i < endTime; i += increment ) {
          available.push(i);
        }
      }

      if ( ( element.staffIdx !== currentStaffIdx ) || ( index === availability.length - 1 && available.length ) ) {
        
        allAvailability.push({ staffIdx: currentStaffIdx, available });
        currentStaffIdx = element.staffIdx;
        available = [];

        for ( let i = startTime; i < endTime; i += increment ) {
          available.push(i);
        }
      }
    })

    return allAvailability;
  }

  /**
   * 
   * @param clinicInfo object containing the opening times for the clinic
   * @returns a list of objects with the opening and ending times as Dates, structure required to convert the opening times into calendar availabilities
   */
  public convertToDates(clinicInfo: any) {
    const daysOfWeek = ["mon", "tue", "wed", "thu", "fri", "sat", "sun"];
    const dateObjects = [];

    daysOfWeek.forEach(day => {
      const start = clinicInfo[`start_${day}`];
      const end = clinicInfo[`end_${day}`];

      if (start && end) {
        const currentDate = this.addDays(this.getCurrentWeekMonday(), daysOfWeek.indexOf(day))

        let startTime = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), ...start.split(':'));
        let endTime = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate(), ...end.split(':'));

        dateObjects.push({
          startTime,
          endTime,
        });
      }
    });
    return dateObjects
  }

  // calculates the date of the Monday of the current week 
  public getCurrentWeekMonday() {
    let date = new Date()
    let day = date.getDay() || 7;
    if (day !== 1)
      date.setHours(-24 * (day - 1));
    return date;
  }

  // adds days to a give date 
  public addDays(date: Date, days: number) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  public saveAvailability(payload: any): Observable<any> {
    return this.http.post<any>(`/diary/availability`, payload);
  }

  public saveAvailabilityTemplate(payload: any): Observable<any> {
    return this.http.post<any>(`/diary/availability/template`, payload);
  }

  public listAvailabilityTemplates(): Observable<any> {
    return this.http.get<any>(`/diary/availability/templates`);
  }

  public deleteAvailabilityTemplate(idx: number): Observable<any> {
    return this.http.delete(`/diary/availability/template/${idx}`);
  }

}

