<div mat-dialog-title class="dialog-title">
    <h5>{{data.title}}</h5>
    <button mat-icon-button aria-label="close dialog" class="close-button" tabindex="-1" mat-dialog-close>
      <mat-icon class="close-icon text-muted">close</mat-icon>
    </button>
</div>

<app-forms-list-chooser (change)="updateList($event)"></app-forms-list-chooser>

<div mat-dialog-content>
    <p [innerHtml] = "data.message"></p>
    <hr/>
    <p class="warning" *ngIf="data.warningMessage">
        {{data.warningMessage}}
    </p>
</div>

<div mat-dialog-actions>
    <button *ngFor="let text of data.confirmText; index as i"  mat-raised-button color="primary"  (click)="onConfirm(i)">{{text}}</button>
    <button *ngFor="let text of data.dismissText; index as i" mat-raised-button color="primary"  (click)="onDismiss(i)">{{text}}</button>
</div>

