<form autocomplete="off" #formEle="ngForm">   
    
    <mat-form-field>
        <mat-label>How many hours per night on average do you sleep?</mat-label>
        <input 
            matInput 
            type="text" 
            name="sleep_hours"
            [(ngModel)]="form.sleep_hours">
    </mat-form-field>

    <mat-form-field>
        <mat-label>Sleep Quality (Poor, Good, Excellent)</mat-label>
        <input 
            matInput 
            type="text" 
            name="sleep_quality"
            [(ngModel)]="form.sleep_quality">
    </mat-form-field>

    <mat-form-field>
        <mat-label>Do you unintentionally fall asleep during the day?</mat-label>
        <input 
            matInput 
            type="text" 
            name="unintentional_sleep"
            [(ngModel)]="form.unintentional_sleep">
    </mat-form-field>

    <mat-form-field>
        <mat-label>Does it take longer than 30 minutes for you to fall asleep?</mat-label>
        <input 
            matInput 
            type="text" 
            name="long_wait_before_sleep"
            [(ngModel)]="form.long_wait_before_sleep">
    </mat-form-field>

    <mat-form-field>
        <mat-label>To fall asleep at night, do you need pharmaceuticals, alcohol, or other drugs?</mat-label>
        <input 
            matInput 
            type="text" 
            name="drugs_needed_to_sleep"
            [(ngModel)]="form.drugs_needed_to_sleep">
    </mat-form-field>

    <mat-form-field>
        <mat-label>Do you wake up easily during the night?</mat-label>
        <input 
            matInput 
            type="text" 
            name="easy_wake_up_during_sleep"
            [(ngModel)]="form.easy_wake_up_during_sleep">
    </mat-form-field>

    <mat-form-field>
        <mat-label>If you wake at night, do you have difficulty going back to sleep?</mat-label>
        <input 
            matInput 
            type="text" 
            name="cant_sleep_after_awake"
            [(ngModel)]="form.cant_sleep_after_awake">
    </mat-form-field>

    <mat-form-field>
        <mat-label>Do you have pain that interferes with sleep? Please give details:</mat-label>
        <input 
            matInput 
            type="text" 
            name="pain_with_sleep"
            [(ngModel)]="form.pain_with_sleep">
    </mat-form-field>

</form>