import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DynamicFormSaveService } from 'app/dynamic-form/services/dynamic-form-save.service';
import { AuthService } from 'app/services/auth.service';
import { DataService } from 'app/services/data.service';
import { Location } from '@angular/common';
import { DialogService } from 'app/services/dialog/dialog.service';
import { PinComponent } from 'app/dialogs/pin/pin.component';
import { NotificationService } from 'app/services/notification.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {
  public profileForm: UntypedFormGroup;
  public hidePassword: boolean = true;
  public settingNewPassword: boolean = false;
  public formInvalidError: boolean = false;
  public updateSuccessful: boolean = false;
  public updateFailure: boolean = false;
  public passwordChangeError: boolean = false;
  public passwordStrengthError: boolean = false;
  public passwordUpdateSuccessful: boolean = false;
  private personId: number = 0;
  public formStructure: any = {};
  public passwordsMatch: boolean = true;
  public passwordStrongEnough: boolean = true;
  public messageDisplayLength: number = 5000;
  public userDataMissingError: boolean = false;
  public pinCode: string;
  public profileImage: string;

  constructor(private auth: AuthService, 
              private formBuilder: UntypedFormBuilder, 
              private http: DataService, 
              private dfsave: DynamicFormSaveService,
              private dialogService: DialogService,
              private notifications: NotificationService,
              private location: Location) {
    this.personId = this.auth.personId() || null;

    if ( this.personId === null ) this.userDataMissingError = true;
  }

  ngOnInit(): void {
    this.buildForm();
    this.getUserData(this.personId);
    this.profileImage = this.auth.profileImage();
  }

  private buildForm(patientData: any = []): void {
    this.profileForm = this.formBuilder.group({
      FirstName: [this.getFormValue("FirstName", patientData) || '', [ Validators.required ]],
      LastName: [this.getFormValue("LastName", patientData) || '', [ Validators.required ]],
      email: [this.getFormValue("EMail", patientData) || '', [ Validators.required, Validators.email ]],
      HomePhone: [this.getFormValue("HomePhone", patientData) || '', [ Validators.required ]],
      password: [''],
      newPassword: [''],
      newPasswordConfirm: ['']
    })
  }

  private getUserData(personId: number): void {
    this.http.get(`/forms/patientdetails/${personId}`).subscribe((formData: any) => {
      this.obtainDataStructure(personId, formData);
      this.buildForm(formData.form.questions);
    })

    this.auth.getPinCode().subscribe((pinCode: string) => {
      this.pinCode = pinCode;
    })
  }

  private obtainDataStructure(personId: number, formData: any): void {
    this.formStructure.idx = personId;
    this.formStructure.formIdx = formData.form.idx;
    this.formStructure.formName = formData.form.formName;

    let form = {};
    formData.form.questions.forEach((field: any) => {
      if ( field.fieldKey.substring(0, 6) !== "title-"  ) {
        form[field.fieldKey] = field.value;
      }
    });
    this.formStructure.form = [form];
  }

  private getFormValue(fieldKey: string, patientData: any): string {
    if ( patientData && patientData.length ) return patientData.filter((form: any) => form.fieldKey === fieldKey)[0].value;
    return null;
  }

  public handleError(controlName: string, errorName: string) {
    return this.profileForm.controls[controlName].hasError(errorName);
  }

  public doPasswordsMatch(): void {
    if ( this.profileForm.controls['newPassword'].value && this.profileForm.controls['newPasswordConfirm'].value ) {
      this.passwordsMatch = this.profileForm.controls['newPassword'].value.trim() === this.profileForm.controls['newPasswordConfirm'].value.trim();
    } else this.passwordsMatch = false;
  }

  public cancel(): void {
    this.location.back();
  }

  public setValidators(): void {
    if ( this.settingNewPassword ) {
      this.settingNewPassword = false;
      this.profileForm.controls['newPassword'].clearValidators();
      this.profileForm.controls['newPasswordConfirm'].clearValidators();
      this.profileForm.controls['password'].clearValidators();
      this.profileForm.controls['password'].updateValueAndValidity();
      this.profileForm.controls['newPassword'].updateValueAndValidity();
      this.profileForm.controls['newPasswordConfirm'].updateValueAndValidity();
    } else {
      this.settingNewPassword = true;
      this.profileForm.controls['newPassword'].setValidators([Validators.required]);
      this.profileForm.controls['newPasswordConfirm'].setValidators([Validators.required]);
      this.profileForm.controls['password'].setValidators([Validators.required]);
      this.profileForm.controls['password'].updateValueAndValidity();
      this.profileForm.controls['newPassword'].updateValueAndValidity();
      this.profileForm.controls['newPasswordConfirm'].updateValueAndValidity();
    }
  }

  private resetPasswordInputs(): void {
    this.profileForm.controls['password'].reset();
    this.profileForm.controls['newPassword'].reset();
    this.profileForm.controls['newPasswordConfirm'].reset();
  }

  public savePassword(): void {
    this.doPasswordsMatch();

    if ( this.profileForm.invalid || !this.profileForm.controls['newPassword'].value.trim() || !this.profileForm.controls['newPasswordConfirm'].value.trim() ) {
      this.formInvalidError = true;
      setTimeout(() => this.formInvalidError = false, this.messageDisplayLength);
      return;
    };

    if ( !this.passwordStrongEnough ) {
      this.passwordStrengthError = true;
      setTimeout(() => this.passwordStrengthError = false, this.messageDisplayLength);
      return;
    }

    if ( !this.passwordsMatch ) {
      this.passwordChangeError = true;
      setTimeout(() => this.passwordChangeError = false, this.messageDisplayLength);
      return;
    };

    this.http.post("/users/password", { oldPassword: this.profileForm.controls['password'].value.trim(), newPassword: this.profileForm.controls['newPassword'].value.trim() }).subscribe(( newPassword: any ) => {
      if ( newPassword.success ) {
        this.resetPasswordInputs();
        this.passwordUpdateSuccessful = true;
        setTimeout(() => this.passwordUpdateSuccessful = false, this.messageDisplayLength);
      } else {
        this.resetPasswordInputs();
        this.updateFailure = true;
        setTimeout(() => this.updateFailure = false, this.messageDisplayLength);
      }
    })
  }

  public saveUpdates(): void {
    if ( this.profileForm.invalid ) {
      this.formInvalidError = true;
      setTimeout(() => this.formInvalidError = false, this.messageDisplayLength);
      return;
    };

    const FirstName = this.profileForm.controls['FirstName'].value.trim();
    const LastName = this.profileForm.controls['LastName'].value.trim();
    const email = this.profileForm.controls['email'].value.trim();
    const phone = this.profileForm.controls['HomePhone'].value.trim();

    if ( FirstName && LastName && email && phone ) {
      this.dfsave.saveForm(this.formStructure).subscribe(( updated: any ) => {
        if ( updated.patientIdx ) {
          this.updateSuccessful = true;
          setTimeout(() => this.updateSuccessful = false, this.messageDisplayLength);
        } else {
          this.updateFailure = true;
          setTimeout(() => this.updateFailure = false, this.messageDisplayLength);
        }
      });
    }

  }

  public openPinPanel(): void {
    const modalTitle = this.pinCode ? 'Enter your old PIN code' : 'Set your PIN code';

    this.dialogService.bespokeDialog("", PinComponent, "pin", modalTitle).then((pinCode: string) => {
      if ( pinCode ) {
        if ( !this.pinCode ) {
          this.auth.updatePinCode({ pinCode }).subscribe((response) => {
            if ( response ) {
              this.notifications.send("You have successfully set your PIN code.");
              this.pinCode = response;
            }
          })
        } else {
          const previousPinCode = pinCode;

          this.dialogService.bespokeDialog("", PinComponent, "pin", 'Enter your new PIN code').then((pinCode: string) => {
            if ( pinCode ) {
              this.auth.updatePinCode({ pinCode, previousPinCode }).subscribe((response) => {
                if ( response && !response.previousError ) {
                  this.notifications.send("You have successfully updated your PIN code.");
                  this.pinCode = response;
                } else {
                  this.notifications.send(response.previousError);
                }
              })
            }
          })
        }
      }
    })
  }

}
