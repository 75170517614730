import { RoomsService } from "./../../../services/rooms.service";
import { Component, HostListener, ViewChild } from "@angular/core";
import { CardTemplateBaseComponent } from "../card-template-base/card-template-base.component";
import { DiaryService } from "../../../services/diary/diary.service";
import { Availability, CalendarSettings, SortingMode, StaffRoomsView, viewTypes, ViewTypes, DiaryFocusOn } from "../../../models/diary";
import { StaffService } from "../../../services/staff.service";
import { StaffMember } from "../../../models/staff";
import { MatSelectionList } from "@angular/material/list";
import { AvailabilityService } from "app/services/diary/availability.service";
import { TreatmentService } from "app/services/treatment.service";
import { NumberInput } from "@angular/cdk/coercion";
import { _ } from "ag-grid-community";
import { Subscription, take } from "rxjs";
import { type Treatment } from "app/models/treatments";
import { AppointmentsService } from "app/services/diary/appointments.service";
import { ActivatedRoute, NavigationEnd, Params, Router } from "@angular/router";
import { NavigationService } from "app/services/navigation.service";
import { FormGroup } from "@angular/forms";
import { startOfDecade } from "date-fns";

@Component({
  selector: 'app-card-left-sidebar',
  templateUrl: './card-left-sidebar.component.html',
  styleUrls: ['./card-left-sidebar.component.scss']
})
export class CardLeftSidebarComponent extends CardTemplateBaseComponent {
  // staffMembers: string[] = ['Andy Hanson', 'David Brookes', 'Sam Scott-Hake', 'Brooklyn P-something', 'Dom'];
  selectedOptions = [];
  selectedView: ViewTypes;
  private selectedView$;
  viewTypes = viewTypes;
  DiaryFocusOn = DiaryFocusOn;
  public allRooms$;
  public selectedStaff = [];
  private selectedRooms$: Subscription;
  public selectedRooms;
  private selectedRoomsWeek$: Subscription;
  public selectedRoomsWeek;
  public selectedIndex: number = 0;
  private currentDate$;
  private viewDate;
  // private treatments$;
  // public allTreatments;
  
 // public sortingMode: SortingMode = SortingMode.treatment;
  private savedState;
  public staffOrRooms: string;
  private navigatedBack: boolean = false;
  private staffOrRooms$: Subscription;
  public staffPanelCollapsed: boolean = true;
  public mobileView: boolean = false;

  //public focusedOn = DiaryFocusOn.NONE;
  public showOnlyWorkingStaff = false;
  private showOnlyWorkingStaff$;
  public settings: CalendarSettings;
  
  private availability: Availability[];
  private availability$;
  private staffGroupBy$;
  private selectedStaff$;
  private settings$;


  @ViewChild("staff", { static: false }) staffList: MatSelectionList;

  constructor(public diary: DiaryService,
    private staffService: StaffService,
    private roomService: RoomsService,
    private availabilityService: AvailabilityService,
    
    private appointments: AppointmentsService,
    private navigationService: NavigationService) {
    super()
  }

  @HostListener("window:resize", ["$event"])
  onResize(event) {
    this.setMobileView(event.target.innerWidth);
  }

  setMobileView(windowWidth: number): void {
    this.mobileView = windowWidth <= 1000;
  }

  imActive(event) {
    console.log(event)
  }

  ngOnInit() {
    this.availability$ = this.diary.getAvailability().subscribe(res => {
      this.availability = res;
    });

    

    this.settings$ = this.diary.getCalendarSettings().subscribe(res => {
      console.log("[events] [settings] [sidebar] updates", res)
      this.settings = res;
      //this.selectWorkingStaff();
    });

    const URLhistory: string[] = this.navigationService.getHistory();
        
    if ( URLhistory.length && URLhistory.length > 1 ) {
      this.navigatedBack = true;
    }

    this.staffOrRooms$ = this.diary.getStaffOrRoomsView().subscribe((staffOrRooms: string) => {
      this.staffOrRooms = staffOrRooms;
      this.selectedIndex = staffOrRooms === StaffRoomsView.STAFF ? 0 : 1;
    });

    this.setMobileView(window.innerWidth);

    this.selectedRooms$ = this.diary.getSelectedRooms().subscribe((selectedRooms: any) => {
      this.selectedRooms = selectedRooms;
    });

    this.selectedRoomsWeek$ = this.diary.getSelectedRoomsWeekView().subscribe((selectedRoomsWeek: any) => {
      this.selectedRoomsWeek = selectedRoomsWeek;
    });

    this.selectedStaff$ = this.diary.getSelectedStaff().subscribe(res=>{
      console.log("[selected] staff has changed", res);
      this.selectedStaffUpdates(res);
    });

  }

  ngOnDestroy() {
 
    if (this.currentDate$) {
      this.currentDate$.unsubscribe();
    }
    if (this.selectedView$) {
      this.selectedView$.unsubscribe();
    }
    if (this.showOnlyWorkingStaff$) {
      this.showOnlyWorkingStaff$.unsubscribe();
    }
    if (this.staffOrRooms$) {
      this.staffOrRooms$.unsubscribe();
    }
    if (this.selectedRooms$) {
      this.selectedRooms$.unsubscribe();
    }
    if (this.selectedRoomsWeek$) {
      this.selectedRoomsWeek$.unsubscribe();
    }
    
    if (this.selectedRoomsWeek$) {
      this.selectedRoomsWeek$.unsubscribe();
    }
    if (this.availability$){
      this.availability$.unsubscribe();
    }
    if (this.staffGroupBy$){
      this.staffGroupBy$.unsubscribe();
    }
    if (this.settings$){
      this.settings$.unsubscribe();
    }
  }

  ngAfterViewInit() {
    setTimeout(()=>{

      
      this.allRooms$ = this.roomService.getRooms();

      this.currentDate$ = this.diary.getCurrentDate().subscribe(res => { //date changes
        this.viewDate = res;
        console.log("[date] [cl]", res);
        if (this.showOnlyWorkingStaff && this.selectedView == ViewTypes.DAY) {
          //this.selectWorkingStaff();
        }
      });

      

      this.selectedView$ = this.diary.getCurrentView().subscribe(res => {
        console.log("[view] selectedView$", res);
        this.selectedView = res;
        if (this.showOnlyWorkingStaff) {
          return;
        }
        //view changes need to re-request the tick box states 
       // this.updateTickBoxesToMatch(this.diary.getCurrentSelectedStaff());
      });

      this.showOnlyWorkingStaff$ = this.diary.getShowWorking().subscribe(res => {
        console.log("[working] from state", res);
        this.showOnlyWorkingStaff = res;
        if (res) {
          //this.selectWorkingStaff();
        } else {
//          this.updateSelectedStaff(); //hmm
        }
      });

      this.diary.timeWindowChanged.subscribe(res => {
        if (this.showOnlyWorkingStaff){
          //this.selectWorkingStaff();
        }
      });

      if (this.diary.groups){
        for (let cat of this.diary.groups){
          if (!cat.staff.find(f => f.selected === true)) {
            cat.selected = false
          }
        };
      }
    });
  }


 
  selectAllStaff() {
    this.setShowWorking(false);
    this.diary.selectAllStaff();
  }

  selectAllRooms(){
    let allRooms = [];
    this.allRooms$.pipe(take(1)).subscribe(res=>{
        allRooms = [...res];
    })
    this.diary.updateSelectedRooms(allRooms);

  }

  selectAllCat(cat, v) {
    this.diary.setShowWorking(false);
    console.log("[cat] meow", cat, v);
    let selectedStaff = [];
    cat.staff.forEach(e => { 
//      selectedStaff.push(e.idx) 
        this.diary.selectStaffMember(e.idx, v);
    });
    
  }

  compareRooms(o1: any, o2: any) {
    // sorts out if it should be selected
    console.log(o1);
    console.log(o2);
    return (o1.idx == o2.idx);

  }

  tabClick(view: number) {
    console.log(`Tab Change Detected: ${view}`)
    this.diary.updateStaffOrRoomsView(view == 0 ? StaffRoomsView.STAFF : StaffRoomsView.ROOMS)
  }

  selectStaff(staff: any, checked: boolean, treatment: any) {
    console.log("[select] selectStaff");
    this.diary.setShowWorking(false);
    let selectedStaff = [];

    if (this.selectedView == ViewTypes.DAY) {
      this.diary.selectStaffMember(staff.idx, checked);
    } else {
      this.diary.updateSelectedStaff([staff]); //week view solo
      this.diary.selectTreatments(staff.idx);
    }
    if (!staff.working) {
      // uncheck WORKING
      this.showOnlyWorkingStaff = false
      this.diary.setShowWorking(false);
    }
    // Find treatment category
    let index = this.diary.groups.indexOf(this.diary.groups.find(f => f.idx === treatment.idx))
    if (this.diary.groups[index].staff.find(f => f.selected === true)) {
      this.diary.groups[index].selected = true
    } else {
      this.diary.groups[index].selected = false
    }

  }

  private updateSelectedStaff(selectedStaff: number[]) {
      this.diary.updateSelectedStaff(selectedStaff);
  }

  public isRoomSelected(roomIdx: number): boolean {
    const selectedRooms = (viewTypes.DAY === this.selectedView) ? this.selectedRooms : this.selectedRoomsWeek;
    return (selectedRooms.findIndex((room: any) => room.idx === roomIdx) !== -1);
  }

  updateSelectedRooms(checked: boolean, room: any) {
    let selectedRooms = (viewTypes.DAY === this.selectedView) ? this.selectedRooms : this.selectedRoomsWeek;
    if (!checked) selectedRooms = selectedRooms.filter((room_: any) => room_.idx !== room.idx);
    if (checked) {
      const foundRoom = selectedRooms.findIndex((room_: any) => room_.idx === room.idx) !== -1;
      if (!foundRoom) selectedRooms = [...selectedRooms, room];
    }

    if (this.selectedView == ViewTypes.DAY) {
      this.diary.updateSelectedRooms(selectedRooms);
    } else {
      selectedRooms = [room];
      this.diary.updateSelectedRoomWeekView(selectedRooms);
    }
  }

  compareWith(o1: any, o2: any) { //sorts out if it should be selected
    return (o1.idx == o2.idx)
  }

   

  setShowWorking(v) {
    this.diary.setShowWorking(v);
  }
/*
  selectWorkingStaff() {
    if (!this.showOnlyWorkingStaff || !this.diary.groups){
      return;
    }
    console.log("[selected] [events] [cl] [working] SELECTING WORKING STAFF", this.showOnlyWorkingStaff);
    if (!this.availability || !this.viewDate || this.availability.length==0) {
      //availability not loaded, just wait
      console.log("[working] not ready");
      setTimeout(() => { this.selectWorkingStaff() }, 1000);
      return;
    }

    const vdDate = this.viewDate
    // Set viewDate to beginning of day
    vdDate.setHours(0,0,0,0)
    const vdTime = vdDate.getTime()

    const dayStart: number = 
      (Math.ceil((vdTime - this.availabilityService.getWeekStartUnixTime(this.viewDate)) / (1000 * 60))) 
      + (this.settings.startHour * 60); 
    
    const dayEnd: number = dayStart + ((this.settings.endHour-this.settings.startHour) * 60);   

    let staffToSelect = [];

    for (let avail of this.availability){
      if (avail.available.find(f => (f > dayStart && f < dayEnd))) {
//          const staffMemeber = this.allStaff.find(f => f.idx == avail.staffIdx);

          for (let group of this.diary.groups){
          
              const staffMember = group.staff.find(f => f.idx == avail.staffIdx);
              if (staffMember){
                staffToSelect.push(staffMember);
              }
            }         
          
      }
    }

//    this.workingStaff = staffToSelect
    this.diary.updateSelectedStaff(staffToSelect);

    if ( this.navigatedBack ) {
      this.navigatedBack = false;
      setTimeout(() => { this.selectWorkingStaff() }, 500);
    }
  }

 */ 

  selectedStaffUpdates(staffToSelect){
    if (!this.diary.groups){
      return;
    }
    const ids = staffToSelect.map(s => s.idx);
    console.log("[selected] [ticking] ", ids)
    for (let group of this.diary.groups){
      for (let staff of group.staff){
        staff.selected = ids.includes(staff.idx);
      }
    }
  }

  focusStaff(id: number) {
    this.diary.focusOn({type: DiaryFocusOn.STAFF, id: id});
  }
  focusGroup(id: number) {
    console.log("[focus] on group ", id);
    this.diary.focusOn({type: DiaryFocusOn.GROUP, id: id});
  }

}
