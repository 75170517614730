
<div class="row margin-collapse">
    <div class="col-lg-10 mb-5">
        <mat-card><mat-card-content>  
            <div class="alert alert-danger" *ngIf="updateError"><strong>Update error:</strong> An error occurred while updating this PO. Please try again!</div>
            <div class="alert alert-success" *ngIf="updateSuccess"><strong>Update success:</strong> You have successfully updated the details of this PO.</div>
            
            <h2>Receive Purchase Order</h2>
            <br>
            <div class="mt-2">
                <table class="table">
                    <tr>
                        <th>Vendor</th>
                        <td>{{ poDetails?.accountName }}</td>
                    </tr>
                    <tr>
                        <th>Date</th>
                        <td>{{ poDetails?.dateAdded | date : 'short' :undefined: 'en-GB' }}</td>
                    </tr>
                    <tr>
                        <th>Notes</th>
                        <td>
                            <mat-form-field  class="notes" style="padding-left: 0!important" *ngIf="poDetails?.notes">
                                <mat-label>Notes</mat-label>
                                <input matInput type="textarea" name="notes" [(ngModel)]="poDetails.notes">
                            </mat-form-field>
                        </td>
                    </tr>
                    <tr>
                        <th>Grand Total </th>
                        <td>{{ total | currency: 'GBP'}}</td>
                    </tr>
                    <tr>
                        <th>Outstanding</th>
                        <td>{{ total - poDetails?.amountPaid | currency: 'GBP'}}</td>
                    </tr>
                    <tr *ngIf="this.outstanding>0">
                        <th>Payment Amount (ex VAT)</th>
                        <td><mat-form-field style="padding-left: 0!important" *ngIf="poDetails?.amountPaid">
                            <mat-label>Payment Amount</mat-label>
                            <input matInput type="text" name="paymentAmount" [(ngModel)]="paymentAmount">
                        </mat-form-field></td>
                    </tr>
                    <tr *ngIf="this.outstanding>0">
                        <th>Payment Method</th>
                        <td>
                            <div class="d-inline-flex justify-content-start flex-wrap align-items-center button-row" style="padding-left: 0!important">
                                
                                    <button *ngFor="let method of paymentMethods.actual" 
                                        class="me-3 mb-3 payment-button"
                                        [style.borderColor]="method.colour" 
                                        (click)="payBy(method)" 
                                        [ngClass]="{'highlight-payment': poDetails?.paymentMethod === method.name}"
                                        mat-stroked-button>
                                        {{method.name}}
                                    </button>
                        
                                   
                            </div>
                        </td>
                    </tr>
                </table>
            </div>

            <hr>

            <h2 class="my-3">Items</h2>

            <div class="totals">
                <table class="table top-table po-table-tr">
                    <tr>
                        <th>Item</th>
                        <th>Qty. Ordered</th>
                        <th>Qty. Outstanding</th>
                        <th>Qty. Received</th>
                        <th>Receive</th>
                        <th>Notes</th>
                    </tr>
                    <ng-container *ngIf="poDetails?.items">
                        <tr *ngFor="let item of poDetails.items, let i = index">
                            <td>{{ item.saleFreeText }}</td>
                            <td>{{ item.qty }}</td>
                            <td>{{ item.qty - item.qtyReceived - item.qtyToReceive}}</td>
                            <td>{{ item.qtyReceived + item.qtyToReceive }}</td>
                            <td>
                                <mat-form-field>
                                    <mat-label>Quantity</mat-label>
                                    <input matInput type="number" min="0" [(ngModel)]="item.qtyToReceive" name="quantity">
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field class="notes">
                                    <mat-label>Notes</mat-label>
                                    <input matInput type="textarea" name="notes" [(ngModel)]="item.notes">
                                </mat-form-field>
                            </td>
                        </tr>
                    </ng-container>
                </table>
            </div>
    
            <div>
            </div>
        </mat-card-content></mat-card>
    </div>
    
    <div class="col-sm-2 mobile-till-margin">
        <div class="fix-top">
            <ul class="list-sections">
                <li>
                   
                </li>
            </ul>
            <div class="desktop-buttons mobile-buttons">
                <div class="my-3 text-center mobile-button">
                    <button mat-raised-button 
                    [disabled] = "received"
                    (click)="receiveAll()"
                    color="secondary" 
                    class="side-button w-100" 
                    >Receive All</button>
                </div>
                <div class="my-3 text-center mobile-button">
                    <button mat-raised-button 
                    (click)="submit()"
                    color="primary" 
                    class="side-button w-100" 
                    >Submit</button>
                </div>
                <div class="my-3 text-center mobile-button">
                    <button mat-raised-button 
                    class="side-button w-100" 
                    (click)="back()"
                    >Back</button>
                </div>
            </div>
        </div>
    </div>

    
