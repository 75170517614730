import { Component, OnInit } from '@angular/core';
import { FormTemplateBaseComponent } from '../form-template-base/form-template-base.component';

@Component({
  selector: 'app-form-checkbox',
  templateUrl: './form-checkbox.component.html',
  styleUrls: ['./form-checkbox.component.css']
})
export class FormCheckboxComponent extends FormTemplateBaseComponent{
  ngOnInit(){
    console.log("[check]", this.question);
  }

}
