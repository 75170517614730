import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { SignatureTypes } from 'app/models/signatures';
import { AuthService } from 'app/services/auth.service';

@Component({
  selector: 'app-lock',
  templateUrl: './lock.component.html',
  styleUrls: ['./lock.component.scss']
})
export class LockComponent implements OnInit {
//  @Input("notesIdx") notesIdx: number;
//  @Input("onlyHistory") onlyHistory: boolean = false;
  @Input("signatureIdx") signatureIdx: number;
  @Input("onlyButtons") onlyButtons: boolean = false;
  @Input("signatureTitle") signatureTitle: string;
  @Input("locked") locked: boolean = false;
  @Input("changeRequested") changeRequested: boolean = false;
  @Input("userSupervisor") userSupervisor: boolean = false;
  @Input("statusChanged") statusChanged = 0;
  @Input("lockable") lockable: boolean;
  
  @Input("signatures") signatures;
  @Input("recordIdx") recordIdx;

  @Output() onLock: EventEmitter<boolean> = new EventEmitter();

  public onlyHistory = false;
  public locks: any[] = [];
  public expanded: boolean = false;
  public SignatureTypes = SignatureTypes;
  public myUserId: number;

  constructor(private auth: AuthService) { }

  ngOnChanges(changes: SimpleChanges): void {
    this.userSupervisor = this.signatures.userSupervisor; 
    if (this.signatures.signatures[0]){
      console.log("[sigs]", this.myUserId, this.signatures);
      this.locks = this.signatures.signatures.filter(sign => sign.recordIdx == this.recordIdx)
      this.locked = this.locks[0].locked; 
      this.createFriendlyNames()
    }
  }

  ngOnInit(): void {
    this.myUserId = this.auth.userId();
  }

  createFriendlyNames(){
    for (let lock of this.locks){
      lock.friendly = this.getFriendlyTitle(lock);
    }
  }
  
  getFriendlyTitle(lock) : string{
    switch (lock.signatureTitle){
      case SignatureTypes.RFI:
        return "A request for more information was made";
      case SignatureTypes.SignOff:
        return "The form was signed off"
      case SignatureTypes.studentChangeRequest:
        return "A change was requested"
      case SignatureTypes.lock:
        if (lock.locked){
          return "The form was locked";
        }
          return "The form was unlocked";
    }
    return `The form status was changed ${lock.signatureTitle}`;

  }
  

  public lock(locked: boolean): void {
    this.onLock.emit(locked);
  }

}