import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'values'})
export class ValuesPipe implements PipeTransform {
    transform(obj: any): any[] {
        if (!obj) return [];
        let out = Object.keys(obj).map((k) => obj[k])
        console.log(out);
        return (out);
    }
}