import { Component, OnInit } from '@angular/core';
import { NotesBuilderBaseComponent } from '../../notes-builder/notes-builder-base/notes-builder-base-component';


@Component({
  selector: 'app-drawing-cervical',
  templateUrl: './drawing-cervical.component.html',
  styleUrls: ['./drawing-cervical.component.scss']
})
export class DrawingCervicalComponent extends NotesBuilderBaseComponent{

}
