// Boring Angular Modules //
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";

import { MyMaterialModule } from "app/material/material.module";
import { DynamicFormsModule } from "app/dynamic-form/dynamic-forms.module";
import { PipesModule } from "app/pipes/pipes.module";

import { ReflexesFieldComponent } from "./reflexes-field/reflexes-field.component";
import { ReflexesFieldSpecialComponent } from "./reflexes-field-special/reflexes-field-special.component";
import { MuscleFieldComponent } from "./muscle-field/muscle-field.component";
import { RangeOfMotionComponent} from "./range-of-motion/range-of-motion.component";

const CustomComponents =  [
    ReflexesFieldComponent,
    MuscleFieldComponent,
    ReflexesFieldSpecialComponent,
    RangeOfMotionComponent
]


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MyMaterialModule,
    DynamicFormsModule,
    PipesModule
  ],
  declarations: CustomComponents,
  exports: CustomComponents,
})
export class NotesCustomFormControlsModule {}
