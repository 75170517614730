<div [formGroup]="form" class="form-group full-width">
  <mat-form-field style="width:100%"
  [ngStyle]="{'background-color': question.type === 'colour' ? question.value : 'inherit'}">
    <mat-label>{{question.label}}</mat-label>
    <div class="d-flex flex-row">
    <input matInput
      [maxLength]="question.maxLength"
      #inputValue
      autocomplete="off" 
      aria-autocomplete="none"
      [id]="question.key"
      [type]="question.type"
      [formControlName]="question.key"
      (keyup)="handleChange(question, inputValue)" />
      <a href="tel:{{question.value}}" *ngIf="question.type==fieldType.PHONE">
        <mat-icon style="color:#1a5e20">phone</mat-icon>
      </a>
      <a href="mailto:{{question.value}}" *ngIf="question.type==fieldType.EMAIL" >
        <mat-icon style="color:#1a5e20">email</mat-icon>
      </a>
      <div matSuffix *ngIf="question.options=='awsVerify'">
        <button mat-icon-button
        (click)="verifyEmail()"
        title="AWS verify for sending">
          <mat-icon>cloud_circle</mat-icon>
        </button>
      </div>
      <span *ngIf="question.key == 'DOB' && question.value">Age:&nbsp;{{getAge(question.value)}}</span>
    </div>
    <mat-error *ngIf="fieldInvalid() && showInvalidField">{{fieldInvalidError()}}</mat-error>
  </mat-form-field>
</div>
