export class QuestionBase<T>{
    value: T;
    idx: number;
    key: string;
    label: string;
    required: boolean;
    orderNo: number;
    controlType: string;
    type: string;
    options: { key: string, value: string }[] = [];
    active: boolean;
    validators: string;
    width: number;
    maxLength: number;
    multichoice: number;
    meta: any;

    constructor(options: {
        value?: T,
        idx?: number,
        key?: string,
        label?: string,
        required?: boolean,
        orderNo?: number,
        controlType?: string,
        active?: string,
        validators?: string,
        type?: string,
        width?: number,
        options?: any,
        maxLength?: number,
        meta?: any,
        multichoice?: number,


    } = {}) {
        this.idx = +options.idx || 0
        this.value = options.value;
        this.key = options.key || '';
        this.label = options.label || '';
        this.required = !!options.required;
        this.orderNo = +options.orderNo === undefined ? 1 : options.orderNo;
        this.controlType = options.controlType || '';
        this.options = options['options'] || [];
        this.active = options['active'] === undefined ? true : options['active'] == 'true';
        this.validators = options.validators || "";
        this.type = options.type || "";
        this.width = options.width || 12;
        this.maxLength = options.maxLength || 999999;
        this.meta = options.meta || null;
        this.multichoice = options.multichoice || 0;

    }
    /*
    fieldName(){
      return "dffield_"+this.idx;
    }
    */
}


export const fieldType = {
    TITLEAREA: 'titlearea',
    SUBTITLEAREA: 'subtitlearea',
    TEXTBOX: 'textbox',
    PASSWORD: 'password',
    TEXTAREA: 'textarea',
    CHECKBOX: 'checkbox',
    INFOBOX: 'infobox',
    DROPDOWN: 'dropdown',
    DROPDOWNMULTI: 'dropdownmulti',
    DROPDOWNMULTINESTED: 'dropdownmultinested',
    FILEUPLOAD: 'fileupload',
    CHOOSER: 'chooser',
    SLIDER: 'slider',
    HIDDEN: 'hidden',
    EMAIL: 'email',
    PHONE: 'phone',
    AUTOSELECT: 'autoselect'

}

/* all the fieldtypes available for building forms */
export const fieldClasses = {
    TITLEAREA: 'TitleArea',
    SUBTITLEAREA: 'SubTitleArea',
    TEXTBOX: 'TextboxQuestion',
    PASSWORD: 'Password',
    TEXTAREA: 'TextArea',
    CHECKBOX: 'Checkbox',
    CHECKTEXT: 'Checktext',
    INFOBOX: 'InfoBox',
    DROPDOWN: 'DropdownQuestion',
    DROPDOWN_MULTISELECT: 'DropdownMultiselect',
    FILEUPLOAD: 'FileUploader',
    GPCHOOSER: 'GPchooser',
    VENDORCHOOSER: 'Vendorchooser',
    PATIENTCHOOSER: 'Patientchooser',
    ADDRESSCHOOSER: 'AddressChooser',
    AUTOSELECT: 'AutoSelect',
    COLOUR: 'Colour',
    SLIDER: 'Slider',
    HIDDEN: 'Hidden',
    DROPDOWN_NESTED_MULTISELECT: 'DropdownNestedMultiselect'

};
