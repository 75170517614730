import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SubMainMenuService } from './sub-main-menu.service';

@Component({
  selector: 'app-sub-main-menu',
  templateUrl: './sub-main-menu.component.html',
  styleUrls: ['./sub-main-menu.component.scss']
})
export class SubMainMenuComponent implements OnInit {

  public routes = [];

  constructor(private router: Router, private subMainMenuService: SubMainMenuService, private route: ActivatedRoute) { }

  ngOnInit() {
    this.route.params.subscribe((res) => {
      console.log(res);
      this.routes = this.subMainMenuService.getRoutes(res.id);
    });
  }

  goTo(route: string) {
    this.router.navigateByUrl(route);
  }

  goBack() {
    window.history.back();
  }

}
