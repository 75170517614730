import { Injectable } from '@angular/core';
import {
    Actions,
    ofType,
    createEffect
} from '@ngrx/effects';

import { mergeMap, map } from 'rxjs/operators';
import * as roomsActions from '../actions/rooms';
import { DataService } from '../../services/data.service';
import { Room } from '../../models/rooms';

@Injectable()
export class RoomsEffects {
    constructor(private http: DataService, private actions$: Actions, ) { }

    initRooms$ =  createEffect(() => {
        return this.actions$
        .pipe(
            ofType(roomsActions.ActionTypes.INIT),
            mergeMap(() => this.http.get<Room[]>('/rooms')
                .pipe(
                    map(rooms => (new roomsActions.SetAction(rooms)))
                ))
        );
    });
}

