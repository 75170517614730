import { Injectable } from '@angular/core';
import * as fromDiary from "../../models/diary";
import { AuthService } from '../auth.service';
import { AvailablePermissions } from 'app/models/auth';

@Injectable({
  providedIn: 'root'
})
export class AppointmentsActionsService{
    
    private availableActions: fromDiary.AvailableActions[] = fromDiary.AVAILABLEACTIONS;
    private filteredActions;

    constructor( private auth: AuthService){
    }

    private filterActions(){
        const canEditAppointment = !this.auth.permission(AvailablePermissions.diary_read_only);
        const hasTill = this.auth.hasMenuAccess('/pay');
        const hasNotes = this.auth.hasMenuAccess('/notes');
        this.filteredActions = this.availableActions;
        
        for (let action of this.filteredActions){

            if (!hasTill){
                action.actions = action.actions.filter(f =>  
                    f != fromDiary.DiaryActions.TAKE_PAYMENT && 
                    f != fromDiary.DiaryActions.SHOW_INVOICE
                );

                action.statusActions = action.statusActions.filter(f =>  
                    f != fromDiary.DiaryActions.TAKE_PAYMENT && 
                    f != fromDiary.DiaryActions.SHOW_INVOICE
                );

            }

            if (!hasNotes){
                action.actions = action.actions.filter(f =>  
                    f != fromDiary.DiaryActions.SHOW_CASE_HISTORY &&
                    f != fromDiary.DiaryActions.SHOW_PHYSICAL_EXAMS &&
                    f != fromDiary.DiaryActions.SHOW_SOAP
                );

                action.statusActions = action.statusActions.filter(f =>  
                    f != fromDiary.DiaryActions.SHOW_CASE_HISTORY &&
                    f != fromDiary.DiaryActions.SHOW_PHYSICAL_EXAMS &&
                    f != fromDiary.DiaryActions.SHOW_SOAP
                );
            }

            if ( !canEditAppointment ) {
                action.actions = action.actions.filter((diaryAction: string) => fromDiary.readOnlyDiaryActions.includes(diaryAction));
                action.statusActions = action.statusActions.filter((diaryActionStatus: string) => fromDiary.readOnlyDiaryActions.includes(diaryActionStatus));
            }

        }
    }

    getFilteredActions(){
        if (!this.filteredActions){
            this.filterActions();
        }

        return this.filteredActions;
    }



}
