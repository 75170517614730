import { Injectable } from '@angular/core';
import { DataService } from 'app/services/data.service';
import { Observable } from 'rxjs';

export interface Template {
  idx: number;
  title: string;
  subject: string;
  friendlyTitle?: string;
  body?: string;
  lastUpdated?: Date;
}

@Injectable({
  providedIn: 'root'
})
export class EmailTemplatesService {

  constructor(private http: DataService) { }

  listTemplates() : Observable<Template[]> {
    return this.http.get("/email/templates/list");
  }

  getTemplate(name: string) : Observable<Template>{
    return this.http.get(`/email/templates/${name}`);
  }

  saveTemplate(idx: number, payload: Template) : Observable<any> {
    const data = {
      data: {
        title: payload.title,
        subject: payload.subject,
        body: payload.body,
        friendlyTitle: payload.friendlyTitle
      }
    };
    return this.http.post(`/email/templates/${idx}`, data)
  }
}
