<form autocomplete="off" #formEle="ngForm">   
    <mat-form-field>
        <mat-label>Occupation</mat-label>
        <input 
            matInput 
            type="text" 
            name="job"
            [(ngModel)]="form.job">
    </mat-form-field>

    <mat-form-field>
        <mat-label>Employer</mat-label>
        <input 
            matInput 
            type="text" 
            name="employer"
            [(ngModel)]="form.employer">
    </mat-form-field>

    <mat-form-field>
        <mat-label>Time (years) at current job</mat-label>
        <input 
            matInput 
            type="text" 
            name="time"
            [(ngModel)]="form.time">
    </mat-form-field>

    <mat-form-field>
        <mat-label>Previous Occupations</mat-label>
        <input 
            matInput 
            type="text" 
            name="previous"
            [(ngModel)]="form.previous">
    </mat-form-field>

</form>