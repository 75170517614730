export const SignatureTypes = {
    SignOff: "SingleSupervising",
    RFI: "RFI",
    lock: "LockStatus",
    studentChangeRequest: "StudentChangeRequest",
    comment: "Comment"
}

/*
    {
      name: "Permission To Treat Supervising Tutor Sign Off",
      title: "PermissionToTreatSupervising",
      index: -1,
      comment: null,
      required: true
    },
    {
      name: "Case Learning Cycle Supervising Tutor Sign Off",
      title: "CaseLearningCycleSupervising",
      index: -1,
      comment: null,
      required: true
    },
    {
      name: "Report of Findings Appointment Supervising Tutor Sign off",
      title: "ReportofFindingsAppointmentSupervising",
      index: -1,
      comment: null,
      required: true
    },
    {
      name: "Tutor Sign-off",
      title: "SingleSupervising",
      index: -1,
      comment: null,
      required: true
    }
  ]
  */


  
export interface Signature{
  idx: number,
  userIdx: number,
  comment: string,
  signatureTitle: string,
  recordIdx: number,
  locked: boolean,
  change_requested: boolean,
  DateAdded: string,
  userName: string
}
export interface Signatures{
  notesIdx: number,
  signatures: Signature[],
  userHasPinCode: boolean,
  userGroupName: string,
  userSupervisor: boolean
}