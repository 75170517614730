import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FastTextService } from 'app/directives/fast-text/fast-text.service';
import { DialogService } from 'app/services/dialog/dialog.service';

@Component({
    selector: 'app-fasttext-edit-dialog',
    templateUrl: './fasttext-edit-dialog.component.html',
    styleUrls: ['../dialog-shared.scss']
})
export class FasttextEditDialogComponent implements OnInit, OnDestroy {
    fastTextForm: FormGroup

    constructor(
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<FasttextEditDialogComponent>,
        private dialogService: DialogService,
        private fastTextService: FastTextService
    ) { 
    }

    ngOnInit(): void {
        this.initForm()
    }

    onSelectClose() {
        this.dialogRef.close()
    }

    onSubmit() {
        const payload = {
            appliesTo: this.data.appliesTo,
            data: this.fastTextForm.value.fastTextList,
        }
        this.fastTextService.saveFastText(this.data.idx ? this.data.idx : 0, payload).subscribe(res => {
            console.log(res);
        })
        this.dialogRef.close()
    }

    get controls() {
        return (<FormArray>this.fastTextForm.get('fastTextList')).controls;
    }

    private initForm() {
        let fastTextList: any = new FormArray([])

        for (let line of this.data.fastText) {
            fastTextList.push(
                new FormGroup({
                    'short': new FormControl(line.short),
                    'long': new FormControl(line.long)
                })
            )
        }
        this.fastTextForm = new FormGroup({
            'fastTextList': fastTextList
        })
        if(this.data.fastText.length == 0) this.onAddButton()
    }

    onAddButton() {
        (<FormArray>this.fastTextForm.get('fastTextList')).push(new FormGroup({
            'short': new FormControl(),
            'long': new FormControl()
        }))
    }

    onDeleteButton(index: number) {
        (<FormArray>this.fastTextForm.get('fastTextList')).removeAt(index)
    }

    ngOnDestroy(): void {
    }
}
