<div class="row margin-collapse">
  <div class="col-lg-10">
    <div *ngIf='form'>
      <h4 class="fg-a" *ngIf="formTitle!=''">{{formTitle}} xxx</h4> 
      <div [innerHtml]="notes"></div>
      <form autocomplete="off" [formGroup]="form" class="form-horizontal">
        <div class="saveForm" [ngClass]="{'saveFormShown' : submitting}"></div>
        <div formArrayName="sections">
          <mat-card *ngFor="let section of form.get('sections')['controls']; let i = index">
            <mat-card-content>
            <div class="font-monospace float-end">#{{searchOn}}</div>
            <mat-grid-list style="height: 100%" cols="12" rowHeight="2em">
              <div *ngFor="let question of questions; let i = index">
                <mat-grid-tile [colspan]="colWidth(question.width === 'Checktext' ? question : question?.controlType)"
                  [rowspan]="rowHeight(question)">
                  <df-question 
                      [question]="question" 
                      [form]="section" 
                      [patientIdx]="searchOn"
                      (openFormDialog)="openFormDialog($event)"></df-question>
                </mat-grid-tile>
              </div>
            </mat-grid-list>
          </mat-card-content></mat-card>
        </div>
      </form>
    </div>
    </div>

    <div class="col-sm-2 mobile-margin">
      <div class="fix-top">
        <ul class="list-sections">
          <li *ngFor="let section of formSections">
            <button class="btn btn-link" (click)="scroll('section-'+section.idx)">
              {{ section.title }}
            </button>
          </li>
        </ul>

        <div *ngIf="formName && formName === 'staff'" class="my-3 text-center">
          <button mat-raised-button color="primary" class="mobile-button desktop-button" (click)="SetAvailability()">Set
            Availability</button>
        </div>


        <div class="desktop-buttons">

          <app-submit-cancel [saving]="saving" (onSave)="save()" (onCancel)="cancel()" *ngIf="!multiple">
          </app-submit-cancel>
        </div>
      </div>
    </div>


    <!--
  <div class="col-sm-1"></div>
    <div class="col-sm-3">
      <div class="fix-top">
          
      </div>
    </div>    
  -->
  </div>