import { Component } from '@angular/core';
import { CardTemplateBaseComponent } from '../card-template-base/card-template-base.component';

@Component({
  selector: 'app-card-diary-header',
  templateUrl: './card-diary-header.component.html',
  styleUrls: ['./card-diary-header.component.css']
})
export class CardDiaryHeaderComponent extends CardTemplateBaseComponent  {
 date = Date.now();
 
}
