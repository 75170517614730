import { Action } from '@ngrx/store';
import { ReportFilter, State } from '../../models/user-settings';
import { type } from '../util';
/*
export const SET = 'SETTINGS_SET';
export const SET_DIARY = 'SETTINGS_SET_DIARY';
export const ADD_MENU = 'SETTINGS_ADD_MENU';
export const INIT = 'SETTINGS_INIT';
*/


export const ActionTypes = {
    SET: type('[UserSettingsState] Set'),
    SET_DIARY: type('[UserSettingsState] Set Diary'),
    SET_REPORT_FILTERS: type('[UserSettingsState] Set Report Filters'),
  //  DO_SET_REPORT_FILTERS: type('[UserSettingsState] Do Set Report Filters'),
    SET_MOBILE_VIEW: type('[UserSettingsState] Set Mobile View'),
    INIT: type('[UserSettingsState] Initalise settings'),
    SET_DARK_MODE: type('[UserSettingsState] Set Dark Mode')
};


/**
 * Every action is comprised of at least a type and an optional
 * payload. Expressing actions as classes enables powerful 
 * type checking in reducer functions.
 * 
 * See Discriminated Unions: https://www.typescriptlang.org/docs/handbook/advanced-types.html#discriminated-unions
 */

export class InitAction implements Action {
    type = ActionTypes.INIT;
    constructor() { }
}


export class SetAction implements Action {
    type = ActionTypes.SET;
    constructor(public payload: State) { }
}

export class SetMobileView implements Action {
    type = ActionTypes.SET_MOBILE_VIEW;
    constructor(public payload: boolean) { }
}

export class SetReportFilters implements Action {
    type = ActionTypes.SET_REPORT_FILTERS;
    constructor(public payload: ReportFilter) { }
}

export class SetDarkMode implements Action {
    type = ActionTypes.SET_DARK_MODE;
    constructor(public payload: boolean) {}
}
    

/*
export class DoSetReportFilters implements Action {
    type = ActionTypes.DO_SET_REPORT_FILTERS;
    constructor(public payload: ReportFilter) { }
}
*/


/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */

export type Actions
    = SetAction |   
    InitAction 