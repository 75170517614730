import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { take } from 'rxjs/operators';
import { AuthService } from '../services/auth.service';
import * as fromRoot from '../store/reducers'

@Injectable()
export class HasMenuAccessGuard  {

  constructor(private router: Router, 
    private auth: AuthService,
    private store: Store<fromRoot.State>) { }

  canActivate(route: ActivatedRouteSnapshot) {
    console.log("[guard] permissions guard checks you");

    const url = `/${route.url[0].path}`;
    return this.auth.hasMenuAccess(url);
  }
}
