import {
    Component,
    ViewChild,
    AfterViewInit,
    HostListener,
    OnDestroy,
    Directive,
    OnInit,
    SimpleChanges,
    Input
} from "@angular/core";

import { CardTemplateBaseComponent } from "../card-template-base/card-template-base.component";

import {
    CalendarDateFormatter,
    CalendarEvent,
    CalendarEventTimesChangedEvent,
    CalendarEventTitleFormatter,
    CalendarWeekViewBeforeRenderEvent
} from "angular-calendar";

import { DiaryService } from "../../../services/diary/diary.service";
import { AppointmentsService } from "../../../services/diary/appointments.service";
import {
    AppointmentStatus,
    Availability,
    CalendarSettings,
    DiaryColumn,
    StaffRoomsView,
    viewTypes,
    ViewTypes
} from "../../../models/diary";
import { ZoomService } from "../../../services/diary/zoom.service";
import { Router } from "@angular/router";
import { debounceTime, distinctUntilChanged, map, sampleTime, take } from 'rxjs/operators';
import { MatDialog } from '@angular/material/dialog';
import * as fromRoot from '../../../store/reducers';

import { PatientChooserService } from "../../../services/patient.service";
import { BehaviorSubject, fromEvent, Observable, Subject, Subscription } from "rxjs";
import { CustomDateFormatter } from './custom-date-formatter/custom-date-formatter.provider';
import { DatePipe } from "@angular/common";
import { NotificationService } from "app/services/notification.service";
import { Store } from "@ngrx/store";
import { MatMenuTrigger } from "@angular/material/menu";
import { DialogResult, DialogService } from "app/services/dialog/dialog.service";
import { WeekViewHourColumn } from "calendar-utils";
import * as fromDiary from "../../../store/actions/diary";
import { type StaffMember } from "app/models/staff";
import { AuthService } from "app/services/auth.service";
import { ChangeDetectionStrategy } from "@angular/core";
import { PatientAppointmentHistoryService } from "app/services/patient-appointment-history.service";
import { AvailablePermissions } from "app/models/auth";
import { ClinicService } from "app/services/clinic.service";
import { AvailabilityService } from "app/services/diary/availability.service";

// import { CustomEventTitleFormatter } from './custom-event-title-formatter/custom-event-title-formatter.provider';
interface CalendarWeekViewBeforeRenderEventWithUsers extends CalendarWeekViewBeforeRenderEvent {
    users: DiaryColumn[];
}

const CONTROL_WIDTH = 150;

@Component({
    selector: "app-card-diary",
    // changeDetection: ChangeDetectionStrategy.OnPush, //this improves performance.. but what did it break?
    templateUrl: "./card-diary.component.html",
    styleUrls: ["./card-diary.component.scss"],
    //   encapsulation: ViewEncapsulation.None, // this causes issues as styles from here can go anyehere
    providers: [
        {
            provide: CalendarDateFormatter,
            useClass: CustomDateFormatter,
        },
        /*        {
                    provide: CalendarEventTitleFormatter,
                    useClass: CustomEventTitleFormatter,
                } */
    ]
})
export class CardDiaryComponent extends CardTemplateBaseComponent implements OnDestroy, AfterViewInit {

    @ViewChild(MatMenuTrigger) diaryMenu: MatMenuTrigger;

    public viewTypes = viewTypes;
    public AppointmentStatus = AppointmentStatus;
    public currentView: ViewTypes;
    viewDate = new Date();
    segment;
    segmentHeight: number;
    innerWidth: number;
    excludeDays: number[] = [0, 6];
    private diaryEvents$;
    public diaryEvents: any;
    public selectedEvent;
    public controlsPos = { left: 0, top: 0 };
    private contextHideTimer$;
    private pipe = new DatePipe("en-GB");
    private currentAppointments: any;
    public staffOrRooms: string;
    public newRoom: any;
    public newUser: any;
    public refresh: Subject<any> = new Subject();
    public selectedStaff = [];
    hourColumns: WeekViewHourColumn[];
    public showCancelled: boolean;
    public settings: CalendarSettings;
    public workingButtonClicked = false;
    public diaryEventsForRoomView: any[] = [];
    private selectedRooms: any;
    private subscriptions$: Subscription[] = [];
    private availabilites: Availability[];
    public onMobile:boolean
    private beforeWeekViewRender$: any;
    public roomAvailabilities: any;
    public StaffRoomsView = StaffRoomsView;
    
    @HostListener("window:resize", ["$event"])
    onResize(event) {
        this.calculateResize();

    }
    constructor(
        public diary: DiaryService,
        public appointments: AppointmentsService,
        public zoomService: ZoomService,
        private router: Router,
        private patientChooser: PatientChooserService,
        public dialog: MatDialog,
        private notifications: NotificationService,
        private store: Store<fromRoot.State>,
        private dialogService: DialogService,
        private auth: AuthService,
        public appHistoryService: PatientAppointmentHistoryService,
        private clinicService: ClinicService,
        private availabilityService: AvailabilityService
    ) {
        super();

        // this.currentView = viewTypes.DAY;
        this.subscriptions$.push(this.diary.getCurrentView()
            .subscribe(res => {
                this.currentView = res;
            }));

        this.subscriptions$.push(this.diary.getStaffOrRoomsView().subscribe((staffOrRooms: string) => {
            this.staffOrRooms = staffOrRooms;
        }));

        this.subscriptions$.push(this.diary.getSelectedRooms().subscribe((rooms: any) => {
            this.selectedRooms = rooms.map((room: any) => { return { id: room.idx, title: room.name }})
            console.log("[rooms] updates", this.selectedRooms, );
          }));
     
     /*     this.subscriptions$.push(this.diary.getSelectedRoomsWeekView().subscribe((selectedRooms: any) => {
            console.log(`[rooms] updates `, selectedRooms);
            this.selectedRooms = selectedRooms;
        }));
*/
        this.subscriptions$.push(this.store.select(fromRoot.getShowCancelled).subscribe(res => {
            console.log("[cancelled] event", res);
            this.showCancelled = res;
        }));

        this.subscriptions$.push(this.diary.getSelectedStaff().pipe(
            map(res => {
                const mapped = res.map(s => {
                    return { id: s.idx, title: `${s.firstname} ${s.lastname}`, order: s.order };
                });
                return mapped;
            })).subscribe(res => {
                this.selectedStaff = res;
            }));

        // fetches the clinic opening times and converts them into calendar availabilities for room view     
        this.clinicService.getSelectedClinic().subscribe(res => {
            console.log(res);
            this.roomAvailabilities = this.availabilityService.convertBackAvailability(this.availabilityService.convertToDates(res));
            console.log(this.roomAvailabilities);
        })    
    }


    private closeDiaryMenu(){
        this.diaryMenu.closeMenu();
    }

    ngAfterViewInit() {
        this.calculateResize();


        setTimeout(() => {
            fromEvent(document.body, 'click').subscribe((e: MouseEvent) => {
                if (this.diaryMenu && this.diaryMenu.menuOpen) { this.closeDiaryMenu(); }
            });
        }, 500);
    }

    ngOnDestroy() {
        for (const s of this.subscriptions$) {
            s.unsubscribe();
        }
    }

    calculateResize() { // NO what if its days!
        this.innerWidth = window.innerWidth;
          
        if (this.currentView == viewTypes.WEEK) {
            this.zoomService.width = 7;
            return;
        }
        this.diary.getStaffOrRoomsView().subscribe((res) => {
            if (res === StaffRoomsView.ROOMS) {
                this.zoomService.width = this.diary.getRoomArrayLength();
            } else {
                this.zoomService.width = this.diary.getPractitionerArrayLength();
            }
        });
        if(this.innerWidth>=1000){
            this.onMobile=false
        }else{
            this.onMobile=true
        }
    }

    reassignUsersToEvents(users: any, res: any) {
        
        this.diaryEvents = res.map((event: any) => {
            let foundUserIndex = 0;
            const foundUser = users.find((user: any, index: number) => {
                foundUserIndex = index;
                return (user.id === event.meta.user.idx);
            });

            console.log("[events$]", foundUser);

            return {
                ...event,
                meta: {
                    ...event.meta,
                    user: {
                        ...event.meta.user,
                        ...foundUser
                    }
                }
            };
        });
    }

    ngOnInit() {
        this.subscriptions$.push(this.appointments.observeApps.subscribe((appointments: any) => {
            // console.log(["[diary] [apps] update observerd in CDC **this never fiires??"]);
            this.currentAppointments = appointments; // tis never fires so has been removed?!?!
        }));


        this.diary.getAvailability().subscribe(res => {
            this.availabilites = res;
        });

        this.diary.getCalendarSettings().subscribe(res => {
            this.settings = res;

        });

        //   this.selectedStaffWeekView$ = this.diary.getSelectedStaffWeekView().subscribe((res) => {
        //          this.selectedStaffWeekView = res;
        //     });


        this.diary.getCurrentZoom().subscribe(res => {
            this.segmentHeight = res;
        });

        this.diary.getCurrentDate().subscribe(res => {
            this.viewDate = res;
        });

        this.diaryEvents$ = this.diary.events$
            .pipe(debounceTime(50), distinctUntilChanged())
            .subscribe(res => {
                console.log("[events$] recieved", res);
                if (res && res.length) {
                    this.reassignUsersToEvents(this.selectedStaff, res); // what does this do?
                } else { this.diaryEvents = [] }
                if (this.staffOrRooms === StaffRoomsView.ROOMS && this.currentView === viewTypes.WEEK) {
                    this.diaryEventsForRoomView = [];
                    this.selectedRooms.forEach((selectedRoom: any) => {
                        const diaryEventsForRoom = this.diaryEvents.filter((event: any) => event.meta.roomIdx === selectedRoom.idx);

                        this.diaryEventsForRoomView = [...this.diaryEventsForRoomView, ...diaryEventsForRoom];
                    });

                    /* removed as does nothing but run a lot of http requests and log them
                    this.diaryEventsForRoomView.forEach(event => {
                        this.appointments.getAppointment(event.idx).pipe(take(1)).subscribe(res=>console.log("APOINTMENT DEETS", res))
                    })
        */
                } else {
                    /* removed as does nothing but run a lot of http requests and log them
                        this.diaryEvents.forEach(event => {
                            this.appointments.getAppointment(event.idx).pipe(take(1)).subscribe(res=>console.log("APOINTMENT DEETS", res))
                        })
                    */
                }

            });
/*
        fromEvent(document.body, 'mousemove').subscribe((e: MouseEvent) => {
            this.currentMousePosition.x = e.pageX - (CONTROL_WIDTH / 2);
            this.currentMousePosition.y = e.pageY - (CONTROL_WIDTH / 2);
        });
    */
        
    }

    public selectWorkingOrRefresh(): void {
        if (this.workingButtonClicked && !this.selectedStaff.length) {
            window.location.reload();
            this.workingButtonClicked = false;
            return;
        }

        if (!this.workingButtonClicked) {
            this.diary.setShowWorking(true);
            this.workingButtonClicked = true;
        }
    }

    private updateAppointmentsState(idx: number, newStart: string, duration: number, newRoom?: { id: number, title: string }): void {
        this.appointments.saveStartTime(idx, newStart, duration, newRoom);
        // this.appointments.getAllAppointments(); // this does nothing
    }

    public getCurrentRoomName(): string {
        if (this.selectedRooms[0]) {
            return this.selectedRooms[0].name;
        }
        return "No room selected";
    }

    public roomChanged(event: any) {
        this.newRoom = event.newRoom;
    }

    public eventTimesChangedInRoomView(newEvent: any): void {
        setTimeout(() => {
            if (this.newRoom) {
                this.initiateUpdateAppointment(newEvent.event, newEvent.newStart, newEvent.newEnd, this.newRoom);
            } else {
                this.initiateUpdateAppointment(newEvent.event, newEvent.newStart, newEvent.newEnd);
            }
        }, 300);
    }

    public eventTimesChangedInStaffView(newEvent: any): void {
        this.initiateUpdateAppointment(newEvent.event, newEvent.newStart, newEvent.newEnd);
    }

    private initiateUpdateAppointment(event: any, newStart: Date, newEnd: Date, newRoom?: any): void {

        const changedEvent = event;
        console.log("[drag]", event, newRoom);

        if (
            (event.start.getTime() !== newStart.getTime() || event.end.getTime() !== newEnd.getTime()) &&
            this.checkEventStartTime(event, newStart) == 0 &&
            this.checkEventEndTime(event, newEnd) == 0
        ) {
            event.start = newStart;
            event.end = newEnd;
            this.diaryEvents = [...this.diaryEvents];

            console.log("[drag]", changedEvent, this.currentAppointments);

            const currentBooking = this.currentAppointments.filter((app: any) => app.idx === changedEvent.idx)[0];
            this.updateAppointmentsState(changedEvent.idx,
                this.pipe.transform(newStart, "y-MM-d HH:mm:ss"),
                Math.abs((newEnd.getTime() - newStart.getTime()) / 60000),
                newRoom ? newRoom : undefined);

            const oldStartInFormat = this.pipe.transform(currentBooking.startTime, "dd/MM/y HH:mm");
            const newStartInFormat = this.pipe.transform(newStart, "dd/MM/y HH:mm");

            let confirmQuestion = "Are you sure you want to move this appointment";
            if (oldStartInFormat !== newStartInFormat) { confirmQuestion += ` from ${oldStartInFormat} to ${newStartInFormat}`; }
            if ((oldStartInFormat !== newStartInFormat) && newRoom) { confirmQuestion += " and"; }
            if (newRoom) { confirmQuestion += ` from ${currentBooking.roomname} to ${newRoom.title}`; }
            confirmQuestion += "?";

            this.updateAppointmentsState(changedEvent.idx,
                this.pipe.transform(newStart, "y-MM-d HH:mm:ss"),
                Math.abs((newEnd.getTime() - newStart.getTime()) / 60000));
            this.store.dispatch(fromDiary.SetDraggingActive({ payload: true }));

            this.dialogService.genericDialog("Move Appointment", confirmQuestion, ['Yes'], ['No'], false)
                .then((answer: DialogResult) => {
                    this.newRoom = undefined;
                    this.store.dispatch(fromDiary.SetDraggingActive({ payload: false }));

                    if (answer.data) {
                        changedEvent.start = newStart;
                        changedEvent.end = newEnd;
                        changedEvent.duration = Math.abs((newEnd.getTime() - newStart.getTime()) / 60000);
                        this.triggerObservable();
                        this.updateDraggedAppointmentOnAPI(changedEvent, currentBooking, newRoom);
                    } else {
                        this.updateAppointmentsState(currentBooking.idx,
                            currentBooking.startTime,
                            currentBooking.duration,
                            { id: currentBooking.roomIdx, title: currentBooking.roomname });

                        this.appointments.forceAppointmentEmit();
                    }
        })
    }

    }

    private updateDraggedAppointmentOnAPI(changedEvent: any, currentBooking: any, newRoom?: any): void {

        this.patientChooser.setPatient(changedEvent.meta.patientId);
        this.appointments.getAppointment(changedEvent.meta.apointmentUID)
            .subscribe(draggedAppointment => {
                this.appointments.clashCheck({
                    startTime: changedEvent.start,
                    duration: changedEvent.duration,
                    staffIdx: draggedAppointment.staffIdx,
                    roomIdx: newRoom ? newRoom.id : draggedAppointment.roomIdx,
                    idx: changedEvent.meta.apointmentUID
                }).subscribe((errors: string[]) => {

                    this.appointments.save({
                        startTime: changedEvent.start,
                        duration: changedEvent.duration,
                        patientIdx: changedEvent.meta.patientId,
                        staffIdx: draggedAppointment.staffIdx,
                        roomIdx: newRoom ? newRoom.id : draggedAppointment.roomIdx,
                        treatmentIdx: draggedAppointment.treatmentIdx,
                        notes: draggedAppointment.notes,
                        idx: changedEvent.meta.apointmentUID
                    }).subscribe(status => {
                        console.log("Status of update patients time", status);
                    });
                    if (errors) {
                        this.notifications.send(errors.join("\n"));
                        this.updateAppointmentsState(currentBooking.idx,
                            currentBooking.startTime,
                            currentBooking.duration,
                            { id: currentBooking.roomIdx, title: currentBooking.roomname });
                    }
                });
            });

    }

    checkEventStartTime(event, newStart): number {
        const unavailableTimes = this.currentAppointments
            .filter(existingEvent => existingEvent.title == "strictUnavailable")
            .filter(existingEvent => existingEvent.meta.user == event.meta.user)
            .filter(existingEvent => existingEvent.start <= newStart)
            .filter(existingEvent => existingEvent.end > newStart);

        return unavailableTimes.length;
    }
    checkEventEndTime(event, newEnd) {
        const unavailableTimes = this.currentAppointments
            .filter(existingEvent => existingEvent.title == "strictUnavailable")
            .filter(existingEvent => existingEvent.meta.user == event.meta.user)
            .filter(existingEvent => existingEvent.start < newEnd)
            .filter(existingEvent => existingEvent.end >= newEnd);

        return unavailableTimes.length;
    }
    triggerObservable() {
        // this.diary.emitAllApps(); //diaryEventEmitter.emit();
    }

    addEvent(start, column) {

        // let staffMember;

        const startMs = start.getTime();

        let queryParams = { start: startMs, staffIdx: null, roomIdx: null }

        if (this.currentView == ViewTypes.MONTH) {
            return;
        }
        console.log(`[diary] ${this.staffOrRooms}  @ ${column}`, this.selectedRooms);

        if (this.currentView == ViewTypes.DAY && this.staffOrRooms==StaffRoomsView.STAFF) {
            queryParams.staffIdx = this.selectedStaff[column].id;

        }

        if (this.currentView == ViewTypes.DAY && this.staffOrRooms==StaffRoomsView.ROOMS) {
            queryParams.roomIdx = this.selectedRooms[column].id;
        }

        if (this.currentView == ViewTypes.WEEK) {
            queryParams.staffIdx = this.selectedStaff[0] ? this.selectedStaff[0].id : 0;
        }

        console.log(`[staff column] book @ ${start} for ${column}`, this.selectedStaff);
        //  console.log("[staff column]", this.selectedStaff, column);

        

        this.router.navigate(['/appointment'], { queryParams: queryParams });
    }

    logClickEvent(event) {
        // console.log("[staff column]", event)
        // const staffClassName = event.sourceEvent.target.className.split(" ").find((s: string) => s.substring(0, 5) === "staff");
        // const staffColumn = +staffClassName.charAt(staffClassName.length - 1);

        if (!this.auth.permission(AvailablePermissions.diary_read_only)) {
            console.log("[click]", event);
            this.addEvent(event.date, event.columnIndex);
        }
    }

    eventClicked(event) {
        console.log("[LEFT CLICK]", event);
        this.appointmentSelected(event.event);
    }

    showContextMenu(event) {
//        console.log("[menu] positions here: ", this.currentMousePosition, event);
//        this.controlsPos.left = this.currentMousePosition.x;
//        this.controlsPos.top = this.currentMousePosition.y;
        this.selectedEvent = event;
        this.diaryMenu.openMenu();
    }

    closeMenu() {
        this.cancelCloseContextMenu();
        this.contextHideTimer$ = setTimeout(() => {
            this.closeDiaryMenu();
        }, 500);
    }

    cancelCloseContextMenu() {
        if (this.contextHideTimer$) {
            clearTimeout(this.contextHideTimer$);
        }
    }

    contextMenu(event) {
        event.mouseEvent.preventDefault();
//        console.log("[menu] positions here: ", this.currentMousePosition, event);
        const mouseEvent = (event.mouseEvent as PointerEvent)
        this.controlsPos = {left: mouseEvent.pageX, top: mouseEvent.pageY};
        if (this.diaryMenu.menuOpen) {
            this.closeDiaryMenu();
            setTimeout(() => this.showContextMenu(event.timeEvent), 300);
        } else {
            this.showContextMenu(event.timeEvent);
        }
    }

    setDate(deltaDays: number) { // takes a difference in days from TODAY and then emits an event that changes the diary.
        this.diary.setDate(this.viewDate, deltaDays);
    }

    setWeekOrDayView(view: ViewTypes) { // takes a difference in days from TODAY and then emits an event that changes the diary.
        this.diary.setWeekOrDayView(view);
    }

    public togglePrivateView() {
        this.diary.setPrivate(!this.diary.privateView);
    }
    public viewCancelled() {
        this.diary.setCancelled(!this.showCancelled);
    }

    public clickLocation(event) {
        console.log(event);
    }

    public appointmentSelected(event) {
        this.selectedEvent = null;

        if (this.diary.mobileView) { return; }

        if (event.meta.status===AppointmentStatus.UNALLOCATED_SLOT){
            this.router.navigate(['/appointment'], { queryParams: { appointmentIdx: event.idx } });
            return;
        }
        if (event) {
            this.diary.showRightSidebar(event);
            return;
        }
        this.diary.hideRightSidebar();
    }

    beforeWeekViewRender(renderEvent: CalendarWeekViewBeforeRenderEventWithUsers, checkHeader = false) { // calculates the availabilty
        console.log("render event: ", renderEvent);
        // const availabilites = this.diary.getAvailability();
        this.hourColumns = renderEvent.hourColumns;
        let staffIdx = null;
        let filteredAvailability;
        if (this.availabilites.length == 0) {
            return;
        }

        if (renderEvent.users) {
            renderEvent.users.forEach((user: DiaryColumn) => {
                const treatmentIdx = this.diary.allStaff.find((staff: StaffMember) => staff.idx === user.id).treatFromCat;
                const treatment = this.diary.allTreatments.find((treatment: any) => treatment.idx === treatmentIdx);

                if (treatment) {
                    user.discipline = treatment.name;
                } else {
                    user.discipline = "";
                }
                const splitTitle = user.title.split(" ");
                user.name = { firstName: splitTitle[0], lastName: splitTitle[1] };
            });
        }

        renderEvent.hourColumns.forEach((hourColumn, columnIndex) => {
            let weekView;
            if (renderEvent.users) {
                staffIdx = renderEvent.users[columnIndex].id;
                filteredAvailability = this.availabilites.find(f => f.staffIdx == staffIdx);
            } else if (this.selectedStaff[0]) {
                //  console.log("[avail] no check header staff = ",this.selectedStaff);
                filteredAvailability = this.availabilites.find(f => f.staffIdx == this.selectedStaff[0].id);
                weekView = true;
            }
            
            if(this.staffOrRooms == StaffRoomsView.ROOMS) filteredAvailability = this.roomAvailabilities[0];
            
            // console.log("[avail] filtered availability = ", this.availabilites, filteredAvailability);
            if (filteredAvailability && filteredAvailability.available) {
                //     console.log("[bwvr]", filteredAvailability);
                // filteredAvailability.available.sort((a, b) => a - b);
                // console.log("[bwvr] sorted :)", filteredAvailability);
                hourColumn.hours.forEach((hour) => {
                    hour.segments.forEach((segment) => {
                        segment.cssClass = `staff-index-${columnIndex}`;
                        if (this.isSegmentAvailable(segment, filteredAvailability.available, staffIdx, weekView)) {
                            segment.cssClass += ' bg-available';
                        }
                    });
                });
            }
        });

    }

    private isSegmentAvailable(segment, availability, staffIdx, weekView?): boolean {
        let day = segment.date.getDay() - 1;
        let segmentTime;
        if (day == -1) { day = 6; }

        segmentTime = (segment.date.getHours() * 60) + segment.date.getMinutes() + (day * 1440); // + 1;

        const found = availability.find(f => f == segmentTime);
        if (found) {
            return true;
        }
        return false;
    }

    private resetTimes() {
        this.diary.resetWorking();
    }


    /**
     * Set the optional middle of the day time view.
     */
    public setMID() {
        if (this.settings.endHour == this.settings.midSplitEnd && this.settings.startHour == this.settings.midSplitStart) {
            this.resetTimes();
            return;
        }
        this.updateTimes(this.settings.midSplitStart, this.settings.midSplitEnd);
    }

    public setAM() {
        if (this.settings.endHour == this.settings.morningEnd) {
            this.resetTimes();
            return;
        }
        this.updateTimes(null, this.settings.morningEnd);
    }

    public setPM() {
        if (this.settings.startHour == this.settings.afternoonStart) {
            this.resetTimes();
            return;
        }
        this.updateTimes(this.settings.afternoonStart, null);
    }

    updateTimes(startHour, endHour) {
        this.settings.startHour = startHour;
        this.settings.endHour = endHour;
        this.diary.updateWorking({
            startHour: startHour,
            endHour: endHour
        });
    }

    updateRolloverPatientId(meta) {
        this.appHistoryService.setPatientId(meta.patientId);
        return meta.patientId;
    }

}

