import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  SimpleChanges,
  OnInit,
  inject,
  DestroyRef,
  ChangeDetectorRef
} from "@angular/core";
import { PatientChooserService } from "../../services/patient.service";
import { type PatientChooserResult } from "app/models/patient-details";
import { NG_VALUE_ACCESSOR, NgModel, UntypedFormControl } from "@angular/forms";
import {
  ValueAccessorBase,
  ControlValueAccessor,
} from "../value-accessor-base";
import { ActivatedRoute, Router } from "@angular/router";
import { AppointmentStatus } from "app/models/diary";
import { FormatterService } from "app/services/formatter.service";
import { DialogService } from "app/services/dialog/dialog.service";
import { AttendanceDialogComponent } from "app/dialogs/attendance-dialog/attendance-dialog.component";
import { Subscription } from "rxjs";
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: "patient-chooser",
  templateUrl: "./patient-chooser.component.html",
  styleUrls: ["./patient-chooser.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: PatientChooserComponent,
      multi: true,
    }]
})
export class PatientChooserComponent extends ValueAccessorBase<string> implements OnInit {

  @Input() allowAddNew = false;
  @Input() showLastAppointment = false;

  @Input() question;
  @Input() id;
  @Input() formControlName;
  @Input() form;
  @Input() readOnly;
  @Input() showNotes;

  @Input() includePatients: number;
  @Input() includeCompanies: number;
  @Input() includeInsurance: number;

  @Output() selectPatient = new EventEmitter<PatientChooserResult>()

  @ViewChild(NgModel, { static: false }) model: NgModel;
  @ViewChild("inputElement", { static: false }) inputEle: ElementRef;
  @ViewChild("resultsList", { static: false }) resultsEle: ElementRef;

  patientChooser: UntypedFormControl;
  public placeholder: string;
  inputValue: string;
  foundValues: PatientChooserResult[];
  selectedPatient: PatientChooserResult;
  selectListShown: boolean;
  _showPatientName = "";
  resultsListPos: any;
  unpaidDNA = false;
  unpaidLNC: any = null;
  createForm = false;
  patientDetails$: Subscription;
  destroyRef = inject(DestroyRef);

  private inputUpdateTimeout;
  private getPatient$;
  private getPatientInfo$;
  public lastAppointment;
  public patientNotes: string;

  constructor(private pcs: PatientChooserService,
    private router: Router,
    private route: ActivatedRoute,
    private formatter: FormatterService,
    private dialogService: DialogService,
    private cd: ChangeDetectorRef) 
  {
    super();
    this.selectListShown = false;
    this.selectedPatient = { idx: 0, name: "", address: "" };
    this.resultsListPos = { left: 0, top: 0, width: 0 };
  }

  get showPatientName(): string{
    return this._showPatientName;
  }

  set showPatientName(value: string){
    console.log("[chooser] set spn", value);
      this._showPatientName = value;  
      if (this.inputEle){
        this.inputEle.nativeElement.value = value;
      }
  }

  ngOnInit() {
    if (this.router.url == '/credits/transfer') this.clearPatient();
    if (this.router.url == '/merge-patients') this.clearPatient();

    this.patientChooser = new UntypedFormControl(this.id);
    this.route.params.subscribe(res => {
      if (res.searchOn === "0") { this.createForm = true; }

      if (res.formName !== "patientdetails" && res.searchOn !== "0") {
        this.getPatient$ = this.pcs.getPatient()
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe(res => {
            console.log("[chooser] we get told to set patient ", res);
            this.selectedPatient = res;
            if (res) {
              this.showPatientName = res.name;
            }
          });

        if (this.selectedPatient) {
          this.choosePatient(this.selectedPatient);
        }
      }

      if (this.question && this.question.options === 'disabled' && res.searchOn !== "0") {
        this.readOnly = true;
      }


      if (this.question && !this.createForm && this.question.value > 0) {
        this.patientDetails$ = this.pcs.getPatientDetails(this.question.value)
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe(patient => {
            console.log("[chooser] we get patient ", patient, this.question.value);
            this.selectedPatient = {
              idx: this.question.value,
              isSpecial: patient.isSpecial,
              name: patient.name,
              address: patient.address.join(", ")
            };
            this.showPatientName = patient.name;
          });
      }
    });

    this.placeholder = this.router.url.split("/")[2] === "staff" ? '' : 'Select Patient';

    this.getPatientInfo$ = this.pcs.getPatientInfo().pipe(takeUntilDestroyed(this.destroyRef)).subscribe(patientInfo => {
      console.log("[chooser]  : ", patientInfo);

      if (patientInfo) {
        this.lastAppointment = patientInfo.lastAppointment ? patientInfo.lastAppointment : null;
        this.unpaidDNA = false;
        this.unpaidLNC = null;
        this.patientNotes = patientInfo.notes;

        if (this.lastAppointment && this.lastAppointment.Status === AppointmentStatus.NOSHOW) {
          this.unpaidDNA = true;
        }

        if (patientInfo.unpaidLNC) {
          this.unpaidLNC = patientInfo.unpaidLNC;
        }
      }
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.inputEle.nativeElement.focus();
    }, 1);
  }

  ngOnDestroy() { }

  updateChooser(event) {
    const input = event.target.value;
    if (input != this.inputValue) {
      this.inputValue = input;
      if (this.inputUpdateTimeout) {
        clearTimeout(this.inputUpdateTimeout);
      }
      this.inputUpdateTimeout = setTimeout(() => this.fetchData(), 250);
    }
  }

  fetchData() {
    if (this.inputValue.length < 3) { return; }
    this.showResultsList();
    this.pcs.getResults(this.inputValue, this.includePatients, this.includeCompanies, this.includeInsurance).subscribe((res) => {
      console.log("[chooser]  found value: ", res, res.length);
      if (res.length > 0) {
        this.foundValues = res.map((foundValue: PatientChooserResult) =>
          ({ ...foundValue, highlighted: this.formatter.highlightName(foundValue.name, this.inputValue), idx: foundValue.idx }));
      } else {
        this.foundValues = [
          {
            idx: 0,
            name: "No results found",
            highlighted: "No results found",
            address: `searching for ${this.inputValue}`,
          },
        ];
      }

    });
  }

  showAttendance() {
    this.pcs.getAttendance(this.selectedPatient.idx)
      .subscribe(attendance => {
        this.dialogService.bespokeDialog(
          "",
          AttendanceDialogComponent,
          "Attendance", this.selectedPatient.name,
          attendance,
          "1200px");
      });
  }


  /*
    chooseNotAvailable(id) {
      this.pcs.getResults("available").subscribe((res) => {
        const notAvailableObject = res.filter(
          (obj) => obj.name === "Not Available"
        );
        this.choosePatient(notAvailableObject[0]);
      });
    }
  */
  chooseWalkIn() {
    this.pcs.getResults("walk").subscribe((res) => {
      const walkInObject = res.filter(
        (obj) => obj.name === "Walk In Patient"
      );
      this.choosePatient(walkInObject[0]);
    });
  }

  choosePatient(foundValue) {
    console.log("[chooser] ", foundValue);
    console.log("[chooser] ", foundValue.name);
    if (foundValue.name == "No results found") {
      this.value = "";
      this.showPatientName = "";
      return;
    }
    this.selectedPatient = foundValue;
    this.selectPatient.emit(this.selectedPatient)
    this.showPatientName = this.selectedPatient.name;
    //      this.patientChanged.emit(this.selectedPatient.idx);
    this.value = "" + this.selectedPatient.idx;
    this.pcs.selectPatient(this.selectedPatient);

    if (foundValue.isSpecial) {
      return; // do not run checks
    }


  }

  onBlur() {
    setTimeout(() => {
      this.selectListShown = false;
    }, 250);
    if(this.selectedPatient.idx){
        this.showPatientName = this.selectedPatient.name;
    }else{
        this.showPatientName = "";
    }

    console.log("[chooser] BLUR", this.selectedPatient);
  }

  showResultsList() {
    this.selectListShown = true;
    const inputPos = offset(this.inputEle.nativeElement);
    console.log(inputPos);
    // this.resultsListPos.left = inputPos.left;
    // this.resultsListPos.top = inputPos.top + inputPos.height;
    this.resultsListPos.width = inputPos.width;
  }

  addNewPatient() {
    this.router.navigate(["form", "patientdetails", "0"]);
  }

  clearPatient() {
    this.inputEle.nativeElement.value = '';
    this.selectedPatient = null;
    this.pcs.clearPatient();
  }

  editPatient() {
    console.log("edit patient", this.selectedPatient);
    this.router.navigate(["form", "patientdetails", this.selectedPatient.idx]);
  }


}

function offset(el) {
  let rect = el.getBoundingClientRect();
  const scrollLeft = 0; // .pageXOffset || document.documentElement.scrollLeft,
  const scrollTop = 0; // window.pageYOffset || document.documentElement.scrollTop;
  return {
    top: rect.top - scrollTop,
    left: rect.left + scrollLeft,
    height: rect.height,
    width: rect.width,
  };
}
