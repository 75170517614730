import { Component, ElementRef, HostListener } from '@angular/core';
import { type FastText, FastTextService } from './fast-text.service';
import { Subscription, filter } from 'rxjs';

@Component({
    selector: 'app-fast-text',
    templateUrl: './fast-text.component.html',
    styleUrls: ['./fast-text.component.scss'],
})
export class FastTextComponent {

    fastText$: Subscription;
    private fastText: FastText[];
    public filteredFastText: FastText[];
    origin: ElementRef;
    search: string = "";
    selectedOption: FastText;
    optionDelta = 0;
    public containerWidth = "400px";
    idx: number;
    constructor( private fastTextService: FastTextService){

    }

    ngOnInit() {
        this.fastText$ = this.fastTextService.fastText$.subscribe(res=> {
            this.search = "";
            this.selectedOption = null;
            if (!res){
                this.fastText = null;
                this.filteredFastText = null;
            }else{
                this.origin = res.origin;
                this.fastText = res.fastText;
                this.idx = res.idx;
                this.updateMatch();
                console.log(this.origin.nativeElement);
                this.containerWidth = (this.origin.nativeElement as HTMLElement).clientWidth+"px";
            }
            
        });
    }

    ngOnDestroy(){
        this.fastText$.unsubscribe();
    }

    detach(event){
        console.log("detached", event);
        this.close();
    }

    close(){
        this.fastText = [];
        this.fastTextService.hide(); // bye!
    }

    keyDown(event: KeyboardEvent){
        console.log("overlay key", event);
        switch (event.code){
            case "Enter":
            case "Tab":
                event.stopPropagation();
                event.preventDefault();
                if (this.selectedOption){
                    this.fastTextService.insert(this.selectedOption.long);
                }
                this.close();
                return;
            case "ArrowDown":
                this.optionDelta = this.optionDelta+1; //option delta handles up and down to pick
                break;
            case "ArrowUp":
                this.optionDelta = this.optionDelta-1;
                break;
            case "Backspace":
                this.search = this.search.substring(0, this.search.length-1);
                break;
            case "Space":
                this.close();
                return;
            default:
                if (event.code.startsWith("Key") || event.code.startsWith("Digit")){
                    this.search += event.key;
                }else{
                    this.close();
                }
        }
        this.updateMatch();
    }

    updateFilterList(){
        if (!this.fastText){
            this.filteredFastText=[];
            return;
        }

        console.log(this.fastText);
        this.filteredFastText = this.fastText.filter(text=>(
            this.search=="" || text.short.toLocaleLowerCase().startsWith(this.search.toLocaleLowerCase())
        ));
    }

    selectOption(text){
        this.selectedOption = text;
        this.fastTextService.insert(this.selectedOption.long);
        this.close();
    }


    updateMatch(){
        this.updateFilterList();
        this.selectedOption = null;
        if (this.filteredFastText.length==0){ return; }
        if (this.optionDelta<0) { this.optionDelta = this.filteredFastText.length-1; }
        if (this.optionDelta>=this.filteredFastText.length) { this.optionDelta = 0 }
        this.selectedOption = this.filteredFastText[this.optionDelta];

    }

    editMode() {
        console.log('EDIT MODE!!!');
        this.fastTextService.enterEditMode({
            idx: this.idx,
            fastText: this.fastText,
            appliesTo: this.origin
        })
    }
}
