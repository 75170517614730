import { Injectable } from '@angular/core';
import {
    Actions,
    createEffect,
    ofType
} from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';

import { mergeMap, map,  } from 'rxjs/operators';
import * as settingsActions from '../actions/site-settings';
import { DataService } from '../../services/data.service';
import { State } from '../../models/site-settings';



@Injectable()
export class SiteSettingsEffects {
    constructor(private http: DataService, private actions$: Actions) { }

    // ngrxOnInitEffects(): Action {
    //     return { type: settingsActions.ActionTypes.INIT };
    // }

    initSettings$ = createEffect(() => {
        return this.actions$
        .pipe(
            ofType(settingsActions.ActionTypes.INIT),
            mergeMap(() => this.http.get<State>('/settings')
                .pipe(
                    map(settings => {
                        console.log("[settings site] sets", settings);
                        return (new settingsActions.SetAction(settings))
                        })
                ))
        );
    });

    
}
