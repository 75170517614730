import { Component, EventEmitter, Input, OnInit, Output, SimpleChange } from '@angular/core';
import { NotesBuilderBaseComponent } from 'app/notes/notes-builder/notes-builder-base/notes-builder-base-component';
import { LumboFields } from '../models/lumbopelvic';


@Component({
  selector: 'app-lumbopelvic',
  templateUrl: './lumbopelvic.component.html',
  styleUrls: ['./lumbopelvic.component.css']
})
export class LumbopelvicComponent extends NotesBuilderBaseComponent {
  LumboFields = LumboFields;

}
