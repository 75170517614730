import { LetterWriterComponent } from './letter-writer/letter-writer.component';
import { LetterTemplateSelectComponent } from './letter-template-select/letter-template-select.component';
import { NewLetterTemplateComponent } from './new-letter-template/new-letter-template.component';

export const LettersAppRoutes = [  
    {
      path: 'letters/template/:notesIdx',
      component: LetterTemplateSelectComponent,
    },
    {
      path: 'letters/:notesIdx/:templateIdx',
      component: LetterWriterComponent
    },
    {
      path: 'letters/:notesIdx',
      component: LetterWriterComponent
    },
    {
      path: 'letters/:notesIdx',
      component: LetterWriterComponent
    },
    {
      path: 'letters/:notesIdx/:templateIdx',
      component: LetterWriterComponent
    },
    {
      path: 'lettertemplates',
      component: NewLetterTemplateComponent
    },
    {
      path: 'lettertemplates/:templateIdx/:notesIdx',
      component: NewLetterTemplateComponent,
    },
    {
      path: 'lettertemplates/:templateIdx',
      component: NewLetterTemplateComponent
    },
];

