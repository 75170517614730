<div mat-dialog-title class="dialog-title">
    <h5>{{data.title}}</h5>
    <button mat-icon-button aria-label="close dialog" class="close-button" tabindex="-1" mat-dialog-close>
      <mat-icon class="close-icon text-muted">close</mat-icon>
    </button>
</div>

<div mat-dialog-content>
    <my-attendance-graph [attendance]="attendance"></my-attendance-graph>
    <info-attendance [info]="attendance" fromModal="true"></info-attendance>
</div>
