<div [id]='formName'>
    <form autocomplete="off" class="lumbopelvicForm" #formEle="ngForm">
      <table>
        <thead>
          <th></th>
          <th class="text-center">L</th>
          <th class="text-center">R</th>
        </thead>
        <tbody>
          <tr *ngFor="let field of LumboFields">
            <td>{{ field.label }}</td>
            <td class="pe-3">
              <mat-form-field>
                <mat-label>{{ field.label }} (L)</mat-label>
                <input matInput [(ngModel)]="form[field.field+'_L']" [name]="field.field+'_L'">
              </mat-form-field>
            </td>
            <td class="pe-3">
              <mat-form-field >
                <mat-label>{{ field.label }} (R)</mat-label>
                <input matInput [(ngModel)]="form[field.field+'_R']" [name]="field.field+'_R'">
              </mat-form-field>
            </td>
          </tr>
        </tbody>
      </table>
    </form>
  </div>