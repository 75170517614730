// Boring Angular Modules //
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { PatientChooserComponent } from "./patient-chooser/patient-chooser.component";
import { GenericChooserComponent } from "./generic-chooser/generic-chooser.component";
import { InsuranceChooserComponent } from "./insurance-chooser/insurance-chooser.component";
import { MyMaterialModule } from "app/material/material.module";
import { StaffChooserComponent } from "./staff-chooser/staff-chooser.component";
import { PipesModule } from "app/pipes/pipes.module";
import { AddressChooserComponent } from "./address-chooser/address-chooser.component";
import { AddressLookupService } from "app/services/address-lookup.service";
import { FormsListChooserComponent } from "./forms-list-chooser/forms-list-chooser.component";
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    MyMaterialModule,
  //  DynamicFormsModule,
    PipesModule
  ],
  declarations: [
    AddressChooserComponent,
    StaffChooserComponent,
    PatientChooserComponent,
    GenericChooserComponent,
    InsuranceChooserComponent,
    FormsListChooserComponent
  ],
  exports: [
    AddressChooserComponent,
    PatientChooserComponent,
    GenericChooserComponent,
    InsuranceChooserComponent,
    StaffChooserComponent,
    FormsListChooserComponent
  ],
  providers: [
    AddressLookupService
  ]
})
export class CustomFormControlsModule {}
