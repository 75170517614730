<div class="credit-modal">
<div mat-dialog-title class="dialog-title">
    <h5>{{data.title}}</h5>
    <button mat-icon-button aria-label="close dialog" class="close-button" tabindex="-1" mat-dialog-close>
        <mat-icon class="close-icon text-muted">close</mat-icon>
    </button>
</div>



<div mat-dialog-content>
       <div class="row">
        <div class="col-sm-6" >
            <button class="btn btn-link" (click)="onDismiss()">{{staffMemeber}}</button>
        </div>
        <div *ngIf="dateFilter && dateFilter.from; else noDateFilter" class="col-sm-6">
            <button class="btn btn-link" (click)="onDismiss()">{{dateFilter.from | date : 'dd/MM/yyyy'}} - {{dateFilter.to | date : 'dd/MM/yyyy' }}</button>
        </div>
        <ng-template #noDateFilter>
            <div class="col-sm-6">
                <button class="btn btn-link" (click)="onDismiss()">No date filter</button>
            </div>
        </ng-template>

       </div>
        <!-- <pre>{{ transactions | json }} </pre> -->
        <table class="table">
            <thead>
                <tr>
                    <th></th>
                    <th><mat-checkbox [(ngModel)]="allSelected" (change)="selectAll($event.checked)"></mat-checkbox></th>
                    <th>Patient</th>
                    <th>Date</th>
                    <th>Sales</th>
                    <th>Commission</th>
                    <th>Balance</th>
                </tr>
            </thead>
            <tbody>
                <ng-container *ngFor="let transaction of transactions">
                    <tr 
                        [ngClass]="{'expanded-parent': transaction.expanded}" 
                        class="parent">
                        <td (click)="expandTransaction(transaction)">
                            <span *ngIf="transaction.transactions.length>1">
                                <mat-icon *ngIf="!transaction.expanded">expand_more</mat-icon>
                                <mat-icon *ngIf="transaction.expanded">expand_less</mat-icon>
                            </span>
                        </td>
                        <td><mat-checkbox [(ngModel)]="transaction.checked" (change)="selectTransaction($event.checked, transaction)"></mat-checkbox></td>
                        <td>{{ transaction.patientName }}</td>
                        <td>
                            <span *ngIf="transaction.transactions.length==1; else multiTxn">
                                {{ transaction.appointmentTime | date : 'HH:mm dd/MM/YY' }}
                            </span>
                            <ng-template #multiTxn><span class="text-muted"> x {{transaction.transactions.length}}</span></ng-template>
                        </td>
                        <td class="text-end">{{ transaction.totalSales | currency : 'GBP' }}</td>
                        <td class="text-end">{{ transaction.commissionPayable | currency : 'GBP' }}</td>
                        <td class="text-end">{{ transaction.balance | currency : 'GBP' }}</td>
                    </tr>

                    <ng-container *ngIf="transaction.expanded">
                        <tr *ngFor="let trans of transaction.transactions" class="expanded">
                            <td></td>
                            <td><mat-checkbox [(ngModel)]="trans.checked" (change)="selectTransaction($event.checked, trans, true, transaction)"></mat-checkbox></td>
                            <td>{{ trans.patientName }}</td>
                            <td>{{ trans.appointmentTime | date : 'HH:mm dd/MM/YY' }}</td>
                            <td class="text-end">{{ trans.totalSales | currency : 'GBP' }}</td>
                            <td class="text-end">{{ trans.commissionPayable | currency : 'GBP' }}</td>
                            <td class="text-end">{{ transaction.balance | currency : 'GBP' }}</td>
                        </tr>
                    </ng-container>
                </ng-container>
                <tr class='totals'>
                    <td></td>
                    <td colspan="3"><span class="text-muted">Selected</span> TOTAL</td>
                    <td class="text-end">{{ totalSales| currency : 'GBP' }}</td>
                    <td class="text-end">{{ totalCommissions | currency : 'GBP' }}</td>
                    <td></td>
                </tr>
            </tbody>
        </table>
        

        <div class="text-muted text-start"><span style="color: #c31432;">*</span> Patient has outstanding payments</div>
</div>

<div mat-dialog-actions>
        <div class="row w-100">
            <div class="col-sm-6">
                <button mat-button class="w-100" (click)="onDismiss()">Cancel</button>
            </div>
            <div class="col-sm-6">
                <button mat-raised-button class="w-100" color="primary" (click)="addSelectedTransactions()" [disabled]="!transactionsSelected">Add selected</button>
            </div>
        </div>
</div>
</div>