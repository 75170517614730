<header class="row" *ngIf="!diary.mobileView">
  <div class="col-md-4" [ngClass]="{'diary-header-left': !diary.mobileView }">
    <h5
      *ngIf="currentView == viewTypes.DAY"
      [ngClass]="{'header-title-mobile': diary.mobileView }"
      class="day-view-title"
      >
      {{ viewDate | calendarDate: "dayViewTitle" }}
    </h5>
    <h5 *ngIf="currentView == viewTypes.WEEK && selectedStaff[0] && staffOrRooms === StaffRoomsView.STAFF"
      class="day-view-title"
      [ngClass]="{'header-title-mobile': diary.mobileView }">
      {{ selectedStaff[0].title}}</h5>
    <h5 *ngIf="currentView == viewTypes.WEEK && selectedStaff[0] && staffOrRooms === StaffRoomsView.ROOMS"
      class="day-view-title"
      [ngClass]="{'header-title-mobile': diary.mobileView }">
      {{ getCurrentRoomName() }}</h5>

  </div>


  <div class="col-md-8 d-inline-flex justify-content-end" [ngClass]="{'diary-header-right': !diary.mobileView }">
    <div class="me-2 h-90">
      <div class="zoom-select">
        <div class="zoom-select-box" (click)="setAM()"
        [ngClass]="{'selcted' : settings && settings.endHour==settings.morningEnd}"
        >AM</div>
        <div class="zoom-select-box" (click)="setMID()" *ngIf="settings.midSplitStart>0"
        [ngClass]="{'selcted' : settings && settings.endHour==settings.midSplitEnd}"
        >{{settings.midSplitStart}}-{{settings.midSplitEnd}}</div>
        <div class="zoom-select-box text-end" (click)="setPM()"
        [ngClass]="{'selcted' : settings && settings.startHour==settings.afternoonStart}" >PM</div>
      </div>
    </div>

    <div title="Private View" class="me-2">
      <button mat-button (click)="togglePrivateView()">
        <mat-icon *ngIf="!diary.privateView" >visibility</mat-icon>
        <mat-icon *ngIf="diary.privateView" >visibility_off</mat-icon>
        <span class="hide-narrow">&nbsp;{{ diary.privateView ? 'Normal' : 'Private'}}</span>
      </button>
    </div>
    <div  title="Show Cancelled" class="me-2">
      <button mat-button class="right" (click)="viewCancelled()">
        <mat-icon *ngIf='!showCancelled'>delete_outline</mat-icon>
        <mat-icon *ngIf='showCancelled'>delete</mat-icon>
          <span class="hide-narrow">&nbsp;{{ showCancelled ? 'Hide Cancelled' : 'Show Cancelled' }}</span>
      </button>
    </div>

    <div class="font-14 me-2">
      <button mat-button
        class="p-0 small-btn"
        (click)="zoomService.height=zoomService.height-1">
        <mat-icon>zoom_out</mat-icon>
      </button>
      <span class="hide-narrow">
        <mat-slider
          [min]="zoomService.MIN_ZOOM"
          [max]="zoomService.MAX_ZOOM"
          step="1"
         ><input matSliderThumb  [(ngModel)]="zoomService.height" /></mat-slider>
        </span>
        <button mat-button
          class="p-0 small-btn"
          (click)="zoomService.height=zoomService.height+1">
          <mat-icon>zoom_in</mat-icon>
        </button>
    </div>
  </div>
</header>

<div
  [ngSwitch]="currentView"
  id="diaryDiv"
  *ngIf="settings"
  >

  <!-- MONTH -->
  <div *ngSwitchCase="viewTypes.MONTH">
    <mwl-calendar-month-view [viewDate]="viewDate" [events]="diaryEvents">
    </mwl-calendar-month-view>
  </div>

  <div class="open-background" *ngIf="(!selectedStaff || !selectedStaff.length) && staffOrRooms === StaffRoomsView.STAFF && currentView === viewTypes.DAY">
    <div class="open-background-icon"><mat-icon>people</mat-icon></div>
    <div class="open-background-text">No Staff seem to have loaded for the selected date.</div>
    <div class="open-background-text">Please select another day or</div>

    <button mat-raised-button color="primary" (click)="selectWorkingOrRefresh()">Select Working Staff</button>
  </div>

  <!-- WEEK -->
  <div *ngSwitchCase="viewTypes.WEEK">
    <ng-container *ngIf="staffOrRooms === StaffRoomsView.STAFF">
      <mwl-calendar-week-view
        [viewDate]="viewDate"
        [events]="diaryEvents"
        [dayStartHour]="settings.startHour"
        [dayEndHour]="settings.endHour"
        [hourSegments]="settings.hourSegments"
        snapDraggedEvents="false"
        (eventTimesChanged)="eventTimesChangedInStaffView($event)"
        [hourSegmentTemplate]="hourSegmentTemplate"
        [hourSegmentHeight]="zoomService.height"
        [tooltipTemplate]="tooltipTemplate"
        [eventTemplate]="weekViewEventTemplate"
        [minimumEventHeight]="20"
        (beforeViewRender)="beforeWeekViewRender($event, false)"
        (eventClicked)="eventClicked($event)"
        (hourSegmentClicked)="logClickEvent($event)">
      </mwl-calendar-week-view>
    </ng-container>

    <ng-container *ngIf="staffOrRooms === StaffRoomsView.ROOMS">
      <mwl-calendar-week-view
        [viewDate]="viewDate"
        [events]="diaryEventsForRoomView"
        [dayStartHour]="settings.startHour"
        [dayEndHour]="settings.endHour"
        [hourSegments]="settings.hourSegments"
        snapDraggedEvents="false"
        (eventTimesChanged)="eventTimesChangedInStaffView($event)"
        [hourSegmentTemplate]="hourSegmentTemplate"
        [hourSegmentHeight]="zoomService.height"
        [tooltipTemplate]="tooltipTemplate"
        [eventTemplate]="weekViewEventTemplate"
        (beforeViewRender)="beforeWeekViewRender($event, false)"
        (eventClicked)="eventClicked($event)"
        (hourSegmentClicked)="logClickEvent($event)">
      </mwl-calendar-week-view>
    </ng-container>

  </div>

  <!-- DAY -->
  <div *ngSwitchCase="viewTypes.DAY">
    <ng-container *ngIf="staffOrRooms === StaffRoomsView.STAFF">
      <app-mwl-day-view-scheduler
        [viewDate]="viewDate"
        [events]="diaryEvents"
        [users]="selectedStaff"
        (eventTimesChanged)="eventTimesChangedInStaffView($event)"
        [dayStartHour]="settings.startHour"
        [dayEndHour]="settings.endHour"
        [hourSegments]="settings.hourSegments"
        [tooltipTemplate]="tooltipTemplate"
        [hourSegmentTemplate]="hourSegmentTemplate"
        [hourSegmentHeight]="zoomService.height"
        (beforeViewRender)="beforeWeekViewRender($event, true)"
        (eventClicked)="eventClicked($event)"
        (hourSegmentClicked)="logClickEvent($event)"
        (rightClickEvent)="contextMenu($event)"
      >
      </app-mwl-day-view-scheduler>
    </ng-container>

    <ng-container *ngIf="staffOrRooms === StaffRoomsView.ROOMS">
      <app-mwl-day-room-view-scheduler
        [viewDate]="viewDate"
        [events]="diaryEvents"
        [dayStartHour]="settings.startHour"
        [dayEndHour]="settings.endHour"
        [hourSegments]="settings.hourSegments"
        [tooltipTemplate]="tooltipTemplate"
        snapDraggedEvents="false"
        (roomChanged)="roomChanged($event)"
        (eventTimesChanged)="eventTimesChangedInRoomView($event)"
        [hourSegmentTemplate]="hourSegmentTemplate"
        [hourSegmentHeight]="zoomService.height"
        (beforeViewRender)="beforeWeekViewRender($event, true)"
        (eventClicked)="eventClicked($event)"
        (hourSegmentClicked)="logClickEvent($event)"
        (rightClickEvent)="contextMenu($event)">
      </app-mwl-day-room-view-scheduler>
    </ng-container>

  </div>

  <div class="diary-controls darkMode"
    [matMenuTriggerFor]="diaryMenu"
    [style.top.px]="controlsPos.top"
    [style.left.px]="controlsPos.left">
    <mat-menu [hasBackdrop]="false" #diaryMenu="matMenu" xPosition="after" class="darkMode mouseleave">
      <app-diary-actions
        [appointment]="selectedEvent"
        (mouseleave)="closeMenu()"
        styleType="context-list"
        (clicked)="closeMenu()"></app-diary-actions>
    </mat-menu>
  </div>

  <!-- used for on hover                                                                            -->
  <ng-template #tooltipTemplate let-contents="contents" let-placement="placement" let-event="event">
    <div class="cal-tooltip" [ngClass]="'cal-tooltip-' + placement">
      <div class="cal-tooltip-arrow"></div>
      <div class="cal-tooltip-inner bg-dark text-white">
        <div class="tool-tip-time">
          {{event.start | date : 'HH:mm'}} - {{event.end | date : 'HH:mm'}}
          <mat-icon
          *ngIf="event.meta.SMSStatus !== null"
          class="mail-icon"
          [ngClass]="{'sms-green': event.meta.SMSStatus === 00 || event.meta.SMSStatus === '00',
                      'sms-red': event.meta.SMSStatus === 99 || event.meta.SMSStatus === '99'}"
          >
          mail</mat-icon>{{ event.meta.SMSStatus }}
        </div>

        <div class="d-flex w-100 justify-content-between">
          <div *ngIf="event.meta.status != AppointmentStatus.UNALLOCATED_SLOT else unallocatedTitle">{{event.meta.patientName}}</div>
          <ng-template #unallocatedTitle>Open Slot</ng-template>
          <div class='text-end' *ngIf="event.meta.status != AppointmentStatus.TIMEOFF && event.meta.status !== AppointmentStatus.UNALLOCATED_SLOT">#{{updateRolloverPatientId(event.meta)}}</div>
        </div>

        <div class="row w-100">
          <div class="col-sm" *ngIf="event.meta.patientDOB  &&  event.meta.status !== AppointmentStatus.TIMEOFF && event.meta.status !== AppointmentStatus.UNALLOCATED_SLOT">
              {{event.meta.patientDOB | date : 'dd/MM/yyyy'}}

          </div>
        </div>

        <div class="row w-100 border-top mt-2" *ngIf="event.meta.status != AppointmentStatus.TIMEOFF && event.meta.status !== AppointmentStatus.UNALLOCATED_SLOT && staffOrRooms === StaffRoomsView.ROOMS">
          <div class="col-sm fw-bold">{{event.meta.user.firstname}} {{event.meta.user.lastname}}</div>
        </div>
        <div [ngClass]="{'border-top mt-2': staffOrRooms !== StaffRoomsView.ROOMS }" class="row w-100" *ngIf="event.meta.status != AppointmentStatus.TIMEOFF && event.meta.status !== AppointmentStatus.UNALLOCATED_SLOT">
          <div class="col-sm">{{event.meta.treatment}}</div>
        </div>
        <div class="row w-100" *ngIf="event.meta.status != AppointmentStatus.TIMEOFF && event.meta.status !== AppointmentStatus.UNALLOCATED_SLOT">
          <div class="col-sm">{{event.meta.roomName}}</div>
        </div>

        <div class="row w-100 border-top mt-2" *ngIf="event.meta.status != AppointmentStatus.TIMEOFF && event.meta.status !== AppointmentStatus.UNALLOCATED_SLOT">
          <div class="col-sm">Previous: {{appHistoryService.previousApps$ | async}}</div>
        </div>

        <div class="row w-100 border-top mt-2" *ngIf="event.meta.status != AppointmentStatus.TIMEOFF  && event.meta.status !== AppointmentStatus.UNALLOCATED_SLOT && (appHistoryService.nextVisit$ | async)">
          <div class="col-sm">Next: {{appHistoryService.nextVisit$ | async | date : 'short'}}</div>
        </div>

        <div class="tool-tip-time" *ngIf="event.meta.status != AppointmentStatus.TIMEOFF  && event.meta.status !== AppointmentStatus.UNALLOCATED_SLOT && +(appHistoryService.dnaUnpaidCount$ | async) > 0 ">
          <div class="col-sm">UNPAID DNAs {{appHistoryService.dnaUnpaidCount$ | async}}</div>
        </div>

        <div class="tool-tip-time" *ngIf="event.meta.status != AppointmentStatus.TIMEOFF  && event.meta.status !== AppointmentStatus.UNALLOCATED_SLOT && +(appHistoryService.lncUnpaidCount$ | async )> 0 ">
          <div class="col-sm">UNPAID LNCs {{appHistoryService.lncUnpaidCount$ | async }}</div>
        </div>

        <div class="tool-tip-time row w-100 border-white mt-2 text-highlight-bg" *ngIf="event.meta.notes">
          <div class="col-sm">{{event.meta.notes}}</div>
        </div>

        <div class="row w-100 border-top mt-2 " *ngIf="event.meta.patientGeneralNotes">
          <div class="col-sm">{{event.meta.patientGeneralNotes}}</div>
        </div>
      </div>
      </div>
    </ng-template>


    <!-- custom hour blocks -->
    <ng-template #hourSegmentTemplate let-segment="segment" let-locale="locale" let-segmentHeight="segmentHeight"
      let-isTimeLabel="isTimeLabel" let-daysInWeek="daysInWeek">
      <div [attr.aria-hidden]="
        {}
          | calendarA11y
            : (daysInWeek === 1
                ? 'hideDayHourSegment'
                : 'hideWeekHourSegment')
      " class="cal-hour-segment"
      [style.height.px]="segmentHeight"
      [class.cal-hour-start]="segment.isStart"
      [class.cal-after-hour-start]="!segment.isStart"
      [ngClass]="segment.cssClass"
      title="{{segment.displayDate | date : 'short' }}">
      <!-- Time displayed as hh:mm if on larger screens -->
      <div class="cal-time" *ngIf="isTimeLabel && !onMobile">
        {{
        segment.displayDate
          | calendarDate
            : (daysInWeek === 1 ? 'dayViewHour' : 'weekViewHour')
            : locale
      }}
      </div>
      <!-- Time displayed as hh if on smaller screens-->
      <div class="cal-time" *ngIf="isTimeLabel && onMobile">
        {{
          segment.displayDate
            | date:'hh'
        }}
      </div>
      </div>
    </ng-template>


    <!-- custom event template to allow for context menu -->

    <ng-template #weekViewEventTemplate let-weekEvent="weekEvent" let-tooltipPlacement="tooltipPlacement"
      let-eventClicked="eventClicked" let-tooltipTemplate="tooltipTemplate" let-tooltipAppendToBody="tooltipAppendToBody"
      let-tooltipDisabled="tooltipDisabled" let-tooltipDelay="tooltipDelay" let-column="column"
      let-daysInWeek="daysInWeek" let-eventActionsTemplate="eventActionsTemplate" let-locale="locale"
      let-eventTitleTemplate="customEventTemplate" let-minimumEventHeight="20">
      <div class="cal-event"
      [ngClass]="{
        'flashing':  weekEvent.event.meta.status == AppointmentStatus.PENDING_APPROVAL,
        'cancelled': weekEvent.event.meta.status == AppointmentStatus.CANCELLED
      }"
      [ngStyle]="{
            backgroundColor: weekEvent.event.color?.secondary,
            borderColor: weekEvent.event.color?.primary
          }"
      [mwlCalendarTooltip]="
            !tooltipDisabled ? (weekEvent.event.title
            | calendarEventTitle
                  : (daysInWeek === 1 ? 'dayTooltip' : 'weekTooltip')
                  : weekEvent.tempEvent || weekEvent.event)
                  : '' "
        [tooltipPlacement]="tooltipPlacement"
        [tooltipEvent]="weekEvent.tempEvent || weekEvent.event"
        [tooltipTemplate]="tooltipTemplate"
        [tooltipAppendToBody]="tooltipAppendToBody"
        [tooltipDelay]="tooltipDelay"
        (mwlClick)="eventClicked.emit({ sourceEvent: $event })"
        (mwlKeydownEnter)="eventClicked.emit({ sourceEvent: $event })"
        (contextmenu)="contextMenu({ mouseEvent: $event, timeEvent: weekEvent.event})" tabindex="0" role="application"
        [attr.aria-label]="
            { event: weekEvent.tempEvent || weekEvent.event, locale: locale }
              | calendarA11y: 'eventDescription'
          ">

        <mwl-calendar-event-actions [event]="weekEvent.tempEvent || weekEvent.event"
          [customTemplate]="eventActionsTemplate">
        </mwl-calendar-event-actions>

          &ngsp;

          <mwl-calendar-event-title [event]="weekEvent.tempEvent || weekEvent.event" [customTemplate]="customEventTemplate"
            [view]="daysInWeek === 1 ? 'day' : 'week'">
          </mwl-calendar-event-title>

      </div>

    </ng-template>

    <ng-template #customEventTemplate let-event="event" let-locale="locale">
      <div *ngIf="event.title != 'null'" style="margin: 5px;" [style.color]="event.color?.textColour">{{ event.title }}</div>
      <div class="notesInAppointment" *ngIf="event.meta.status == AppointmentStatus.TIMEOFF">{{ event.meta.notes }}</div>
      <mat-icon *ngIf="event.meta?.nextAppointment" class="green-ticks">check_circle_outline</mat-icon>
      <div class="notesInAppointment" *ngIf="event?.meta?.status == AppointmentStatus.UNALLOCATED_SLOT">
        Open Slot
      </div>
    </ng-template>

</div>
