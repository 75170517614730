import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthNeededGuard  {

  constructor(private router: Router, private auth: AuthService) { }

  canActivate() {
    console.log("[guard] acti auth guard checks you");
    if (this.auth.isLoggedIn()) return true;
    console.log("[guard] acti auth guard rejects you");
    this.router.navigate(['/login']);
    return false;
  }
}


//this is the opposite to stop you going back to login screen if logged in
@Injectable()
export class AuthNotNeededGuard  {

  constructor(private router: Router, private auth: AuthService) { }

  canActivate() {
    console.log("[guard] acti ANTIauth guard checks you");
    if (this.auth.isLoggedIn()) {
      console.log("we are loagged in");
      this.router.navigate(['/']);
      return false;
    }
    return true;
  }
}
