import { Component, EventEmitter, Input, OnInit, Output, SimpleChange } from '@angular/core';
import { NotesBuilderBaseComponent } from 'app/notes/notes-builder/notes-builder-base/notes-builder-base-component';

@Component({
  selector: 'app-drawing-abdomen',
  templateUrl: './drawing-abdomen.component.html',
  styleUrls: ['./drawing-abdomen.component.css']
})
export class DrawingAbdomenComponent extends NotesBuilderBaseComponent {
 
}