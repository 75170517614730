<div class="hide-overflow">
    <div mat-dialog-title class="dialog-title hide-overflow">
        <h5>{{data.title}}</h5>
        <button mat-icon-button aria-label="close dialog" class="close-button" tabindex="-1" mat-dialog-close>
            <mat-icon class="close-icon text-muted">close</mat-icon>
        </button>
    </div>
    
    <div mat-dialog-content>
        <mat-form-field class="w-100">
            <mat-label>Reason (optional)</mat-label>
            <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="1" id="cancelReason" name="cancelReason"
                [(ngModel)]="textInput"></textarea>
        </mat-form-field>
    </div>
    
    <div mat-dialog-actions>
        <div class="row" style="min-width: 400px;">
            <div class="col-sm-12 d-flex">
                    <mat-checkbox class="example-margin" [(ngModel)]="isChecked">Send cancellation notice to patient</mat-checkbox>
            </div>
            <div class="col-sm-6">
                <button mat-raised-button class="w-100" color="primary" (click)="onConfirm(appStatus.CANCELLED)">Cancel
                    App.</button>
            </div>
            <div class="col-sm-6">
                <button mat-raised-button class="w-100" color="secondary" (click)="onConfirm(appStatus.LNC)">LNC</button>
            </div>
        </div>
    </div>
</div>
