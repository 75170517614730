import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { Store } from '@ngrx/store';
import { buildNumber } from 'app/build-number';
import { AuthService } from 'app/services/auth.service';
import { VisualsService } from 'app/services/visuals.service';
import * as fromRoot from 'app/store/reducers';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
@Component({
    selector: 'app-user-button',
    templateUrl: './user-button.component.html',
    styleUrls: ['./user-button.component.scss']
})
export class UserButtonComponent implements OnInit{
    readonly buildNumber = buildNumber;
    public loggedIn: boolean;
    public loading: boolean = false;
    public userName: string = ""
    public staffDetails;
    public darkMode: boolean = false;
    

    constructor(private router: Router,
        private auth: AuthService,
        private visualService: VisualsService,
        private store: Store<fromRoot.State>) {
        this.store.select(fromRoot.getJWT).pipe(takeUntilDestroyed()).subscribe(res => {
            this.loggedIn = res && res !== "" ?  true : false
        })
        this.store.select(fromRoot.getBackgroundLoading).pipe(takeUntilDestroyed()).subscribe(res => {
            this.loading = res;
        });

        this.store.select(fromRoot.getAuthState).pipe(takeUntilDestroyed()).subscribe(res => {
            this.staffDetails = res.meta;
           
        });

        this.visualService.getDarkMode().pipe(takeUntilDestroyed()).subscribe(res=>{
            this.darkMode = res;
        })
        
    }

    ngOnInit(){
    }

    public logout(): void {
        this.router.navigate(['logout']);
    }

    public setDarkMode(event){
        console.log("[dark] set", this.darkMode);
        this.visualService.setDarkMode(this.darkMode);
    }
}
