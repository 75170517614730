import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GenericDialogComponent } from '../generic-dialog/generic-dialog.component';

@Component({
  selector: 'app-save-dialog',
  templateUrl: './save-dialog.component.html',
  styleUrls: ['../dialog-shared.scss']
})
export class SaveDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) 
    public data: any, 
    public dialogRef: MatDialogRef<GenericDialogComponent>, 
  ) { }

  ngOnInit(): void {
  }

  public onConfirm() : void {
    this.dialogRef.close(true);
  }

  public onDismiss() : void {
    this.dialogRef.close(false);
  }

  public onSave() : void {
    this.dialogRef.close('save')
  }

}
