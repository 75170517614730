<div class="wrapper">
    <div class="container"> 
        <h2 class="logo">Logout</h2>
        
        <div class="d-flex align-items-center justify-content-center w-100 flex-column pt-4">
            <div *ngIf="logoutComplete">You have successfully logged out.</div>
            <div *ngIf="!logoutComplete">Logging you out.</div>
            <div><button class="mt-5 btn-link" (click)="toLogin()">Back to Login</button></div>
        </div>
    </div>
</div>
    