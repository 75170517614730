import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';

@Injectable()

export class DataService {  

    constructor(private http: HttpClient) { }

    post<T>(url: string, payload: any = {}, options: { headers?: HttpHeaders | { [header: string]: string | string[] } } = {}): Observable < T > {
        return this.http.post<T>(`${environment.apiEndPoint}${url}`, payload, { headers: options.headers });
    }

    get<T>(url: string): Observable<T> {
        return this.http.get<T>(`${environment.apiEndPoint}${url}`);
    }

    put<T>(url: string, payload: any = {}): Observable<T> {
        return this.http.put<T>(`${environment.apiEndPoint}${url}`, payload);
    }

    delete(url: string, payload: any = {}): Observable<{}> {
        return this.http.delete(`${environment.apiEndPoint}${url}`, payload);
    }

    request(method: string, url: string, payload: any = {}): Observable<{}> {
        return this.http.request(method, `${environment.apiEndPoint}${url}`, payload)
    }



}
