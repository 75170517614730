import { Injectable } from "@angular/core";
import * as fromRoot from "../store/reducers";
import { Store } from "@ngrx/store";
import { skipWhile, take } from "rxjs/operators";
import { Observable } from "rxjs";
import { Room } from "../models/rooms";
import * as roomActions from "../store/actions/rooms"

@Injectable({
    providedIn: "root"
})
export class RoomsService {

    constructor(private store: Store<fromRoot.State>) {
        console.log("[room] roomservice");
    
    }
    
    

    getRooms(): Observable<Room[]> {
        return this.store.select(fromRoot.getRooms).pipe(
            skipWhile(val => val == null || val.length == 0),
            take(1));
    }

}
