<div class="row margin-collapse">
    <div class="col-lg-10">
        <form autocomplete="off" [formGroup]="stockForm">
            <mat-card><mat-card-content>
                <h2 class="mb-5 controller text-start mx-auto">Stock settings</h2>

                <div class="controllers">
                    <mat-form-field class="controller">
                        <mat-label>Sale Item</mat-label>
                        <mat-select formControlName="saleItem">
                          <mat-option *ngFor="let saleItem of saleItems" [value]="saleItem">
                            {{ saleItem.name }}
                          </mat-option>
                        </mat-select>

                        <button *ngIf="stockForm.value.saleItem" matSuffix mat-icon-button aria-label="Clear" (click)="clear('saleItem')">
                            <mat-icon>close</mat-icon>
                        </button>
                    </mat-form-field>
    
                    <div class="controller">Description: {{ stockForm.value.saleItem && stockForm.value.saleItem.description }} </div>
                    <div class="controller">Balance: {{ stockForm.value.saleItem && stockForm.value.saleItem.stock }} </div>
    
                    <mat-form-field class="controller">
                        <mat-label>New balance</mat-label>
                        <input class="right-align" type="number" matInput name="balance" placeholder="New balance" formControlName="balance">
                        <span matSuffix>.00</span>

                        <button *ngIf="stockForm.value.balance" matSuffix mat-icon-button aria-label="Clear" (click)="clear('balance')">
                            <mat-icon>close</mat-icon>
                        </button>
                    </mat-form-field>

                    <div class="alert alert-warning controller" *ngIf="stockForm.value.amendment && stockForm.value.balance">You are setting both the new balance and the amendment values. Please select only one option.</div>

                    <div class="row controller">
                        <button mat-raised-button class="col-1 operator" (click)="setAmendment('decrement')">-</button>

                        <mat-list class="col-1 p-0 list">
                            <mat-list-item class="listitem" [ngClass]="{'listitem-active': increment === 1}" (click)="increment = 1">1</mat-list-item>
                            <mat-list-item class="listitem" [ngClass]="{'listitem-active': increment === 10}" (click)="increment = 10">10</mat-list-item>
                            <mat-list-item class="listitem" [ngClass]="{'listitem-active': increment === 100}" (click)="increment = 100">100</mat-list-item>
                        </mat-list>

                        <mat-form-field class="col-8">
                            <mat-label>Amendment</mat-label>
                            <input matInput class="right-align amendment" type="number" name="amendment" placeholder="Amendment" formControlName="amendment">
                            <span matSuffix>.00</span>

                            <button *ngIf="stockForm.value.amendment" matSuffix mat-icon-button aria-label="Clear" (click)="clear('amendment')">
                                <mat-icon>close</mat-icon>
                            </button>
                        </mat-form-field>

                        <button mat-raised-button color="primary" class="col-1 operator" (click)="setAmendment('increment')">+</button>
                    </div>

                    <mat-form-field class="controller">
                        <mat-label>Reason</mat-label>
                        <input matInput name="reason" placeholder="Reason" formControlName="reason">

                        <button *ngIf="stockForm.value.reason" matSuffix mat-icon-button aria-label="Clear" (click)="clear('reason')">
                            <mat-icon>close</mat-icon>
                        </button>
                    </mat-form-field>

                    <div class="alert alert-danger controller mt-3" *ngIf="unchangedError">There is a problem with your provided values. Please be careful!</div>
                    <div class="alert alert-info controller mt-3" *ngIf="reasonMissingError">Please next time provide a reason for the balance change. It helps identify these changes in the future.</div>
                </div>
            </mat-card-content></mat-card>
        </form>
    </div>

    <div class="col-sm-2 mobile-till-margin">
        <div class="fix-top">
            <div class="desktop-buttons mobile-buttons">
                <div class="m3-3 text-center mobile-button">
                    <button mat-raised-button color="primary" class="w-100" (click)="save()">Update</button>
                </div>
                <div class="my-3 text-center mobile-button">
                    <button mat-raised-button class="w-100" (click)="back()">Back</button>
                </div>
            </div>
        </div>
    </div>
</div>
