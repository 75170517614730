import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { NotesService } from 'app/services/notes/notes.service';
import { SoapService } from 'app/services/notes/soap.service';

@Component({
  selector: 'app-diagnosis-card',
  templateUrl: './diagnosis-card.component.html',
  styleUrls: ['./diagnosis-card.component.scss']
})
export class DiagnosisCardComponent implements OnInit, OnChanges {
  public caseHistories: any[] = [];
  patientIdx: number = 0;
  diagnosisData: any;

  @Input() diagnosisIdx: number;
  @Input({ required: true }) notesIdx: number;

  constructor(private notesService: NotesService) { }

  ngOnInit() {
    this.getDiagnosisList();
  }

  updateDiagnosis() {
//    this.diagnosisIdx = diagnosisData.idx;
    if (!(this.diagnosisIdx>0)){
      if(this.caseHistories[0]){
        this.diagnosisIdx = this.caseHistories[0].idx;
      }else{
        return;
      }
    }
    
    this.notesService.setDiagnosis(this.notesIdx, this.diagnosisIdx).subscribe(res => {
      console.log(res);
      this.diagnosisData = res;
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.diagnosisIdx) {
      // The notesIdx input property has changed, you can trigger your method here
      this.updateDiagnosis();
      
    }
  }

  pickActiveDiagnosis(){
    
  }

  getDiagnosisList() {
    this.notesService.patientIdx().subscribe((patientIdx: number) => {
      if (patientIdx) {

        this.patientIdx = patientIdx;

        this.notesService.getDiagnosisList(this.patientIdx).subscribe(res => {
          this.caseHistories = res;
          if (!this.diagnosisIdx){
            this.updateDiagnosis();
          }
        })
      }
    });
  }

}
