import { UntypedFormGroup } from '@angular/forms';

export class ConfirmDialog {
    public title: string;
    public message: string;
    public args: any[];
    public componentName: string;
  
    constructor( title: string, message: string, componentName?: string, args?: any[] ){
      this.title = title;
      this.message = message;
      this.args = args;
      this.componentName = componentName;
    }
  }
  
  enum ReturnType {
    true = "true",
    false ="false",
    input ="input",
  }
  
  export interface DialogAction {
    name: string;
    returnType: ReturnType;
  }
  
  export const returnTypes = {
    TRUE: ReturnType.true,
    FALSE: ReturnType.false,
    INPUT: ReturnType.input
  }
  
  export class GenericInput {
    public title: string;
    public message: string;
    public confirmText: string[];
    public dismissText: string[];
    public warning: boolean;
    public warningMessage?: string;
  
    constructor(
      title: string,
      message: string,
      confirmText: string[],
      dismissText: string[],
      warning: boolean,
      warningMessage?: string
      ) {
        this.title = title;
        this.message = message;
        this.confirmText = confirmText;
        this.dismissText = dismissText;
        this.warning = warning;
        if (warning && warningMessage) {
          this.warningMessage = warningMessage;
        }
      }
  }
  
  export class FormDialogQuestion {
    public label: string;
    public id: string;
    public type: string;
    public formControlName: string;
    public placeholder: string;
    public options: FormOptions[]
    public value: any;
  
    constructor(
      label: string,
      id: string,
      type: string,
      options: FormOptions[],
      value?: any
    ) {
      this.label = label;
      this.id = id;
      this.type = type;
      this.formControlName = label;
      this.placeholder = label;
      this.options = options;
      this.value = value;
    }
  }
  
  export class FormOptions {
    public idx: any;
    public text: string;
  
    constructor( idx: any, text: string ) {
      this.idx = idx;
      this.text = text;
    }
  }
  
  export class FormInput {
    public title: string;
    public message: string;
    public formFields: FormFields[];
    public confirmText: string[];
    public dismissText: string[];
    public inputText: string[];
    public form: UntypedFormGroup;
  
    constructor(
      title: string,
      message: string,
      formFields: FormFields[],
      confirmText: string[],
      dismissText: string[],
      inputText: string[],
      form: UntypedFormGroup,
      ) {
        this.title = title;
        this.message = message;
        this.formFields = formFields;
        this.confirmText = confirmText;
        this.dismissText = dismissText;
        this.inputText = inputText;
        this.form = form;
      }
  }
  
  export interface FormFields {
    type: string;
    label: string;
    options: any[];
  }
  
  export class QRCodeDialog{
    public title: string;
    public qrData: string;
    
    constructor( title: string, qrData: string){
      this.title = title;
      this.qrData = qrData;
    }
  }
  
  