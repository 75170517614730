<div class="row margin-collapse">
  <div class="col-lg-10">
    <mat-card>
      <mat-card-content>
      <div *ngIf="errorMessage" class="alert alert-danger">
        {{errorMessage}}
      </div>
  <!--    <pre>{{editAppointmentService.patientIdx | json}}</pre> -->
      <div class="row">
        <div class="col-sm-6">
          <h4>{{isNewAppointment ? 'Create' : 'Edit'}} Appointment</h4>
        </div>
        <div class="col-sm-6 text-end pe-4">
          <div class="time-area">
            <mat-icon>schedule</mat-icon>
            {{editAppointmentService.startTime | date : 'shortTime'}} &nbsp;
            {{editAppointmentService.startTime | date : 'fullDate'}}
          </div>
        </div>
      </div>

      <div class="full-width patient-chooser-container " >
        
       
        <span class="mat-form-field-label-wrapper">
          <label class="mat-form-field-label ng-tns-c18-10 ng-star-inserted">Patient</label>
        </span>
        
        <patient-chooser [showLastAppointment]="true" [showNotes]="true" [allowAddNew]="true"
          [readOnly]="!isNewAppointment"></patient-chooser>
      
        
      </div>

      <ng-template [ngIf]="editAppointmentService.status !== AppointmentStatus.UNALLOCATED_SLOT">

      <div *ngIf="editAppointmentService.patientInfo && !editAppointmentService.isNotAvailableAppointment">
      
        <app-staff-chooser (click)="stopPropagation($event)" (valueChange)="selectStaffMember($event)" [ngModel]="editAppointmentService.staffIdx"
          class="show-full-width"></app-staff-chooser>
      
      
      </div>


      

      <div *ngIf="editAppointmentService.patientInfo && !editAppointmentService.isNotAvailableAppointment" class="row  mobile-column">
        <!-- <pre>Treatment = [{{editAppointmentService.treatmentIdx}}]</pre> -->

        <div *ngIf="changeCat" class="col-2 mobile-width">
          <mat-form-field class="full-width">
            <mat-label>Category</mat-label>
            <mat-select id="category" [(ngModel)]="editAppointmentService.categoryIdx" name="categoryIdx"
              (selectionChange)="updateTreatments($event)" required>
              <mat-option *ngFor="let treatment of editAppointmentService.allTreatments" [value]="treatment.idx">
                {{treatment.name}}
              </mat-option>
            </mat-select>
            <mat-error>Treatment type is required</mat-error>
          </mat-form-field>
        </div>


        <div [class]="changeCat ? 'col-8' : 'col-10'">
          <mat-form-field class="full-width">
            <mat-label>Treatment</mat-label>
            <mat-select id="treatment" [(ngModel)]="editAppointmentService.treatmentIdx" name="treatmentIdx" required>
              <mat-option *ngFor="let treatment of editAppointmentService.availableTreatments" [value]="treatment.idx">
                {{treatment.name}} ({{treatment.defaultPrice | currency: 'GBP'}})
              </mat-option>
            </mat-select>
            <mat-error>Treatment type is required</mat-error>
          </mat-form-field>
        </div>
        <div class="col-2 mobile-width">
          <button class="cat-button mobile-relative" mat-button color="secondary" (click)="toggleCats()">{{ changeCat ? 'Hide
            categories' : 'Show categories'}}</button>
        </div>
      </div>


      <div class="row" >
        <div class="col-sm-4">
          <mat-form-field>
            <mat-label>Duration</mat-label>
            <input matInput placeholder="Duration" type="number" max="720" name="duration"
              [(ngModel)]="editAppointmentService.duration" (change)="clashCheck()" required />
            <mat-error>Duration must be provided and no greater than 720.</mat-error>
          </mat-form-field>
        </div>

        <div *ngIf="editAppointmentService.patientInfo && !editAppointmentService.isNotAvailableAppointment"
          class="col-sm-8">
          <!--<pre>Room = {{editAppointmentService.roomIdx}}</pre>-->
          <mat-form-field class="full-width">
            <mat-label>Room</mat-label>
            <mat-select [(ngModel)]="editAppointmentService.roomIdx" name="roomIdx" [disabled]="fixRoom" required>
              <mat-option *ngFor="let room of editAppointmentService.availableRooms" [value]="room.idx">
                {{room.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </div>
    </ng-template> 
      <div *ngIf="editAppointmentService.patientInfo">
        <mat-form-field class="full-width">
          <mat-label>Notes</mat-label>
          <textarea name="notes" cdkTextareaAutosize cdkAutosizeMinRows="1" cdkAutosizeMaxRows="6"
            [(ngModel)]="editAppointmentService.notes" matInput></textarea>
        </mat-form-field>
      </div>

      <div class="row" *ngIf="editAppointmentService.patientInfo && !editAppointmentService.isNotAvailableAppointment">
        <div class="col-sm-2 pt-3">
          <h6>Reminders</h6>
        </div>

        <div class="col-sm-5 pe-4 no-right-padding">
          <div class="d-inline-flex w-100">
            <mat-form-field class="w-100">
              <mat-label>Phone</mat-label>
              <input type="phonenumber" autocomplete="off" name="sms" [(ngModel)]="editAppointmentService.patientPhone"
                matInput />
            </mat-form-field>
            <div class="pt-3">
              <mat-checkbox name="smsEnabled" [(ngModel)]="editAppointmentService.smsReminderEnabled"></mat-checkbox>
            </div>

          </div>
        </div>

        <div class="col-sm-5 ps-4 mobile-padding">
          <div class="d-inline-flex w-100 justify-content-middle">
            <mat-form-field class="w-100">
              <mat-label>Email</mat-label>
              <input autocomplete="off" type="email" name="email" [(ngModel)]="editAppointmentService.patientEmail"
                matInput />
            </mat-form-field>
            <div class="pt-3">
              <mat-checkbox name="emailEnabled" [(ngModel)]="editAppointmentService.emailReminderEnabled">
              </mat-checkbox>
            </div>
          </div>
        </div>

      </div>



  
    </mat-card-content></mat-card>
    <!--
  <div *ngFor="let message of editAppointmentService.clashMessages" class="alert alert-warning">
    {{message}}
  </div>
-->
  </div>
  <div class="col-sm-2">
    <div class="fix-top mobile-footer">
      <ul class="list-sections" *ngIf='isNewAppointment'>
        <li>
          <button class="btn btn-link" (click)="addNewPatient()">
            Add new patient
          </button>
        </li>
        <li class="border-top text-muted mt-2">None appointments:</li>
        <li *ngFor="let case of editAppointmentService.diarySpecialApps">
          <button class="btn btn-link" (click)="setNotAvailable(case)">
            {{case.name}}
          </button>
        </li>
      </ul>
      <div class="desktop-buttons">
        <div class="action-buttons" *ngIf="editAppointmentService.status === AppointmentStatus.PENDING_APPROVAL">
          <button mat-raised-button color="primary" (click)="save(AppointmentStatus.BOOKING)">Accept</button>
          <button mat-raised-button color="warn" (click)="save(AppointmentStatus.CANCELLED)">Reject</button>
        </div>
        
        <app-submit-cancel [saving]="editAppointmentService.saving" (onSave)="save()" (onCancel)="cancel()">
        </app-submit-cancel>
      </div>


      <div class="mt-5 w-100">
        <button mat-raised-button color="primary" class="w-100" (click)="save(null, true)">Save and Email</button>
      </div>
    </div>
  </div>

</div>