import { Component, OnInit } from '@angular/core';
import { FormTemplateBaseComponent } from '../form-template-base/form-template-base.component';

@Component({
  selector: 'app-form-info-box',
  templateUrl: './form-info-box.component.html',
  styleUrls: ['./form-info-box.component.css']
})
export class FormInfoBoxComponent extends FormTemplateBaseComponent {


}
