<div class="container-fluid" >
    <div class="row margin-collapse">
      <div class="col-lg-10">
        <mat-card class="loading-card" *ngIf="loadingQuestionnaire">
          <mat-card-content>
          <mat-progress-spinner mode="indeterminate" [diameter]="50"></mat-progress-spinner>
        </mat-card-content></mat-card>
  
        <mat-card [ngClass]="{'loading': loadingQuestionnaire}">
          <mat-card-content>
            <h2>Patient Questionnaire</h2>
            <br>
            <dynamic-form *ngFor="let form of formsList" 
            [id]="form" 
            (touched)="formTouched()" 
            (loadComplete)="loadComplete()" 
            [multiple]="true" 
            [formName]="form" 
            [submitting]="submitting" 
            [checking]="checking" 
            [navigate]="navigate" 
            (checkComplete)="checkComplete()" 
            (saveComplete)="saveComplete(form)" 
            [searchOn]="searchOn"></dynamic-form>  
                     
        </mat-card-content></mat-card>
      </div>
      <div class="col-lg-2 mobile-margin">
        <div class="fix-top">
          <div class="desktop-buttons">
            <ul class="list-sections">
              <button class="btn btn-link" (click)="scroll('notes_employmentdetails')">
                <li>Employment</li>
              </button>
              <button class="btn btn-link" (click)="scroll('notes_medicationdetails')">
                <li>Medication</li>
              </button>
              <button class="btn btn-link" (click)="scroll('notes_medicalhistorydetails')">
                <li>Medical History</li>
              </button> 
              <button class="btn btn-link" (click)="scroll('notes_healthdetails')">
                <li>General Medical Questions</li>
              </button>
              <button class="btn btn-link" (click)="scroll('notes_familyhistory')">
                <li>Family History</li>
              </button>
              <button class="btn btn-link" (click)="scroll('notes_pain')">
                <li>Pain</li>
              </button>
              <button class="btn btn-link" (click)="scroll('notes_femalesdetails')">
                <li>Females</li>
              </button>
              <button class="btn btn-link" (click)="scroll('notes_sleephabits')">
                <li>Sleep Habits</li>
              </button> 
              <button class="btn btn-link" (click)="scroll('notes_osteoporosis')">
                <li>Osteoporosis</li>
              </button> 
            </ul>
            <app-submit-cancel (onCancel)="cancel()" (onSave)="saveForm()"></app-submit-cancel>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  
      
  <!-- 
    <app-submit-cancel (onSave)="saveForm()"></app-submit-cancel> -->
  