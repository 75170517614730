import { QuestionBase, fieldType } from './question-base';

export class DropdownQuestion extends QuestionBase<string> {
  controlType = fieldType.DROPDOWN;


  constructor(options: any = {}) {
    super(options);
    //if only one option and nothing selected then assume it's this    
    if (options.options.length == 1 && !options.value) {
      this.value = ""+options.options[0].idx;
    }
  }
}
