import { Component, OnInit } from '@angular/core';
import { VisualsService } from '../../services/visuals.service';

@Component({
  selector: 'app-settings',
  templateUrl: './ui-settings.component.html',
  styleUrls: ['./ui-settings.component.scss']
})
export class UISettingsComponent implements OnInit {
  public isNewStyleChecked: boolean;

  constructor(public visual: VisualsService) { }

  ngOnInit() {
    this.visual.getIconsStyle().subscribe(res => {this.isNewStyleChecked = res});
  }
  public sliderToggled() {
    this.visual.setIconStyle(this.isNewStyleChecked);
}
}
