import * as menuAction from '../actions/menu';

import { State, initialState } from '../../models/menu'
import { ActionWithPayload } from '.';
import { SubMenuItem } from 'app/models/menu';

export { State, initialState }
export function reducer(state = initialState, action: ActionWithPayload): State {
  const flatten = (arr) => {
    let flatArray = []
    arr.forEach(a => {
      if (a.subItems) {
        flatArray.push(a)

        a.subItems.forEach(b => { flatArray.push(b) })
      }
      else {
        flatArray.push(a)

      }
    })
    return flatArray
  }

  
  switch (action.type) {

    case menuAction.ActionTypes.Loaded: {
      console.log("[menu] Loaded: ", action.payload);
      return Object.assign({}, state, {
        items: [{
          title: "Home",
          link: "/home",
        }, ...action.payload],
        filterItems: [{
          title: "Home",
          link: "/home",
        }, ...flatten(action.payload)]
      });
    }
    case menuAction.ActionTypes.FilterMenu: {

      return Object.assign({}, state, {
        filterItems: [...flatten(state.items).filter(item => {
          if (
            item.title
              .toLowerCase()
              .indexOf(action.payload.toLowerCase()) != -1 || !item.link
          ) {
            return true;
          } else {
            return false;
          }
        })]
      });
    }

    case menuAction.ActionTypes.ScorePlusOne: {
      //locate sub menu
      if (!action.payload) {
        return state;
      }
      const updatedMenu = state.items.map(menu => {
        if (menu.subItems) {
          const foundIndex = menu.subItems.findIndex(f => f.link == action.payload.link);
          if (foundIndex >= 0) {
            return {
              ...menu, subItems: menu.subItems.map(subItem => {
                if (subItem.link == action.payload.link) {
                  return { ...subItem, score: (subItem.score || 0) + 1 }; //this one add one point
                } else {
                  return { ...subItem, score: ((subItem.score || 0) * 0.9).toFixed(2) }; //not this one lose 10%
                }
              })
            }
          } else {
            return menu; //has sub items but not in this list
          }
        } else {
          return menu; //no sub-items
        }
      });

      //console.log("[menu] new = ", updatedMenu);
      return Object.assign({}, state, { items: updatedMenu });
      //return state;
    }

    case menuAction.ActionTypes.ToggleFavourite: {
      const updatedMenu = state.items.map(menu => {
        if (menu.subItems) {
          return {
            ...menu, subItems: menu.subItems.map(subItem => {
              if (subItem.link == action.payload.link) {
                return { ...subItem, favourite: !subItem.favourite }; //this one add one point
              } else {
                return subItem;
              }
            })
          }
        } else {
          return menu; //no sub-items
        }
      });

      //console.log("[menu] new = ", updatedMenu);
      return Object.assign({}, state, { items: updatedMenu });
      //return state;
    }


    default: {
      return state;
    }

  }
}
export const getMenu = (state: State) => state.items;
export const getFilteredMenu = (state: State) => state.filterItems;


