import { Component, OnInit } from '@angular/core';
import { NotesBuilderBaseComponent } from '../../notes-builder/notes-builder-base/notes-builder-base-component';


@Component({
  selector: 'app-drawing-extremities',
  templateUrl: './drawing-extremities.component.html',
  styleUrls: ['./drawing-extremities.component.scss']
})
export class DrawingExtremitiesComponent extends NotesBuilderBaseComponent{

}
