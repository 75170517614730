import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormTemplateBaseComponent } from '../form-template-base/form-template-base.component';

@Component({
  selector: 'app-form-hidden',
  templateUrl: './form-hidden.component.html',
  styleUrls: ['./form-hidden.component.css']
})
export class FormHiddenComponent extends FormTemplateBaseComponent implements OnChanges{

   
    ngOnChanges(changes: SimpleChanges){
      console.log("[change]", changes);
    }

    handleChange(question: any, input: any) {
      question.value = input.value;
      console.log(input);
    }

}
