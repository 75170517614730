import { Component, EventEmitter, Input, OnInit, Output, SimpleChange } from '@angular/core';
import { NotesBuilderBaseComponent } from 'app/notes/notes-builder/notes-builder-base/notes-builder-base-component';

@Component({
  selector: 'app-drawing-thoracic',
  templateUrl: './drawing-thoracic.component.html',
  styleUrls: ['./drawing-thoracic.component.css']
})
export class DrawingThoracicComponent extends NotesBuilderBaseComponent {
 
}