import { Action } from '@ngrx/store';
import { Room } from '../../models/rooms';
import { type } from '../util';

export const SET = 'ROOMS_SET';
export const INIT = 'ROOMS_INIT';

export const ActionTypes = {
    SET: type('[RoomsState] Set'),
    INIT: type('[RoomsState] Init'),
};


/**
 * Every action is comprised of at least a type and an optional
 * payload. Expressing actions as classes enables powerful 
 * type checking in reducer functions.
 * 
 * See Discriminated Unions: https://www.typescriptlang.org/docs/handbook/advanced-types.html#discriminated-unions
 */
export class SetAction implements Action {
    type = ActionTypes.SET;
    constructor(public payload: Room[]) { }
}

export class InitAction implements Action {
    type = ActionTypes.INIT;
    constructor() { }
}

/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
export type Actions
    = SetAction |
    InitAction;
