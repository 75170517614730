<h1 mat-dialog-title>
    {{data.title}}
  </h1>
  <div mat-dialog-content>
    <p>{{data.message}}</p>
    <mat-form-field>
        <mat-label>{{data.message}}</mat-label>
        <input matInput [(ngModel)]="promptResponse">
      </mat-form-field>
  </div>
  
  <div *ngIf="invalid" class="alert alert-danger">
    Required: {{data.message}}
  </div>
  <div mat-dialog-actions>
    <button mat-button (click)="onDismiss()">Cancel</button>
    <button mat-raised-button color="primary" (click)="onConfirm()">Submit</button>
  </div>
  

      
   
      