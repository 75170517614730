export const CaseSummaryQuestions: { label: string, field: string, textbox?: boolean }[] = [
   {label: "Chronic low back pain (pain > 3 months)", field: "chronic_low_back" },
   {label: "Chronic low back pain + referred leg pain", field: "chronic_low_back_ref" },
   {label: "Chronic low back pain + radiculopathy", field: "chronic_low_back_rad"  },
   {label: "Acute low back pain (pain < 3 months)", field: "acute_low_back"  },
   {label: "Acute low back pain + referred leg pain", field: "acute_low_back_ref"  },
   {label: "Acute low back pain + radiculopathy", field: "acute_low_back_rad"  },
   {label: "Acute Sacroiliac Syndrome", field: "acute_sacro_syn"  },
   {label: "Chronic Sacroiliac Syndrome", field: "chronic_sacro_syn"  },
   {label: "Acute Facet Syndrome", field: "acute_facet_syn"  },
   {label: "Chronic Facet Syndrome", field: "chronic_facet_syn"  },
   {label: "Chronic thoracic pain (pain > 3 months)", field: "chronic_thoratic" },
   {label: "Chronic thoracic pain + referred", field: "chronic_thoratic_ref" },
   {label: "Chronic thoracic pain + radiculopathy", field: "chronic_thoratic_rad" },
   {label: "Acute thoracic pain (pain < 3 months)", field: "acute_thoratic" },
   {label: "Acute thoracic pain + referred", field: "acute_thoratic_ref" },
   {label: "Acute thoracic pain + radiculopathy", field: "acute_thoratic_rad" },
   {label: "Chronic neck pain (pain > 3 months)", field: "chronic_neck" },
   {label: "Chronic neck pain + referred leg pain", field: "chronic_neck_ref" },
   {label: "Chronic neck pain + radiculopathy", field: "chronic_neck_rad" },
   {label: "Acute neck pain (pain < 3 months)", field: "acute_neck" },
   {label: "Acute neck pain + referred pain", field: "acute_neck_ref" },
   {label: "Acute neck pain + radiculopathy", field: "acute_neck_rad" },
   {label: "Cervicogenic headache", field: "cervicogenic" },
   {label: "Tension type headache", field: "tension_type" },
   {label: "Migraine headache", field: "migraine" },
   {label: "Extremity", field: "extremity", textbox: true },
   {label: "Sports Injury", field: "sports_injury", textbox: true },
   {label: "Non specific back pain", field: "ns_back_pain" },
   {label: "Non specific neck pain", field: "ns_neck_pain" },
   {label: "Other MSK", field: "other_msk", textbox: true },
   {label: "Pelvic Pain (pregnancy related)", field: "pelvic_pregnancy" },
   {label: "Comorbidity", field: "comorbidity", textbox: true },
   {label: "Rheumatology", field: "rheumatology", textbox: true },
   {label: "TMJ", field: "tmj" }
];
