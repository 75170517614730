<mat-toolbar class="navbar-header">

  <div fxShow="true" fxHide.gt-sm="true" class="mobile-icon" [ngClass]="sidenav.opened ? 'mobile-icon--opened' : 'mobile-icon--closed'">
    <button mat-icon-button (click)="sidenav.toggle()">
      <mat-icon>menu</mat-icon>
    </button>
  </div>

  <img [src]="clientLogo" class="d-none d-md-block main-logo" alt="" />

  <div class="flex-spacer"></div>
  <ng-template ngFor let-menuItem [ngForOf]="newItems">
    <sub-menu class="toolbar-menu" [menuItem]="menuItem"></sub-menu>
  </ng-template>
  <div class="flex-spacer "></div>

  <mat-autocomplete #menuQuickFind="matAutocomplete" (optionSelected)="quickNavigate($event)">
    <mat-option *ngFor="let item of filteredFlatItems | async" 
      [value]="item">
      {{item.title}}
    </mat-option>
  </mat-autocomplete>

  <div class="search-container  d-flex" *ngIf='newItems'>
    <div>
    <input type="text"
       class="search-input"
       placeholder="Search..."
       aria-label="Menu find"
       name="menuSearch"
       [formControl]="menuQuickFindInput"
       [matAutocomplete]="menuQuickFind">
    </div>
    <div class="icon-container">
       <mat-icon matListIcon class="search-icon">search</mat-icon>   
    </div>
  </div>

  <div>
    <app-user-button></app-user-button>
  </div>

</mat-toolbar>

<div (click)="sidenav.toggle()" [ngClass]="sidenav.opened ? 'background-overlay background-overlay--open' : 'background-overlay'"></div>

<div class="sidenav-container">
  <mat-sidenav color="primary" #sidenav fxLayout="column" mode="over"  opened="false" fxHide.gt-sm="true">
    <div fxLayout="column">
        <app-side-menu (closeMenu)="sidenav.toggle()"></app-side-menu>
    </div>
  </mat-sidenav>
</div>