import { Injectable } from '@angular/core';
import {
    Actions,
    createEffect,
    ofType
} from '@ngrx/effects';
import { Action } from '@ngrx/store';

import { mergeMap, map } from 'rxjs/operators';
import * as treatmentsActions from '../actions/treatments';
import { DataService } from '../../services/data.service';
import { type TreatmentGroup } from '../../models/treatments';


@Injectable()
export class TreatmentsEffects {
    constructor(private http: DataService, private actions$: Actions, ) { }

    // ngrxOnInitEffects(): Action {
    //     console.log("STAFF Init effect has happened");
    //     return { type: treatmentsActions.ActionTypes.INIT };
    // }


    initTreatments$ =  createEffect(() => {
        return this.actions$
            .pipe(
                ofType(treatmentsActions.ActionTypes.INIT),
                mergeMap(() => this.http.get<TreatmentGroup[]>('/treatments')
                    .pipe(
                        map(tg => (new treatmentsActions.SetAction(tg)))
                ))
            );
        });
}
