<div class="container-fluid" *ngIf="info">
  <mat-tab-group mat-stretch-tabs [(selectedIndex)]="selectedTab" (selectedTabChange)="onTabChanged($event)">
    <mat-tab *ngFor="let tab of noteTabs" >
      <ng-template mat-tab-label>
        <span 
          [matBadge]="tab.recordCount" 
          matBadgeSize="small"
          matBadgeColor="primary"
          matBadgeOverlap="false">{{ tab.title }}</span>
      </ng-template>

      <div [ngSwitch]="tab.component">
        <ng-template [ngSwitchCase]="NotesTabComponents.PATIENT_DETAILS" *ngIf="info.details">
          <info-patient-details 
            [info]="info.details"
            [visits]="info.visitInfo"
            [patientId]="patientIdx">
          </info-patient-details>
        </ng-template>

        <ng-template [ngSwitchCase]="NotesTabComponents.ATTENDANCE">
          <div class="container mb-2" *ngIf="info.attendance">
            <my-attendance-graph [attendance]="info.attendance"></my-attendance-graph>
          </div>
          <info-attendance [info]="info.attendance" *ngIf="info.attendance"></info-attendance>
        </ng-template>
    
        <ng-template [ngSwitchCase]="NotesTabComponents.NOTES_TABLE">
          <info-notes-table  
            *ngIf="info.notes"
            [info]="info.notes[tab.name]" 
            [formName]="tab.formName" 
            [formFriendlyName]="tab.title" 
            (loadNotes)="loadNotes($event, tab.name)">
          </info-notes-table>
        </ng-template>
        
        
        <ng-template [ngSwitchCase]="NotesTabComponents.TELEPHONE">
          <app-telephone-consultation  
            (loadNotes)="loadNotes($event, tab.name)">
          </app-telephone-consultation>
        </ng-template>

        <ng-template [ngSwitchCase]="NotesTabComponents.UPLOADER">
          <app-uploader [patientIdx]='patientIdx'></app-uploader>
        </ng-template>
      </div>
      </mat-tab>
  </mat-tab-group>
</div>
