export class GiftCard {
    txIdx?: number;
    code: string;
    clientIdx: number;
    value: number;
    issueDate: Date;
    expiryDate: Date;
    empty: boolean;
    usedDates: any[]

    constructor(
        code: string,
        clientIdx: number,
        value: number,
        issueDate: Date,
        expiryDate: Date,
        )
        {
            this.code = code;
            this.clientIdx = clientIdx;
            this.value = value;
            this.issueDate = issueDate;
            this.expiryDate = expiryDate;
            this.empty = false;
            this.usedDates = []
        }

    public use(value: number){
        this.value -= value
        this.usedDates.push(new Date())
    }

    public addTxId(txId: number){
        this.txIdx = txId
    }


}