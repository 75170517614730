import { Component, OnInit, EventEmitter, Output, Input, SimpleChanges, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AuthService } from 'app/services/auth.service';
import { NotificationService } from 'app/services/notification.service';
import { State } from 'app/store/reducers';
import { getCurrentPatient } from 'app/store/reducers';
import { FilesService } from './files.service';
import { UploadFilePickerAdapter } from './upload-file-picker-adapter';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { FilePreviewModel } from './lib/file-preview.model';
import { DomSanitizer } from '@angular/platform-browser';
import { AvailablePermissions } from 'app/models/auth';

export interface UploadedFile {
  idx?: number;
  fileId?: string;
  name: string;
  dateAdded: Date;
  progress?: number;
  error?: boolean;
}
@Component({
  selector: 'app-uploader',
  templateUrl: './uploader.component.html',
  styleUrls: ['./uploader.component.scss']
})
export class UploaderComponent implements OnInit {
  @Input("fromForm") fromForm = false;
  @Input("fromNotes") fromNotes = false;
  @Input('serverStorageLocation') serverStorageLocation: string;
  @Input('returnFileData') returnFileData = "";
  @Input('patientIdx') patientIdx: number;
  @Input('currentFile') currentFile: string;
  @Input('type') fileType: string; // could be CSV
  @Input("accepted") acceptedFileTypes: Array<string>;
  @Output() uploadState = new EventEmitter<string>();
  @Output('fileData') fileData = new EventEmitter<any>();

  @ViewChild(MatSort) sort: MatSort;

  formData: FormData;

  _filesFromServer: UploadedFile[];

  humanizeBytes: Function;
  dragOver: boolean;

  filesInQueue: UploadedFile[] = [];
  dataSource: MatTableDataSource<UploadedFile>;
  displayedColumns: string[] = ['name', 'dateAdded', 'download'];
  private expectedFiles: string[] = [];
  allowUpload: boolean = false;
  adapter = new UploadFilePickerAdapter(this.http);

  constructor(
    private service: FilesService,
    private router: Router,
    private store: Store<State>,
    private route: ActivatedRoute,
    private http: HttpClient,
    public authService: AuthService,
    private sanitization: DomSanitizer
  ) {

  }

  ngOnInit() {

    this.route.params.subscribe(res => {
      if (res.page && res.page === 'uploads') {


        this.store.select(getCurrentPatient).subscribe(res => {
          console.log("[files] patient", res);
          if (res) {
            this.patientIdx = res.idx;
            this.adapter.setPatientIdx(this.patientIdx);
          }
          this.service.getFiles(this.patientIdx).subscribe(res => {
            this.getFiles();
            this.filesFromServer = res;
          });
        });
      
        
      }
      this.adapter.setPublic(this.fromForm);
      this.allowUpload = this.authService.permission(AvailablePermissions.allow_upload);
    });

  }

  getExistingFileUrl(){
    return this.sanitization.bypassSecurityTrustStyle(`url('${this.currentFile}')`);
  }  
  
  getFiles() {
    this.service.getFiles(this.patientIdx).subscribe(res => {
      console.log("[files] GOT FILES for ", res);
      this.filesFromServer = res;
    });
  }


  ngOnChanges(changes: SimpleChanges): void {
    this.adapter.setPatientIdx(this.patientIdx);
    if (changes.patientIdx && this.patientIdx > 0) {
      this.adapter.setPatientIdx(this.patientIdx);
      this.getFiles();
    }
  }

  ngOnChange() { }

  set filesFromServer(files) {
    this._filesFromServer = files;
    this.dataSource = new MatTableDataSource(files);
    this.dataSource.sort = this.sort;
  }

  cancel() {
    this.router.navigate(['notes', this.patientIdx, 'patientquestionaire']);
  }
  
  uploadSuccess(response: FilePreviewModel){
    console.log("[file] ok", response);
    this.updateAssetList();
    this.filesFromServer = response.uploadResponse.files;
    this.fileData.emit(response.uploadResponse.upload);
  }

  uploadFail(err: HttpErrorResponse){
    console.log("[file] error", err);
  }


  updateAssetList() {
    // emit a upload complete event
  }

  download(file) {
    this.service.getFile(file.fileId);
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }


}
