export const MedicalHistoryFields: { label: string, field: string }[] =
[{
    label: "Any previous operations (date)",
    field: "operation"
},
{
    label: "Other previous hospitalisation (date)",
    field: "hospitalise"
},
{
    label: "Broken bones (date)",
    field: "brokenbones"
},
{
    label: "Any previous road traffic or other accidents",
    field: "accident"
},
{
    label: "Any previous xrays (date)",
    field: "xrays"
},
{
    label: "Any non-medical problems",
    field: "nonmedical"
}
];

