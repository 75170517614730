<form autocomplete="off" #formEle="ngForm">
    <mat-form-field>
        <mat-label>Has the patient ever had a fracture of the spine detected on an X-ray?</mat-label>
        <mat-select [(ngModel)]="form.fracture_of_the_spine" name="fracture_of_the_spine">
            <mat-option [value]="'yes'">Yes</mat-option>
            <mat-option [value]="'no'">No</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field>
        <mat-label>Low trauma fracture</mat-label>
        <mat-select [(ngModel)]="form.low_trauma_fracture" name="low_trauma_fracture">
            <mat-option [value]="'yes'">Yes</mat-option>
            <mat-option [value]="'no'">No</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field>
        <mat-label>First degree relative with osteoporosis</mat-label>
        <mat-select [(ngModel)]="form.first_degree_relative" name="first_degree_relative">
            <mat-option [value]="'yes'">Yes</mat-option>
            <mat-option [value]="'no'">No</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field>
        <mat-label>History of taking long term oral corticosteroids</mat-label>
        <mat-select [(ngModel)]="form.history_of_corticosteroids" name="history_of_corticosteroids">
            <mat-option [value]="'yes'">Yes</mat-option>
            <mat-option [value]="'no'">No</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field>
        <mat-label>Low Bone Density</mat-label>
        <mat-select [(ngModel)]="form.low_bone_density" name="low_bone_density">
            <mat-option [value]="'yes'">Yes</mat-option>
            <mat-option [value]="'no'">No</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field>
        <mat-label>Transplant organ</mat-label>
        <mat-select [(ngModel)]="form.transplant_organ" name="transplant_organ">
            <mat-option [value]="'yes'">Yes</mat-option>
            <mat-option [value]="'no'">No</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field>
        <mat-label>Alcohol intake > 3 units/day</mat-label>
        <mat-select [(ngModel)]="form.alcohol_more_than_three" name="alcohol_more_than_three">
            <mat-option [value]="'yes'">Yes</mat-option>
            <mat-option [value]="'no'">No</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field>
        <mat-label>Premature menopause (&lt;45 yrs)</mat-label>
        <mat-select [(ngModel)]="form.premature_menopause" name="premature_menopause">
            <mat-option [value]="'yes'">Yes</mat-option>
            <mat-option [value]="'no'">No</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field>
        <mat-label>History of unexplained amenorrhoea (&gt;6 months)</mat-label>
        <mat-select [(ngModel)]="form.history_of_amenorrhoea" name="history_of_amenorrhoea">
            <mat-option [value]="'yes'">Yes</mat-option>
            <mat-option [value]="'no'">No</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field>
        <mat-label>Chronic disorders associated with osteoporosis (e.g. Coeliac disease, anorexia nervosa)</mat-label>
        <mat-select [(ngModel)]="form.chronic_osteoporosis" name="chronic_osteoporosis">
            <mat-option [value]="'yes'">Yes</mat-option>
            <mat-option [value]="'no'">No</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field>
        <mat-label>Rheumatoid arthritis</mat-label>
        <mat-select [(ngModel)]="form.rheumatoid_arthritis" name="rheumatoid_arthritis">
            <mat-option [value]="'yes'">Yes</mat-option>
            <mat-option [value]="'no'">No</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field>
        <mat-label>Treatment with Aromatase Inhibitors (currently or previously)</mat-label>
        <mat-select [(ngModel)]="form.aromatase_inhibitors" name="aromatase_inhibitors">
            <mat-option [value]="'yes'">Yes</mat-option>
            <mat-option [value]="'no'">No</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field>
        <mat-label>Treatment with Androgen Deprivation Therapy</mat-label>
        <mat-select [(ngModel)]="form.androgen_deprivation" name="androgen_deprivation">
            <mat-option [value]="'yes'">Yes</mat-option>
            <mat-option [value]="'no'">No</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field>
        <mat-label>BMI &lt;19kg/m2</mat-label>
        <mat-select [(ngModel)]="form.bmi_less_than" name="bmi_less_than">
            <mat-option [value]="'yes'">Yes</mat-option>
            <mat-option [value]="'no'">No</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field>
        <mat-label>Kyphosis/Loss of height > 2cm</mat-label>
        <mat-select [(ngModel)]="form.kyphosis" name="kyphosis">
            <mat-option [value]="'yes'">Yes</mat-option>
            <mat-option [value]="'no'">No</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field>
        <mat-label>History of falls (50+ yrs old) (2 falls in pas 12 months)</mat-label>
        <mat-select [(ngModel)]="form.history_of_falls" name="history_of_falls">
            <mat-option [value]="'yes'">Yes</mat-option>
            <mat-option [value]="'no'">No</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field>
        <mat-label>Smoker (50+ yrs old)</mat-label>
        <mat-select [(ngModel)]="form.smoker" name="smoker">
            <mat-option [value]="'yes'">Yes</mat-option>
            <mat-option [value]="'no'">No</mat-option>
        </mat-select>
    </mat-form-field>
    <hr/>
    <div class="row">
        <div class="col-sm-12">
            <div class="checkbox">
                <mat-checkbox name="risk_factors_identified" [(ngModel)]="form.risk_factors_identified">
                    Risk Factors Identified?
                </mat-checkbox>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12">
            <!-- Only show if checkbox ticked -->
            <mat-form-field *ngIf="form.risk_factors_identified" class="w-100">
                <mat-label>Risk Factors</mat-label>
                <textarea  
                    matInput 
                    cdkTextareaAutosize
                    cdkAutosizeMinRows="3"
                    cdkAutosizeMaxRows="12"
                    name="risk_factors" 
                    [(ngModel)]="form.risk_factors"></textarea>
            </mat-form-field>
        </div>
    </div>
</form>