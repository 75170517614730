import { Injectable } from '@angular/core';
import * as fromRoot from '../../store/reducers';
import { Store } from '@ngrx/store';
import { DataService } from '../../services/data.service';
import { filter, switchMap, take } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { PatientChooserService } from '../patient.service';
import { type Attendance, type PatientNotesInfo } from 'app/models/patient-details';


@Injectable()
export class PatientInfoBoxService {
    constructor(private store: Store<fromRoot.State>,
      private http: DataService,
      private patientInfo: PatientChooserService) {}

  getPatientDetails() {
    return this.getPatientIdx().pipe(switchMap(res=>{
      return this.patientInfo.getPatientDetails(res.idx);

    }));
  }

  getPatientIdx() {
    return this.store.select(fromRoot.getCurrentPatient).pipe(filter(f => f && f.idx && f.idx > 0));
  }

  saveData(notesIdx: number, patientIdx: number, newSoap): Observable<any> {
    const payload = { soap: newSoap };
    return this.http.post<any>(`/notes/soap/${notesIdx}/${patientIdx}`, payload)
}

  addNewNotes(patientIdx: number, notesType: string, description: string) {
    const payload = {
      patientIdx: patientIdx,
      notesType: notesType,
      description: description,
      type: 'Adult',
      action: 'add'
    };
    return this.http.post<any>('/notes', payload);
  }

  /**
   * When patient changes send the notes data
   * @returns Observable of PatentNotesInfo
   */
  getNotesSummary(): Observable<PatientNotesInfo> {
    return this.getPatientIdx().pipe(switchMap(res=>{
      return this.patientInfo.getPatientInfoNotes(res.idx);
    }));
  }

  getAttendance(): Observable<Attendance[]> {
    return this.getPatientIdx().pipe(switchMap(res=>{
      return this.patientInfo.getAttendance(res.idx);
    }));
  }
}


