import {
  Component,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  SimpleChanges,
  ChangeDetectorRef,
  OnInit,
  OnChanges,
} from "@angular/core";

import { NG_VALUE_ACCESSOR, NgModel, UntypedFormControl, FormControl } from "@angular/forms";
import {
  ValueAccessorBase,
  ControlValueAccessor,
} from "../value-accessor-base";


import { Observable, Subscription } from "rxjs";
import { MatAutocomplete } from "@angular/material/autocomplete";
import { DynamicDropdownOption } from "app/dynamic-form/services/dynamic-autocomplete.service";
import { AddressListItem, AddressLookupService, AddressPlace } from "app/services/address-lookup.service";
import { filter, map, switchMap } from "rxjs/operators";



@Component({
  selector: "address-chooser",
  templateUrl: "./address-chooser.component.html",
  styleUrls: ["./address-chooser.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: AddressChooserComponent,
      multi: true,
    }]
})
export class AddressChooserComponent extends ValueAccessorBase<string> implements OnInit, OnChanges {
  @Input() question: any;
  @ViewChild('inputRef', { static: true }) inputRef: ElementRef<HTMLInputElement>;

  @ViewChild('auto') auto: MatAutocomplete;

  private allOptions: AddressListItem[] = [];
  public addressResults: Observable<AddressListItem[]>;
  public iValue: string;

  resultsListPos: any;
  @Output() valueChange = new EventEmitter<AddressPlace>();
  @Input() externalValue;

  public autoCompleteTextInput = new FormControl("");

  constructor(
    private dataService: AddressLookupService
  ) {
    super();
  }

  ngOnInit(){}

  ngAfterViewInit() {
    this.addressResults = this.autoCompleteTextInput.valueChanges.pipe(
      filter( value => value && value.length > 4),
      switchMap(value => {
        return this.dataService.search(value);
      })
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['externalValue']) {
      console.log("[choose a] change from external value ", this.externalValue);
      this.setValue(this.externalValue);
    }
  }


  setAutoCompleteValue(value): void {
    console.log("[choose a] make it say", value);

    setTimeout(() => {
      this.autoCompleteTextInput.setValue(value);
    }, 5);
  }

  displayFn(data: any): string {
    if (data && data.Text){
      return data.Text
    }
    return data ? data : '';
  }


  setValue(v) {
    console.log(`[choose a] will update and choose ${v} eeek`);
    this.setAutoCompleteValue(v);
  }

  updateValue($event) {
 //   const address = $event.option.value.split(", ");
    this.dataService.getPlace($event.option.value.PlaceId).subscribe(res=>{
        console.log("[chooser addr]", res);  
        console.log("[chooser a]", $event);
        this.valueChange.emit(res);
    });
    
 //   
  }

  
}
