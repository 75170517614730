//control if the app is busy and locked
import { ActionWithPayload } from './index';
//import { createSelector } from 'reselect';

import * as paymentMethodActions from '../actions/payment-methods';
import { PaymentMethod } from '../../models/payment-methods';

export interface State {
    paymentMethod: PaymentMethod[];
};

export const initialState: State = {
    paymentMethod: []
};


export function reducer(state = initialState, action: ActionWithPayload): State {
    switch (action.type) {
        case paymentMethodActions.ActionTypes.SET: {
            return Object.assign({}, state, { paymentMethod: action.payload });
        }
        default:
            return state;
    }
}

export const getpaymentMethods = (state: State) => state.paymentMethod;
