<div class="container">
    <section>
        <h2>Gift Card Validation</h2>
        <div class="main">
            <p>Enter the code on your gift card</p>
            <input [(ngModel)]="giftCardCode" placeholder="e.g. ABCDE">
            <p class="danger">{{errorMessage}}</p>
        </div>
        <div class="controls">
            <button (click)="cancel()">Cancel</button>
            <button (click)="validate()">Validate</button>
        </div>
    </section>
</div>