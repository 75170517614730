//control if the app is busy and locked
import { ActionWithPayload } from './index';
//import { createSelector } from 'reselect';

import * as notificationAction from '../actions/notification';
import { NotificationMessage } from '../../models/notification-message';

export interface State {
    messages: NotificationMessage[];
};

export const initialState: State = {
    messages: []
};


export function reducer(state = initialState, action: ActionWithPayload): State {
    switch (action.type) {
        case notificationAction.ActionTypes.DISPLAY: {
            if (!action.payload.message || action.payload.message == "") return state;
            for (let message of state.messages){
                if (message.message == action.payload.message){ //dont suplicate
                    return state;
                }
            }
            let newMessages = [...state.messages];
            newMessages.push(action.payload);
            return Object.assign({}, state, { messages: newMessages });
        }
        case notificationAction.ActionTypes.SHOWN: {
            let newMessages = [...state.messages];
            if (newMessages.length > 0) {
                newMessages.shift();
                return Object.assign({}, state, { messages: newMessages });
            }
            return state;
        }
        default:
            return state;
    }
}

export const getMessage = (state: State) => state.messages;
