import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { InfoAttendanceComponent } from './info-attendance.component';
import { MyMaterialModule } from 'app/material/material.module';
import { MyAttendanceGraphComponent } from '../my-attendance-graph/my-attendance-graph.component';
@NgModule({
    imports: [
     CommonModule,
     MyMaterialModule
    ],
    declarations: [
        InfoAttendanceComponent,
        MyAttendanceGraphComponent
    ],
    exports: [
        InfoAttendanceComponent,
        MyAttendanceGraphComponent
    ]
    })
    export class InfoAttendanceModule { }