import { GraphConfig } from "./graph";

export interface ReportDetails {
    idx: number,
    reportName: string,
    friendlyName: string,
    sqlTable: string,
    access : string,
    DateUpdated: string,
    onClick: string,
    onClickField: string,
    onClickVar: string,
    columnDefs: any[],
    rowModelType: string,
    error?: string,
    graph?: GraphConfig,
    deleteEnabled: boolean,
    addNewEnabled: boolean
}

export enum ReportClickAction {
    openForm = "MAKEFORM",
    openLetter = "MAKELETTER",
    openInvoice = "popupInvoiceTxn",
    popupAppointmentList = "popupAppointmentList",
    openStatement = "openStatement",
    openPO = "popupPO",
    accountMembers = "accountMembers",
    submitFilters = "submitFilters",
    staffAppointments = "staffAppointments",
    zReport = "zReport",
    openCreditsList = 'openCreditsList'
}