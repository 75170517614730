<div class="row margin-collapse">
    <h4>Email Templates</h4>
    <br>
    <ag-grid-angular 
        style="width: 100%; height: 100vh" 
        class="ag-theme-alpine-dark" 
        [columnDefs]="columnDefs"
        [defaultColDef]="defaultColDef" 
        [rowData]="rowData$" 
        [animateRows]="true" 
        (gridReady)="onGridReady($event)" 
        (cellClicked)="onCellClicked($event)"
        [gridOptions]="gridOptions"
        [domLayout]="'autoHeight'">
        </ag-grid-angular>
</div>