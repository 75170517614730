export class PaymentMethod {
    idx: number;
    name: string;
    voucher: boolean;
    colour: string;
    discountValue: number;
}


export interface GiftVoucherPayment{
    code: string,
    amount: number,
    id: number
  }