import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';

import { take } from 'rxjs/operators';
import * as fromRoot from '../store/reducers';

import { DataService } from './data.service';

@Injectable()
export class SiteSettingsService {
    constructor(private store: Store<fromRoot.State>, 
        private http: DataService) { }

    get(key: string) {
        return this.http.get(`/settings/${key}`);
    }

    getConfig() {
        return this.store.select(fromRoot.getConfig).pipe(take(1))
    }

    getSiteSettings(){
        return this.store.select(fromRoot.getSiteSettings).pipe(take(1));
    }

    getNotesSettings(){
        return this.store.select(fromRoot.getNotesSettings);
    }

    set(key: string, val: any){
        return this.http.post(`/settings/${key}`, {data: val});
    }

}

