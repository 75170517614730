import { Component, EventEmitter, Input, OnInit, Output, SimpleChange } from '@angular/core';
import { NotesBuilderBaseComponent } from 'app/notes/notes-builder/notes-builder-base/notes-builder-base-component';

@Component({
  selector: 'app-reflexes',
  templateUrl: './reflexes.component.html',
  styleUrls: ['./reflexes.component.css']
})
export class ReflexesComponent extends NotesBuilderBaseComponent {
  private parts = {
    upper: ["BIC", "ABD", "LABR", "UABR", "TRI", "BRA"],
    lower: ["ACH", "TIB", "HAMS", "PAT"]
  };

  public setAllReflexes(part: string, setTo: string) {
    for (let p of this.parts[part]){
      this.form[`${p}_L`] = setTo;
      this.form[`${p}_R`] = setTo;
    }
/*
    switch (part) {
      case 'upper':
        // should only set BIC, TRI, BRA
        const uppers = ["BIC", "TRI", "BRA"]
        this.form["BIC_L"] = setTo;
        this.form["BIC_R"] = setTo;
        this.form["TRI_L"] = setTo;
        this.form["TRI_R"] = setTo;
        this.form["BRA_L"] = setTo;
        this.form["BRA_R"] = setTo;

        break;
      case 'lower':
        // should only set ACH, TIB POST, HAMS, PAT
        this.form["ACH_L"] = setTo;
        this.form["ACH_R"] = setTo;
        this.form["TIB_L"] = setTo;
        this.form["TIB_R"] = setTo;
        this.form["HAMS_L"] = setTo;
        this.form["HAMS_R"] = setTo;
        this.form["PAT_L"] = setTo;
        this.form["PAT_R"] = setTo;
        break;
    }
  
*/
  }
}
