import { Component } from '@angular/core';
import { NotesBuilderBaseComponent } from 'app/notes/notes-builder/notes-builder-base/notes-builder-base-component';
import { CaseHistoryFields } from '../models/casehistory';

export interface CaseHistory {
    name: string,
    title: string,
    value: string,
} 

@Component({
    selector: 'app-case-history',
    templateUrl: './case-history.component.html',
    styleUrls: ['./case-history.component.scss']
})


export class CaseHistoryComponent extends NotesBuilderBaseComponent{
    CaseHistoryFields = CaseHistoryFields;

    public toggleAide(field: any) {
        field.showAide = !field.showAide
    }
}