import { QuestionBase } from './question-base';

export class TextboxQuestion extends QuestionBase<string> {
  controlType = 'textbox';
  declare type: string;
  placeholder: string;

  constructor(options: {} = {}) {
    super(options);
    this.type = options['type'] || '';
    this.placeholder = options['placeholder'] || '';  
    if (this.type=="date" && this.value){
      this.value = this.value.split(" ")[0];
    }
  }
}
