<div *ngIf="loggedIn" class="button" mat-button [matMenuTriggerFor]="userMenu">
<!--<i class="material-icons">face</i> -->
<img src="assets/images/mc-circle.png" [ngClass]="{'rotate-scale-up' : loading}" class="hover-highlight"/>
  </div>
<mat-menu #userMenu="matMenu" class="px-2">
    <div class="profile-area d-inline-flex flex-column justify-content-center align-items-center">
      <div class="profile-photo" [style.background-image]="'url('+staffDetails.photo+')'"></div>
      <div class="username fw-bold">{{staffDetails.firstname}} {{staffDetails.lastname}}</div>
      <div class="username fw-light">{{staffDetails.username}}</div>
    </div>
    <button mat-menu-item [routerLink]="'/profile'">
      <mat-icon>account_box</mat-icon>
      User profile
    </button>

    <mat-slide-toggle name="dark-mode" (change)="setDarkMode($event)" [(ngModel)]="darkMode">Dark Mode</mat-slide-toggle>

    <button mat-menu-item [routerLink]="'/loading'" [queryParams]="{refresh: 1}">
      <mat-icon>restart_alt</mat-icon>
      Restart App
    </button>

    <button  mat-menu-item (click)="logout()">
      <mat-icon>exit_to_app</mat-icon>
      Log out
    </button>

    <hr/>
   
    <div class="d-flex justify-content-between mb-2 mx-3">
      <a target="_blank" href='https://www.multiclinic.co.uk/index.php/support/' title="Support">
        <mat-icon>contact_support</mat-icon>
      </a>
      <a target="_blank" href='https://www.facebook.com/Multiclinic.by.attend2IT' class="f-color">
        <mat-icon>facebook</mat-icon>
      </a>
      <a target="_blank" href='https://twitter.com/a2_Multiclinic' class="f-color">
        <img class="icon-size" src="assets/icons/main-menu-icons/classic-look/twitter.png" />
      </a>
    </div>

    <div class="text-muted build-number">{{ buildNumber }}</div>

</mat-menu>
