import { Component, OnInit } from '@angular/core';
import { NotesBuilderBaseComponent } from 'app/notes/notes-builder/notes-builder-base/notes-builder-base-component';

@Component({
  selector: 'app-axial-spondyloarthritis-risk-assessment',
  templateUrl: './axial-spondyloarthritis-risk-assessment.component.html',
  styleUrls: ['./axial-spondyloarthritis-risk-assessment.component.scss']
})
export class AxialSpondyloarthritisRiskAssessmentComponent extends NotesBuilderBaseComponent {

}
