import { Component, OnInit } from '@angular/core';
import { NotesBuilderBaseComponent } from 'app/notes/notes-builder/notes-builder-base/notes-builder-base-component';

@Component({
  selector: 'app-drawing-questionnaire',
  templateUrl: './drawing-questionnaire.component.html',
  styleUrls: ['./drawing-questionnaire.component.scss']
})
export class DrawingQuestionnaireComponent extends NotesBuilderBaseComponent {

}
