import { Component, OnInit } from '@angular/core';
import { FormTemplateBaseComponent } from '../form-template-base/form-template-base.component';

@Component({
  selector: 'app-form-title-area',
  templateUrl: './form-title-area.component.html',
  styleUrls: ['./form-title-area.component.css']
})
export class FormTitleAreaComponent extends FormTemplateBaseComponent{



}
