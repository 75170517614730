import { Component, OnInit, ViewChild } from '@angular/core';
import { MatOption } from '@angular/material/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DynamicFormSaveService } from 'app/dynamic-form/services/dynamic-form-save.service';
import { FilesService } from 'app/notes/uploader/files.service';
import { DialogService } from 'app/services/dialog/dialog.service';
import { FormTemplateBaseComponent } from '../form-template-base/form-template-base.component';

@Component({
  selector: 'app-form-dropdown-multi-nested',
  templateUrl: './form-dropdown-multi-nested.component.html',
  styleUrls: ['./form-dropdown-multi-nested.component.css']
})
export class FormDropdownMultiNestedComponent extends FormTemplateBaseComponent {
  @ViewChild('inputValue') private inputValue;
  optionsGrouped: any[]

  constructor(fileService: FilesService, dialogService: DialogService, router: Router, route: ActivatedRoute, dynamicFormSaveService: DynamicFormSaveService) {
    super(fileService, dialogService, router, dynamicFormSaveService)
  }

  ngOnInit() {
    // grouping the options by category and subcotegory of treatments 
    let data = this.question.options
    const treatCats = data.filter(item => item.level === 2);
    this.optionsGrouped = treatCats.map(treatCat => {
      const subcat = data.filter(item => item.parent === treatCat.idx);
      return { ...treatCat, subcat };
    });

    console.log(this.question, this.optionsGrouped);
  }

  selectAllToggle() {

    const selected = this.inputValue.options.find(opt => opt.selected);

    console.log(this.inputValue.options, selected);

    if (selected) {
      this.inputValue.options.forEach((item: MatOption) => item.deselect());
    } else {
      this.inputValue.options.forEach((item: MatOption) => item.select());
    }
  }

  selectAllFromCat(cat) {
    let subcats = []
    cat.subcat.forEach(subcat => subcats.push(subcat.idx))

    // check if there is any option selected in the group 
    const selected = this.inputValue.options.filter(opt => subcats.includes(opt.value)).find(opt => opt.selected);

    console.log(cat, subcats, selected);

    if (selected) {
      this.inputValue.options.forEach((item: MatOption) => {
        console.log(item);
        if (subcats.includes(item.value)) item.deselect()
      });
    } else {
      this.inputValue.options.forEach((item: MatOption) => {
        if (subcats.includes(item.value)) item.select()
      });
    }

  }

}
