// Boring Angular Modules //
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DiaryComponent } from './diary.component';
import { DiaryActionsModule } from './grid-item-components/card-diary/diary-actions/diary-actions.module';
import { CardTemplateBaseComponent } from './grid-item-components/card-template-base/card-template-base.component';
import { CardLeftSidebarComponent } from './grid-item-components/card-left-sidebar/card-left-sidebar.component';
import { CardRightSidebarComponent } from './grid-item-components/card-right-sidebar/card-right-sidebar.component';
import { CardSidebarHeaderComponent } from './grid-item-components/card-sidebar-header/card-sidebar-header.component';
import { CardDiaryHeaderComponent } from './grid-item-components/card-diary-header/card-diary-header.component';
import { CardDiaryComponent } from './grid-item-components/card-diary/card-diary.component';
import { CardFooterComponent } from './grid-item-components/card-footer/card-footer.component';
import { StaffService } from '../services/staff.service';
import { TreatmentService } from '../services/treatment.service';
import { MyMaterialModule } from '../material/material.module';
import { CalendarModule, DateAdapter } from 'angular-calendar';

import { DayViewSchedulerComponent } from './grid-item-components/card-diary/day-view-scheduler/day-view-scheduler.component';
import { PractitionerListItemComponent } from './components/practitioner-list-item/practitioner-list-item.component';

import { DiaryService } from '../services/diary/diary.service';
import { ZoomService } from '../services/diary/zoom.service';
import { EditAppointmentComponent } from './grid-item-components/card-diary/edit-appointment/edit-appointment.component';
import { CustomFormControlsModule } from '../custom-form-controls/custom-form-controls.module';
import { GridItemComponent } from './grid-item/grid-item.component';
import { PatientChooserService } from '../services/patient.service';
import { RoomsService } from '../services/rooms.service';


import { SubmitCancelModule } from 'app/submit-cancel/submit-cancel.module';
import { DayRoomViewSchedulerComponent } from './grid-item-components/card-diary/day-room-view-scheduler/day-room-view-scheduler.component';
import { EditAppointmentService } from '../services/diary/edit-appointment.service';
import { AppointmentsActionsService } from '../services/diary/appointment-actions.service';
import { AppointmentsStatusService } from '../services/diary/appointment-status.service';
import { AppointmentColourService } from '../services/diary/appointment-colour.service';
import { SwiperModule } from 'swiper/angular';
import { DiaryTimeMarkerComponent } from './grid-item-components/diary-time-marker/diary-timer-marker.component';
@NgModule({
    imports: [
        CalendarModule,
        CommonModule,
        CustomFormControlsModule,
        DiaryActionsModule,
        FormsModule,
        MyMaterialModule,
        ReactiveFormsModule,
        SubmitCancelModule,
        SwiperModule
    ],
    declarations: [
        CardTemplateBaseComponent,
        CardLeftSidebarComponent,
        CardRightSidebarComponent,
        CardSidebarHeaderComponent,
        CardDiaryHeaderComponent,
        CardDiaryComponent,
        CardFooterComponent,
        DayViewSchedulerComponent,
        DiaryComponent,
        EditAppointmentComponent,
        //EventTemplateComponent, //removed as I do not think its used
        GridItemComponent,
        PractitionerListItemComponent,
        DayRoomViewSchedulerComponent,
        DiaryTimeMarkerComponent

    ],
    providers: [
        AppointmentsActionsService,
        AppointmentsStatusService,
        AppointmentColourService,
        DiaryService,
        RoomsService,
        StaffService,
        TreatmentService,
        ZoomService,
        PatientChooserService,
        EditAppointmentService
    ],
    exports: [
        DiaryComponent
    ]
})
export class DiaryModule { }
