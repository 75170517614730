import { Template } from 'app/models/letter';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { PatientChooserService } from 'app/services/patient.service';
import { getSiteSettings, State } from 'app/store/reducers';
import { getSelectedClinic } from 'app/store/reducers';
import { combineLatest, Subscription } from 'rxjs';
import { NotesService } from 'app/services/notes/notes.service';
import { templates } from "../letter-template-select/usw-templates"
import { NavigationService } from 'app/services/navigation.service';
import { DynamicFormSaveService } from 'app/dynamic-form/services/dynamic-form-save.service';

import { DialogService } from 'app/services/dialog/dialog.service';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { NotificationService } from 'app/services/notification.service';
import { SaveDialogComponent } from 'app/dialogs/save-dialog/save-dialog.component';
import { take } from 'rxjs/operators';
import { AuthService } from 'app/services/auth.service';
import { EditorConfig } from 'app/models/editor-config';
import { LettersService } from 'app/services/notes/letters.service';
import { LettersReplacementService } from 'app/services/notes/letters-replacement.service';
import { isThisSecond } from 'date-fns';
import { EmailTemplates } from "app/models/email";
import { EmailTemplatesService } from 'app/settings/email-templates/email-templates.service';
import { ClinicService } from 'app/services/clinic.service';


export interface TemplateVariables {
  logo?: string;
  patientAddress?: string;
  age?: number;
  dob?: string;
  email?: string;
  patientName?: string;
  title?: string;
  firstName?: string;
  lastName?: string;
  firstVisit?: string;
  lastVisit?: string;
  nextVisit?: string;
  noOfVisits?: number;
  practitioner?: string;
  gpName?: string;
  gpSurgery?: string;
  gpAddress?: string;
  clinicName?: string;
  clinicAddress?: string;
  clinicEmail?: string;
  clinicPhone?: string;
  clinicWebsite?: string;
  clinicalDiagnosis?: string;
  planOfManagement?: string;
  diagnosis?: any;
  staffName?: any;
}


@Component({
  selector: 'app-letter-writer',
  templateUrl: './letter-writer.component.html',
  styleUrls: ['./letter-writer.component.scss']
})

export class LetterWriterComponent implements OnInit, OnDestroy {
  public htmlContent: string = ""
  public editorConfig: AngularEditorConfig = EditorConfig;
  public gpEmail: string; // Need to get this from somewhere
  public template: Template;
  public unavailableReplacements: string;
  public deprecatedReplacements: string;


  private notesIdx: number;
  private patientIdx: number;
  private patientName: string;
  private isOldLetter: boolean = false;
  private savedState?: string;
 // private templateIdx: number;
  emailing = false;
  clinic: any
  emailText = {
    body: ``,
    subject: "Your Letter"
  };


  constructor(
    private route: ActivatedRoute,

    private router: Router,
    private patient: PatientChooserService,
    private notesService: NotesService,
    private nav: NavigationService,
    private form: DynamicFormSaveService,
    private dialog: DialogService,
    private notification: NotificationService,
    private letterService: LettersService,
    private letterReplacement: LettersReplacementService,
    private email: EmailTemplatesService,
    private clinicService: ClinicService) { }

    public letterTitle: string = "";
    public emailSubject: string = "";

    private patient$: Subscription;

  ngOnInit() {

    this.route.params.subscribe(res => {
      if (res.notesIdx && !res.templateIdx) {
        // this is a previously saved note, load the letter
        this.isOldLetter = false;
        this.notesIdx = res.notesIdx;
        this.notesService.getData('notes_letters', this.notesIdx, this.patientIdx).subscribe(res => {
          this.patientIdx = res.patientIdx;
          this.htmlContent = res.data.text;
          this.savedState = this.htmlContent;
          this.replaceKeywords();
        })
      } else if (res.notesIdx && res.templateIdx === "old") {
        // this is a legacy note, load letter from old table
        this.isOldLetter = true;
        this.notesIdx = res.notesIdx;
        this.notesService.getData('SavedLetters', this.notesIdx, this.patientIdx).subscribe(res => {
          this.patientIdx = res.data.PatientIdx;
          this.htmlContent = res.data.LetterText;
          this.savedState = res.data.LetterText;
        })
      } else if (res.notesIdx && res.templateIdx) {
        // this is a newly created note with a template, load and fill the template
        this.isOldLetter = false;
        this.notesIdx = res.notesIdx;
        this.letterService.getTemplate(res.templateIdx).subscribe(res=>{
          this.htmlContent = res.content;
          this.letterTitle = res.title+" "+new Date().toLocaleDateString("en-GB");
          this.emailSubject = this.letterTitle;
          this.template = res;
          this.replaceKeywords();
        })
    //    this.templateIdx = res.templateIdx;

      }
        // this a newly created note with no template, load a blank letter
    })

    this.patient$ = this.patient.getPatient().subscribe(res=>{
      this.patientIdx = res.idx;
      this.patientName = res.name
    })

    this.clinicService.getSelectedClinic().subscribe(res=> {
      console.log("[clinic]", res);
      this.clinic = {name: res.name, address: res.address1, postcode: res.postcode};
    });

    this.getEmailTemplate(EmailTemplates.LETTERS)
  }

  ngOnDestroy(){
    this.patient$.unsubscribe();
  }

/**
 * Checkes for unsaved changes and prompts user if
 * they want to leave the page when refreshing/navigating
 * outside of app.
 * @returns {boolean} Returns a promise resolving to a boolean or a boolean
 */
  @HostListener('window:beforeunload')
  unsaved(){
    if ( this.savedState && this.savedState === this.htmlContent ) {
      return true;
    }else{
      return false
    }
  }



/**
 * Determines whether the user can deactivate the current route.
 * If there are unsaved changes, it prompts the user to save before navigating away.
 * @returns {Promise<boolean> | boolean} Returns a promise resolving to a boolean or a boolean
 * indicating whether the user can deactivate the current route.
 */
    canDeactivate() : Promise<boolean> | boolean {
        return this.savedState && this.savedState === this.htmlContent ?
        true :
        this.dialog.bespokeDialog('Are you sure you want to leave this page? Any unsaved data will be lost', SaveDialogComponent, 'save', 'Unsaved Data', null, "550px")
            .then((res: boolean | 'save') => {
                if (res === 'save') {
                  this.save(true);
                  return true;
                } else {
                    return res
                }
            }).catch(err => {
                return false
              })
      }

  public cancel() {
    this.nav.back()
  }


  private replaceKeywords() {
    //this involves multiple API to calls to get various bit of data so is managed synchronously
    this.letterReplacement.fulfillReplacements(this.htmlContent).subscribe(res=>{
      this.htmlContent = res;
    });

  }

  public print() {
    var mywindow = window.open('', 'PRINT', 'height=400,width=600');
    mywindow.document.write(`<html>
    <head>
    <link rel="stylesheet" type="text/css" href="/assets/css/letters.css">
    </head>
    <body>`)
    mywindow.document.write(this.htmlContent);
    mywindow.document.write('</body></html>')

    //mywindow.document.close(); // necessary for IE >= 10
    mywindow.focus(); // necessary for IE >= 10*/
  //  mywindow.print();
 //   mywindow.close();
  }

  public emailLetter() {
    const email = this.gpEmail
    const letter = this.htmlContent

    this.notesService.emailLetter(EmailTemplates.LETTERS, this.notesIdx, this.patientIdx, email, { attachment: letter }, this.emailSubject).subscribe(res => {
      this.notification.send(`You have successfully emailed this letter to ${email}.`);
      this.emailing = false;
      console.log(res)
    })
  }

  private cleanHtml() {
    let find = "&#10;"
    let re = new RegExp(find, 'g')
    this.htmlContent = this.htmlContent.replace(re, "")
    find = "&#160;"
    re = new RegExp(find, 'g')
    this.htmlContent = this.htmlContent.replace(re, "")
  }


  public save(noNav?:boolean){
    this.cleanHtml()
    if (this.isOldLetter) {
      // this is legacy note, save to old table
      this.oldSave(noNav || false);
      return;
    }
    const data = {
      idx: this.notesIdx,
      fileName: this.letterTitle,
      templateName: this.template.title,
      content: this.htmlContent,
      patientIdx: this.patientIdx
    }

    this.letterService.saveLetter(data).subscribe(res => {
      if (res && res>0) {
        this.notification.send('Letter saved succesfully')
        this.savedState = this.htmlContent
      }else{
        this.notification.send('Error saving letter')
      }
    });
//    this.patient.setPatient(this.patientIdx); // Why?
    this.savedState = this.htmlContent;

    // only navigate if not from deactivate guard
    if (!noNav) this.router.navigate(['notes']);
  }

  private oldSave(noNav?: boolean) {
    const data = {
      formIdx: 72,
      formName: "SavedLetters",
      idx: this.notesIdx,
      patientIdx: this.patientIdx,
      form: [{ LetterText: this.htmlContent }]
    }
    this.form.saveForm(data).subscribe(res => {
      if (res.idx && res.idx !== -1) {
        this.notification.send('Letter saved succesfully!')
      }
    })
    this.patient.setPatient(this.patientIdx);
    this.savedState = this.htmlContent;
    // only navigate if not from deactivate guard
    if (!noNav) this.router.navigateByUrl('notes');

  }

  emailCopy() {
    this.emailing = true
    this.updateEmailText()
  }

  private getEmailTemplate(name: string) : void {
    this.email.getTemplate(name).subscribe((res: any) => {
      this.emailText.body = res.body
      this.emailText.subject = res.subject
    })
  }

  updateEmailText() {
    this.emailText.body = this.emailText.body.replace(/{patient.Name}/g, this.patientName);
    this.emailText.body = this.emailText.body.replace(/{clinic.Name}/g, this.clinic.name);
  }

}
