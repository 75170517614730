import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AppointmentStatus } from "app/models/diary";
import { PrintCardDialogService } from "app/reports/print-card-dialog/print-card-dialog.service";
import { DiaryService } from "app/services/diary/diary.service";
import { EditAppointmentService } from "app/services/diary/edit-appointment.service"
import { PatientChooserService } from "app/services/patient.service";
import { StorageService } from "app/services/storage.service";
import { StaffRoomsView } from "app/models/diary";


@Component({
    selector: "app-edit-appointment",
    templateUrl: "./edit-appointment.component.html",
    styleUrls: ["./edit-appointment.component.scss"],
})


export class EditAppointmentComponent implements OnInit, OnDestroy {
    public minTimeValue;
    public maxTimeValue;
    public errorMessage: string
    public disableContact = true

    public changeCat: boolean = false;
    public isNewAppointment = true;
    public $qParamMap;
    public fixStaff = false;
    public fixRoom = false;
    public fixTreatment = false;
    

    public AppointmentStatus = AppointmentStatus;

    constructor(
        private route: ActivatedRoute,
        public editAppointmentService: EditAppointmentService,
        private router: Router,
        private patient: PatientChooserService,
        private storage: StorageService,
        private printCardDialog: PrintCardDialogService,
        public diary: DiaryService
    ) {
        this.minTimeValue = new Date();
        this.minTimeValue.setHours(7);
        this.minTimeValue.setMinutes(0);

        this.maxTimeValue = new Date();
        this.maxTimeValue.setHours(21);
        this.maxTimeValue.setMinutes(0);
    }

    ngOnInit() {
        // this.editAppointmentService.newAppointmentData(); // no?
        this.$qParamMap = this.route.queryParamMap.subscribe(queryParams => {
            if (queryParams.get("start")) {
                const appointmentDateTime = new Date();
                appointmentDateTime.setTime(parseInt(queryParams.get("start")));
                this.editAppointmentService.startTime = appointmentDateTime;
            }

            if (queryParams.get("staffIdx")) {
                this.editAppointmentService.staffIdx = parseInt(queryParams.get("staffIdx"));
          
            }

            if (queryParams.get("roomIdx")) {
                this.editAppointmentService.roomIdx = parseInt(queryParams.get("roomIdx"));
                this.editAppointmentService.lockRoom = true;
            }

            if (queryParams.get("appointmentIdx")) {
                this.isNewAppointment = false;
                this.editAppointmentService.loadAppointment(parseInt(queryParams.get("appointmentIdx")))
            }

        });


        const rebookPatientIdx = this.storage.getItem("rebookPatientIdx");
        if (rebookPatientIdx) {
            this.patient.setPatient(+rebookPatientIdx);
            this.storage.remove("rebookPatientIdx");
        }

        // fetches the StaffRoomsView and sets values for fixRoom and fixStaff, used to enable/disable form-fields
        // if in room view set lockRoom to true, which will prevent the override to staff deafult room
        this.diary.getStaffOrRoomsView().subscribe((staffOrRooms: string) => {
            if (staffOrRooms == StaffRoomsView.ROOMS) {
                this.editAppointmentService.lockRoom = true
                this.fixRoom = true
            } else {
                this.fixStaff = true
            }
        });

    }

    ngOnDestroy() {
        this.$qParamMap.unsubscribe();
        this.editAppointmentService.clearAppointmentData();
    }

    public toggleContact() {
        this.disableContact = !this.disableContact
    }

    public toggleCats() {
        this.changeCat = !this.changeCat;
    }

    public cancel() {
        this.editAppointmentService.navigateToDiary(); //OnAppointmentDay();
    }

    updateTreatments(e) {
        console.log("[treat] refreshTreatmentOpionList", e);
        this.editAppointmentService.refreshTreatmentOpionList(e.value)
    }

    addNewPatient() {
        this.router.navigate(["form", "patientdetails", "0"]);
    }

    setNotAvailable(naPatient) {
        this.editAppointmentService.setNotAvailable(naPatient);
    }

    clashCheck() {
        this.editAppointmentService.clashCheck();
    }


    save(status: string = null, emailRequired: boolean = false) {
        const valid = this.editAppointmentService.validate();        
        if (!valid.ok) {
            this.errorMessage = valid.msg;
            return;
        }
        this.errorMessage = "";
        this.editAppointmentService.save(status, false, emailRequired).subscribe(res => {
            console.log("[app saved] ", res);
            if (emailRequired) {
                // for a new appointment res is an array, for an existing one res is an object 
                this.printCardDialog.getAppointmentDetails(res.idx ? res.idx : res[0].idx);//.then((dialogResult: any) => {})
            }
        });
    }

    public selectStaffMember(staff: any): void {
        this.editAppointmentService.refreshTreatmentOpionList(staff.treatFromCat)
        this.editAppointmentService.staffIdx = staff.idx
        this.editAppointmentService.treatmentIdx = staff.defaultFollowUpTreatment
    }

    stopPropagation(event) {
        event.stopPropagation();
    }
}
