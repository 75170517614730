<div class="row margin-collapse">
    <div class="col-lg-10">
        <mat-card class="mb-4">
            <mat-card-content>
            <h2>{{ editingPage ? "Edit Purchase Order " + poDetails?.idx : commissionPO ? "Write Commission Purchase Order" : "New Purchase Order" }}</h2>

            <div class="mt-2">
                <form autocomplete="off" #purchase>
                    <!-- Account Autocomplete -->
                    <div class="form-group" *ngIf="!commissionPO">
                        <mat-form-field class="w-100">
                            <mat-label>Account</mat-label>
                            <input matInput 
                                   placeholder="Account"
                                   [matAutocomplete]="autoAccount" 
                                   name="accountChooser" 
                                   [formControl]="accountsControl"
                                   (change)="account = $event.target.value"
                                   [value]="account"
                                   required>
                            <mat-autocomplete #autoAccount="matAutocomplete"  [displayWith]="displayFn" (optionSelected)="updateValue($event)">
                              <mat-option *ngFor="let acc of filteredAccounts | async" [value]="acc.name">
                                {{ acc.name }}
                              </mat-option>
                            </mat-autocomplete>
                            <mat-error *ngIf="accountsControl.invalid">Need an account to pay</mat-error>
                          </mat-form-field>
                    </div>

                    <div class="form-group" *ngIf="commissionPO">
                        <mat-form-field class="w-100">
                            <mat-label>Account</mat-label>
                            <input matInput disabled type="text" [value]="accountDisplay" name="account">
                        </mat-form-field>
                    </div>
<!--
                    <div class="form-group" *ngIf="commissionPO">
                        <mat-form-field>
                            <mat-label>Clinic</mat-label>
                            <input matInput disabled type="text" [value]="clinic.name" name="account">
                        </mat-form-field>
                    </div>
-->
                    <!-- Clinic Selection -->
                    <div class="form-group" *ngIf="!commissionPO">
                        <mat-form-field class="w-100">
                            <mat-label>Clinic</mat-label>
                            <mat-select name="clinicSelect" [(ngModel)]="clinic">
                                <mat-option *ngFor="let clinic of clinics" [value]="clinic">
                                {{ clinic.name }}
                                </mat-option>
                            </mat-select>
                        </mat-form-field>
                    </div>
                  
                    <!-- Date selection -->
                    <div class="form-group">
                        <mat-form-field class="w-100">
                            <mat-label>Date</mat-label>
                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                            <mat-datepicker #picker color="primary"></mat-datepicker>
                            <input matInput
                            name="appointmentDateTime"
                            [matDatepicker]="picker" 
                            [(ngModel)]="date">
                        </mat-form-field>
                    </div>

                    <!-- Notes Input -->
                    <div class="form-group">
                        <mat-form-field class="w-100">
                            <mat-label>Notes</mat-label>
                            <textarea matInput 
                                class="notes top"
                                name="notes"
                                [(ngModel)]="poDetails.notes">
                            </textarea>
                        </mat-form-field>
                    </div>
                </form>
            </div>

            <!-- Display total figures -->
            <div *ngIf="total" class="totals">
                <table class="table top-table">
                    <tr>
                        <th>Total (ex Vat)</th>
                        <td>{{ totalExVat | currency:'GBP' }}</td>
                    </tr>
                    <tr>
                        <th>VAT</th>
                        <td>{{totalVat | currency:'GBP'}}</td>
                    </tr>
                    <tr>
                    <th>Grand Total</th>
                    <td>{{total | currency:'GBP'}}</td>
                </tr>
                </table>
            </div>
            </mat-card-content>
            </mat-card>

           

            <mat-card class="mb-4" *ngIf="!commissionPO">
             <mat-card-content>
                <div class="w-100 d-flex justify-content-between align-items-center" *ngIf="newItem.idx>0; else newAddBtn">
                    <h2 class="my-3">Edit Item</h2>
                    <button  (click)="addLine()" mat-stroked-button >
                        <span>Done <mat-icon>check</mat-icon></span>
                    </button>
                </div>

                <ng-template #newAddBtn>
                    <div class="w-100 d-flex justify-content-between align-items-center">
                        <h2 class="my-3">New Item</h2>
                        <button  (click)="addLine()" mat-stroked-button >
                            Add <mat-icon>add_circle</mat-icon>
                        </button>
                    </div>
                </ng-template>


                <div class="d-flex justify-content-between w-100" *ngIf="!commissionPO">
                    <mat-form-field class="item-autocomplete w-100" >
                        <mat-label>Item</mat-label>
                            <input type="text" 
                            matInput 
                            [matAutocomplete]="autoItem" 
                            name="itemName"
                            [(ngModel)]="newItem.saleFreeText"
                            [formControl]="autoCompleteTextInput"
                            id="inputDropdown">
                            <mat-autocomplete #autoItem="matAutocomplete" (optionSelected)="itemSelected($event.option.value)">
                            <mat-option *ngFor="let option of filteredSaleProductsList | async" [value]="option.name"
                                [id]="'item_'+option.idx">
                                {{option.name}}
                            </mat-option>
                            </mat-autocomplete>
                    </mat-form-field>

                    <mat-form-field class="w-100 mx-3">
                        <mat-label>Acc. Cat</mat-label>
                        <mat-select type="text" [(ngModel)]="newItem.PandLSubCatIdx" name="PandLSubCatIdx">
                            <mat-option *ngFor="let subcat of subCategories" [value]="subcat.idx">
                                {{ subcat.name }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field class="w-100">
                        <mat-label>Notes</mat-label>
                        <input matInput 
                            class="notes bottom"
                            type="textarea"
                            name="notes"
                            [(ngModel)]="newItem.notes">
                    </mat-form-field>

                </div>
                <div class="d-flex justify-content-between w-100" *ngIf="!commissionPO">
                    <mat-form-field>
                        <mat-label>Qty.</mat-label>
                        <input matInput 
                        type="number" 
                        min="0" 
                        [(ngModel)]="newItem.qty" 
                        name="quantity">
                    </mat-form-field>

                    <mat-form-field >
                        <mat-label>Ex VAT ea.</mat-label>
                        <input matInput type="currency" [(ngModel)]="newItem.value" name="costExVat">
                    </mat-form-field>

                    <mat-form-field>
                        <mat-label>VAT</mat-label>
                        <mat-select type="text" [(ngModel)]="newItem.pervailVATRate" name="vatRate">
                            <mat-option *ngFor="let rate of vatRates" [value]="rate.value">
                                {{rate.display}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>

                    <mat-form-field >
                        <mat-label>Inc VAT ea.</mat-label>
                        <input matInput type="currency" [value]="newItem.value*(1+newItem.pervailVATRate)" (change)="updateIncVAT($event)" name="costIncVat">
                    </mat-form-field>


                </div>
            </mat-card-content>
        </mat-card>
        <!-- <pre>{{ poDetails | json }}</pre> -->
        <mat-card class="mb-4" *ngIf="poDetails?.items && poDetails.items.length>0">
        <mat-card-content>



            <div>
                <table class="table table-sm items-table">
                    
                    <tr class="po-edit-tr">
                        <th>Item</th>
                        <th>Cost ex. VAT (Ea.)</th>
                        <th>VAT</th>
                        <th>Cost inc. VAT (Ea.)</th>
                        <th>Qty</th>
                        <th>Notes</th>
                        <th></th>
                    </tr>

                        <tr *ngFor="let item of poDetails.items; let i = index;">
                            <td [ngClass]="{'text-decoration-line-through' : item.qty==0}">{{item.saleFreeText}}</td>
                            <td>{{item.value | currency : 'GBP'}}</td>
                            <td>{{item.pervailVATRate*100}}%</td>
                            <td>{{ +item.value * (1 + +item.pervailVATRate) | currency:'GBP' }}</td>
                            <td class="max-100">{{item.qty}}</td>
                            <td>{{ item.notes }}</td>
                            <td style="padding-bottom: 1rem" *ngIf="!commissionPO">
                                <button (click)="editItem(item)" mat-icon-button>
                                    <mat-icon>edit</mat-icon>
                                </button>
                                <button (click)="removeItem(item)" mat-icon-button>
                                    <mat-icon>delete</mat-icon>
                                </button>
                            </td>
                        </tr>
                    
                </table>

                <div class="alert alert-info" *ngIf="commissionPO && commissionPO.length">Commission PO is not editable, will be automatically received upon submit.</div>
                <div class="alert alert-warning" *ngIf="commissionPO && !commissionPO.length">There are no items on this commission PO.</div>
            </div>
        </mat-card-content></mat-card>
    </div>
    
    <div class="col-sm-2 mobile-till-margin">
        <div class="fix-top">
            <div class="desktop-buttons mobile-buttons">
                <div class="my-3 text-center mobile-button">
                    <button *ngIf="!commissionPO" mat-raised-button (click)="savePurchaseOrder()" color="primary" class="side-button w-100">{{ editingPage ? "Update" : "Create" }}</button>
                    <button *ngIf="commissionPO" mat-raised-button (click)="writeCommissionPO()" color="primary" class="side-button w-100">Submit</button>
                </div>
                <div class="my-3 text-center mobile-button">
                    <button mat-raised-button (click)="back()" class="side-button w-100">Back</button>
                </div>
            </div>
        </div>
    </div>

    
