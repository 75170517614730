import { MenuEffects } from "./menu";
import { StaffEffects } from "./staff";
import { PatientEffects } from "./patient";
import { AppointmentEffects } from "./appointments";
import { TreatmentsEffects } from "./treatments";
import { RoomsEffects } from "./rooms";
import { AuthEffects } from "./auth";
import { ClinicsEffects } from "./clinics";
import { PaymentMethodEffects } from "./payment-methods";
import { SaleItemsEffects } from "./saleItems";
import { SiteSettingsEffects } from "./site-settings";
import { UserSettingsEffects } from "./user-settings";
//list all effects here

export const EffectsList = [
    AppointmentEffects,
    AuthEffects,
    ClinicsEffects,
    MenuEffects, 
    PatientEffects,
    PaymentMethodEffects,
    RoomsEffects,
    SaleItemsEffects,
    SiteSettingsEffects,
    UserSettingsEffects,
    StaffEffects,
    TreatmentsEffects
];

