import { createAction, props } from '@ngrx/store';
import { type PatientChooserResult } from "../../models/patient-details";
import { type } from '../util';

export const SET = 'PATIENT_SET';
export const LOAD = 'PATIENT_LOAD';
export const SET_DETAIL = 'PATIENT_GET_DETAIL';
export const CLEAR = 'PATIENT_CLEAR'

export const ActionTypes = {
    SET: type('[PatientState] Set'),
    SET_PATIENT_IDX: type('[PatientState] Set PatientIdx'),
    LOAD: type('[PatientState] Load'),
    LOAD_FULL_DETAILS: type('[PatientState] Load full details'),
    SET_DETAIL: type('[PatientsState] Set Detail'),
    CLEAR: type('[PatientState] Clear')
};


export const SetPatientIdx = createAction(ActionTypes.SET_PATIENT_IDX, props<{payload: number }>());
export const SetAction = createAction(ActionTypes.SET, props<{payload: PatientChooserResult }>());
export const LoadAction = createAction(ActionTypes.LOAD, props<{payload: number }>());
export const SetDetail = createAction(ActionTypes.SET_DETAIL, props<{payload: any }>());
export const LoadFullDetails = createAction(ActionTypes.LOAD_FULL_DETAILS);
export const ClearAction = createAction(ActionTypes.CLEAR);



/**
 * Every action is comprised of at least a type and an optional
 * payload. Expressing actions as classes enables powerful 
 * type checking in reducer functions.
 * 
 * See Discriminated Unions: https://www.typescriptlang.org/docs/handbook/advanced-types.html#discriminated-unions
 */
/*
export class SetAction implements Action {
    type = ActionTypes.SET;
    constructor(public payload: PatientDetails) { }
}

export class LoadAction implements Action {
    type = ActionTypes.LOAD;
    constructor(public payload: number) { } //send patient IDX
}

export class SetDetail implements Action {
    type = ActionTypes.SET_DETAIL;
    constructor(public payload: any) { }
}

export class LoadFullDetails implements Action {
    type= ActionTypes.LOAD_FULL_DETAILS;
    constructor() {}
}

export class ClearAction implements Action {
    type = ActionTypes.CLEAR;
    constructor() {}
}

/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 
export type Actions
    = SetAction |
    LoadAction;
*/