import { Component, Input } from '@angular/core';
import { NotesBuilderBaseComponent } from 'app/notes/notes-builder/notes-builder-base/notes-builder-base-component';
import { PainScales } from '../models/pain';

@Component({
  selector: 'app-pain-details',
  templateUrl: './pain-details.component.html',
  styleUrls: ['../shared.scss']
})

export class PainDetailsComponent extends NotesBuilderBaseComponent {
  PainScales = PainScales
  numbers = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10"];

}