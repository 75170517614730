import { Component, Input } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { SaveDialogComponent } from 'app/dialogs/save-dialog/save-dialog.component';
import { DialogService } from 'app/services/dialog/dialog.service';
import { NotificationService } from '../../services/notification.service';

@Component({
    selector: 'patient-questionnaire',
    templateUrl: './patient-questionnaire.component.html',
    styleUrls: ['./patient-questionnaire.component.scss']
})
export class PatientQuestionnaireComponent {
    public notesIdx;
    public patientIdx;
    public formsList: string[];
    public submitting: boolean = false;
    public checking: boolean = false;
    public searchOn: any;
    public navigate: boolean = true;
    private saveCount;
    public loadingQuestionnaire: boolean = false;
    private loadingCount: number = 0;
    private checkCount: number;
    private formChecked: boolean;

    constructor(private router: Router,
        private route: ActivatedRoute,
        private notification: NotificationService,
        private dialog: DialogService) {
        this.formsList = ['notes_employmentdetails', 'notes_medicationdetails', 'notes_medicalhistorydetails', 'notes_healthdetails', 'notes_familyhistory', 'notes_paindetails', 'notes_femalesdetails', 'notes_sleephabits', 'notes_osteoporosis' ];
        this.checkCount = this.formsList.length
    }

    ngOnInit() {
        this.route.params.subscribe((params: Params) => {
            console.log(params);
            if (params['notesIdx']) {
                this.loadingQuestionnaire = true;
                this.loadingCount = 0;
                this.notesIdx = +params['notesIdx'];
                this.searchOn = this.notesIdx;
            }

        });
        this.route.parent.params.subscribe(params => {
            console.log("[notes] route params in questionnaire", params)
            this.patientIdx = +params['patientIdx'];
            // route to notes/patientidx on cancel
        });
    }

    public loadComplete(): void {
        this.loadingCount++;
        if ( this.loadingCount >= 0 ) {
            this.loadingQuestionnaire = false
        }
    }

    saveComplete(form) {
        this.saveCount--;
        console.log("saving form")
        console.log(`saved ::  > ${this.saveCount}`, form);
        if (this.saveCount == 0) {
            this.notification.send("Notes have been saved");
            this.submitting = false;
        }
    }


    saveForm(navigate?:boolean) : Promise<boolean> {
        /*  Returns boolean promise for use in the
            canDeactivate guard. All forms must be
            saved before navigating away, otherwise
            the form data gets mixed up in different
            notesIdx
        */
        console.log("[Patient Questionnaire] Saving initiated")
        console.log(`[Patient Questionnaire] Saving ${this.formsList}`)
        this.saveCount = this.formsList.length;
        this.navigate = navigate ? false : true;
        this.submitting = true;
        return new Promise((resolve) => {
            // Wait for all forms to save before returning true
            setInterval(() => {
                if (!this.submitting){
                    resolve(true);
                    this.formChecked = false;
                    clearInterval(undefined);
                }
            }, 50);
        })
    }

    formTouched() {
        this.formChecked = true;
    }

    checkForm() : Promise<boolean> {
        this.checkCount = this.formsList.length;
        this.checking = true;
        return new Promise((resolve) => {
            // Wait for all forms to be checked before returning
            setInterval(() => {
                if (!this.checking) {
                    resolve(true);
                    clearInterval(undefined);
                }
            }, 50);
        })
    }

    checkComplete() {
        this.checkCount--;
        if (this.checkCount == 0) {
            this.checking = false;
            if (!this.formChecked) {
                return true
            }
        }
    }


    cancel(){
        this.router.navigate(['notes', this.patientIdx, 'patientquestionaire'])
    }

    scroll(dest: string) {
        console.log("[scroll] ", dest)
    //    this.scroller.scrollToAnchor(dest);
        const ele = document.getElementById(dest);
        console.log(ele)
        ele.scrollIntoView({behavior: "smooth"});
    }

    public canDeactivate() : Promise<boolean> | boolean {
        return this.checkForm().then((res: boolean) => {
            if (res && !this.formChecked) {
                this.formChecked = false
                return true
            }
            return this.dialog.bespokeDialog('Are you sure you want to leave this page? Any unsaved data will be lost', SaveDialogComponent, 'save', 'Unsaved Data', null, "550px")
            .then((res: boolean | 'save') => {
                if (res === 'save') {
                  // Wait for all forms to save before returning true
                    return this.saveForm(true);
                } else {
                    if (res) this.formChecked = false
                    return res
                };
            }).catch(err => {
                return false
            })
        }).catch(err => {
            return false
        });

      }
}
