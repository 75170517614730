import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { ReportsRoutes } from './reports.routes';

import { LicenseManager } from "ag-grid-enterprise";
import { AgGridModule } from 'ag-grid-angular';
import { ReportsService } from './reports.service';
import { ReportsConfigService } from './reports-config.service';
import { GenericReportComponent } from './generic/generic-report.component';
import { MyMaterialModule } from 'app/material/material.module';
import { PrintCardDialogComponent } from './print-card-dialog/print-card-dialog.component';
import { CustomDateRangeComponent } from './custom-date-range/custom-date-range.component';
import { DefaultMatCalendarRangeStrategy, MAT_DATE_RANGE_SELECTION_STRATEGY } from '@angular/material/datepicker';
import { MatMomentDateModule, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { CustomPinnedRowRenderer } from './custom-pinned-row-renderer/custom-pinned-row-renderer.component';
import { CustomFormControlsModule } from 'app/custom-form-controls/custom-form-controls.module';

LicenseManager.setLicenseKey("your license key");
@NgModule({

    imports: [
        AgGridModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule.forChild(ReportsRoutes),
        MyMaterialModule,
        CustomFormControlsModule
    ],
    exports: [
        MatMomentDateModule,
    ],
    declarations: [
        GenericReportComponent,
        PrintCardDialogComponent,
        CustomDateRangeComponent,
        CustomPinnedRowRenderer

    ],
    providers: [
        ReportsService,
        ReportsConfigService,
        {
            provide: MAT_DATE_RANGE_SELECTION_STRATEGY,
            useClass: DefaultMatCalendarRangeStrategy,
        },
        { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }
    ]

})
export class ReportsModule { }
