//control if the app is busy and locked
import { ActionWithPayload } from './index';
//import { createSelector } from 'reselect';

import * as treatmentsAction from '../actions/treatments';
import { type TreatmentGroup } from '../../models/treatments';

export interface State {
    treatments: TreatmentGroup[]
};

export const initialState: State = {
    treatments: []
};


export function reducer(state = initialState, action: ActionWithPayload): State {
    switch (action.type) {
        case treatmentsAction.ActionTypes.SET: {
            return Object.assign({}, state, { treatments: action.payload });
        }

        default:
            return state;
    }
}

export const getTreatments = (state: State) => state.treatments;
