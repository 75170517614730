<div class="main-container" style="height: 100%">
    <header>
        <h1>Main Menu</h1>
    </header>
    <div class="list">
        <div class="tile" *ngFor="let item of links" [hidden]="item.hidden">
            <button class="tile-button" (click)="logSomething(item)">
                <app-grid-button [link]="item" [isOldStyle]="isOldStyle"></app-grid-button>
            </button>
        </div>
    </div>
</div>
