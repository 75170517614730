import { Component, ViewChild, OnInit, OnChanges } from "@angular/core";
import { CardTemplateBaseComponent } from "../card-template-base/card-template-base.component";
import { DiaryService } from "../../../services/diary/diary.service";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { PatientChooserService } from "../../../services/patient.service";
import { AppointmentsService } from "../../../services/diary/appointments.service";
import { take } from "rxjs/operators"

import { AppointmentStatus } from "app/models/diary";
import { AttendanceSummaryTransalted, PatientAppointmentHistoryService } from "app/services/patient-appointment-history.service";
import { AttendanceSummary } from "app/models/patient-details";

@Component({
  selector: "app-card-right-sidebar",
  templateUrl: "./card-right-sidebar.component.html",
  styleUrls: ["./card-right-sidebar.component.scss"]
})
export class CardRightSidebarComponent extends CardTemplateBaseComponent implements OnInit, OnChanges {
  public selectedLink;
  lastAppointment: string;
  public AppointmentStatus = AppointmentStatus;
  public contactDetails;
  public attendance: AttendanceSummaryTransalted;
  private clickedEvent$;
  public clickedEvent;

  @ViewChild('linkInput') linkInput;
  constructor(
    private diary: DiaryService,
    private router: Router,
    private http: HttpClient,
    private patientChooser: PatientChooserService,
    private appointment: AppointmentsService,
    private patientAppointmentHistoryService: PatientAppointmentHistoryService
  ) {
    super();

    // setInterval(()=>{
    //   console.log(this.diary.clickedEvent)
    // },1000);
  }

  ngOnInit() {

    this.diary.clickedEvent$.subscribe( clickedEvent => {
      this.clickedEvent = clickedEvent;
      if (!clickedEvent) {
        this.hideRightSidebar();
        return;
      }
    console.log("[sidebar] ngOnInit", clickedEvent);

    this.lastAppointment = '';
    this.patientChooser.getPatientDetails(clickedEvent.meta.patientId).pipe(take(1)).subscribe(res => {
      if (res.lastAppointment) {
        this.lastAppointment = res.lastAppointment;
      }
      this.contactDetails = {
        phone: res.mobilePhone || res.homePhone || "",
        email: res.email || "",
      };
    });


    this.patientAppointmentHistoryService.setPatientId(clickedEvent.meta.patientId);
    this.patientAppointmentHistoryService.getSummaryForPatient(clickedEvent.meta.patientId).subscribe(res => {
      this.attendance = res;
    });
  });

  }

  ngOnChanges() {
    console.log("[sidebar] ngOnChanges");
  }



  viewPatientForm(id) {
    this.router.navigate([`/form`, "patientdetails", this.diary.clickedEvent.idx]);
  }
  viewPatientNotes(id) {
    this.router.navigate([`/notes`, id]);
  }
  callPatient() { }
  hideRightSidebar() {
    this.diary.hideRightSidebar()
  }
  goToVirtualAppointment() {
    ///    this.router.navigate([`/video`,'appointment', this.diary.clickedEvent.idx]);
  }

  copyToClipboard(link) {
    this.selectedLink = link;
    console.log(this.linkInput);
    setTimeout(() => { //sleep for update
      this.linkInput.nativeElement.select()
      document.execCommand("copy");
    }, 10);
  }

 

  actionClicked(event){
    this.patientChooser.setPatient(this.diary.clickedEvent.meta.patientId)
    console.log("[execute] ", event)
  }
}
