/* SAves dynmic forms to server */
import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";
import { PatientChooserService } from "app/services/patient.service";
import { BehaviorSubject, Observable } from "rxjs";
import { map, take } from "rxjs/operators";
import { DataService } from "../../services/data.service";


@Injectable()
export class DynamicFormSaveService {
  public showInvalidField = new BehaviorSubject(false)

  constructor(
    private http: DataService,
    private patients: PatientChooserService,
  ) {}

  saveForm(data: any) {
    console.log("[form] D F S S ");
    if (!data.idx){ data.idx = 0; }
    return this.http.post(`/forms/${data.formIdx}/${data.idx}`, data).pipe(
      map((res: any) => {
        if (res.patientIdx) {
          this.patients.setPatient(res.patientIdx);
        }
        return res;
      })
    );
  }

  deleteForm(reportName: string, rowIdx: number): Observable<any> {
    console.log("[deleting] From dynamic form  report:",reportName,"rowIdx",rowIdx)
    return this.http.delete(`/forms/${reportName}/${rowIdx}`);
  }
}
