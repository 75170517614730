<h1 mat-dialog-title *ngIf="data.giftCards.length === 1">
   Gift Voucher Code
</h1>
<h1 mat-dialog-title *ngIf="data.giftCards.length > 1">
  Gift Voucher Codes
</h1>
  
  <div mat-dialog-content>
    <p *ngIf="data.giftCards.length === 1">Please record the following code</p>
    <p *ngIf="data.giftCards.length > 1">Please record the following codes</p>
    <table class="table table-sm">
        <tr>
            <th>Code</th>
            <th>Value</th>
            <th>Expiry Date</th>
        </tr>
        <tr *ngFor="let card of data.giftCards">
            <td>{{card.code}}</td>
            <td>£{{card.value}}</td>
            <td>{{card.expiryDate.toLocaleDateString("en-GB")}}</td>
        </tr>
    </table>
  </div>
  
  <div mat-dialog-actions>
    <button mat-raised-button color="primary" (click)="onConfirm()">Yes</button>
  </div>