import { Component, OnInit } from '@angular/core';
import { KnownReplacements } from 'app/models/letter';

@Component({
  selector: 'app-template-keyword-guide',
  templateUrl: './template-keyword-guide.component.html',
  styleUrls: ['./template-keyword-guide.component.scss']
})
export class TemplateKeywordGuideComponent implements OnInit {
  
  public KnownReplacements = KnownReplacements;
  
  constructor() { }

  ngOnInit(): void {  }

}