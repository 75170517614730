import { Injectable } from "@angular/core";
import * as fromRoot from "../store/reducers";
import * as fromSettings from "../store/actions/site-settings";

import { Store } from "@ngrx/store";
import { map, skipWhile, switchMap } from "rxjs/operators";
import { Observable } from "rxjs";
import { DataService } from "../services/data.service";
import { type TreatmentGroup } from "../models/treatments";
import { StaffService } from "./staff.service";

@Injectable({
    providedIn: "root"
})
export class TreatmentService {

    constructor(private store: Store<fromRoot.State>, private staffService: StaffService, private http: DataService) { }

    private treatmentGroups: TreatmentGroup[] = [];

    getTreatments(): Observable<TreatmentGroup[]> {
        return this.store.select(fromRoot.getTreatments).pipe(
            skipWhile(val => val == null || val.length == 0));
    }

    getTreatmentsForCat(staffId: number) {
        return this.staffService.getTreatFromCat(staffId).pipe(map(cat => {
            this.getTreatments().pipe(
                map(res => {
                    console.log(res);
                    console.log(cat);
                    let treatments = res.find(t => t.idx == cat);
                    if (treatments && treatments.treatments) return treatments.treatments
                    return [];
                }));
        }));
    }

    getTreatmentsFromServer(){
        return this.http.get<TreatmentGroup[]>('/treatments');
    }

    save(payload){
        console.log("[treat] save", payload);
        return this.http.post<TreatmentGroup[]>('/treatments', payload);
    }

}
