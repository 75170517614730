import {
  Component,
  Input,
  ViewContainerRef,
  ViewChild,
  OnInit,
  Output,
  EventEmitter
} from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";
import { QuestionBase, fieldType } from "../question-base";
import { FormTitleAreaComponent } from "../form-components/form-title-area/form-title-area.component";
import { FormTextBoxComponent } from "../form-components/form-text-box/form-text-box.component";
import { FormPasswordComponent } from "../form-components/form-password/form-password.component";
import { FormChooserComponent } from "../form-components/form-chooser/form-chooser.component";
import { FormDropdownComponent } from "../form-components/form-dropdown/form-dropdown.component";
import { FormTextAreaComponent } from "../form-components/form-text-area/form-text-area.component";
import { FormCheckboxComponent } from "../form-components/form-checkbox/form-checkbox.component";
import { FormTemplateBaseComponent } from "../form-components/form-template-base/form-template-base.component";
import { FormSliderComponent } from "../form-components/form-slider/form-slider.component";
import { FormsColourComponent } from "../form-components/forms-colour/forms-colour.component";
import { FormInfoBoxComponent } from "../form-components/form-info-box/form-info-box.component";
import { FormUploaderComponent } from "../form-components/form-uploader/form-uploader.component";
import { FormHiddenComponent } from "../form-components/form-hidden/form-hidden.component";
import { FormDropdownMultiComponent } from "../form-components/form-dropdown-multi/form-dropdown-multi.component";
import { FormDropdownMultiNestedComponent } from "../form-components/form-dropdown-multi-nested/form-dropdown-multi-nested.component";
import { StaffChooserComponent } from "app/custom-form-controls/staff-chooser/staff-chooser.component";

@Component({
  selector: "df-question",
  templateUrl: "./dynamic-form-question.component.html",
  styleUrls: ["./dynamic-form-question.component.css"],
})
export class DynamicFormQuestionComponent implements OnInit {
  @Input() question: QuestionBase<any>;
  @Input() form: UntypedFormGroup;
  @Input() instance: number;
  @Input() patientIdx: number;

  @Output() openFormDialog = new EventEmitter();

  @ViewChild("container", { read: ViewContainerRef, static: true })
  private container: ViewContainerRef;
  private componentRef: any;

  //large: boolean = false;

  fieldType = fieldType;
  private readonly templateMapper = {
    titlearea: FormTitleAreaComponent,
    subtitlearea: FormTitleAreaComponent,
    textbox: FormTextBoxComponent,
    infobox: FormInfoBoxComponent,
    password: FormPasswordComponent,
    chooser: FormChooserComponent,
    dropdown: FormDropdownComponent,
    dropdownmulti: FormDropdownMultiComponent,
    dropdownmultinested: FormDropdownMultiNestedComponent,
    textarea: FormTextAreaComponent,
    checkbox: FormCheckboxComponent,
    slider: FormSliderComponent,
    colour: FormsColourComponent,
    fileupload: FormUploaderComponent,
    hidden: FormHiddenComponent
  };

  constructor(){}
  ngOnInit() {
   // if (this.question.label === "GP Details") {  // everyone loves a hard coded field label
   //   this.large = true;
   // }
    if ( !this.question ) return;

    if (this.question.controlType === fieldType.DROPDOWN) {
      for (let i = 0; i < this.question.options.length; i++) {
        if ((this.question.options[i] as any).idx) {
          this.question.options[i].key = (this.question.options[i] as any).idx;
        }
        if ((this.question.options[i] as any).name) {
          this.question.options[i].value = (this.question.options[
            i
          ] as any).name;
        }
      }
    }

    this.componentRef = this.container.createComponent(this.getComponentForCardType(this.question.controlType));
    (<FormTemplateBaseComponent>this.componentRef.instance).question = this.question;
    (<FormTemplateBaseComponent>this.componentRef.instance).form = this.form;
    (<FormTemplateBaseComponent>this.componentRef.instance).patientIdx = this.patientIdx;
    if (this.question.controlType === fieldType.CHOOSER) {
      this.setValue(this.question.value);
      (this.componentRef as any).instance.addNew.subscribe(res => {
        this.openFormDialog.emit({data: res, ele: this, action: 'dialog'});
      });
      (this.componentRef as any).instance.valueChange.subscribe(res => {
        this.openFormDialog.emit({data: res, ele: this, action: this.question.type});
      });
    }
  }

  private getComponentForCardType(cardType) {
    return this.templateMapper[cardType];
  }

  public setValue(v){
    (<FormChooserComponent>this.componentRef.instance).value = v;
  }

}
