import { Component } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { AWSEmailVerifyService } from "../services/aws-email-verify.service";

@Component({
    selector: 'aws-email-verify',
    templateUrl: './aws-email-verify.component.html',
    styleUrls:  ['./aws-email-verify.component.scss']
})

export class AWSVerifyEmailComponent {
    public emailAddress;
    public status;
    public statusOk;
    readonly emailStates = {
        pending: "Pending",
        success: "Success"
    };

    constructor( private route: ActivatedRoute, private awsVerifyService: AWSEmailVerifyService){
        this.route.queryParams.subscribe(params =>{
            if (params.email){
                this.emailAddress = params.email;
                this.emailUpdated();
            }
        });
    }

    public emailUpdated(){
        this.status = "Checking";
        this.statusOk = null;
        this.awsVerifyService.check(this.emailAddress).subscribe(res=>{
            this.status = "No request Found";
            if (res.result && res.result[this.emailAddress] && res.result[this.emailAddress].VerificationStatus){
                this.status = res.result[this.emailAddress].VerificationStatus;
                this.statusOk = true;
            }else{
                this.statusOk = false;
            }
        });
    }

    public sendVerification(){
        this.awsVerifyService.verify(this.emailAddress).subscribe(res=>{});
    }

    public back() {
       window.history.back();
    }
}