import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'app/services/auth.service';
import { LoadingService } from 'app/services/loading.service';
import { StorageService } from "../services/storage.service";

@Component({
    selector: 'app-logout',
    templateUrl: './logout.component.html',
    styleUrls: ['./logout.component.scss']
})

export class LogoutComponent implements OnInit {

    goBackToLogin$;
    jwt$;
    public logoutComplete = false;

    constructor(private router: Router, 
        private auth: AuthService,
        private loading: LoadingService) { }

    ngOnInit() { 
        this.loading.start();
        this.jwt$ = this.auth.jwt().subscribe(res=>{
            console.log("[auth] logout jwy=>", res);
            if (!res || res==""){
                this.logoutComplete = true;
                this.loading.stop();
                if (!this.goBackToLogin$){
                    this.goBackToLogin$ = setTimeout(()=>{
                        this.toLogin();
                    }, 10000);
                }
            }
        });
//        this.storage.clearAndRestoreCompanyName();
        this.auth.logout();
        

    }

    ngOnDestroy(){
        this.loading.stop();
        this.jwt$.unsubscribe();
    }


    toLogin(){
        if (this.goBackToLogin$){
            clearTimeout(this.goBackToLogin$);
            this.goBackToLogin$ = null;
        }
        document.location = "/login";
    }
}
