
import { Component } from '@angular/core';
import { NotesBuilderBaseComponent } from 'app/notes/notes-builder/notes-builder-base/notes-builder-base-component';
import { OtherTestsFields } from "../models/usw-other-tests"

@Component({
  selector: 'app-usw-other-tests',
  templateUrl: './other-tests.component.html',
  styleUrls: ['./other-tests.component.css']
})
export class USWOtherTestsComponent extends NotesBuilderBaseComponent {
  OtherTestsFields = OtherTestsFields
}
