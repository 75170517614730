<form autocomplete="off" #formEle="ngForm">

    <mat-form-field [style.width.%]="100">
        <mat-label>Are you physically active or do you exercise regularly?</mat-label>
        <mat-select [(ngModel)]="form.physically_active" name="physically_active">
            <mat-option value="yes">Yes</mat-option>
            <mat-option value="no">No</mat-option>
        </mat-select>
    </mat-form-field>

    <mat-form-field [style.width.%]="100">
        <mat-label>Type of activity? How Frequent?</mat-label>
        <input matInput type="text" name="type_activity_how_frequent" [(ngModel)]="form.type_activity_how_frequent">
    </mat-form-field>

    <mat-form-field [style.width.%]="100">
        <mat-label>How long? How many steps per day (Average)?</mat-label>
        <input matInput type="text" name="steps_per_day" [(ngModel)]="form.steps_per_day">
    </mat-form-field>

    <mat-label>During the last 7 days, on how many days did you do vigorous physical activities like heavy lifting,
        digging, aerobics, or fast bicycling?</mat-label>
    <mat-form-field [style.width.%]="100">
        <mat-label>days per week</mat-label>
        <input matInput type="text" name="vigorous_activities_days" [(ngModel)]="form.vigorous_activities_days">
    </mat-form-field>

    <mat-label>How much time did you usually spend doing vigorous physical activities on one of those days?</mat-label>
    <mat-form-field [style.width.%]="100">
        <mat-label>hours/minutes per day</mat-label>
        <input matInput type="text" name="vigorous_activities_hours_minutes"
            [(ngModel)]="form.vigorous_activities_hours_minutes">
    </mat-form-field>

    <mat-label>
        Think about all the moderate activities that you did in the last 7 days.  Moderate activities refer to
        activities that take moderate physical effort and make you breathe somewhat harder than normal.  Think only
        about those physical activities that you did for at least 10 minutes at a time.
    </mat-label>

    <div class="mt-3">
        <mat-label>
            During the last 7 days, on how many days did you do moderate physical activities like carrying light
            loads, bicycling at a regular pace, or doubles tennis?  Do not include walking.
        </mat-label>
    </div>
    <mat-form-field [style.width.%]="100">
        <mat-label>days per week</mat-label>
        <input matInput type="text" name="moderate_activities_days" [(ngModel)]="form.moderate_activities_days">
    </mat-form-field>

    <mat-label>How much time did you usually spend doing moderate physical activities on one of those days?</mat-label>
    <mat-form-field [style.width.%]="100">
        <mat-label>hours/minutes per day</mat-label>
        <input matInput type="text" name="moderate_activities_hours_minutes"
            [(ngModel)]="form.moderate_activities_hours_minutes">
    </mat-form-field>

    <mat-label>
        Think about the time you spent walking in the last 7 days.  This includes at work and at home, walking to travel
        from place to place, and any other walking that you have done solely for recreation, sport, exercise, or
        leisure.
    </mat-label>

    <div class="mt-3">
        <mat-label>
            During the last 7 days, on how many days did you walk for at least 10 minutes at a time?
        </mat-label>
    </div>
    <mat-form-field [style.width.%]="100">
        <mat-label>days per week</mat-label>
        <input matInput type="text" name="walk_activities_days" [(ngModel)]="form.walk_activities_days">
    </mat-form-field>

    <mat-label>How much time did you usually spend walking on one of those days?</mat-label>
    <mat-form-field [style.width.%]="100">
        <mat-label>hours and minutes per day</mat-label>
        <input matInput type="text" name="walk_activities_hours_minutes"
            [(ngModel)]="form.walk_activities_hours_minutes">
    </mat-form-field>

    <mat-label>
        The last question is about the time you spent sitting on weekdays during the last 7 days.  Include time spent at
        work, at home, while doing course work and during leisure time. This may include time spent sitting at a desk,
        visiting friends, reading, or sitting or lying down to watch television.
    </mat-label>

    <div class="mt-3">
        <mat-label>During the last 7 days, how much time did you spend sitting on a week day?</mat-label>
    </div>
    <mat-form-field [style.width.%]="100">
        <mat-label> hours and minutes per day</mat-label>
        <input matInput type="text" name="sitting_hours_minutes" [(ngModel)]="form.sitting_hours_minutes">
    </mat-form-field>

</form>