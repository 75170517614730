<mat-expansion-panel hideToggle>
    <mat-expansion-panel-header>
        <mat-panel-title>
            Keyword Guide
        </mat-panel-title>
        <mat-panel-description>
            Automatic substitutions can be made using special <em>{{ '{' }}KEYWORDS{{ '}' }}</em>
        </mat-panel-description>
    </mat-expansion-panel-header>
    <div class="row">
        <div class="col-sm-4" *ngFor="let group of KnownReplacements">
            <h5>{{group.description}}</h5>
            <table class="table">
                <tr *ngFor="let item of group.subItems">
                    <td>{{ '{'+group.tag+'.'+item.tag+'}' }}</td>
                    <td>{{ item.description }}</td>
                </tr>
            </table>
        </div>
    </div>
</mat-expansion-panel>