import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MyMaterialModule } from 'app/material/material.module';
import { TemplateKeywordGuideComponent } from './template-keyword-guide.component';


@NgModule({
    imports: [   
        CommonModule,
        MyMaterialModule,
    ],
    declarations: [
        TemplateKeywordGuideComponent
    ],
   
    exports: [
        TemplateKeywordGuideComponent
    ]
})
export class TemplateKeywordGuideModule {}
