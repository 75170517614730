import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { type Attendance } from 'app/models/patient-details';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { PatientChooserService } from 'app/services/patient.service';


@Component({
  selector: 'info-attendance',
  templateUrl: './info-attendance.component.html',
  styleUrls: ['info-attendance.component.scss', '../info-table-shared.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class InfoAttendanceComponent implements OnInit, OnChanges, OnDestroy{
  @Input() info: Attendance[] = [];
  expandedElement!: Attendance;
  @Input() fromModal = false;
  displayedColumns: string[] = ['startTime', 'staffName', 'treatmentName', 'charge', 'status', 'expand' ];

  constructor(private patient: PatientChooserService) {}

  ngOnInit() {
  }

  ngOnDestroy() {
  }

  ngOnChanges(changes: SimpleChanges): void {
   if (changes['info'] && this.info) {
      console.log("[attendance]", this.info);
    }

  }
}

