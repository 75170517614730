import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { NavigationService } from 'app/services/navigation.service';

import { LettersService } from 'app/services/notes/letters.service';
import { Template } from 'app/models/letter';



@Component({
  selector: 'app-letter-template-select',
  templateUrl: './letter-template-select.component.html',
  styleUrls: ['./letter-template-select.component.scss']
})
export class LetterTemplateSelectComponent implements OnInit {
  templates: Template[]
  notesIdx: number = 0;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private nav: NavigationService,
    private lettersService: LettersService
  ) { }

  ngOnInit(): void {
    this.getTemplates()
    this.route.params.subscribe(res => {
        this.notesIdx = res.notesIdx;
    });
  }

  selectTemplate(template) {
      this.router.navigate([`letters`, this.notesIdx, template.idx]);
  }


  getTemplates(){
    this.lettersService.listTemplates().subscribe(res => {
      this.templates = res;
    });
  }

  noReportRoute() {
//    this.templates = templates
  }

  cancel() {
    this.nav.back();
  }

}
