
import { Template } from 'app/models/letter';
import { Injectable, resolveForwardRef } from '@angular/core';
import { Observable } from 'rxjs';
import { DataService } from '../data.service';
import { map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import * as fromRoot from 'app/store/reducers';
import * as patientActions from 'app/store/actions/patient';
@Injectable()
export class LettersService {   

    constructor(
        private http: DataService,
        private store: Store<fromRoot.State>) {        
    }
    
    
    listTemplates(): Observable<Template[]> {
        return this.http.get<Template[]>('/letter/templates')
        .pipe( map (res => {
            return res.sort((a, b)=>{
                return a.title.localeCompare(b.title);
            })
        }));
    }

    getTemplate(idx): Observable<Template> {
        return this.http.get(`/letter/template/${idx}`);
    }

    updateTemplate(idx, payload){
        return this.http.post(`/letter/template/${idx}`, {data: payload});
    }

    deleteTemplate(idx) {
        return this.http.delete(`/letter/template/${idx}`);
    }

    saveLetter(payload): Observable<number>{
        return this.http.post<number>(`/letter`, {data: payload}).pipe(map(res=>{
            this.store.dispatch(patientActions.SetAction({payload: {idx:  payload.patientIdx, isSpecial: false, name: "", address: ""}}));
            return res;
        }));
    }

}
