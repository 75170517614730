// Boring Angular Modules //
import { NgModule } from "@angular/core";
//import { BrowserModule }                  from '@angular/platform-browser';
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { FormsModule } from "@angular/forms";
import { FinanceRoutes } from "./finance.routes";
import { PandLComponent } from "./p-and-l/p-and-l.component";
import { PandLLineComponent } from "./p-and-l/p-and-l-line.component";
import { MyMaterialModule } from "../material/material.module";
import { IncomeDrillDownComponent } from "./income-drill-down/income-drill-down.component";
import { AgGridModule } from "ag-grid-angular";
import { PandLService } from "./p-and-l/p-and-l.service";
import { InvoiceComponent } from "./invoice/invoice.component";
import { InvoiceService } from "./invoice/invoice.service";
import { PatientInfoBoxService } from 'app/services/notes/patient-info-box.service';
import { XzReportsComponent } from "./xzreports/xzreports.component";
import { ReportSelectorComponent } from "./xzreports/report-selector/report-selector.component";
import { InsuranceInvoiceService } from "./insurance-invoice/insurance-invoice.service";
import { CustomerComponent } from "./insurance-invoice/customer/customer.component";
import { InsurerComponent } from "./insurance-invoice/insurer/insurer.component";
import { PurchaseOrdersComponent } from "./purchase-orders/purchase-orders.component"
import { PrintPurchaseOrderComponent } from "./print-purchase-order/print-purchase-order.component"
import { PurchaseOrdersService } from "./purchase-orders/purchase-orders.service"
import { ReceivePurchaseOrderComponent } from "./receive-purchase-order/receive-purchase-order.component"
import { AngularEditorModule } from "@kolkov/angular-editor";
import { CommissionMatrixComponent } from './commission-matrix/commission-matrix.component';
import { PipesModule } from "app/pipes/pipes.module";

import { CommissionPODialogComponent } from '../dialogs/commission-po-dialog/commission-po-dialog.component';
@NgModule({
  imports: [
    AgGridModule,
    CommonModule,
    FormsModule,
    RouterModule.forChild(FinanceRoutes),
    MyMaterialModule,
    ReactiveFormsModule,
    AngularEditorModule,
    PipesModule
  ],
  declarations: [
    IncomeDrillDownComponent,
    InvoiceComponent,
    PandLComponent,
    PandLLineComponent,
    XzReportsComponent,
    ReportSelectorComponent,
    CustomerComponent,
    InsurerComponent,
    PrintPurchaseOrderComponent,
    PurchaseOrdersComponent,
    ReceivePurchaseOrderComponent,
    CommissionMatrixComponent,
    CommissionPODialogComponent
  ],
  providers: [
    PandLService,
    InvoiceService,
    PatientInfoBoxService,
    InsuranceInvoiceService,
    PurchaseOrdersService,
  ],
})
export class FinanceModule {}
