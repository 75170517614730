import { ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChange, SimpleChanges, ViewChild } from "@angular/core";
import { FormControl, NG_VALUE_ACCESSOR, UntypedFormControl } from "@angular/forms";
import { ValueAccessorBase } from "../value-accessor-base";
import { ActivatedRoute, Router } from "@angular/router";
import { DynamicAutoCompleteService, DynamicDropdownOption } from "app/dynamic-form/services/dynamic-autocomplete.service";
import { PatientChooserService } from "app/services/patient.service";
import { QuestionService } from "app/dynamic-form/services/question.service";
import { Observable, Subscription } from "rxjs";
import { map, startWith } from "rxjs/operators";
import { MatAutocomplete, MatAutocompleteTrigger } from "@angular/material/autocomplete";

@Component({
  selector: "generic-chooser",
  templateUrl: "./generic-chooser.component.html",
  styleUrls: ["./generic-chooser.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: GenericChooserComponent,
      multi: true,
    },
  ],
})
export class GenericChooserComponent extends ValueAccessorBase<any> implements OnInit, OnChanges, OnDestroy {
  @Input() question: any;
  @ViewChild('inputRef', { static: true }) inputRef: ElementRef<HTMLInputElement>;

  @ViewChild('auto') auto: MatAutocomplete;

  private allOptions: DynamicDropdownOption[] = [];
  public filteredOptions: Observable<DynamicDropdownOption[]>;

  resultsListPos: any;
  @Output() valueChange = new EventEmitter()
  @Input() externalValue;

  public autoCompleteTextInput = new FormControl("");

  public newOption$: Subscription

  constructor(
    private dataService: DynamicAutoCompleteService,
    private changeDetectorRef: ChangeDetectorRef,
    private qs: QuestionService
  ) {
    super();
  }

  ngOnInit() {
    // we listen if there are new elements added and we update the data if so
    this.newOption$ = this.qs.newOptionOnChooser.subscribe(res => {

      if (res && res.data && res.data.idx) {
        this.updateOptions()
        this.value = res.data.idx
      }
    })
  }

  ngAfterViewInit(){
    this.filteredOptions = this.autoCompleteTextInput.valueChanges.pipe(
      map(value => {
        if (typeof value != "string") { value = ""; }
        let filtered = this._filter(value || '');
        if (filtered.length < 5) {
          filtered.push({ idx: 0, text: "**ADD NEW**", info: "" });
        }
        return filtered;
      })
    );

    this.updateOptions();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['externalValue']) {
      console.log(`[choose] ${this.question.label} change from external value `, this.externalValue);
      this.setAutoCompleteValue(this.externalValue);
    }
  }


  setAutoCompleteValue(value): void {



      //this.inputRef.nativeElement.value = 'Bupa'; // Won't work without this line
      // debugger;
      if (!this.auto){
        return;
      }
      const options = this.auto.options.toArray();

      if (options.length==0){
        console.log(`[choose] ${this.question.label} options not loaded waiting `);
        return;
      }

      console.log(`[choose] searching options for ${value}`, options);
      const found = options.find(f => { return +f.value.idx === +value; });
      if (found) {
        this.autoCompleteTextInput.setValue(found.value);
      } else {
        console.log("not found");
      }

    
  }

  private updateOptions(value = null) {
    this.dataService.get(this.question.idx).subscribe(res => {
      this.allOptions = res;
      this.autoCompleteTextInput.setValue(""); //this triggers an update to give us all the values
      if (this.externalValue){
        setTimeout(()=>{ this.setAutoCompleteValue(this.externalValue) }, 10); //have to delay to allow for options array to be updated on chooser
      }
    });
  }


  displayFn(data: any): string {
    let ret = data && data.text ? data.text : '';
    ret += data && data.info ? ` - ${data.info}` : '';
    return ret;
  }

  updateValue(event) {
    console.log("[choose] change ", event);
    this.value = event.option.value.idx;
    this.valueChange.emit(this.value);
  }


  private _filter(value: string): DynamicDropdownOption[] {
    console.log("[choose] why bad", value);

    if (!value || value == "") {
      return this.allOptions;
    }

    const filterValue = value.toLowerCase() || "";
    return this.allOptions.filter(option =>
      (option.text.toLowerCase().includes(filterValue) || (option.info && option.info.toLowerCase().includes(filterValue)) ));
  }

  setValue(v) {
    console.log(`[choose] will update and choose ${v} eeek`);
//    this.updateOptions(v);
  }

  ngOnDestroy() {
    this.newOption$.unsubscribe()
  }

}
