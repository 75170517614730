import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { EditorConfig } from 'app/models/editor-config';
import { KnownReplacements } from 'app/models/letter';
import { NotificationService } from 'app/services/notification.service';
import { EmailTemplatesService, Template } from '../email-templates.service';

@Component({
  selector: 'app-edit-template',
  templateUrl: './edit-template.component.html',
  styleUrls: ['./edit-template.component.scss']
})
export class EditTemplateComponent implements OnInit {
  public editorConfig: AngularEditorConfig = EditorConfig;
  public template: Template;
  public KnownReplacements = KnownReplacements;
  
  constructor(
    private email: EmailTemplatesService,
    private route: ActivatedRoute,
    private router: Router,
    private notification: NotificationService,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      this.getTemplate(params['templateName']);
    })
  }

  private getTemplate(name: string) : void {
    this.email.getTemplate(name).subscribe((res: Template) => {
      this.template = res;
    })
  }

  public save() : void {
    this.email.saveTemplate(this.template.idx, this.template).subscribe(
      res => {
        if (res) {
          this.notification.send('You have successfull saved the email template.')
          this.cancel();
        } else {
          this.notification.send("There was a problem saving the email template.")
        }
      }
    );
  }

  public cancel() : void {
    this.router.navigate(['../'], {relativeTo: this.activatedRoute})
  }

}
