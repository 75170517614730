<div class="container">
  <h3>Patient Purge</h3>
  <div class="d-flex flex-row my-2">
    <button mat-flat-button color="warn" (click)="openDialog()">
      Click to purge {{ selectedRows }} of {{ totalRows }}
    </button>
    <button
      mat-stroked-button
      name="export-button"
      class="ms-5"
      (click)="exportReport()"
    >
      <mat-icon aria-hidden="false" aria-label="Export report">forward</mat-icon
      >Export
    </button>
  </div>

  <div class="row">
    <form autocomplete="off" role="form" (ngSubmit)="updatePatientData()">
      <div class="styled-input left-margin">
        <label for="minAge">Minimum days since last appointment: &nbsp; </label>
        <mat-form-field>
          <input
            matInput
            type="number"
            class="form-control"
            id="minAge"
            name="minAge"
            [(ngModel)]="minAge"
          />
        </mat-form-field>
        <button mat-raised-button color="primary">Update</button>
      </div>
    </form>
  </div>

  <ag-grid-angular
    style="width: 100%; height: 800px"
    class="ag-theme-alpine-dark"
    [gridOptions]="gridOptions"
    [suppressRowClickSelection]="true"
    [rowSelection]="rowSelection"
    (selectionChanged)="updateSelection($event)"
  >
  </ag-grid-angular>
</div>
