import { Component} from '@angular/core';
import { MuscleNerveOptions, MuscleAndNerves, MusclesAndNerves } from '../models/muscles-and-nerves';
import { NotesBuilderBaseComponent } from 'app/notes/notes-builder/notes-builder-base/notes-builder-base-component';



@Component({
  selector: 'app-muscles-and-nerves',
  templateUrl: './muscles-and-nerves.component.html',
  styleUrls: ['./muscles-and-nerves.component.css']
})
export class MusclesAndNervesComponent extends NotesBuilderBaseComponent {
  
  public sections = MusclesAndNerves;
  
  public muscleNerveOptions = MuscleNerveOptions;

  public setAllMuscles(section: MuscleAndNerves[], value: string) {
    for (let item of section){
      this.form[`${item.model}_L`] = value;
      this.form[`${item.model}_R`] = value;
    }
  }
}