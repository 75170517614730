// Boring Angular Modules //
import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FastTextDirective } from './fast-text.directive';
import { FastTextComponent } from './fast-text.component';
import { FastTextService } from './fast-text.service';
import { OverlayModule } from "@angular/cdk/overlay";
import { MyMaterialModule } from "app/material/material.module";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    OverlayModule,
    MyMaterialModule
  
  ],
  declarations: [
    FastTextComponent,
    FastTextDirective,
  ],
  exports: [
    FastTextDirective,
    FastTextComponent
  ],
  providers: [
    FastTextService
  ]
})
export class FastTextModule {}
