<form autocomplete="off" #formEle="ngForm">
  <table class="table chtable">
    <thead>
      <tr>
        <th></th>
        <th>Primary Complaint</th>
        <th>Other Complaints</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let field of CaseHistoryFields">
        <td>
          {{field.label}}
          <button mat-flat-button color="secondary" class="text-muted button-link" 
            *ngIf="field.aide" 
            (click)="toggleAide(field)">
            <mat-icon>live_help</mat-icon>
          </button>

            <div *ngIf="field.showAide" class="alert alert-info aide-memoire">
              <div class="aide-memoire-text">Aide Memoire</div>
              <ul>
                  <li *ngFor="let aide of field.aide">{{aide}}</li>
              </ul>
          </div>
        </td>
        <td>
          <mat-form-field class="w-100">
            <textarea matInput 
            cdkTextareaAutosize
            cdkAutosizeMinRows="3"
            cdkAutosizeMaxRows="12"
            [name]="field.field+'1'"
            [(ngModel)]="form[field.field+'1']"></textarea>
          </mat-form-field>
        </td>
        <td>
          <mat-form-field class="w-100">
            <textarea matInput 
            cdkTextareaAutosize 
            cdkAutosizeMinRows="3"
            cdkAutosizeMaxRows="12"
            [name]="field.field+'2'"
            [(ngModel)]="form[field.field+'2']"></textarea>
          </mat-form-field>
        </td>
      </tr>
    </tbody>
  </table>
</form>