<div mat-dialog-title class="dialog-title">
    <h5>{{data.title}}</h5>
    <button mat-icon-button aria-label="close dialog" class="close-button" tabindex="-1" mat-dialog-close>
      <mat-icon class="close-icon text-muted">close</mat-icon>
    </button>
</div>

<div mat-dialog-content>
    <p>
        {{data.message}}
    </p>
    <mat-form-field class="w-100">
        <mat-label>New Password</mat-label>
        <input matInput type="password" [(ngModel)]="newPassword">
    </mat-form-field>

    <mat-form-field class="w-100">
        <mat-label>Confirm New Password</mat-label>
        <input matInput type="password" [(ngModel)]="confirmNewPassword">
    </mat-form-field>

    <div class="alert alert-danger" *ngIf="passwordError">{{ passwordError }}</div>
</div>

<div mat-dialog-actions>
    <button mat-button (click)="onDismiss()">Cancel</button>
    <button mat-raised-button color="primary" (click)="changePassword()">Submit</button>
</div>