//CONFIG is a global public setting that controls the look and feel of login page based on url
export class State {
    settings: {
        logo: string,
        site: string,
    };
    site: string
}

export const initialState = {
    settings: {
        logo: "",
        site: ""
    },
    site: ""
}