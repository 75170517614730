import { Component, Input, OnChanges, OnDestroy, OnInit, SimpleChanges } from '@angular/core';
import { type Attendance } from 'app/models/patient-details';
import { AppointmentColourService } from 'app/services/diary/appointment-colour.service';

interface AttendanceGraph{
    time: number;
    percentage: number;
    colour: string;
    description: string;
}

@Component({
  selector: 'my-attendance-graph',
  templateUrl: './my-attendance-graph.component.html',
  styleUrls: ['my-attendance-graph.component.scss']
})
export class MyAttendanceGraphComponent implements OnInit, OnChanges, OnDestroy{
  @Input() attendance: Attendance[] = [];
  public attendanceGraph: AttendanceGraph[] = [];
  years: {year: number, percentage: number, length: number}[];

  constructor(private appointmentColourService: AppointmentColourService) {}

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['attendance'] && this.attendance) {
        this.calculateAttendanceSpread();
    }
  }

  calculateAttendanceSpread(){
    this.attendanceGraph = [];
    this.years = [];
    if (this.attendance.length==0){
      return;
    }
    const zero = (this.attendance.length>1) ? this.attendance.length-1 : 0;

    const minAttendance = new Date(this.attendance[zero].startTime).getTime();
    const maxAttendance = Math.max( new Date(this.attendance[0].startTime).getTime(), new Date().getTime()) ;
    const diff = maxAttendance - minAttendance;

    const minYear = new Date(minAttendance).getFullYear();
    const maxYear = new Date(maxAttendance).getFullYear();


    for (let year = minYear; year <= maxYear; year++) {
      let percentage = 0;
      let length = 0;


      if (year == minYear){
        percentage = 0;
      } else {
        const t = new Date(year, 0, 1).getTime();
        percentage = ( ((t - minAttendance) / (maxAttendance - minAttendance)) * 100);
      }

      if (year == maxYear){
        length = 100 - percentage;
      } else {
        const tEnd = new Date(year+1, 0, 1).getTime();
        length =  (((tEnd - minAttendance) / (maxAttendance - minAttendance)) * 100) - percentage;
      }

      this.years.push({
        year: year,
        percentage: percentage,
        length: length
      });

    }



    console.log ("years", this.years);

    for (const att of this.attendance) {
        const t = new Date(att.startTime).getTime();
        this.attendanceGraph.unshift({
            time: t,
            percentage: Math.floor( ((t - minAttendance) / (maxAttendance - minAttendance)) * 100),
            colour: this.appointmentColourService.getColour(att.status, true),
            description: `${att.startTime}`
        });
    }
  }

}
