<div [id]='formName'>
    <form autocomplete="off" #formEle="ngForm">
        
        <div class="d-flex align-items-center">


            <app-notes-canvas 
                [(ngModel)]="form.data"
                [bgimage]="'/assets/images/abdomen.png'"
                name="AbdoCanvas"></app-notes-canvas>
       

            <div class="w-100">
                <table class="mx-5 table table-borderless">
                    <tr>
                        <td>1. Hypochondrium</td>
                        <td>2. Epigastrium</td>
                        <td>3. Hypochondrium</td>
                    </tr>
                    <tr>
                        <td>4. Lumbar</td>
                        <td>5. Umbilical</td>
                        <td>6. Lumbar</td>
                    </tr>
                    <tr>
                        <td>7. Iliac</td>
                        <td>8. Hypogastrium or Suprapubic</td>
                        <td>9. Iliac</td>
                    </tr>
                </table>
            </div>
        </div>
    </form>
</div>