import { Injectable } from '@angular/core';
import { DataService } from '../../services/data.service';

@Injectable()
export class PatientCompletedFormsService {
    constructor(private http: DataService) { }

    requestCompletion(formName: string, patientIdx: number, email: boolean, forms: any[]){
        let emailQ = "";
        if (email){
            emailQ = `&email=${email}`;
        }

        const formIds = forms ? Array.from(forms, f => f.idx) : false;

        return this.http.get(`/patient/forms/request/${formName}/${patientIdx}?forms=${JSON.stringify(formIds)}${emailQ}`);
    }

}
