<div mat-dialog-title class="dialog-title">
    <button mat-icon-button aria-label="close dialog" class="close-button" tabindex="-1" mat-dialog-close>
      <mat-icon class="close-icon text-muted">close</mat-icon>
    </button>
</div>

<div mat-dialog-content style="min-width: 80vw;">
    <dynamic-form
[formName]="'GPDetails'"
[multiple]="false"
[navigate]="false"
(loadComplete)="loadComplete($event)"
(saveComplete)="onConfirm($event)"
[isDialog]='data.isDialog'>

</dynamic-form>
</div>


