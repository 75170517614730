<div class="d-flex justify-content-center">
  <div class="set-all-btns">
    <h6 class="text-center">Set All</h6>
    <button [disabled]="isDisabled"  (click)="setRom(-1)" class="mb-1" mat-raised-button class="w-100 h-auto py-3">Reset
      <mat-icon>more_horiz</mat-icon>
    </button>
    <button [disabled]="isDisabled"  (click)="setRom(0)" class="mb-1" mat-raised-button class="yellow w-100 h-auto py-3">Restricted
      <mat-icon>panorama_fish_eye</mat-icon>
    </button>
    <button [disabled]="isDisabled"  (click)="setRom(1)" class="mb-1" mat-raised-button class="orange w-100 h-auto py-3">Painful
      <mat-icon>clear</mat-icon>
    </button>
    <button [disabled]="isDisabled" (click)="setRom(2)" class="mb-1" mat-raised-button class="red w-100 h-auto py-3">Painful and<br/>restricted
      <mat-icon>highlight_off</mat-icon>
    </button>
  </div>
  <form autocomplete="off" name="form" #formEle="ngForm">
    <table class="rom-table">
      <thead>
        <tr>
          <th></th>
          <th>L</th>
          <th></th>
          <th>R</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td></td>
          <td>
            <rom-field name="Oc_L" [(ngModel)]="form.Oc_L"></rom-field>
          </td>
          <td>Oc/C1</td>
          <td>
            <rom-field name="C1_R" [(ngModel)]="form.C1_R"></rom-field>
          </td>
          <td> </td>
        </tr>
        <tr>
          <td> </td>
          <td>
            <rom-field name="C1_L" [(ngModel)]="form.C1_L"></rom-field>
          </td>
          <td>C1/C2</td>
          <td>
            <rom-field name="C2_R" [(ngModel)]="form.C2_R"></rom-field>
          </td>
          <td> </td>
        </tr>
        <tr>
          <td> </td>
          <td>
            <rom-field name="C2_L" [(ngModel)]="form.C2_L"></rom-field>
          </td>
          <td>C2/C3</td>
          <td>
            <rom-field name="C3_R" [(ngModel)]="form.C3_R"></rom-field>
          </td>
          <td> </td>
        </tr>
        <tr>
          <td> </td>
          <td>
            <rom-field name="C3_L" [(ngModel)]="form.C3_L"></rom-field>
          </td>
          <td>C3/C4</td>
          <td>
            <rom-field name="C4_R" [(ngModel)]="form.C4_R"></rom-field>
          </td>
          <td> </td>
        </tr>
        <tr>
          <td> </td>
          <td>
            <rom-field name="C4_L" [(ngModel)]="form.C4_L"></rom-field>
          </td>
          <td>C4/C5</td>
          <td>
            <rom-field name="C5_R" [(ngModel)]="form.C5_R"></rom-field>
          </td>
          <td> </td>
        </tr>
        <tr>
          <td> </td>
          <td>
            <rom-field name="C5_L" [(ngModel)]="form.C5_L"></rom-field>
          </td>
          <td>C5/C6</td>
          <td>
            <rom-field name="C6_R" [(ngModel)]="form.C6_R"></rom-field>
          </td>
          <td> </td>
        </tr>
        <tr>
          <td> </td>
          <td>
            <rom-field name="C6_L" [(ngModel)]="form.C6_L"></rom-field>
          </td>
          <td>C6/C7</td>
          <td>
            <rom-field name="C7_R" [(ngModel)]="form.C7_R"></rom-field>
          </td>
          <td> </td>
        </tr>
        <tr>
          <td>Rib</td>
          <td>
            <rom-field name="C7_L" [(ngModel)]="form.C7_L"></rom-field>
          </td>
          <td>C7/C8</td>
          <td>
            <rom-field name="T1_R" [(ngModel)]="form.T1_R"></rom-field>
          </td>
          <td>Rib</td>
        </tr>
        <tr>
          <td>
            <rom-field name="T1_RIB_L" [(ngModel)]="form.T1_RIB_L"></rom-field>
          </td>
          <td>
            <rom-field name="T1_L" [(ngModel)]="form.T1_L"></rom-field>
          </td>
          <td>T1/T2</td>
          <td>
            <rom-field name="T2_R" [(ngModel)]="form.T2_R"></rom-field>
          </td>
          <td>
            <rom-field name="T2_RIB_R" [(ngModel)]="form.T2_RIB_R"></rom-field>
          </td>
        </tr>
        <tr>
          <td>
            <rom-field name="T2_RIB_L" [(ngModel)]="form.T2_RIB_L"></rom-field>
          </td>
          <td>
            <rom-field name="T2_L" [(ngModel)]="form.T2_L"></rom-field>
          </td>
          <td>T2/T3</td>
          <td>
            <rom-field name="T3_R" [(ngModel)]="form.T3_R"></rom-field>
          </td>
          <td>
            <rom-field name="T3_RIB_R" [(ngModel)]="form.T3_RIB_R"></rom-field>
          </td>
        </tr>
        <tr>
          <td>
            <rom-field name="T3_RIB_L" [(ngModel)]="form.T3_RIB_L"></rom-field>
          </td>
          <td>
            <rom-field name="T3_L" [(ngModel)]="form.T3_L"></rom-field>
          </td>
          <td>T3/T4</td>
          <td>
            <rom-field name="T4_R" [(ngModel)]="form.T4_R"></rom-field>
          </td>
          <td>
            <rom-field name="T4_RIB_R" [(ngModel)]="form.T4_RIB_R"></rom-field>
          </td>
        </tr>
        <tr>
          <td>
            <rom-field name="T4_RIB_L" [(ngModel)]="form.T4_RIB_L"></rom-field>
          </td>
          <td>
            <rom-field name="T4_L" [(ngModel)]="form.T4_L"></rom-field>
          </td>
          <td>T4/T5</td>
          <td>
            <rom-field name="T5_R" [(ngModel)]="form.T5_R"></rom-field>
          </td>
          <td>
            <rom-field name="T5_RIB_R" [(ngModel)]="form.T5_RIB_R"></rom-field>
          </td>
        </tr>
        <tr>
          <td>
            <rom-field name="T5_RIB_L" [(ngModel)]="form.T5_RIB_L"></rom-field>
          </td>
          <td>
            <rom-field name="T5_L" [(ngModel)]="form.T5_L"></rom-field>
          </td>
          <td>T5/T6</td>
          <td>
            <rom-field name="T6_R" [(ngModel)]="form.T6_R"></rom-field>
          </td>
          <td>
            <rom-field name="T6_RIB_R" [(ngModel)]="form.T6_RIB_R"></rom-field>
          </td>
        </tr>
        <tr>
          <td>
            <rom-field name="T6_RIB_L" [(ngModel)]="form.T6_RIB_L"></rom-field>
          </td>
          <td>
            <rom-field name="T6_L" [(ngModel)]="form.T6_L"></rom-field>
          </td>
          <td>T6/T7</td>
          <td>
            <rom-field name="T7_R" [(ngModel)]="form.T7_R"></rom-field>
          </td>
          <td>
            <rom-field name="T7_RIB_R" [(ngModel)]="form.T7_RIB_R"></rom-field>
          </td>
        </tr>
        <tr>
          <td>
            <rom-field name="T7_RIB_L" [(ngModel)]="form.T7_RIB_L"></rom-field>
          </td>
          <td>
            <rom-field name="T7_L" [(ngModel)]="form.T7_L"></rom-field>
          </td>
          <td>T7/T8</td>
          <td>
            <rom-field name="T8_R" [(ngModel)]="form.T8_R"></rom-field>
          </td>
          <td>
            <rom-field name="T8_RIB_R" [(ngModel)]="form.T8_RIB_R"></rom-field>
          </td>
        </tr>
        <tr>
          <td>
            <rom-field name="T8_RIB_L" [(ngModel)]="form.T8_RIB_L"></rom-field>
          </td>
          <td>
            <rom-field name="T8_L" [(ngModel)]="form.T8_L"></rom-field>
          </td>
          <td>T8/T9</td>
          <td>
            <rom-field name="T9_R" [(ngModel)]="form.T9_R"></rom-field>
          </td>
          <td>
            <rom-field name="T9_RIB_R" [(ngModel)]="form.T9_RIB_R"></rom-field>
          </td>
        </tr>
        <tr>
          <td>
            <rom-field name="T9_RIB_L" [(ngModel)]="form.T9_RIB_L"></rom-field>
          </td>
          <td>
            <rom-field name="T9_L" [(ngModel)]="form.T9_L"></rom-field>
          </td>
          <td>T9/T10</td>
          <td>
            <rom-field name="T10_R" [(ngModel)]="form.T10_R"></rom-field>
          </td>
          <td>
            <rom-field name="T10_RIB_R" [(ngModel)]="form.T10_RIB_R"></rom-field>
          </td>
        </tr>
        <tr>
          <td>
            <rom-field name="T10_RIB_L" [(ngModel)]="form.T10_RIB_L"></rom-field>
          </td>
          <td>
            <rom-field name="T10_L" [(ngModel)]="form.T10_L"></rom-field>
          </td>
          <td>T10/T11</td>
          <td>
            <rom-field name="T11_R" [(ngModel)]="form.T11_R"></rom-field>
          </td>
          <td>
            <rom-field name="T11_RIB_R" [(ngModel)]="form.T11_RIB_R"></rom-field>
          </td>
        </tr>
        <tr>
          <td>
            <rom-field name="T11_RIB_L" [(ngModel)]="form.T11_RIB_L"></rom-field>
          </td>
          <td>
            <rom-field name="T11_L" [(ngModel)]="form.T11_L"></rom-field>
          </td>
          <td>T11/T12</td>
          <td>
            <rom-field name="T12_R" [(ngModel)]="form.T12_R"></rom-field>
          </td>
          <td>
            <rom-field name="T12_RIB_R" [(ngModel)]="form.T12_RIB_R"></rom-field>
          </td>
        </tr>
        <tr>
          <td>
            <rom-field name="T12_RIB_L" [(ngModel)]="form.T12_RIB_L"></rom-field>
          </td>
          <td>
            <rom-field name="T12_L" [(ngModel)]="form.T12_L"></rom-field>
          </td>
          <td>T12/L1</td>
          <td>
            <rom-field name="L1_R" [(ngModel)]="form.L1_R"></rom-field>
          </td>
          <td>
            <rom-field name="L1_RIB_R" [(ngModel)]="form.L1_RIB_R"></rom-field>
          </td>
        </tr>
        <tr>
          <td> </td>
          <td>
            <rom-field name="L1_L" [(ngModel)]="form.L1_L"></rom-field>
          </td>
          <td>L1/L2</td>
          <td>
            <rom-field name="L2_R" [(ngModel)]="form.L2_R"></rom-field>
          </td>
          <td></td>
        </tr>
        <tr>
          <td> </td>
          <td>
            <rom-field name="L2_L" [(ngModel)]="form.L2_L"></rom-field>
          </td>
          <td>L2/L3</td>
          <td>
            <rom-field name="L3_R" [(ngModel)]="form.L3_R"></rom-field>
          </td>
          <td> </td>
        </tr>
        <tr>
          <td> </td>
          <td>
            <rom-field name="L3_L" [(ngModel)]="form.L3_L"></rom-field>
          </td>
          <td>L3/L4</td>
          <td>
            <rom-field name="L4_R" [(ngModel)]="form.L4_R"></rom-field>
          </td>
          <td> </td>
        </tr>
        <tr>
          <td>SI </td>
          <td>
            <rom-field name="L4_L" [(ngModel)]="form.L4_L"></rom-field>
          </td>
          <td>L4/L5</td>
          <td>
            <rom-field name="L5_R" [(ngModel)]="form.L5_R"></rom-field>
          </td>
          <td>SI </td>
        </tr>
        <tr>
          <td>
            <rom-field name="SI_L" [(ngModel)]="form.SI_L"></rom-field>
          </td>
          <td>
            <rom-field name="L5_L" [(ngModel)]="form.L5_L"></rom-field>
          </td>
          <td>L5/S1</td>
          <td>
            <rom-field name="S1_R" [(ngModel)]="form.S1_R"></rom-field>
          </td>
          <td>
            <rom-field name="SI_R" [(ngModel)]="form.SI_R"></rom-field>
          </td>
          <td> </td>
        </tr>


        <tr>
          <td> </td>
          <td>
            <rom-field name="S2_L" [(ngModel)]="form.S2_L"></rom-field>
          </td>
          <td>S1/S2</td>
          <td>
            <rom-field name="S2_R" [(ngModel)]="form.S2_R"></rom-field>
          </td>
          <td></td>
        </tr>
      </tbody>
    </table>
  </form>
</div>