<div [id]='formName'>
    <form autocomplete="off" #formEle="ngForm">
        <div class="row">
            <div class="col-sm-6">
                <app-notes-canvas [(ngModel)]="form.data" [bgimage]="'/assets/images/physicalExamNotesBkg1.png'"
                    name="extremitiesCanvas"></app-notes-canvas>
            </div>
            <div class="col-sm-6">
                <mat-form-field class="w-100">
                    <mat-label>Notes</mat-label>
                    <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="2" class='mb-3 mt-1' id="notes"
                        name="notes" [(ngModel)]="form.notes"></textarea>
                </mat-form-field>
            </div>
        </div>

    </form>
</div>