import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { PaymentMethod } from 'app/models/payment-methods';
import { NavigationService } from 'app/services/navigation.service';
import { PaymentMethodsService } from 'app/services/payment-methods.service';
import { take } from 'rxjs/operators';
import { PurchaseOrdersService } from '../purchase-orders/purchase-orders.service';
import { PurchaseOrder, PurchaseOrderItem } from "../../models/purchaseOrder";


@Component({
  selector: 'app-receive-purchase-order',
  templateUrl: './receive-purchase-order.component.html',
  styleUrls: ['./receive-purchase-order.component.scss']
})
export class ReceivePurchaseOrderComponent implements OnInit {
  public poDetails: PurchaseOrder;
  public total: number = 0;
  public outstanding = 0;
  public received: boolean;
  public paymentMethod: any;
  public paymentAmount: number;
  public updateError: boolean = false;
  public updateSuccess: boolean = false;
  public paymentMethods: { actual: PaymentMethod[], voucher: PaymentMethod[], insurance: PaymentMethod[] } = { actual: [], voucher: [], insurance: [] };

  constructor(
    private paymentMethodsService: PaymentMethodsService,
    private poService: PurchaseOrdersService,
    private route: ActivatedRoute, 
    private navigationService: NavigationService) {

      this.route.params.subscribe((params: Params) => {
        if ( params["poIdx"] ) this.getCurrentPO(params["poIdx"]);
      })
  }

  ngOnInit(): void {
    this.getPaymentMethods();
    this.paymentMethod = this.paymentMethods.actual[0] // set default payment method
    this.received = true
  }

  private getCurrentPO(poIdx: number): void {
    this.poService.getPOByIdx(poIdx).subscribe((currentPO: PurchaseOrder) => {
      this.poDetails = currentPO;
      this.poDetails.items.forEach(item => this.total += item.value * item.qty);
      this.poDetails.items.forEach(item => item.qty > item.qtyReceived && !this.received)
      this.poDetails.items.forEach(item => item.qtyToReceive = 0);
      this.paymentAmount = this.total - this.poDetails.amountPaid
      this.outstanding = this.total - this.poDetails.amountPaid;
    })
  }

  private getPaymentMethods(): void {
    this.paymentMethodsService.getPaymentMethods().subscribe((response: PaymentMethod[]) => {
      this.paymentMethods.actual = response.filter((p: PaymentMethod) => (!p.discountValue && p.name != "Insurance" && p.name != "Gift Voucher"));
    })
  }

  public receiveAll(): void {
    this.poDetails.items.forEach(item =>{
      item.qtyReceived = item.qty;
    })
    this.poDetails.received = true
    this.save()
  }

  public paidInFull(): void {
    this.poDetails.amountPaid = this.total;
    this.save()
  }

  public payBy(method: any): void {
    this.paymentMethod = method;
    this.poDetails.paymentMethod = method.name;
  }

  private allReceived(): boolean {
    return this.poDetails.items.findIndex((item: PurchaseOrderItem) => item.qtyReceived !== item.qty) === -1;
  }

  public submit(): void {
    this.save();
  }

  public save(): void {
    this.poDetails.items.forEach(item => item.qtyReceived += item.qtyToReceive);
    if ( !this.paymentMethod ) this.paymentMethod = this.paymentMethods.actual.filter((p: PaymentMethod) => p.name === this.poDetails.paymentMethod)[0];

    this.poDetails.paymentMethod = (this.paymentMethod?.idx || null);
    this.poDetails.amountPaid = Number( this.poDetails.amountPaid) + this.paymentAmount

    this.poService.postPurchaseOrder(this.poDetails).pipe(take(1)).subscribe((response: PurchaseOrder) => {
      if ( response ) {
        this.poDetails = response;
        this.updateSuccess = true;
        setTimeout(() => this.updateSuccess = false, 5000);
      } else {
        this.updateError = true;
        setTimeout(() => this.updateError = false, 5000);
      }
      this.back();
    });
  }

  public back(): void {
    this.navigationService.back();
  }
}
