import { Injectable } from '@angular/core';
import { ReportsService } from 'app/reports/reports.service';
import { PatientChooserService } from 'app/services/patient.service';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, map } from 'rxjs/operators';
import { DataService } from '../data.service';
import { Store } from '@ngrx/store';
import * as fromRoot from '../../store/reducers';
import * as notesActions from '../../store/actions/notes';
import * as fromNotes from 'app/models/notes';
import { EMPTY } from 'rxjs';

@Injectable()
export class NotesService {

    private patientIdx$;
    private _patientIdx

    constructor(private http: DataService, private patientService: PatientChooserService, private reports: ReportsService, private store: Store<fromRoot.State>) {
        this.patientIdx$ = this.patientService.getCurrentPatientIdx().subscribe(res => {
            console.log(`[pc] sets [notes] we have updated patientIdx=${res}`);
            this._patientIdx = res;
        });
    }

    getData(formName: string, notesIdx: number, patientIdx: number = null): Observable<any> {

        let qParams = "";
        patientIdx = this._patientIdx;
        /*
        if ( !notesIdx && !patientIdx) {
            patientIdx = this._patientIdx
        }
        */
        console.log(`[pc] sets [notes] asks for [form] ${formName} with noteIdx=${notesIdx} and patientIdx=${patientIdx}`);

        if (patientIdx) {
            qParams = `?patientIdx=${patientIdx}`
        }        

        if (!formName || ( !notesIdx && !patientIdx)) {
            console.warn(`[notes] lookup refused for form=${formName} and nIdx=${notesIdx} and patient = ${patientIdx}`);
            return EMPTY;
        }

        
        const url=`/notes/data/${formName}/${notesIdx}${qParams}`;
        console.log("[notes] URL=", url);
        return this.http.get<any>(url).pipe(map(res =>{
            console.log(" internal ref checks on", res);
            
            this.setLatestStatus({idx: res.notesIdx, formName: formName});
    
            this.checkPatientIdMatches(res.patientIdx);
            return res;
        }));
    }

    saveData(notesIdx: number, patientIdx: number, newSoap): Observable<any> {
        const payload = { soap: newSoap };
        return this.http.post<any>(`/notes/soap/${notesIdx}/${patientIdx}`, payload)
    }

    deleteData(notesIdx: number, patientIdx: number, reason: any): Observable<any> {
        const payload = { reason: reason, deleted: true }
        return this.http.post(`/notes/soap/${notesIdx}/${patientIdx}/`, payload)
    }

    getDrawing(notesIdx: number, canvasId: number): Observable<any> {
        return this.http.get<any>(`/notes/canvas/${notesIdx}/${canvasId}`);
    }

    saveConsultation(patientIdx: number, data: any) {
        const payload = { data: data }
        return this.http.post(`/notes/telephone/${patientIdx}`, payload)
    }

    getTelephoneConsultations(patientIdx){
        return this.http.get(`/notes/telephone/${patientIdx}`);
    }

    getGPData(gpIdx: number) {
        return this.http.get(`/gps/${gpIdx}`)
    }

    emailLetter(type: string, notesIdx: number, patientIdx: number, email: string, html: any, subject: string) {
        const payload = {
            emailSubject: subject,
            idx: notesIdx,
            patientIdx: patientIdx,
            emailTo: email,
            email: html,
            type: type
        };

        console.log(payload);
        
        return this.http.post(`/letter/send`, payload);
    }

    

    newNote(patientIdx: number, type: string, description: string) {
        const payload = {
            patientIdx: patientIdx,
            notesType: type,
            description: description,
            type: "Adult",
            action: "add"
        }
        return this.http.post('/notes', payload)
    }

    patientIdx(): Observable<number> {
        return this.patientService.getPatient().pipe(map(patient => {
            if (patient && patient.idx) {
                return patient.idx;
            }
            return null;
        }),
            distinctUntilChanged()
        );
    }

 // this seems to be some sort of left over from when we rewrote notes and sign off that never got reimplemented :thinking:
    public setLatestStatus(payload: fromNotes.LatestStatus): void {
        this.store.dispatch(notesActions.SetLatestStatus({ payload }));
    }



    public getLatestStatus(): Observable<fromNotes.LatestStatus> {
        return this.store.select(fromRoot.getLatestStatus).pipe(distinctUntilChanged(), debounceTime(100));

    }


    public checkPatientIdMatches(patientIdx) {
        if (this._patientIdx != patientIdx && patientIdx > 0) {
            console.log(`[pc] sets [patient] id mis match, selection correct one ${patientIdx}`);
            this._patientIdx = patientIdx; // lock it now so we don't call this again.
            this.patientService.setPatient(patientIdx);
        }
    }

    saveDiagnosis(data:any): Observable<any> {    
        return this.http.post<any>(`/forms/0/${data.idx}`, data)
    }

    getDiagnosisList(patientIdx: number){
        return this.http.get<any>(`/notes/diagnosis/${patientIdx}`);
    }

    setDiagnosis(notesIdx: number, diagnosis: number): Observable<any> {
        return this.http.post<any>(`/notes/diagnosis/${notesIdx}`, {diagnosis: diagnosis});
    }
}

