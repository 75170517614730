<div *ngIf="form" [id]='formName'>
    <form autocomplete="off"  #formEle="ngForm">
      <mat-form-field *ngFor="let field of OtherTestsFields">
        <mat-label>{{field.label}}</mat-label>
        <textarea 
          matInput 
          cdkTextareaAutosize
          cdkAutosizeMinRows="2" 
          class='mb-3 mt-1' 
          [(ngModel)]="form[field.field]" 
          [name]="field.field"></textarea>
      </mat-form-field>
    </form>
  </div>