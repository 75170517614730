import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { DialogService } from 'app/services/dialog/dialog.service';
import { PatientChooserService } from 'app/services/patient.service';


@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.css']
})
export class NotesComponent {
  public patientIdx;
  
  constructor (
      private patientService: PatientChooserService
      ){

    this.patientService.getPatient().subscribe(res=>{
      console.log("[notes pc]", res);
        if (!res) {
          this.patientChanged(null );
        } else {
          this.patientChanged(res.idx || null );
        }
        
    });
  }

  patientChanged(id){
    console.log("[changed]", id);
    this.patientIdx = id;
//    if (!id){
//      this.router.navigate(['notes']);
//      return;
//    }
//    this.router.navigate(['notes', id]);
//    return;
  }
}
