//export const MuscleNerveOptions = ["0","1","2","3","4","4+","5"];
export const MuscleNerveOptions = ["4+", "4", "3" , "2", "1", "0"];
export interface MuscleAndNerves 
{
  name: string;
  model: string;
}

const first: MuscleAndNerves[] = 
  [
    {
      name: "Traps",
      model: "C2-4"
    },
    {
      name: "Deltoid",
      model: "C4-5"
    },
    {
      name: "Biceps",
      model: "C5-6"
    },
    {
      name: "Triceps",
      model: "C5-T1"
    },
    {
      name: "Finger Extensions",
      model: "C7"
    },
    {
      name: "Flx Poll Lng",
      model: "C7-T1"
    },
    {
      name: "Interossei",
      model: "C8-T1"
    },
    {
      name: "Supraspinatus",
      model: "C4-6"
    },
    {
      name: "Ext Carpri Rad",
      model: "C6-7"
    },
    {
      name: "Pect Min",
      model: "C7-T1a"
    }
  ];

  const second: MuscleAndNerves[] = 
  [
    {
      name: "Illio-Psoas",
      model: "L1-4"
    },
    {
      name: "Quads",
      model: "L2-4"
    },
    {
      name: "Hip Abductors",
      model: "L2-L4"
    },
    {
      name: "Tib Anterior",
      model: "L4-S1"
    },
    {
      name: "Ext. Hall Lng",
      model: "L4-S1a"
    },
    {
      name: "Peroneus",
      model: "L4-S1b"
    },
    {
      name: "Flex Hall L and B",
      model: "L4-S2"
    },
    {
      name: "Hamstrings",
      model: "L5-S1"
    },
    {
      name: "Piriformis",
      model: "S1-S2"
    }
  ];

  export const MusclesAndNerves = [
    first, second
  ]
