<div [formGroup]="form" class="form-group full-width">
  <label style="margin-right: 40%;">{{question.label}}</label>
  <div style="width:50%">
    <input type="range" 
      min="0"
      max="10" 
      step="1"
      name="rangerSlider"
      [(ngModel)]="question.value" 
      [id]="question.key"
      [formControlName]="question.key" 
      style="width: '90%';" /><label style="float: right;">{{question.value}}</label>
  </div>
  <mat-error *ngIf="fieldInvalid() && showInvalidField">{{fieldInvalidError()}}</mat-error>
</div>
