import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from "@angular/core";
import { FormControl, NG_VALUE_ACCESSOR } from "@angular/forms";
import { ValueAccessorBase } from "../value-accessor-base";
import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { MatAutocomplete, MatAutocompleteTrigger } from "@angular/material/autocomplete";
import { StaffService } from "app/services/staff.service";
import { type  StaffMember } from "app/models/staff";


@Component({
    selector: "app-staff-chooser",
    templateUrl: "./staff-chooser.component.html",
    styleUrls: ["./staff-chooser.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: StaffChooserComponent,
            multi: true,
        },
    ],
})
export class StaffChooserComponent extends ValueAccessorBase<any> implements OnInit, OnChanges, AfterViewInit {

    @ViewChild('inputRef', { static: true }) inputRef: ElementRef<HTMLInputElement>;
    @ViewChild('auto') auto: MatAutocomplete;

    private allOptions: StaffMember[] = [];
    public filteredOptions: Observable<StaffMember[]>;
    public showStaffName = "";

    resultsListPos: any;
    @Output() valueChange = new EventEmitter();
    @Input() externalValue;
    @Input() shown; 

    public autoCompleteTextInput: FormControl = new FormControl("");

    constructor(
        private staffService: StaffService,
        private changeDetectorRef: ChangeDetectorRef
    ) {
        super();
    }

    ngOnInit() {

        this.staffService.getStaffAlphabetical().subscribe((staff: StaffMember[]) => {
            this.allOptions = staff;
        });

        this.filteredOptions = this.autoCompleteTextInput.valueChanges.pipe(
            map(value => {
                if (typeof value != "string") { value = ""; }
                const filtered = this._filter(value || '');
                return filtered;
            })

        );
    }

    ngAfterViewInit() {
       // this.autoCompleteTextInput.setValue(""); // this triggers an update to give us all the values
    }

    ngOnChanges(changes: SimpleChanges){
        if (changes['shown']) { // used in reports when chooser get shown to trigger update.
            this.autoCompleteTextInput.setValue("");
            this.inputRef.nativeElement.focus();
        }

    }

    setAutoCompleteValue(value) {
        setTimeout(() => {
            const options = this.auto.options.toArray();
            this.autoCompleteTextInput.setValue(options.find(f => {
                return f.value.idx === value;
            }).value);

        }, 5);
    }

    writeValue(value) {
        super.writeValue(value);
        const staff = this.allOptions.find(f=>{
            if (f.idx == value){
                return true;
            }
        });
        if (staff){
            this.showStaffName = `${staff.firstname} ${staff.lastname}`;
        }

    }

    displayFn(data: any): string {
        let ret = data && data.text ? data.text : '';
        ret += data && data.info ? ` - ${data.info}` : '';
        return ret;
    }

    updateValue(event) {
        console.log(`[chooser] will update value `, event);
        this.value = event.option.value.idx;
        this.valueChange.emit(event.option.value);
        this.showStaffName = `${event.option.value.firstname} ${event.option.value.lastname}`;
    }


    private _filter(value: string): StaffMember[] {
        if (!value || value == "") {
            return this.allOptions;
        }

        const filterValue = value.toLowerCase() || "";
        return this.allOptions.filter(option => (
            option.firstname.toLowerCase().includes(filterValue) || option.lastname.toLowerCase().includes(filterValue)
        ));
    }

    setValue(v) {
        console.log(`[chooser] will update and choose ${v} eeek`);
    }

}
