<div class="mobile-buttons">

  <div class="m3-3 text-center mobile-button" *ngIf="isSaveUsed">
    <div class="button-container">
      <div class="spinner-container" *ngIf="saving">
        <mat-spinner diameter="24"></mat-spinner>
      </div>
      <button mat-raised-button
        [disabled]="saving || noSave" 
        class="submit-cancel" 
        color="primary" 
        type="submit" (click)="save()">Save</button>
    </div>
  </div>

  <div class="my-3 text-center mobile-button" *ngIf="isCancelUsed">
    <button mat-raised-button class="submit-cancel" (click)="cancel()">Back</button>
  </div>
</div>


