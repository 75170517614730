import { Component, OnInit } from '@angular/core';
import { NotesBuilderBaseComponent } from 'app/notes/notes-builder/notes-builder-base/notes-builder-base-component';
import { SpinalRedFlags } from '../models/spinal-red-flags'

@Component({
  selector: 'app-spinal-red-flags',
  templateUrl: './spinal-red-flags.component.html',
  styleUrls: ['./spinal-red-flags.component.scss']
})
export class SpinalRedFlagsComponent extends NotesBuilderBaseComponent {
  showAide: boolean = false
  SpinalRedFlags = SpinalRedFlags

  public toggleAide() {
    this.showAide = !this.showAide
  }
}
