import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ValueAccessorBase } from 'app/custom-form-controls/value-accessor-base';
import { MuscleNerveOptions } from 'app/notes/form-components/models/muscles-and-nerves';

@Component({
  selector: 'app-muscle-field',
  templateUrl: './muscle-field.component.html',
  styleUrls: ['./muscle-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: MuscleFieldComponent,
      multi: true,
    }]
})
export class MuscleFieldComponent extends ValueAccessorBase<string> {

  @Input() label;
    
  public muscleNerveOptions = MuscleNerveOptions;
  
}
