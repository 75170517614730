import { Component, ViewChild, ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { ClinicService } from 'app/services/clinic.service';
import { LoadingService } from 'app/services/loading.service';
import { PandLService } from './p-and-l.service';
import { ExportService } from 'app/services/export.service'
import moment from 'moment';

@Component({
  selector: 'p-and-l',
  templateUrl: './p-and-l.component.html',
  styleUrls: ['./p-and-l.component.css'],
  providers: [PandLService]
})
export class PandLComponent {
  public data: any = [];
  public treatments: any = [];
  public clinics: any[] = [];
  public itemSales: any[] = [];
  public commission: any[] = [];
  public vouchers: any[] = [];
  public giftVouchers: any[] = [];
  public moneyInFromSales: any[] = [];
  public subcats: any = [];
  public currentStartDate: any;
  public currentEndDate: any;
  public totalRevenue: number[] = [];
  public totalExpenditure: number[] = [];
  public totalGOP: number[] = [];
  public totalGOPPercentage: any[] = [];
  public headings: any[] = [];
  public headingsSpans: any[] = [];
  public costOfSalesOpen: boolean = false;
  public months: string[] = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  private itemSaleHeadings: string[] = ['Treatment', 'Charges', 'Sale', 'Credits', 'Discounts', 'Inter account Xfer'];
  private itemSaleHeaders: string[] = ['treatment', 'charges', 'sale', 'credits', 'discount', 'transfers'];
  public startDate = moment();

  constructor(private plService: PandLService, private clinicService: ClinicService, private router: Router, public loading: LoadingService,
    private exportService: ExportService) {
    const today = moment();
    const lastYear = moment().subtract(1, 'year');
    this.startDate = moment([lastYear.year(), today.month(), today.date()]);
    this.clinicService.getClinics().subscribe((clinics: any[]) => {
      this.clinics = clinics.map((clinic: any) => { return { ...clinic, selected: true } });
    });

    this.startDateChanged(null);
    this.getIncome();
  }
  @ViewChild('userTable') userTable: ElementRef;

  ngOnInit() { }

  public spread(items: any): any {
    return this.plService.spread(items, this.headings);
  }

  public startSorting() {
    this.itemSales = [];
    this.vouchers = [];
    this.giftVouchers = [];
    this.moneyInFromSales = [];
    this.subcats = [];
    this.totalRevenue = [];
    this.totalExpenditure = [];
    this.totalGOP = [];
    this.totalGOPPercentage = [];
    this.commission = [];

    this.itemSaleHeaders.forEach((header: string, index: number) => {
      this.sortItem(header, this.itemSaleHeadings[index]);
    })

    this.commission = this.spread(this.data.commissions);
    this.vouchers = this.spread(this.data.vouchers);
    this.giftVouchers = this.spread(this.data.giftVouchers);

    this.moneyInFromSales = this.data.moneyInFromSales && this.data.moneyInFromSales.length ? this.populateWithSubCategories(this.data.moneyInFromSales, this.moneyInFromSales) : [];
    this.subcats = this.data.outgoings && this.data.outgoings.length ? this.populateWithSubCategories(this.data.outgoings, this.subcats) : [];

    this.getTotalRevenue();
    this.getTotalExpenditure();
    this.getTotalGOP();

    this.loading.stop();
  }

  public populateWithSubCategories(fromArray: any, toArray: any): any {
    return this.plService.populateWithSubCategories(fromArray, toArray, this.data.subcats, this.headings);
  }

  public sortItem(header: string, heading: string): void {
    const itemSales = this.plService.sortItem(header, heading, this.data.itemSales, this.headings);

    this.itemSales.push(itemSales);
  }

  public rowExists(items: any): boolean {
    let exist = false;
    items.forEach((item: any) => {
      if (item !== 0 && item !== "0.00") exist = true;
    })

    return exist;
  }

  public getTotalRevenue(): void {
    this.totalRevenue = this.plService.getTotalRevenue(this.itemSales, this.moneyInFromSales, this.vouchers, this.giftVouchers, this.commission);
  }

  public getTotalExpenditure(): void {
    this.totalExpenditure = this.plService.getTotalExpenditure(this.subcats);
  }

  public getTotalGOP(): void {
    const totals = this.plService.getTotalGOP(this.totalRevenue, this.totalExpenditure);

    this.totalGOP = totals[0];
    this.totalGOPPercentage = totals[1];
  }

  public generateHeadings(startDate: string): void {
    const processedHeadings = this.plService.generateHeadings(startDate, this.months);

    this.headings = processedHeadings.headings;
    this.headingsSpans = processedHeadings.headingsSpans;
  }

  public getSelectedClinics(field: string): string {
    const clinics = this.clinics.filter((clinic: any) => clinic.selected);

    if (field === "name") return clinics.map((clinic: any) => clinic.name).join(", ");
    if (field === "idx") return clinics.map((clinic: any) => clinic.idx).join(",");
  }

  public clinicsChanged(): void {
    this.getIncome();
  }

  startDateChanged(event: any): void {
    const endDate = moment(this.startDate).add(1, 'y');
    this.currentStartDate = this.startDate.format('YYYY-MM-DD');
    this.currentEndDate = endDate.format('YYYY-MM-DD');
    this.getIncome();
  }

  private getIncome(): void {
    const clinicsString = this.getSelectedClinics("idx");

    this.loading.start();

    this.plService.getIncome(this.currentStartDate, this.currentEndDate, clinicsString).subscribe((income: any) => {
      this.data = income;

      this.generateHeadings(this.currentStartDate);
      this.startSorting();
    })
  }

  public navigateToReport(reportName: string, array: any, arrayIndex: number, typename: string = null): void {
    this.plService.navigateToReport(this.headings, this.currentStartDate, this.currentEndDate, this.getSelectedClinics("idx"), reportName, array, arrayIndex, typename);
  }

  exportElmToExcel(): void {
    this.exportService.exportTableElmToExcel(this.userTable, 'profit&loss');
  }

}