import { Injectable } from '@angular/core';
import { State } from '../../models/auth';
import { ActionWithPayload } from '../reducers';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { map, catchError, mergeMap, filter } from 'rxjs/operators';
import { DataService } from '../../services/data.service';
import { EMPTY, Observable, Subject, throwError } from 'rxjs';
import * as fromAuthActions from '../actions/auth';
import * as fromAuth from '../../models/auth';
import { AuthService } from 'app/services/auth.service';
import { Router } from '@angular/router';
import { NotificationService } from 'app/services/notification.service';
import { AuthenticationResult } from '@azure/msal-browser';
import { MsalService } from '@azure/msal-angular';
import { LoadingService } from 'app/services/loading.service';

interface twoFactorAuthenticationResponse {
    tfa: boolean; 
    message: string
}

@Injectable()
export class AuthEffects {
    public twoFactorAuthenticationNeeded$: Subject<string>;

    constructor(
        private actions$: Actions,
        private http: DataService,
        private authService: AuthService,
        private msalService: MsalService,
        private notifications: NotificationService,
        private router: Router,
        private loading: LoadingService
      ) {
        this.twoFactorAuthenticationNeeded$ = new Subject();
    }

    public get2FANeeded() {
        return this.twoFactorAuthenticationNeeded$;
    }

    LogIn$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(fromAuthActions.ActionTypes.LOGIN),
            map((action: ActionWithPayload) => {
                this.loading.start();
                return action;
            }),
            mergeMap((action: ActionWithPayload) => {
                return this.http.post<fromAuth.State | twoFactorAuthenticationResponse>('/authenticate', action.payload).pipe(
                    catchError(error => {
                        this.authService.authFailed();
                        this.notifications.send("Incorrect username or password.");
                        this.loading.stop();
                        return throwError(error);
                    }),
                    map((serverResponse: fromAuth.State | twoFactorAuthenticationResponse) => {
                        if ( (serverResponse as twoFactorAuthenticationResponse).tfa ) {
                            this.twoFactorAuthenticationNeeded$.next((serverResponse as twoFactorAuthenticationResponse).message);
                            this.loading.stop();
                        } else if ( (serverResponse as fromAuth.State).jwt ) {
                            this.authService.afterLogin((serverResponse as fromAuth.State));
                            this.loading.stop();
                        } else {
                            this.authService.authFailed();
                            this.loading.stop();
                            this.notifications.send("Incorrect username or password.");
                        }
                    })
            )
            })
        )
    }, { dispatch: false })

    LogInWithCode$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(fromAuthActions.ActionTypes.LOGIN_WITH_CODE),
            mergeMap((action: ActionWithPayload) => {
                return this.http.post<fromAuth.State>('/authenticate', action.payload).pipe(
                    catchError(error => {
                        this.authService.authFailed();
                        this.notifications.send("Incorrect code.");
                        return throwError(error);
                    }),
                    map((userState: fromAuth.State) => {
                        if ( userState.jwt ) {
                            this.authService.afterLogin(userState);
                        } else {
                            this.authService.authFailed();
                            this.notifications.send("Incorrect code.");
                        }
                    })
            )
            })
        )
    }, { dispatch: false })

    LogInSSO$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(fromAuthActions.ActionTypes.LOGINSSO),
            mergeMap((action: ActionWithPayload) => 
                this.authService.logInWithSSO().then(
                    (ObservableResponse: Observable<AuthenticationResult>) => {
                        ObservableResponse.subscribe((response: AuthenticationResult) => {
                            this.msalService.instance.setActiveAccount(response.account);
                            this.authService.retrieveToken();
                        }, (err => {
                            this.authService.authFailed();
                            console.log("[auth] [sso] error", err);
                        }));
                }).catch(err => {
                    this.authService.authFailed();
                    console.log("[auth] [sso] error", err);
                })
                )
        )
    }, { dispatch: false })

    LogOut$ = createEffect(() => { 
        return this.actions$.pipe(
            ofType(fromAuthActions.ActionTypes.LOGOUT),
            mergeMap((action: ActionWithPayload) => this.http.get<any>("/auth/logout").pipe(
                map(response => {
                        console.log("[reduce] logged out")
                        this.router.navigate(["logout"]);
                        this.authService.clearRefreshTokenTimer();
                        return fromAuthActions.LogoutCleanupAction();
                })))
        )
    });

    refreshToken$ = createEffect(() => { 
        return this.actions$
        .pipe(
        ofType(fromAuthActions.ActionTypes.REFRESH),
        mergeMap((action: ActionWithPayload) => this.http
            .get<State>("/auth/refresh")
            .pipe(
            map(authData => {
                return fromAuthActions.UpdateAction({payload: authData});
            })
            )
        )
        )
    });

}