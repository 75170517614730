import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { InitalisationService } from "app/services/initialisation-service";


import { LoadingService } from "app/services/loading.service";


@Component({
    selector: 'loading-page',
    templateUrl: './loading-page.component.html',
    styleUrls: ['../login/login.component.scss', './loading-page.component.scss']
})

export class LoadingPageComponent implements OnInit, OnDestroy{

    private checkOk$;

    constructor (
        private loading: LoadingService, 
        private route: ActivatedRoute,
        private router: Router,
        public initService: InitalisationService){   }

    ngOnInit(){
        if (this.route.snapshot.queryParamMap.get('refresh')){
            this.initService.softResetState();
            setTimeout(() => {
                this.startChecks();
            }, 2000);
            return;
        }

        if (this.checkOk$){
            return;
        }

        this.startChecks();
        
    }

    startChecks(){
        this.initService.init();
        this.loading.stop();
        this.checkOk$ = setInterval(()=>{
            if (this.initService.checkRequirements()){
                this.router.navigate(['/']);
            }
        }, 1000);
    }

    ngOnDestroy(){
        if (this.checkOk$){
            clearInterval(this.checkOk$);
        }
    }

  
}
 