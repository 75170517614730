//control if the app is busy and locked
import { ActionWithPayload } from './index';
//import { createSelector } from 'reselect';

import * as roomsAction from '../actions/rooms';
import { Room } from '../../models/rooms';

export interface State {
    rooms: Room[];
};

export const initialState: State = {
    rooms: []
};


export function reducer(state = initialState, action: ActionWithPayload): State {
    switch (action.type) {
        case roomsAction.ActionTypes.SET: {
            return Object.assign({}, state, { rooms: action.payload });
        }
        default:
            return state;
    }
}

export const getRooms = (state: State) => state.rooms;
