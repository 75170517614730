import { Component, Input, SimpleChanges, Output, EventEmitter, ViewChild, OnInit, AfterViewInit, OnChanges, OnDestroy } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { DynamicFormSaveService } from 'app/dynamic-form/services/dynamic-form-save.service';
import { LatestStatus } from 'app/models/notes';
import {  DialogService } from 'app/services/dialog/dialog.service';
import { FormDialogQuestion, FormOptions, returnTypes } from "../../models/dialog";
import { PatientChooserService } from 'app/services/patient.service';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { CsvService } from 'app/services/csv.service';
import { NotesService } from 'app/services/notes/notes.service';
import { NotesNewDialogComponent } from 'app/dialogs/notes-new/notes-new-dialog.component';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { PatientCompletedFormsService } from 'app/services/notes/patient-completed-forms.service';
import { NotificationService } from 'app/services/notification.service';
import { SiteSettingsService } from 'app/services/site-settings.service';
import { NotesSaveService } from 'app/services/notes/notes-save.service';
import { LoadingService } from 'app/services/loading.service';


@Component({
  selector: 'info-notes-table',
  templateUrl: './info-notes-table.component.html',
    styleUrls: ['info-box.component.css', '../info-table-shared.css']
})
export class InfoNotesTableComponent implements OnInit, AfterViewInit, OnChanges, OnDestroy {
  @Input() info: any;
  @Input() formName: string;
  @Input() formFriendlyName = "";
  @Output() loadNotes = new EventEmitter<{idx: number, description?: string, isOldLetter?: boolean }>();
  @ViewChild(MatSort) sort: MatSort;

  public dataSource: MatTableDataSource<any>;
  public description: string;
  public shownCard = false;
  public strikethrough = false;
  private statusSubscription$: Subscription;
  displayedColumns: string[] = ['idx', 'dateAdded', 'lastUpdated', 'description', 'user'];

  public selectedNotes: { idx: number; description: string; } = { idx: null, description: null };
  public notes: any;
  private settings$: Subscription;
  protected signOffEnabled = false;



  constructor(private route: ActivatedRoute,
              private csvService: CsvService,
              public  dialog: MatDialog,
              private patient: PatientChooserService,
              private notesService: NotesService,
              private dialogService: DialogService,
              private notificationService: NotificationService,
              private form: DynamicFormSaveService,
              private patientCompletedFormService: PatientCompletedFormsService,
              private settings: SiteSettingsService,
              private notesSaveService: NotesSaveService,
              private loading: LoadingService) {
  }

  ngOnInit() {
    const statusRequired = ["notes_casehistory", "notes_physicalexam", "notes_casesummary"];


    this.info = this.sortNotesByLastUpdatedDate(this.info);

    this.statusSubscription$ = this.notesService.getLatestStatus().subscribe((latestStatus: LatestStatus) => {
      this.updateStatus(latestStatus);
    });

    this.settings$ = this.settings.getNotesSettings().subscribe(res => {
      this.signOffEnabled = res.enableSignOff;
      if ( this.signOffEnabled && statusRequired.includes(this.formName) ) { this.displayedColumns.push('status'); }
    });
  }

  ngOnDestroy(): void {
    if ( this.statusSubscription$ ) {
      this.statusSubscription$.unsubscribe();
    }
    this.settings$.unsubscribe();
  }

  ngAfterViewInit() {
   // this.dataSource.paginator = this.paginator;

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.info) {
      this.dataSource = new MatTableDataSource(this.info);
      this.dataSource.sort = this.sort;
      this.info = this.sortNotesByLastUpdatedDate(changes.info.currentValue);
    }
  }



  sortNotesByLastUpdatedDate(notes: any): any {
    return notes && notes.length ? notes.slice().sort((noteA: any, noteB: any) => new Date(noteB.lastUpdated).getTime() - new Date(noteA.lastUpdated).getTime()) : notes;
  }

  private updateStatus(latestStatus: LatestStatus): void {
    console.log(`[notes] add new may get called for ${this.formName}`, latestStatus);
    if (latestStatus.formName == this.formName  && !latestStatus.idx) {
   //   this.addNewNotes();
      return;
    }


    if ( this.info && this.info.length ) {

      if (latestStatus.idx > 0 && this.selectedNotes && this.selectedNotes.idx != latestStatus.idx) {
        this.selectedNotes = this.info.find(f => (f.idx == latestStatus.idx));
      }

    }


  }

  public selectNote(notesIdx: number, type: string): void {
    console.log("[notes] load emit");
    this.selectedNotes = { description: null, idx: notesIdx };
    if (type === 'SavedLetters') {
      this.loadNotes.emit({ idx: Number(notesIdx), isOldLetter: true });
    } else {
      this.loadNotes.emit({ idx: Number(notesIdx) });
    }
  }

  delete(e: any) {
    if (this.formName) {
      this.dialogService.formDialog('Delete Record', "Are you sure you want to delete record? If so please enter a reason below", [new FormDialogQuestion('Reason', 'reason', 'textarea', null)], null, ['Cancel'], ['Delete'])
      .then( (res: any) => {
        if (res.data) {
          const row = e.srcElement.parentNode.parentElement.parentElement;

          this.form.deleteForm(this.formName, row.id).subscribe(res => console.log("[notes] deleted?", res));
          return;
        }
      });
    } else {

    e.preventDefault();

    const row = e.srcElement.parentNode.parentElement.parentElement;

    row.classList.toggle("strikethrough");


    this.dialogService.formDialog('Delete Record', "Are you sure you want to delete record? If so please enter a reason below", [new FormDialogQuestion('Reason', 'reason', 'textarea', null)], null, ['Cancel'], ['Delete'])
      .then( (res: any) => {
        if (res.data) {
          this.notesService.deleteData(row.idx, this.patient.getCurrentPatientIdxSnapshot(), res).pipe(take(1)).subscribe(res => console.log("[notes] delete res", res));
        }
      });
  }
}

  addNewNotes() {
    console.log("[notes] Someone demanded add new notes");
    const title = this.formFriendlyName;

    if (this.formName == 'letters') {
      this.loadNotes.emit({ idx: -1, description : "" });
      return;
    }
    const dialogReference = this.dialog.open(NotesNewDialogComponent, {
      data: {title: `Add New ${title}`},
      maxWidth: "600px"
    });

    dialogReference.afterClosed().subscribe((dialogResult: any) => {

      if (dialogResult && dialogResult.action) {
        this.description = dialogResult.title;
        this.loadNotes.emit({ idx: -1, description : this.description });
      }
    });
/*
    const description = "Please enter a description";
    const form = [{label: description, type: 'textbox', options: null}]

    this.dialogService.formDialog(title, description, form, null, ['Cancel'], ['Submit'])
    .then(( response : any ) => {
      if ( response ) {
        this.description = response.data[0].value;
        this.loadNotes.emit({ idx: -1, description : this.description, isNewNote: true });
      }
    })
*/


  }

  rowSelected(row) {
    console.log(row);
  }



  downloadData() {
    console.log("DOWNLOADING CSV NOW");
    const dataToBeDownloaded = [];
    dataToBeDownloaded.push(Object.keys(this.info[0]));
    this.info.forEach(element => {
      dataToBeDownloaded.push(element);
    });
    this.csvService.downloadCSVData(dataToBeDownloaded, 'notes.csv');
  }


  requestPatientCompletesForm() {
    this.dialogService.questionnaireDialog(
      "Send Form",
      "Do you want to email form or get QR code to complete in clinic?",
      ["Email"],
      ["QR Code"]
    )
    .then((dialogResult: any) => {
      if (dialogResult) {
        console.log(dialogResult, this.formName, this.patient.getCurrentPatientIdxSnapshot());
  
        if (dialogResult.sendOnEmail) {
          this.loading.start();
          this.patientCompletedFormService.requestCompletion(
            this.formName,
            this.patient.getCurrentPatientIdxSnapshot(),
            true, 
            dialogResult.selectedSubforms
          )
          .subscribe((res: any) => {
            console.log(dialogResult, res);
            this.notificationService.send("Notes link has been emailed");
            this.loading.stop();
          });
        }else {
          let site = "";
          this.settings.getSiteSettings().pipe(take(1)).subscribe((res: any) => {
            site = res.siteName;
            this.patientCompletedFormService.requestCompletion(
              this.formName,
              this.patient.getCurrentPatientIdxSnapshot(),
              false, 
              dialogResult.selectedSubforms
            ).subscribe((res: any) => { 
              const token = res.token
              console.log(res, 'res');
              const link = `https://forms.multiclinic.co.uk/hello/${site}/${token}`;
              this.dialogService.qrDialog("Scan For Notes", link).then(dialogResult => {
                console.log(dialogResult)
              });
            });
          });
        }
      } else {
        console.log("Dialog was closed without selecting an option.");
      }
    });
  }
}
