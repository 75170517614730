<div class="wrapper">
  <div class="container pb-0">
    <div class="header-row row">
      <h2 class="logo">Initialising</h2>
      <mat-progress-bar mode="indeterminate"></mat-progress-bar>
    </div>
    <div class="login-item">
      <table class="table mx-6">
        <tbody>
          <ng-template ngFor let-requirement [ngForOf]="initService.requirements">
            <tr *ngIf="!requirement.ok">
              <td>{{ requirement.require }}</td>
              <td>
                <mat-spinner diameter="24"></mat-spinner>
              </td>
            </tr>
          </ng-template>
        </tbody>
      </table>
    </div>
  </div>
</div>