
<div class="row margin-collapse">
  <div class="col-lg-10">
    <mat-card>
      <mat-card-content>
        <h3 *ngIf="template">{{template.friendlyTitle}}</h3>
        <br>
        <div class="cardContainer">
          <app-template-keyword-guide></app-template-keyword-guide>
        </div>

        <div class="cardCol mt-2" *ngIf="template">

          <mat-form-field>
            <mat-label for="subject">Subject</mat-label>
            <input type="text" matInput name="subject" [(ngModel)]="template.subject">
          </mat-form-field>


          <mat-label for="body">Email body</mat-label>
          <angular-editor [placeholder]="'Enter text here...'" [config]="editorConfig" name="body"
            [(ngModel)]="template.body" id="print"></angular-editor>

        </div>

      </mat-card-content>
    </mat-card>
  </div>
  <div class="col-sm-2 no-print mobile-margin">
    <div class="fix-top">
      <div class="mb-3">
        <div class="mobile-buttons mobile-invoice-buttons">
          <div class="my-3 desktop-buttons">
            <button mat-raised-button class="mobile-button desktop-button" color="primary"
              (click)="save()">Save</button>
          </div>
          <div class="my-3 desktop-buttons">
            <button mat-raised-button class="mobile-button desktop-button" (click)="cancel()">Back</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
