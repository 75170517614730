import { Component,  } from '@angular/core';
import { NotesBuilderBaseComponent } from 'app/notes/notes-builder/notes-builder-base/notes-builder-base-component';
import { ExtremetiesFields, AideMemoire } from '../models/extremities';

@Component({
  selector: 'app-extremities',
  templateUrl: './extremities.component.html',
  styleUrls: ['./extremities.component.css']
})
export class ExtremitiesComponent extends NotesBuilderBaseComponent {
  ExtremetiesFields = ExtremetiesFields
  AideMemoire = AideMemoire;
}
