import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormTemplateBaseComponent } from '../form-template-base/form-template-base.component';
import { fieldType } from 'app/dynamic-form/question-base';
import moment from 'moment';

@Component({
  selector: 'app-form-text-box',
  templateUrl: './form-text-box.component.html',
  styleUrls: ['./form-text-box.component.css']
})
export class FormTextBoxComponent extends FormTemplateBaseComponent implements OnChanges {
  fieldType = fieldType

  ngOnChanges(changes: SimpleChanges) {
  }

  handleChange(question: any, input: any) {
    question.value = input.value;
  }

  verifyEmail() {
    this.router.navigate(['aws-verify-email'], { queryParams: { email: this.question.value } });
  }

  getAge(dob: string) {
    let birthDate = new Date(dob);
    return moment().diff(birthDate, 'years', false);
  }

}
