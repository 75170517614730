import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { QRCodeDialog } from 'app/models/dialog';


@Component({
  selector: 'qr-code-dialog',
  templateUrl: './qr-code-dialog.component.html',
  styleUrls: ['../dialog-shared.scss']
})
export class QRCodeDialogComponent implements OnInit {

  public textInput: string

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: QRCodeDialog,
    public dialogRef: MatDialogRef<QRCodeDialogComponent>, 
  ) { }

  ngOnInit(): void {
    console.log("[qr]", this.data);
  }

  public onDismiss() : void {
    this.dialogRef.close(false);
  }

}