import { createReducer, on } from '@ngrx/store';
import { LatestStatus as State, initialLatestStatus as initialState } from '../../models/notes';
import * as notesAction from '../actions/notes';
import { ActionWithPayload } from './index';

export { State, initialState };

const featureReducer = createReducer(
  initialState,
  on(notesAction.SetLatestStatus, (state, action) => ( { ...initialState, ...action.payload } ))
);
  
export function reducer(state: State = initialState, action: ActionWithPayload) {
  return featureReducer(state, action);
}

export const getLatestStatus = (state: State) => state;


