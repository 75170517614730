import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'app-pin',
  templateUrl: './pin.component.html',
  styleUrls: ['./pin.component.scss', '../dialog-shared.scss']
})
export class PinComponent implements OnInit {
  public places: number[] = Array(6);
  public numbers: string = "";
  public options: string[] = ["7", "8", "9", "4", "5", "6", "1", "2", "3", "0", "CLEAR"];
  public incompleteError: boolean = false;

  @HostListener('window:keyup', ['$event'])
  onDialogKey(event: KeyboardEvent): void {
    console.log("[key]",event.key);
    const key = event.key;
    if (key=="Enter") { 
      this.onConfirm();
      return;
    }

    if (key=="Backspace"){
      this.numbers = this.numbers.substring(0, this.numbers.length - 1);
      return;
    }


    if ( this.numbers.length < 6 ) {
      const asNumber = parseInt(key);
      if ( asNumber>=0 && asNumber<=9 ){
        this.numbers = `${this.numbers}${key}`;
      }
    }

  }

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, 
              public dialogRef: MatDialogRef<PinComponent> ) {}

  ngOnInit(): void { }

  public optionSelected(option: string): void {
    if ( option === "CLEAR" ) {
      this.numbers = "";
    } else {
      if ( this.numbers.length < 6 ) {
        this.numbers = `${this.numbers}${option}`;
      }
    }
  }


  onConfirm(): void { 
    if ( this.numbers.length < 6 ) {
      this.incompleteError = true;
      setTimeout(() => this.incompleteError = false, 3000);
    } else {
      this.dialogRef.close(this.numbers); 
    }
  }

  onDismiss(): void {
    this.dialogRef.close(false);
  }

}