import { Injectable } from '@angular/core';
import { DataService } from 'app/services/data.service';
import { Observable } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AWSEmailVerifyService {
    constructor(
        private http: DataService
    ) {}

    check(email:string): Observable<any>{
      return this.http.post(`/email/check`, {email: email});
    }

    verify(email:string): Observable<any>{
      return this.http.post(`/email/verify`, {email: email});
    }

    

}
