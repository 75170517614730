//control if the app is busy and locked
import { ActionWithPayload } from './index';
//import { createSelector } from 'reselect';

import * as staffAction from '../actions/staff';
import { StaffMember } from '../../models/staff';

export interface State {
    staff: StaffMember[];
};

export const initialState: State = {
    staff: []
};


export function reducer(state = initialState, action: ActionWithPayload): State {
    switch (action.type) {
        case staffAction.ActionTypes.SET: {
            for (let staff of action.payload){
                staff = Object.assign({}, staff, { working: false, selected: true, order:0 });
            }
            console.log("[staff selected] set ", action.payload);
            return Object.assign({}, {staff: action.payload});
        }      

        case staffAction.ActionTypes.MARK_SELECTED: {
            const index = state.staff.findIndex(f => f.idx == action.payload.idx );
            
            if (index>=0){
                const staff = state.staff[index];
                console.log("[staff selected] mark_selected ",index, action.payload.selected);

                return {
                    ...state,
                    staff: [
                        ...state.staff.slice(0, index),
                        Object.assign({}, state.staff[index], {selected: action.payload.selected}),
                        ...state.staff.slice(index+1)
                    ]
                }
            }else{
                console.log(`[staff selected] WHO the hell is?? ${action.payload.idx}`);
            }
            
            return state;
        }

        case staffAction.ActionTypes.BULK_MARK_SELECTED: {
            console.log("[staff selected] bulk_mark_selected ", action.payload);
            const newStaff = state.staff.map(staff=>{
                const found = action.payload.includes(staff.idx);
                if (staff.selected == found){
                    return staff;
                }
                return Object.assign({}, staff, { selected: found });
            })

            if (JSON.stringify(newStaff) == JSON.stringify(state.staff)){
                return state; // no changes found
            }

            return { ...state, 
                staff: newStaff
            }
        }

        case staffAction.ActionTypes.SELECT_ALL: {
            console.log("[staff selected] select_all");
            return { ...state, 
                staff: state.staff.map(staff=>{
                    if (staff.selected){
                        return staff;
                    }
                    return Object.assign({}, staff, {selected: true});
                })
            }
        }

        case staffAction.ActionTypes.SELECT_NONE: {
            console.log("[staff selected] select_none");
            return { ...state, 
                staff: state.staff.map(staff=>{
                    return Object.assign({}, staff, {selected: false});
                })
            }
        }

        case staffAction.ActionTypes.SET_SORT_ORDER:{            
            return { ...state, 
                staff: state.staff.map(staff=>{
                    const index = action.payload.findIndex(f=> f == staff.idx);
                    return Object.assign({}, staff, {order: index});
                })
            }
        }

        default:
            return state;
    }
}

export const getStaff = (state: State) => state.staff;
export const getSelectedStaff = (state: State) => state.staff.filter(s => (s.selected)).sort((a,b) => a.order - b.order);
