import { Component, Input, Output, EventEmitter, ViewChild, ElementRef, SimpleChanges } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { PandLService } from '../p-and-l/p-and-l.service';

@Component({
  selector: 'income-drill-down',
  templateUrl: './income-drill-down.component.html',
  styleUrls: ['./income-drill-down.component.css']

})
export class IncomeDrillDownComponent {

  columnDefs = [
    { headerName: 'Transaction Id', field: 'transactionIdx' },
    { headerName: 'Date', field: 'timePaid' },
    { headerName: 'Value', field: 'total' }
  ];

  constructor(private plService: PandLService, private route: ActivatedRoute, private router: Router) {

  }

  startDate: string;
  interval: string = '1 MONTH';
  type: string;
  rowData: any;


  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      this.startDate = `${params.get('year')}-${params.get('month')}-01`;
      this.type = params.get('type');
      this.updateTable();
    });
  }

  updateTable() {
    this.plService.getIncomeDetail(this.startDate, this.interval, this.type).subscribe(res => {
      this.rowData = res;
    })
  }

  rowClicked(row) {
    console.log(row);
    this.router.navigate(['finance/invoice', row.data.transactionIdx]);
  }

}
