import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  public setItem(key: string, item: string): void {
    window.localStorage.setItem(key, item);
  }

  public getItem(key: string): string {
    return window.localStorage.getItem(key);
  }

  public saveObject(key: string, obj: any): void {
    window.localStorage.setItem(key, JSON.stringify(obj));
  }

  public getObject(key: string): any {
    return JSON.parse(window.localStorage.getItem(key));
  }

  public remove(key: string): void {
    window.localStorage.removeItem(key);
  }

  public clear(): void {
    window.localStorage.clear();
  }

  public clearAndRestoreCompanyName(): void {
    const companyName = this.getItem("companyName");
    this.clear();
    this.setItem("companyName", companyName);
  }
}
