import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { DataService } from 'app/services/data.service';
import { map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { NavBarService } from '../../services/menu/nav-bar.service';
import * as fromMenu from '../actions/menu';
import * as fromRoot from '../reducers';


@Injectable()
export class MenuEffects {

  loadMenu$ = createEffect(() => {
    return this.actions$
    .pipe(
      ofType(fromMenu.ActionTypes.LoadMenu),
      mergeMap(() => this.menuService.getMenuFromApi()
        .pipe(
          map(menu => (new fromMenu.Loaded(menu.menuItems))),
        ))
      );
   });

   saveMenu$ = createEffect(() => {
     return this.actions$
     .pipe(
       ofType(fromMenu.ActionTypes.SaveMenuScores),
       withLatestFrom(this.store$),
       map(([action, storeState]) => {
          this.http.post(`/session/menu`, this.flattenMenuItems(storeState.menu)).subscribe(res => {});
          return new fromMenu.MenuStateSaved();
       })
     );
    });

    flattenMenuItems(menus) {
      console.log("[menu]", menus);
      const flatItems = [];
      for (const menu of menus) {
        flatItems.push(
          menu.subItems.map(item => {
            return {id: item.id, score: item.score || 0, favourite: item.favourite || false};
          })
        );
      }
      return flatItems;
    }

  constructor(
    private actions$: Actions,
    private store$: Store<fromRoot.State>,
    private menuService: NavBarService,
    private http: DataService
  ) {}

}
