import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { DataService } from './data.service';
import { GeoLocationService } from './geo-location.service';

export  interface AddressListItem{ 
  PlaceId: string;
  Text: string;
}

export interface AddressForm{
  Address1: string;
  Address2: string;
  Town: string;
  County: string;
  PostCode: string;
}

export interface AddressPlace{
  Label: string,
  Geometry: {
    Point: []
  },
  AddressNumber: string,
  Street: string,
  Municipality: string,
  SubRegion: string,
  Region: string,
  Country: string,
  PostalCode: string,
  Interpolated: boolean
}
@Injectable({
  providedIn: 'root'
})
export class AddressLookupService {
  location: GeolocationCoordinates = {latitude: null, longitude: null, accuracy: null, altitude: null, altitudeAccuracy: null, speed: null, heading: null};

  constructor(private http: DataService, private userLocation: GeoLocationService) {
    this.getLocation();
  }

  getLocation(){
    this.userLocation.getPosition().subscribe(res=>{
      this.location = res;
      console.log("[locate] we are: ", res);
    })

  }

  search(query: string): Observable<AddressListItem[]>{
    return this.http.get<AddressListItem[]>(`/address/lookup/${encodeURI(query)}?lat=${this.location.latitude}&lng=${this.location.longitude}`)
  }

  getPlace(location: string): Observable<AddressPlace>{
    return this.http.get(`/address/fetch/${encodeURI(location)}`)
  }

/** convert address array to form object 
 * 1 = Address1
 * n - 1 = Country
 * n - 2 = Postcode
 * n - 3 = "England"
 * n - 4 = "County"
 * n - 5 = Town
*/
translateToForm(input: AddressPlace): AddressForm{
  const out = {
    Address1: input.AddressNumber ? `${input.AddressNumber} ${input.Street}` : `${input.Street}`,
    Address2: "", //only if it is there thanks.
    Town:  input.Municipality,
    County: input.SubRegion,
    PostCode: input.PostalCode
  };

  console.log(input, out);

  return out;
}
}
