import { Component, Input, OnInit, SimpleChanges, Output, EventEmitter, HostListener, OnChanges, OnDestroy } from '@angular/core';

import { UntypedFormGroup, UntypedFormArray, UntypedFormBuilder, UntypedFormControl, FormBuilder, FormGroup, FormArray } from '@angular/forms';

import { QuestionService } from '../services/question.service';
import { QuestionControlService } from '../services/question-control.service';
import { DynamicFormSaveService } from '../services/dynamic-form-save.service';
import { Router, Params, ActivatedRoute } from '@angular/router';

import { NavigationService } from 'app/services/navigation.service';
import { ReportsService } from 'app/reports/reports.service';

import { DialogService } from 'app/services/dialog/dialog.service';

import { take } from 'rxjs/operators';
import { PatientChooserService } from 'app/services/patient.service';
import { AddressLookupService } from 'app/services/address-lookup.service';
import { fieldClasses } from '../question-base';
import { NotificationService } from 'app/services/notification.service';



const FORMS =  //some hard coded form names
{
    PATIENT_DETAILS: 'patientdetails'
};



@Component({
    selector: 'dynamic-form',
    templateUrl: './dynamic-form.component.html',
    styleUrls: ['./dynamic-form.component.scss']
})

export class DynamicFormComponent implements OnChanges, OnInit, OnDestroy {
    txnId: number;
    formDataValue: any;
    @Input() isDialog:boolean = false // used if form appears in a dialog box, to change the back button to close the dialog instead
    @Input() formName: string = "";
    @Input() searchOn: any;
    @Input() multiple: boolean = false; // used if multiple forms to hide submit buttons
    @Input() submitting: boolean = false;
    @Input() navigate: boolean = true;
    @Input() checking: boolean = false;
    @Output() loadComplete = new EventEmitter<string>();
    @Output() touched = new EventEmitter<boolean>();
    @Output() saveComplete = new EventEmitter<{form: string, idx: string}>();
    @Output() checkComplete = new EventEmitter<string>();

    token: string;
    questions: any[];
    questionOptions: any[];
    formTitle: string;
    formIdx: number = 0;
    dForm: any;
    prevDForm: any;
    form: FormGroup;
    formCopiesCache: any[] = [];
    formInfo: any;
    notes: string = "";
    formSections: any[];
    nav: boolean = true;
    stoppedNav: boolean = false;
    patientIdx: number;
    public saving = false;
    //addingGP: boolean = false;
    //addingPractice: boolean = false;
   // chooseGP: boolean = false;
    chooseFrom: string;
    gpIdx: number;
    practiceIdx: number;
    practices: any[];

    navBackTo: string;
    sections: any[];
    noBack: string[] = ['notes_employmentdetails', 'notes_medicationdetails', 'notes_femalesdetails', 'notes_healthdetails', 'notes_medicalhistorydetails', 'notes_sleephabits', 'notes_osteoporosis'];
    private windowWidth: number;
    private dynamicForm: any;
    private dynamicForms: any[] = [];
    private dynamicFormBuilder: any[] = [];
    private savedState?: any;

    subscription;

    payLoad = '';
    constructor(private qs: QuestionService,
        private qcs: QuestionControlService,
        private route: ActivatedRoute,
        private saveService: DynamicFormSaveService,
        private _fb: FormBuilder,
        private navigationService: NavigationService,
        private patientService: PatientChooserService,
        private dialog: DialogService,
        private router: Router,
        private addressLookup: AddressLookupService,
        private notificationService : NotificationService) {}

    @HostListener('window:resize', ['$event'])
    onResize(event: any) {
        this.windowWidth = event.target.innerWidth;
    }


    ngOnInit() {
        this.windowWidth = window.innerWidth;
        this.route.params.subscribe((params: Params) => {
            if (params['searchOn']) {
                    this.searchOn = params['searchOn'];
                    
            }

            if (params['formName']) {
                    this.loadForm(params['formName']);
            }
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['submitting']) {
            const subv = changes['submitting'].currentValue;
            if (subv === true) {
                this.submitting = subv;
                this.saveForm();
            }
        }
        if (changes['checking']) {
            // Check form for any changes
            this.checkForChanges();
        }
        if (changes['formName'] && changes['formName'].currentValue != changes['formName'].previousValue) {
            this.formName = changes['formName'].currentValue;
            this.loadForm(this.formName);
        }

        if (changes['searchOn']) {
            console.log("load note: ", changes["searchOn"])
            this.searchOn = changes['searchOn'].currentValue;
            this.loadForm(this.formName);
        }
    }


    openFormDialog(e) {
      console.log("[chooser] from", e);
      switch (e.action) {
        case 'dialog':
            const d = this.dialog.dynamicFormDialog(e.data).then(res => {

                // we pass on to the subject the new entry (example new gp) and we listen in the generic-component so we can update the options
                if(res) this.qs.newOptionOnChooser.next(res)

                e.ele.setValue(res.data.idx);
                console.log("[chooser] r", res);
                console.log("[chooser] f", this.dForm);
            });
        return;
        case fieldClasses.ADDRESSCHOOSER:
            console.log("[chooser]", <FormArray>this.form.controls['sections']);
            (<FormArray>this.form.controls['sections']).controls.forEach(control => {
                control.patchValue(this.addressLookup.translateToForm(e.data));
            });
           // const qn = this.questions.find(q => q.key === "Address2").value = e.data[1];
//           this.form.controls['sections'].patchValue({Address2: e.data[1], Address1: "help"});
//           this.form.controls.sections.patchValue({Address2: e.data[1], Address1: "help"});
//            .setValue(e.data[1]);
        return;
        }

    }

    loadForm(formName: string, blank?: boolean) {
        this.formName = formName;
        if (this.formName && this.formName != "") {
            const searchOn = blank ? "0" : this.searchOn;
            this.qs.getQuestions(this.formName, searchOn).subscribe(data => {
                console.log("[questions]", data);
                
                // if a question is multichoice we have to convert the response string into an array of numbers for the dropdown to work
                data.questions.forEach((q, i) => {
                    if (q.multichoice && Array.isArray(JSON.parse(q.value))) {
                        q.value = JSON.parse(q.value).map(ele => Number(ele))
                    }
                })

                console.log("[questions]", data);
                console.log(this.formName);
                this.dForm = data;
                this.updateForm();
                this.loadComplete.emit("emitted");
            });
        }
    }


    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
        this.saveService.showInvalidField.next(false)
    }

    updateForm() {
        this.formTitle = this.dForm.formTitle || '';
        this.formName = this.dForm.formName || '';
        this.formIdx = this.dForm.idx || 0;

        this.dForm.questions.map(q => {
            // Carry through Idx
            if (q.fieldKey === "GPIdx" && this.gpIdx) {
                q.value = this.gpIdx
            } else if (q.fieldKey === "PracticeIdx" && this.practiceIdx) {
                q.value = String(this.practiceIdx)
            }
            return q;
        });

        this.questions = this.qs.buildFormGroup(this.dForm.questions);

        // Gather all dynamic fields into dynamic forms.
        this.dForm.questions.find((field: any) => {
            if (field.fieldType === 'DynamicField') {
                this.dynamicForm = field;
                this.dynamicForms.push(this.dynamicForm);
            }
        });



        if (this.dynamicForm) {
            this.dynamicForms.forEach((dynamicField: any) => {
                const dynamicForm = dynamicField.options.map((field: any, index: number) => {
                    return {
                        title: field.title,
                        idx: field.idx,
                        checkboxValue: (typeof field.selected === 'boolean') ? field.selected : (field.selected === 1) ? true : false,
                        textboxValue: field.value,
                        active: true,
                        adminOnly: 0,
                        fieldKey: field.title.replace(/\s/g, ''),
                        fieldType: "Checktext",
                        label: field.title,
                        required: false,
                        orderNo: this.questions.length + index,
                        validators: null,
                        width: 'Checktext',
                        placeholder: field.title,
                        type: 'text'
                    };
                });
                this.dynamicFormBuilder = [...this.dynamicFormBuilder, ...dynamicForm];
            });

            // Build the form fields as Checktext => Checkbox + Textbox.
            this.questionOptions = this.qs.buildFormGroup(this.dynamicFormBuilder);
            this.questionOptions.forEach((question: any) => this.questions.push(...question));
        }

        this.notes = this.dForm.notes || '';

        const group: UntypedFormControl[] = [];

        // Filter out undefined fields.
        this.questions = this.questions.filter((q: any) => !!q);

        this.form = this._fb.group({
            sections: this._fb.array(
                [
                    this.qcs.toFormBuilder(this.questions)
                ])
        });


        this.savedState = JSON.parse(JSON.stringify(this.form.value.sections))

        if (this.dynamicForm) {
            // Set up listeners to disable / enable textbox when checkbox value changes.
            (<FormArray>this.form.controls['sections']).controls.forEach(control => {
                const checktextFields = this.dynamicFormBuilder.map((field: any) => { return { key: field.fieldKey, value: field.checkboxValue } });
                checktextFields.forEach((checkField: any) => {
                    if (!checkField.value) (<UntypedFormGroup>control).controls[checkField.key + "text"].disable();

                    (<UntypedFormGroup>control).controls[checkField.key].valueChanges.subscribe(changed => {
                        if (!changed) (<UntypedFormGroup>control).controls[checkField.key + "text"].disable();
                        else (<UntypedFormGroup>control).controls[checkField.key + "text"].enable();
                    })
                })
            })
        }

        this.submitting = false;

        this.formSections = [];
        for (let qn of this.dForm.questions) {
            console.log(qn);
            if (qn.fieldType == "TitleArea") {
                this.formSections.push({ title: qn.label, idx: qn.idx });
            }
        }

    }

    checkPatients() {  //this whole function is mental!
        //getResults

        const search = `${this.form.value.sections[0].FirstName} ${this.form.value.sections[0].LastName}`;

        this.patientService.getResults(search).subscribe(res => {
            console.log("[dupli check]", res)
            if (res.length == 0) {
                this.saveForm();
                return;
            }

            const messageArr = res.map(dup => {
                let out = `#${dup.idx} ${dup.name}`;
                if (dup.address){
                    out += ` of ${dup.address}`
                }
                return out;

            });

            const message = messageArr.join("<br/>");

            this.dialog.genericDialog('Potential Duplicates', `${message}<br/><hr/>Are you sure you would like to continue?`, ['Yes'], ['No'], null)
                .then(res => {
                    console.log("[dialog says]", res);
                    if (res.data) {
                        this.saveForm()
                    }
                })
        });
    }



    save() {
        if (this.formName == FORMS.PATIENT_DETAILS && this.searchOn == "0") {
            this.checkPatients(); //new patient run dupli check
            return;
        }

        this.saveForm();
    }



    saveForm(formName?: string, noNav?: boolean) {
        
        if (this.form.status == "INVALID") { //this does not just check required could be any number of reasons the form is invalid. 
            this.form.markAllAsTouched();
            console.log("[form]", this.form);
            this.saveService.showInvalidField.next(true)
            this.submitting = false;
            this.notificationService.send("Please fill in the required fields")
            return
        }

        let additional: boolean;
        let adjustForm: string;
        if (formName) {
            additional = true;
            adjustForm = formName;

        }

        this.route.queryParams.pipe(take(1)).subscribe(res => {
            if (res.patient && !this.stoppedNav) {
                this.nav = false
                this.stoppedNav = true
                this.patientIdx = res.patient
            }
            this.submitting = true;

            // This step is needed as if a value is disabled, it will not be present.
            this.form.enable();

            let formName = !this.nav ? "patientdetails" : this.formName
            let idx = !this.nav ? this.patientIdx : this.searchOn
            if (additional) idx = 0
            let additionalFields = this.questions.filter(f => f.additional === adjustForm).map(f => f.key)

            delete this.form.value.sections[0]['2']
            delete this.form.value.sections[0]['']
            delete this.form.value.sections[0]['']
            let formIdx;
            if (additional) {
                if (adjustForm === "GPDetails") {
                    formIdx = 11;
                } else {
                    formIdx = 12;
                }
            }

            const payload = {
                form: this.form.value.sections,
                formName: adjustForm || formName,
                formIdx: formIdx || this.formIdx,
                idx: idx
            };
            
            
            const submittableForm = this.form.value.sections;

            if (this.dynamicForm) {
                const options = [];
                const checktextFields = this.dynamicFormBuilder.map((field: any) => { return { key: field.fieldKey, title: field.title, idx: field.idx } });

                for (let [key, value] of (<any>Object).entries(this.form.value.sections[0])) {
                    const foundEntry = checktextFields.find((field: any) => field.key === key);

                    if (foundEntry) {
                        options.push({ idx: foundEntry.idx, selected: value, title: foundEntry.title, value: this.form.value.sections[0][key + "text"] });
                    }
                }

                checktextFields.forEach((field: any) => {
                    delete submittableForm[0][field.key];
                    delete submittableForm[0][field.key + "text"];
                })

                payload["dynamicField"] = [{ idx: this.dynamicForm.idx, data: options }];
                payload.form = submittableForm;
            }


            this.saving = true;
            this.saveService.saveForm(payload).subscribe(res => {
                // Store idx for use in patient details form
                if (formName === "patientdetails" && Number(res.idx) !== 0) {
                    this.searchOn = res.idx;
                } else if (formName === "GPDetails" && Number(res.idx) !== 0) {
                    this.gpIdx = Number(res.idx);
                } else if (formName === "GPPractices" && Number(res.idx) !== 0) {
                    this.practiceIdx = Number(res.idx);
                }
                this.saveComplete.emit({form: this.formName, idx: res.idx});
                this.submitting = false;


                if (this.nav) {
                    if (!noNav && this.navigate) {
                        // Only if single form and not from deactivate guard
                        this.navigationService.back();
                    }
                    this.stoppedNav = false;
                } else {
                    this.nav = true
                    console.log("[form] we now navigate away");
                    console.log("[form]", this.formName)
                    if (!this.noBack.includes(this.formName) && !noNav) {
                        this.navigationService.back();
                        console.log("[form] we now navigate away");
                    }
                }
                this.savedState = JSON.parse(JSON.stringify(this.form.value.sections))
                this.saving = false;
            });
        })


    }

  cancel() {
    // Close the dialog box if the form is in a dialog box, rather than navigate the page behind back
    if (this.isDialog) {
      this.dialog.close()
      return
    }
      this.navigationService.back();

    }

    next() {

    }

    colWidth(type) {
        // Which means there is a 'Checktext' type
        if (type.controlType) {
            if (type.controlType === 'checkbox') {
                if (this.windowWidth < 800) return 12;
                else return 4;
            };
            if (type.controlType === 'textbox') {
                if (this.windowWidth < 800) return 12;
                else return 8;
            }
        }

        switch (type) {
            case 'chooser':
                return 12;
            case 'titlearea':
                return 12;
            case 'subtitlearea':
                return 12;
            case 'textarea':
                return 12;
            case 'dropdown':
                return 12;
            case 'checkbox':
                return 12;
            case 'infobox':
                return 12;
            case 'fileupload':
                return 12;
            default:
                return 6;
        }
    }
    rowHeight(question) {
        switch (question.controlType) {
            case 'chooser':
                return 3;
            case 'titlearea':
                return 2;
            case 'subtitlearea':
                return 2;
            case 'textarea':
                return question.options && question.options.length ? JSON.parse(question.options).rowHeight : 4;
            case 'dropdown':
                return 3;
            case 'checkbox':
                return question.width === 'Checktext' ? 3 : 1.5;
            case 'fileupload':
                return 15;
            default:
                return 3;
        }
    }

    scroll(dest: string) {
        console.log("[scroll] ", dest)
        //    this.scroller.scrollToAnchor(dest);
        const ele = document.getElementById(dest);
        ele.scrollIntoView({ behavior: "smooth" });
    }

    public SetAvailability(): void {
        const staffIdx = this.searchOn;
        this.router.navigate(["settings", "availability", staffIdx]);
    }

    checkForChanges() {
        if (this.formName === 'notes_gpdetails') {
            this.checkComplete.emit(this.formName)
            return;
        }
        if (this.savedState && JSON.stringify(this.savedState) !== JSON.stringify(this.form.value.sections)) {
            this.touched.emit(true);
        }
        this.checkComplete.emit(this.formName);
    }
}
