<form autocomplete="off" #formEle="ngForm">   
    <mat-form-field>
        <mat-label>Do you have regular breast exams (date and self/gp)</mat-label>
        <input 
            matInput 
            type="text" 
            name="breastexam"
            [(ngModel)]="form.breastexam">
    </mat-form-field>

    <mat-form-field>
        <mat-label>Last cervical smear (date/result)</mat-label>
        <input 
            matInput 
            type="text" 
            name="cervicalsmear"
            [(ngModel)]="form.cervicalsmear">
    </mat-form-field>

    <mat-form-field>
        <mat-label>Last menstural period (date)</mat-label>
        <input 
            matInput 
            type="text" 
            name="period"
            [(ngModel)]="form.period">
    </mat-form-field>

    <mat-form-field>
        <mat-label>Are you pregnant</mat-label>
        <input 
            matInput 
            type="text" 
            name="pregnant"
            [(ngModel)]="form.pregnant">
    </mat-form-field>

    <mat-form-field>
        <mat-label>Due date</mat-label>
        <input 
            matInput 
            type="text" 
            name="duedate"
            [(ngModel)]="form.duedate">
    </mat-form-field>

</form>