import { Component, OnInit, HostListener, OnDestroy, ViewChild} from "@angular/core";
import { ReportsService, ReportDetails } from "../reports.service";
import { ActivatedRoute, Params, Router } from "@angular/router";
// import 'ag-grid-enterprise';
import {
  GridOptions,
  IGetRowsParams,
  SideBarDef
} from "ag-grid-community";
import { filter, map, take } from "rxjs/operators";
import { DynamicFormSaveService } from "app/dynamic-form/services/dynamic-form-save.service";
import { GraphConfig } from "app/models/graph";
import { ReportsConfigService } from "../reports-config.service";
import { ReportClickAction } from "app/models/reports";
import { combineLatest, Subscription } from "rxjs";
import { StaffService } from "app/services/staff.service";
import { StaffMember } from "app/models/staff";
import { Clinic, Clinics } from "app/models/clinics";
import { ClinicService } from "app/services/clinic.service";
import { NotificationService } from "app/services/notification.service";
import { UserSettingsService } from "app/services/user-settings.service";
import { SortingFilter, ReportFilter } from "app/models/user-settings";
import { DialogService } from "app/services/dialog/dialog.service";
import { CustomPinnedRowRenderer } from "../custom-pinned-row-renderer/custom-pinned-row-renderer.component";
import * as fields from "app/models/report-fields";
import { NavigationService } from "app/services/navigation.service";
import { MatMenuTrigger } from "@angular/material/menu";
import { VisualsService } from "app/services/visuals.service";
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
const DataModel = {
  CLIENT: 'clientSide'
};
@Component({
  selector: "generic-report",
  templateUrl: "./generic-report.component.html",
  styleUrls: ["./generic-report.component.scss"],
})
export class GenericReportComponent implements OnInit, OnDestroy {
  public reportName: string;
  public reportDetails: ReportDetails;
  private gridApi: any;
  private gridColumnApi: any;
  public columnDefs: any[];
  public rowData: any[] = [];
  public makeForm: boolean;
  public makeLetter: boolean;
  public gridOptions: GridOptions;
  public reportNameToDisplay: any;
  public type: string;
  public displayName: string;
  public addText: string;
  public popupParent: any;
  public chartReference: any;
  public chartVisible = true;
  public routeChanged = false;
  public graphConfig: GraphConfig;
  public graphRequired = false;
  public gridActivated = true;
  public quickDateFilters: any[] = [];
  public sideBar: SideBarDef;
  public filtersActive: boolean;
  public clientData: any[] = [];
  public firstRender = true;
  public poPopup = false;
  private queryString: string = null;
  private pivotMode = false;
  private reportLoaded = false;
  public staff: StaffMember[] = [];
  public selectedStaff: StaffMember = null;
  public staffFilter: any = null;
  public clinics: Clinics[] = [];
  public selectedClinic: Clinic = null;
  public clinicFilter: any = null;
  public years: number[] = [];
  public yearFrom: number;
  public yearTo: number;
  public yearsFrom: number[];
  public yearsTo: number[];
  public filters$: Subscription;
  public filters: ReportFilter[] = [];
  public savedFiltersApplied = false;
  private clinic$: Subscription;
  private filterModelStore: any;
  private dateRange$: Subscription;
  private defaultFilterValue = null;
  private currentDateFilterModel = null;
  private reportWithDefaultFilter = false;
  public staffMenuShown: boolean = false;
  public reportValue: any;
  public pinnedBottomRowData: any = [];
  public darkMode: boolean = false;


  constructor(
    private reportService: ReportsService,
    private dform: DynamicFormSaveService,
    private route: ActivatedRoute,
    private router: Router,
    private reportsConfig: ReportsConfigService,
    private staffService: StaffService,
    private clinicService: ClinicService,
    private notifications: NotificationService,
    private settingsService: UserSettingsService,
    private dialogService: DialogService,
    private navigationService: NavigationService,
    private visualService: VisualsService
  ) {

    this.setGridOptions();
    this.visualService.getDarkMode().pipe(takeUntilDestroyed()).subscribe(res=>{
      this.darkMode = res;
  })
    
  }

@ViewChild('staffMenuTrigger') staffMenuTrigger: MatMenuTrigger;

  

  private datasource = {
    getRows: (params: IGetRowsParams) => {
      const filterModel = this.gridApi.getFilterModel();

      if ( filterModel && Object.keys(filterModel).length > 0 ) { this.filtersActive = true; }
      else { this.filtersActive = false; }

      this.gridOptions.api.showLoadingOverlay();
      this.reportService.getData(this.reportDetails.idx, params, this.queryString).subscribe((res: any) => {
          const data = res.data;
          this.setReportData(data);
          if (res.aggregates) {
            this.pinnedBottomRowData = res.aggregates;
            console.log('bottom', this.pinnedBottomRowData);
          }

          const lastRowNumber = data.length < params.endRow - params.startRow ? params.startRow + data.length : null;

          params.successCallback(data, lastRowNumber);
          // this.gridApi.setColumnDefs(this.columnDefs)
        });
    }
  };


  setGridOptions(): void {
    this.columnDefs = [];
    this.sideBar = this.reportsConfig.gridSettings.sideBar;
    this.popupParent = document.body;

    this.gridOptions = {
      ...this.reportsConfig.gridSettings.gridOptions,
      onCellClicked: this.cellClicked.bind(this),
      onGridSizeChanged: this.onGridSizeChanged,
      onFilterChanged: this.filterChanged.bind(this)
    };

    this.onGridSizeChanged(this.gridOptions);

    this.staffService.getStaffAlphabetical().subscribe((staff: StaffMember[]) => this.staff = staff );

    this.clinicService.getClinics().subscribe((clinics: any) => {
      this.clinics = clinics;

      this.clinicService.getSelectedClinic().subscribe((clinic) => this.selectedClinic = clinic);
    });
  }

  ngOnInit(): void {
    combineLatest([this.route.params, this.route.queryParams]).pipe(map(results => ({params: results[0].id, query: results[1]}))).subscribe(results => {
      if ( results.params && results.params !== this.reportName ) {
        this.savedFiltersApplied = false;
//        this.saveReportFilters();
        this.initiateReport(results.params, results.query);
      }

      if ( Object.keys(results.query).length !== 0 && this.queryString !== this.constructQuery(results.query) ) {
        this.initiateReport(results.params || this.reportName, results.query);
      }

      this.clinic$ = this.clinicService.getSelectedClinic().subscribe((clinic) => { if ( this.clinicFilter ) { this.selectClinic(clinic) } });

    });
    /*
    this.route.params.subscribe((params: Params) => {
      if ( params["id"] ) {
        this.selectedStaff = null;
        this.staffFilter = null;
        this.selectedClinic = null;
        this.clinicFilter = null;
        this.graphRequired = false;
        this.destroyChart();
        this.reportName = params["id"];
        this.loadReport();
      }
    });
    */
  }

  constructQuery(queries: any): string {
    let queryString = "?";
    for ( const query in queries ) {
      queryString += `${query}=${queries[query]}&`;
    }

    return queryString;
  }

  initiateReport(name: string, query: any): void {
    this.queryString = query ? this.constructQuery(query) : "";
    this.graphRequired = false;
    this.destroyChart();
    this.reportName = name;
    this.reportLoaded = false;
    this.defaultFilterValue = null;
    this.loadReport();
  }

  @HostListener('window:resize', ['$event']) onResize() {
     this.onGridSizeChanged(this.gridOptions);
  }

  public capitalize(word: string): string {
    return word[0].toUpperCase() + word.slice(1);
  }

  public selectStaffMember(staff: StaffMember): void {
    console.log(this.staffMenuTrigger);
    this.staffMenuTrigger.closeMenu();

    this.selectedStaff = staff;
    const instance = this.gridApi.getFilterInstance(this.staffFilter.fieldname);
    const filter = this.staffFilter.fieldprop === 'name' ? staff.firstname + " " + staff.lastname : staff[this.staffFilter.fieldprop];

    instance.setModel({filterType: "text", type: "contains", filter});
    this.gridApi.onFilterChanged();
  }

  public selectClinic(clinic: Clinic): void {
    this.selectedClinic = clinic;
    const instance = this.gridApi.getFilterInstance(this.clinicFilter.clinicname);
    const filter = clinic[this.clinicFilter.fieldprop];

    instance.setModel({filterType: "text", type: "contains", filter});
//    this.gridApi.onFilterChanged();
  }

  public filterChanged(params: any): void {
    console.log("FILTER CHANGED", this.gridApi.getFilterModel(), this.filterModelStore);
    if (JSON.stringify(this.gridApi.getFilterModel()) == JSON.stringify(this.filterModelStore)) {
      return;
    }
    if (params.columns && params.columns[0] && !this.reportWithDefaultFilter) {
      const filterType = params.columns[0].colDef.filter;
      if (filterType == "agDateColumnFilter") {
        this.refreshDataIfClientSideModel();
      }
    }

    if ( params.api?.getDisplayedRowCount() === 0 && this.chartReference ) { this.destroyChart(); } 
    else { this.createChart(); }
    if (!this.gridApi.getFilterModel() && !this.filterModelStore) {
      this.filtersActive = false;
    } else {
      this.filtersActive = true;
    }
  }

  public destroyChart(): void {
    this.chartVisible = false;
    setTimeout(() => { if ( this.chartReference ) { this.chartReference.destroyChart() } }, 200);
  }

  public createChart(): void {
    this.chartVisible = true;
    setTimeout(() => this.onFirstDataRendered(this.gridOptions), 100);
  }

  public onFirstDataRendered(event: any): void {

    this.settingsService.getReportFilters()
      .pipe(take(1), filter(f => {  return Array.isArray(f); }), map((filters:any[])=>{
        return filters.find((r:any)=>{return r.reportName==this.reportName;})
      }))
      .subscribe(filters => {
      console.log("[grid] loaded? =>",this.reportName, filters);
      if (filters){
        this.gridColumnApi.applyColumnState({
          state: filters.columnState, 
          applyOrder: true});
 
      }
      //this.filters = filters;
    });


    if ( event.api?.getDisplayedRowCount() === 0 && this.chartReference ) {
      console.log("DESTROY CHART");
      this.destroyChart();
      return;
    }

    if ( !this.graphRequired ) { return; }

    if ( this.chartReference ) {
      this.chartReference.destroyChart();
    }

    event.api.sizeColumnsToFit();

    const chartContainer = document.querySelector("#chart");
    const params = this.reportsConfig.getGraphParameters(this.graphConfig, chartContainer);

    this.pivotMode = this.reportsConfig.pivotMode(this.reportName);

    if ( this.pivotMode ) {
      this.chartReference = event.api.createPivotChart(params);
    } else {
      this.chartReference = event.api.createRangeChart(params);
    }

  }

  public onGridSizeChanged(params: any): void {
    if ( document.getElementById('grid-container') === null ) { return; }

    const gridWidth = document.getElementById('grid-container').offsetWidth;

    if ( this.reportsConfig ) { this.reportsConfig.onGridSizeChanged(gridWidth, params); }
  }

  exportReport(): void {
    this.gridOptions.api.exportDataAsExcel();
  }

  loadReport(): void {
    if ( !this.gridApi ) { return; }
    if ( !this.reportName ) { return; }
    if ( this.reportLoaded ) { return; }

    this.staffFilter = null;
    this.reportWithDefaultFilter = false;
    this.quickDateFilters = [];
    this.reportsConfig.dateDeltaState  = { unit: null, delta: 0};

    this.reportService.loadReport(this.reportName).pipe(map((response: ReportDetails) => {
      this.graphConfig = response.graph;

      if (response.graph && (response.graph as any).length || this.graphConfig.xAxis) {
        this.graphRequired = true;
      }

      if ((this.gridOptions.rowModelType != response.rowModelType) || (response.rowModelType === DataModel.CLIENT && this.pivotMode)) {
        this.gridOptions.rowModelType = response.rowModelType as "clientSide" | "infinite" | "viewport" | "serverSide";
        this.gridActivated = false;
        setTimeout(() => { this.gridActivated = true; });
      }
      // this.reportsConfig.reportAggregation(this.gridOptions, response.columnDefs, this.reportName);
      return this.reportsConfig.reportConfig(response, this.quickDateFilters, this.makeForm);
    })
    ).subscribe(
      (response: ReportDetails) => {
        if (!response) { return; }
        this.poPopup = response.onClick === ReportClickAction.openPO;
        this.makeLetter = response.onClick === "MAKELETTER";
        this.makeForm = response.onClick === "MAKEFORM";

        this.reportDetails = response;
        console.log(this.reportDetails);

        this.columnDefs = response.columnDefs.filter((column: any) => column.headerName !== 'id' && column.headerName !== 'Transaction ID');

        this.columnDefs.forEach((column: any) => {
          if (column.defaultFilterValue) {
            this.defaultFilterValue = JSON.parse(column.defaultFilterValue);
            this.reportWithDefaultFilter = true;
          }
          if (column.filterParams && column.filterParams.fieldname) { this.staffFilter = column.filterParams; }
          if (column.filterParams && column.filterParams.clinicname) { this.clinicFilter = column.filterParams; }
        });

        /*
        If a column has defaultFilterValue, build the filter model before calling this.getReportData()
        */
        if (this.defaultFilterValue) {
          setTimeout(() => {
            this.reportsConfig.setDateRangeFilterModel(this.quickDateFilters[0], this.defaultFilterValue.delta, this.defaultFilterValue.unit, null, null);
          }, 50);
        }

        /*
        Subscription listens to the changes of the date range filter model and fires the function only on new data
        */
        this.dateRange$ = this.reportsConfig.dateRangeFilterModelSubject.subscribe(data => {
          console.log("[report] gets data", data);
          if (data && data !== this.currentDateFilterModel) {
            this.setDateRangeFilter(data);
          }
          this.currentDateFilterModel = data;
        });

        this.columnDefs = response.columnDefs.filter(
          (column: any) => column.headerName !== 'id' && column.headerName !== 'Transaction ID');

        this.gridApi.setSideBarVisible(true);

        if (response.rowModelType === DataModel.CLIENT) { this.reportLoaded = true; }

        if (this.gridOptions.rowModelType == DataModel.CLIENT && !this.reportWithDefaultFilter) {
          this.getReportData();
        } else {
          this.gridApi.setDatasource(this.datasource);
        }
        this.gridApi.setColumnDefs(this.columnDefs)
      },
      err => {
        console.error("report load up error", err);
      }
    );
    const filterModel = this.gridApi.getFilterModel();
    console.log(filterModel, Object.keys(filterModel).length);
    if (filterModel && Object.keys(filterModel).length > 0) {
      this.filtersActive = true;
    } else {
      this.filtersActive = false;
    }
  }

  onGridReady(params: any): void {
    this.gridApi = params.api;
    this.gridColumnApi = params.columnApi;
    this.loadReport();
  }

  getChartToolbarItems() {
    return ['chartDownload', 'chartData', 'chartFormat', 'chartUnlink', 'chartSettings'];
  }

  applyReportFilters(): void {
    if ( this.savedFiltersApplied ) { return; }

    if ( this.filters && this.filters.length ) {
      this.filters.forEach((filter: ReportFilter) => {
        if ( filter.reportName !== this.reportName ) { return; }

       // this.gridColumnApi.applyColumnState({ state: filter.sortingFilters, defaultState: { sort: null } });

        if ( filter.columnFilters ) {
         this.gridApi.setFilterModel(filter.columnFilters);
        }
      });
    }

    this.savedFiltersApplied = true;
  }

  saveReportFilters() {
    console.log("[grid] save")
    if ( !this.gridApi ) { return; }

    const columnFilters = this.gridApi.getFilterModel();
    const columnState  = this.gridColumnApi.getColumnState();
    const columnGroupState = this.gridColumnApi?.getColumnGroupState();

    const reportFilter= {
      reportName: this.reportName,
      columnFilters: columnFilters,
      columnState: columnState,
      columnGroupState: columnGroupState
    };
    console.log("[grid] save ", reportFilter);
    this.settingsService.setReportFilters(reportFilter);
  }
/**
 * Goes through incoming data and provides transforms
 * @param data 
 */
  private setReportData(data: any): void {
    for ( const line of data ) {
      for (const i in data) {
        
        if (this.columnDefs[i] && this.columnDefs[i].valueFormatter && this.columnDefs[i].valueFormatter.name == "bound dateFormatter") {
          line[this.columnDefs[i].field] = new Date(line[this.columnDefs[i].field]).getTime();
        }
        if (this.columnDefs[i] && this.columnDefs[i].valueFormatter && this.columnDefs[i].valueFormatter.name == "bound currencyFormatter") {
          line[this.columnDefs[i].field] = +line[this.columnDefs[i].field];
        }
      }
    }

    this.rowData = data;  

    if ( this.reportName === 'AppointmentsAMonth' ) { this.setYears(); }

    this.reportLoaded = true;
    this.onGridSizeChanged(this.gridOptions);
    this.gridOptions.api.hideOverlay();
    this.applyReportFilters();

  }

  getReportData(): void {
    this.reportService.getData(this.reportDetails.idx, {
      startRow: 0,
      endRow: 999999,
      successCallback: null,
      failCallback: null,
      sortModel: [],
      filterModel: this.gridApi.getFilterModel(),
      context: null
    }, this.queryString)
    .subscribe((res: any) => {
      console.log(res.data);
      const data = res.data;
      if (res.aggregates) {
        this.pinnedBottomRowData = res.aggregates;
        console.log('bottom', this.pinnedBottomRowData);
      }
      this.setReportData(data);
      this.gridOptions.api.setRowData(this.rowData);
      if ( this.graphRequired ) { this.createChart(); }
      if (this.filterModelStore) {
        this.gridApi.setFilterModel(this.filterModelStore); // prevent change though!
      }
      this.gridApi.setColumnDefs(this.columnDefs)
    });
  }



  private confirmDeletion(rowId) {
    this.dialogService.genericDialog("Confirm Deletion", `Are you sure you want to delete id=${rowId} ?`, ["Yes"], ["No"], true).then(res => {
      console.log(res);
      if (res.data === true) {
        this.deleteRow(rowId);
      }
    });
  }

  private deleteRow(rowId: number): void {
    this.dform.deleteForm(this.reportDetails.onClickVar, rowId).subscribe((deleted: any) => {
      this.loadReport();

      if ( deleted ) {
        this.gridOptions.api.refreshInfiniteCache();

       // if ( this.reportDetails.onClickVar === "patientdetails" ) {
       //   this.notifications.send(`You have successfully deleted the patient with id ${rowId}.`);
       // }
      }
    });
  }

  private cellClicked(params: any): void {
    if ( !params.data ) {
      return;
    }

    console.log("[report]", this.reportDetails.onClickField, params.data);

    let rowId = params.data.rowId;
    if (this.reportDetails.onClickField){
      rowId = params.data[this.reportDetails.onClickField];
    }




    if ( params.colDef.field === "delete-row" ) {
      this.confirmDeletion(rowId);
    } else {
      //this.saveReportFilters();
      this.reportsConfig.navigateReport(this.reportDetails, rowId, params);
    }
  }

  add() {
    if ( this.poPopup ) {
      this.router.navigateByUrl(`/finance/${this.reportDetails.onClickVar}`);
    } else if (this.makeLetter ) {
      this.router.navigateByUrl(`/lettertemplates`);
    } else {
      this.router.navigateByUrl(`/form/${this.reportDetails.onClickVar}/0`);
    }
  }

  writeCommissionPO(): void {
    // const staffMemeber = this.selectedStaff.idx ? this.selectedStaff.idx : 0;
    // const selectedClinic = this.selectedClinic.idx ? this.selectedClinic.idx : 0;
    const filterModel = this.gridApi.getFilterModel();
    console.log(filterModel);
    console.log(this.columnDefs);
    this.router.navigate(['finance', 'commissionPO', this.reportName, {model: JSON.stringify(filterModel)}]);
  }

  clearFilters() {

    if (this.reportWithDefaultFilter) {
      this.reportsConfig.setDateRangeFilterModel(this.quickDateFilters[0], this.defaultFilterValue.delta, this.defaultFilterValue.unit, null, null);
    } else {
      // this.gridOptions.api.setRowData(this.rowData);
      this.gridApi.setFilterModel(null);
      this.selectedStaff = null;
      this.selectedClinic = null;

      for (const filter of this.quickDateFilters) {
        filter.activeText = null;
      }

      this.reportsConfig.clearFilters();

      this.refreshDataIfClientSideModel();

      this.filtersActive = false;
    }
  }


  refreshDataIfClientSideModel() {
    if ( this.gridOptions.rowModelType == DataModel.CLIENT ) {
      this.filterModelStore = this.gridApi.getFilterModel();
      this.getReportData();
    }
  }

  public selectYear(selectedYear: number, yearFrom: boolean, yearTo: boolean): void {
    this.yearFrom = yearFrom ? selectedYear : this.yearFrom;
    this.yearTo = yearTo ? selectedYear : this.yearTo;

    this.yearsTo = this.yearsRange(this.yearFrom, this.years[this.years.length - 1]);
    this.yearsFrom = this.yearsRange(this.years[0], this.yearTo);

    const yearsRange = [];
    for (let year = this.yearFrom; year <= this.yearTo; year++) {
      yearsRange.push(year.toString());
    }

    const instance = this.gridApi.getFilterInstance(fields.AppointmentsAMonth.year);
    instance.setModel({ filterType: 'set', values: yearsRange });
    this.gridApi.onFilterChanged();
  }

  private setYears(): void {
    this.rowData.forEach((row: any) => {
      if (!this.years.includes(row[fields.AppointmentsAMonth.year])) { this.years.push(row[fields.AppointmentsAMonth.year]); }
    });
    this.yearsFrom = this.years.sort();
    this.yearsTo = this.years.sort();
    this.yearFrom = this.years[0];
    this.yearTo = this.years[this.years.length - 1];
  }

  private yearsRange(from: number, to: number): number[] {
    return this.years.slice(this.years.indexOf(from), this.years.indexOf(to) + 1);
  }

  private setDateRangeFilter(data: any) {
    if (data) {
      const instance = this.gridApi.getFilterInstance(data.column.field);

      instance.setModel(data.model);
      console.log("[report] [filter]", data.model);
      this.gridApi.onFilterChanged();
      this.refreshDataIfClientSideModel();
      this.filtersActive = true;
    }
  }

  ngOnDestroy(): void {
    if ( this.filters$ ) { this.filters$.unsubscribe(); }
    if ( this.clinic$ ) { this.clinic$.unsubscribe(); }
    if ( this.dateRange$ ) { this.dateRange$.unsubscribe(); }
  }

  stopPropagation(event){
    event.stopPropagation();
  }

  staffMenuOpened(){
    console.log("staff menu is open");
    this.staffMenuShown = !this.staffMenuShown;
  }

}
