import { createReducer, on } from '@ngrx/store';
import { State, initialState } from '../../models/config';
import * as configAction from '../actions/config';
import { ActionWithPayload } from './index';

export { State, initialState };

const featureReducer = createReducer(
  initialState,
  on(configAction.GetConfig, (state) => state),
  on(configAction.SetConfig, (state, action) => state = action.payload)
);
  
export function reducer(state:State = initialState, action: ActionWithPayload) {
  return featureReducer(state, action);
}

export const getState = (state: State) => state;

