import { Injectable } from '@angular/core';
import { Clinic } from 'app/models/clinics';
import { StaffMember } from 'app/models/staff';
import { Observable } from 'rxjs';
import { DataService } from './data.service';
import { NotificationService } from './notification.service';

@Injectable({ providedIn: 'root' })
export class CommissionMatrixService {

  constructor( private http: DataService, private notification: NotificationService ) {}

  public getMatrix(): Observable<any> {
    return this.http.get("/matrix");
  }

  public updateMatrix(matrix: any): Observable<any> {
    return this.http.post("/matrix", matrix);
  }

  public matrixChanged(matrix: any, value: any, staff: StaffMember, clinic: Clinic, table: string): any {
    let found: boolean = false;

    matrix[table] = matrix[table].map((field: any) => {
      if ( field.StaffIdx === staff.idx && field.ClinicIdx === clinic.idx ) {
        found = true;

        const update: any = { ...field };

        if ( table === 'worksAt' ) update.WorksHere = value ? 'TRUE' : 'FALSE';
        if ( table === 'billsAt' ) update.BillsHere = value ? 'TRUE' : 'FALSE';
        if ( table === 'commission' ) {
          if ( this.validPercentage(value) ) update.Commission = this.forceFloatFormat(value);
          else {
            this.notification.send("Commission values must be valid percentages between 0 and 100.");
            update.Commission = this.forceFloatFormat("100.0");
          }
        }

        return update;
      } else return field;
    })

    if ( !found ) {
      const insert: any = { ClinicIdx: clinic.idx, StaffIdx: staff.idx, isActive: "TRUE" };

      if ( table === 'worksAt' ) insert.WorksHere = "FALSE";
      if ( table === 'billsAt' ) insert.BillsHere = "FALSE";

      matrix[table] = [ ...matrix[table], insert ];
    }

    return matrix;
  }

  private validPercentage(value: string): boolean {
    return ( ( !isNaN(+value) ) && ( +value >= 0 ) && ( +value <= 100 ) );
  }

  private forceFloatFormat(value: string): string {
    return ( value.indexOf(".") !== -1 ) ? value.split(".")[1].length > 1 ? value.toString() : value + "0" : value + ".00";
  }

  private setInitials(table: string, segment: any): any {
    switch ( table ) {
      case "worksAt": return segment ? segment.WorksHere === 'TRUE' ? true : false : true;
      case "billsAt": return segment ? segment.BillsHere === 'TRUE' ? true : false : true;
      case "commission": return segment ? segment.Commission : "0.00";
    }
  }

  public populateCommissions(staff: StaffMember[], clinics: Clinic[], commission: any): any {
    staff.forEach((member: StaffMember) => {
      clinics.forEach((clinic: Clinic) => {
        if ( !commission.find((comm: any) => comm.StaffIdx === member.idx && comm.ClinicIdx === clinic.idx) ) {
          commission.push({
            ClinicIdx: clinic.idx,
            Commission: "0.00",
            StaffIdx: member.idx,
            isActive: "TRUE"
          })
        }
      })
    })

    return commission;
  }

  public getMatrixValue(matrix: any, staff: StaffMember, clinic: Clinic, table: string): any {
    const segment = matrix[table].find((field: any) => field.StaffIdx === staff.idx && field.ClinicIdx === clinic.idx );

    return this.setInitials(table, segment);
  }

  public sortStaff(staff: StaffMember[]): StaffMember[] {
    return staff.slice().sort((memberA: StaffMember, memberB: StaffMember) => memberA.firstname.localeCompare(memberB.firstname));
  }

  public inspectMatrix(matrix: any): any {
    matrix.billsAt = this.ensureValues(matrix, "billsAt", "BillsHere");
    matrix.worksAt = this.ensureValues(matrix, "worksAt", "WorksHere");

    return matrix;
  }

  private ensureValues(matrix: any, table: string, field: string): any {
    return matrix[table].map((entry: any) => {
      return {
        ...entry,
        [field]: entry[field] === "" ? 'FALSE' : entry[field]
      }
    })
  }
}
