<form autocomplete="off" #formEle="ngForm">   

    <mat-form-field *ngFor="let field of medicalHistoryFields">
        <mat-label>{{field.label}}</mat-label>
        <textarea 
            matInput
            cdkTextareaAutosize
            cdkAutosizeMinRows="1"
            cdkAutosizeMaxRows="12"
            [name]="field.field"
            [(ngModel)]="form[field.field]"></textarea>
    </mat-form-field>

</form>
