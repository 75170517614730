<!-- The whole status history is required -->
<ng-container *ngIf="!onlyButtons">
    <mat-card *ngIf="(!onlyHistory && ( locked || locks.length )) || (onlyHistory && locks.length)" class="mb-3">
        <mat-card-content>
            <div>
                <div *ngFor="let lock of locks; let i = index" class="d-flex" [ngClass]="{'my-comment': lock.userIdx==myUserId, 'other-comment':  lock.userIdx!=myUserId}">
                    <div class="comment-wrapper">
                      
                        <span *ngIf="lock.locked && !lock.change_requested"
                            class="icon icon-danger"><mat-icon>lock</mat-icon></span>
                        <span *ngIf="!lock.locked && !lock.change_requested"
                            class="icon icon-success"><mat-icon>lock_open</mat-icon></span>                   
                        <span *ngIf="lock.change_requested"
                            class="icon icon-warning"><mat-icon>lock_reset</mat-icon></span>

                        <span class="text fw-100">
                            {{ lock.userName }}&nbsp;<span>{{ lock.DateAdded | date : "short" }}</span> 
                        </span>
                        <div class="break" *ngIf="lock.comment"></div> <!-- break -->

                        <div class="comment-text other-comment" *ngIf="lock.comment">
                            {{lock.comment}}
                        </div>

                    </div>
                </div>


              

            </div>
        </mat-card-content></mat-card>
</ng-container>