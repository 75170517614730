<h2 mat-dialog-title>PURGE</h2>
<mat-dialog-content>
  This will permanently erase {{ data.length }} patients from the database.<br />
  Including all notes, and personal details.<br />
  This information CAN NOT be recovererd.<br />
  Financial and sales data is retrained but will be associated with anonymous
  entries.
  <br />
  To confirm please re-enter your password
  <mat-form-field>
    <input
      matInput
      type="password"
      [(ngModel)]="password"
      placeholder="password"
    />
  </mat-form-field>
</mat-dialog-content>
<mat-dialog-actions>
  <button mat-raised-button mat-dialog-close>No, stop</button>
  <!-- The mat-dialog-close directive optionally accepts a value as a result for the dialog. -->
  <button mat-raised-button color="warn" [mat-dialog-close]="password">
    Begin the purge
  </button>
</mat-dialog-actions>
