<div class="container" *ngFor="let menuItem of filteredMenu">
    <div class="menuItemTitle">{{menuItem.title}}</div>
    <mat-card><mat-card-content>
        <mat-list *ngFor="let subMenuItem of menuItem.subItems;">
            <mat-list-item>
                <div class="row w-100 cursor-pointer" (click)="starClicked(subMenuItem, menuItem.title)">
                    <div class="col-sm-1">
                        <mat-icon [ngClass]="subMenuItem.favourite ? 'star-active' : 'star-inactive'">{{ subMenuItem.favourite ? 'star' : 'star_border' }}</mat-icon> 
                    </div>
                    <div class="col-sm-8">{{ subMenuItem.title }}</div>
                    <div class="col-sm-1" >{{ subMenuItem.score }}</div>
                </div>
            </mat-list-item>
        </mat-list>
    </mat-card-content></mat-card>
</div>