export const ReapsQuestions: { label: string, field: string }[] = [
    { label: "1. Skip breakfast?", field: "skip_breakfast" },
    { label: "2. Eat 4 or more meals from sit-down or take-away?", field: "eat_four_meals" },
    { label: "3. Eat less than 2 servings of whole grain products or higher fibre starches a day? Serving = 1 slice of 100% whole grain bread; 3 tablespoons (tbs) whole grain cereal like Shredded Wheat, Weetabix, brown flakes, high fibre cereals, porridge, 3-4 whole grain crackers, 2-3 tbsp brown rice or wholemeal pasta, boiled or baked potatoes", field: "eat_high_fibre" },
    { label: "4. Eat less than 2 servings of fruit a day? Serving = (80 g/3oz) or 1 med. fruit or 1 small glass 100% fruit juice", field: "eat_fruits" },
    { label: "5. Eat less than 2 servings of vegetables a day? Serving = 80g/3oz/3 heaped tablespoons vegetables or leafy raw vegetables", field: "eat_vegetables" },
    { label: "6. Eat or drink less than 2 servings of milk, yogurt, or cheese a day? Serving = 1 glass milk or yogurt; 30-60g/11/2-2 ounces cheese", field: "eat_cheese_milk" },
    { label: "7. Eat more than 225g/8oz (see sizes below) of meat, chicken, turkey or fish per day? Note: 80g/3oz of meat or chicken is the size of a deck of cards or ONE of the following: 1 regular hamburger, 1 chicken breast or leg (thigh and drumstick), or 1 pork chop.", field: "eat_meat" },
    { label: "8. Use regular processed meats (like salami, corned beef, hotdogs, sausage or bacon) instead of low fat processed meats (like roast beef, turkey, lean ham)?", field: "eat_processed_meat" },
    { label: "9. Eat fried foods such as fried chicken, fried fish, chips?", field: "eat_fried_foods" },
    { label: "10. Eat regular crisps, tortilla chips, crackers, regular popcorn, peanuts instead of, low-fat crisps or low-fat crackers, air-popped popcorn?", field: "eat_junk_food" },
    { label: "11. Add butter, margarine or oil to bread, potatoes, rice or vegetables at the table?", field: "add_butter_oil" },
    { label: "12. Eat sweets like cake, cookies, pastries, doughnut, muffins, chocolate and sweets more than 2 times per day.", field: "eat_sweets" },
    { label: "13. Drink 500ml/1 pint or more of non-diet fizzy drink, fruit drink/squash a day? Note: 1 can of fizzy drink = 355ml/12oz", field: "drink_fizzy_drinks" }
];
