import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-card-template-base',
  templateUrl: './card-template-base.component.html',
  styleUrls: ['./card-template-base.component.css']
})
export class CardTemplateBaseComponent implements OnInit {
  @Input() data: any;

  constructor() { }

  ngOnInit() {
  }

}
