import { Injectable } from '@angular/core';
import { DataService } from '../../services/data.service'
import { environment } from '../../../environments/environment'
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators'
import { AuthService } from 'app/services/auth.service';

@Injectable()

export class FilesService {

    private endpoint = "/files"
    private _cdn = "https://cdn.multiclinic.co.uk/"

    constructor(private http: DataService, private auth: AuthService) { }

    getFiles(idx: number): Observable <any> {
       return this.http.get(`${this.endpoint}/${idx}`)
    }
    
    getFile(fileId: string){
        this.http.get(`/file/${fileId}`).subscribe((res:any)=>{
            window.open(res.url);
        });
    }

    public getFileURL(fileId: string): Observable<{ url: string }> {
        return this.http.get<{ url: string }>(`/file/${fileId}`);
    }

    deleteFile(fileId: string){
        return this.http.delete(`/file/${fileId}`)
    }

    cdn(){
        const auth =  this.auth.getAuthState();
        return this._cdn+auth.meta.db;
    }
    

}
