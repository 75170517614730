import { Injectable } from '@angular/core';
import {
    Actions,
    createEffect,
    ofType
} from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';

import { mergeMap, map, withLatestFrom } from 'rxjs/operators';
import * as settingsActions from '../actions/user-settings';
import { DataService } from '../../services/data.service';
import * as fromRoot from '../reducers';

@Injectable()
export class UserSettingsEffects {
    constructor(private http: DataService, private actions$: Actions, private store$: Store<fromRoot.State>) { }

    // ngrxOnInitEffects(): Action {
    //     return { type: settingsActions.ActionTypes.INIT };
    // }

    initSettings$ = createEffect(() => {
        return this.actions$
        .pipe(
            ofType(settingsActions.ActionTypes.INIT),
            mergeMap(() => this.http.get<any[]>('/session')
                .pipe(
                    map(settings => {
                        console.log("[settings USER] sets", settings);
                        const rf = settings.find(f=>(f.var=='reportFilters'));
                        let reportFilters = [];
                        if (rf && rf.data && Array.isArray(rf.data)){
                            reportFilters = rf.data;
                        }
                        return (new settingsActions.SetAction({
                            loaded: true,
                            useOldIcons: settings.find(f=>(f.var=='useOldIcons')) ? settings.find(f=>(f.var=='useOldIcons')).data : false,
                            reportFilters: reportFilters,
                            mobileView: settings.find(f=>(f.var=='mobileView')) ? settings.find(f=>(f.var=='mobileView')).data : false,
                            darkMode: settings.find(f=>(f.var=='darkMode')) ? settings.find(f=>(f.var=='darkMode')).data : false,
                        }))
                    })
                ))
        );
    });

    saveReportSettings$ = createEffect(()=>{
        return this.actions$
        .pipe(
            ofType(settingsActions.ActionTypes.SET_REPORT_FILTERS),
            withLatestFrom(this.store$),
            map(([action, storeState]) => {
                this.http.post(`/session/reportFilters`, {data: storeState.userSettings.reportFilters} ).subscribe(res => {});
            })
        )
     }, { dispatch: false });
}
