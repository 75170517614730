import { Component, HostListener, OnInit, ViewChild } from '@angular/core';
import { CardTemplateBaseComponent } from '../card-template-base/card-template-base.component';
import { DiaryService } from '../../../services/diary/diary.service';
import { viewTypes } from '../../../models/diary';
import { ActivatedRoute } from '@angular/router';
import { MatCalendar, MatDatepicker } from '@angular/material/datepicker';
import { SwiperOptions } from 'swiper';
import { StaffMember } from 'app/models/staff';
import { AuthService } from 'app/services/auth.service';
import { ZoomService } from 'app/services/diary/zoom.service';
import moment from 'moment';

const NUMBER_OF_SLIDES_IN_VIEW = 7;
const NUMBER_OF_INITIAL_SLIDES: number = 4 * NUMBER_OF_SLIDES_IN_VIEW;
const INITIAL_SLIDE_INDEX: number = ( NUMBER_OF_INITIAL_SLIDES / 2 ) - Math.floor(NUMBER_OF_SLIDES_IN_VIEW / 2);

interface Slide {
    dayTag: string;
    dayNumber: number;
    date: Date;
}

@Component({
    selector: 'app-card-sidebar-header',
    templateUrl: './card-sidebar-header.component.html',
    styleUrls: ['./card-sidebar-header.component.scss']
})
export class CardSidebarHeaderComponent extends CardTemplateBaseComponent implements OnInit {
    public viewDate: Date;
    public isToday: boolean;
    public minDate: Date = new Date();
    public maxDate: Date = new Date();
    public value: Date = new Date();
    public calendarCollapsed = true;
    public view: string;
    public mobileView = false;
    public slides: Slide[] = [];
    public today: Date = new Date();
    public viewTypes = viewTypes;
    public calendarExpanded = false;
    public allStaffRequested = false;

    public swiperOptions: SwiperOptions = {
        slidesPerView: NUMBER_OF_SLIDES_IN_VIEW,
        initialSlide: INITIAL_SLIDE_INDEX
    };

    @ViewChild('calendar', {static: false}) calendar: MatCalendar<Date>;

    constructor(public diary: DiaryService, public zoomService: ZoomService, private route: ActivatedRoute, private auth: AuthService) {
        super();
    }

    @HostListener("window:resize", ["$event"])
    onResize(event) {
        this.setMobileView(event.target.innerWidth);
    }

    setMobileView(windowWidth: number): void {
        const wasMobile: boolean = this.mobileView;

        this.mobileView = windowWidth <= 1000;

        if ( this.mobileView && !wasMobile ) {
            this.selectCurrentStaffMember();
        }
    }

    ngOnInit() {
        this.setMobileView(window.innerWidth);
        this.generateSlides();

        this.diary.getCurrentDate().subscribe(res => {
            this.viewDate = res;


             if (Math.floor((this.viewDate.getTime() + 3600000) / (1000 * 60 * 60 * 24)) == Math.floor(new Date().getTime() / (1000 * 60 * 60 * 24))) {
                this.isToday = true; // have to add an hour as if it's 00:00:00 then the calc is out
            } else {
                this.isToday = false;
            }


            if (this.calendar) {
                this.calendar.selected = this.viewDate;

                const vd = moment(res);
                // @ts-ignore
                this.calendar._goToDateInView(moment(res), 'month'); // you have to use moment here but then TS goes off on one as it is the wrong type

            }
        });

        this.diary.getCurrentView().subscribe(res => {
            this.view = res;
        });

        this.route.queryParams.subscribe((res) => {
            if (res.date) {
                this.diary.setDate(res.date);
            }
        });
    }

    public selectCurrentStaffMember(): void {
        if ( this.mobileView && !this.allStaffRequested ) {
            const staff = this.diary.allStaff.find((staff: StaffMember) => staff.idx === this.auth.staffId());

            if ( staff ) {
                this.diary.setShowWorking(false);
                this.diary.updateSelectedStaff([ staff ]);
            }
        }
    }

    public sameDay(dateOne: Date, dateTwo: Date): boolean {
        return dateOne.getFullYear() === dateTwo.getFullYear() &&
               dateOne.getMonth() === dateTwo.getMonth() &&
               dateOne.getDate() === dateTwo.getDate();
    }

    private getNextDay(fromDate: Date, delta: number): Date {
        return new Date(new Date(fromDate.getTime()).setDate(fromDate.getDate() + delta));
    }

    private generateNextSlide(next?: boolean): void {
        const index = next ? this.slides.length - 1 : 0;
        const delta = next ? 1 : -1;

        const date = this.getNextDay(this.slides[index].date, delta);
        const dayTag = new Intl.DateTimeFormat('en-UK', { weekday: 'short' }).format(date).charAt(0);
        const dayNumber = date.getDate();

        (next ? this.slides.push({ dayTag, dayNumber, date }) : this.slides.unshift({ dayTag, dayNumber, date }) );
    }

    private generateNextSlides(next?: boolean, slidesRequired: number = NUMBER_OF_SLIDES_IN_VIEW): void {
        for ( let iterator = 0; iterator < slidesRequired; iterator++ ) {
            this.generateNextSlide(next);
        }
    }
    /*
    Doesn't seem to run, the only use i can see is that it is used as an event for the calendar to listen for and update the day
    */
    public selectDay(date: Date): void {
        if ( !this.allStaffRequested ) {
            this.selectCurrentStaffMember();
        }

        this.onCalendarSelectedChange(date);
    }

    private generateSlides(): void {
        this.slides = [];

        for ( let dayIndex: number = -(NUMBER_OF_INITIAL_SLIDES / 2); dayIndex <= (NUMBER_OF_INITIAL_SLIDES / 2); dayIndex++ ) {
            const date = this.getNextDay(this.today, dayIndex);
            const dayTag = new Intl.DateTimeFormat('en-UK', { weekday: 'short' }).format(date).charAt(0);
            const dayNumber = date.getDate();

            this.slides.push({ dayTag, dayNumber, date });
        }
    }


     /*
    Shows the previous month in the calendar
    */
    public reachedBeginning(): void {
        this.generateNextSlides(false);
    }
    /*
    Shows the next month in the calendar
    */
    public reachedEnd(): void {
        this.generateNextSlides(true);
    }

    /*
    Filters the diary to only show a single employee
    */
    public selectOnlyOne(): void {
        this.allStaffRequested = false;
        this.selectCurrentStaffMember();
    }
    /*
    Filters the diary to only show working employees
    */
    public selectWorking(): void {
        this.diary.setShowWorking(true);
        this.onCalendarSelectedChange(this.viewDate);
        this.allStaffRequested = true;
    }


    /*
    Changes the date by the supplied days
    */
    setDate(deltaDays: number) {
        this.diary.setDate(this.viewDate, deltaDays);
    }

    /*
    Changes the highlighted day and updates the view.
    Only called when day is selected with the button, not when advancing time.
    */
    onCalendarSelectedChange($event) {
        this.viewDate = $event;
        this.diary.setDate($event, null);
}
}
