/**
 * Hard coded field mapping used within MC.
 * Listed here so that if they break then they are not burried in random places
 */

export const CommissionUnpaidCash = {
    REPORT_IDX: 114,
    REPORT_NAME: 'commissionUnpaid',
    clinicIdx: 'field_495',
    staffIdx: 'field_496',
    date: 'field_498'
};

export const CommissionUnpaidCredits = {
    REPORT_IDX: 115,
    REPORT_NAME: 'commissionUnpaidCredits',
    clinicIdx: 'field_516',
    staffIdx: 'field_515',
    date: 'field_507'
};


export const POs = {
    REPORT_IDX: 69,
};

export const AppointmentsAMonth = {
    REPORT_IDX: 8,
    year: 'field_29'
};




