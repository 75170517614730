import { Injectable } from "@angular/core";
import { Store } from "@ngrx/store";

import { SubMenuService } from "./sub-menu.service";
import { forkJoin, interval } from "rxjs";
import { Observable } from "rxjs";
import { distinctUntilChanged, map, skip, throttle } from "rxjs/operators";
import { DataService } from "../data.service";
import * as fromRoot from '../../store/reducers';
import * as fromMenu from '../../store/actions/menu';
import { SubMenuItem } from "app/models/menu";
@Injectable()
export class NavBarService {
  constructor(
    private http: DataService, 
    private subMenu: SubMenuService, 
    private store: Store<fromRoot.State>
    ) {
      this.autoSaveMenuSession();
    }

  getManipulatedNavBarItems(items) {
    let newItems;
    if (items.length > 0) {
      newItems = items.slice(1);

      // newItems.splice(1, 0, { title: 'Till', link: '/pay' })

      newItems.join();

      // Old code but use this if you have to force add items to the nav bar menu

      // newItems.splice(5, 0, {
      //   title: 'Credits', subItems: [{ title: 'Credit Pricing', link: 'report/CreditPricing' },
      //   { title: 'Transfer  Credit Between Accounts', link: 'null' },
      //   { title: 'Buy Credits', link: 'report/BuyCredits' }, { title: 'Adjust Patient Credit Balance', link: 'report/CreditAdjust' },
      //   { title: 'Adjust Patient Credit Value', link: 'report/CreditValuation' },
      //   { title: 'Credit Reports', link: 'report/ClientCredits' }]
      // });

      // newItems.join();
    }
    return newItems;
  }

  getSession(key: string): Observable<any> {
    return this.http.get<any>(`/session/${key}`);
  }

  saveSession(key: string, payload: any) {
    return this.http.post<any>(`/session/${key}`, {data: payload});
  }

  saveMenu(){
  // this.store.dispatch(new fromMenu.SaveMenuScores());
  }

  toggleFavourite(menuItem : SubMenuItem){
    this.store.dispatch(new fromMenu.ToggleFavourite(menuItem));
  }
  

  getMenuFromApi(): Observable<any> {
    const menu = this.http.get('/menu');
    const scores = this.getSession('menu');

    return forkJoin({menu, scores}).pipe(map((response: any) =>{
      console.log("[menu] merging in scores", response.scores, response.menu);
      for (let score of response.scores){ // roll over scores
        for (let menu of response.menu.menuItems){  // over top level menu
          if (menu.subItems){ // sub items exists
            for (let item of menu.subItems){ // check them all
              if (item.link == score.link){
                item.score = score.score;
                item.favourite = score.favourite;
              }
            }
          }
        }
      }

      return response.menu;
    }));

  }

  autoSaveMenuSession(){
    this.store.select(fromRoot.getMenu)
    .pipe(skip(1), distinctUntilChanged(), throttle(val => interval(5000)))
    .subscribe(res=>{
      let flatMenu = [];
      for (let menu of res){
        if (menu.subItems){
          flatMenu = flatMenu.concat(
            menu.subItems.map(item=>{
              return {link: item.link, score: item.score || 0, favourite: item.favourite || false };
            })
          )
        }
      }
      console.log("[menu] update detected saving session", flatMenu);
      this.saveSession("menu", flatMenu).subscribe(res=>{});
    });
  }
  
  

/*
  getMenu(): Observable<any> {  //scoring moved to state so it persists
    const menu: Observable<any> = this.store.select(fromRoot.getMenu);
    return menu;
    //const menuSession: Observable<any> = this.getSession("menu"); 
/*
    return forkJoin({ menu, menuSession }).pipe(map((response: any) => {
      for (let item of response.menu.menuItems) {
        if (item.subItems) {
          for (let sub of item.subItems) {
            let sessionElement: any;
            response.menuSession.find((session: any) => {
              for ( let element in session ) {
                if ( session[element].id === sub.idx ) {
                  sessionElement = session[element];
                  return true;
                }
              }
            });

            sub.enabled = true;
            sub.score = sessionElement?.score ? sessionElement?.score : 0;
            sub.favourite = sessionElement?.favourite ? sessionElement?.favourite : false;

            if ( sessionElement?.favourite === true ) {
              const subItem = {
                parent: item.title,
                name: sub.title,
                link: sub.link,
                score: 10
              }

              this.subMenu.addNewItem(subItem);
            }
          }
        }
      }


      return response.menu;
    }))
    
  }
*/

  flattenMenuItem(items){
    let flat = [];
    for (let item of items){
      if (item.link!=""){
        flat.push({title: item.title, link: item.link});
      }
      if (item.subItems){
        flat = flat.concat(this.flattenMenuItem(item.subItems));
      }
      
    }
    return flat;
  }
}
 