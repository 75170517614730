import { Component } from '@angular/core';
import { NotesBuilderBaseComponent } from 'app/notes/notes-builder/notes-builder-base/notes-builder-base-component';
import { StartBackQuestions } from '../models/start-back';

@Component({
  selector: 'app-start-back',
  templateUrl: './start-back.component.html',
  styleUrls: ['./start-back.component.scss']
})
export class StartBackComponent extends NotesBuilderBaseComponent {
  StartBackQuestions = StartBackQuestions

  numSequence(n: number): Array<number> {
    return Array(n);
  }
}
