import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CreditsRoutingModule } from './credits-routing.module';
import { TransferCreditsComponent } from './transfer-credits/transfer-credits.component';
import { PatientCreditsComponent } from './patient-credits/patient-credits.component';
import { MyMaterialModule } from 'app/material/material.module';
import { CustomFormControlsModule } from 'app/custom-form-controls/custom-form-controls.module';


@NgModule({
  declarations: [
    TransferCreditsComponent,
    PatientCreditsComponent
  ],
  imports: [
    CommonModule,
    CreditsRoutingModule,
    MyMaterialModule,
    CustomFormControlsModule
  ]
})
export class CreditsModule { }
