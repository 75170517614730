import { AfterViewInit, Component, ElementRef, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'notes-new-app-dialog',
  templateUrl: './notes-new-dialog.component.html',
  styleUrls: ['../dialog-shared.scss']
})
export class NotesNewDialogComponent implements OnInit, AfterViewInit {

  public textInput: string
  @ViewChild('inputEle', {static: true}) inputEle : ElementRef;
  constructor(
    @Inject(MAT_DIALOG_DATA) 
    public data: any,
    public dialogRef: MatDialogRef<NotesNewDialogComponent>, 
  ) { }

  @HostListener('window:keyup.Enter', ['$event'])
  onDialogClick(event: KeyboardEvent): void {
    this.onConfirm(true);
  }

  ngOnInit(): void {
  }

  ngAfterViewInit(){
    setTimeout(() => { 
      this.inputEle.nativeElement.focus()
    }, 500);
  }

  public onConfirm(action) : void {
    this.dialogRef.close({action: action, title: this.textInput});
  }

  public onDismiss() : void {
    this.dialogRef.close(false);
  }

}
