<div class="title m-5">
    <h5>Transfer Credits</h5>
</div>

<div class="row my-5 mx-2">

    <div class="col-5">
        <mat-label>From</mat-label>
        <patient-chooser *ngIf="!patientFromIsSelected" (selectPatient)="selectedPatientEvent(1, $event)">
        </patient-chooser>
        <div *ngIf="patientFromIsSelected">
            <mat-form-field class="full-width">
                <input matInput disabled [value]="fromPatient?.name">
            </mat-form-field>
        </div>

    </div>

    <div class="col-2 arrow-container">
        <mat-icon class="center">arrow_right_alt</mat-icon>
    </div>

    <div class="col-5">
        <mat-label>To</mat-label>
        <patient-chooser *ngIf="patientFromIsSelected && !patientToIsSelected"
            (selectPatient)="selectedPatientEvent(2, $event)"></patient-chooser>
        <div *ngIf="patientToIsSelected || !patientFromIsSelected">
            <mat-form-field class="full-width">
                <input matInput disabled [value]="toPatient?.name">
            </mat-form-field>
        </div>
    </div>

</div>

<div class="row mx-2">

    <div class="col-5">

        <mat-list *ngIf="fromPatientCredits && fromPatientCredits.length > 0" role="list">
            <mat-list-item *ngFor="let credit of fromPatientCredits" role="listitem">{{credit.description}} -
                {{credit.count}}</mat-list-item>
        </mat-list>
    </div>

    <div class="col-2"></div>

    <div class="col-5">
        <mat-list *ngIf="toPatientCredits && toPatientCredits.length > 0" role="list" class="">
            <mat-list-item *ngFor="let credit of toPatientCredits" role="listitem">{{credit.description}} -
                {{credit.count}}</mat-list-item>
        </mat-list>
    </div>
</div>

<div *ngIf="toPatientCredits && !transfered" class="row mx-2 my-5">
    <h5 class="mx-3 mb-5">Select the type and number of credits you want to transfer</h5>
    <div class="d-flex justify-content-around">
        <mat-form-field class="form-fields">
            <mat-label>Credit Type</mat-label>
            <mat-select (selectionChange)="changeCreditType($event.value)">
                <mat-option *ngFor="let credit of fromPatientCredits" [value]="credit.creditIdx">
                    {{credit.description}}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="form-fields">
            <mat-label>Count</mat-label>
            <mat-select (selectionChange)="selectNumber($event.value)">
                <mat-option *ngFor="let n of numSequence(numberAvailableToTransfer); let i = index" [value]="i+1">
                    {{i+1}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>

<div *ngIf="!transfered" class="row mx-2 my-5 d-flex justify-content-evenly">
    <button (click)="onReset()" *ngIf="patientFromIsSelected" mat-raised-button color="warn">Start again</button>
    <button (click)="onTransfer()" *ngIf="numberToTransfer" mat-raised-button color="primary">Transfer</button>
</div>
