import { QuestionBase } from './question-base';

export class TextArea extends QuestionBase<string> {
  controlType = 'textarea';
  declare type: string;
  placeholder: string;

  constructor(options: {} = {}) {
    super(options);
    this.placeholder = options['placeholder'] || '';  
  }
}
