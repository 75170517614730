
<div class="container-fluid" >
    <div class="row margin-collapse">
      <div class="col-lg-10">
        <mat-card><mat-card-content>
            <div class="row">

                <div class="col-sm-4">
                    <h4 class="text-center">Telephone Consultation</h4>
                </div>

                <div class="col-sm-4">
                    <mat-icon class="text-muted me-1">smartphone</mat-icon>{{patientInfo?.mobilePhone || "Not provided"}}
                </div>

                <div class="col-sm-4">
                    <mat-icon class="text-muted me-1">cottage</mat-icon>{{patientInfo?.homePhone || "Not provided"}}
                </div>

            </div>
            <table class="table">
                <thead class="header center">
                    <tr class="lg-txt">
                        <th class="w-10">Date</th>
                        <th class="w-10">Contact</th>
                        <th class="w-40">Overview of Conversation or Message Left</th>
                    </tr>

                </thead>
                <tbody>
                    <tr class="center">
                        <td>
                            <mat-form-field>
                                <input matInput [matDatepicker]="picker" class="w-100" [(ngModel)]="consultation.date">
                                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                <mat-datepicker #picker></mat-datepicker>
                              </mat-form-field>

                            <mat-form-field>
                                <mat-label>Time</mat-label>
                                <input matInput class="w-100" placeholder="hh:mm" name="time-7"
                                    [(ngModel)]="consultation.time">
                                <mat-icon matSuffix>access_time</mat-icon>
                            </mat-form-field>
                        </td>
                            <td>
                                <mat-button-toggle-group name="contactMethod" aria-label="Contacted on" [(ngModel)]="consultation.contactMethod">
                                    <mat-button-toggle [value]="TelephoneConstultationMethod.mobile" title="Mobile phone">
                                        <mat-icon
                                            [ngClass]="{'text-success' :consultation.contacted && consultation.contactMethod=='mobile' }">
                                            smartphone</mat-icon>
                                    </mat-button-toggle>
                                    <mat-button-toggle [value]="TelephoneConstultationMethod.home" title="Home phone">
                                        <mat-icon
                                        [ngClass]="{'text-success' :consultation.contacted && consultation.contactMethod==TelephoneConstultationMethod.home }">
                                        cottage</mat-icon>
                                    </mat-button-toggle>
                                </mat-button-toggle-group>

                                <mat-checkbox
                                    class="mt-3"
                                    name="contacted"
                                    [(ngModel)]="consultation.contacted">Spoke to patient</mat-checkbox>
                            </td>
                            <td>
                                <mat-form-field class="w-100">
                                    <textarea
                                    matInput
                                    (input)="cantLeave($event)"
                                    cdkTextareaAutosize
                                    cdkAutosizeMinRows="3"
                                    cdkAutosizeMaxRows="12"
                                    [(ngModel)]="consultation.overview"
                                    name="overview"
                                    id="overview" ></textarea>
                                </mat-form-field>
                            </td>
                    </tr>
                    <tr *ngFor="let record of consultations">
                        <td>{{record.date | date : 'short'}}</td>
                        <td class="text-center">
                            <mat-icon class="text-success" *ngIf="record.contactMethod==TelephoneConstultationMethod.home && record.contacted" title="Home NO answer">cottage</mat-icon>
                            <mat-icon class="text-danger" *ngIf="record.contactMethod==TelephoneConstultationMethod.home && !record.contacted"  title="Home answerd">cottage</mat-icon>

                            <mat-icon class="text-success" *ngIf="record.contactMethod==TelephoneConstultationMethod.mobile && record.contacted" title="Mobile NO answer">smartphone</mat-icon>
                            <mat-icon class="text-danger" *ngIf="record.contactMethod==TelephoneConstultationMethod.mobile && !record.contacted" title="Mobile answered">smartphone</mat-icon>

                            <mat-icon class="text-success" *ngIf="!record.contactMethod && record.contacted" title="Answered">done</mat-icon>
                            <mat-icon class="text-danger" *ngIf="!record.contactMethod && !record.contacted" title="No Answer">clear</mat-icon>
                        </td>
                        <td>{{record.overview}}</td>
                    </tr>
                </tbody>
            </table>

        </mat-card-content></mat-card>
      </div>
      <div class="col-lg-2 mobile-margin">
        <div class="fix-top">
          <div class="desktop-buttons">
            <button mat-raised-button color="primary" (click)="addConsultation()" class="submit-cancel">Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
