<div>
    <button mat-button 
    [style.color]="colour" 
    [title]="action" 
    (click)="setStatus()" 
    [ngClass]="styleType">
      <mat-icon>{{icon}} </mat-icon>
      <span *ngIf="styleType!='icons'" class="text-black">{{action}}</span>
    </button>
    

</div> 