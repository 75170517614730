<form autocomplete="off" #formEle="ngForm">

    <h5 class="mt-4">Thinking about the last 2 weeks tick your response to the following questions:</h5>

    <div *ngFor="let question of StartBackQuestions" class="row mt-4 align-items-center">
        <mat-label class="col-md-7">{{question.label}}</mat-label>
        <div class="col-md-5 my-2">
            <mat-button-toggle-group [(ngModel)]="form[question.field]" [name]="question.field">
                <mat-button-toggle value="1">Agree</mat-button-toggle>
                <mat-button-toggle value="0">Disagree</mat-button-toggle>         
            </mat-button-toggle-group>
        </div>
    </div>

    <div class="row mt-4 align-items-center">
        <mat-label class="col-md-6">Overall, how bothersome has your pain been in the last 2 weeks?</mat-label>
        <div class="col-md-6 my-2">
            <mat-button-toggle-group [(ngModel)]="form.bothersome_pain" name="bothersome_pain">
                <mat-button-toggle value="1">Not at all</mat-button-toggle>
                <mat-button-toggle value="2">Slightly</mat-button-toggle>
                <mat-button-toggle value="3">Moderately</mat-button-toggle>
                <mat-button-toggle value="4">Very much</mat-button-toggle>
                <mat-button-toggle value="5">Extremely</mat-button-toggle>
            </mat-button-toggle-group>
        </div>
    </div>

    <div class="row mt-4 align-items-center">
        <mat-label class="col-md-3">STarT Back Score</mat-label>
            <div class="col-md-4">
                <mat-form-field>
                    <mat-label>Select an option</mat-label>
                    <mat-select [(ngModel)]="+form.start_back_score" name="start_back_score">
                        <mat-option *ngFor="let n of numSequence(9);
                           let i = index;" [value]="i+1">{{i+1}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
            <div class="col-md-4">
                <mat-button-toggle-group [(ngModel)]="form.risk_tick" name="risk_tick">
                    <mat-button-toggle value="1">Low Risk</mat-button-toggle>
                    <mat-button-toggle value="2">Med Risk</mat-button-toggle>
                    <mat-button-toggle value="3">High Risk</mat-button-toggle>
                </mat-button-toggle-group>
            </div>
    </div>

    <div class="row mt-4 align-items-center">
        <mat-label class="col-md-3">MSK-HQ</mat-label>
        <mat-form-field class="col-md-6">
            <mat-label>Select an option</mat-label>
            <mat-select [(ngModel)]="+form.b_questionnaire" name="b_questionnaire">
                <mat-option *ngFor="let n of numSequence(71);
                let i = index;" [value]="i">{{i}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

    <div class="row mt-4 align-items-center">
        <mat-label class="col-md-3">
            <div>Pain Score</div>
        </mat-label>
        <mat-form-field class="col-md-6">
            <mat-label>Select an option</mat-label>
            <mat-select [(ngModel)]="+form.pain_score" name="pain_score">
                <mat-option *ngFor="let n of numSequence(11); let i = index" [value]="i">{{i}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>

</form>