<div class="row margin-collapse" *ngIf="form">
    <div class="col-lg-10">
      <div class="row">
        <div class="col-sm-10">
          <span class="main-title">{{ form.formTitle }}</span>
          <app-signature [notesIdx]="notesIdx" [recordIdx]="notesIdx" (onLocked)="onLocked($event, null)" [formName]="form.fieldKey"
            *ngIf="signOffEnabled && globalSignOffEnabled && nSignOffSections>0"
            (click)="showGlobalSignOff=!showGlobalSignOff"></app-signature>
        </div>
  
        <div class="col-sm-2">
          <button mat-icon-button (click)="expandAll()">
            <mat-icon class="main-icon">keyboard_arrow_down</mat-icon>
          </button>
  
          <button mat-icon-button (click)="collapseAll()">
            <mat-icon class="main-icon">keyboard_arrow_up</mat-icon>
          </button>
        </div>
      </div>
      
      <div *ngFor="let section of form.questions; let i=index" class="notes-section" [id]="section.fieldKey">
        <div class="title">
          <span 
            class="sub-title"
            [ngClass]="{
                  'text-muted' : !notesStates[section.fieldKey],
                  'text-danger' : notesStates[section.fieldKey]!=NotesFormState.SAVED}">
            {{section.label}}
          </span>
  
            <app-signature [notesIdx]="notesIdx" 
            [recordIdx]="section.recordIdx" 
            [formName]="section.fieldKey"
            [requestLock]="section.signature?.forceLocked" 
            (onLocked)="onLocked($event, section)"
            *ngIf="signOffEnabled && !globalSignOffEnabled && !section.meta.noSignOff"></app-signature>
            
            <mat-icon class="icon text-muted" 
                [ngClass]="{'collapsed': !section.showBody,
                            'not-collapsed': section.showBody}" 
                            (click)="toggleBody(section)">
              keyboard_arrow_down
            </mat-icon>
          
  
          <!-- <pre>{{ section.signature | json }}</pre> -->
  
        </div>
  
  
        <mat-card [ngClass]="{'collapsed': !section.showBody,
                              'not-collapsed': section.showBody}">
  
          <div class="body m-2">
            <notes-builder-form 
              *ngIf="section.data"
              [section]="section" 
              [locked]="section.signature.locked"
              [notesIdx]="notesIdx"
              [hidden]="!notesIdx"
              ></notes-builder-form>
          </div>
        </mat-card>
      </div>
  
      <button 
        mat-flat-button 
        *ngIf="signOffEnabled && nSignOffSections>0"
        color="accent" 
        class="my-2 w-100" 
        (click)="scroll('notes_very_top')">
        <mat-icon>arrow_upward</mat-icon>&nbsp;To Sign off
      </button>
  
    </div>
    <div class="col-sm-2 mobile-margin">
      <div class="fix-top">
        <ul class="list-sections">
            <li>
              <button class="btn btn-link" (click)="scroll('notes_very_top')">
                <mat-icon>arrow_upward</mat-icon>&nbsp;TOP
              </button>
            </li>
            <li 
              *ngFor="let section of form.questions"
              [ngClass]="{
              'text-muted' : !notesStates[section.fieldKey],
              'text-danger' : notesStates[section.fieldKey]!=NotesFormState.SAVED}"
              >
              <button class="btn btn-link" (click)="scroll(section.fieldKey)">
                {{ section.label }}
              </button>
            </li>
          
        </ul>
        <div class="desktop-buttons">
          <app-submit-cancel (onSave)="save()" [saving]="saving"></app-submit-cancel>
        </div>
      </div>
    </div>
  </div>