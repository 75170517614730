<div mat-dialog-title class="dialog-title">
    <h2>Future Appointments</h2>
    <button mat-flat-button color="primary" (click)="print()" title="Print Card">
        <mat-icon>print</mat-icon>
    </button>
    <button mat-icon-button aria-label="close dialog" mat-dialog-close>
      <mat-icon>close</mat-icon>
    </button>
</div>


<div mat-dialog-actions *ngIf="data.appointments && data.appointments.length" class="mb-1 w-100">
    <mat-form-field class="w-100 mt-2">
        <mat-label>Email address</mat-label>
        <input matInput type="text" [(ngModel)]="patientEmail" required>
        <button mat-flat-button matSuffix 
        color="primary" 
        (click)="sendEmail()" 
        *ngIf="data.appointments && data.appointments.length"
        title="Send Card">
            <mat-icon>send</mat-icon>
        </button>
        <mat-error>Please provide an email address.</mat-error>
    </mat-form-field>
</div>





<div mat-dialog-content class="print-card">
    <div class="card-container">
        <div class="image-container">
            <img [src]="clientLogo" alt="Client logo" title="Client logo" style="display: block" width="150" height="auto" />
        </div>
        <div class="px-2 py-3 inner-text">{{patientName}}</div>
        <div *ngIf="data.appointments && !data.appointments.length" class="px-2 py-3 inner-text">There are no future appointments booked for this patient.</div>
        <div *ngIf="data.appointments && data.appointments.length" class="px-2 py-3 inner-text">Please find below your upcoming appointments.</div>

        <table *ngIf="data.appointments && data.appointments.length" class="px-2 table">
            <tbody>
                <tr *ngFor="let booking of data.appointments">

                    <td>{{ booking.startTime | date: 'HH:mm - EE dd MMMM' }}</td>
                    <td class="text-muted">{{ booking.staffName }}</td>
                    <td class="ical-link">
                    <a [href]="getLink(booking)">Add to calendar</a>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>

    <div>
        <p>If you need to cancel or move your appointment(s) then please give the clinic at least 24 hours notice or a late cancellation charge may apply.</p>
    </div>


    <div class="bottom-container p-2 text-center" *ngIf="data.appointments && data.appointments.length">
        <div class="clinic-title" *ngIf="data.clinicDetails.address1 && data.clinicDetails.town && data.clinicDetails.postcode">
            {{ data.clinicDetails.address1 }}, {{ data.clinicDetails.town }}, {{ data.clinicDetails.postcode }}
        </div>
        <div *ngIf="data.clinicDetails.phone || data.clinicDetails.email">{{ data.clinicDetails.phone }} - {{ data.clinicDetails.email }}</div>
        <div *ngIf="data.clinicDetails.website">
            <a style="color: white" 
            [href]="data.clinicDetails.website">{{data.clinicDetails.website}}</a>
        </div>


    </div>
</div>
