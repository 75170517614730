<div mat-dialog-title class="dialog-title">
    <h5>{{data.title}}</h5>
    <button mat-icon-button aria-label="close dialog" class="close-button" tabindex="-1" mat-dialog-close>
      <mat-icon class="close-icon text-muted">close</mat-icon>
    </button>
</div>

<div mat-dialog-content>
    <p>
        {{data.message}}
    </p>
    <p class="warning" *ngIf="data.warningMessage">
        {{data.warningMessage}}
    </p>
</div>

<div mat-dialog-actions>
    <button mat-button (click)="onDismiss()">Cancel</button>
    <button mat-raised-button color="primary" (click)="onConfirm()">Leave without saving</button>
    <button mat-raised-button color="primary" (click)="onSave()">Save and exit</button>
</div>