import { AuthNeededGuard, AuthNotNeededGuard } from "./guards/auth.guard";
import { LoginComponent } from "./login/login.component";
import { PatientPurgeComponent } from "./patient-purge/patient-purge.component";
import { DiaryComponent } from "./diary/diary.component";
import { NotesComponent } from "./notes/notes.component";
import { LogoutComponent } from "./logout/logout.component";
import { EditAppointmentComponent } from "./diary/grid-item-components/card-diary/edit-appointment/edit-appointment.component";
import { MainMenuComponent } from "./main-menu/main-menu/main-menu.component";
import { TillComponent } from "./till/till.component";
import { ViewAllGiftCardsComponent } from "./view-all-gift-cards/view-all-gift-cards.component";
import { SubMainMenuComponent } from "./main-menu/sub-main-menu/sub-main-menu.component";
import { TreatmentManagerComponent } from "./treatment-manager/treatment-manager.component";
import { AccessDeniedComponent } from "./access-denied/access-denied.component";
import { UserProfileComponent } from "./user-profile/user-profile.component";
import { InitializeGuard } from "./guards/initialize.guard";
import { LoadingPageComponent } from "./loading-page/loading-page.component";
import { HasMenuAccessGuard } from "./guards/has-menu-access.guard";
import { MergePatientsComponent } from "./patient-purge/merge-patients/merge-patients.component";
import { RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";

const routes: Routes = [
  {
    path: "",
    pathMatch: 'full',
    redirectTo: '/diary'
  },
  {
    path: 'code',
    component: DiaryComponent
  },
  {
    path: "access-denied",
    component: AccessDeniedComponent
  },
  {
    path: "appointment",
    component: EditAppointmentComponent,
    canActivate: [AuthNeededGuard],
  },
  {
    path: "home",
    component: MainMenuComponent,
    canActivate: [AuthNeededGuard, InitializeGuard],
  },
  {
    path: "login",
    component: LoginComponent,
    canActivate: [AuthNotNeededGuard],
  },
  {
    path: "logout",
    component: LogoutComponent,
  },
  {
    path: "patients/purge",
    component: PatientPurgeComponent,
    canActivate: [AuthNeededGuard, InitializeGuard],
  },
  {
    path: "merge-patients",
    component: MergePatientsComponent,
    canActivate: [AuthNeededGuard, InitializeGuard],
  },
  {
    path: "diary",
    component: DiaryComponent,
    canActivate: [AuthNeededGuard, InitializeGuard],
  },
  {
    path: "notes",
    component: NotesComponent,
    canActivate: [AuthNeededGuard, InitializeGuard],
  },
  {
    path: "pay",
    component: TillComponent,
    canActivate: [AuthNeededGuard, InitializeGuard, HasMenuAccessGuard],
  },
  {
    path: "menu",
    loadChildren: () =>
      import("./main-menu/main-menu.module").then((m) => m.MainMenuModule),
  },
  {
    path: "marketing",
    loadChildren: () =>
      import("./marketing/marketing.module").then((m) => m.MarketingModule),
  },
  {
    path: "all-gift-cards",
    component: ViewAllGiftCardsComponent,
    canActivate: [AuthNeededGuard, InitializeGuard]
  },
  {
    path: "profile",
    component: UserProfileComponent,
    canActivate: [AuthNeededGuard, InitializeGuard]
  },
  {
    path: "home/:id",
    component: SubMainMenuComponent,
    canActivate: [AuthNeededGuard, InitializeGuard]
  },
  {
    path: "loading",
    component: LoadingPageComponent,
    canActivate: [AuthNeededGuard],
  },
  { 
    path: "treatments", 
    component: TreatmentManagerComponent 
  },  
  {
    path: "users",
    loadChildren: () =>
      import("./user-admin/user-admin.module").then((m) => m.UserAdminModule)
  },
  {
    path: "credits",
    loadChildren: () =>
      import("./credits/credits.module").then((m) => m.CreditsModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

/*
RouterModule.forRoot(MCAppRoutes, { 
  initialNavigation:   !isIframe ? 'enabledBlocking' : 'disabled',
  enableTracing: true
 }),
 */