<div class="row margin-collapse">
    <div class="col-lg-10">
        <mat-card><mat-card-content>
            <h2>Choose template</h2>
            <br>
            <p>Select template from the list below, or <span class="fw-bold">No Template</span> for an empty document</p>
            
            <div class="cardContainer">
                   <ul class="list-group list-group-flush">
                        <li class="list-group-item list-group-item-action" (click)="selectTemplate(0)">No Template</li>
                        <li class="list-group-item list-group-item-action" *ngFor="let template of templates" (click)="selectTemplate(template)">
                            <div class="fw-bold">{{template.title}}</div>
                            {{template.description}}
                        </li>
                    </ul>
            </div>
        </mat-card-content></mat-card>
    </div>
    <div class="col-sm-2 no-print mobile-margin">
        <div class="fix-top">
            <div class="mb-3">
               
                
                <div class="my-3 desktop-buttons">
                    <button mat-raised-button class="mobile-button desktop-button" (click)="cancel()">Back</button>
                </div>
            </div>
        </div>
    </div>
</div>
