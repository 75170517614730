import { QuestionBase } from './question-base';

export class ColourQuestion extends QuestionBase<string> {
  controlType = 'colour';
  

  constructor(options: {} = {}) {
    super(options);
    
  }
}
