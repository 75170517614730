
export const LumboFields: { label: string, field: string }[] =
[{
  label: "Stand Kemps",
  field: "Stand_Kemps"
},
{
  label: "Sit Kemps",
  field: "Sit_Kemps"
},
{
  label: "SLR",
  field: "SLR"
}, {
  label: "Braggard-S",
  field: "Braggards"
}, {
  label: "Bowstring",
  field: "Bowstring"
},
{
  label: "Bonnet-S",
  field: "Bonnets"
},
{
  label: "Slump",
  field: "Slump"
},
{
  label: "Thomas",
  field: "Thomas"
},
{
  label: "Laguerre",
  field: "Laguerre"
},
{
  label: "Fabere",
  field: "Fabere"
},
{
  label: "Yeomans",
  field: "Yeomans"
},
{
  label: "Homer-Pheasent",
  field: "Homer-Pheasent"
},
{
  label: "SI Spring",
  field: "SI_Spring"
},
{
  label: "Path Reflexes",
  field: "Path_Reflexes"
},
{
  label: "Dejerines Triad",
  field: "Dejerines_Triad"
},
{
  label: "Leg Length",
  field: "Leg_Length"
},
{
  label: "Dermatomes",
  field: "Dermatomes"
},
{
  label: "Trigger Points",
  field: "Trigger_Points"
},
{
  label: "",
  field: "blank_1"
},
{
  label: "",
  field: "blank_2"
},];
