import { Component } from '@angular/core';
import { buildNumber } from '../build-number';

@Component({
  selector: 'contact-footer',
  templateUrl: './contact-footer.component.html',
  styleUrls: ['./contact-footer.component.scss']
})

export class ContactFooterComponent {
  buildNumber: string;

  constructor() {  }

  ngOnInit() {
    this.buildNumber = buildNumber;

  }

}
