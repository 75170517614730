import { Component, Input } from "@angular/core";
import { SubMenuService } from "../../services/menu/sub-menu.service";
import { SubMenuItem } from "../../models/menu";
import { MenuItem } from "app/models/menu";


const TOP_COUNT = 5;
@Component({
  selector: "sub-menu",
  templateUrl: "./sub-menu.component.html",
  styleUrls: ["./sub-menu.component.scss"]
})
export class SubMenuComponent {
  @Input("menuItem") menuItem : MenuItem;
  subMenuShown: boolean = false;
  displayItems: SubMenuItem[];
  showAll: boolean = false;
  TOP_COUNT = TOP_COUNT;

  constructor(
    private subMenu: SubMenuService
  ) { }

  ngOnInit() {
    this.sortMenu();
  }

  addNewItem(menuItem, subItem) {
    this.subMenu.addNewItem(subItem);
  }

  sortMenu(){
    if (!this.menuItem.subItems){
      this.displayItems = [];
    }
    
    this.displayItems = this.menuItem.subItems.map( item => { //have to deep copy state so we can sort it
      return {...item};
    });

    

    if (this.showAll || this.menuItem.subItems.length <= TOP_COUNT){ //just alpha top if less
      this.sortAlpha();
      return;
    }

    this.sortTop();
    
  }

  copyMenuItems(){
    this.displayItems = this.menuItem.subItems.map( item => { //have to deep copy state so we can sort it
      return {...item};
    });
  }

  sortAlpha(){
    this.copyMenuItems();

    this.displayItems = this.displayItems.sort((a,b) => {
      return a.title.localeCompare(b.title);
    });
  }

  sortTop(){

    this.copyMenuItems();

    this.displayItems = this.displayItems.sort( (a , b) => {
      if (a.favourite && b.favourite){ //both faves 
        return 0; //a.title.localeCompare(b.title);
      }
      if (a.favourite){ //a fave b not
        return -1;
      }
  
      if (b.favourite){ // b fave a not
        return 1;
      }
  
      const scoreDiff = a.score - b.score;
      if (scoreDiff!=0){ //no faves but score diff
        return (a.score < b.score ? 1 : -1);
      }
      
      return a.title.localeCompare(b.title); //no scores just alpha
    });

    const countFaves = this.displayItems.filter(f=> (f.favourite)).length;

    if (countFaves < TOP_COUNT){
      this.displayItems = this.displayItems.slice(0, TOP_COUNT); //use top count
    }else{
      this.displayItems = this.displayItems.slice(0, countFaves); //just take faves
    }

    this.displayItems.sort( (a , b) => { //resort top 5 alpha
      return a.title.localeCompare(b.title); //no scores just alpha
    });

  }

  setShowAll(event, all: boolean) {
    event.stopPropagation();
    this.showAll = all;
    this.sortMenu();
  }
}

