import { Component } from '@angular/core';
import { NotesBuilderBaseComponent } from 'app/notes/notes-builder/notes-builder-base/notes-builder-base-component';

import { BehaviorSubject } from 'rxjs';

import { ExtremetiesFields, AideMemoire } from '../models/extremities';

interface NotesTest {
  testName: string;
  l: string;
  r: string;
}

export const blankTest: NotesTest = {
  testName: "",
  l: "",
  r: "",
}
@Component({
  selector: 'app-extremities-v2',
  templateUrl: './extremities-v2.component.html',
  styleUrls: ['./extremities-v2.component.scss']
})
export class ExtremitiesV2Component extends NotesBuilderBaseComponent {
  showAide: boolean = false

  ExtremetiesFields = ExtremetiesFields

  public tests = [];
  AideMemoire = AideMemoire;
  aideMemoire: BehaviorSubject<any[]> = new BehaviorSubject(AideMemoire);

  ngOnInit(): void {
    super.ngOnInit();
    if (this.form._json) {
      this.tests = JSON.parse(this.form._json);
    }
    this.addTest();
  }

  public doFilter(event) {
    console.log(this.tests);
    const filterOn = this.tests[this.tests.length - 1].testName.toLowerCase();
    let filtered = [];
    for (let sec of AideMemoire) {
      const items = sec.categories.filter(f => {
        return f.toLowerCase().startsWith(filterOn);
      })
      if (items.length > 0) {
        filtered.push({ title: sec.title, categories: items });
      }
    }

    this.aideMemoire.next(filtered)
    console.log("Do FIlter", filtered, event);
  }

  protected save(): void {
    this.form._json = this.tests.filter(t=> (t.l!="" || t.r!="" || t.testName!=""));
    super.save();
    this.doFilter(null);
  }

  addTest() {
    this.tests.push({ ...blankTest });
  }

  public toggleAide() {
    this.showAide = !this.showAide
  }
}
