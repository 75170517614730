//control if the app is busy and locked
import { ActionWithPayload } from './index';
//import { createSelector } from 'reselect';

import * as clinicsAction from '../actions/clinics';
import { Clinic } from '../../models/clinics';

export interface State {
    clinics: Clinic[];
    active: Clinic;
};

export const initialState: State = {
    clinics: [],
    active: null
};


export function reducer(state = initialState, action: ActionWithPayload): State {
    switch (action.type) {
        case clinicsAction.ActionTypes.SET: {
            console.log("[clinics] set", action.payload);
            return Object.assign({}, state, { clinics: action.payload });
        }      

        case clinicsAction.ActionTypes.SELECTED: {
            return Object.assign({}, state, { active: action.payload });
        }

        default:
            return state;
    }
}

export const getClinics = (state: State) => state.clinics;
export const getSelectedClinic = (state: State) => state.active;
