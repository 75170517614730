<section>
  <mat-form-field class="example-full-width">
    <mat-label>{{question.label}}</mat-label>
    <input 
      #inputRef
      id="inputRef"
      matInput 
      [matAutocomplete]="auto" 
      [formControl]="autoCompleteTextInput"
      >
    <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="updateValue($event)">
      <mat-option *ngFor="let gp of filteredOptions | async" [value]="gp">
        {{ gp.text }}<span *ngIf="gp.info" class="ps-2 text-muted text-small">{{gp.info}}</span>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</section>
