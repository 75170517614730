import { Component, OnInit } from '@angular/core';
import { FormTemplateBaseComponent } from '../form-template-base/form-template-base.component';


@Component({
  selector: 'app-form-uploader',
  templateUrl: './form-uploader.component.html',
  styleUrls: ['./form-uploader.component.scss']
})
export class FormUploaderComponent extends FormTemplateBaseComponent implements OnInit {
  public state: string;
  
  ngOnInit(): void {
  }

  public uploadStateChanged(state: string): void {
    this.state = state;
  }

  public fileUploaded(file: any): void {
    const fileURL = file[0].publicLink;
    this.question.value = fileURL;
    console.log("[file]", fileURL);
    this.form.patchValue({
        ...this.form.value,
        [this.question.key]: fileURL
    });
  }
}
