/* Fetches questions then takes question data and build the classes */
import { Injectable } from "@angular/core";
import { DataService } from "../../services/data.service";
import { DropdownQuestion } from "../question-dropdown";
import { DropdownMultiQuestion } from "../question-dropdown-multi";
import { DropdownMultiNestedQuestion } from "../question-dropdown-multi-nested";
import { QuestionBase, fieldType, fieldClasses } from "../question-base";
import { TextboxQuestion } from "../question-textbox";
import { ColourQuestion } from "../question-colour";
import { SliderQuestion } from "../question-slider"
import { TextArea } from "../question-textarea";
import { Checkbox } from "../question-checkbox";
import { HiddenQuestion } from "../question-hidden";
import { PasswordQuestion } from "../question-password";
import { Form } from "../form";
import { map } from "rxjs/operators";
import { BehaviorSubject } from "rxjs";

@Injectable()
export class QuestionService {
  public newOptionOnChooser = new BehaviorSubject(null)

  constructor(private http: DataService) {}

  private formsList: any[];

  buildFormGroup(data) {
    let questions: QuestionBase<any>[] = [];
    console.log(data, "builder..");
    data.forEach((s) => {
      var nq;
      switch (s.fieldType) {
        case fieldClasses.SLIDER:
          nq = new SliderQuestion({
            idx: s.idx,
            key: s.fieldKey,
            label: s.label,
            orderNo: s.orderNo,
            required: s.required,
            value: s.value,
            width: s.width,
          });
          if( s.additional ){
            nq.additional = s.additional
          }
          break
        case fieldClasses.COLOUR:
          nq = new ColourQuestion({
            idx: s.idx,
            key: s.fieldKey,
            label: s.label,
            orderNo: s.orderNo,
            required: s.required,
            value: s.value,
            width: s.width,
          });
          if( s.additional ){
            nq.additional = s.additional
          }
          break
        case fieldClasses.DROPDOWN:
          nq = new DropdownQuestion({
            idx: s.idx,
            key: s.fieldKey,
            label: s.label,
            options: s.options,
            orderNo: s.orderNo,
            required: s.required,
            value: s.value,
            width: s.width,
            multichoice: (s.fieldType==fieldClasses.DROPDOWN_MULTISELECT),
          });
          if( s.additional ){
            nq.additional = s.additional
          }
          break;
        case fieldClasses.DROPDOWN_MULTISELECT:
          nq = new DropdownMultiQuestion({
            idx: s.idx,
            key: s.fieldKey,
            label: s.label,
            options: s.options,
            orderNo: s.orderNo,
            required: s.required,
            value: s.value,
            width: s.width,
            multichoice: (s.fieldType==fieldClasses.DROPDOWN_MULTISELECT),
          });
          if( s.additional ){
            nq.additional = s.additional
          }
          break;
        case fieldClasses.DROPDOWN_NESTED_MULTISELECT:
          nq = new DropdownMultiNestedQuestion({
            idx: s.idx,
            key: s.fieldKey,
            label: s.label,
            options: s.options,
            orderNo: s.orderNo,
            required: s.required,
            value: s.value,
            width: s.width,
            multichoice: (s.fieldType==fieldClasses.DROPDOWN_NESTED_MULTISELECT),
          });
          if( s.additional ){
            nq.additional = s.additional
          }
          break;
        case fieldClasses.TEXTBOX:
          nq = new TextboxQuestion({
            idx: s.idx,
            key: s.fieldKey,
            label: s.label,
            value: s.value,
            type: s.type,
            required: s.required,
            options: s.options,
            orderNo: s.orderNo,
            placeholder: s.placeholder,
            validators: s.validators,
            width: s.width,
            maxLength: s.maxLength,
          });
          if( s.additional ){
            nq.additional = s.additional
          }
          break;
        case fieldClasses.TEXTAREA:
          nq = new TextArea({
            idx: s.idx,
            key: s.fieldKey,
            label: s.label,
            value: s.value,
            type: s.type,
            required: s.required,
            options: s.options,
            orderNo: s.orderNo,
            placeholder: s.placeholder,
            validators: s.validators,
            width: s.width,
            maxLength: s.maxLength,
          });
          if( s.additional ){
            nq.additional = s.additional
          }
          break;
          case fieldClasses.HIDDEN:
            nq = new HiddenQuestion({
              idx: s.idx,
              key: s.fieldKey,
              label: s.label,
              value: s.value,
              type: s.type,
              required: s.required,
              options: s.options,
              orderNo: s.orderNo,
              placeholder: s.placeholder,
              validators: s.validators,
              width: s.width,
            });
            if( s.additional ){
              nq.additional = s.additional
            }
            break;
        case fieldClasses.SUBTITLEAREA:
          nq = new QuestionBase<string>({
            idx: s.idx,
            label: s.label,
            controlType: fieldType.SUBTITLEAREA,
            orderNo: s.orderNo,
            width: s.width,
          });
          if( s.additional ){
            nq.additional = s.additional
          }
          break;
        case fieldClasses.TITLEAREA:
          nq = new QuestionBase<string>({
            idx: s.idx,
            label: s.label,
            controlType: fieldType.TITLEAREA,
            orderNo: s.orderNo,
            width: s.width,
          });
          if( s.additional ){
            nq.additional = s.additional
          }
          break;
        case fieldClasses.PASSWORD:
          nq = new PasswordQuestion({
            idx: s.idx,
            key: s.fieldKey,
            label: s.label,
            value: s.value,
            type: s.type,
            required: s.required,
            orderNo: s.orderNo,
            validators: s.validators,
            width: s.width,
          });
          if( s.additional ){
            nq.additional = s.additional
          }
          break;
        case fieldClasses.CHECKBOX:
          nq = new Checkbox({
            idx: s.idx,
            key: s.fieldKey,
            label: s.label,
            value: s.value,
            required: s.required,
            orderNo: s.orderNo,
            validators: s.validators,
            width: s.width,
          });
          if( s.additional ){
            nq.additional = s.additional
          }
          break;
        case fieldClasses.CHECKTEXT:
          nq = [ 
            new Checkbox({
                idx: s.idx,
                key: s.fieldKey,
                label: s.label,
                value: s.checkboxValue,
                required: s.required,
                orderNo: s.orderNo,
                validators: s.validators,
                width: s.width,}), 
            new TextboxQuestion({
                idx: s.idx,
                key: s.fieldKey + "text",
                label: s.label,
                value: s.textboxValue,
                type: s.type,
                required: s.required,
                orderNo: s.orderNo,
                placeholder: s.placeholder,
                validators: s.validators,
                width: s.width,
          })];
          break;
        case fieldClasses.INFOBOX:
          nq = new QuestionBase<string>({
            idx: s.idx,
            label: s.label,
            controlType: fieldType.INFOBOX,
            orderNo: s.orderNo,
            width: s.width,
          });
          break;
        case fieldClasses.FILEUPLOAD:
          nq = new QuestionBase<string>({
            idx: s.idx,
            key: s.fieldKey,
            label: s.label,
            value: s.value,
            controlType: fieldType.FILEUPLOAD,
            orderNo: s.orderNo,
            width: s.width,
          });
          break;
        case fieldClasses.AUTOSELECT:
        case fieldClasses.PATIENTCHOOSER:
        case fieldClasses.VENDORCHOOSER:
        case fieldClasses.GPCHOOSER:
        case fieldClasses.ADDRESSCHOOSER:
          nq = new QuestionBase<string>({
            idx: s.idx,
            key: s.fieldKey,
            label: s.label,
            controlType: fieldType.CHOOSER,
            type: s.fieldType,
            orderNo: s.orderNo,
            value: s.value,
            width: s.width,
            options: s.options,
            meta: s.meta
          });
          if ( s.additional ) {
            nq.additional = s.additional;
          }
          break;
      }

      questions.push(nq);
    });
       console.log(questions, "builder..");
    return questions.sort((a, b) => a.orderNo - b.orderNo);
  }

  getQuestions(formName: string, searchOn: any = 0) {
    return this.http
      .get(`/forms/${formName}/${searchOn}`)
      .pipe(map((res: any) => res.form));
  }

  getForm(idx): Form {
    var ret: Form = null;
    this.formsList.forEach((s) => {
      if (s.idx == idx) {
        ret = s;
        return ret;
      }
    });

    if (ret == null) {
      console.error("form %s not found", idx);
    }
    return ret;
  }

  getAllForms() {
    return this.formsList;
  }

  saveField(field, formName) {
    const payload = {
      field: field,
      formName: formName,
    };

    return this.http.post("forms.php", payload);
  }
}
