import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { NavBarService } from "../../services/menu/nav-bar.service";
import { Router } from "@angular/router";
import {MenuItem} from '../../models/menu'
import * as fromRoot from '../../store/reducers';
import * as fromMenu from '../../store/actions/menu';

import { Store} from "@ngrx/store";
import { Observable, from} from "rxjs";
import { map } from 'rxjs/operators';

const flatten = (arr: Array<MenuItem>) =>{let flatArray = []
  arr.forEach(a => {
    if(a.subItems){
      a.subItems.forEach(b => flatArray.push(b))
    }
    else{
      flatArray.push(a)

    }
  })
  return flatArray
  }

@Component({
  selector: "app-side-menu",
  templateUrl: "./side-menu.component.html",
  styleUrls: ["./side-menu.component.css"]
})
export class SideMenuComponent implements OnInit {
  @Output() closeMenu = new EventEmitter();

  title = "app";
  public filterItems$: Observable<Array<MenuItem>> = this.store.select(fromRoot.getFilteredMenu)
  public autoSearchValue: string;
  panelOpenState = false;

  constructor(
    public nav: NavBarService,
     public router: Router,
    private store: Store<fromRoot.State>
    ) {

  }

  ngOnInit() {
  }
  searchEvent(event) {
    this.store.dispatch(new fromMenu.FilterMenu(this.autoSearchValue))
  }

  closeSideMenu(){
    this.closeMenu.emit(true)
  }
}
