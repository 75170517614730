import { Component } from "@angular/core";
import {
  PatientChooserResult,
  PatientDetails,
} from "app/models/patient-details";
import { DialogService } from "app/services/dialog/dialog.service";
import { NotificationService } from "app/services/notification.service";
import { PatientChooserService } from "app/services/patient.service";
import { SaleItemsService } from "app/services/sale-items.service";

@Component({
  selector: "app-merge-patients",
  templateUrl: "./merge-patients.component.html",
  styleUrls: ["./merge-patients.component.scss"],
})
export class MergePatientsComponent {
  fromPatient: PatientDetails;
  toPatient: PatientDetails;
  fromPatientCredits: any;
  toPatientCredits: any;
  patientFromIsSelected = false;
  patientToIsSelected = false;
  merged = false;

  constructor(
    private patientService: PatientChooserService,
    private saleItemsService: SaleItemsService,
    private notificationService: NotificationService,
    private dialogService: DialogService
  ) {}

  ngOnInit(): void {}

  selectedPatientEvent(instance: number, selectedPatient: any) {
    if (instance === 1) {
      this.fromPatient = selectedPatient;

      console.log(this.fromPatient);
      this.patientFromIsSelected = true;
      this.patientService
        .getPatientDetails(this.fromPatient.idx)
        .subscribe((res) => {
          console.log(res);
        });
    } else if (instance === 2) {
      this.toPatient = selectedPatient;
      this.patientToIsSelected = true;
    }
    console.log("From: ", this.fromPatient, "To", this.toPatient);
  }

  ngOnDestroy(): void {
    this.patientFromIsSelected = false;
    this.patientToIsSelected = false;
  }

  onReset() {
    this.fromPatient =
      this.toPatient =
      this.fromPatientCredits =
      this.toPatientCredits =
      this.patientFromIsSelected =
      this.patientToIsSelected =
        undefined;
    this.ngOnInit();
  }

  onMerge() {
    this.dialogService
      .genericDialog(
        "Merge Patients",
        `Are you sure you want to merge ${this.fromPatient.name} into ${this.toPatient.name}?`,
        ["Yes"],
        ["No"],
        true,
        "This process is irreversible!"
      )
      .then((dialogResult: any) => {
        console.log(dialogResult);

        if (dialogResult && dialogResult.data) {
          this.patientService
            .mergePatients(this.fromPatient.idx, this.toPatient.idx)
            .subscribe((res) => {
              console.log("Patient merged in: ", res);
              this.merged = true
              this.notificationService.send('You have successfully merged patient!')
            });
        }
      });
  }
}
