import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { type  Attendance } from 'app/models/patient-details';
import { LoadingService } from 'app/services/loading.service';
import { PatientChooserService } from 'app/services/patient.service';
import { GenericDialogComponent } from '../generic-dialog/generic-dialog.component';

@Component({
  selector: 'app-attendance-dialog',
  templateUrl: './attendance-dialog.component.html',
  styleUrls: ['../dialog-shared.scss']
})
export class AttendanceDialogComponent implements OnInit {
  public attendance: Attendance[];
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    public dialogRef: MatDialogRef<GenericDialogComponent>,
    private patient: PatientChooserService,
    private loading: LoadingService
  ) { }

  ngOnInit(): void {
    const patientIdx = this.data.args;
    console.log("[att dialog]", patientIdx);
    this.loading.start();
    this.patient.getAttendance(patientIdx).subscribe( attendance => {
      this.attendance = attendance;
      this.loading.stop();
    });
  }

  public onConfirm(index: number): void {
    this.dialogRef.close({index: index, data: true});
  }

  public onDismiss(index: number): void {
    this.dialogRef.close({index: index, data: false});
  }
}