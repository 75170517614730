import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AppointmentsService } from './appointments.service';
import { DiaryService } from './diary.service';
import * as fromDiary from "app/models/diary";
import { CancelAppConfirmDialogComponent } from 'app/dialogs/cancel-app-confirm/cancel-app-confirm-dialog.component';
import { AppointmentColourService } from './appointment-colour.service';
import { PatientChooserService } from '../patient.service';

@Injectable({
    providedIn: 'root'
})
export class AppointmentsStatusService {
    constructor(
        private appointmentColourService: AppointmentColourService,
        private dialog: MatDialog,
        private appointments: AppointmentsService,
        private patientService: PatientChooserService
    ) { }

    getColour(status) {
        return this.appointmentColourService.getColour(status);
    }

    setStatus(appointment, toStatus) {

        console.log("[status]", appointment);

        if (toStatus === fromDiary.AppointmentStatus.CANCELLED) {
            const dialogReference = this.dialog.open(CancelAppConfirmDialogComponent, {
                data: {},
                maxWidth: "600px"
              });

              dialogReference.afterClosed().subscribe((dialogResult : any) => {

                if (dialogResult.action){
                    toStatus = dialogResult.action;
                    if (appointment.meta.groupSize>1 && toStatus == fromDiary.AppointmentStatus.CANCELLED){ //is cancelled & group booking
                        toStatus = fromDiary.AppointmentStatus.UNALLOCATED_SLOT;
                    }
                    
                    this.patientService.setPatient(appointment.meta.patientId);

                    this.appointments.updateStatus(appointment, toStatus, dialogResult.reason);
                }
                if (dialogResult.isChecked) {
                    const payload = {
                            appointmentIdx: appointment.idx,
                            patientIdx: appointment.meta.patientId
                    }
                    this.appointments.emailCancelPatient(payload).subscribe((res) => {});
                }
              });
        } else {
            this.appointments.updateStatus(appointment, toStatus);
            //  this.clicked.emit(toStatus);
        }
    }
}
