import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

/**
 * Allow you to track the users location
 *  
 * */

@Injectable()
export class GeoLocationService {

    pos$;

    constructor() { }

    getPosition(): Observable<GeolocationCoordinates> {
        if (!this.pos$) {
            this.init();
        }

        return this.pos$;
    }

    init() {
        this.pos$ = new Observable(observer => {
            window.navigator.geolocation.getCurrentPosition(position => {
                observer.next(position.coords);
                observer.complete();
            },
                error => observer.error(error));
        });
    }


}

