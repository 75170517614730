
import { Component, HostListener, OnDestroy, OnInit  } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { QuestionService } from 'app/dynamic-form/services/question.service';
import { DialogService } from 'app/services/dialog/dialog.service';
import { NotesFormState, NotesSaveService } from 'app/services/notes/notes-save.service';
import { NotesService } from 'app/services/notes/notes.service';
import { SignatureService } from 'app/services/notes/signature.service';
import { SiteSettingsService } from 'app/services/site-settings.service';
import { Subscription } from 'rxjs';
import { NotesTypes } from "app/models/notes";

@Component({
  selector: 'notes-builder',
  templateUrl: './notes-builder.component.html',
  styleUrls: ['./notes-builder.component.scss']
})

export class NotesBuilderComponent implements OnInit, OnDestroy{
      public form: any;
      private formName: string;
      public locked: boolean = false;
      public notesIdx : number;
      public notesStates: any = [];
      public NotesFormState = NotesFormState;
      public saving = false;
      public showBody = true;
      public showGlobalSignOff: boolean = false;
      public globalSignOffEnabled: boolean = true;
      public nSignOffSections = 0;
      private settings$: Subscription;
      private params$: Subscription;
      protected signOffEnabled:boolean = false
      private listenForSaves$: Subscription;

      /**
       * Checkes for unsaved changes and prompts user if
       * they want to leave the page when refreshing/navigating
       * outside of app.
       * @returns {boolean} Returns a boolean
       */
      @HostListener('window:beforeunload')
      unsaved(){
        if ( this.notesSaveService.checkAllSaved()===0 ) {
          return true;
        }else{
          return false
        }
      }

      /**
       * Determines whether the user can deactivate the current route.
       * If there are unsaved changes, it stops the user from leaving
       * until the autosave feature has run.
       * @returns {Promise<boolean> | boolean} Returns a promise resolving to a boolean or a boolean
       * indicating whether the user can deactivate the current route.
       */
      canDeactivate() : Promise<boolean> | boolean{
        return true;
        /*
          if ( this.notesSaveService.checkAllSaved()===0 ) {
            return true;
          }
          this.dialog.bespokeDialog('Please wait whilst data saves', GenericDialogComponent, 'return', 'Unsaved Data', null, "550px")
          return false
        */
        }


      constructor(
        private qs: QuestionService,
        private route: ActivatedRoute,
        private router: Router,
        private dialog: DialogService,
        private notesSaveService: NotesSaveService,
        private notes: NotesService,
        private settingsService: SiteSettingsService,
        private signatureService: SignatureService
      ) {}


      ngOnInit() {
        this.params$ = this.route.params.subscribe((params: Params) => {
          this.globalSignOffEnabled = true;
          this.showGlobalSignOff = false;
          this.notesStates = this.notesSaveService.formStates;

          if (params['notesIdx']) {
            this.notesIdx = +params['notesIdx'];
            this.formName = params['form'];
            this.getForms();
     //       this.signatureService.notesIdxChange$.next(this.notesIdx) //NGRX!!!!
          }
        });

        this.settings$ = this.settingsService.getNotesSettings().subscribe(res=>{
          this.signOffEnabled = res.enableSignOff;
        });

        this.listenForSaves$ = this.notesSaveService.formSectionSaves$.subscribe(res=>{
          console.log("[broadcast] got ", res)
          const found = this.form.questions.find(q=>(q.fieldKey==res.formName));
          console.log("[broadcast] got ", res, found, this.form.questions);
          if (found) {
            found.recordIdx = res.recordIdx;
          }
        });

      }

      ngOnDestroy(): void {
          this.settings$.unsubscribe();
          this.params$.unsubscribe();
        //  this.signatureService.notesIdxChange$.next(0) //NGRX!
          this.signatureService.stopInterval();
          this.listenForSaves$.unsubscribe();
      }


      private getForms() {
        this.qs.getQuestions(this.formName).subscribe(form => {
          console.log("[form] =>", form);

          if (!form) {
            return;
          }

          form.questions = form.questions.map(f => {
            f.showBody = true;
            f.showSignOff = false;
            if (!f.signature){
              f.signature = {locked: false};
            }
            return f})
          this.form = form

          for(let section of form.questions){
            if (section.meta && section.meta.requirePrevious){ //we have individual sign off mode as atlwast one section needs it
                console.log("[gso] disabled as dependancy issue by", section)
                this.globalSignOffEnabled = false;
            }

            if (section.meta && section.meta.noSignOff){ //we have individual sign off mode as atlwast one section needs it
              console.log("[gso] disabled as no sign off by", section)
                this.globalSignOffEnabled = false;
            }else if (!section.meta.noSignOff){
              this.nSignOffSections ++;
            }

            this.updateData(section);
          }
        })

      }

      public toggleBody(section: any): void {
        section.showBody = !section.showBody
      }

      expandAll(){
        for(let form of this.form.questions){
          form.showBody = true;
        }
      }

      collapseAll(){
        for(let form of this.form.questions){
          form.showBody = false;
        }
      }

      scrollTop(){
        console.log("TOPTOPTOP");
        document.body.scrollTop = 0;
        document.documentElement.scrollTop = 0;
      }

      scroll(dest: string) {
        console.log("[scroll] ", dest)
        //    this.scroller.scrollToAnchor(dest);
        const ele = document.getElementById(dest);
        ele.scrollIntoView({ behavior: "smooth" });
      }

  save() {
    this.saving = true;

      this.notesSaveService.awaitAllSaved().subscribe(res => {
      if (res == 0) {
        this.saving = false;
        // after save change notes tab
        if (this.formName === NotesTypes.CASE_HISTORY) {
          this.notesSaveService.currentNotesTab.next(NotesTypes.PHYSICAL_EXAM)
        } else if (this.formName === NotesTypes.PHYSICAL_EXAM) {
          this.notesSaveService.currentNotesTab.next(NotesTypes.SOAP) 
        }
      } 
    });
  }

      onLocked(locked, section){
        console.log("[locked] received to onLocked", section, locked);
        if (section){
          section.signature.locked = locked;
          section.signature.idx = locked;
          this.checkRequirePreviousLocks();
          return;
        }

        for (let sect of this.form.questions){
            if (sect.signature){
              sect.signature.locked = locked;
            }
        }


      }

      checkRequirePreviousLocks(){ //runs all sections and checks signature dependancy
        let lastLocked = true; //always unlock the first section

        for (let section of this.form.questions){
          if (section.signature){
            if (!section.signature.idx && lastLocked && !section.meta.noSignOff ){ //this section is not locked but the last one was. so open this but no more
              console.log("[locked]  AUTO locking all following at ", section);
              section.signature.locked = false;
              lastLocked = false;
              section.signature.forceLocked = false;
            }else if(section.meta && section.meta.requirePrevious){
              console.log("[locked]  AUTO locking  ", section);
              section.signature.locked = true;
              section.signature.forceLocked = true;
            }
          }
        }

        console.log("[locked] checkRequirePreviousLocks", this.form.questions);
      }

      updateData(section): void {
        // Get correct data if USW specific

        const fieldKey = section.fieldKey.includes('_usw') ? section.fieldKey.substring(0, section.fieldKey.length - 4) : section.fieldKey;
        this.notes.getData(fieldKey, this.notesIdx)
          .subscribe(res => {
            console.log("[notes] get data ", res);
            let obj = {}
            const patientIdx = res.patientIdx;
            if (res.notesIdx){
              this.notesIdx = res.notesIdx
            }

            let locked = false;
            if (res.signature && res.signature.locked){
              locked = true;
            }

            section.recordIdx = res?.data?.idx | 0;
            section.signature = res.signature;

            const exclude = ['idx', 'notesIdx', 'date', 'DateAdded', 'DateUpdated', 'patientIdx']; //BODGE! 🚨

            if (res.data){
              Object.keys(res.data).forEach(item => {
                if (!exclude.includes(item)) {
                  obj[item] = res.data[item]
                }
              })
              section.data = obj
            }else{
              section.data = {};
            }


//            this.notes.checkPatientIdMatches(patientIdx); //checked now in the service on load
            this.checkRequirePreviousLocks();
          })
      }
}
