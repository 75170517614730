import { Component } from "@angular/core";
import { DataService } from "app/services/data.service";
import { StorageService } from "app/services/storage.service";
import { AuthEffects } from "app/store/effects/auth";
import { Subscription } from "rxjs";
import { Observable } from "rxjs";
import * as login from "../models/auth";
import { AuthService } from "../services/auth.service";

@Component({
  selector: "login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.scss"],
  providers: [AuthService]
})
export class LoginComponent {
  username: string;
  password: string;
  companyName: string = window.localStorage.getItem("companyName");
  forgotUsername: string;
  loginError: boolean = false;
  iForgot: boolean = false;
  iForgotError: boolean = false;
  emailSent: boolean = false;
  twoFactorAuth$: Subscription;
  twoFactorNeededMessage: string;
  loginState: Observable<login.State>;
  logo?: string;
  companyNameStored: boolean = false;
  public keepAlive: boolean = false;
  public allowCompanyNameChange = false;
  public stage: number = 0;
  public code: string[] = ["", "", "", "", "", ""];
  public resendTimeOut: number = 30;
  private resendTimeOut$: any;

  constructor(
    private auth: AuthService,
    private storage: StorageService,
    private api: DataService,
    private authEffects: AuthEffects
  ) {
    this.startListeningFor2FA();
  }

  startListeningFor2FA(): void {
    this.twoFactorAuth$ = this.authEffects.get2FANeeded().subscribe(( twoFactorNeededMessage: string ) => {
      this.twoFactorNeededMessage = twoFactorNeededMessage;
      
      if ( this.twoFactorNeededMessage !== "" ) {
        this.stage = 1;
        this.resendTimeOut = 30;
        
        if ( this.resendTimeOut$ ) {
          clearInterval(this.resendTimeOut$);
        }

        this.resendTimeOut$ = setInterval(() => {
          this.resendTimeOut--;
          if ( this.resendTimeOut <= 0 ) {
            clearInterval(this.resendTimeOut$);
          }
        }, 1000);
      }
    })
  }

  login() {
    this.companyNameSelected();
    this.auth.authenticate(this.companyName, this.username, this.password, this.keepAlive);
  }

  loginWithCode(): void {
    this.auth.authenticateCode(this.companyName, this.username, this.password, this.code.join(""));
    this.clearCodeField();
  }

  clearCodeField(): void {
    this.code = this.code.map((input: string) => "");

    this.code.forEach((input: string, index: number) => {
      (document.getElementById(`code${index}`) as HTMLInputElement).value = "";
    })
  }

  changeClinic() {
    console.log("[clear company name]")
    this.companyName = '';
    this.storage.setItem("companyName", "");
    this.companyNameStored = false;
  }

  companyNameSelected() {
    this.storage.setItem("companyName", this.companyName);
    this.companyNameStored = true;
  }

  executeSSO() {
    this.auth.authenticateWithSSO();
  }

  ngOnInit() {
    if (this.companyName) this.companyNameStored = true;
    this.api.get('/config').subscribe((res: any) => {
      if (res.settings && res.settings.site) {
        this.auth.storeConfig(res)
        this.logo = res.settings.logo
        this.companyName = res.settings.site
        this.companyNameSelected()
      } else {
        this.allowCompanyNameChange = true;
      }
    })
  }

  public codeProvided(): boolean {
    return this.code.every((input: string) => input !== "");
  }

  public validInput(input: string): boolean {
    return (( +input >= 0 ) && ( +input <= 9 ));
  }

  public validateKey(event: any, input: number): void {
    const key = event.key;

    if ( !key ) return;

    const currentElement = document.getElementById(`code${input}`) as HTMLInputElement;
    const previousElement = input > 0 ? document.getElementById(`code${input - 1}`) as HTMLInputElement : null;
    const nextElement = input < 5 ? document.getElementById(`code${input + 1}`) as HTMLInputElement : null;

    if ( ( key === "Backspace" || key === "Delete" ) ) {
      if ( this.code[input] !== "" ) {
        currentElement.value = "";
        this.code[input] = "";
      } else if ( previousElement ) {
        previousElement.focus();
      }
    }

    if ( key === "Enter" ) {
      if ( this.codeProvided() ) {
        this.loginWithCode();
      }
    }

    if ( key === "ArrowLeft" ) {
      if ( previousElement ) previousElement.focus();
    }

    if ( key === "ArrowRight" ) {
      if ( nextElement ) nextElement.focus();
    }
  }

  public validateInput(event: any, input: number): void {
    const key = event.data;

    if ( !key ) return;

    const currentElement = document.getElementById(`code${input}`) as HTMLInputElement;
    const nextElement = input < 5 ? document.getElementById(`code${input + 1}`) as HTMLInputElement : null;

    if ( this.validInput(key) ) {
      this.code[input] = key;
      currentElement.value = "";
      currentElement.value = key;
      if (nextElement) nextElement.focus();
    }
  }

  ngOnDestroy() {
    if ( this.twoFactorAuth$ ) this.twoFactorAuth$.unsubscribe();
  }
}
