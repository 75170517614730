import { State, initialState, ReportFilter } from '../../models/site-settings';
import * as settingsAction from '../actions/site-settings';
import { ActionWithPayload } from './index';

export { State, initialState };

const MENU_DECAY = 0.9;

export function reducer(state = initialState, action: ActionWithPayload): State {
    switch (action.type) {
        case settingsAction.ActionTypes.SET:
            return Object.assign({}, state, action.payload);
        default:
            return state;
  }
}

export const getSiteSettings = (state: State) => state.site;
export const getNotesSettings = (state: State) => state.notes;
