<div class="controls">
    <button mat-raised-button color="primary" (click)="showGiftCardForm(true)">Add New</button>
    <button mat-raised-button color="primary">Export as CSV</button>
    <button mat-raised-button color="primary">Print</button>
</div>

<div class="newCardForm" *ngIf="shouldShowGiftCardForm">
    <form autocomplete="off">
        <h2>New Gift Card</h2>
        <label>To</label>
        <input>
        <label>From</label>
        <input>
        <label>Valid Until</label>
        <input>
        <label>Valid At</label>
        <input>
        <div>
            <button mat-raised-button color="primary">Create</button>
            <button mat-button (click)="showGiftCardForm(false)">Done</button>
        </div>
    </form>
</div>

<table mat-table [dataSource]="giftcards" class="mat-elevation-z8">
    <ng-container matColumnDef="transactionID">
        <th mat-header-cell *matHeaderCellDef>Transaction ID</th>
        <td>TEST</td>
        <td mat-cell *matCellDef="let element">{{ element.transactionID }}</td>
    </ng-container>
    <ng-container matColumnDef="voucherID">
        <th mat-header-cell *matHeaderCellDef>Voucher ID</th>
        <td mat-cell *matCellDef="let element">{{ element.voucherID }}</td>
    </ng-container>
    <ng-container matColumnDef="purchasedBy">
        <th mat-header-cell *matHeaderCellDef>Purchased By</th>
        <td mat-cell *matCellDef="let element">{{ element.purchasedBy }}</td>
    </ng-container>
    <ng-container matColumnDef="value">
        <th mat-header-cell *matHeaderCellDef>Value</th>
        <td mat-cell *matCellDef="let element">{{ element.value }}</td>
    </ng-container>
    <ng-container matColumnDef="issuedOn">
        <th mat-header-cell *matHeaderCellDef>Issued On</th>
        <td mat-cell *matCellDef="let element">{{ element.issuedOn }}</td>
    </ng-container>
    <ng-container matColumnDef="usedOn">
        <th mat-header-cell *matHeaderCellDef>Used On</th>
        <td mat-cell *matCellDef="let element">{{ element.usedOn }}</td>
    </ng-container>
    <ng-container matColumnDef="code">
        <th mat-header-cell *matHeaderCellDef>Voucher Code</th>
        <td mat-cell *matCellDef="let element">{{ element.code }}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns" class="data-row"></tr>
</table>