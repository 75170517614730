<form autocomplete="off" #formEle="ngForm">


    <h5>Please check the box that best describes your habits.</h5>

    <div *ngFor="let question of ReapsQuestions" class="row mt-4">
        <mat-label class="col-md-7">{{question.label}}</mat-label>
        <div class="col-md-5">
            <mat-button-toggle-group [(ngModel)]="form[question.field]" [name]="question.field">
                <mat-button-toggle value="1">Usually/Often</mat-button-toggle>
                <mat-button-toggle value="2">Sometimes</mat-button-toggle>
                <mat-button-toggle value="3">Rarely/never</mat-button-toggle>
            </mat-button-toggle-group>
        </div>
    </div>

    <div class="row mt-4">
        <mat-label>14. You or a member of your family usually shops and cooks rather than eating sit-down or
            take-away
            restaurant food?</mat-label>
        <mat-form-field [style.width.%]="100">
            <mat-label>Select answer</mat-label>
            <mat-select [(ngModel)]="form.shop_and_cook" name="shop_and_cook">
                <mat-option [value]="'yes'">Yes</mat-option>
                <mat-option [value]="'no'">No</mat-option>
            </mat-select>
        </mat-form-field>
    </div>


    <mat-label class="">15. Usually feel well enough to shop or cook.</mat-label>
    <mat-form-field [style.width.%]="100">
        <mat-label>Select answer</mat-label>
        <mat-select [(ngModel)]="form.well_enough_shop_and_cook" name="well_enough_shop_and_cook">
            <mat-option [value]="'yes'">Yes</mat-option>
            <mat-option [value]="'no'">No</mat-option>
        </mat-select>
    </mat-form-field>

    <p>16. How willing are you to make changes in your eating habits in order to be healthier?</p>
    <div>
        <div class="d-flex">
            <div>
                <label for="willing_to_be_healthier">Not at all willing</label>
            </div>
            <div class="ms-auto">
                <label for="willing_to_be_healthier">Very willing</label>
            </div>
        </div>
        <div>
            <input type="range" min="0" max="5" step="1" name="willing_to_be_healthier"
                [(ngModel)]="form.willing_to_be_healthier" id="willing_to_be_healthier" style="width: '90%';" /><label
                style="float: right;">{{form.willing_to_be_healthier}}</label>
        </div>
    </div>
</form>