<div class="main-container" style="height: 100%">
    <header>
        <button (click)="goBack()">Back</button>
        <h1>Accounts</h1>
        <button [style.visibility]="'hidden'"></button>
    </header>
    <div class="columnContainer" *ngIf="routes.length > 0">
        <div class="column" *ngFor="let column of routes">
            <h2>{{column.header}}</h2>
            <div class="tile" *ngFor="let route of column.routes" (click)="goTo(route.route)">
                {{route.name}}
            </div>
        </div>
    </div>
</div>