<div class="container mt-5">
    <mat-card>
        <mat-card-content>
            <h3>Credits for {{patientName}}</h3>
            <mat-list>
                <mat-list-item *ngFor="let credit of creditsDetails">{{credit.description}}: <strong>{{credit.count}}</strong>
                </mat-list-item>
            </mat-list>
        </mat-card-content>
    </mat-card>
</div>