import { Injectable } from "@angular/core";
import * as fromRoot from "../store/reducers";
import * as fromSettings from "../store/actions/site-settings";

import { Store } from "@ngrx/store";
import { map, take, skipWhile } from "rxjs/operators";
import { Observable } from "rxjs";
import { DataService } from "../services/data.service";
import { StaffMember } from "../models/staff";

@Injectable({
    providedIn: "root"
})
export class StaffService {

    constructor(private store: Store<fromRoot.State>, private http: DataService) {
       
    }



    getStaff(): Observable<any> { //ALL staff, single observable
        return this.store.select(fromRoot.getStaff).pipe(
            skipWhile(val => val == null || val.length == 0),
            take(1));
    }

    getStaffAlphabetical(): Observable<any>{
        return this.getStaff().pipe((map(res => {
            const toSort = [...res];

            const sorted =  toSort.sort((a, b) => {
                const ret =  a.lastname.localeCompare(b.lastname);
                if (ret === 0) {
                    return a.firstname.localeCompare(b.firstname);
                }
                return ret;
            });

            return sorted;

        })));
    }


    getTreatFromCat(staffId: number) {
        return this.getStaff().pipe(
            map((res: StaffMember[]) => {
                console.log(res);
                console.log(staffId);

                const staffMember = res.find(t => t.idx == staffId);
                console.log(staffMember);
                return staffMember.treatFromCat;
            }));
    }
   
}
