import { Pipe, PipeTransform } from '@angular/core';
/** 
 * truncates strings longer than length
 */

@Pipe({
  name: 'maxLength'
})
export class MaxStringLengthPipe implements PipeTransform {

  constructor() {}

  transform( html: string, length: number ): string {
    if (html.length<=length){ // string ok send it back
        return html;
    }
    return html.substring(0, length - 3) + "...";
  }
}

