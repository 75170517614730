<div [formGroup]="form" class="form-group">
  <mat-form-field style="width:100%">
    <mat-label>{{question.label}}</mat-label>
    <mat-select #inputValue [id]="question.key" [formControlName]="question.key" [(value)]="question.value"
      [multiple]="this.question.multichoice == 1">

     

      <mat-option disabled value=""></mat-option>
      <mat-option *ngFor="let opt of question.options" [value]="''+opt.idx">{{opt.text}}</mat-option>
    </mat-select>
    <mat-error *ngIf="fieldInvalid() && showInvalidField">{{fieldInvalidError()}}</mat-error>
  </mat-form-field>

</div>