export interface EmailMessage {
    body?: string, 
    subject?: string,
    attachment?: string;
}

export const EmailTemplates = {
    PO: "po",
    INVOICE: "invoice",
    LETTERS: "letters",
    INSURANCE_INVOICE: "invoice-insurance"
}

