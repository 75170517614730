import { Component, OnInit, ViewChild } from '@angular/core';
import { MatListOption, MatSelectionList } from '@angular/material/list';
import { QuestionService } from 'app/dynamic-form/services/question.service';
import { Setting } from 'app/models/site-settings';
import { LoadingService } from 'app/services/loading.service';
import { NavigationService } from 'app/services/navigation.service';
import { SiteSettingsService } from 'app/services/site-settings.service';

@Component({
    selector: 'app-online-booking-settings',
    templateUrl: './online-booking-settings.component.html',
    styleUrls: ['./online-booking-settings.component.scss']
  })
  export class OnlineBookingSettingsComponent implements OnInit {

    questionnaireSubforms: any;
    selectedSubforms: any;
    saving: boolean;
    @ViewChild('subformsList') subformsList: MatSelectionList;

    constructor(
      private qs: QuestionService,
      private loading: LoadingService,
      private settings: SiteSettingsService,
      private navigation: NavigationService
    ) { }

    ngOnInit(): void {

    }
    updateList(e){
      this.selectedSubforms = e;
    }

    save() {
      this.settings.set(Setting.ONLINE_FORMS, this.selectedSubforms).subscribe(res=>{
      });
    }

    cancel() {
      this.navigation.back();
    }


  }
