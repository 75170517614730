import { State } from '../../models/auth';
import { type } from '../util';
import { createAction, props } from '@ngrx/store';

export const ADMIN = 'AUTH_ADMIN'
export const INIT = 'AUTH_INIT';
export const LOGIN = 'AUTH_LOGIN';
export const LOGINSSO = 'AUTH_LOGIN_SSO';
export const LOGIN_WITH_CODE = 'LOGIN_WITH_CODE';
export const LOGOUT_CLEAR = 'AUTH_LOGOUT_CLEAR';
export const LOGOUT = 'AUTH_LOGOUT';
export const UPDATE = 'AUTH_UPDATE';

export const ActionTypes = {
  ADMIN: type('[LoginState] Admin'),
  INIT: type('[LoginState] Init'),
  LOGIN: type('[LoginState] Login'),
  LOGINSSO: type('[LoginState] Login SSO'),
  LOGIN_WITH_CODE: type('[LoginState] Login With Code'),
  LOGOUT: type('[LoginState] Logout'),
  LOGOUT_CLEAR: type('[LoginState] Logout Clear State'),
  UPDATE: type('[LoginState] Load'),
  REFRESH: type('[LoginState] Refresh')
};

export const LoginAction = createAction(ActionTypes.LOGIN, props<{payload: { companyName: string, username: string, password: string, keepAlive: boolean }}>());
export const InitializeAction = createAction(ActionTypes.INIT);
export const LogInSSOAction = createAction(ActionTypes.LOGINSSO);
export const LogInWithCodeAction = createAction(ActionTypes.LOGIN_WITH_CODE, props<{payload: { companyName: string, username: string, password: string, tfa: string }}>());
export const LogoutAction  = createAction(ActionTypes.LOGOUT);
export const UpdateAction  = createAction(ActionTypes.UPDATE, props<{payload: State}>());
export const RefreshAction = createAction(ActionTypes.REFRESH);
export const LogoutCleanupAction = createAction(ActionTypes.LOGOUT_CLEAR);
