<form autocomplete="off" #formEle="ngForm">   
    <mat-form-field class="w-100">
        <mat-label>Student Clinician to document any specific tests or examination required from supervising clinician</mat-label>
        <textarea cdkTextareaAutosize
            cdkAutosizeMinRows="3"
            cdkAutosizeMaxRows="12"
            matInput 
            name="additional_tests"
            [(ngModel)]="form.additional_tests">
        </textarea>
    </mat-form-field>

   
</form>