import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PatientCreditsComponent } from './patient-credits/patient-credits.component';
import { TransferCreditsComponent } from './transfer-credits/transfer-credits.component';

const routes: Routes = [{
  path: '',
  children: [
    {
      path: 'transfer',
      component: TransferCreditsComponent
    },
    {
      path: 'list/:patientIdx',
      component: PatientCreditsComponent
    },
  ]
}];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class CreditsRoutingModule { }
