import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { DynamicFormSaveService } from 'app/dynamic-form/services/dynamic-form-save.service';
import { FilesService } from 'app/notes/uploader/files.service';
import { DialogService } from 'app/services/dialog/dialog.service';

@Component({
  selector: 'app-form-template-base',
  templateUrl: './form-template-base.component.html',
  styleUrls: ['./form-template-base.component.css']
})
export class FormTemplateBaseComponent implements OnInit {
  @Input() question: any;
  @Input() form: any;
  @Input() patientIdx: number;
  public showInvalidField: boolean;

  constructor(private fileService: FilesService,
      public dialogService: DialogService,
      public router: Router,
      public dynamicFormSaveService: DynamicFormSaveService) {
      this.dynamicFormSaveService.showInvalidField.subscribe(res => {
          this.showInvalidField = res
      })
  }

  ngOnInit() {
  }
  
  get isValid() {
    if (!this.form.controls[this.question.key]) return true;
    return this.form.controls[this.question.key].valid;
}

fieldInvalid() { 
    if(this.showInvalidField) return !this.form.controls[this.question.key].valid;
    // return true if invalid and not pristine
    if (!this.form.controls[this.question.key]) return false;
    return !this.form.controls[this.question.key].valid && !this.form.controls[this.question.key].pristine;
    //return !this.form.controls[this.question.key].valid;
}

fieldInvalidError() {
    if (!this.form.controls[this.question.key]) return "";
    if (this.form.controls[this.question.key].valid == false) {
        var error = this.form.controls[this.question.key].errors;
        //   console.log(error);
        if (error && error['error']) {
            return error['error'];
        } else {
            //   console.log(error);
            return "Field required";
        }
    }
    return "Field required";

}
}