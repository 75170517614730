import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-practitioner-list-item',
  templateUrl: './practitioner-list-item.component.html',
  styleUrls: ['./practitioner-list-item.component.css']
})
export class PractitionerListItemComponent implements OnInit {
@Input() staffMember: string;
  constructor() { }

  ngOnInit() {
  }

}
