import {SubMenuItem} from "./menu"

export interface ReportFilter {
    reportName: string,
    columnFilters: any,
    columnState: any,
    columnGroupState: any
}
export interface SortingFilter {
    colId: string;
    sort: string;
    sortIndex?: number;
}




export interface State {
    useOldIcons?: boolean;
    reportFilters?: ReportFilter[];
    mobileView?: boolean;
    darkMode?: boolean;
    loaded?: boolean;
}



export const initialState: State = {
    useOldIcons: false,
    reportFilters: [],
    mobileView: false,
    darkMode: false,
    loaded: false
}





export const Setting = {
    ONLINE_FORMS: "online-booking-forms"
}
