import {
  Component,
  Input,
  ViewContainerRef,
  ViewChild,
  OnInit,
  Output,
  EventEmitter,
  SimpleChanges
} from "@angular/core";

import { VitalSignsComponent }            from 'app/notes/form-components/vital-signs/vital-signs.component';
import { RangeOfMotionAreaComponent }     from 'app/notes/form-components/range-of-motion/range-of-motion.component';
import { MusclesAndNervesComponent }      from 'app/notes/form-components/muscles-and-nerves/muscles-and-nerves.component';
import { OtherTestsComponent }            from 'app/notes/form-components/other-tests/other-tests.component';
import { DrawingAbdomenComponent }        from 'app/notes/form-components/drawing-abdomen/drawing-abdomen.component';
import { DrawingThoracicComponent }       from 'app/notes/form-components/drawing-thoracic/drawing-thoracic.component';
import { DrawingLumbarComponent }         from 'app/notes/form-components/drawing-lumbar/drawing-lumbar.component';
import { ExtremitiesComponent }           from 'app/notes/form-components/extremities/extremities.component';
import { LumbopelvicComponent }           from 'app/notes/form-components/lumbopelvic/lumbopelvic.component';
import { DiagnosisComponent }             from 'app/notes/form-components/diagnosis/diagnosis.component';
import { CervicalComponent }              from 'app/notes/form-components/cervical/cervical.component';
import { ReflexesComponent }              from "app/notes/form-components/reflexes/reflexes.component";
import { CaseHistoryComponent }           from "app/notes/form-components/case-history/case-history.component";
import { PermissionToTreatComponent }     from "app/notes/form-components/permission-to-treat/permission-to-treat.component";
import { CaseSummaryQuestionsComponent }  from "app/notes/form-components/case-summary-questions/case-summary-questions.component";
import { ReportOfFindingsComponent }      from "app/notes/form-components/report-of-findings/report-of-findings.component";
import { PatientConsentComponent }        from "app/notes/form-components/patient-consent/patient-consent.component";

import { NotesBuilderBaseComponent } from "../notes-builder-base/notes-builder-base-component";
import { PePatientConsentComponent } from "app/notes/form-components/pe-patient-consent/pe-patient-consent.component";
import { SleepDetailsComponent } from "app/notes/form-components/sleep/sleep-details.component";
import { OsteoporosisComponent } from "app/notes/form-components/osteoporosis/osteoporosis.component";
import { PainDetailsComponent } from "app/notes/form-components/pain/pain-details.component";
import { FamilyHistoryComponent } from "app/notes/form-components/family-history/family-history.component";
import { HealthDetailsComponent } from "app/notes/form-components/health-details/health-details.component";
import { MedicalHistoryComponent } from "app/notes/form-components/medical-history/medical-history.component";
import { MedicationComponent } from "app/notes/form-components/medication/medication.component";
import { EmploymentDetailsComponent } from "app/notes/form-components/employment-details/employment-details.component";
import { FemalesDetailsComponent } from "app/notes/form-components/females/females-details.component";
import { TutorCommentsComponent } from "app/notes/form-components/tutor-comments/tutor-comments.component";
import { DrawingQuestionnaireComponent } from "app/notes/form-components/drawing-questionnaire/drawing-questionnaire.component";
import { USWOtherTestsComponent } from "app/notes/form-components/other-tests/usw-other-tests.component";
import { ExtremitiesV2Component } from "app/notes/form-components/extremities-v2/extremities-v2.component";
import { FastAlcoholScreeningTestComponent } from "app/notes/form-components/fast-alcohol-screening-test/fast-alcohol-screening-test.component";
import { ReapsComponent } from "app/notes/form-components/reaps/reaps.component";
import { PhysicalActivityComponent } from "app/notes/form-components/physical-activity/physical-activity.component";
import { SpinalRedFlagsComponent } from "app/notes/form-components/spinal-red-flags/spinal-red-flags.component";
import { DrawingExtremitiesComponent } from "app/notes/form-components/drawing-extremities/drawing-extremities.component";
import { DrawingCervicalComponent } from "app/notes/form-components/drawing-cervical/drawing-cervical.component";
import { AxialSpondyloarthritisRiskAssessmentComponent } from "app/notes/form-components/axial-spondyloarthritis-risk-assessment/axial-spondyloarthritis-risk-assessment.component";
import { MskhqComponent } from "app/notes/form-components/mskhq/mskhq.component";
import { StartBackComponent } from "app/notes/form-components/start-back/start-back.component";
import { USWHealthDetailsComponent } from "app/notes/form-components/health-details/usw-health-details.component";


@Component({
  selector: "notes-builder-form",
  templateUrl: "./notes-builder-form.component.html",
  styleUrls: ["./notes-builder-form.component.scss"],
})
export class NotesBuilderFormComponent implements OnInit {
  @Input() section: any;
  @Input() locked: boolean;
  @Input() notesIdx: number;
  @Output() recordIdx = new EventEmitter<any>();

  @ViewChild("container", { read: ViewContainerRef, static: true }) container;
  
  private component;
  private componentRef;
  large: boolean = false;
  saving: boolean;
  checking: boolean;
  //form: any;
 
// maps form names from API to components locally.
// try and keep this in alphabetical order.

  private readonly templateMapper = {
    notes_axial_spondyloarthritis: AxialSpondyloarthritisRiskAssessmentComponent,
    notes_casehistory: CaseHistoryComponent,
    notes_casesummary: CaseSummaryQuestionsComponent,
    notes_cervical: CervicalComponent,
    notes_diagnosis: DiagnosisComponent,
    notes_employmentdetails: EmploymentDetailsComponent,
    notes_extremities: ExtremitiesComponent,
    notes_extremities_v2: ExtremitiesV2Component,
    notes_fast: FastAlcoholScreeningTestComponent,
    notes_femalesdetails: FemalesDetailsComponent,
    notes_flashdrawing_abdomen: DrawingAbdomenComponent,
    notes_flashdrawing_cervical: DrawingCervicalComponent,
    notes_flashdrawing_extremities: DrawingExtremitiesComponent,
    notes_flashdrawing_lumbar: DrawingLumbarComponent,
    notes_flashdrawing_thoracic: DrawingThoracicComponent,
    notes_flashdrawing_questionnaire: DrawingQuestionnaireComponent,
    notes_healthdetails: HealthDetailsComponent,
    notes_healthdetails_usw: USWHealthDetailsComponent,
    notes_lumbopevic: LumbopelvicComponent,
    notes_miscmedicaldetails: MedicalHistoryComponent,
    notes_mskhq: MskhqComponent,
    notes_medicalhistorydetails: FamilyHistoryComponent,
    notes_medicationdetails: MedicationComponent,
    notes_musclenerve: MusclesAndNervesComponent,
    notes_osteoporosis: OsteoporosisComponent,
    notes_othertestsphysexam: OtherTestsComponent,
    notes_othertestsphysexam_usw: USWOtherTestsComponent,
    notes_paindetails: PainDetailsComponent,
    notes_patientconsent: PatientConsentComponent,
    notes_permissiontotreat: PermissionToTreatComponent,
    notes_pe_patientconsent: PePatientConsentComponent,
    notes_persondiagram: ReflexesComponent,
    notes_physical_activity: PhysicalActivityComponent,
    notes_rangeofmotion1: RangeOfMotionAreaComponent,
    notes_reaps: ReapsComponent,
    notes_reportoffindings: ReportOfFindingsComponent,
    notes_sleephabits: SleepDetailsComponent, 
    notes_spinal_red_flags: SpinalRedFlagsComponent,
    notes_start_back: StartBackComponent,
    notes_tutor_exam: TutorCommentsComponent,
    notes_vitalsigns: VitalSignsComponent
  };

  constructor() { }

  ngOnInit() {
    this.component = this.getComponentForCardType(this.section.fieldKey);
    
    console.log("[fieldkey] ", this.section.fieldKey);

    if (this.component) {
      this.createComponent();
    } else {
      console.error(`no component found for ${this.section.fieldKey}`);
    }
  }

  ngOnChanges(changes: SimpleChanges){
    console.log("[nbf)", changes);
    if (!this.componentRef) { return; } //not started yet
    if (changes.locked){
      (<NotesBuilderBaseComponent>this.componentRef.instance).locked = changes.locked.currentValue;  
      this.componentRef.instance.checkLocks();
    }
  }

  createComponent(locked = false) {
    this.componentRef = this.container.createComponent(this.component);
    (<NotesBuilderBaseComponent>this.componentRef.instance).notesIdx = this.notesIdx;
    (<NotesBuilderBaseComponent>this.componentRef.instance).formName = this.section.fieldKey;
    (<NotesBuilderBaseComponent>this.componentRef.instance).form = this.section.data;
    (<NotesBuilderBaseComponent>this.componentRef.instance).locked = this.locked;

  }

  private getComponentForCardType(cardType) {
    if (!this.templateMapper[cardType]){
      throw new Error(`Template not defined ${cardType}`);
    }
    return this.templateMapper[cardType];
  }


 

}
