import { PandLComponent } from "./p-and-l/p-and-l.component";
import { IncomeDrillDownComponent } from "./income-drill-down/income-drill-down.component";
import { InvoiceComponent } from "./invoice/invoice.component";
import { InsurerComponent } from "./insurance-invoice/insurer/insurer.component";
import { CustomerComponent } from "./insurance-invoice/customer/customer.component";
import { AuthNeededGuard } from "app/guards/auth.guard";
import { PrintPurchaseOrderComponent } from "./print-purchase-order/print-purchase-order.component";
import { ReceivePurchaseOrderComponent } from "./receive-purchase-order/receive-purchase-order.component";
import { PurchaseOrdersComponent } from "./purchase-orders/purchase-orders.component";
import { ReportSelectorComponent } from "./xzreports/report-selector/report-selector.component";
import { XzReportsComponent } from "./xzreports/xzreports.component";
import { CommissionMatrixComponent } from "./commission-matrix/commission-matrix.component";
export const FinanceRoutes = [
  {
    path: "finance",
    canActivate: [AuthNeededGuard],
    children: [
      {
        path: "pandl",
        component: PandLComponent,
        canActivate: [AuthNeededGuard]
      },
      {
        path: "cmatrix",
        component: CommissionMatrixComponent,
        canActivate: [AuthNeededGuard]
      },
      {
        path: "income/:type/:month/:year",
        component: IncomeDrillDownComponent,
        canActivate: [AuthNeededGuard]
      },
      {
        path: "invoice/:txnIdx",
        component: InvoiceComponent,
        canActivate: [AuthNeededGuard]
      },
      {
        path: "invoice/transaction-list/:patientIdx",
        component: InvoiceComponent,
        canActivate: [AuthNeededGuard]
      },
      {
        path: "invoice/insurer/:txnIdx",
        component: InsurerComponent,
        canActivate: [AuthNeededGuard]
      },
      {
        path: "invoice/customer/:txnIdx",
        component: CustomerComponent,
        canActivate: [AuthNeededGuard]
      },
      {
        path: "newPO",
        component: PurchaseOrdersComponent,
        canActivate: [AuthNeededGuard],
      },
      {
        path: "po/:poIdx",
        component: PurchaseOrdersComponent,
        canActivate: [AuthNeededGuard],
      },
      {
        path: "commissionPO/:reportName",
        component: PurchaseOrdersComponent,
        canActivate: [AuthNeededGuard],
      },
      {
        path: "po/print/:poIdx",
        component: PrintPurchaseOrderComponent,
        canActivate: [AuthNeededGuard]
      },
      {
        path: "po/receive/:poIdx",
        component: ReceivePurchaseOrderComponent,
        canActivate: [AuthNeededGuard]
      },
      {
        path: "xzreports",
        component: ReportSelectorComponent,
        canActivate: [AuthNeededGuard],
      },
      {
        path: "xzreports/:reportType/:userID/:zreportIdx",
        component: XzReportsComponent,
        canActivate: [AuthNeededGuard],
      },
      {
        path: "xzreports/:reportType/:zreportIdx",
        component: XzReportsComponent,
        canActivate: [AuthNeededGuard],
      }
    ],
  },
];
