<div [class]="height && width ? 'w-764' : 'w-378'">
    <div class="disabled" *ngIf="isDisabled">
        
    </div>
    <div class="w-100">
        <mat-toolbar color="primary">
            <div *ngIf="canvasID !== '8' && canvasID !== '9' && canvasID !== '10'">
                <mat-button-toggle-group name="favoriteColor" aria-label="Favorite Color" class="quick-color">
                    <mat-button-toggle *ngFor="let colour of presetColours" [value]="colour"
                        (click)="selectPresetColour(colour)" [style.background]="colour">
                        &nbsp;
                    </mat-button-toggle>
                    <input class="ms-1 color-picker" type="color" [(ngModel)]="selectedColour" name="selectedColor">
                </mat-button-toggle-group>
            </div>


            <span class="example-spacer"></span>

            <button mat-icon-button (click)="undo()" title="Undo">
                <mat-icon>undo</mat-icon>
            </button>
            <button mat-icon-button (click)="resetCanvas()" title="Clear">
                <mat-icon>delete_forever</mat-icon>
            </button>
        </mat-toolbar>
    </div>

    <div class="d-flex">
        <div class="w-128">
            <mat-toolbar [class]="height && width ? 'toolbar-vertical-tall' : 'toolbar-vertical'" color="primary">
                <div class="row">
                    <div class="col">
                        <button mat-flat-button class="tool-btn" [ngClass]="{'active' : selectedTool==ToolType.pencil }"
                        (click)="selectTool(ToolType.pencil)">
                        <mat-icon>edit</mat-icon>
                        </button>
                    </div>
                    <div class="col">
                        <button mat-flat-button class="line-btn" [matMenuTriggerFor]="lineWeightMenu" aria-label="Line Weight Menu">
                            <mat-icon>line_weight</mat-icon>
                            <mat-icon>expand_more</mat-icon>
                        </button>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <button mat-flat-button class="tool-btn" [ngClass]="{'active' : selectedTool==ToolType.circle }"
                    (click)="selectTool(ToolType.circle)">
                    <img src="/assets/images/circle.png">
                </button>
                    </div>
                    <div class="col">
                        <button mat-flat-button class="tool-btn" [ngClass]="{'active' : selectedTool==ToolType.cross }"
                        (click)="selectTool(ToolType.cross)">
                        <img src="/assets/images/cross.png">
                </button>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <button mat-flat-button class="tool-btn" class="tool-btn"
                        [ngClass]="{'active' : selectedTool==ToolType.circleCross }" (click)="selectTool(ToolType.circleCross)">
                        <img src="/assets/images/circlecross.png">
                </button>
                    </div>
                    <div class="col">
                        <button mat-flat-button class="tool-btn" class="tool-btn"
                    [ngClass]="{'active' : selectedTool==ToolType.equals }" (click)="selectTool(ToolType.equals)">
                    <img src="/assets/images/equals.png">
                </button>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <button mat-flat-button class="tool-btn" class="tool-btn"
                        [ngClass]="{'active' : selectedTool==ToolType.v }" (click)="selectTool(ToolType.v)">
                        <img src="/assets/images/v.png">
                        </button>
                    </div>
                    <div class="col">
                        <button mat-flat-button class="tool-btn" class="tool-btn"
                        [ngClass]="{'active' : selectedTool==ToolType.line }" (click)="selectTool(ToolType.line)">
                        <img src="/assets/images/line.png">
                    </button>
                    </div>
                </div>
                <div class="row">
                    <div class="col">
                        <button mat-flat-button class="tool-btn" class="tool-btn"
                        [ngClass]="{'active' : selectedTool==ToolType.hashtag }" (click)="selectTool(ToolType.hashtag)">
                        <img src="/assets/images/hashtag.png">
                    </button>
                    </div>
                </div>
                

                

                

                

                

                
               


                
                <mat-menu #lineWeightMenu="matMenu">
                    <button mat-menu-item (click)="brushWidth=1">
                        <div style="border-bottom: 1px solid #000000;"></div>
                    </button>
                    <button mat-menu-item (click)="brushWidth=4">
                        <div style="border-bottom: 2px solid #000000;"></div>
                    </button>
                    <button mat-menu-item (click)="brushWidth=8">
                        <div style="border-bottom: 8px solid #000000;"></div>
                    </button>
                </mat-menu>
            </mat-toolbar>
        </div>

        <div class="canvasContainer">
            <canvas #myCanvas></canvas>
        </div>
    </div>
</div>