import { Injectable } from "@angular/core";
import * as fromRoot from "../store/reducers";
import { Store } from "@ngrx/store";
import { distinctUntilChanged, map, switchMap, take } from "rxjs/operators";
import { Observable, of } from "rxjs";
import { Room } from "../models/rooms";
import { type Clinic } from "../models/clinics";
import * as clinicsActions from "../store/actions/clinics";
import { DataService } from "./data.service";

@Injectable({
    providedIn: "root"
})
export class ClinicService {

    constructor(private store: Store<fromRoot.State>, 
        private http: DataService) {
            console.log("[clinics] constructs");    
        this.init();
    }

    init(){
        console.log("[clinics] INIT");
        this.store.dispatch(new clinicsActions.InitAction());
    }

    getClinics(): Observable<Clinic[]> {
        return this.store.select(fromRoot.getClinics).pipe(distinctUntilChanged()); //.pipe(take(1));
    }

    public getAddress(idx: number): Observable<any> {
        return this.http.get<any>(`/clinic/${idx}`);
    }

    getSelectedClinic(): Observable<Clinic>{
        return this.store.select(fromRoot.getSelectedClinic)
            .pipe(distinctUntilChanged(),
              switchMap((res) => {
                    if (!res){ //no clinic panic and send the first one
                        return this.getClinics().pipe(map(all => {
                            console.log("ALL ", all);
                            this.setSelectedClinic(all[0]);
                            return all[0];
                        }));
                    }
                    return of(res);
            }));
    }

    setSelectedClinic(clinic: Clinic): void{
        this.store.dispatch(new clinicsActions.SelectedAction(clinic));
    }

    setSelectedClinicById(id: number){
        this.getClinics().pipe(take(1)).subscribe(clinics =>{
            console.log("[clinic]", id, clinics);
            for (let clinic of clinics){
                if (clinic.idx==id){
                    this.setSelectedClinic(clinic);
                    return;
                }
            }
        });
    }
}
