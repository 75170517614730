import { Component } from '@angular/core';
import { NotesBuilderBaseComponent } from 'app/notes/notes-builder/notes-builder-base/notes-builder-base-component';

@Component({
  selector: 'app-report-of-findings',
  templateUrl: './report-of-findings.component.html',
  styleUrls: ['./report-of-findings.component.scss']
})
export class ReportOfFindingsComponent  extends NotesBuilderBaseComponent {

}