import { Component} from '@angular/core';
import { NotesBuilderBaseComponent } from 'app/notes/notes-builder/notes-builder-base/notes-builder-base-component';
import { CaseSummaryQuestions } from '../models/case-summary';

@Component({
  selector: 'app-case-summary-questions',
  templateUrl: './case-summary-questions.component.html',
  styleUrls: ['./case-summary-questions.component.scss']
})
export class CaseSummaryQuestionsComponent extends NotesBuilderBaseComponent {
  public aideMemoireOpen: boolean;
  CaseSummaryQuestions = CaseSummaryQuestions;
}