<div *ngIf="info" class="container-fluid" >
  <div class="row margin-collapse">
    <div class="col-lg-10">
      <div class="row">
        <div class="col">
          <mat-card><mat-card-content>
            <h1>Personal Details 
            </h1>
                    
            <h2>Name</h2>
            <div class="w-100 mb-3">{{info.name || 'Not given'}}
              <div class="text-muted float-end">#{{patientId}}</div>
            </div>
    
            <h2>D.O.B</h2>
            <p>{{info.dob || 'Not given'}}</p>
    
            <h2>Occupation</h2>
            <p>{{info.occupation || 'Not given'}}</p>
    
            <h2>Practitioner</h2>
            <p>{{info.practitioner || 'Not set'}}</p>
    
            <h2>Address</h2>
            <p>{{fullAddress || 'Not given'}}</p>

            <h2>E-mail</h2>
            <p>{{ info.email || 'No e-mail given' }}</p>
          </mat-card-content></mat-card>
        </div>
        <div class="col">
          <mat-card><mat-card-content>
            <h1>Visits</h1>
    
            <h2>Number of visits</h2>
            <p>{{visits.nVisits || 'None'}}</p>
    
            <h2>Last Visit</h2>
            <p *ngIf="visits.lastVisit; else noLastVisit">{{visits.lastVisit | date: 'short'}}</p>
            <ng-template #noLastVisit><p>None</p></ng-template>
    
            <h2>First Visit</h2>
            <p *ngIf="visits.firstVisit else noFirstVisit">{{visits.firstVisit | date: 'short'}}</p>
            <ng-template #noFirstVisit><p>None</p></ng-template>

            <h2>Next Visit</h2>
            <p>{{visits.nextVisit | date: 'short' || 'None'}}</p>
          </mat-card-content></mat-card>
        </div>
      </div>
    </div>
    <div class="col-sm-2 mobile-margin">
      <div class="fix-top">
        <div class="desktop-buttons">
          <button mat-raised-button color="primary" class="submit-cancel" (click)="editPatientDetails()">Edit Patient Details</button>
        </div>
      </div>
    </div>
  </div>
</div>
