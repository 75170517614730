<form autocomplete="off" #formEle="ngForm">
    <mat-form-field class="w-100">
        <mat-label>Spinal Reflex notes</mat-label>
        <textarea matInput cdkTextareaAutosize cdkAutosizeMinRows="3" cdkAutosizeMaxRows="12" name="spinal_reflex"
            [(ngModel)]="form.spinal_reflex"></textarea>
    </mat-form-field>

    <button mat-flat-button color="secondary" (click)="toggleAide()">
        <div class=" d-flex flex-row">
            <div *ngIf="!showAide">Show Aide Memoire</div>
            <div *ngIf="showAide">Close Aide Memoire</div>
            <mat-icon class="mt-1 ms-2">live_help</mat-icon>
        </div>
    </button>

    <div *ngIf="showAide" class="alert alert-info aide-memoire">
        <ul style="list-style-type:none;">
            <li><b>Spinal red flags</b></li>
            <li *ngFor="let flag of SpinalRedFlags">{{flag}}</li>
        </ul>
    </div>
</form>