//import { Action } from '@ngrx/store';
import { DiaryFocusOnType, SortingMode, StaffRoomsView, ViewTypes } from '../../models/diary';
import { type } from '../util';
import { createAction, props, Action, createSelector } from '@ngrx/store';
import { Appointment } from 'app/models/appointments';

export const ActionTypes = {
  SET_WEEK_DAY_VIEW: type('[DiaryState] Week or Day'),
  SET_ZOOM: type('[DiaryState]] Zoom'),
  SET_PRIVATE: type('[DiaryState] Private'),
//  SET_SELECTED_STAFF: type('[DiaryState] Select Staff'),
  SET_SELECTED_ROOMS: type('[DiaryState] Select Rooms'),
 // SET_SELECTED_STAFF_WEEK_VIEW: type('[DiaryState] Staff Week View'),
  SET_SELECTED_ROOM_WEEK_VIEW: type('[DiaryState] Room Week View'),
  SET_STAFF_OR_ROOM_VIEW: type('[DiaryState] Room or Staff View'),
  SET_DATE: type('[DiaryState] Set Start Date'),
  SET_SHOW_RIGHT_PANEL: type('[DiaryState] Show Right Panel'),
  SET_SHOW_WORKING: type('[DiaryState] Show Working'),
  SET_DRAGGING_ACTIVE: type('[DiaryState] Dragging Active'),
  SET_AVAILABILITY: type('[DiaryState] Set Availability'),
  SET_COLOURS: type('[DiaryState] Set Colours'),
  SET_START_HOUR: type('[DiaryState] Set Start Hour'),
  SET_END_HOUR: type('[DiaryState] Set End Hour'),
  SET_INCREMENT: type('[DiaryState] Set Increment'),
  SET_HOUR_SEGMENTS: type('[DiaryState] Set Hour Segments'),
  SET_STAFF_GROUP_BY: type('[DiaryState] Set Staff Group By'),
  SET_WORKING: type('[DiaryState] Set Working Hours'),
  SET_FOCUS_ON: type('[DiaryState] Set Focus On'),
  SET_SHOW_CANCELLED: type('DiaryState] Set Show Cancelled'),
  APPOINTMENT_SELECTED: type('DiaryState] Appointment Selected'),
  SET_APPOINTMENT_SELECTED: type('DiaryState] Set Appointment Selected'),
  RESET_WORKING: type('[DiaryState] Reset working hours'),
  SET_QUICK_SEGMENT_BREAKS: type('DiaryState] Set Quick Segment Breaks')
};


/**
 * Every action is comprised of at least a type and an optional
 * payload. Expressing actions as classes enables powerful 
 * type checking in reducer functions.
 * 
 * See Discriminated Unions: https://www.typescriptlang.org/docs/handbook/advanced-types.html#discriminated-unions
 */

/*
export class LoginAction implements Action {
  type = ActionTypes.LOGIN;
  constructor(public payload: string) { }
}
*/

export const SetWeekOrDayView  = createAction(ActionTypes.SET_WEEK_DAY_VIEW, props<{payload: ViewTypes}>());
export const SetZoom = createAction(ActionTypes.SET_ZOOM, props<{payload: number}>());
export const SetPrivate = createAction(ActionTypes.SET_PRIVATE, props<{payload: boolean}>());
// export const SetSelectedStaff = createAction(ActionTypes.SET_SELECTED_STAFF, props<{payload: any[]}>());
export const SetSelectedRooms = createAction(ActionTypes.SET_SELECTED_ROOMS, props<{payload: any[]}>());
// export const SetSelectedStaffWeekView = createAction(ActionTypes.SET_SELECTED_STAFF_WEEK_VIEW, props<{payload: any[]}>());
export const SetSelectedRoomWeekView = createAction(ActionTypes.SET_SELECTED_ROOM_WEEK_VIEW, props<{payload: any[]}>());
export const SetStaffOrRoomView = createAction(ActionTypes.SET_STAFF_OR_ROOM_VIEW, props<{payload: StaffRoomsView}>());
export const SetDate = createAction(ActionTypes.SET_DATE, props<{payload: number}>());
export const SetShowRightPanel = createAction(ActionTypes.SET_SHOW_RIGHT_PANEL, props<{payload: boolean}>());
export const SetShowWorking = createAction(ActionTypes.SET_SHOW_WORKING, props<{payload: boolean}>());
export const SetDraggingActive = createAction(ActionTypes.SET_DRAGGING_ACTIVE, props<{payload: boolean}>());
export const SetAvailablity = createAction(ActionTypes.SET_AVAILABILITY, props<{payload: any}>());
export const SetColours = createAction(ActionTypes.SET_COLOURS, props<{payload: string[]}>());
export const SetStartHour = createAction(ActionTypes.SET_START_HOUR, props<{payload: number}>());
export const SetEndHour = createAction(ActionTypes.SET_END_HOUR, props<{payload: number}>());
export const SetIncrement = createAction(ActionTypes.SET_INCREMENT, props<{payload: number}>());
export const SetHourSegments = createAction(ActionTypes.SET_HOUR_SEGMENTS, props<{payload: number}>());
export const SetStaffGroupBy = createAction(ActionTypes.SET_STAFF_GROUP_BY, props<{payload: SortingMode}>());
export const SetWorkingHours = createAction(ActionTypes.SET_WORKING, props<{payload: {startHour: number, endHour: number}}>());
export const ResetWorkingHours = createAction(ActionTypes.RESET_WORKING);
export const SetFocusOn = createAction(ActionTypes.SET_FOCUS_ON, props<{payload: DiaryFocusOnType}>());
export const SetShowCancelled = createAction(ActionTypes.SET_SHOW_CANCELLED, props<{payload: boolean}>());
export const AppointmentSelected = createAction(ActionTypes.APPOINTMENT_SELECTED, props<{payload: Appointment}>());
export const SetAppointmentSelected = createAction(ActionTypes.SET_APPOINTMENT_SELECTED, props<{payload: Appointment}>());
export const SetQuickSegmentBreaks = createAction(ActionTypes.SET_QUICK_SEGMENT_BREAKS, props<{payload: { morningEnd: string, afternoonStart: string, midSplitStart: string, midSplitEnd: string}}>());

/*

   

  updateSelectedStaffWeekView(staff) {
    this.calendarDirty = true;
    this.store.dispatch(new fromSettings.SetDiaryAction({ selectedStaffWeekView: staff }));
  }

  updateSelectedRoomWeekView(room) {
    this.calendarDirty = true;
    this.store.dispatch(new fromSettings.SetDiaryAction({ selectedRoomWeekView: room }));
  }

  updateStaffOrRoomsView(view: string) {
    this.calendarDirty = true;
    this.store.dispatch(new fromSettings.SetDiaryAction({ staffOrRooms: view }));


*/