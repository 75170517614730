import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Inject,
  Injectable,
  Input,
  LOCALE_ID,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  CalendarUtils,
  CalendarWeekViewComponent,
  DateAdapter,
  getWeekViewPeriod,
} from 'angular-calendar';
import {
  WeekView,
  GetWeekViewArgs,
  CalendarEvent,
  WeekViewAllDayEvent,
} from 'calendar-utils';
import { DragEndEvent, DragMoveEvent } from 'angular-draggable-droppable';
import { DiaryService } from 'app/services/diary/diary.service';
import { AppointmentStatus, DiaryColumn } from 'app/models/diary';


interface DayViewScheduler extends WeekView {
  users: DiaryColumn[];
}

interface GetWeekViewArgsWithUsers extends GetWeekViewArgs {
  users: DiaryColumn[];
}


declare global {
}


@Injectable()
export class DayViewSchedulerCalendarUtils extends CalendarUtils {
  getWeekView(args: GetWeekViewArgsWithUsers): DayViewScheduler {
    const { period } = super.getWeekView(args);
    const view: DayViewScheduler = {
      period,
      allDayEventRows: [],
      hourColumns: [],
      users: [...args.users]
    };

    if (!args || !args.events) {
      return;
    }



    view.users.forEach((user, columnIndex) => {
      const userEvents = args.events.filter(
        (event) => event?.meta?.user?.id === user.id
      );

      const events = userEvents.map((event) => {
        if ( event?.meta?.status === AppointmentStatus.PENDING_APPROVAL ) {
          return {
            ...event,
            cssClass: "flashing"
          }
        }
        if ( event?.meta?.status === AppointmentStatus.CANCELLED ) {
          return {
            ...event,
            cssClass: "cancelled"
          }
        }


        return event;
      })

      const columnView = super.getWeekView({
        ...args,
        events,
      });
      view.hourColumns.push(columnView.hourColumns[0]);
      columnView.allDayEventRows.forEach(({ row }, rowIndex) => {
        view.allDayEventRows[rowIndex] = view.allDayEventRows[rowIndex] || {
          row: [],
        };
        view.allDayEventRows[rowIndex].row.push({
          ...row[0],
          offset: columnIndex,
          span: 1,
        });
      });
    });
    console.log("[events] [view]", view);
    return view;
  }
}

@Component({
  // tslint:disable-line max-classes-per-file
  selector: 'app-mwl-day-view-scheduler',
  templateUrl: 'day-view-scheduler.component.html',
  styleUrls: [ 'day-view-scheduler.component.scss' ],
  providers: [DayViewSchedulerCalendarUtils],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DayViewSchedulerComponent extends CalendarWeekViewComponent
  implements OnDestroy, OnInit {
  
  @Input()  users: any[] = [];
  @Output() userChanged = new EventEmitter();
  @Output() rightClickEvent = new EventEmitter<any>();
  
  declare view: DayViewScheduler;
  daysInWeek = 1;
  public AppointmentStatus = AppointmentStatus;

  declare hourSegmentClicked: EventEmitter<{
    date: Date;
    sourceEvent: MouseEvent;
    columnIndex: number;
  }>;


  constructor(
    protected cdr: ChangeDetectorRef,
    protected utils: DayViewSchedulerCalendarUtils,
    @Inject(LOCALE_ID) locale: string,
    protected dateAdapter: DateAdapter,
    protected element: ElementRef<HTMLElement>,
    public diary: DiaryService,
    private ref: ChangeDetectorRef
  ) {
    super(cdr, utils, locale, dateAdapter, element);
    //this.ref.detach();
   // setInterval(() => {
   //   this.ref.detectChanges();
   // }, 5000);
  }

  ngOnInit(){ }

  trackByUserId = (index: number, row: any) => row.id;
  
  
  ngOnChanges(changes: SimpleChanges): void {
    console.log("[events] [ngOnChanges] [bwvr]", changes);
    super.ngOnChanges(changes);
    
    
    if (changes.users) { //forces an update when staff change. interesting we had to bring this back maybe means some other underlying performance issue
      this.refreshBody();
      this.emitBeforeViewRender();
    }

   // this.ref.detectChanges();
    // console.profile("draw diary");
   // debugger;
  }

  ngOnDestroy(){
  }

  getDayColumnWidth(eventRowContainer: HTMLElement): number {
    return Math.floor(eventRowContainer.offsetWidth / this.users.length);
  }

  dragMove(dayEvent: any, dragEvent: DragMoveEvent) {
  
    if (this.snapDraggedEvents) {
      const newUser = this.getDraggedUserColumn(dayEvent, dragEvent.x);
      console.log("[drag] in day view", this.hourSegments);
      const newEventTimes = this.getDragMovedEventTimes(
        dayEvent,
        { ...dragEvent, x: 0 },
        this.dayColumnWidth,
        true
      );
     // console.log("[drag] in day view", newUser, newEventTimes);
      if (dayEvent.event.meta.user.id!=newUser.id){
        console.log("[drag] STAFF CHANGED");
     //   return dayEvent.event;
      }

      const originalEvent = dayEvent.event;
      const adjustedEvent = {
        ...originalEvent,
        ...newEventTimes,
        meta: { ...originalEvent.meta }
      };
      const tempEvents = this.events.map((event) => {
        if (event === originalEvent) {
          return adjustedEvent;
        }
        return event;
      });
      this.restoreOriginalEvents(
        tempEvents,
        new Map([[adjustedEvent, originalEvent]])
      );
    }
    this.dragAlreadyMoved = true;
  }

  dragEnded(
    weekEvent: WeekViewAllDayEvent,
    dragEndEvent: DragEndEvent,
    dayWidth: number,
    useY = false
  ) {
    super.dragEnded(
      weekEvent,
      {
        ...dragEndEvent,
        x: 0,
      },
      dayWidth,
      useY
    );
    const newUser = this.getDraggedUserColumn(weekEvent, dragEndEvent.x);
    if (newUser && newUser.id !== weekEvent.event.meta.user.id) {
      this.userChanged.emit({ event: weekEvent.event, newUser });
    }
  }

  protected getWeekView(events: CalendarEvent[]) {
    const wv = this.utils.getWeekView({
      events,
      users: this.users,
      viewDate: this.viewDate,
      weekStartsOn: this.weekStartsOn,
      excluded: this.excludeDays,
      precision: this.precision,
      absolutePositionedEvents: true,
      hourSegments: this.hourSegments,
      dayStart: {
        hour: this.dayStartHour,
        minute: this.dayStartMinute,
      },
      dayEnd: {
        hour: this.dayEndHour,
        minute: this.dayEndMinute,
      },
      segmentHeight: this.hourSegmentHeight,
      weekendDays: this.weekendDays,
      ...getWeekViewPeriod(
        this.dateAdapter,
        this.viewDate,
        this.weekStartsOn,
        this.excludeDays,
        this.daysInWeek
      ),
    });
    console.log("[events] getWeekView", wv);
    return wv;
  }

  private getDraggedUserColumn(
    dayEvent: WeekViewAllDayEvent,
    xPixels: number
  ) {
    const columnsMoved = Math.round(xPixels / this.dayColumnWidth);
    const currentColumnIndex = this.view.users.findIndex(
      (user) => user.id === dayEvent.event.meta.user.id
    );
    const newIndex = currentColumnIndex + columnsMoved;
    return this.view.users[newIndex];
  }

  contextmenuOpen(event, timeEvent){
    console.log("[context]", event,timeEvent);
    this.rightClickEvent.emit({mouseEvent: event, timeEvent: timeEvent});
  }

}



