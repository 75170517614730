import { Component,  ElementRef,  Input, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ValueAccessorBase } from 'app/custom-form-controls/value-accessor-base';
import { ShareReplayConfig } from 'rxjs/internal/operators/shareReplay';
@Component({
  selector: 'app-notes-canvas',
  templateUrl: './notes-canvas.component.html',
  styleUrls: ['./notes-canvas.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: NotesCanvasComponent,
      multi: true,
    }]
})
export class NotesCanvasComponent extends ValueAccessorBase<any[]> {

  @Input() bgimage;
  @Input() width: number;
  @Input() height: number;
  @ViewChild('myCanvas', {static: true}) canvasRef: ElementRef
  private canvas: HTMLCanvasElement;
  private isCanvasSet: any;
  private canvasContext: CanvasRenderingContext2D;
  private backgroundImage: HTMLImageElement;

  private imageElement: HTMLImageElement;
  public canvasID = "mycanvas";

  public ToolType = {
    points: "points",
    pencil: "pencil",
    circle: "circle",
    circleCross: "circleCross",
    cross: "cross",
    v: 'v',
    equals: 'equals',
    line: 'line',
    hashtag: 'hashtag'
  }

  public selectedColour = "red";
  public selectedTool = this.ToolType.pencil;

  public brushWidth = 1;

  private isMouseDown = false;

  private initialCanvasHistory = [];
  private currentPoints: Array<{ x: number, y: number }> = [];
  private canvasCoords = {
    prevX: 0,
    prevY: 0,
    currX: 0,
    currY: 0
  };

  public presetColours = ['#000000', '#330000', '#ff0000', '#ffff00', '#00ff00', '#ff5555'];

  public readonly CANVAS_WIDTH = 250;
  public readonly CANVAS_HEIGHT = 250;

  public readonly CANVAS_WIDTH_RECT = 612;
  public readonly CANVAS_HEIGHT_RECT = 792;

  ngOnInit() {
    this.canvas = this.canvasRef.nativeElement;
    this.imageElement = new Image();
    this.isCanvasSet = setInterval((res) => {
      
      if (this.canvas) {
        this.setUpCanvas();
      }
    }, 500);

  }

  imageMapper(toolType) {
    switch (toolType) {
      case this.ToolType.circle:
        return "/assets/images/circle.png";
      case this.ToolType.circleCross:
        return "/assets/images/circlecross.png";
      case this.ToolType.cross:
        return "/assets/images/cross.png";
      case this.ToolType.equals:
        return "/assets/images/equals.png";
      case this.ToolType.hashtag:
        return "/assets/images/hashtag.png";
      case this.ToolType.line:
        return "/assets/images/line.png";
      case this.ToolType.v:
          return "/assets/images/v.png"
    }
  }

  updateNotes() {
       
        if (!this.value){
          return;
        }
       
        this.initialCanvasHistory = JSON.parse(JSON.stringify(this.value))
        if (this.value) {
          let images = []
          this.value.forEach(drawing => {
            if (drawing.type === this.ToolType.points) {
              this.drawImageFromDb(drawing)
            } else {
             images.push({ ...drawing, url: this.imageMapper(drawing.type)})
            }
          })
          if (images.length > 0) this.drawArrayOfImages(images)
      
  }
}

  setUpCanvas() {
    clearInterval(this.isCanvasSet);
    this.canvasContext = this.canvas.getContext('2d');

    this.canvas.width = this.width ? this.width : this.CANVAS_WIDTH;
    this.canvas.height = this.height ? this.height : this.CANVAS_HEIGHT;

    this.backgroundImage = new Image();
//    this.bgimage = this.canvasID === '8' ? "/assets/images/physicalExamLumboPelvic.png" : this.canvasID === '9' ? "/assets/images/physicalExamLumboPelvic1.png" : this.canvasID === '10' ? "/assets/images/abdomen.png" : "/assets/images/physicalExamNotesBkg1.png";
    this.backgroundImage.src = this.bgimage
    this.backgroundImage.onload = () => {
      this.clearCanvas(true);
    };

    this.canvas.addEventListener("mousemove", (e) => {
      this.handleMouseEvent('mousemove', e);
    }, false);
    this.canvas.addEventListener("mousedown", (e) => {
      this.handleMouseEvent('mousedown', e);
    }, false);
    this.canvas.addEventListener("mouseup", (e) => {
      this.handleMouseEvent('mouseup', e);
    }, false);
    this.canvas.addEventListener("mouseout", (e) => {
      this.handleMouseEvent('mouseout', e);
    }, false);

  }

  resetCanvas(){
    this.clearCanvas();
    this.value = []
  }

  clearCanvas(initial?: boolean) {
    this.canvasContext.clearRect(0, 0, this.width ? this.width : this.CANVAS_WIDTH, this.height ? this.height : this.CANVAS_HEIGHT);
    
    this.canvasContext.drawImage(this.backgroundImage, 0, 0);
    if (initial) this.updateNotes()
  }

  drawImageFromDb(data: any) {
    if (data.type === this.ToolType.points) {
      for (let i = 0; i < data.data.length; i++) {
        let x = data.data[i].x
        let y = data.data[i].y
        if (i === 0) {
          this.canvasContext.moveTo(x, y)
          this.canvasContext.beginPath()
          this.canvasContext.strokeStyle = data.colour
          this.canvasContext.lineWidth = data.width || this.brushWidth
        } else {
          this.canvasContext.lineTo(x, y)
        }
      }
      // this.canvasContext.closePath();
      this.canvasContext.stroke();
    }
  }

  handleMouseEvent(eventType: string, mouseEventObj: MouseEvent) {
    const newValue = [...this.value];
    switch (eventType) {
      case 'mousemove':
        this.canvasCoords.currX = mouseEventObj.clientX - this.canvas.getBoundingClientRect().left;
        this.canvasCoords.currY = mouseEventObj.clientY - this.canvas.getBoundingClientRect().top;
        if (this.isMouseDown && this.selectedTool === 'pencil') {

          this.currentPoints.push({ x: this.canvasCoords.currX, y: this.canvasCoords.currY });

          this.canvasContext.lineTo(this.canvasCoords.currX, this.canvasCoords.currY);
          this.canvasContext.strokeStyle = this.selectedColour;
          this.canvasContext.lineWidth = this.brushWidth;

          this.canvasContext.stroke();
        }
        break;
      case 'mousedown':
        if (this.selectedTool !== this.ToolType.pencil) {
          // If it's not a pencil, add the correct image
          this.drawImage(this.imageMapper(this.selectedTool), this.canvasCoords.currX - 8.5, this.canvasCoords.currY - 10, this.selectedColour);
        } else  {
          this.isMouseDown = true;

          this.canvasCoords.prevX = mouseEventObj.clientX - this.canvas.getBoundingClientRect().left;
          this.canvasCoords.prevY = mouseEventObj.clientY - this.canvas.getBoundingClientRect().top;
          this.canvasCoords.currX = mouseEventObj.clientX - this.canvas.getBoundingClientRect().left;
          this.canvasCoords.currY = mouseEventObj.clientY - this.canvas.getBoundingClientRect().top;

          this.canvasContext.beginPath()
          this.canvasContext.moveTo(this.canvasCoords.currX, this.canvasCoords.currY);
          this.currentPoints.push({ x: this.canvasCoords.currX, y: this.canvasCoords.currY });
        }
        break;
      case 'mouseup':
        this.isMouseDown = false;
        if (this.selectedTool == this.ToolType.pencil) {
          newValue.push({ type: this.ToolType.points, data: this.currentPoints, colour: this.selectedColour, width: this.brushWidth });
          this.currentPoints = [];
        } else {
          newValue.push({ type: this.selectedTool, data: { x: this.canvasCoords.currX, y: this.canvasCoords.currY, colour: this.selectedColour } });
        }
        break;
      case 'mouseout':
        this.isMouseDown = false;
        break;
    }
    this.value = newValue; //have to replace value to trigger change detection
  }

  selectPresetColour(colour: string) {
    this.selectedColour = colour;
  }

  selectTool(tool: string) {
    this.selectedTool = tool;
  }

  drawImage(imageUrl: string, x: number, y: number, colour: string) {

    this.selectedColour = colour
    this.imageElement.src = imageUrl;
    this.imageElement.onload = () => {
      this.canvasContext.drawImage(this.imageElement, x, y);
    };
  }

  drawArrayOfImages(images: Array<any>) {
    console.log("drawing images", images)
    if (images.length < 1) {
      return;
    }

    this.imageElement.src = images[0].url;
    this.imageElement.onload = () => {
      this.selectedColour = images[0].data.colour

      this.canvasContext.drawImage(this.imageElement, images[0].data.x, images[0].data.y);
      images.shift();
      this.drawArrayOfImages(images);
    };
  }

  undo() {
    this.currentPoints = [];
    this.value.splice(-1, 1);
    this.clearCanvas();
    let imagesToDraw = []
    this.value.forEach(historyItem => {
      if (historyItem.type === this.ToolType.points) {
        this.canvasContext.beginPath();
        this.canvasContext.strokeStyle = historyItem.colour
        this.canvasContext.lineWidth = historyItem.width;
        this.canvasContext.moveTo(historyItem.data[0].x, historyItem.data[0].y);
        for (let i = 1; i < historyItem.data.length; i++) {
          //  this.canvasContext.beginPath();
          //this.canvasContext.strokeStyle = historyItem.data[i].colour
          this.canvasContext.lineTo(historyItem.data[i].x, historyItem.data[i].y);
        }
        this.canvasContext.stroke();
      } else {
        // If not a point draw the correct image
        imagesToDraw.push({ ...historyItem, url: this.imageMapper(historyItem.type) })
      }
    });
    this.drawArrayOfImages(imagesToDraw);

  }
}
