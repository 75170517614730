export const CaseHistoryFields: { label: string, field: string, aide?: string[], showAide?: boolean }[] =
[{
  label: "Chief complaint",
  field: "complaint"
},
{
  label: "History of condition. Date of onset mode/course",
  field: "history"
},
{
  label: "Pain location type of pain character radiations frequency and severity",
  field: "location"
},
{
  label: "Associated symptoms and aggrevating factors",
  field: "symptoms"
},
{
  label: "Relieving factors",
  field: "releiving"
},
{
  label: "Previous episodes and treatment",
  field: "previous"
},
{
  label: "Home, hobbies, recreational",
  field: "home"
},
{
  label: "Occupational History",
  field: "occupation"
},
{
  label: "System History",
  field: "system",
  aide: ["ENT","G/I","G/U","REPRODUCTIVE","CV/RESPIRATORY"],
  showAide: false
},
{
  label: "Generic Red Flags",
  field: "generic_red_flags",
  aide: ["Night Sweats", 
    "Fever / Recent Infection", 
    "Intravenous Drug Abuse", 
    "Unexplained Weight Loss", 
    "Severe and progressing levels of Pain",
    "Night Pain (different quality to day pain)",    
    "Diffuse, non-mechanically-reproducible Pain",
    "History of Cancer, TB, HIV or Inflammatory Arthritis"
  ],
  showAide: false
}
];