
import { Component } from '@angular/core';
import { NotesBuilderBaseComponent } from 'app/notes/notes-builder/notes-builder-base/notes-builder-base-component';
import { OtherTestsFields } from "../models/other-test";

@Component({
  selector: 'app-other-tests',
  templateUrl: './other-tests.component.html',
  styleUrls: ['./other-tests.component.css']
})
export class OtherTestsComponent extends NotesBuilderBaseComponent {
  OtherTestsFields = OtherTestsFields
}
