import { Injectable } from '@angular/core';
import { format } from 'date-fns';

@Injectable()
export class FormatterService {

  constructor() { }

  /** 
   * @param - string -> 2008-12-08 09:00:00
   * @output - string -> 08/12/2008 09:00:00
  */
  public datetimeFormatter(dateString: string): string {
    if ( !dateString ) return "";

    const part = dateString.split(" ");
    const datePart = part[0].split("-");
    return `${datePart[2]}/${datePart[1]}/${datePart[0]} ${part[1]}`;
  }

  /** 
   * @param - string -> 2008-12-08
   * @output - string -> 08/12/2008
  */
  public dateFormatter(dateString: string): string {
    if ( !dateString ) return "";
    try {
      return format(new Date(dateString), "dd/MM/yyyy");  
    } catch (error) {
      return ""; //invalid date
    }
    
  }

  /** 
   * @param - string -> 10.3333
   * @output - string -> £10.33
  */
  public currencyFormatter(num: string): string {
    if ( !num ) return "£ 0.00";

    return "£ " + parseFloat(num).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  public floatFormatter(num: string): number {
    console.log(num);
    if ( !num ) return 0.00;
    return parseFloat(num);
  }

  public roundNumberFormatter(num) {
    if ( !num ) return 0;
    return Math.round(num)
  }

  public highlight(main: string, sub: string): any {
    return main.replace(new RegExp(sub, 'gi'), (str) => `<span class="text-danger text-capitalize">${str}</span>`);
  }

  /** 
   * Highlights the matched parts in a ( firstname - lastname ) string combination
  */
  public highlightName(name: string, input: string): any {
    const nameArray = name.split(" ");
    const firstName = nameArray[0] && nameArray[0].toLowerCase();
    let lastName = ""
    for (let i = 1 ; i < nameArray.length ; i ++) {
      // For each name in last name, add to lastName string
      lastName += nameArray[i] && nameArray[i].toLowerCase();
      if (i + 1 !== nameArray.length) lastName += " ";
    }
     

    const inputArray = input.trim().split(" ");
    const nameOne = inputArray[0].toLowerCase();
    const nameTwo = inputArray[1] && inputArray[1].toLowerCase();

    let nameOneFound = false;
    let nameTwoFound = false;

    const highlightedName = [];

    if ( firstName && nameOne && firstName.includes(nameOne) ) {
      nameOneFound = true;
      highlightedName.push(this.highlight(firstName, nameOne));
    } else if ( firstName && nameTwo && firstName.includes(nameTwo) ) {
      nameTwoFound = true;
      highlightedName.push(this.highlight(firstName, nameTwo));
    } else {
      highlightedName.push(`<span class="text-capitalize">${firstName}</span>`);
    }

    if ( lastName && nameOne && !nameOneFound && lastName.includes(nameOne) ) {
      highlightedName.push(this.highlight(lastName, nameOne));
    } else if ( lastName && nameTwo && !nameTwoFound && lastName.includes(nameTwo) ) {
      highlightedName.push(this.highlight(lastName, nameTwo));
    } else {
      highlightedName.push(`<span class="text-capitalize">${lastName}</span>`);
    }

    return highlightedName.join(" ");
  }
}
