<div class="container-fluid">
  <div class="row margin-collapse">
    <div class="col-lg-10 p-0">
      <div class="mb-2">
        <app-diagnosis-card [diagnosisIdx]="assocDiagnosis" [notesIdx]="notesIdx"></app-diagnosis-card>
      </div>
      <mat-card>
        <mat-card-content>
         
          <ng-container *ngIf="treatmentCards && treatmentCards.length">
            <h2 class="mt-5">Treatment Cards</h2>
            <br />
          </ng-container>

          <div class="d-flex w-100 mb-4">
            <button mat-stroked-button class="me-2" (click)="addNewNotes()">
              <mat-icon>add</mat-icon>Notes
            </button>

            <button
              mat-stroked-button
              class="me-2"
              (click)="addNewCard(card)"
              *ngFor="let card of treatmentCardTemplates"
            >
              <mat-icon>add</mat-icon>{{ card.name }}
            </button>
          </div>
          <hr />

          <div class="table-container active" *ngIf="newTreatmentCard">
            <div class="label"> Rx No. {{ newTreatmentCard.rxNo }}</div>
                <div *ngFor="let card of newTreatmentCard.cards" >

          <!-- Notes card section -->
          
              
              <table class="w-100 py-3" *ngIf="card.type === 'notes'">
                <tbody>
                  <tr>
                    <td class="td-lead"> 
                      {{ card.type }}
                    </td>
                    <td>
                      <mat-form-field class="w-100">
                        <mat-label>Notes</mat-label>
                        <textarea
                          matInput
                          cdkTextareaAutosize
                          cdkAutosizeMinRows="3"
                          cdkAutosizeMaxRows="12"
                          id="new_notes"
                          name="new_notes"
                          [(ngModel)]="card.data.topLabels[0]"
                        ></textarea>
                      </mat-form-field>
                    </td>
                  </tr>
                </tbody>
              </table>
            
            <!-- Spinal card section -->
            
              <table class="w-100 py-3"  *ngIf="card.type === 'card'">
                <tbody>
                  <tr>
                    <td class="td-lead" rowspan="3">
                      Card
                    </td>
                    <td
                      *ngFor="let label of labelItterator; let i = index"
                      class="td-number"
                    >
                      <input
                        type="text"
                        [name]="'top_' + i"
                        [(ngModel)]="card.data.topLabels[i]"
                        tabindex="{{ i + 1 }}"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td
                      *ngFor="let data of labelItterator; let i = index"
                      class="td-number"
                    >
                      <input
                        type="text"
                        [name]="'mid_' + i"
                        [(ngModel)]="card.data.middleLabels[i]"
                        tabindex="{{
                          card.data.middleLabels.length * 2 + i + 1
                        }}"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td
                      *ngFor="let data of labelItterator; let i = index"
                      class="td-number"
                    >
                      <input
                        type="text"
                        [name]="'bot_' + i"
                        [(ngModel)]="card.data.bottomLabels[i]"
                        tabindex="{{
                          card.data.middleLabels.length + i + 1
                        }}"
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
          </div>
          </div>
          <!-- For COMPLETED cards.-->
          <div class="table-container" *ngFor="let cardGroup of treatmentCards">
            <div class="label">
              <span class="me-5">Rx No. {{ cardGroup.rxNo }}</span>
              <span>{{ cardGroup.date | date : "short" }}</span>
            </div>
            <table class="w-100 py-3" *ngFor="let card of cardGroup.cards">
              <tbody>
                <ng-container *ngIf="card.type === 'notes' && card.data">
                  <tr>
                    <td class="td-lead" rowspan="3">
                     Notes
                    </td>
                    <td class="text-start ps-1">
                      {{ card.data.topLabels[0] }}
                    </td>
                  </tr>
                </ng-container>

                <ng-container
                  *ngIf="
                    card.type === 'card' && card.data && card.data.topLabels
                  "
                >
                  <tr>
                    <td class="td-lead" rowspan="3">
                      Card
                    </td>
                    <td
                      *ngFor="let data of card.data.topLabels"
                      class="td-number"
                    >
                      {{ data }}
                    </td>
                  </tr>
                  <tr>
                    <td
                      *ngFor="let data of card.data.middleLabels"
                      class="td-number"
                    >
                      {{ data }}
                    </td>
                  </tr>
                  <tr>
                    <td
                      *ngFor="let data of card.data.bottomLabels"
                      class="td-number"
                    >
                      {{ data }}
                    </td>
                  </tr>
                </ng-container>
              </tbody>
            </table>
          </div>
        </mat-card-content></mat-card
      >
    </div>

    <div class="col-lg-2 mobile-margin">
      <div class="fix-top">
        <div class="desktop-buttons">
          <button
            mat-raised-button
            color="primary"
            class="w-100"
            (click)="save()"
          >
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
