import { Component, SimpleChanges, Input, Output, EventEmitter, OnInit, OnChanges } from "@angular/core";
import { ProcessedAppointment } from "../../../../models/appointments";
import * as fromDiary from "../../../../models/diary";
import { Router } from "@angular/router";
import { PrintCardDialogService } from "app/reports/print-card-dialog/print-card-dialog.service";
import { PatientChooserService } from "app/services/patient.service";
import { DialogService } from "app/services/dialog/dialog.service";
import { AppointmentsActionsService } from "app/services/diary/appointment-actions.service";
import { StorageService } from "app/services/storage.service";
import { AttendanceDialogComponent } from "app/dialogs/attendance-dialog/attendance-dialog.component";
import { forkJoin } from "rxjs";
import { take } from "rxjs/operators";
import { NotesTypes } from "app/models/notes";

@Component({
    selector: "app-diary-actions",
    templateUrl: "./diary-actions.component.html",
    styleUrls: ["./diary-actions.component.scss"],
})


export class DiaryActionsComponent implements OnInit, OnChanges {
    @Input() styleType = "context-list";
    @Input() appointment: ProcessedAppointment
    @Output() clicked = new EventEmitter<string>();
    public actions = fromDiary.DiaryActions;
    public appointmentActions: fromDiary.AvailableActions;
    public appointmentStatus = fromDiary.AppointmentStatus;
    //private availableActions: fromDiary.AvailableActions[];
    patientIdx: number;


    constructor(private router: Router,
        private printCardDialog: PrintCardDialogService,
        private patient: PatientChooserService,
        private dialogService: DialogService,
        private appointmentActionsService: AppointmentsActionsService,
        private storage: StorageService,
    ) { }

    ngOnInit() {
        this.updatePatient();
    }

    ngOnChanges(changes: SimpleChanges) {
        this.updatePatient();
        this.updateActions();
    }

    private availableActions() {
        return this.appointmentActionsService.getFilteredActions();
    }
    updateActions() {
        // if no appointment return
        if (!this.appointment || !this.appointment.meta) return;
        // update actions
        console.log("[actions] filter", this.availableActions());
        this.appointmentActions = this.availableActions().find(f => f.status == this.appointment.meta.status)
    }

    updatePatient() {
        // if no appointment return
        if (!this.appointment || !this.appointment.meta) return;
        // update patient
        this.patientIdx = this.appointment.meta.patientId
        // chaeck for soap notes

    }

    execute(action) {

        this.emitEvent(action)
        switch (action) {
            case this.actions.EDIT:
                this.editAppointment();
                return;
            case this.actions.TAKE_PAYMENT:
                this.payAppointment();
                return;
            case this.actions.SHOW_INVOICE:
                this.showInvoice();
                return;
            case this.actions.DNA_CHARGE:
                this.payAppointment();
                return;
            case this.actions.SHOW_PATIENT_INFO:
                this.showPatientInfo();
                return;
            case this.actions.SHOW_SOAP:
                this.showNotesFromAppointment(NotesTypes.SOAP);
                return;
            case this.actions.SHOW_PHYSICAL_EXAMS:
                this.showNotesFromAppointment(NotesTypes.PHYSICAL_EXAM);
                return;
            case this.actions.SHOW_CASE_HISTORY:
                this.showNotesFromAppointment(NotesTypes.CASE_HISTORY);
                return;
            case this.actions.REBOOK:
                this.rebook();
                return;
            case this.actions.SHOW_FUTURE_APPOINTMENTS:
                this.showFutureAppointments();
                return;
            case this.actions.ATTENDANCE:
                this.showAttendance();
                return;
            case this.actions.REJECT_BOOKING:
                return;
            case this.actions.ACCEPT_BOOKING:
                return;
            //            default: 
            // console.error(`Unknown Appointment Action ${action}`);
        }

        this.updateActions();
    }


    rebook() {
        const repeatVisitDays = this.appointment.meta.repeatVisitDays || 30;
        const newStartTime = new Date(this.appointment.start.setDate(this.appointment.start.getDate() + repeatVisitDays));
        const startDate = newStartTime.getFullYear() + "-" + (newStartTime.getMonth() + 1) + "-" + newStartTime.getDate();
        this.router.navigate(['/diary'], { queryParams: { date: startDate } });
        this.storage.setItem("rebookPatientIdx", this.appointment.meta.patientId);
    }

    showAttendance() {
        this.dialogService.bespokeDialog("",
            AttendanceDialogComponent,
            "Attendance",
            this.appointment.meta.patientName,
            this.appointment.meta.patientId,
            "1200px");
    }

    showFutureAppointments() {
        this.printCardDialog.getAppointmentDetails(this.appointment.idx);//.then((dialogResult: any) => {})
    }

    showNotesFromAppointment(section: string) {
        // set current patient
        this.patient.setPatient(this.patientIdx)
        // get patient details

        let path = [];
        if (section === "soap") {
            path = ['notes', section, "0"];
        } else {
            path = ['notes', 'edit', section, "0"];
        }
        /*
                if (res[section] && res[section].length) {
                    // if patient has notes load latest
                    let index = res[section][res[section].length - 1].idx;
                    path.push(index);
                } else {
                    // open empty notes page
                }
        */

        this.router.navigate(path);

        
    }

    showInvoice() {
        this.router.navigate(['/finance/invoice', this.appointment.meta.transactionIdx]);
    }

    editAppointment() {
        this.router.navigate(['/appointment'], { queryParams: { appointmentIdx: this.appointment.idx } });
    }

    payAppointment() {
        this.router.navigate(['/pay'], { queryParams: { appointmentIdx: this.appointment.idx } });
    }

    showPatientInfo() {
        this.router.navigate(['/form/patientdetails/' + this.appointment.meta.patientId]);
    }

    emitEvent(action) {
        this.clicked.emit(action);
    }
}
