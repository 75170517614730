import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';


import { UISettingsComponent } from "./ui-settings/ui-settings.component";
import { AvailabilitySettingComponent } from './availability-setting/availability-setting.component';
import { AvailabilityComponent } from './availability-setting/availability/availability.component';
import { MenuSettingsComponent } from './menu-settings/menu-settings.component';
import { StockSettingsComponent } from './stock-settings/stock-settings.component';
import { EmailTemplatesComponent } from './email-templates/email-templates/email-templates.component';
import { EditTemplateComponent } from './email-templates/edit-template/edit-template.component';
import { OnlineBookingSettingsComponent } from './online-booking-settings/online-booking-settings.component'
import { AuthNeededGuard } from "app/guards/auth.guard";
import { InitializeGuard } from 'app/guards/initialize.guard';


export const SettingsAppRoutes = [
    {
        path: "settings",
        canActivate: [AuthNeededGuard, InitializeGuard],
        children: [
            {
                path: "menu",
                component: MenuSettingsComponent,

            },
            {
                path: "stock",
                component: StockSettingsComponent,
            },
            {
                path: 'emailtemplates',
                component: EmailTemplatesComponent
            },
            {
                path: 'emailtemplates/:templateName',
                component: EditTemplateComponent
            },
            {
                path: "availability/:staffIdx",
                component: AvailabilityComponent,
            },
            {
                path: "availability",
                component: AvailabilitySettingComponent
            },
            {
                path: "online-booking",
                component: OnlineBookingSettingsComponent
            },
            {
                path: "ui",
                component: UISettingsComponent
            }
        ]
    }
];
@NgModule({
            imports: [
                RouterModule.forChild(SettingsAppRoutes)
            ],
            exports: [
                RouterModule 
            ]
        })
export class SettingsRoutingModule { }

