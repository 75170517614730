interface PatientChooserResult{
  idx: number;
  address: string;
  name: string;
  highlighted?: string;
  isSpecial?: boolean;
}

export type { PatientChooserResult }

export const initialPatientChooserResult: PatientChooserResult = {
  idx: 0, name: "", address: ""
};

export interface PatientDetails {
  idx: number;
  dob?: string;
  name: string;
  title?: string;
  firstname?: string;
  lastname?: string;
  address: string[];
  email?: string;
  isSpecial?: boolean;
  highlighted?: string;
  lastAppointmentStaff?: string;
  insuranceNumber?: string;
  occupation?: string;
  practitioner?: string;
  insurance?: any;
  mobilePhone?: string;
  homePhone?: string;
  firstVisit?: any;
  lastAppointment?: any;
  notes?: string;
  nVisits?: number;
  unpaidLNC?: boolean;
  gp?: any[];
  reminders?: {
    sms: boolean,
    email: boolean
  };
}

/**
 * some or all of this may be populated as it is now filled on demand
 */
export interface PatientInfo  {
  details: PatientDetails;
  attendanceSummary?: AttendanceSummary[];
  attendance?: Attendance[];
  notes?: PatientNotesInfo;
  visitInfo?: VisitInfo;
}

export const initialPatientDetails: PatientDetails = {
  idx: 0, name: "", address: []
};

export const initialPatientNotesInfo = {
  casehistory: [],
  casesummary: [],
  patientquestionaire: [],
  physicalexam: [],
  soap: [],
  treatmentcards: [],
  letters: [],
  telephoneConsults: []
};

export const initialPatientInfo: PatientInfo = {
  details: initialPatientDetails,
  visitInfo: { nVisits: 0, lastVisit: "", firstVisit: "", nextVisit: "" }, 
  notes: initialPatientNotesInfo
};


export interface PatientNotesInfo{
  casehistory: NotesList[];
  casesummary: NotesList[];
  patientquestionaire: NotesList[];
  physicalexam: NotesList[];
  soap: NotesList[];
  treatmentcards: NotesList[];
  letters: NotesList[];
  telephoneConsults: NotesList[];
}
export interface AttendanceSummary {
  status: string;
  nApps: number;
  first: string;
  last: string;
}

export interface VisitInfo {
  nVisits: number;
  lastVisit: string;
  firstVisit: string;
  nextVisit: string;
}
export interface NotesList {
  idx: number;
  dateAdded: string;
  lastUpdates: string;
  description: string;
  type: string;
  staffMember: string;
  locked: boolean;
  signatureCount: number;
  changeRequested: boolean;
}

export interface Attendance {
  startTime: string;
  status: string;
  statusDisplay?: {icon: string, text: string};
  bookingMadeAt: string;
  cancelledAt: string;
  treatmentName: string;
  notes: string;
  SMSSent: boolean;
  staffName: string;
  price: number;
}
