import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignatureComponent } from './signature.component';
import { LockComponent } from './lock/lock.component';
import { SigningComponent } from './signing/signing.component';
import { DialogsModule } from 'app/dialogs/dialogs.module';
import { MyMaterialModule } from 'app/material/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';


@NgModule({
    imports: [   
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MyMaterialModule,
        DialogsModule,
    ],
    declarations: [
        SignatureComponent,
        LockComponent,
        SigningComponent
    ],
    providers: [  ],
    exports: [
        SignatureComponent
    ]
})
export class SignatureModule {}

