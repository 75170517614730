import { Component, EventEmitter, Input, OnInit, Output, SimpleChange } from '@angular/core';

@Component({
  selector: 'app-password-strength',
  templateUrl: './password-strength.component.html',
  styleUrls: ['./password-strength.component.scss']
})
export class PasswordStrengthComponent implements OnInit {
  private colours: Array<string> = ['darkred', 'orangered', 'orange', 'yellowgreen', 'green'];
  public maxStrength: number = 50;
  public missingStrength: string = "";
  public currentValue: any = {};

  @Input() public passwordToCheck: string;
  @Output() passwordStrength = new EventEmitter<boolean>();

  constructor() { 
    this.setValues(0);
    this.passwordStrength.emit(false);
  }

  ngOnChanges(changes: { [propName: string]: SimpleChange }): void {
    const password = changes.passwordToCheck.currentValue;
    let passwordStrength = 0;

    if ( password ) {
      this.setValues(this.checkStrength(password));
      passwordStrength = this.checkStrength(password);
      passwordStrength === 50 ? this.passwordStrength.emit(true) : this.passwordStrength.emit(false);
    }
    
    if ( !password || passwordStrength === 50 ) this.missingStrength = "";
    if ( password === null ) this.passwordStrength.emit(false);
  }

  ngOnInit(): void {}

  private setValues(strength: number): void {
    this.currentValue = ( strength > 40 ) ? { colour: this.colours[4], value: strength / this.maxStrength * 100 } :
                        ( strength > 30 ) ? { colour: this.colours[3], value: strength / this.maxStrength * 100 } :
                        ( strength > 20 ) ? { colour: this.colours[2], value: strength / this.maxStrength * 100 } :
                        ( strength > 10 ) ? { colour: this.colours[1], value: strength / this.maxStrength * 100 } :
                        { colour: this.colours[0], value: strength / this.maxStrength * 100 };
  }

  checkStrength(password: string) {
    let strength = 0;
    const minLength = 8;
  
    const specials = /[$-/:-?{-~!"^_@`\[\]]/g.test(password);;
    const lowerLetters = /[a-z]+/.test(password);
    const upperLetters = /[A-Z]+/.test(password);
    const numbers = /[0-9]+/.test(password);
    const length = password.length >= minLength;

    if ( !length ) this.missingStrength = `At least ${minLength} characters must be provided.`; else strength += 10;
    if ( !specials ) this.missingStrength = "A special character must be provided."; else strength += 10;
    if ( !numbers ) this.missingStrength = "A numeric character must be provided."; else strength += 10;
    if ( !upperLetters ) this.missingStrength = "An uppercase character must be provided."; else strength += 10;
    if ( !lowerLetters ) this.missingStrength = "A lowercase character must be provided."; else strength += 10;
  
    return strength;
  }

}
