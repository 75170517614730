import { Action } 			from '@ngrx/store';
import { type } 		  	from '../util';
import { NotificationMessage } from '../../models/notification-message';
export const DISPLAY = 'NOTIFICATION_SHOW'


export const ActionTypes = {
  DISPLAY:  type('[notification] ShowThis'), 
  SHOWN:    type('[notification] ShownIt'), 
};

export class ShowMessage implements Action {
  type = ActionTypes.DISPLAY;
  constructor(public payload: NotificationMessage) { }
}

export class ShownAction implements Action {
  type = ActionTypes.SHOWN;
  constructor() { }
}


/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */
 
export type Actions
  = ShowMessage | ShownAction;
