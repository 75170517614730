<div mat-dialog-title class="dialog-title">
    <h5>{{data.title}}</h5>
    <button mat-icon-button aria-label="close dialog" class="close-button" tabindex="-1" mat-dialog-close>
        <mat-icon class="close-icon text-muted">close</mat-icon>
    </button>
</div>

<div mat-dialog-content>
    <img [src]="data.qrData" alt="QR Code" />
</div>

<div mat-dialog-actions>
    
            <button mat-raised-button color="primary" (click)="onDismiss()">Close</button>
    

</div>