<div class="row" [ngClass]="fromNotes ? '' : 'm-3'">
  <div [ngClass]="( fromForm || fromNotes ) ? 'col-lg-12' : 'col-lg-4'" *ngIf="allowUpload">
  
        <ngx-awesome-uploader
        [adapter]="adapter"
        [itemTemplate]="myItemTemplate"
        (uploadSuccess)="uploadSuccess($event)"
        (uploadFail)="uploadFail($event)"
        >
        <div class="dropzoneTemplate" 
        style="padding:0" 
        [style.background-image]="getExistingFileUrl()"
        *ngIf="fromForm || fromNotes">
          <div class="upload-button">
            <mat-icon>upload_file</mat-icon>
          </div>
        </div>
        </ngx-awesome-uploader>
   
  </div>
  <div class="col-sm-8 mobile-margin" *ngIf="!fromForm && !fromNotes">
    <mat-card>
      <mat-card-content>
       
        <mat-form-field class='w-100'>
          <mat-label>Filter</mat-label>
          <input matInput (keyup)="applyFilter($event)" placeholder="Filename" #input>
        </mat-form-field>


        <table mat-table [dataSource]="dataSource" matSort matSortActive="dateAdded" matSortDirection="desc"
          class='w-100'>

          <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Name</th>
            <td mat-cell *matCellDef="let row"> {{row.name}} </td>
          </ng-container>

          <ng-container matColumnDef="dateAdded">
            <th mat-header-cell *matHeaderCellDef mat-sort-header>Date Added</th>
            <td mat-cell *matCellDef="let row"> {{row.dateAdded | date : 'short'}} </td>
          </ng-container>

          <ng-container matColumnDef="download">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let row" class="text-center">
              <button mat-icon-button color="primary" aria-label="Download" (click)="download(row)">
                <mat-icon>file_download</mat-icon>
              </button>
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
      </mat-card-content>
    </mat-card>
  </div>
</div>


<ng-template
    #myItemTemplate
    let-fileItem="fileItem"
    let-uploadProgress="uploadProgress"
    let-status="uploadStatus"
    let-uploadResponse="uploadResponse"
  >
    <div class="d-flex justify-content-between w-100">
      <div class="error-bar" *ngIf="uploadProgress==-1">Failed!</div>
      <div class="progress-bar" [style.width.%]="uploadProgress-1" *ngIf="uploadProgress>=0">
        <span *ngIf="uploadProgress<100">{{ uploadProgress-1 }} %</span>
      </div>
      <div class="filename">{{ fileItem.fileName }}</div>
      <div>{{ fileItem.file.size | formatFileSize }}</div>
    </div>
  </ng-template>