import { Action } from '@ngrx/store';
import { type } from '../util';

export const SHOW = 'LOADING_SHOW'
export const HIDE = 'LOADING_HIDE';

export const ActionTypes = {
    SHOW: type('[loading] Loading'),
    HIDE: type('[loading] NotLoading'),
    BACKGROUND_START: type('[loading] Background Loading Starts'),
    BACKGROUND_STOP : type('[loading] Background Loading Stops'),
};

export class ShowAction implements Action {
    type = ActionTypes.SHOW;
    constructor() { }
}

export class HideAction implements Action {
    type = ActionTypes.HIDE;
    constructor() { }
}

export class BackgroundStart implements Action {
    type = ActionTypes.BACKGROUND_START;
    constructor() { }
}

export class BackgroundStop implements Action {
    type = ActionTypes.BACKGROUND_STOP;
    constructor() { }
}

/**
 * Export a type alias of all actions in this action group
 * so that reducers can easily compose action types
 */

export type Actions
    = ShowAction
    | HideAction
    | BackgroundStart
    | BackgroundStop;
