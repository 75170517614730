import { QuestionBase, fieldType } from './question-base';

export class DropdownMultiQuestion extends QuestionBase<string[]> {
  controlType = fieldType.DROPDOWNMULTI;


  constructor(options: any = {}) {
    super(options);
    //Decode the value
    if (options.value){
      this.value = JSON.parse(options.value)
    }else{
      this.value=[];
    }
  }
}
