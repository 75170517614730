import { Injectable } from '@angular/core';
import { ReportsService } from 'app/reports/reports.service';
import { DataService } from 'app/services/data.service';
import { Observable, of } from 'rxjs';
import { take } from 'rxjs/operators';
import * as reportsFields from 'app/models/report-fields';

@Injectable({
  providedIn: 'root'
})
export class PurchaseOrdersService {

  constructor(private http: DataService, private reports: ReportsService) { }

  postPurchaseOrder(payload) {
    console.log("TRANSACTION PAYLOAD", JSON.stringify(payload.items));
    return this.http.post("/finance/po", payload);
  }

  public getPOByIdx(poIdx: number): Observable<any> {
    return this.http.get(`/finance/po/${poIdx}`);
  }

  public getPandLCats(): Observable<any> {
    return this.http.get("/pandlcats");
  }

  public getCommissionPO(reportName: string, staffIdx: number, clinicIdx: number, dateFilter: any): Observable<any> {
    return this.http.get(`/finance/po/commission/${reportName}/${staffIdx}/${clinicIdx}?dateFilter=${JSON.stringify(dateFilter)}`);
  }

  getPurchaseOrder(idx: number){
    return this.http.get(`/po/${idx}`)
  }

  getAllPurchaseOrders(){
   return this.reports.getData(reportsFields.POs.REPORT_IDX,
      {startRow: 0, endRow: 100, sortModel: [], filterModel: {}, successCallback: null, failCallback: null, context: null });
  }
/*
  putPurchaseOrder(idx: number, payload: any){
    return this.http.put(`po/${idx}`, payload);
  }


  tryReports(){
    // Just testing to see what data I could get from the API
    for (let i = 1 ; i < 500 ; i ++){
      this.reports.getData(i, {startRow:0,endRow:100,sortModel:[],filterModel:{},successCallback: null, failCallback: null, context:null})
      .pipe(take(1))
      .subscribe(res => console.log(`[POS] Report ${i}`, res));
    }
  }
*/
  voidPO(idx){
    return this.http.delete(`/finance/po/${idx}`);
  }

}
