import { createReducer, on } from '@ngrx/store';
import { State, initialState } from '../../models/auth';
import * as authAction from '../actions/auth';
import { ActionWithPayload } from './index';

export { State, initialState };

const featureReducer = createReducer(
  initialState,
  on(authAction.InitializeAction, (state) => state = initialState),
  on(authAction.UpdateAction, (state, action) => state = action.payload)
);
  
export function reducer(state:State = initialState, action: ActionWithPayload) {
  return featureReducer(state, action);
}

export const getJWT = (state: State) => state.jwt;
export const getState = (state: State) => state;
export const permissions = (state: State) => state.permissions;