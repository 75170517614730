import { Component } from '@angular/core';
import { PasswordDialogComponent } from 'app/dialogs/password-dialog/password-dialog.component';
import { FormTemplateBaseComponent } from '../form-template-base/form-template-base.component';

@Component({
  selector: 'app-form-password',
  templateUrl: './form-password.component.html',
  styleUrls: ['./form-password.component.css']
})
export class FormPasswordComponent extends FormTemplateBaseComponent {
  public passwordError: string;

  public open(): void {
    this.dialogService.bespokeDialog("", PasswordDialogComponent, "password", "Change Password", [ { userIdx: +this.router.url.split("/")[3] } ]);
  }
}
