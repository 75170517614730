import { Injectable } from "@angular/core";
import { AppointmentStatus } from "app/models/diary";
import { AttendanceSummary } from "app/models/patient-details";
import { BehaviorSubject, Observable } from "rxjs";
import { filter, map, switchMap } from "rxjs/operators";
import { PatientChooserService } from "./patient.service";


export interface AttendanceSummaryTransalted {
  previousApps: number;
  lastVisit: string;
  nextVisit: string;
  dnaTotalCount: number;
  dnaUnpaidCount: number;
  lastDNA: string;
  lncTotalCount: number;
  lncUnpaidCount: number;
  lastLNC: string;
  firstVisit: string;
}

@Injectable()
export class PatientAppointmentHistoryService {
  private patientId: number;
  private lastDisplayedPatientId: number;
  private cacheTimeout$;

  
  public previousApps$ = new BehaviorSubject<string | number>('-');
  public lastVisit$ = new BehaviorSubject<string>(null);
  public nextVisit$ = new BehaviorSubject<string>(null);
  public firstVisit$ = new BehaviorSubject<string>(null);

  public dnaTotalCount$ = new BehaviorSubject<string | number>('-');
  public dnaUnpaidCount$ = new BehaviorSubject<string | number>('-');
  public lastDNA$ = new BehaviorSubject<string>(null);

  public lncTotalCount$ = new BehaviorSubject<string | number>('-');
  public lncUnpaidCount$ = new BehaviorSubject<string | number>('-');
  public lastLNC$ = new BehaviorSubject<string | number>(null);

  constructor(
    private patientService: PatientChooserService
  ) {

  }


  setPatientId(id): void {
    if (this.patientId !== id) {

      if (this.lastDisplayedPatientId !== id){ // only clean down if iuts actually changed, and was not a debounce
        this.previousApps$.next(0);
        this.lastVisit$.next(null);
        this.nextVisit$.next(null);
        this.firstVisit$.next(null)
        this.dnaTotalCount$.next(0);
        this.dnaUnpaidCount$.next(0);
        this.lastDNA$.next(null);
        this.lncTotalCount$.next(0);
        this.lncUnpaidCount$.next(0);
        this.lastLNC$.next(null);
      }

      this.patientId = id;
      this.lastDisplayedPatientId = id;
      this.patientService.getAttendanceSummary(id).subscribe(res => {
        this.updateOutputs(res);
      });


/** this is essentially a debouce so the cache is not long lived.
 * for instance when the tooltip is activated then we see multiple requests to this setId in verq quick succession.
 * Once the timout expires gets pushed to the DB cache one level up.
**/

      if (this.cacheTimeout$){
        clearTimeout(this.cacheTimeout$);
      }

      this.cacheTimeout$ = setTimeout(() => {
        this.patientId = null;
      }, 5000);


    }
  }

  getSummaryForPatient(id): Observable<AttendanceSummaryTransalted> {
    return this.patientService.getAttendanceSummary(id).pipe(
      map(res => {
      console.log("[atts]", res);
      
      return this.updateOutputs(res);
    }));
  }

  private updateOutputs(atts: AttendanceSummary[]): AttendanceSummaryTransalted {

    const out: AttendanceSummaryTransalted = {
      previousApps: 0,
      lastVisit: "",
      nextVisit: "",
      firstVisit: '',
      dnaTotalCount: 0,
      dnaUnpaidCount: 0,
      lastDNA: "",
      lncTotalCount: 0,
      lncUnpaidCount: 0,
      lastLNC: "",
    };
    console.log("[atts]", atts);
    for (const att of atts) {
      switch (att.status) {
        case (AppointmentStatus.BOOKING):
          this.nextVisit$.next(att.first);
          out.nextVisit = att.first;
          break;
        case (AppointmentStatus.PAID):
          this.previousApps$.next(att.nApps);
          this.lastVisit$.next(att.last);
          this.firstVisit$.next(att.first)
          out.previousApps = att.nApps;
          out.lastVisit = att.last;
          out.firstVisit = att.first;
          break;

        case (AppointmentStatus.LNCPAID):
          this.lncTotalCount$.next(att.nApps);
          out.lncTotalCount = att.nApps;
          break;

        case (AppointmentStatus.LNC):
          this.lncUnpaidCount$.next(att.nApps);
          out.lncUnpaidCount = att.nApps;
          break;

        case (AppointmentStatus.DNAPAID):
          this.dnaTotalCount$.next(att.nApps);
          out.dnaTotalCount = att.nApps;
          break;

        case (AppointmentStatus.NOSHOW):
          this.dnaUnpaidCount$.next(att.nApps);
          out.dnaUnpaidCount = att.nApps;
          break;
      }
    }

    return out;
  }

}
