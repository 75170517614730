import { Injectable } from "@angular/core";
import { InsuranceInvoiceService } from "app/finance/insurance-invoice/insurance-invoice.service";
import { InsuranceDetails } from "app/models/insurance-details.model";
import { DataService } from "../services/data.service";

export interface PostTransaction{
  patientIdx: number,
  items: any[],
  payments: any[],
  clinicIdx: number,
  accountingDate: Date,
  insuranceInvoice: boolean,
  insuranceAuthCode?: string,
  refundsTxn?: number
}

@Injectable()
export class TillService {
  constructor(private http: DataService) {}

  postTransaction( payload: PostTransaction
  ) {
    console.log("TRANSACTION PAYLOAD");
    console.log(payload);
    return this.http.post("/pay", payload);
  }

  fetchInsurers() {
    return this.http.get<InsuranceDetails[]>(`/insurers/list`);
  }

  fetchInsurance(patientIdx) {
    return this.http.get(`/insurers/${patientIdx}`);
  }

  fetchStaffInsurance() {
    return this.http.get("/insurers/staff");
  }
}
