import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { VisualsService } from '../../services/visuals.service';

@Component({
  selector: 'app-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss']
})
export class GridComponent implements OnInit {

  public links = [
    {
      title: "Patients",
      link: "/home/patients",
      oldStyleImgUrl: "./assets/icons/main-menu-icons/classic-look/Patients.gif",
      //newStyleIconClass: "far fa-user",
    },
    {
      title: "Diary",
      link: "/diary",
      oldStyleImgUrl: "./assets/icons/main-menu-icons/classic-look/Diary.gif",
     // newStyleIconClass: "far fa-clock",
    },
    {
      title: "Till",
      link: "/pay",
      oldStyleImgUrl: "./assets/icons/main-menu-icons/classic-look/Till.png",
     // newStyleIconClass: "far fa-cash-register",
    },
    {
      title: "Notes",
      link: "/notes",
      oldStyleImgUrl: "./assets/icons/main-menu-icons/classic-look/Notes.gif",
     // newStyleIconClass: "far fa-sticky-note",
    },
    {
      title: "Reports",
      link: "/home/reports",
      oldStyleImgUrl: "./assets/icons/main-menu-icons/classic-look/Reports.gif",
    //  newStyleIconClass: "far fa-file-alt",
    },
    {
      title: "Settings",
      link: "/home/settings",
      oldStyleImgUrl: "./assets/icons/main-menu-icons/classic-look/Settings.gif",
    //  newStyleIconClass: "far fa-cogs",
    },
    {
      title: "Accounts",
      link: "/home/accounts",
      oldStyleImgUrl: "./assets/icons/main-menu-icons/classic-look/Accounts.gif",
     // newStyleIconClass: "far fa-calculator",
    },
    {
      title: "Credits",
      link: "/home/credits",
      oldStyleImgUrl: "./assets/icons/main-menu-icons/classic-look/Credits.png",
     // newStyleIconClass: "far fa-money-bill-wave",
    },
    {
      title: "Marketing",
      link: "/marketing",
      oldStyleImgUrl: "./assets/icons/main-menu-icons/classic-look/Marketing.png",
    //  newStyleIconClass: "far fa-bullhorn",
    },
    {
      title: "To Do",
      link: "/todo",
      oldStyleImgUrl: "./assets/icons/main-menu-icons/classic-look/ToDo.png",
     // newStyleIconClass: "far fa-clipboard",
    },
    {
      title: "Letters",
      link: "/home/letters",
      oldStyleImgUrl: "./assets/icons/main-menu-icons/classic-look/Letters.png",
     // newStyleIconClass: "far fa-envelope",
    },
    {
      title: "Help",
      link: "/help",
      oldStyleImgUrl: "./assets/icons/main-menu-icons/classic-look/Help.gif",
    //  newStyleIconClass: "far fa-life-ring",
    },
    {
      title: "Log Off",
      link: "/logout",
      oldStyleImgUrl: "./assets/icons/main-menu-icons/classic-look/LogOff.png",
     // newStyleIconClass: "far fa-sign-out-alt",
    },
    {
      hidden: true,
    },
    {
      title: "Support",
      link: "/support",
      oldStyleImgUrl: "./assets/icons/main-menu-icons/classic-look/Support.gif",
    //  newStyleIconClass: "far fa-question-circle",
    },
  ]

  private isOldStyle$;
  public isOldStyle;

  constructor(public router: Router, private visual: VisualsService) { }


  public logSomething(link: any) {
    this.router.navigate([link.link]);
    console.log("go to path: " + link.link)
  }

  ngOnInit() {
    this.isOldStyle$ = this.visual.getIconsStyle().subscribe(res => { this.isOldStyle = res });
  }

  ngOnDestroy() {
    this.isOldStyle$.unsubscribe();
  }
}
