<div  *ngIf="form" [id]='formName'>
    <form autocomplete="off" class="extremetiesForm" #formEle="ngForm">
      <table class="w-100">
        <thead>
          <th></th>
          <th class="text-center">L</th>
          <th class="text-center">R</th>
        </thead>
        <tbody>
          <tr *ngFor="let test of tests; index as i">
            <td>

              <mat-form-field *ngIf="i == tests.length-1;">
                <mat-label>Test {{i+1}}</mat-label>
                <input type="text"
                       matInput
                       name="'testname_{{i}}"
                       [matAutocomplete]="autoGroup"
                       [(ngModel)]="test.testName"
                       (ngModelChange)="doFilter($event)"
                       >
                  <mat-autocomplete #autoGroup="matAutocomplete">
                    <mat-optgroup *ngFor="let sec of aideMemoire | async" [label]="sec.title">
                      <mat-option *ngFor="let name of sec.categories" [value]="sec.title+' > '+name">
                        {{name}}
                      </mat-option>
                  </mat-optgroup>
                </mat-autocomplete>
              </mat-form-field>

              <mat-form-field *ngIf="i != tests.length-1;">
                <mat-label>Test {{i+1}}</mat-label>
                <input type="text"
                       matInput
                       name="testname_{{i}}"
                       [(ngModel)]="test.testName">
              </mat-form-field>
            </td>
            <td>
              <mat-form-field >
                <textarea matInput 
                  cdkTextareaAutosize
                  cdkAutosizeMinRows="1"
                  cdkAutosizeMaxRows="12"
                  name="test_l_{{i}}"
                  [(ngModel)]="test.l"
                ></textarea>
              </mat-form-field>
            </td>
            <td>
              <mat-form-field >
                <textarea matInput 
                  cdkTextareaAutosize
                  cdkAutosizeMinRows="1"
                  cdkAutosizeMaxRows="12"
                  name="test_r_{{i}}"
                  [(ngModel)]="test.r"
                ></textarea>
              </mat-form-field>
            </td>
            <td>
              <button *ngIf="i == tests.length-1" mat-mini-fab color="primary" aria-label="Add" (click)="addTest()" [disabled]="isDisabled">
                <mat-icon>add</mat-icon>
              </button>
            </td>
          </tr>
        </tbody>
      </table>
      
      
     
        <!-- <mat-expansion-panel hideToggle>
          <mat-expansion-panel-header>
            <mat-panel-title>
              Show Aide Memoire
            </mat-panel-title>
            <mat-panel-description>
              Click to show reminders
            </mat-panel-description>
          </mat-expansion-panel-header>
          <div class="row">
            <div class="col-sm-4"  *ngFor="let sec of AideMemoire">
              <ul style="list-style-type:none;" >
                <li><b>{{sec.title}}</b></li>
                <li *ngFor="let cat of sec.categories">{{cat}}</li>
              </ul>
              </div>
          </div>
        </mat-expansion-panel> -->

        <button mat-flat-button color="secondary" (click)="toggleAide()">
          <div class=" d-flex flex-row">
              <div *ngIf="!showAide">Show Aide Memoire</div>
              <div *ngIf="showAide">Close Aide Memoire</div>
              <mat-icon class="mt-1 ms-2">live_help</mat-icon>
          </div>
      </button>
  
      <div *ngIf="showAide" class="alert alert-info aide-memoire">
          <!-- <div class="aide-memoire-text">Spinal red flags</div> -->
          <div class="row">
            <div class="col-sm-4"  *ngFor="let sec of AideMemoire">
              <ul style="list-style-type:none;" >
                <li><b>{{sec.title}}</b></li>
                <li *ngFor="let cat of sec.categories">{{cat}}</li>
              </ul>
              </div>
          </div>
      </div>

    </form>
  </div>
