<div class="alert alert-warning" *ngIf="appointmentInFuture">Please be aware that this appointment will take place {{ appointmentInFuture }}</div>

<div *ngIf="otherUnpaidAppointments.length > 0" class="alert alert-danger unpaid">
  <div class="row">
    <div class="col-sm-8">Client has {{otherUnpaidAppointments.length}} other unpaid appointments. Will you like to process them now?</div>
    <div class="col-sm-4">
      <div class="buttons">
        <button mat-flat-button color="primary" class="me-3" (click)="addUnpaidAppointments()" >Yes</button>
        <button mat-flat-button (click)="removeUnpaidAppointments()">No</button>
      </div>
    </div>
  </div>
</div>
<div *ngIf="errorMessage" class="alert alert-danger">
  {{errorMessage}}
</div>

<div class="row margin-collapse">
  <div class="col-lg-10">

    

    <mat-card><mat-card-content>

  
<div class="mt-2">
  <div class="row text-center">
  
    <div class="col-sm-4">
      <h6 class="little-title">Date</h6>
      <div class="form-group">
        <mat-form-field>
          <mat-label>Date</mat-label>
            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
            <mat-datepicker #picker color="primary"></mat-datepicker>
            <input matInput 
              name="dateSelect"
              [matDatepicker]="picker" 
              (change)="accountingDateChanges($event)"
              [(ngModel)]="accountingDate">
        </mat-form-field>
      </div>
    </div>
  
  <div class="col-sm-4">
    <h6 class="little-title">Clinic</h6>
    
    <span *ngIf="clinicFromTreatment && clinic">{{clinic.name}}</span>
    
    <div class="form-group" *ngIf="!clinicFromTreatment">
      <mat-form-field>
        <mat-label>Clinic</mat-label>
        <mat-select
          name="clinicSelect"
          (selectionChange)="updateClinic($event)" 
          [(ngModel)]="clinic">
          <mat-option *ngFor="let clinic of clinics" [value]="clinic">
            {{ clinic.name }}
          </mat-option>
        </mat-select>
      </mat-form-field>

      <div *ngIf="!clinic && formSubmitted" class="alert alert-danger">Please select a clinic</div>
    </div>
  </div>

  <div *ngIf="patientInfo" class="col-sm-4">
    <h6 class="little-title">Patient</h6>
    
    
    {{ patientInfo.name }}

    <div *ngIf="patientInfoFull" class="notes-trunc" [matTooltip]="patientInfoFull.notes ">
      <p class="overflow-ellipsis">{{ patientInfoFull.notes }}</p>
    </div>


  </div>
  <div class="col-sm-3">
      <h6 class="little-title">Account balance</h6>
      {{account.balance | currency : "GBP"}}
  </div>
  </div>
</div>





  <!-- Patient chooser -->
  <div *ngIf="!appointmentIdx">
    <div class="full-width patient-chooser-container">
      <span class="mat-form-field-label-wrapper">
        <label class="mat-form-field-label ng-tns-c18-10 ng-star-inserted">Patient</label>
      </span>
      <patient-chooser [showLastAppointment]="false" [allowAddNew]="true" [includePatients]="1" [includeCompanies]='1'></patient-chooser>
    </div>
    

  </div>
<section>
<hr/>
  <div >
    <table>
      <thead>
        <tr>
          <th>Item</th>
          <th>Cost (each)</th>
          <th>Qty</th>
          <th>Total</th>
          <th>Notes</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of items; let i=index">
          <td>{{item.name}}</td>
          <td>
            <mat-form-field>
              <input type="text" [disabled]="recoveredTransaction" [(ngModel)]="item.price" name="price_{{i}}" matInput (change)="updateTotal()" />
            </mat-form-field>
          </td>
          <td>
            <mat-form-field *ngIf="(item.type==ItemType.SALE_ITEM && !recoveredTransaction) else qtyAlone">
              <input type="number" [disabled]="recoveredTransaction" [(ngModel)]="item.qty" name="qty{{i}}" matInput (change)="updateTotal()" />
            </mat-form-field>
            <ng-template #qtyAlone>
              {{item.qty}}
            </ng-template>

          </td>
          <td>{{(item.price*item.qty) | currency : "GBP"}}</td>
          <td>
            <mat-form-field [matTooltip]="item.notes">
              <input type="text" [disabled]="recoveredTransaction" name="notes{{i}}" [(ngModel)]="item.notes" matInput />
            </mat-form-field>
          </td>
          <td *ngIf="!recoveredTransaction">
            <button (click)="removeItem(item.idx)" mat-flat-button>
              <mat-icon>delete</mat-icon>
            </button>
          </td>
        </tr>
        <tr *ngIf="!recoveredTransaction">
          <td>
            <mat-form-field>
              <input type="text" 
                placeholder="Choose item" 
                matInput 
                [matAutocomplete]="auto" 
                [formControl]="myControl"
                name="chooseitem"
                id="inputDropdown"
                >
              <mat-autocomplete #auto="matAutocomplete" 
                (optionSelected)="itemSelected($event)">
                <mat-option
                  *ngFor="let option of filteredSaleProducts | async" 
                  [value]="option.name"
                  [id]="option.type+'_'+option.idx" 
                  (click)="addItem();">
                  {{option.name}}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>

          </td>
          <td>
            <mat-form-field>
              <input name="price_new" type="text" [(ngModel)]="newItem.price" matInput />
            </mat-form-field>
          </td>
          <td>
            <mat-form-field>
              <input name="qty_new" type="number" [(ngModel)]="newItem.qty" matInput />
            </mat-form-field>
          </td>
          <td>{{(newItem.price * newItem.qty) | currency : 'GBP'}}</td>
          <td>
            <mat-form-field>
              <input name="notes_new" type="text" [(ngModel)]="newItem.notes"  matInput />
            </mat-form-field>
          </td>
        </tr>
      </tbody>
    </table>
    <div *ngIf="items.length < 1 && formSubmitted && !payingBalance" class="alert alert-danger">Please select items</div>
  </div>
</section>
<section>
  <div >
    <div class="row">
      <div class="col-sm-4">
        <div class="payment-amount">
        <h6>Payment Amount</h6>
        <mat-form-field>
          <!-- (blur)="transformAmount($event)" onfocus="this.value=''" -->
          <input type="text" 
          name="payAmnt"
          onfocus="this.value=''" 
          [(ngModel)]="paymentAmountPounds"
            matInput />
        </mat-form-field>
        </div>

        <table>
          <tr>
            <td>Total Amount</td>
            <td>{{totalPrice | currencyPence : 'GBP'}}</td>
          </tr>
          <tr *ngFor="let payment of payments">
            <td>{{payment.name}}</td>
            <td>{{payment.amount | currencyPence : 'GBP'}}</td>
            <td>
              <button class='btn btn-link float-right' (click)="removePayment(payment)">
                <mat-icon>remove_circle_outline</mat-icon>
              </button>
            </td>
          </tr>
  
          <tr *ngIf="payments.length>0" class='payment-balance'>
            <td>Amount Remaining</td>
            <td>{{outstanding | currencyPence : 'GBP'}}</td>
            <td></td>
          </tr>
        </table>




      </div>

      <div class="col-sm-4" >
        <h6 *ngIf="!paymentIntent;">Pay by</h6>


        <div class="d-inline-flex justify-content-start flex-wrap" *ngIf="!paymentIntent; else payByIntentSection">
          <button *ngFor="let method of paymentMethods.actual" 
          class="me-3 mb-3 payment-button"
            [style.backgroundColor]="method.colour" 
            (click)="payBy(method)" 
            mat-stroked-button>
            <div class="btn-text">{{method.name}}</div>
          </button>
          <button *ngFor="let ins of matchedInsurers" 
            class="me-3 mb-3 payment-button"
              [style.backgroundColor]="paymentMethods.insurance[0].colour" 
              [title]="ins.name"
              (click)="payBy(paymentMethods.insurance[0])" 
              mat-stroked-button>
              <div class="btn-text">{{ins.name | maxLength : 12 }}</div>
            </button>

          <div *ngIf="creditCost > 0 && (creditsDetails ? creditsDetails.length > 0 : false)">
            <button *ngFor="let credits of creditsDetails" 
              [disabled]="credits.count<creditCost || items.length>1"
              class="me-3 mb-3 payment-button-2x" 
              (click)="payByCredits(credits)"
              mat-stroked-button>{{credits.description}} {{creditCost}}/{{credits.count}}
                <span *ngIf="patientIdx!=credits.patientIdx">(on acc.)</span>
            </button>
          </div>
        </div>
        <ng-template #payByIntentSection>
          <div class="d-inline-flex justify-content-start flex-wrap">
            <div class="mb-2">Complete online payment up to {{paymentIntent.amount/100 | currency : 'GBP'}} </div>
            <button
            class="me-3 mb-3 payment-button w-100"
            [style.backgroundColor]="'#008800'" 
              title="Complete Online Payment"
              (click)="payByIntent()" 
              mat-stroked-button>
              <div class="btn-text">Pay</div>
            </button>
          </div>
        </ng-template>
    </div>

        <div class="col-sm-4" >
          <h6>Apply Discount</h6>
          <div class="d-inline-flex justify-content-start flex-wrap">
            <button *ngFor="let method of paymentMethods.voucher" class="me-3 mb-3 discount-button"
              [style.backgroundColor]="method.colour" (click)="payBy(method)" [hidden]="method.name == 'Insurance'"
              mat-stroked-button>
              <div class="btn-text">{{method.name}}</div>
            </button>
        </div>
      </div>
    </div>
    </div>
  </section>
  </mat-card-content></mat-card>
  </div>
    <div class="col-sm-2 mobile-till-margin">
      <div class="fix-top">
        <ul class="list-sections">
          <li>
            <button class="btn btn-link" (click)="addNewPatient()">
              Add new patient
            </button>
          </li>
          <li>
            <button class="btn btn-link" (click)="chooseWalkIn()">
              Walk-In Paitent
            </button>
          </li>
        </ul>
        <div class="desktop-buttons mobile-buttons">
          <div class="m3-3 text-center mobile-button">      
            <div class="button-container">
              <div class="spinner-container" *ngIf="processing">
                <mat-spinner diameter="24"></mat-spinner>
              </div>
              <button mat-raised-button 
                          color="primary" 
                          class="process-invoice-btn" 
                          [disabled]="processing"
                          (click)="processPayment()">{{isRefund ? 'Process Refund' : 'Process Invoice'}}</button>
            </div>
          </div>
          <div class="my-3 text-center mobile-button">
            <button mat-raised-button 
              class="process-invoice-btn" 
              (click)="cancel()">Back</button>
          </div>
      </div>
      </div>
    </div>

    <!-- <div><h2>{{creditsDetails | json}} {{treatmentFromCat}}</h2></div> -->
</div>